import React, { useEffect, useState, useRef } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { useForm } from "react-hook-form";
import axios from 'axios';
import {
      Button, Modal, UncontrolledTooltip, Row, Col
} from "reactstrap";
import { ProfileUploader } from '../ProfileUpload';
import { NewFieldAutocomplete } from '_components/FormElements/FormInput';
import { Badge } from '@material-ui/core';
import { conditionallyUpdateScrollbar } from 'reactstrap/lib/utils';
import NotificationAlert from "react-notification-alert";
import { dashboardConstants } from '_constants';
import { dashboardService } from '_services';
import { useDispatch } from 'react-redux'

export const Favourities = (props) => {
      const { control } = useForm({
            mode: 'onBlur'
      });

      const dispatch = useDispatch();
      const [group, setGroup] = useState([]);
      const [open, setOpen] = useState(false);
      const [groupSelected, setGroupSelected] = useState(false);
      let [groupName, setGroupName] = useState(null);
      const [isFavorite, setIsFavorite] = useState(props.isFavorite);
      const notificationAlert = useRef(null);
      let userData = JSON.parse(localStorage.getItem('CHuser'));

      const theme = useTheme();
      const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

      useEffect(() => {
            //setOpen(true);
      }, [])

      const notify = (type, message) => {
            let options = {
                  place: "tr",
                  message: (
                        <div className="alert-text">
                              <span data-notify="message">
                                    {message}
                              </span>
                        </div>
                  ),
                  type: type,
                  // icon: "ni ni-bell-55",
                  autoDismiss: 5
            };
            notificationAlert.current.notificationAlert(options);
      };

      const handleClickOpen = () => {
            if (!isFavorite) {
                  (async () => {
                        await axios.get('favorite/group/').then(res => {
                              setGroup(res.data.result);
                        })
                  })();
                  setOpen(true);
            } else {
                  (async () => {
                        await axios.put('favorite/removechannel/', {
                              type: "WISHLIST",
                              channelgroupid: parseInt(isFavorite),
                              channelid: +props.channelId,
                        }).then(res => {
                              dashboardService.dashboard_count().then(dasboard_count => {
                                    //console.log("dashboard_count:",dasboard_count);
                                    dispatch({ type: dashboardConstants.COUNT_SUCCESS, dasboard_count });
                              });
                              notify('danger', 'Favourite Removed Successfully');
                              if (props.fromEvent) {
                                    favToCatalyst({
                                          channelId: userData.channelId,
                                          favouriteChannelId: +props.channelId,
                                          updatedBy: userData.contactId,
                                          isRemove: 1
                                    })
                              }
                              setIsFavorite(null);
                              if (props.isFavoriteList)
                                    props.getChannelList();
                        })
                  })();
            }
      };

      const handleClose = () => {
            setGroupSelected(false);
            setGroupName(null);
            setOpen(false);
      };

      const save = async () => {


            if (groupSelected == true) {
                  await axios.post('favorite/addchannel', {
                        type: "WISHLIST",
                        channelgroupid: groupName.id,
                        channelid: +props.channelId
                  }).then(res => {
                        dashboardService.dashboard_count().then(dasboard_count => {
                              //console.log("dashboard_count:",dasboard_count);
                              dispatch({ type: dashboardConstants.COUNT_SUCCESS, dasboard_count });
                        });
                        notify('success', '  Favourite Added Successfully');

                        setIsFavorite(groupName.id);
                        if (props.fromEvent) {
                              favToCatalyst({
                                    channelId: userData.channelId,
                                    favouriteChannelId: +props.channelId,
                                    updatedBy: userData.contactId,
                                    isRemove: 0
                              })
                        }

                        // if (props.channelTypeId == 2) {
                        //       axios.post('mailerservice/Buyer_Following_Vendor', { channelId: +props.channelId });
                        // }
                  })
                  handleClose();
            } else {
                  notify('warning', '  Please select or create group to add your wishlist');
            }


      }

      const favToCatalyst = async (fav) => {

            let req = {
                  url: process.env.REACT_APP_EVENTCATALYST_API + 'oauth/token'
            }

            let token = await axios.post('account/eventcatalystToken', req).then(res => {
                  if (res.data?.error) {
                        notify('warning', 'Something went wrong in catalyst');
                  } else {
                        return res.data;
                  }
            }).catch(err => {
                  console.log('err', err)
            })

            let body = {
                  token: token, //JSON.parse(localStorage.getItem('eventcatalyst_token')),
                  url: process.env.REACT_APP_EVENTCATALYST_API + 'api/favourite',
                  body: fav
            }

            await axios.post('account/postEventFavourite', body);
      }

      const handleCloseSpan = () => {
            setGroupSelected(false);
            setGroupName(null);
      }

      const handleChangeSelect = async (input, value) => {
            let isAvailable = 1;

            group.map(x => {
                  if (x.name == value.name)
                        isAvailable = 0;
            })

            if (isAvailable && value.name) {
                  await axios.post('favorite/group/', { groupName: value.name }).then(res => {
                        console.log("favor:", res);
                        group.push({ id: res.data.status, name: value.name, count: 0 });
                        setGroup(group);
                        groupName = {
                              id: res.data.status,
                              name: value.name,
                              count: 0
                        }
                        setGroupName(groupName);
                        console.log('groupName', groupName);
                  });
            } else {
                  setGroupName(value);
            }

            setGroupSelected(true);
      }



      return (
            <div>
                  {/* <Button  variant="outlined" color="primary" onClick={handleClickOpen}>
      open popup
      </Button> */}
                  <NotificationAlert ref={notificationAlert} />
                  <div id='eye' className="editeye text-center pointer" onClick={handleClickOpen}>
                        <i class="fas fa-heart heart" style={{ color: isFavorite ? 'red' : 'gray' }} aria-hidden="true"></i>
                  </div>
                  <UncontrolledTooltip target='eye' placement='bottom'>
                        Add favourites
                  </UncontrolledTooltip>
                  <Modal
                        className="modal-dialog-centered"
                        isOpen={open} >
                        <div className="modal-header">
                              <h3 className="text-center">Add to Favourite</h3>
                              <button
                                    aria-label="Close"
                                    className="close"
                                    data-dismiss="modal"
                                    type="button"
                                    onClick={handleClose}
                              >
                                    <span aria-hidden={true}>×</span>
                              </button>
                        </div>
                        <div className="modal-body fav-body">
                              {/* <div className="row">
<div className="col-6 d-flex align-items-center">
<TextField id="outlined-basic" label="Brand Name" variant="outlined" />
</div>
<div className="col-6">
<ProfileUploader img='https://d35w8j22j5uly8.cloudfront.net/document/brand/KVw8QS0n_1545236009520_1545236013342.png'/>
</div>
</div> */}
                              <p>You can create your own lists of favourites to organise your work. Simply type the list name in the search box below. If the name is not found you can create a new list and save it. Once done you can access your favourite lists from the <i class="fas fa-heart fav"></i> icon on the top of the page</p>
                              {groupSelected && (<div>
                                    <Row>
                                          <Col lg="3">
                                                <div className="fav-cl" onClick={handleCloseSpan}>X</div>
                                                <Badge className="B-prod mb-3 fav-list w-80">{groupName.name}</Badge>
                                                <div className="fav-count">
                                                      <span>{groupName.count}</span>
                                                </div>
                                          </Col>
                                    </Row>
                              </div>)}

                              {!groupSelected && (<div className="col-lg-12 fav-ser">
                                    <div className="form-group">
                                          <NewFieldAutocomplete
                                                id="Favourities"
                                                type="text"
                                                label="Search"
                                                //     value={props.signupData.companyname}
                                                name="Favourities"
                                                options={group}
                                                placeholder="Search"
                                                // error={errors.postalcode}
                                                disableCloseOnSelect
                                                limitTags={2}
                                                //defaultValue={{name:'raja', id:1}}
                                                control={control}
                                                input='Favourities'
                                                value={groupName}
                                                //     errMessage={CompanyError ? 'Company name already taken' : companyName[0]? 'Company name must be minimum 2 characters' : 'Please enter company name'}
                                                //     error={errors.companyName ? errors.companyName : CompanyError}   
                                                handleChangeSelect={handleChangeSelect}
                                          />
                                    </div>
                              </div>)}
                        </div>
                        <div className="modal-footer">
                              <Button
                                    color="secondary"
                                    data-dismiss="modal"
                                    type="button"
                                    onClick={handleClose}
                              >
                                    Close
                              </Button>
                              <Button
                                    className='btn btn-primary'
                                    data-dismiss="modal"
                                    type="button"
                                    onClick={save}
                              >
                                    Save
                              </Button>
                        </div>
                  </Modal>
            </div>
      );
}
export default Favourities;