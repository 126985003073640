import React ,{useEffect, useState} from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {FormInput,FormButton, FormCheckBox} from "_components/FormElements/FormInput";
import {
Button, Modal,
} from "reactstrap";
import { ProfileUploader } from '../ProfileUpload';
import FormControl from '@material-ui/core/FormControl';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
  } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { commonService } from '_services/common.service';
import {Spinner} from 'views/Hoc/Spinner';
import FormHelperText from '@material-ui/core/FormHelperText';

export const ProductAwards=(props)=> {

const useStyles = makeStyles((theme) => ({
    formControl: {
      minWidth:"100%",
    },
    // margin: {
    //   margin: theme.spacing(1),
    // },
  }));

  const classes = useStyles();

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

const [selectedDate, setSelectedDate] = useState(props.data.date? (props.data.date === '1' ? null : props.data.date) : null);
const [image, setImage] = useState(null);
let [loading, setLoading]  = useState(false);
const [state, setState] = useState(props.data.id? props.data : {

  awardImg : '',

})
const [errors, setError] = useState({
  award_img: '',
})
//const { open, closeModalPopup } = props
const [open, setOpen] = React.useState(false);
const theme = useTheme();
const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
useEffect(() => {
 setOpen(true);
}, [])
const handleClickOpen = () => {
setOpen(true);
};

const handleClose = () => {
  setState({});
  setSelectedDate(null);
  props.closeAward();
  setOpen(false);
};

const handleChange = (e) => {
  console.log('prof', e, e.target.files[0]);
  let data = e.target.files[0];
  if(data && data['type'].split('/')[0] === 'image'){
    let file = data;
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          console.log('file', file)
          console.log('reader.result' ,reader.result)
          setImage(
            reader.result
          );
          console.log('image', image)
        }
  }
}

function dataURLtoFile(dataurl, filename) {

  var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), 
      n = bstr.length, 
      u8arr = new Uint8Array(n);
      
  while(n--){
      u8arr[n] = bstr.charCodeAt(n);
  }
  
  return new File([u8arr], filename, {type:mime});
}

const selectImg = async(data)=>{
  if(data.length>0){
    var random = Math.random(); 
    var fileName =random +'.png';
    var file = dataURLtoFile(data, fileName);    
    const formData = new FormData();
    formData.append('file', file);
    setLoading(true);
    const resImagePath = await commonService.fileUploadService(formData, '/mediaupload/upload/awards');
    if(resImagePath){
      if(state?.imageId){
        state.imageId=null;
      }
      if(state?.documentUrl){
        state.documentUrl='';
      }
      setState({...state, awardImg : resImagePath.data.url});
      setLoading(false);
      handleCropClose();
    }
  }
}

const addAwards = () => {
  if(state.awardImg || state?.documentUrl){
    if(props.data.id){
      props.addAwards({
        id : props.data.id,
        // award_details : state.award_details,
        // awardName: state.awardName,
        awardImg: state.awardImg,
        // date : !state.lifeTime ? selectedDate : '1',
        // lifeTime : state.lifeTime,
        imageId:state?.imageId?state?.imageId:null,
        documentUrl:state?.documentUrl?state?.documentUrl:null,
        product_award_id:state?.product_award_id?state?.product_award_id:null,
      });
    }else{
      let index = Math.floor(Math.random()*90000) + 10000;
    props.addAwards({
      id : index,
    //   award_details : state.award_details,
    //   awardName: state.awardName,
      awardImg: state.awardImg,
    //   date : !state.lifeTime ? selectedDate : '1',
    //   lifeTime : state.lifeTime
    });  
    }
    handleClose();
  }
  else{
    errors.award_img= !state.award_details ? 'Please Select Award Image' : '';
    
    setError({...errors})
  }
}

const handleCropClose = () => {
  setImage('');
}

return (
<div>
{/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
      Awards
</Button> */}

<Modal
className="modal-dialog-centered"
isOpen={open} size="lg">
{/* <div className="award-main"> */}
<div className="modal-header">
<h3 className="text-center">Add Your Awards</h3>
<button
aria-label="Close"
className="close"
data-dismiss="modal"
type="button"
onClick={handleClose}
>
<span aria-hidden={true}>×</span>
</button>
</div>
<div className="modal-body">
{loading ? <Spinner/> :  <>
<div className="row">
<div className="col-lg-3 col-md-3 col-5">
<div>
<ProfileUploader img={state?.documentUrl ? state.documentUrl :state?.awardImg? process.env.REACT_APP_CDN_URL_TEMP+state.awardImg : 'http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'} handleChange={handleChange} image={image} selectImg={selectImg} handleCropClose={handleCropClose}/>
</div>
</div>
<div className="col-lg-4 col-md-4 col-6 pt-4 res-padr">
<p>Accepts Jpeg / Jpg / Png Only</p>
<p>(Max size allowed 200 Kb)</p>
</div>
<div className="col-lg-1 col-md-1 col-1 award-add">
<a href="#">
  {/* <i class="fas fa-plus-circle pt-6" ></i> */}
  </a>
</div>
</div>
<span style={{float: 'right', color: '#f44336'}}>{errors.award_img}</span>
</>
}
</div>
<div className="modal-footer">
<Button
color="secondary"
data-dismiss="modal"
type="button"
onClick={handleClose}
>
Close
</Button>
<Button
className='btn btn-primary'
data-dismiss="modal"
type="button"
onClick={addAwards}
>
Add
</Button>
{/* </div> */}
</div>
</Modal>
</div>
);
}
export default ProductAwards;