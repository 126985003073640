import React, {useEffect,useState} from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import {signupschema} from './Validation'; 
import { InputAdornment, IconButton } from "@material-ui/core";
import { NewFieldAutocomplete,FormAutocompleteGrouping } from '_components/FormElements/FormInput';
import { useForm} from "react-hook-form";
import {signupschema1} from './Validation'; 
import {FormInput, FormAutocomplete, FormRadio} from "_components/FormElements/FormInput";
import Step2 from './Signup.step2'


const SignupForm = (props) => {
    
    const onSubmit = data => {    
    if (data) {        
        console.log("data:",data);
    }    
    }
const active=event =>{
    if ((event.target.id=='ven'|| event.target.id=='dis' || event.target.id=='ret')) {
        
    
    document.getElementById('v').style.color='#999b9c';
    document.getElementById('d').style.color='#999b9c';
    document.getElementById('r').style.color="#999b9c";

    if (document.getElementById("ven").classList.contains("img-v-ac")) {
        document.getElementById("ven").classList.remove("img-v-ac");
        // setChannelTypeId(2);
    }
    if (document.getElementById("dis").classList.contains("img-dac")) {
        document.getElementById("dis").classList.remove("img-dac");
        // setChannelTypeId(3);
    }
    if (document.getElementById("ret").classList.contains("img-rac")) {
        document.getElementById("ret").classList.remove("img-rac");
        // setChannelTypeId(4);
    }
    if(event.target.id=='ven'){
        document.getElementById(event.target.id).classList.add('img-v-ac');
        document.getElementById('v').style.color='#3f51b5';
        // setChannelTypeId(2);
    }else if(event.target.id=='dis'){
        document.getElementById(event.target.id).classList.add('img-dac');
        document.getElementById('d').style.color='#3f51b5';
        // setChannelTypeId(3);
    }else if(event.target.id=='ret'){
        document.getElementById(event.target.id).classList.add('img-rac');
        document.getElementById('r').style.color="#3f51b5";
        // setChannelTypeId(4);
    }
}
}
const { register, handleSubmit, control, errors, watch } = useForm({            
    mode:'onBlur',  
    resolver: yupResolver(signupschema1),
}); 


// const { companyList, productGroup } = props.staticValues;
    return (
        <div>
            <div className="container">
            <div className="row">
        <div className="col-lg-12 prof_001">
                <div class="container">
                <div id="pro" class="row prof-row1">
                  <div onClick={active} class="col-sm-4 prof-003">
                  <div id="ven" class="img-v"></div>
                  <span id="v" className="prof-span">VENDOR</span>
                  </div>
                  <div onClick={active}  class="col-sm-4 prof-003">
                  <div id="dis" class="img-d"></div>
                  <span id="d" className="prof-span">DISTRIBUTOR</span>
                  </div>
                  <div onClick={active} class="col-sm-4 prof-003">
                  <div id="ret" class="img-r"></div>
                  <span id="r" className="prof-span">RETAILER</span>
                  </div>
        </div>    
        </div>
            </div>
            </div>
{/* ------------------------------------------------------------  step2 --------------------------------------------------- */}
        <div className="row">
        <div className="col-lg-12">
        <div className="form-group">
        <FormInput
            id="companyemail" 
            type="email" 
            label="Enter your Email" 
            name="companyemail" 
            //register={register}
            //value={watchEmail}
            //onChange={handleChange('email')}
           // error={errors.companyemail ? errors.companyemail : checkMailId ? {message:"Email Id already taken", required: true} : ''}
            
            //defaultValue={email}
         />
        </div>
        </div>
            <div className="col-lg-6">
            <div className="form-group">
            <FormInput
                    id="password" 
                    //type = {showPassword ? "text" : "password"}  
                    label="Password" 
                    name="password" 
                    //error={errState.password!=''?{message:errState.password,type:'required'}:errors.password}
                    //value={watchPassword}
                    //onChange={handleChange('password')}
                    //register={register}
                    //InputProps={{}}
                    //defaultValue={password}

             />
             </div>
            </div>
            <div className="col-lg-6">
            <div className="form-group">    
            <FormInput
                id="rePassword" 
                //type = {showPassword1 ? "text" : "password"} 
                label="Confirm Password" 
                name="rePassword" 
                //error={errState.rePassword!=''?{message:errState.rePassword,type:'required'}:errors.rePassword}
                //value={watchRepassword}
                //onChange={handleChange('rePassword')}
                //register={register}
                //defaultValue={rePassword}
                hintText="Password"
                 InputProps={{
                     endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        //onClick={handleClickShowPassword1}
                        // onMouseDown={handleMouseDownPassword}
                      >
                        {/* {showPassword1 ?  <Visibility /> : <VisibilityOff />} */}
                      </IconButton>
                    </InputAdornment>
                  )
                     }}
             />
            </div> 
        </div>
        </div>
        {/* ------------------------------------------------------------  step3 --------------------------------------------------- */}
        
        </div>
        </div>
    );
};

export default SignupForm;