
export function event(state = {}, action) {

    switch (action.type) {
      case 'EVENT':
      {
        const stateValue = action.data;
        return {          
            ...state,eventDetails:stateValue
        };
      }

      case 'EVENT_PACK':
        {
          const eventPack = action.data;
          return {
            ...state, eventPackage: eventPack
          }
        }

        case 'EVENT_PAYMENT':
          {
            const payment = action.data;
            return {
              ...state, eventPayment: payment
            }
          }

      case 'EVENT_USERS':
          {
              const users = action.data.contacts;      
              const additionalMember = action.data.additionalMember;
              const additionalMemberNew = action.data.additionalMemberNew; 
              const marketingOptions = action.data.marketingOptions;
              const packageOptions = action.data.packageOptions;
              const marketingOptionsPrice = action.data.marketingOptionsPrice;
              const packageOptionsPrice = action.data.packageOptionsPrice;

              return {              
                ...state,eventContacts:users, additionalMember:additionalMember, additionalMemberNew: additionalMemberNew, marketingOptions, packageOptions, marketingOptionsPrice, packageOptionsPrice
              };
            }
      case 'EVENT_AMT':
        {
          const amt = action.data;
          return {
            ...state, eventPrice: amt
          }
        }
        
      default:
        return state
    }
  }