import React,{useState,useEffect} from "react";
import {Container,Row,Button,Col,CardBody,CardHeader,Card,Nav, NavItem, NavLink,DropdownToggle,DropdownMenu,DropdownItem,UncontrolledDropdown,Badge} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader"
import Title from "views/Hoc/Dialog/Title";
import Product from "../Channel-Listing/Product-List";
import { SelectAutocomplete,FormAutocomplete } from  '_components/FormElements/FormInput';
import { useForm} from "react-hook-form";
import {history} from '_helpers'
import Contact from "../Channel-Listing/Contact-List";
import { Channel } from "../Channel-Listing";
export const Search =(props)=> {
  const {  Tabname, Type, activeTab, filterValueState,search,desc,listing, openConnectPopUp, contactList } = props;
  const [tabs,setTab] = useState(activeTab);
  let [name,setName] = useState();
  const [sort, setSort] = React.useState(true);
  const { control } = useForm({ mode:'onBlur' });

  useEffect(() => {         
    console.log("searchl:",Tabname);   
    name=Tabname;
    setName(Tabname);  
    console.log("searchl:",name);  
},[props.Tabname] )

return (
 <>
<SimpleHeader location={props.match}/>
<div id='Search List' className="main-content">
<Title title={Tabname} desc={desc} />
<Container fluid className="mt-4">
<Nav tabs className="list-tab">
    <NavItem className="list-main1">
        <NavLink className="active">
          
          <Row className="text-uppercase">
            <Col onClick={()=>setTab(1)}   xs="12" className=" mt-2 sub-a text-center">
              <a style={{cursor:'pointer'}}>
                <h3 className="mb-0">{name ?name:'Companies'}</h3>
                <p class="count m-0">(12)</p>
              </a>
            </Col> 
          </Row>
        </NavLink>
      </NavItem>
      {Type !=="Product" &&<>
      <NavItem className="list-adtab">
            <NavLink>
              <h4>VENDOR</h4><span>(3)</span>
              </NavLink>
            </NavItem>
            <NavItem className="list-adtab">
            <NavLink>
              <h4>DISTRIBUTOR</h4><span>(3)</span>
              </NavLink>
            </NavItem>
            <NavItem className="list-adtab">
            <NavLink>
              <h4>RETAILER</h4><span>(3)</span>
              </NavLink>
            </NavItem></>
}
      <NavItem className="pl-2 right-search1">
    <SelectAutocomplete
                            id="search"
                            type="text"                
                            label="search"
                            name="search"
                            //options={searchState.dropdownList}
                            placeholder="search..."
                            disableCloseOnSelect
                            // defaultValue={searchState.search?searchState.search[0]:null}
                            control={control}                         
                            input='search'
                            //value={searchState.search}
                            //handleChangeSelect={handleChangeSelect}
                        /> 
            </NavItem>
      <NavItem className="right-sort1">
                <UncontrolledDropdown className="p-0" group>
                  <DropdownToggle caret  className="p-2">
                    <h3
                      style={{ display: "inline-block" }}
                      className="font-weight-bold m-0"
                    >
                      <i className="fa fa-bars  mr-2" aria-hidden="true"></i>
                     {sort}
                    </h3>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                    
                      onClick={() => setSort(true)}
                    >
                     A-Z
                    </DropdownItem>
                    <DropdownItem
                    
                      onClick={() => setSort(false)}
                    >
                     Z-A
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
            </NavItem>   
    </Nav>
    <Card>
    <CardBody>
    {Type=="Company" && <Channel  activeTab={activeTab} filterValueState={filterValueState} search ={search}/> }
   {Type=="Product" &&
    <Product/>}
   {Type=="Contact" && <Contact/>
   }
    </CardBody>
    </Card>

        </Container>
</div>
</>
);
}


export default Search;

