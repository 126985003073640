import React, { useState, useEffect } from 'react';
import { Card, CardBody } from "reactstrap";
import { FormAutocomplete } from "_components/FormElements/FormInput";
import { useForm} from "react-hook-form";
import { ListGroupItem, ListGroup } from 'reactstrap';
import { commonService } from '_services/common.service';
import Popper from '@material-ui/core/Popper';


const CompanyListingFilters = (props) => {
  const { activeTab, handleChangeSelect, filterValueState, resetFilterValue } = props;
  const [ filterOptionState, setFilterOption] = useState({SpecialityOption:[], Brand_Option:[], Retailer_Profile_Option:[], Company_Origin_Option:[], channelTypeOption:null });
  const user = JSON.parse(localStorage.getItem('CHuser'));
  const { control } = useForm({ mode:'onBlur' });
    useEffect(() => {
      if(activeTab)
      getSearchAggregation();
  }, [activeTab])
  const getSearchAggregation=async()=>{
    resetFilterValue();
    resetFilterOptions();
    let data = await commonService.getServiceWithParams({
      channelId:user.channelId,
      contactId:user.contactId,
      channelTypeId:user.channelTypeId
    },'/channel/getSearchAggregation/'+activeTab);
    if(data){
      let options = data.data;
      if(options){
     filterOptionState.SpecialityOption= options.specialIds;
     filterOptionState.Brand_Option=options.brandIds;
     filterOptionState.Retailer_Profile_Option=options.retailIds.length>0?options.retailIds.filter(x=> x.name.trim() !== '' && x.name):[];
     filterOptionState.Company_Origin_Option=options.countryIds;
     setFilterOption({...filterOptionState});
     }
     }
    console.log('filterOptionState', filterOptionState);
  }

  const resetFilterOptions=()=>{
    filterOptionState.SpecialityOption=[];
    filterOptionState.Brand_Option=[];
    filterOptionState.Retailer_Profile_Option=[];
    filterOptionState.Company_Origin_Option=[];
    filterOptionState.channelTypeOption=channelTypeList.filter(x=>x.id !==user.channelTypeId);
    setFilterOption({...filterOptionState});
  }

  const PopperMy = function (props) {
    return (<Popper {...props} style={{ width: '300px' }} placement='bottom-start' />)
  }

  const style = { height: '20rem' };
  

  return (

    <Card>
    
      <CardBody className='fav-ser1 p-2'>
        <ListGroup>
        {activeTab!=='8' && <ListGroupItem style = {style}>
        <div className=" text-center mt-2">
                    <div className="form-group">
                      <FormAutocomplete
                        id="Speciality"
                        name="Speciality"
                        label="Speciality"
                        options={filterOptionState.SpecialityOption}
                        placeholder="Select Speciality"
                        multiple
                        disableCloseOnSelect
                        limitTags={1}
                        control={control}                         
                        input='Speciality'
                        value={ filterValueState.Speciality }
                        handleChangeSelect={handleChangeSelect}
                        open={true}
                        popupIcon=''
                        disablePortal={true}
                        size='small'
                        ListboxProps={{ style: { maxHeight: '8rem' } }}
                        />
                    </div>
                  </div>
                  </ListGroupItem>}

                  {activeTab!=='10' && <ListGroupItem style = {style}>
                  <div className=" text-center mt-2">
                    <div className="form-group">
                      <FormAutocomplete
                        id="Brand"
                        name="Brand"
                        label="Brand"
                        options={filterOptionState.Brand_Option}
                        placeholder="Select Brand"
                        multiple
                        disableCloseOnSelect
                        limitTags={1}
                        control={control}                         
                        input='Brand'
                        value={ filterValueState.Brand }
                        handleChangeSelect={handleChangeSelect}
                        open={true}
                        popupIcon=''
                        disablePortal={true}
                        size='small'
                        ListboxProps={{ style: { maxHeight: '8rem' } }}
                        />
                    </div>
                  </div>
                  </ListGroupItem>}
                    
                  {activeTab !=='10' && <ListGroupItem style = {style}>
                  <div className=" text-center mt-2">
                    <div className="form-group">
                      <FormAutocomplete
                        id="Retailer_Profile"
                        name="Retailer_Profile"
                        label="Retailer Customer Profile"
                        options={filterOptionState.Retailer_Profile_Option}
                        placeholder="Select Retailer Customer Profile"
                        multiple
                        disableCloseOnSelect
                        limitTags={1}
                        control={control}                         
                        input='Retailer_Profile'
                        value={ filterValueState.Retailer_Profile }
                        handleChangeSelect={handleChangeSelect}
                        open={true}
                        popupIcon=''
                        disablePortal={true}
                        size='small'
                        ListboxProps={{ style: { maxHeight: '8rem' } }}
                        />
                    </div>
                  </div>
                  </ListGroupItem>}
                  
                  <ListGroupItem style = {style}>
                  <div className=" text-center mt-2">
                    <div className="form-group">
                      <FormAutocomplete
                        id="Company_Origin"
                        name="Company_Origin"
                        label="Company Origin"
                        options={filterOptionState.Company_Origin_Option}
                        placeholder="Select Company Origin"
                        multiple
                        disableCloseOnSelect
                        limitTags={1}
                        control={control}                         
                        input='Company_Origin'
                        value={ filterValueState.Company_Origin }
                        handleChangeSelect={handleChangeSelect}
                        open={true}
                        popupIcon=''
                        disablePortal={true}
                        size='small'
                        ListboxProps={{ style: { maxHeight: '8rem' } }}
                        style = {{ height: '3rem' }}
                        
                        />
                    </div>
                  </div>

                  </ListGroupItem>

                  {(activeTab !=='4' && activeTab !=='8' && activeTab !=='9' && activeTab !=='10') &&<ListGroupItem style = {style}>
                  <div className=" text-center mt-2">
                    <div className="form-group">
                      <FormAutocomplete
                        id="channel_type"
                        name="channel_type"
                        label="Channel Type"
                      
                        options={filterOptionState.channelTypeOption}
                        placeholder="Select Channel Type"
                        // multiple  
                        disableCloseOnSelect
                        limitTags={1}
                        control={control}
                        input='channel_type'
                        value={ filterValueState.typeId }
                        handleChangeSelect={handleChangeSelect}
                        popupIcon=''
                        disablePortal={true}
                        ListboxProps={{ style: { maxHeight: '8rem' } }}
                        size='small'
                        />
                   
                    </div>
                  </div>

                  </ListGroupItem>}

                  <ListGroupItem style = {style}>

                  <div className=" text-center mt-2">
                    <div className="form-group">
                      <FormAutocomplete
                        id="Annual_Turnover"
                        name="Annual_Turnover"
                        label=" Annual Turnover"
                      
                        options={AnnuvalTurnOver}
                        placeholder="Sample Annual Turnover"
                        // multiple
                        disableCloseOnSelect
                        limitTags={1}
                        control={control}                         
                        input='Annual_Turnover'
                        value={ filterValueState.Annual_Turnover }
                        handleChangeSelect={handleChangeSelect}
                        open={true}
                        popupIcon=''
                        disablePortal={true}
                        size='small'
                        ListboxProps={{ style: { maxHeight: '15rem', fontSize: '1rem',  } }}
                        PopperComponent={PopperMy}
                        limitTags
                        />
                   
                    </div>
                  </div>

                  </ListGroupItem>

                
        </ListGroup>
      </CardBody>
      
      
    </Card>
  );
};



const AnnuvalTurnOver = [
  { id: '<1M€', name: '<1M€' },
  { id: '1-10M€', name: '1-10M€' },
  { id: '10-100M€', name: '10-100M€' },
  { id: '>100M€', name: '>100M€' },
]
const channelTypeList = [
  {
    id: '2',
    type: 'Vendor',
    name: "VENDOR"
  },
  {
    id: '3',
    type: 'Distributor',
    name: "DISTRIBUTOR"
  },
  {
    id: '4',
    type: 'Retailer',
    name: "RETAILER"
  }
];
export default CompanyListingFilters;