import React from 'react';
import { Link} from 'react-router-dom';
import Logo from 'views/pages/LoginPage/login-logo.js';
import axios from 'axios';
import { Card, CardBody, Container, Col, Row, Button } from "reactstrap";

import { useSelector } from 'react-redux';
import { history } from '_helpers';
// reactstrap components
export const EventPending = () => {


  let eventName = useSelector(state => state.event)?.eventDetails?.event_name;

  console.log("eveeee", eventName)

  return (
    <div className="main-content">
      {/* <Logo/> */}
      {/* <div className="head-logo">
          <Link to="/" >
        <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  className="navImg" alt="ch-logo" src={require('assets/img/brand/ch-logo.png')}/>
          </Link></div> */}
    <div className="header py-4 ">


    <Container className="mt-4 col-lg-9 col-md-9 col-sm-12  " fluid>

<Card className="p-3">
  <h1 className="ml-3 mt-3">Success</h1><hr className="m-0 ml-3 paycarhr" style={{ width: "14%", borderBottom:"2px solid #2c9db7" }} />
  <h1 className="text-center"><i style={{ fontSize: '3rem', color: '#2c9db7' }} class="fa fa-check-circle" aria-hidden="true"></i>
  </h1>
  <Row className="justify-content-center pb-2">

    <Col lg="8" >
      <CardBody style={{textalign:"center"}}>
        <p>You have successfully applied to  {eventName? eventName : ''}. Our team will review it and come back to you shortly.</p>
          </CardBody>
    </Col>
  </Row>
  <div className="text-center">
  <button  className="btn btn-primary text-center" variant="contained" color="primary" onClick = {() => history.push('/admin/events')}>
            Click Here to Event Page
          </button>
  </div>


</Card>
</Container>

      {/* <div className="container">
        <div className="header-body text-center mb-7">
          <div className="row justify-content-center">
            <div className="col-xl-5 col-lg-6 col-md-8 px-5">
              <h1 className="text-white">Create Your Account</h1>
              <p className="text-lead text-white">
              You are only few minutes away of joining the largest community of consumer tech professionals in the World
              </p>
          
            </div>
          </div>
        </div>
      </div> */}
      {/* <div className="separator separator-bottom separator-skew zindex-100">
        <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <polygon className="fill-default" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>
      <div className="container  pb-5">
    <div className="row justify-content-center">
    <div className="col-lg-10 col-md-12">
    <div className="card bg-secondary border-0 mb-0">  
  
    <div className="card-body px-lg-5 py-lg-5">     

    <div className="header-body event-pending text-center mb-4">
          <div className="row justify-content-center">
            <div className="col-xl-12 col-lg-10 col-md-12">
            <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  src={require("assets/img/brand/wait.png")}/> 
              <h1 className="text-black text-lead text-marg">Successfully Registered for event {eventName? eventName : ''}.</h1>
              <h1 className="text-black text-lead">Our team will send confirmation details to your email.</h1>
            </div>
          </div>
          <div className="text-center mt-2" >
          <button  className="btn btn-primary text-center" variant="contained" color="primary" onClick = {() => history.push('/admin/events')}>
            Click Here to Event Page
          </button></div>
    
          
        </div>




                 </div>
             
              </div>
              </div>
              </div>
              </div> */}
    </div>

   
              </div> 
              
  );
}
export default EventPending;