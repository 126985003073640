import React,{useState} from "react";
import PerfectScrollbar from 'react-perfect-scrollbar'
import { history } from '_helpers';
import {
    Container,
    Row,
    Col,CardBody,Card,ListGroupItem,
    ListGroup,Badge
    } from "reactstrap";
import image1 from "assets/img/icons/404img.jpg";
const PageNotFound = (props) => { 
return(
    <div>
        <Container fluid>
        <Card className="pg-card">
               <Row>
            <Col xs="12">       
        <div className="col-lg-5 col-md-5 col-sm-5 col-xs-12 pg-content1">
            <figure className="pgimg">
                <img src={image1} alt="" title="404 page no found" />
            </figure>
        </div>
        <div className="col-lg-5 col-md-5 col-sm-5 col-xs-12 pg-content">
<h1>Whoops,nothing to see here.</h1>
<p>sorry,we couldn't find what you were looking for or the page no longer exists.Perhaps you can return back to HomePage and see if you can find what you are looking for.</p>
      <a onClick={e => history.push('/admin/dashboard')} className="pg-a">Back to Home</a>
        </div>
        </Col>  
        </Row>
        </Card>
        </Container>
    </div>
);
 }

export default PageNotFound;
