import React, { useEffect, useState, useMemo } from "react";
import { Card,CardBody,CardImg,CardTitle, Container,Col,Row,CardText,Button,className} from "reactstrap";
import { commonService } from '_services/common.service';
import { history } from '../../../_helpers';
import {ToastsContainer, ToastsStore} from 'react-toasts';
import { Link } from "react-router-dom";
// import img from '../../../assets/img/guestpage/';
const Guestevent = (props) => {
  

  //  console.log('img',img);
return (
        <div className="main-content" id="Guestevent">
<div className="mt-5 container-fluid ">
  <div className="text-left ml-5">
<h1 className="ProductTitle d-inline-block mr-3">Don’t miss our next events for IT & CE Products</h1></div>
<Card className="bgnone mt-4">
<Row>
  <Col xl="6" lg="6">
  <Card className="pt-4 geevntcard">
    <div className="geimg text-center">
                <CardImg
                  alt="..."
                  src='https://channelhub.net/wp-content/uploads/2020/07/RETAIL_CONNECT_ONE_TO_ONE_EMEA.png'
                  top
                  onClick={()=>{
                    window.open("https://www.retailconnect1to1.com",'_blank');
                    
                  }}className="ccconect"
                />
</div>
                <CardBody className="text-center geeveti mt-4 mb-4">
            
                  <p className="text-muted mb-4">
               <b>     Retail Connect One-to-One</b> event combines pre-scheduled one-to-one meetings, Pitch-your-Product session, Conferences and Networking for B2C Consumer Electronic Products.Don’t miss the 4th Edition of the upcoming Event. 
               </p>
            
                  <a target="_blank" href="retailconnect1to1.com" className="mt-3">
                <button className="btn btn-primary btn-md ml-2" onClick={()=>{
                         window.open(" https://www.retailconnect1to1.com",'_blank');
                       }}> View More</button>  </a>
                </CardBody>
              </Card>
  </Col>
  <Col xl="6" lg="6">
  <Card className="pt-4 geevntcard">
    <div className="geimg text-center">
                <CardImg className="navImg"
                onClick={()=>{
                  window.open("https://www.channelconnect1to1.com",'_blank');
                }} 
                className="ccconect"
                alt="ch-logo" src={require("../../../assets/img/guestpage/CHANNEL_CONNECT_ONE_TO_ONE_EMEA.png")}/>
               </div>
                <CardBody className="text-center geeveti mt-4 mb-4">
            
                  <p className="text-muted mb-4">
                  <b>  Channel Connect One-to-One</b> is our latest addition to our online events. And this time we are focusing on B2B IT Products and Solutions. The event will bring together international IT vendors with selected IT distributors from around EMEA region.


                  </p>
            
                  <a target="_blank" href="" className="mt-3">
                 <button className="btn btn-primary btn-md ml-2" onClick={()=>{
                         window.open("https://www.channelconnect1to1.com",'_blank');
                       }}> View More</button>  </a>
                </CardBody>
              </Card>
  </Col>
  </Row>  
</Card>

      </div>
        
      </div>
    );
};



export default Guestevent;