import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { Spinner } from "views/Hoc/Spinner";
import { history } from "_helpers";
import { ToastsContainer, ToastsStore } from 'react-toasts';

const LinkedInCallback = () => {

    const [loading, setLoading] = useState(true);

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const authorizationCode = params.get('code');

    useEffect(() => {
        // Handle the authorization code here (e.g., send it to your server to exchange for an access token).
        console.log('Authorization Code:', authorizationCode);
        posting(authorizationCode);

    }, [authorizationCode]);

    const posting = async (authCode) => {

        // setLoading(true);
        // console.log("authCode:",authCode)
        let accessToken = await axios.post('channel/linkedin', { authCode: authCode })

            .then((response) => {

                console.log('Access Token:', response.data.access_token);
                return response.data.access_token;
            })
            .catch((error) => {
                console.error('Error:', error);
            });

        if (accessToken) {

            let asset; let uploadUrl;

            axios.post('channel/getmemberId', { accessToken: accessToken })

                .then(async (response) => {

                    // console.log("member:", response.data['sub']);

                    let memberId = response.data['sub'];
                    await axios.post('channel/registerimage', { accessToken: accessToken, memberId: memberId })

                        .then((response) => {
                            let eventId = localStorage.getItem("Linked_Share_EventId");
                            asset = response.data['value'].asset;
                            uploadUrl = response.data['value'].uploadMechanism['com.linkedin.digitalmedia.uploading.MediaUploadHttpRequest'].uploadUrl;

                            const postImage = localStorage.getItem("Linked_shareUrl");

                            // console.log("register:",asset,uploadUrl,postImage);

                            axios.post('channel/uploadimage', { accessToken: accessToken, uploadUrl: uploadUrl, postImageUrl: process.env.REACT_APP_CDN_URL_TEMP + postImage })

                                .then((response) => {
                                    // console.log("upload:",response);
                                    axios.post('channel/postlinkedin', { accessToken: accessToken, asset: asset, memberId: memberId, eventId: eventId })

                                        .then((response) => {

                                            let eventId = localStorage.getItem("Linked_Share_EventId");

                                            axios.post('channel/sharestatus', { eventId: eventId })


                                            /*setTimeout(() => {
                                                setLoading(false);
                                                ToastsStore.success('Successfully Posted into your linkedin page')
                                                history.push('/admin/events');
                                            }, 15000)*/

                                            // console.log("shared:",response);
                                        })
                                        .catch((error) => {
                                            console.error('Posting Error:', error);
                                        });
                                })
                                .catch((error) => {
                                    console.error('Upload Error:', error);
                                });

                        })
                        .catch((error) => {
                            console.error('Register Error:', error);
                        });

                })

                .catch((error) => {
                    console.error('Member Error:', error);
                });

            setTimeout(() => {
                setLoading(false);
                ToastsStore.success('Successfully Posted into your linkedin page')
                history.push('/admin/events');
            }, 20000)

        }

    }

    return (
        <>
            <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore} />
            {loading ? <Spinner /> : <><p>Please Wait few Seconds ....</p></>}
        </>
    )
};

export default LinkedInCallback;