import { authHeader } from '../_helpers';

import axios from 'axios';

export const dashboardService = {
    dashboard_count,  
    company_profile_save,
    user_profile_save,
    addFirstProduct,
    update_company_profile_save,
    updateTradeInfo,
    updateSettingInfo,
};

function dashboard_count() {
    return axios.get('notification/getDashboardcount').then(res =>{         
          return res.data;
        });
}

async function company_profile_save(company) {
  
  console.log("service:",company);
    return await axios.post('/setting/company',company).then(res =>{
         console.log("company:",company);
          return 'ok';
        });
}

async function update_company_profile_save(company) {
  
  console.log("service:",company);
    return await axios.post('/setting/updateCompany',company).then(res =>{
         console.log("company:",company);
          return 'ok';
        });
}


async function user_profile_save(user) {  
  
  console.log("service:",user);
    return await axios.post('/setting/userprofile',user).then(res =>{
         console.log("company:",user);
          return 'ok';
        });
}

async function addFirstProduct(product,ratingId) {
  // let userData = JSON.parse(localStorage.getItem('CHuser'))
  // let userId=userData?.userId;
  // let channelId=userData?.channelId;
  // let contactId=userData?.contactId;

  console.log("service:",product);
    return await axios.post('/Product/Product/'+ratingId, product).then(res =>{
         //Mail Code
            // let maildata={
            //   userId:+res.data.data['updatedBy'],                      
            // }
            // axios.post('mailerservice/Add_Product_Mail',maildata);

          return 'ok';
        });
}

async function updateTradeInfo(product) {
  console.log("service:",product);
    return await axios.post('/setting/updateTradeInfo',product).then(res =>{
         //console.log("company:",user);
          return 'ok';
        });
}

async function updateSettingInfo(data) {
  //console.log("service:",data);
    return await axios.post('/setting/settings',data).then(res =>{
         //console.log("company:",user);
          return 'ok';
        });
}




