
export function channelListing(state = {}, action) {

  switch (action.type) {
    case 'TABCOUNT':
      {
        const tab_count = action.data;
        return {
          ...state, tab_count: tab_count
        };
      }
    case 'SORTING':
      {
        const sorting = action.data;
        return {
          ...state, sorting: sorting
        };
      }
    case 'CHANNELS':
      {
        const channels = action.data;
        return {
          ...state, channel: channels
        };
      }
    case 'PRODUCTS':
      {
        const products = action.data;
        return {
          ...state, product: products
        };
      }


    default:
      return state
  }
}