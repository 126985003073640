import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import { Row, Col, CardBody, Card, CardImg, CardHeader, Button, Modal, Table } from "reactstrap";
import axios from 'axios';
import { Spinner } from "views/Hoc/Spinner";
import { FormCheckBox } from "_components/FormElements/FormInput";
import ToolkitProvider from "react-bootstrap-table2-toolkit";


const MySchedule = ({ eventId,eventType }) => {
    const [value, setValue] = React.useState("1");
    // const [rows, setRows] = React.useState([]);
    // let [agenda, setAgenda] = useState([]);

    const [eventReqId, setEventReqId] = React.useState();
    const [loading, setLoading] = React.useState(true);
    const [openContact, setOpenContact] = React.useState(false);
    const [meetingContacts, setMeetingContacts] = React.useState([]);

    const [schedule, setSchedule] = React.useState(["Item1", "Item2", "Item3"]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        getMySchedule();

    }, [])


    const getZoomLink = async () => {
        await axios.get('channel/getZoomMeetingUrl')
            .then(res => {
                window.open(res.data, '_blank')
            });

    }

    const handleClose = () => {
        setOpenContact(false);
    };

    const openRequest = async (participant) => {
        let contacts = await axios.post('channel/getMeetingContacts', { participants: participant })
            .then(res => {
                return res.data;
            }).catch(err => console.log("getMeetingContacts:", err));

        // meetingContacts = [...contacts];
        console.log('meetingContacts:', contacts)
        setMeetingContacts(contacts);
        setOpenContact(true);
    }




    const getMySchedule = async () => {
        let agenda = [];
        await axios.get('channel/getmyschedule/' + eventId)
            .then(res => {


                var groupBy = function (xs, key) {
                    return xs.reduce(function (rv, x) {
                        (rv[x[key]] = rv[x[key]] || []).push(x);
                        return rv;
                    }, {});
                };
                var groubedByTeam = groupBy(res.data, 'sdate')

                const arrayOfObj = Object.entries(groubedByTeam).map((e) => (e[1]));


                let myschedule = [];
                arrayOfObj.map(x => {
                    myschedule.push({ date: x[0].sdate, list: x });
                })
                console.log("myschedule:", myschedule)

                setSchedule(myschedule);
                setLoading(false)
                //changed @19/8
                // setAgenda(arrayOfObj[0]);


                //setRows(agenda);            
                // return agenda;
            }).catch(err => console.log("getsubject:", err));

    }

    const getAgenda = (list) => {
        return (<>

            {list.map(x => <>



                <Card>
                    <CardHeader>
                        <Row>
                            <Col lg="2">
                                <p>{x.stime}</p>
                                <p>Your local time</p>
                            </Col>
                            <Col lg="2">
                                <p>{x.stime}</p>
                                <p>Event time</p>
                            </Col>


                            <Col lg="3">
                                {openContact &&
                                    <Modal className="modal-dialog-centered" fade={false} isOpen={openContact} size="xg" style={{ minWidth: 900, width: '30%' }}>
                                        <div className="modal-header">
                                            Contact details

                                            <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={handleClose}>
                                                <span aria-hidden={true}>×</span>
                                            </button>
                                        </div>

                                        <div className="modal-body">
                                            <div><strong>{x.function}</strong></div>
                                            <div><strong>{meetingContacts[0].companyName}</strong></div>

                                            <div className="container">

                                                {meetingContacts.length && <>
                                                  
                                                    <Table>
                                                        <thead>
                                                            <tr>
                                                                <th>NAME</th><th>JOB TITLE</th><th>EMAIL</th><th>PHONE</th>




                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {meetingContacts.map(item => {
                                                                return <tr>
                                                                    <td>{item.name}</td>
                                                                    <td>{item.jobTitle}</td>
                                                                    <td>{item.email}</td>
                                                                    <td>{item.mobileNo}</td>
                                                                </tr>
                                                            })}


                                                        </tbody>



                                                    </Table>
                                                </>
                                                }

                                            </div>
                                        </div>
                                    </Modal>}
                                <div><strong>{x.function}</strong></div>
                                {((x.isMeet == 1 && (x.participants || x.receivers)) || x.isMeet == 0) &&
                                    <div style={{ marginTop: "15px" }}>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            {x.isMeet == 1  ? <>

                                                <Button onClick={() => { openRequest(x.participants) }}>Contact details</Button>
                                                {eventType==2 &&
                                                <i class="fas fa-video" onClick={() => getZoomLink()} style={{ fontSize: "28px", color: "#0e72ed", margin: "5px 30px 0 10px" }} ></i>}
                                            </> :
                                            eventType==2 &&
                                                <i class="fas fa-video" onClick={() => window.open(x.zoomUrl, '_blank')} style={{ fontSize: "28px", color: "#0e72ed", margin: "5px 30px 0 10px" }} ></i>}
                                            <FormCheckBox
                                                id=""
                                                type="checkbox"
                                                label="No Show"
                                            //label1={{textAlign:"justify"}}
                                            //register={register}

                                            />
                                        </div>
                                        <div style={{ marginTop: "10px" }}>
                                            Rate Your Meeting : * * * * *
                                        </div>
                                    </div>}



                            </Col>
                            {/* <Col lg="3">
                        <img  alt="ch-logo" src={require("../../../assets/img/common/zoom.png")} />
                        </Col> */}

                            {/* <Col lg="2">

                        </Col> */}
                        </Row>
                    </CardHeader>


                </Card>
            </>


            )}
        </>)

    };

    return (
        // <Box sx={{ width: "100%", typography: "body1" }}>
        <div className="main-content">



            {loading ? <Spinner /> :
                <Card>
                    <h3 className="m-3">Your Event Agenda</h3>
                    <CardHeader>Please rate your meeting from 1 to 4 stars(4 stars being the highest score)
                        The 'NO-SHOW' button allows you to inform us if your meeting was absent or unresponsive.</CardHeader>

                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <TabList onChange={handleChange} textColor="primary" indicatorColor="primary">
                                {schedule.map((x) => (
                                    <Tab label={x.date} value={x.date} />
                                ))}
                            </TabList>
                        </Box>

                        {schedule.map((x) => (
                            <TabPanel value={x.date}>{getAgenda(x.list)}</TabPanel>
                        ))}

                    </TabContext>
                </Card>}

        </div>
    );
}

export default MySchedule;
