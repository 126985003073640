import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";

export const Tourstep2 = ({ closeModal, tourData, scrol }) => {
  const [currentStep, setCurrentStep] = useState(0);
  function nextStep() {
    setCurrentStep((prev) => prev + 1);
  }
  function previousStep() {
    setCurrentStep((prev) => prev - 1);
  }

  const getStykle = (fieldId) => {
    let elem = document.querySelector(`[data-field=${fieldId}]`);
    let rect = elem.getBoundingClientRect();
    return { position: "absolute", top: rect.bottom, left: rect.left - 70};
  };

  return (
    <div
      className={`cd-more-info ${tourData[currentStep].target}`}
      style={getStykle(tourData[currentStep].target)}
    >
      <div className="step-no">
        <h4>{tourData[currentStep].title}</h4>

        <i className="fa fa-close page-btn">
          <CloseIcon onClick={closeModal} />
        </i>
      </div>
      <div className="para">{tourData[currentStep].description}</div>
      <div className="page-no">
        <p>
          {currentStep + 1} of {tourData.length}
        </p>
        <div className="page-steps">
          {currentStep >= 1 && (
            <p className="page-btn" onClick={previousStep}>
              <i className="fa fa-angle-double-left"></i>
              Previous
            </p>
          )}

          {currentStep + 1 === tourData.length ? (
            <p className="page-btn" onClick={closeModal}>
              Finish
              <i className="fa fa-angle-double-right"></i>
            </p>
          ) : (
            <p className="page-btn" onClick={nextStep}>
              Next
              <i className="fa fa-angle-double-right"></i>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
