import React from "react";
import { Col, Container, Row, Card,CardBody,Button,UncontrolledTooltip,CardImg } from "reactstrap";
import { Link } from "react-router-dom";
import legal from 'assets/img/terms/terms.jpg';

const LegalInformation = () => {
  return (
<>
    <section className="">
            <div className=" col-lg-12 col-md-8 col-sm-6 mt-2 container-fluid numcard">
              <Card>
              <CardBody >
              <div> 
              <hr></hr>      
              <CardImg
                  alt="..."
                  style={{height: "150px",objectFit:"cover"}}
                  src={legal}
                  top
                />
                </div>
            

             {/* <Title id="TERMS & CONDITIONS" title="TERMS & CONDITIONS" desc={[""]} />  */}

             <hr></hr>
              <div className="bannerlogo text-center">
              <i style={{color:'#2c9db7'}} class="fas fa-users fa-6x"></i>
            
              <h1>LEGAL INFORMATION</h1>
            </div> 
            <hr></hr>
            
            <section class="av_textblock_section " itemscope="itemscope" itemtype="https://schema.org/CreativeWork"><div class="avia_textblock  " itemprop="text"><div class="page-content legal-info">
<p class="h4" >Last Update : 31-Aug-2018 6:30 AM</p>
<p class="h4" >You are currently on the website published by CHANNELHUB, a simplified joint stock company with a capital of €1,300.00, registered with the NANTERRE RCS under number 830 495 842, whose registered office is 20, quai du 4 septembre – 92100 BOULOGNE-BILLANCOURT</p>
<p class="h4"><strong>DIRECTOR OF PUBLICATION</strong></p>
<p class="h4">Editorial team For any question concerning this web site, write to us using the contact form</p>
<p class="h4"><strong>HOSTING</strong></p>
<p class="h4"><span class="ql-size-small">This site is hosted by : AMAZON WEB SERVICES –&nbsp;</span><span class="ql-size-large">Dublin 8 – IRELAND&nbsp;</span><span class="ql-size-small">Tel: +35316458950</span></p>
</div>
<div class="page-content">
<p class="h4" class="privacy-title"><strong>PRIVACY POLICY</strong></p>
<div>
<p class="h4">For any question concerning your personal data, you can write to us at the use of&nbsp;<Link to="/HelpSupport"><a >“Contact Us”</a></Link>&nbsp;form</p>
<p class="h4">This Website Respects The Following Rules:</p>
<ul>
<li>No data collection without the user’s knowledge</li>
<li>No transfer to third parties, without the prior consent of individuals</li>
<li>No use of the information collected beyond the processing of the request.</li>
</ul>
<p class="h4">Personal data are collected and processed only following the voluntary action by the Internet user in the following cases:</p>
<ul>
<p class="h4"><li>The use of the “Contact” form to allow us to answer you</li></p>
<p class="h4"><li>Subscription to our newsletter and its follow-up</li></p>
<p class="h4"><li>Creating and managing an online user account</li></p>
<p class="h4"><li>The subscription to one of the paid online subscriptions.</li></p>
</ul>
<p class="h4">In all the cases mentioned above, your personal data are neither transferred nor sold nor used for any purpose other than that for which they were collected, except with the prior consent of the person. <span class="ql-size-small">You have at any time a right of access, rectification, portability and deletion of personal data concerning you that you can exercise by writing to the following address: privacy@channel-hub.io with proof of your identity.</span></p>
<p class="h4"><strong>Cookies</strong></p>
<p class="h4">When you visit this website, cookies are placed on your computer to improve navigation and use of the services or to measure the website’s audience.</p>
<p class="h4"><strong>1. What is a cookie?</strong></p>
<p class="h4">The term cookie covers all the tracers (pixels, tags, tags, etc.) deposited in the form of a text file, and/or read when consulting a website, social networks or a mobile application. This file contains several data, such as the name of the server which deposited it, a unique identifier or the duration of recording of the cookie.</p>
<p class="h4">The purpose is to collect information relating to users’ browsing on the sites and to personalize services based on personal data collected, for example, when creating an account or subscribing to a newsletter.</p>
<p class="h4"><strong>2. Which cookies are used?</strong></p>
<p class="h4">The cookies that can be used on this website are of several categories depending on their purpose(s):</p>
<ul>
<p class="h4"><li>technical cookies to detect possible problems when browsing the Internet user that do not contain any personal data;</li></p>
<p class="h4"><li>analytics cookies (or performance cookies) to track website traffic and audience statistics (e.g. number of visits, pages viewed, time spent on the site, connection region, etc.). This data is only used to monitor and improve the services of the website and is not shared with third parties.</li></p>
<p class="h4"><li>social cookies to allow the user to interact from the website to social networks for, for example, liking or sharing content. The data collected are associated with an anonymous identifier and may be cross-referenced with other qualifying data. They are neither transmitted nor shared with third parties.</li></p>
</ul>
<p class="h4"><strong>3. Users’ rights</strong></p>
<p class="h4">Any user can oppose the recording of information relating to his navigation on the website by configuring his browser (in the “options tools” menu of Mozilla Firefox or Internet Explorer, or in the “Help” section of the browser toolbar). Cookies can also be managed in the manner described by the CNIL at the following address:&nbsp;<a href="https://www.cnil.fr/fr/cookies-les-outils-pour-les-maitrise" target="_blank" rel="noopener">https://www.cnil.fr/fr/cookies-les-outils-pour-les-maitrise</a>.</p>
<p class="h4">The website may provide access, through hypertext links, to other websites published by third parties who may use their own cookie policies to track the navigation of their visitors.</p>
<p class="h4"><strong>Copyright</strong></p>
<p class="h4">All the contents of this website are subject to French and international legislation on copyright and intellectual property. The reproduction of all or part of the website is strictly prohibited, including for downloadable documents, visual illustrations, texts and photographic representations, without the express prior authorization of ChannelHub.<span class="ql-size-small"> The trademarks and logos of ChannelHub, as well as the distinctive signs and names of third companies appearing on the website, are protected by their respective owners and may not be used for any purpose without the prior authorization of the latter.</span></p>
<p class="h4"><strong>Limitations Of Liability</strong></p>
<p class="h4">ChannelHub strives to provide accurate, complete and up-to-date information on its website. However, the website and all information, text, names, images, photos, logos, links, etc. are provided “as is” and without warranty, express or implied. In particular, ChannelHub does not guarantee the accuracy or completeness of the information presented on this website or that the use of the website will be uninterrupted and error-free.<span class="ql-size-small">In no event shall ChannelHub be liable for any damages or losses resulting from the use of any information contained on the website, including, without limitation, indirect damages or any other damages arising out of or in connection with the use of the website.</span></p>
<p class="h4">​</p>
<p class="h4">​</p>
</div>
</div>
</div></section>

             
            
             </CardBody >
             </Card>
      
      </div>
          
           
    </section> 
</>
  )
}

export default LegalInformation;