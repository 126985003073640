import React ,{useEffect, useState} from 'react';
import { Button, Modal } from "reactstrap";
import { Card, CardBody, ListGroupItem, ListGroup, Row, Col,UncontrolledTooltip } from "reactstrap";
import { commonService } from '_services/common.service';
import { Spinner } from "views/Hoc/Spinner";
import TablePagination from '@material-ui/core/TablePagination';
import {TablePaginationActions} from 'views/Hoc/pagination/PaginationActions';
import {useSelector} from "react-redux";
import axios from 'axios';
export  const Contact=(props)=> {
  
  const {  Tabname, handleClose, channelId, openConnectPopUp, contactList } = props;
  const { filterValueState, activeTab, search,id,data } = props;
  let logo = 'https://media-exp1.licdn.com/dms/image/C5603AQFcoUgY5tjnXA/profile-displayphoto-shrink_100_100/0/1521051307094?e=1619654400&v=beta&t=MfSIMA6R-5Dqvfja9f2BOhb_z8AfK6lfrgvzymF9zSc';
  let companyLogo = '';
  const [contactData, setContactData] = useState([]);
  const [loading, setLoading] = useState(false);

  
  let [page, setPage] = useState(0);
  let [rowsPerPage, setRowsPerPage] = useState(12);
  let [total, setTotal] = useState(0);
  let [sort, setSort] = useState('asc');

  const redux_data = useSelector(state => state.channelListing);

  useEffect(()=>{
    setLoading(true);
    getContactList();
  }, [filterValueState, search]);

  
  useEffect(() => {
    console.log('redux_data []', redux_data);
    if(redux_data.sorting){
      if(sort!==redux_data.sorting){
        // setSort(redux_data.sorting);
        sort=redux_data.sorting;
        setSort(sort);
      getContactList();
      }
    }
  }, [redux_data.sorting]);

  useEffect(() => { setContactData(contactData);  }, [contactData]);

  const callContactList=()=>{
    page=0;
    setPage(page);
    getContactList();
  }

  const getContactList = async() => {
    setLoading(true);
    let postData={
      filter:getFilterData(),
      order:sort?sort : 'asc', //:'desc',
      search:search?search.name:null,
      offset:page * rowsPerPage,
      // offset:1,
      limit:rowsPerPage,      
    }
    let data = postData;
    let res = await commonService.filterPostService( data, '/channel/allcontacts').catch(err => setLoading(false));
    if(res){
      setContactData(res.result);
      setTotal(res.total);
      setLoading(false);
    }else{
      console.log('No Data');
      setLoading(false);
    }
  }

  const getFilterData=()=>{
    let filter ={
      specialityId:null,
      brandId:null,
      countryId:null,
      locationId:null,
      retailerprofileId:null,
      productCategoryId:null,
      turnOver:null,
      connectionStatusId:null,
      typeId:null
    };
    if(filterValueState.Speciality && filterValueState.Speciality.length>0){
      filter.specialityId=filterValueState.Speciality.map(x=> parseInt(x.id));
    }
    if(filterValueState.Brand && filterValueState.Brand.length>0){
      filter.brandId=filterValueState.Brand.map(x=> parseInt(x.id));
    }
    if(filterValueState.Company_Origin && filterValueState.Company_Origin.length>0){
      filter.countryId=filterValueState.Company_Origin.map(x=> parseInt(x.id));
    }
    if(filterValueState.Company_Location && filterValueState.Company_Location.length>0){
      filter.locationId=filterValueState.Company_Location.map(x=> parseInt(x.id));
    }
    if(filterValueState.Retailer_Profile && filterValueState.Retailer_Profile.length>0){
      filter.retailerprofileId=filterValueState.Retailer_Profile.map(x=> parseInt(x.id));
    }
    if(filterValueState.productCategory && filterValueState.productCategory.length>0){
      filter.productCategoryId=filterValueState.productCategory.map(x=> parseInt(x.id));
    }
    if(filterValueState.Annual_Turnover){
      filter.turnOver=filterValueState.Annual_Turnover.id;
    }
    if(filterValueState.connectionStatusId){
      filter.connectionStatusId=filterValueState.connectionStatusId.id;
    }
    if(filterValueState.typeId){
      filter.typeId=filterValueState.typeId.id;
    }
    return filter;
  }

  const handleChangePage = (event, newPage) => {
    page=newPage;
    setPage(page);
    getContactList();
  };
  
  const handleChangeRowsPerPage = (event) => {
    rowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(rowsPerPage);
    callContactList();
  };

  return (
    <div id="iner" className="main-content mt-3">
                 {(contactData.length<=0 && !loading) && <div className="no-records ">
          {/* <span>No Records</span> */}
          <div className="no-img text-center">
          <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  className="img-fluid" alt="ch-logo" src={require("../../../assets/img/medialibrary/no-data.png")}/></div>
        </div> }  
        
    {loading ? <Spinner /> : <>
      <Row className="justify-content-left ">
    {contactData.length > 0 && contactData.map((x,i) => {
      console.log('data', x);
      logo = process.env.REACT_APP_CDN_URL + (x.document ? x.document.documentPath :  'document/default_image/contact.png');
      companyLogo = x.channel?.channelDetail?.channelLogo?.documentPath;
      companyLogo = process.env.REACT_APP_CDN_URL + (companyLogo ? companyLogo : 'document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg');
      return(
        
        <Col lg="3" className="conne">
          <Card >
              <div className="detv-contact">
                <div className="row">
                <div className="conne-img">
                    <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/default_image/contact.png");}}  className="img-fluid" alt="contact img" src={logo}/>
                  </div>
                  <div className="conne-comp ml-4">
                    <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  className="Brandimg" alt="contact img" src={companyLogo}/>
                  </div>
                </div>
                 <div className="conn-cont">
                  <h3  style={{marginTop:"1rem"}}>{x.firstName + ' ' + x.lastName}</h3>
                  <h5 style={{marginBottom:"1rem"}}> {x.jobTitle + ', ' + x.country?.country} </h5>
                 
                  <h4 className="txt-elps" style={{color:"#2e9cb7"}} id={'text'+i}> {x.channel.companyName} </h4>
                  <UncontrolledTooltip  target={'text'+i} placement='bottom'>
                    {x.channel.companyName}
             </UncontrolledTooltip>
               
                  </div>
                  <div style={{textAlign:"center"}} className="conn-btn">

         
                  <Button className="btn-slack1 btn-icon-only" color="secondary" type="button">
                      <span className="btn-inner--icon">
                      <i class="fas fa-envelope-open-text"></i>
                      </span>
                  </Button>

               <Button className="btn-slack1 btn-icon-only" color="secondary" type="button" >
                    <span className="btn-inner--icon">
                    <i class="fas fa-user-plus"></i>
                    </span>
                  </Button>
            
                <Button className="btn-slack1 btn-icon-only" color="secondary" type="button"  >
                    <span className="btn-inner--icon">
                    <i className="fas fa-phone"></i>
                    </span>
                  </Button>

                  <Button className="btn-slack1 btn-icon-only" color="secondary" type="button"  >
                      <span className="btn-inner--icon">
                      <i class="fas fa-minus-circle"></i>
                      </span>
                  </Button>
                                    
                 <Button className="btn-slack1 btn-icon-only" color="secondary" type="button" >
                    <span className="btn-inner--icon">
                    <i class="far fa-clock"></i>
                    </span>
                  </Button>
                
                  <Button className="btn-slack1 btn-icon-only" color="default" type="button"  >
                    <span className="btn-inner--icon">
                    <i className="fas fa-check-circle"></i>
                    </span>
                  </Button>
    
                  </div>
                  {/* <div class=""><div class="ribbon-9">User</div></div> */}
              </div>
          </Card>
        </Col>
 
      )
    })}
 </Row>
 </>}
        {(total>11) &&
                  <TablePagination
                        component="div"
                        count={total}
                        page={page}
                        onChangePage={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        rowsPerPageOptions={[12, 24, 36, 48]}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                      }  

 </div>
  );
}
export default Contact;