import React from "react";
// react library for routing
import { Route, Switch, Redirect } from "react-router-dom";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
// import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import routes from "routes.js";
import  {PrivateRoute}  from '../_components';
class Admin extends React.Component {
  state = {
    sidenavOpen: false
  };
  componentDidUpdate(e) {
    if (e.history.pathname !== e.location.pathname) {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainContent.scrollTop = 0;
    }
  }
  getRoutes = routes => {
    return routes.map((prop, key) => {
    //  console.log('prop.component',prop.component)
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <PrivateRoute
            exact
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        //   <Route
        //   path={prop.layout + prop.path}
        //   component={prop.component}
        //   key={key}
        // />
        );
      } else {
        return null;
      }
    });
  };
  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };
  // toggles collapse between mini sidenav and normal
  toggleSidenav = e => {
   // console.log('is pined',document.body.classList.contains("g-sidenav-pinned"));
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
      this.CallAddClass().then(this.RemoveAdd);
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
    }
    this.setState({
     sidenavOpen: !this.state.sidenavOpen
    });
  };

 CallAddClass = e => {
   return new Promise(function(resolve, reject) {
     setTimeout((function() {
		 resolve(document.getElementById("nav-bar").classList.add("nav-bar")); 
    }), 10);
});
}
 RemoveAdd = e => {	
   setTimeout((function() {
    document.getElementById("nav-bar").classList.remove("nav-bar");
   }), 1000);

}

  getNavbarTheme = () => {
    return this.props.location.pathname.indexOf(
      "admin/dashboard"
    ) === -1
      ? "dark"
      : "dark";
  };
  render() {
    return (
      <>
    {/* admin */}
        <Sidebar
          {...this.props}
          routes={routes}
          toggleSidenav={this.toggleSidenav}
          sidenavOpen={this.state.sidenavOpen}
          logo={{
            innerLink: "/",
            imgSrc: require("assets/img/brand/channel_hub_logo.png"),
            imgAlt: "..."
          }}
        />
        <div
          className="main-content"
          ref="mainContent"
          onClick={this.closeSidenav}
        >
          <AdminNavbar
            {...this.props}
            theme={this.getNavbarTheme()}
            toggleSidenav={this.toggleSidenav}
            sidenavOpen={this.state.sidenavOpen}
            brandText={this.getBrandText(this.props.location.pathname)}
          />
          <Switch>
            {this.getRoutes(routes)}
            <Redirect from="*" to="/admin/dashboard" />
          </Switch>
          {/* <AdminFooter /> */}
        </div>
        {this.state.sidenavOpen ? (
          <div className="backdrop d-xl-none" onClick={this.toggleSidenav} />
        ) : null}
      </>
    );
  }
}

export default Admin;
