import React from 'react';
import { Col, Container, Row, Card,CardBody,Button } from "reactstrap";
import 'assets/scss/custom/guest_styles_v2.scss';
import { Link } from "react-router-dom";
import Logo from "views/pages/LoginPage/login-logo"
import axios from 'axios';
const Footer = () => {
    return (
        <>   
            <div className="par-foo">
             <div className="head-logo1">
          <Link to="/" >
        <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  className="" alt="ch-logo" src={require('assets/img/brand/ch-logo.svg')}/>

          </Link></div>
          
                {/* <Row className="foo-row">
                    <Col lg="1"></Col>
                    <Col lg="2" className='parfoo-col pt-6'>
                        <div className="text-left"> 
                            <h3>ChannelHub</h3>
                            <ul className="list-unstyled">
                                <li className="mt-3">
                                  <a href="#!">About</a>
                                </li>
                                <li className="mt-3">
                                  <a href="#!">BLog</a>
                                </li>
                                <li className="mt-3">
                                <a href="https://marketing.channelhub.net/meetings/farouk1" target="_blank">Schedule a demo</a>
                                  {/* <a href="#!">Schedule a demoss</a> */}
                                {/* </li>
                                <li className="mt-3">
                                  <a href="#!">Contact us</a>
                                </li>
                                <li className="mt-3">
                                  <a href="#!">Subscribe to our newsletter</a>
                                </li>
                                <li className="mt-3">
                                  <a href="#!">Events</a>
                                </li>
                            </ul>
                        </div>
                    </Col>  */}
                    {/* <Col lg="2" className='parfoo-col pt-6'>
                    <div className="text-left">
                    <h3>Business Network</h3>
                    <ul className="list-unstyled">
                        <li className="mt-3">
                          <a href="#!">Vendors</a>
                        </li>
                        <li className="mt-3">
                          <a href="#!">Retailers</a>
                        </li>
                        <li className="mt-3">
                          <a href="#!">Distributors</a>
                        </li>
                        <li className="mt-3">
                          <a href="#!">Sales Reps</a>
                        </li>
                        <li className="mt-3">
                          <a href="#!">Pricing </a>
                        </li>
                        <li className="mt-3">
                          <a href="#!">Apply</a>
                        </li>
                     </ul>
                    </div>
                    </Col>
                    <Col lg="2" className='parfoo-col pt-6'>
                    <div className="text-left">
                    <h3>Legal informations</h3>
                    <ul className="list-unstyled footertext">
                        <li className="mt-3">
                          <a href="#!">Legal information</a>
                        </li>
                        <li className="mt-3">
                          <a href="#!">Privacy Policy</a>
                        </li>
                        <li className="mt-3">
                          <a href="#!">Terms & Conditions</a>
                        </li>
              
                    </ul>
                    </div>
                    </Col> */}
                    {/* <Col lg="4" className='parfoo-col pt-6'>
                    <div className="text-left">
                    <h3>Latest News</h3>
                    <ul className="list-unstyled footertext">
                        <li className="mt-3">
                        <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  className="rounded-circle news-img" src={require('assets/img/brand/logo-icon.png')}/>
                        <a className="news-link ml-3" href="https://channelhub.net/channelhub-to-host-400-consumer-tech-retailers-from-50-countries-in-first-retail-connect-emea!">ChannelHub to host 400 consumer tech retailers from 50 countries in first Retail Connect EMEA</a>
                        </li> <span class="news-time mt-1">August 14, 2020 - 12:24 pm</span> 
                        <li className="mt-3">
                        <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  className="rounded-circle news-img" src={require('assets/img/brand/logo-icon.png')}/>
                        <a class="news-link ml-3" title="Marvel Distributes Aqara" href="https://channelhub.net/marvel-distributes-aqara/">Marvel Distributes Aqara</a>
                        </li> <span class="news-time mt-1">August 14, 2020 - 12:24 pm</span>
                        <li className="mt-3">
                        <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  className="rounded-circle news-img" src={require('assets/img/brand/logo-icon.png')}/>
                        <a class="news-link ml-3" href="https://channelhub.net/tp-link-appoints-koyuncu-elektronik/">TP-Link Appoints Koyuncu Elektronik</a></li><span class="news-time mt-1">August 14, 2020 - 12:24 pm</span>
                    </ul>
                    </div>
                    </Col> */}
                    {/* <Col lg="1"></Col> */}
                {/* </Row> */}
          
                <div className="clip-foo text-right pt-6">
                  <div className="mobpad">
                    <Button className="btn-icon-only rounded-circle" color="facebook" type="button">
                    <span className="btn-inner--icon text-white"><i class="fab fa-facebook-f"/></span>
                    </Button>
                    <Button className="btn-icon-only rounded-circle" color="twitter" type="button">
                        <span className="btn-inner--icon text-white"><i className="fab fa-twitter" /></span>
                    </Button>
                    <Button className="btn-instagram btn-icon-only rounded-circle" color="default" type="button">
                         <span className="btn-inner--icon text-white"> <i className="fab fa-instagram" /></span>
                    </Button>
                    <Button className="btn-icon-only rounded-circle" color="pinterest" type="button">
                      <span className="btn-inner--icon text-white"><i className="fab fa-pinterest" /></span>
                    </Button>
                    <Button className="btn-icon-only rounded-circle mr-4" color="youtube" type="button">
                      <span className="btn-inner--icon text-white"><i className="fab fa-youtube" /></span>
                    </Button>
                    </div>
                <h4 className="text-center pb-4 tricoth" style={{color:"white"}}>© Copyright - ChannelHub</h4>
                </div>
            </div>
            </>
    );
};

export default Footer;