import React, { useState } from "react";
import { Card,CardBody, Container, } from "reactstrap";
import Subscription from "views/pages/profile/PendingProfiles/Subscription-old";
import GuestSubscription from "./GuestSubscription";
import Navbarg from "./Navbarg";
import Footer from "./Footer";

const GuestPricing = (props) => {


return (
<div className="main-content">
<div className="mt-4 col-lg-12 col-md-9 col-sm-12 container" >

 <GuestSubscription/>
 
</div>
</div>


);
};


export default GuestPricing;