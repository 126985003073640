import React, { useState, useEffect } from "react";
import { Col, Container, Row, Card,CardBody } from "reactstrap";
import { yupResolver } from '@hookform/resolvers/yup';
import { FormTextArea,FormCheckBox,FormAutocomplete,FormInput  } from "_components/FormElements/FormInput";
import { useForm} from "react-hook-form";
import { guestSupportSchema } from "./validation"
import Title from "views/Hoc/Dialog/Title";
import  GuestNavbar  from "views/pages/GuestPage/GuestNavbar/GuestNavbar";
import { commonService } from '_services/common.service';
import {ToastsContainer, ToastsStore} from 'react-toasts';
import { history } from '_helpers/history';
import { Spinner } from "views/Hoc/Spinner";
const Subscriber = () => {
    const {control, errors, register, watch, handleSubmit  } = useForm({      
        mode:'onBlur',  
      });

      useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://js.hsforms.net/forms/v2.js';
        document.body.appendChild(script);
        
        script.addEventListener('load', () => {
          if(window.hbspt) {
            window.hbspt.forms.create({
              region: "na1",
              portalId: "4471678",
              formId: "7f9d4471-14d6-4ad0-844b-78e7278667aa",
               target: '#hubspotForm',
            })
          }
        });
      },[]);


  return (
    <>
         <GuestNavbar/>
         {/* <ToastsContainer position="top_right" /> */}
          <Title  className=" col-lg-9 col-sm-6 mt-2  container numcard pad-less " title="Subscribe to our Newsletter" desc={[""]} />
           
          
             <Container className="mt-4 col-lg-12 col-md-9 col-sm-12   container-fluid" fluid>   
           <Card className=" col-lg-12 col-sm-6 mt-2  container numcard pad-less ">
              <CardBody >
              
              <div className="ml-3 mr-3" id="hubspotForm"></div>
    
              </CardBody >
            </Card>
            </Container>     
          
            
            </>
            
           
            
    
  );
  }
export default Subscriber;