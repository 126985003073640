import React from 'react';
import YoutubeEmbed from './YoutubeEmbed';


const PicturesVideosMENA = () => {
    return (
        <div className='picvideo-wrap'>
            <div className='row'>
                <div className='col-sm-6'>
                    <a href='https://channelhub.premagic.com/share/Channel-Summit-MENA-2024/#/' target='_blank'>
                    <div className='menapic-wrap'>
                        <div className='menapic-img'>
                            <img src={"/images/mena_photo.png"} />
                        </div>
<span style={{paddingLeft:"15px",fontSize: "20px",color:"#333" }}>Channel Summit MENA 2024</span><br />
<span className='btn-photoview'>View Photos</span>
                    </div>
                    </a>
                </div>
                
            </div>
        </div>
    )
}
export default PicturesVideosMENA;