import React, { useState, useEffect } from 'react';
import { Card, CardBody, Row, Col, Button, UncontrolledTooltip } from "reactstrap";
import { commonService } from '_services/common.service';
import { ChannelDismiss } from 'views/Hoc/Dialog/ChannelDismiss';
import { ChannelConnect } from "views/Hoc/Dialog/ChannelConnect";
import { AskmeetingPopup } from "views/Hoc/Dialog/AskmeetingPopup";
import { Dialog, UpgradeModal } from "views/Hoc/Dialog/GlobalDialog";
import UserView from "views/Hoc/Dialog/UserView";
import EventMeetingView from "views/Hoc/Dialog/EventMeetingView";
import { history } from '../../../_helpers/history';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import VideoPreview from 'views/Hoc/Dialog/VideoPreview';
import StarRating from 'views/pages/Events/StarRating';
import axios from 'axios';

export const ContactListing = (props) => {
  const { data, title, channelTypeId, channelId, getChannelContact, notify, mynetwork, totalTabCount, publicProfile ,updateRating } = props;
  const [showMore, setMore] = useState(false);
  let [dismissPopUp, setDismissPopUp] = useState(false);
  let [connectPopUp, setConnectPopUp] = useState(false);
  let [isModalPopup, setModalPopup] = useState(false);
  let [isUpgradePopup, setUpgradePopup] = useState(false);
  let [modalPopValue, setModalPopValue] = useState({ message: null, title: null, value: null, popUpId: null, second_message: null, cid: null, JctId: null });
  let [UpdateData, setUpdateData] = useState(null);
  let [RatingUpdate, setRatingUpdate] = useState(null);
  let [askMeeting, setAskMeeting] = useState(false);
  let [selectedContact, setSelectedContact] = useState(null);
  let [connectionLimit, setConnectionLimit] = useState({ connectLimit });
  let [suggest_Count, setSuggest_Count] = useState(0);
  let [confidentialCnt, setConfidentialCnt] = useState(null);
  let [outboundRemaind, setOutboundRemaind] = useState({ remaining: null, end_date: null });
  let userData = JSON.parse(localStorage.getItem('CHuser'));
  let [dissmissFrom, setDissmissFrom] = useState(null);
  let [partnerId, setPartnerId] = useState(props.channelId);
  const [viewuser, setsviewuser] = useState(false);
  const [open, setOpen] = useState(false);
  const [viewUserData, setUserData] = useState();
  const [starRating, setStarRating] = useState(false);
  const [viewmeetings, setViewMeetings] = useState(false);
  const [eventMeetingViewData, setEventMeetingViewData] = useState();

  let logo = 'http://d2maj8ifjo1lti.cloudfront.net/document/default_image/contact.png';
  useEffect(() => {
    getSugOutboundcount();
  }, []);
  let applyBlur = (userData.planId === '1') && (parseInt(channelTypeId) === 3 || parseInt(channelTypeId) === 4) && (userData.channelTypeId === '2' || userData.channelTypeId === '3');
  const getSugOutboundcount = async () => {
    let res = await commonService.getServices('channel/getSugOutboundcount/' + userData.contactId).catch(err => console.log('err', notify('danger', 'Soming went wrong')));
    if (res) {
      console.log(res);
      getRemainingCount(res.data);
      setLimit(res.data);
    }
  }

  const more_click = () => {
    if (!showMore) setMore(true);
    else setMore(false);
  }
  const openPopUp = async (contact, status, title) => {
    let typeId = parseInt(channelTypeId);
    if (((applyBlur && contact.isConfidential == '1') || (connectionLimit.connectLimit[typeId].limit !== -1 && suggest_Count > connectionLimit.connectLimit[typeId].limit))
      && (parseInt(contact.relationId) === 1 || parseInt(contact.relationId) === null || parseInt(contact.relationId) === 0)
      && (typeId === 4 || typeId === 3)) {
      modalPopValue.JctId = contact.channelJCTId;
      openUpgradePopup();
      return false;
    }
    UpdateChannelData.connectionStatusTypeId = status;
    UpdateChannelData.channelJCTId = parseInt(contact.channelJCTId);
    UpdateChannelData.contactid = contact.record.contactId;
    UpdateChannelData.receiver_mail = contact.record.email;
    UpdateChannelData.receiver_photo = contact.record.documenturl ? contact.record.documenturl : logo;
    UpdateChannelData.receiver_name = contact.record.firstName;// + ' ' + contact.record.lastName;
    UpdateData = UpdateChannelData;
    setUpdateData(UpdateData);
    if (status === 5) {
      if (contact.request == 'OUT')
        dissmissFrom = "Outbound";
      else if (contact.request == 'IN')
        dissmissFrom = "Inbound";
      else if (contact.relationId == 2)
        dissmissFrom = "Confirm";
      else
        dissmissFrom = "Suggestion";
      setDissmissFrom(dissmissFrom);
      if (mynetwork == true) {
        partnerId = contact.channelId;
        setPartnerId(partnerId);
      }
      setDismissPopUp(true);
    } else if (status === 7) {
      if (mynetwork == true) {
        partnerId = contact.channelId;
        setPartnerId(partnerId);
      }
      setConnectPopUp(true);
    } else if (status === 10) {
      if (mynetwork == true) {
        partnerId = contact.channelId;
        setPartnerId(partnerId);
      }
      modalPopValue.message = 'You wish to connect to a retail buyer. The process for this connection is different from contacting a distributor. For retailer, ChannelHub will collect your request and will include it in a summary report compiling all the requests from various suppliers. This presentation will be done every month. Once the presentation is done, we’ll inform you about their decision on the platform.';
      modalPopValue.second_message = 'MAKE SURE THAT YOUR COMPANY PROFILE IS COMPLETE INCLUDING PRODUCT LIST BEFORE PROCEEDING AS THIS WILL BE THE ONLY INFORMATION PRESENTED IN THE SUMMARY REPORT TO THE BUYERS';
      modalPopValue.title = "IMPORTANT";
      modalPopValue.value = UpdateData;
      modalPopValue.popUpId = 1;
      setModalPopValue({ ...modalPopValue });
      setModalPopup(true);
    } else if (status === 11 || status === 2) {
      if (mynetwork == true) {
        partnerId = contact.channelId;
        setPartnerId(partnerId);
      }
      modalPopValue.message = status === 11 ? 'Do you wish to move this request for a later decision?' : 'Do you wish to approve this contact?';
      modalPopValue.title = title;
      modalPopValue.value = UpdateData;
      modalPopValue.popUpId = 1;
      setModalPopValue({ ...modalPopValue });
      setModalPopup(true);
    }
  }
  const openDialogAskMeeting = (contact) => {
    console.log(contact);
    selectedContact = contact;
    setSelectedContact(selectedContact);
    if (localStorage.getItem("ask_Meeting_show_Again") !== '1') {
      setAskMeeting(true);
    } else {
      saveAskMeeting(selectedContact);
    }
  }
  const openUpgradePopup = (event) => {
    modalPopValue.cid = parseInt(userData.channelId);
    if (modalPopValue.JctId == null) {
      if (event.target.parentNode.id != null) {
        modalPopValue.JctId = event.target.parentNode.id;
      }
    }

    setModalPopValue({ ...modalPopValue });
    setUpgradePopup(true);
  }
  const dismissPopUpClose = () => {
    setDismissPopUp(false);
  }
  const connectPopUpClose = () => {
    setConnectPopUp(false);
  }
  const closePopup = () => {
    setModalPopup(false);
  };
  const askMeetingPopUpClose = () => {
    setAskMeeting(false);
  }
  const closeUpgradePopup = () => {
    setUpgradePopup(false);
  }
  const confirmPopup = (res) => {
    console.log('res', res);
    setModalPopup(false);
    updateChannel(res.value);
  }
  const saveAskMeeting = async (channellist) => {
    setAskMeeting(false);
    let meeting = {
      senderChannelId: parseInt(userData.channelId),
      senderContactId: parseInt(userData.contactId),
      // alt_senderContactId : Number(localStorage.getItem("fo-user-subscription-contact-id")),
      receiverChannelId: parseInt(channelId),
      receiverContactId: parseInt(channellist.record.contactId),
      // alt_receiverContactId : channellist.default_contact_id,
      // meeting_Scheduled_At : Number(Date()),
      // isMeetingConfirm : 1,
      // isMeetingCompleted : 1,
    }
    let res = await commonService.postService(meeting, 'Meeting').catch(err => console.log('err', notify('danger', 'Soming went wrong')));
    if (res) {
      notify('success', 'Meeting Request Sent Successfully');
      // getChannelContact();
      // history.push('/admin/channel');
      console.log(res);
    } else {
      notify('danger', 'Soming went wrong');
    }
  }
  const updateChannel = async (value) => {
    console.log('value', value);
    setDismissPopUp(false);
    setConnectPopUp(false);

    let res = await commonService.postService(value, 'channel/updateChannel/' + partnerId).catch(() => ToastsStore.error('Soming went wrong'));
    if (res) {
      // getChannelContact();
      // history.push('/admin/channel');
      ToastsStore.success(value?.connectionStatusTypeId == 5 ? 'Contact Dismissed Successfully' : value?.connectionStatusTypeId == 2 ? 'Contact Approval Successfully' : 'Contact Update Successfully');
      if (value?.connectionStatusTypeId == 7) {
        Object.assign(value, { req_contact: userData.contactId, req_channel: userData.channelId, typeId: userData.channelTypeId })
        //if (userData.suggestion_comm == 0)
        axios.post('mailerservice/Connection_Request_Mail', value);
      }
      else if (value?.connectionStatusTypeId == 5) {
        Object.assign(value, { req_contact: userData.contactId })
       // axios.post('mailerservice/Connection_Dismiss_Mail', value);
      }
      else if (value?.connectionStatusTypeId == 2) {
        Object.assign(value, { req_contact: userData.contactId })
        axios.post('mailerservice/Connection_Approve_Mail', value);
      }
      if (mynetwork == true)
        getChannelContact();
      else
        getChannelContact(channelId);

      console.log(res);
    }

  }
  const getRemainingCount = (count) => {
    let distributor_sug = 0;
    let retailer_sug = 0
    let limit = 0;
    let total_sug = 0
    let remaining = 0;
    let end_date;
    if (count) {
      count.map(x => {
        if (x.suggestionfor === 'DISTRIBUTOR_OTB') {
          distributor_sug = x.suggestioncount ? +x.suggestioncount : 0;
        } else if (x.suggestionfor === 'RETAILER_OTB') {
          retailer_sug = x.suggestioncount ? +x.suggestioncount : 0;
          limit = parseInt(x.limit);
          end_date = x.endat;
        }
      });
      if (limit !== -1) {
        if (userData.channelTypeId === '2') {
          total_sug = distributor_sug + retailer_sug;
          if (total_sug <= limit) {
            remaining = limit - total_sug;
          } else {
            remaining = 0;
          }
        } else if (userData.channelTypeId === '3') {
          if (retailer_sug <= limit) {
            remaining = limit - retailer_sug;
          } else {
            remaining = 0;
          }
        }
      } else {
        remaining = -1;
      }
    }
    outboundRemaind.outbound = remaining;
    outboundRemaind.expireDate = end_date;
    setOutboundRemaind({ ...outboundRemaind });
  }
  const setLimit = (limit) => {
    let outbountLimit = connectionLimit.connectLimit
    limit.map(x => {
      if (x.suggestionfor === 'VENDOR_OTB') {
        outbountLimit[2].endat = x.endat;
        outbountLimit[2].limit = x.limit;
        outbountLimit[2].suggestioncount = x.suggestioncount ? parseInt(x.suggestioncount) : 0;
      } else if (x.suggestionfor === 'DISTRIBUTOR_OTB') {
        outbountLimit[3].endat = x.endat;
        outbountLimit[3].limit = x.limit;
        outbountLimit[3].suggestioncount = x.suggestioncount ? parseInt(x.suggestioncount) : 0;
      } else if (x.suggestionfor === 'RETAILER_OTB') {
        outbountLimit[4].endat = x.endat;
        outbountLimit[4].limit = x.limit;
        outbountLimit[4].suggestioncount = x.suggestioncount ? parseInt(x.suggestioncount) : 0;
      }
    });
    if (userData.channelTypeId === '2') {
      suggest_Count = outbountLimit[3].suggestioncount + outbountLimit[4].suggestioncount;
    } else if (userData.channelTypeId === '3') {
      suggest_Count = outbountLimit[4].suggestioncount;
    } else {
      suggest_Count = outbountLimit[2].suggestioncount + outbountLimit[3].suggestioncount;
    }
    setConnectionLimit({ connectLimit });
    setSuggest_Count(suggest_Count)
  }
  const userview = (data) => {
    setUserData(data.record)
    setsviewuser(true);
  }

  const closeUserView = () => {
    setsviewuser(false);
  }

  const openEventMeetings = (data, channelTypeId) => {
    
    // data = { 'channelTypeId' : channelTypeId }
     setEventMeetingViewData({"meetings":data, "typeId":channelTypeId})
     setViewMeetings(true);
  }

  const closeEventMeetingView = () => {
    setViewMeetings(false);
  }

  

  const openStarRating = (contact, rating) => {

    let obj = {
      channelTypeId: contact.record.channelTypeId,
      channelJCTId: contact.channelJCTId,
      eventId: contact.eventId,
      rating:rating
    }
    setRatingUpdate(obj);

    setStarRating(true);
  }

  const closeStarRating = async (value) => {   

    
      setStarRating(false);
     
  }

  const saveStarRating = async (value) => {   

    await commonService.postService(value, 'channel/updateMeetingRating').then(response => {
       updateRating(value)
      setStarRating(false);
      //getChannelContact();
    }).catch(() => ToastsStore.error('Soming went wrong'));
  }

  if (data?.records?.length > 0 || data.length > 0) {
    let _remain = [];
    let _elements;
    if (mynetwork == true) {
      _elements = data.slice(0, 4); // clone array
    }
    else {
      _elements = data?.records.slice(0, 4); // clone array
      _remain = data?.records.slice(4, data.length);
    }
    const contact = (data) => {
      let icnt = 0

      return (<>
        {viewuser &&
          <UserView userData={viewUserData} closeUserView={closeUserView} />}
        {viewmeetings &&
          <EventMeetingView userData={eventMeetingViewData} closeEventMeetingView={closeEventMeetingView} />}
        {data.length > 0 &&
          <>{data.map((x) => {
            // existing condition 
            //   let isConfidential = (((x.isConfidential !== null && parseInt(x.isConfidential) ===0) || (applyBlur && parseInt(x.isConfidential) !==1) || (x?.isConfidential === null && parseInt(x?.relationId) === 0 && userData.planId==='1')) && (parseInt(x.relationId) === 1 || parseInt(x.relationId) ===0) && (parseInt(channelTypeId) !== 2 && userData.channelTypeId!=='4'));
            console.log('+userData.channelTypeId ', parseInt(x.relationId));
            let isConfidential = false;
            let ContactchannelTypeId = x.record.channelTypeId;
            if (publicProfile !== 'PublicProfile') {
              isConfidential = (+userData.channelTypeId == 2 && ((x.isConfidential === null || x.isConfidential === '1') && userData.planId == 1 && parseInt(x.relationId) === 7)) ? true : false;

              if (totalTabCount?.confirm_count == 0 && icnt == 0 && parseInt(x.relationId) === 7) {
                isConfidential = false;
              }
            }



            // isConfidential = ( +userData.channelTypeId == 2 && (((x.isConfidential === null  ||  x.isConfidential === '1') && userData.planId == 1 && parseInt(x.relationId) === 7) || (icnt > 0 && totalTabCount?.confirm_count == 0  && parseInt(x.relationId) === 7)) ) ? true : false;



            if (+userData.channelTypeId != 2) { isConfidential = false; }
            let confidentialClass = isConfidential ? 'isConfidential' : '';
            icnt = parseInt(icnt) + parseInt(1)
            return (<Col xl="3" lg="4" xs="12" md='6'>
              <Card id="contacthover">
                {/* {parseInt(x?.relationId) === 2 &&
                    <UncontrolledTooltip target='contacthover' placement='bottom'>
                          Connected
                        </UncontrolledTooltip>} */}
                <div className="detv-contact">
                  <span onClick={() => isConfidential ? console.log('null') : userview(x)} >
                    <div className={"conne-img " + (isConfidential ? 'isConfidential' : '')}>
                      <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/default_image/contact.png"); }} className="img-fluid" alt="contact img" src={isConfidential ? logo : x.record.documenturl ? x.record.documenturl : logo} />
                    </div>
                    <div className="conn-cont">
                      <h3 className={'' + confidentialClass} style={{ marginTop: "0.5rem" }}> {isConfidential ? 'xxxxxx xxxxxx' : x.record.firstName + ' ' + x.record.lastName} </h3>
                      <p className={'contactTitle' + confidentialClass} style={{ marginBottom: "0.3rem" }}> {isConfidential ? "yyyyyy" : x.record.jobTitle} </p>
                      <a href={'/admin/mynetwork/channelDetail/' + x.channelId}  target='_blank'><p className={'contactTitle  ' + confidentialClass} style={{ marginBottom: "0.3rem" }}> {isConfidential ? "yyyyyy" : x.record.companyName} </p></a>
                      <h5 className={'mt-2' + confidentialClass}>{x.record.country}</h5>
                    </div>
                  </span>
                  <div style={{ textAlign: "center" }} className="conn-btn">

                    {!!x.record?.sociallink?.length ?
                      x.record.sociallink.map(y => (
                        <>
                          {!!y.contactSocialLink ? <a id={'social-id' + x.record.contactId} onClick={() => {
                            let url = y.contactSocialLink;
                            if (!url.match(/^https?:\/\//i) && !url.match(/^http?:\/\//i)) {
                              url = '//' + url;
                            }
                            window.open(url, '_blank')
                          }}>
                            <Button className="btn-icon-only mr-2 i-link" color="twitter" type="button">
                              <span className="btn-inner--icon">
                                <i className="fab fa-linkedin" />
                              </span>
                            </Button>
                            <UncontrolledTooltip target={"social-id" + x.record.contactId} placement='bottom'>
                              linkedin
                            </UncontrolledTooltip>
                          </a>
                            :
                            <a data-placement="top" id={'social-id' + x.record.contactId} onClick={() => {
                              // let url = y.contactSocialLink;
                              // if (!url.match(/^https?:\/\//i) && !url.match(/^http?:\/\//i)) {
                              //   url = '//' + url;
                              // }
                              // window.open(url, '_blank')
                            }}>
                              <Button className="btn-icon-only mr-2 i-linkDisabled" type="button" onClick={() => {
                                if (userData?.contactId == x.record.contactId)
                                  history.push({ pathname: '/admin/profileform', state: { linkedIn: true } })
                              }}>
                                <span className="btn-inner--icon">
                                  <i className="fab fa-linkedin" />
                                </span>
                              </Button>
                              <UncontrolledTooltip target={"social-id" + x.record.contactId} placement='bottom'>
                                {userData?.contactId == x.record.contactId ? 'Update your LinkedIn url for better profile' : 'LinkedIn url not available for this contact.'}
                              </UncontrolledTooltip>
                            </a>
                          }</>
                      )) :
                      <a data-placement="top" id={'social-id' + x.record.contactId} onClick={() => {
                        // let url = y.contactSocialLink;
                        // if (!url.match(/^https?:\/\//i) && !url.match(/^http?:\/\//i)) {
                        //   url = '//' + url;
                        // }
                        // window.open(url, '_blank')
                      }}>
                        <Button className="btn-icon-only mr-2 i-linkDisabled" type="button" onClick={() => {
                          if (userData?.contactId == x.record.contactId)
                            history.push({ pathname: '/admin/profileform', state: { linkedIn: true } })
                        }}>
                          <span className="btn-inner--icon">
                            <i className="fab fa-linkedin" />
                          </span>
                        </Button>
                        <UncontrolledTooltip target={"social-id" + x.record.contactId} placement='bottom'>
                          {userData?.contactId == x.record.contactId ? 'Update your LinkedIn url for better profile' : 'LinkedIn url not available for this contact.'}
                        </UncontrolledTooltip>
                      </a>

                    }


                    {mynetwork === true && <>
                      {isConfidential ?
                        <Button className="btn-icon-only" color="eyeicon" type="button">
                          <a id={"upgrade-id" + x.record.contactId} style={{ color: 'inherit' }} href='#' onClick={openUpgradePopup}>
                            <span className="btn-inner--icon" id={x.channelJCTId}>
                              <i style={{ top: "0px", color: "fff" }} className="far fa-eye" />
                            </span>
                            <UncontrolledTooltip target={"upgrade-id" + x.record.contactId} placement='bottom'>
                              View Channel
                            </UncontrolledTooltip>
                          </a>
                        </Button>
                        :
                        <Button className="btn-icon-only" color="eyeicon" type="button">
                          <a id={"upgrade-id" + x.record.contactId} style={{ color: 'inherit' }} href={'/admin/mynetwork/channelDetail/' + x.channelId} target='_blank'>
                            <span className="btn-inner--icon">
                              <i style={{ top: "0px", color: "fff" }} className="far fa-eye" />
                            </span>
                            <UncontrolledTooltip target={"upgrade-id" + x.record.contactId} placement='bottom'>
                              View Channel
                            </UncontrolledTooltip>
                          </a>
                        </Button>

                      }
                    </>}


                    {(x.relationId === 2 && publicProfile !== 'PublicProfile') && <>

                      <Button className="btn-warning btn-icon-only" type="button" onClick={() => openPopUp(x, 5, 'Dismiss')}>

                        <span className="btn-inner--icon">
                          <i id={"dismiss-id" + x.record.contactId} style={{ top: "0px" }} class="fas fa-minus-circle"></i>
                        </span>
                        <UncontrolledTooltip target={"dismiss-id" + x.record.contactId} placement='bottom'>
                          Dismiss
                        </UncontrolledTooltip>
                      </Button>
                      {/* <Button className="btn-slack1 btn-icon-only" color="default" type="button">
                        <a href={'mailto:' + x.record?.email} >
                          <span className="btn-inner--icon">
                        
                            <i class="fas fa-envelope-open-text"></i>
                          </span>
                        </a>
                      </Button> */}
                      <Button className="btn-primary btn-icon-only" color="default" type="button">
                        <a id={"email-id" + x.record.contactId} style={{ color: 'inherit' }} href={'mailto:' + x.record?.email} >
                          <span className="btn-inner--icon">
                            <i style={{ top: "0px" }} className="far fa-envelope">
                            </i>
                          </span>
                        </a>
                        <UncontrolledTooltip target={"email-id" + x.record.contactId} placement='bottom'>
                          Email
                        </UncontrolledTooltip>
                      </Button>

                    </>}



                    {(parseInt(x?.relationId) !== 2 && publicProfile !== 'PublicProfile') && <>
                      {isConfidential ? <>
                        {userData.channelTypeId != 2 && (parseInt(x?.relationId) === 1 || parseInt(x?.relationId) === 0) && parseInt(channelTypeId) === 4 && <Button className="btn-secondary btn-icon-only" color="secondary" type="button" onClick={openUpgradePopup}>
                          <span className="btn-inner--icon" id={x.channelJCTId}>
                            <i class="fas fa-user-plus"></i>
                          </span>
                        </Button>}
                        {(userData.channelTypeId != 2 && (parseInt(x?.relationId) === 1 || parseInt(x?.relationId) === 0) && parseInt(channelTypeId) !== 4) && <Button className="btn-secondary btn-icon-only" color="secondary" type="button" onClick={openUpgradePopup}>
                          <span className="btn-inner--icon" id={x.channelJCTId}>
                            <i class="fas fa-user-plus"></i>
                          </span>
                        </Button>}
                        {/*userData.channelTypeId === '2' && parseInt(channelTypeId) === 3 && <Button className="btn-secondary btn-icon-only" color="secondary" type="button" onClick={() => openDialogAskMeeting(x)}>
                          <span className="btn-inner--icon">
                            <i className="fas fa-phone"></i>
                          </span>
                      </Button>*/}

                        <Button className="btn-secondary btn-icon-only" color="secondary" type="button" onClick={() => openPopUp(x, 5, 'Dismiss')}>
                          <span className="btn-inner--icon" id={x.channelJCTId}>
                            <i id={"dismiss-id" + x.record.contactId} class="fas fa-minus-circle"></i>
                          </span>
                          <UncontrolledTooltip target={"dismiss-id" + x.record.contactId} placement='bottom'>
                            Dismiss
                          </UncontrolledTooltip>
                        </Button>

                      </> : <>
                        {(parseInt(x?.relationId) === 1 || parseInt(x?.relationId) === 0) && parseInt(channelTypeId) === 4 && userData.channelTypeId != 2 && <Button className="btn-primary btn-icon-only" color="default" type="button" onClick={() => openPopUp(x, 10, 'Request Intro')}>
                          <span className="btn-inner--icon">
                            <i id={"request-id" + x.record.contactId} class="fas fa-user-plus"></i>
                          </span>
                          <UncontrolledTooltip target={"request-id" + x.record.contactId} placement='bottom'>
                            Request Intro
                          </UncontrolledTooltip>
                        </Button>}
                        {(parseInt(x?.relationId) === 1 || parseInt(x?.relationId) === 0 || parseInt(x?.relationId) === 5) && parseInt(channelTypeId) !== 4 && userData.channelTypeId != 2 &&
                          <Button disabled={parseInt(x?.relationId) === 5 ? true : false} className="btn-primary btn-icon-only" color="default" type="button" onClick={() => openPopUp(x, 7, 'Connect')}>
                            <span className="btn-inner--icon">
                              <i id={"connect-id" + x.record.contactId} class="fas fa-user-plus"></i>
                            </span>
                            <UncontrolledTooltip target={"connect-id" + x.record.contactId} placement='bottom'>
                              {parseInt(x?.relationId) === 5 ? 'a previous connection request was declined by this user, you cannot request again' : 'Connect'}
                            </UncontrolledTooltip>
                          </Button>}





                        {/*(userData.channelTypeId === '2' && parseInt(channelTypeId) === 3) && <Button className="btn-slack btn-icon-only" color="default" type="button" onClick={() => openDialogAskMeeting(x)}>
                          <span className="btn-inner--icon">
                            <i className="fas fa-phone"></i>
                          </span>
                    </Button>*/}
                        {parseInt(x?.relationId) !== 5 &&
                          <Button className="btn-warning btn-icon-only" type="button" onClick={() => openPopUp(x, 5, 'Dismiss')}>

                            <span className="btn-inner--icon">

                              <i id={"dismiss-id" + x.record.contactId} class="fas fa-minus-circle"></i>
                            </span>
                            <UncontrolledTooltip target={"dismiss-id" + x.record.contactId} placement='bottom'>
                              Dismiss
                            </UncontrolledTooltip>
                          </Button>
                        }


                        {((parseInt(x?.relationId) === 7 || parseInt(x?.relationId) === 11 || parseInt(x?.relationId) === 10) && x.introVideo !== null) &&
                          <Button className="btn-slack btn-icon-only" color="default" type="button" onClick={() => setOpen(true)}>
                            <span className="btn-inner--icon">
                              <i id={"video-id" + x.record.contactId} class="fas fa-video"></i>
                            </span>
                            <VideoPreview videoModalClose={() => setOpen(false)} isOpen={open} url={process.env.REACT_APP_CDN_URL + x.introVideo} />
                            <UncontrolledTooltip target={"video-id" + x.record.contactId} placement='bottom'>
                              Watch Video
                            </UncontrolledTooltip>
                          </Button>}
                        {/* {(parseInt(x?.relationId) === 7 || parseInt(x?.relationId) === 10) && x?.request === 'IN' && <Button className="btn-slack btn-icon-only" color="default" type="button" onClick={() => openPopUp(x, 11, 'Maybe Later')}>
                          <span className="btn-inner--icon">
                            <i class="far fa-clock"></i>
                          </span>
                        </Button>} */}
                        {(parseInt(x?.relationId) === 7 || parseInt(x?.relationId) === 11 || parseInt(x?.relationId) === 10) && (x?.request === 'IN' || x?.request === 'SAVELATER') &&
                          <Button className="btn-slack btn-icon-only" color="default" type="button" onClick={() => openPopUp(x, 2, 'Approve')}>
                            <span className="btn-inner--icon">
                              <i id={"approve-id" + x.record.contactId} className="fas fa-check-circle"></i>
                            </span>
                            <UncontrolledTooltip target={"approve-id" + x.record.contactId} placement='bottom'>
                              Approve
                            </UncontrolledTooltip>
                          </Button>}

                      </>}
                    </>}

                  </div>
                  {(x?.relationId && parseInt(x?.relationId) !== 0 && parseInt(x?.relationId) != 2 ) ? <div class="">

                    {parseInt(x?.relationId) !== 2 ? <div class="ribbon-9">{getStatus(x)}</div> : <span> <i id="check" class="fa fa-check connectedCheck"></i>

                      <UncontrolledTooltip target='check' placement='top'>
                        Connected
                      </UncontrolledTooltip>
                    </span>
                    }

                  </div> : ''}
                  {
                  (x?.meetings)?(
                  ((x?.relationId && parseInt(x?.relationId) == 2 )  && (!x?.eventId)  && (x.meetings.length > 0)) ? 
                      <div className="starratedvalue" onClick={() => openEventMeetings(x.meetings, ContactchannelTypeId)}>
                      {x.meetings.map((x, i) => (
                        (i <= 2) ?
                      <img title="click to view rating" alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                      src={x.documentPath!=null ? process.env.REACT_APP_CDN_URL + x.documentPath : process.env.REACT_APP_CDN_URL + 'document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'}
                      style={{ width: "35px", display:"inline-block",  cursor: "pointer" }}
                      />
                        :''
                      ))}
                      {(x.meetings.length > 3)? "+" + ( parseInt(x.meetings.length) - 3) : ''}
                  </div>:''
                  )
                  :null
                  }
                  {((x?.relationId && parseInt(x?.relationId) == 2 ) && (x?.eventId)) ?
                     (ContactchannelTypeId === '2') ? 
                        <span className="starratedvalue">
                          <i id="ratedvalue" class={`fa ${x.meetings[0].vendorRating>0 ? "fa-star starrated" : "fa-star ra"}`} onClick={() => openStarRating(x,1)}></i>
                          <i id="ratedvalue" class={`fa ${x.meetings[0].vendorRating>1 ? "fa-star starrated": "fa-star ra"}`} onClick={() => openStarRating(x,2)}></i>
                          <i id="ratedvalue" class={`fa ${x.meetings[0].vendorRating>2 ? "fa-star starrated" : "fa-star ra"}`} onClick={() => openStarRating(x,3)}></i>
                          <i id="ratedvalue" class={`fa ${x.meetings[0].vendorRating>3 ? "fa-star starrated" : "fa-star ra"}`} onClick={() => openStarRating(x,4)}></i>
                        </span>
                        :
                        <span className="starratedvalue">
                          <i id="ratedvalue" class={`fa ${x.meetings[0].rating>0 ? "fa-star starrated" : "fa-star ra"}` } onClick={() => openStarRating(x,1)}></i>
                          <i id="ratedvalue" class={`fa ${x.meetings[0].rating>1 ? "fa-star starrated" : "fa-star ra"}`} onClick={() => openStarRating(x,2)}></i>
                          <i id="ratedvalue" class={`fa ${x.meetings[0].rating>2 ? "fa-star starrated" : "fa-star ra"}`} onClick={() => openStarRating(x,3)}></i>
                          <i id="ratedvalue" class={`fa ${x.meetings[0].rating>3 ? "fa-star starrated" : "fa-star ra"}`} onClick={() => openStarRating(x,4)}></i>
                        </span>
                    :
                    ''
                  }

                  {/* {(x?.relationId && parseInt(x?.relationId) == 2 ) ? <div class="">{!x.rating?<span className="starratedvalue">  
                  <i id="ratedvalue" class="fa fa-star unrated" onClick={() => openStarRating(x)}></i>
                  <i id="ratedvalue" class="fa fa-star unrated" onClick={() => openStarRating(x)}></i>
                  <i id="ratedvalue" class="fa fa-star unrated" onClick={() => openStarRating(x)}></i>
                  <i id="ratedvalue" class="fa fa-star unrated" onClick={() => openStarRating(x)}></i>
                  <UncontrolledTooltip target='ratedvalue' placement='top'>
                        Rate Meeting
                  </UncontrolledTooltip>
                 </span>:
                  <span className="starratedvalue">  
                  <i id="ratedvalue" class={`fa ${x.rating>0 ? "fa-star starrated" : "fa-star ra"}`} onClick={() => openStarRating(x)}></i>
                  <i id="ratedvalue" class={`fa ${x.rating>1 ? "fa-star starrated" : "fa-star ra"}`} onClick={() => openStarRating(x)}></i>
                  <i id="ratedvalue" class={`fa ${x.rating>2 ? "fa-star starrated" : "fa-star ra"}`} onClick={() => openStarRating(x)}></i>
                  <i id="ratedvalue" class={`fa ${x.rating>3 ? "fa-star starrated" : "fa-star ra"}`} onClick={() => openStarRating(x)}></i>
                  <UncontrolledTooltip target='ratedvalue' placement='top'>
                        Rate Meeting
                  </UncontrolledTooltip>
                 </span>
                 }
                 {
                  (x?.relationId && parseInt(x?.relationId) == 2 && x.hasOwnProperty('partnerRating')) && <div class="ribbon-10" style={{backgroundColor:getBackgroundColor(x)}}> 
                  <span className="ratingText">{calculateBusinessPotential(x)}</span>
                  {(!x.rating||!x.partnerRating)&&<span class="titletag">{getTitle(x)}</span>}
                  </div> 
                 }
                  // {/* {x.rating?<span class="ratedvalue">{x.rating?x.rating:''}<i id="ratedvalue" class="fa fa-star rated" onClick={() => openStarRating(x)}></i>
                  //     <UncontrolledTooltip target='ratedvalue' placement='top'>
                  //       Rate Meeting
                  //     </UncontrolledTooltip>
                  //   </span>:
                  //   <span class="ratedvalue"><i id="ratedvalue" class="fa fa-edit" onClick={() => openStarRating(x)}></i>
                  //   <UncontrolledTooltip target='ratedvalue' placement='top'>
                  //       Rate Meeting
                  //     </UncontrolledTooltip></span>} 
                  </div> : ''} */}

                </div>
              </Card>
            </Col>)
          })}</>
        }
      </>
      )
    }


    return (
      <Card>

        <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore} />

        <StarRating isOpen={starRating} handleSave={saveStarRating} handleClose={closeStarRating} value={RatingUpdate} />

        <ChannelDismiss isOpen={dismissPopUp} dismissFrom={dissmissFrom} handleClose={dismissPopUpClose} value={UpdateData} updateChannel={updateChannel} />
        <ChannelConnect isOpen={connectPopUp} handleClose={connectPopUpClose} value={UpdateData} updateChannel={updateChannel} outboundRemaind={outboundRemaind} />
        <AskmeetingPopup isOpen={askMeeting} handleClose={askMeetingPopUpClose} value={selectedContact} handleSave={saveAskMeeting} />
        <Dialog open={isModalPopup} value={modalPopValue} handleSave={confirmPopup} handleClose={closePopup} outboundRemaind={outboundRemaind} />
        <UpgradeModal open={isUpgradePopup} value={modalPopValue} subscriptionTurn={() => history.push('/admin/Subscription')} gotoWallet={closeUpgradePopup} handleClose={closeUpgradePopup} />
        <CardBody className="">
          <h3> {title} </h3>
          <div >
            <Row>
              {contact(_elements)}
            </Row>
            {showMore &&
              <Row>
                {contact(_remain)}
              </Row>
            }
          </div>
          {data?.records?.length > 4 && <h3 onClick={more_click} className='float-right text-primary' style={{ cursor: 'pointer' }}>{!showMore ? 'Show More' : 'Show Less'}</h3>}
        </CardBody>
      </Card>
    )
  }
}

const getStatus = (contacts) => {
  let status = '';
  console.log("getstatus:", contacts);
  if (parseInt(contacts.relationId) !== 0 && contacts.request) {
    if (parseInt(contacts.relationId) === 1) {
      status = 'Best Match'
    }
    if (parseInt(contacts.relationId) === 2) {
      status = 'Connected'
    }
    if (parseInt(contacts.relationId) === 5) {
      status = 'Dismissed'
    }
    if (contacts.request === 'SAVELATER') {
      status = 'Saved For Later'
    }
    if (contacts.request === 'OUT' && (parseInt(contacts.relationId) === 7 || parseInt(contacts.relationId) === 11)) {
      status = 'Outbound'
    }
    if (contacts.request === 'IN' && (parseInt(contacts.relationId) === 7 || parseInt(contacts.relationId) === 10)) {
      status = 'Inbound'
    }
    if (contacts.request === 'REQUESTINTRO') {
      status = 'Request Intro'
    }
  } else {
    status = 'Non Suggestion'
  }
  return status;
}

const calculateBusinessPotential = (contacts) => {
  let partnerRating = contacts.meetings[0].vendorRating;
  let rating = contacts.meetings[0].rating;
  if (partnerRating && rating) {
    const crossRating = (partnerRating + rating) / 2;
    return crossRating === 4
      ? "Business Potential : High"
      : crossRating >= 3 && crossRating < 4
      ? "Business Potential : Medium"
      : "Business Potential : Low";
  } else if (partnerRating || rating) {
    return partnerRating
      ? "Business Potential : Pending"
      : "Business Potential : Not calculated";
  } else {
    return "Business Potential : None";
  }
}

const getTitle = (contacts) => {
   if(contacts.partnerRating&&!contacts.rating){
     return "we have received a rating from the other party but we need your input to calculate the business potential score."
  }else if (!contacts.partnerRating&&contacts.rating){
    return "we cannot calculate the potential of this meeting because the other party did not rate the meeting."
  }else if(!contacts.partnerRating&&!contacts.rating)
  {
    return "we cannot calculate the potential of this meeting because neither you nor the other party rated this meeting."
  }
}

const calculateAverageRating = (x) => {
  return (x.partnerRating + x.rating) / 2;
}

const getBackgroundColor = (x) => {
  if (x.partnerRating && x.rating) {
    const averageRating = calculateAverageRating(x);

    if (averageRating === 4) {
      return "#1fa91f";
    } else if (averageRating >= 3 && averageRating < 4) {
      return "#dd9e2b";
    }else {
      return "#c9c923"; 
    }
  }

  return "grey";
}

const UpdateChannelData = {
  connectionStatusTypeId: null,
  channelJCTId: null,
  dismissreasonid: null,
  otherreasons: null,
  leadManagementTag: null,
  partnerLeadManagementTag: null,
  note: null,
  contactid: null,
  receiver_mail: null,
  receiver_name: null,
  receiver_photo: null,
  videoUrl: null
}

const connectLimit = {
  2: {
    endat: null,
    limit: -1,
    suggestioncount: 0,
    suggestionfor: "VENDOR_OTB"
  },
  3: {
    endat: null,
    limit: 0,
    suggestioncount: 0,
    suggestionfor: "DISTRIBUTOR_OTB"
  },
  4: {
    endat: null,
    limit: 0,
    suggestioncount: 0,
    suggestionfor: "RETAILER_OTB"
  }
}