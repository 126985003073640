import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useForm } from "react-hook-form";
import { commonService } from '_services/common.service';
import { ListGroupItem, ListGroup } from 'reactstrap';
import { FormAutocomplete } from "_components/FormElements/FormInput";
import Slider from '@material-ui/core/Slider';
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));



const ProductFilter = (props) => {
  //   const classes = useStyles();

  const { activeTab, handleChangeSelect, filterValueState, resetFilterValue } = props;
  const [filterOptionState, setFilterOption] = useState({
    PositionOption: [{ name: 'MID', id: '1' }, { name: 'HIGH', id: '2' }], Brand_Option: [], Profile_Option: [], Location_Option: [], Family_Option: [], Category_Option: [],
    hot_option: [
      { id: '1', name: 'Eco Friendly Gadgets' }, { id: '2', name: 'Work from Home Gadgets' }, { id: '3', name: 'Life Style Gadgets' },
      { id: '4', name: 'French Tech' }, { id: '5', name: 'Taiwan Excellence' }
    ],
    businessType: [
      { id: '1', name: 'B2C' }, { id: '2', name: 'B2B' }]
  });
  const user = JSON.parse(localStorage.getItem('CHuser'));
  const { control } = useForm({ mode: 'onBlur' });


  let [changed, setChanged] = useState('');
  let [flag, setFlag] = useState(false);
  let [expand, setExpand] = useState(props.expand);
  let [fromCat, setFromCat] = useState(props.fromCat);

  useEffect(() => {
    getSearchAggregation();
    setFlag(true);
    // getOptions();
  }, [])

  useEffect(() => {
    //console.log("parent:", filterValueState.parent);
    if ((flag && filterValueState.categories?.length) || (filterValueState.brand?.length == 0 && filterValueState.family?.length == 0 && filterValueState.categories?.length == 0)) {
      getSearchAggregation();
    }
  }, [filterValueState.categories]);

  useEffect(() => {
    //console.log("parent:", filterValueState.parent);
    if ((flag && filterValueState.brand?.length) || (filterValueState.brand?.length == 0 && filterValueState.family?.length == 0 && filterValueState.categories?.length == 0)) {
      getSearchAggregation();
    }
  }, [filterValueState.brand]);

  useEffect(() => {
    //console.log("parent:", filterValueState.parent);
    if ((flag && filterValueState.family?.length) || (filterValueState.brand?.length == 0 && filterValueState.family?.length == 0 && filterValueState.categories?.length == 0)) {
      getSearchAggregation();
    }
  }, [filterValueState.family]);

  useEffect(() => {
    //console.log("parent:", filterValueState.parent);
    //if ((flag && filterValueState.family?.length) || (filterValueState.brand?.length == 0 && filterValueState.family?.length == 0 && filterValueState.categories?.length == 0)) {
    getSearchAggregation();
    //}
  }, [filterValueState.businessType]);



  const getOptions = async () => {
    await axios.get('guest/country').then(res => {
      filterOptionState.Location_Option = res.data;

    })
    setFilterOption({ ...filterOptionState });
  }

  const setFamilyFilter = async () => {
    let data = {
      categoryId: [],
      brandId: [],
      familyId: [],
    }


    let products = await commonService.filterPostService({ data }, '/guest/productsaggr/' + filterValueState.parent);

    if (products.length > 0) {
      let data = products;


      filterOptionState.Family_Option = await commonService.filterPostService({
        data
      }, '/guest/familyFilter');

      if (fromCat == 2) {
        filterOptionState.Category_Option = await commonService.filterPostService({
          data
        }, '/guest/categoryGuestFilter');
      }

      setFilterOption({ ...filterOptionState });
    }
  }

  const getSearchAggregation = async () => {

    let data;
    data = {
      categoryId: filterValueState.categories ? filterValueState.categories.map(x => x.id) : [],
      brandId: filterValueState.brand ? filterValueState.brand.map(x => x.id) : [],
      familyId: filterValueState.family ? filterValueState.family.map(x => x.id) : [],
      businessType: filterValueState.businessType ? filterValueState.businessType.map(x => x.id) : [],
    }


    let products = await commonService.filterPostService({ data }, '/guest/productsaggr/' + filterValueState.parent);

    if (products.length > 0) {
      let data = products;

      if (filterValueState.parent == 'categories' || filterValueState.parent == 'family' || filterValueState.brand?.length == 0) {
        filterOptionState.Brand_Option = await commonService.filterPostService({
          data
        }, '/guest/brandFilter');
        setFilterOption({ ...filterOptionState });
      }

      // filterOptionState.Brand_Option = await commonService.filterPostService({
      //   data
      // }, '/guest/brandFilter');
      // setFilterOption({ ...filterOptionState });

      if (filterValueState.parent == 'categories' || filterValueState.parent == 'brand' || filterValueState.family?.length == 0) {
        filterOptionState.Family_Option = await commonService.filterPostService({
          data
        }, '/guest/familyFilter');
        setFilterOption({ ...filterOptionState });
      }

      if (filterValueState.parent == 'family' || filterValueState.parent == 'brand' || filterValueState.categories?.length == 0) {
        //setExpand('');
        //setFromCat(false);
        filterOptionState.Category_Option = await commonService.filterPostService({
          data
        }, '/guest/categoryGuestFilter');
        setFilterOption({ ...filterOptionState });
      }

      if (fromCat) {
        setFamilyFilter();
        fromCat = 0;
        setFromCat(fromCat);
      }

      // filterOptionState.Profile_Option= await commonService.filterPostService({
      //   data
      //   },'/product/profileFilter');      
      // setFilterOption({...filterOptionState});    

      // filterOptionState.Location_Option= await commonService.filterPostService({
      //   data
      //   },'/product/locationFilter');      
      // setFilterOption({...filterOptionState});    

    }



    // if(data){
    //   let options = data.data;
    //   if(options){   
    //  filterOptionState.Brand_Option=options.brand;
    //  filterOptionState.Family_Option=options.family;
    //  filterOptionState.Category_Option=options.category;
    //  filterOptionState.Profile_Option=options.profile;
    //  filterOptionState.Location_Option=options.location;
    //  setFilterOption({...filterOptionState});
    //  }
    //  }
    console.log('filterOptionState', filterOptionState);
  }

  // const toggle = tab => {
  //   if (activeTab !== tab) setActiveTab(tab);
  // }
  //const [activeTab, setActiveTab] = useState('1');
  //const { control } = useForm({ mode:'onBlur' });

  const useStyles = makeStyles({
    root: {
      width: "100%",
    },
  });
  function valuetext(value) {
    return `${value}°C`;
  }

  const classes = useStyles();
  const [value, setValue] = React.useState([20, 37]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const style = { height: '18rem' };

  return (
    <div className='fav-ser1 p-2'>
      <div className={classes.root}>
        <Accordion defaultExpanded={expand == 'family'}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>Families</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ListGroup>
                <ListGroupItem style={filterOptionState.Family_Option.length > 0 ? style : {}}>
                  <div className=" text-center mt-2">
                    <div className="form-group">
                      <FormAutocomplete
                        id="Positioning"
                        name="Positioning"
                        label=""
                        options={filterOptionState.Family_Option}
                        getOptionSelected={(options, value) => options.name === value.name}
                        getOptionsLabel
                        placeholder="Type Families"
                        multiple
                        // disableCloseOnSelect
                        limitTags={1}
                        control={control}
                        input='family'
                        value={filterValueState.family}
                        open={true}
                        popupIcon=''
                        disablePortal={true}
                        size='small'
                        ListboxProps={{ style: { maxHeight: '8rem' } }}
                        handleChangeSelect={handleChangeSelect}
                      />
                    </div>
                  </div>
                </ListGroupItem>
              </ListGroup>
            </Typography>
          </AccordionDetails>
        </Accordion>

        {/* {(!!filterValueState.family?.length || expand == 'category') && */}
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>Categories</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ListGroup>
                <ListGroupItem style={filterOptionState.Category_Option.length > 0 ? style : {}}>
                  <div className=" text-center mt-2">
                    <div className="form-group">
                      <FormAutocomplete
                        id="Positioning"
                        name="Positioning"
                        label=""
                        getOptionSelected={(options, value) => options.name === value.name}
                        options={filterOptionState.Category_Option}
                        placeholder="Type Categories"
                        multiple
                        // disableCloseOnSelect
                        limitTags={1}
                        control={control}
                        input='categories'
                        value={filterValueState.categories}
                        open={true}
                        popupIcon=''
                        disablePortal={true}
                        size='small'
                        ListboxProps={{ style: { maxHeight: '8rem' } }}
                        handleChangeSelect={handleChangeSelect}
                      />
                    </div>
                  </div>
                </ListGroupItem>
              </ListGroup>
            </Typography>
          </AccordionDetails>
        </Accordion>


        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>Brand</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ListGroup>
                <ListGroupItem style={filterOptionState.Brand_Option.length > 0 ? style : {}}>
                  <div className=" text-center mt-2">
                    <div className="form-group">
                      <FormAutocomplete
                        id="Positioning"
                        name="Positioning"
                        label=""
                        getOptionSelected={(options, value) => options.name === value.name}
                        options={filterOptionState.Brand_Option}
                        placeholder="Type Brands"
                        multiple
                        // disableCloseOnSelect
                        limitTags={1}
                        control={control}
                        input='brand'
                        value={filterValueState.brand}
                        open={true}
                        popupIcon=''
                        disablePortal={true}
                        size='small'
                        ListboxProps={{ style: { maxHeight: '8rem' } }}
                        handleChangeSelect={handleChangeSelect}
                      />
                    </div>
                  </div>
                </ListGroupItem>
              </ListGroup>
            </Typography>
          </AccordionDetails>
        </Accordion>


        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>Hot Topics</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ListGroup>
                <ListGroupItem style={filterOptionState.hot_option.length > 0 ? style : {}}>
                  <div className=" text-center mt-2">
                    <div className="form-group">
                      <FormAutocomplete
                        id="HotTopics"
                        name="HotTopics"
                        label=""
                        getOptionSelected={(options, value) => options.name === value.name}
                        options={filterOptionState.hot_option}
                        placeholder="Hot Topics"
                        multiple
                        // disableCloseOnSelect
                        limitTags={1}
                        control={control}
                        input='hot_topics'
                        value={filterValueState.hotValue}
                        open={true}
                        popupIcon=''
                        disablePortal={true}
                        size='small'
                        ListboxProps={{ style: { maxHeight: '8rem' } }}
                        handleChangeSelect={handleChangeSelect}
                      />
                    </div>
                  </div>
                </ListGroupItem>
              </ListGroup>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>Business Type</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ListGroup>
                <ListGroupItem style={filterOptionState.businessType.length > 0 ? style : {}}>
                  <div className=" text-center mt-2">
                    <div className="form-group">
                      <FormAutocomplete
                        id="businessType"
                        name="businessType"
                        label=""
                        getOptionSelected={(options, value) => options.name === value.name}
                        options={filterOptionState.businessType}
                        placeholder="BusinessType"
                        multiple
                        // disableCloseOnSelect
                        limitTags={1}
                        control={control}
                        input='businessType'
                        value={filterValueState.businessType}
                        open={true}
                        popupIcon=''
                        disablePortal={true}
                        size='small'
                        ListboxProps={{ style: { maxHeight: '8rem' } }}
                        handleChangeSelect={handleChangeSelect}
                      />
                    </div>
                  </div>
                </ListGroupItem>
              </ListGroup>
            </Typography>
          </AccordionDetails>
        </Accordion>


      </div>
    </div>
  );
}

export default ProductFilter;