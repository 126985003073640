import React, { useState, useEffect } from "react";
import { Card,CardBody,CardHeader, Container,Row} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader";
import {history} from "_helpers/history";

const Ordersuccess = (props) => {
    const {state}=props;

    return (
        <>
        <SimpleHeader location={props.match} />
        <div className="main-content">
            <Container className="mt-8 col-lg-6 col-md-6 col-sm-12" fluid>
            <Card>
            <CardBody style={{padding:'1rem'}} >
                <h3 className="mb-3 mt-4 text-center">Your Order Placed Successfully</h3>
                <div className="text-center">
                <i class="far fa-check-circle success-i"></i>
                </div>
                <h2 className="mt-3 text-center">Order No: {state.orderId}</h2>
                <p className="mb-0 text-center">Thankyou for your order. Your order will be transferef to the vendor and upon confirmation you'll receive a pro-forma invoice. Track your order status in Sample Order Module.</p>
                <div className="text-center mt-4">            
            <button type="submit" onClick={() => history.push('/admin/dashboard')} className="btn btn-primary">Continue</button>
          </div>
            </CardBody>
            </Card>
            </Container>            
        </div>
        </>
    );
};

const Orderfailed = (props) => {
    return (
        <>
        <SimpleHeader location={props.match} />
        <div className="main-content">
            <Container className="mt-8 col-lg-6 col-md-6 col-sm-12" fluid>
            <Card>
            <CardBody style={{padding:'1rem'}} >
                <h3 className="mb-3 mt-4 text-center">Your Order Failed</h3>
                <div className="text-center">
                <i class="far fa-times-circle fail-i"></i>
                </div>
                <h2 className="mt-3 text-center">Order No: ORD20102124</h2>
                <p className="mb-0 text-center">Thankyou for your order. Your order will be transferef to the vendor and upon confirmation you'll receive a pro-forma invoice. Track your order status in Sample Order Module.</p>
                <div className="text-center mt-4">            
            <button type="submit" className="btn btn-primary">Continue</button>
          </div>
            </CardBody>
            </Card>
            </Container>            
        </div>
        </>
    );
};

export {
    Orderfailed,
    Ordersuccess
  } 