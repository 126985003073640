import React, { useState, useEffect } from "react";

import { Row, Col } from "reactstrap";
import GuestNavBar from "./GuestNavbar/GuestNavbar";
import GuesProducts from "./GuesProducts";
import ProductFilter from "./ProductFilter";
import { commonService } from '_services/common.service';
import GuestContact from "./GuestContact";
import GuestFooter from "../GuestPage/GuestNavbar/GuestFooter";
import { browserName, browserVersion } from "react-device-detect";

export const GuesProductList = props => {
  const [collapsed, setCollapsed] = useState(true);
  console.log('props', props);
  const pathValue = props.location.state?.value;
  const type = props.location.state?.type ? props.location.state?.type : 3;
  let hotValue = props.location.state?.hot_topic ? [props.location.state?.hot_topic] : [];
  let brand = props.location.state?.brandId ? [props.location.state?.brandId] : [];
  let category = props.location.state?.productCategorie ? props.location.state?.productCategorie : [];
  let familyId = props.location.state?.familyId ? props.location.state?.familyId : [];
  let expand = hotValue.length ? 'hotTopics' : brand.length ? 'brand' : category.length ? 'category' : ''; //location.length ? 'family' :
  let fromCat = props.location.state?.fromCat ? props.location.state?.fromCat : false;
  let bType = props.location.state?.type ? [{name: props.location.state?.type=='1' ? 'B2C' : 'B2B', id: props.location.state?.type}] : [];

  const [filterValueState, setFilterValue] = useState({ parent: 'family', location: [], family: familyId, brand: brand, categories: category, hotValue: hotValue, businessType: bType, orderby: 'product' });
  let [compData, setCompData] = useState([]);
  const [loading, setLoading] = useState(false);

  // let categories = props.location.state?.productCategorie ? props.location.state?.productCategorie : [];
  // filterValueState.categories=categories;
  // setFilterValue({...filterValueState});
  // const fetchMoreItems = async() => {

  //   await getproductlist();
  //   setIsFetching(false);

  // }

  // const [isFetching, setIsFetching] = useInfiniteScroll(fetchMoreItems);

  let [page, setPage] = useState(0);
  let [rowsPerPage, setRowsPerPage] = useState(12);
  let [total, setTotal] = useState(0);

  let viewId = props.location.state ? props.location.state.viewId : 1;
  let title = 'Products';//hotValue.length ? 'Hot Topics' : 'New Products';

  // let title = viewId == '1' ? 'Buyers' : viewId == '2' ? 'Vendor' : viewId == '3' ? 'Distributor' : viewId == '4' ? 'Retailer' : 'Buyers';

  useEffect(() => {
    compData = [];
    setCompData([]);
    // setIsFetching(false);
    setLoading(true);
    getproductlist();
    updatenavigation();
  }, [filterValueState]);

  const [sortValue, setSortValue] = useState('Name (A - Z)');
  const [sort, setSort] = useState('asc');

  const updatenavigation= async()=>{
    let navigationData = {}
    navigationData.browser = browserName + ' , Version :'+browserVersion;;
    navigationData.navigatedPage = window.location;
    commonService.postService(navigationData, '/guest/updateNavigationHistory');
  }

  const getproductlist = async () => {
    // setLoading(true);
    let postData = {
      filter: getFilterData(),
      order: sort ? sort : 'asc',
      // search:search?search.name:null,
      offset: compData.length, //page * rowsPerPage,
      // // offset:1,
      orderby: filterValueState.orderby ? filterValueState.orderby : 'product',
      limit: rowsPerPage,
    }

    let res = await commonService.postService(postData, 'guest/my-products/').catch(err => setLoading(false));
    if (res) {
      console.log('aggre', res.data);
      setCompData(prevState => ([...prevState, ...res.data.result]));
      // setCompData(res.data.result);
      setTotal(res.data.total);
      setLoading(false);
    }

  }

  const getFilterData = () => {
    let filter = {
      brandId: null,
      // countryId:null,
      businessType: null,
      productCategoryId: null,
      family: null,
      hot_topic: null,
    };
    // if(filterValueState.location && filterValueState.location.length>0){
    //   filter.countryId=filterValueState.location.map(x=> parseInt(x.id));
    // }
    if (filterValueState.family && filterValueState.family.length > 0) {
      filter.family = filterValueState.family.map(x => parseInt(x.id));
    }
    if (filterValueState.brand && filterValueState.brand.length > 0) {
      filter.brandId = filterValueState.brand.map(x => parseInt(x.id));
    }
    if (filterValueState.categories && filterValueState.categories.length > 0) {
      filter.productCategoryId = filterValueState.categories.map(x => parseInt(x.id));
    }
    if (filterValueState.hotValue && filterValueState.hotValue.length > 0) {
      filter.hot_topic = filterValueState.hotValue.map(x => parseInt(x.id));
    }

    if (filterValueState.businessType && filterValueState.businessType.length > 0) {
      filter.businessType = filterValueState.businessType.map(x => parseInt(x.id));
      // if (filter.businessType.length == 2)
        filter.businessType.push(3);
    }


    console.log('filteredstate', filterValueState);
    return filter;
  }


  const handleChangePage = (event, newPage) => {
    page = newPage;
    setPage(page);
    getproductlist();
  };

  const handleChangeRowsPerPage = (event) => {
    rowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(rowsPerPage);
    getproductlist();
  };

  const handleChangeSelect = (input, value) => {
    console.log('input, value', input, value);

    if (input === 'location') {
      filterValueState.location = value;
    }
    if (input == 'family') {
      filterValueState.family = value;
      //filterValueState.categories = [];
      if (filterValueState.categories?.length == 0 && filterValueState.brand?.length == 0)
        filterValueState.parent = 'family';
    }
    if (input === 'brand') {
      filterValueState.brand = value;
      if (filterValueState.categories?.length == 0 && filterValueState.family?.length == 0)
        filterValueState.parent = 'brand';
    }
    if (input === 'categories') {
      filterValueState.categories = value;
      //filterValueState.brand = [];
      if (filterValueState.brand?.length == 0 && filterValueState.family?.length == 0)
        filterValueState.parent = 'categories';
    }
    if (input === 'businessType') {
      filterValueState.businessType = value;
    }
    if (input === 'hot_topics') {
      filterValueState.hotValue = value;
    }
    if (input == 'brandName') {
      filterValueState.orderby = value;
      setSort('asc');
      setSortValue('Brand');
    }

    if (input == 'product1') {
      filterValueState.orderby = value;
      setSortValue('Name (A - Z)');
    }
    if (input == 'product2') {
      filterValueState.orderby = value;
      setSortValue('Name (Z - A)');
    }
    if (input == 'country') {
      filterValueState.orderby = value;
      setSort('asc');
      setSortValue('country');
    }
    if (input == 'order') {
      if (sort != value) {
        setSort(value);
      }

    }

    setFilterValue({ ...filterValueState })
    console.log('filterValueState', filterValueState);
  }

  const resetFilterValue = () => {
    filterValueState.location = [];
    filterValueState.brand = [];
    filterValueState.categories = [];
    filterValueState.businessType = [];

    setFilterValue({ ...filterValueState });
  }

  const loadProducts = async () => {
    setLoading(true);
    await getproductlist();
  }

  const toggleNavbar = () => setCollapsed(!collapsed);

  return (
    <div className="main-content">
      <GuestNavBar />


      <Row>
        {/* <Col className="leftFilterBg pt-4" lg="2">
          <ProductFilter handleChangeSelect={handleChangeSelect} filterValueState={filterValueState} resetFilterValue={resetFilterValue} expand={expand} fromCat={fromCat} />

        </Col> */}
        <Col lg="12" className="mt-3">
          <GuesProducts title={title} sort={true} products={compData} total={total} handleChangeSelect={handleChangeSelect} loadProducts={loadProducts} loading={loading}
            sortValue={sortValue} fromHot={hotValue?.length ? hotValue : false} hotValue={filterValueState.hotValue}
            value= {pathValue} type={type}
          />
        </Col>

      </Row>
      <GuestContact />
      <div className="guestDividcontainer">
        <div className="GuestDivider">

        </div>
      </div>
      <GuestFooter />
    </div>


  );
}
export default GuesProductList;