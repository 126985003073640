import React, { useState, useEffect } from "react";

import {
  Card, CardBody, CardHeader, Container, FormGroup, Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup, Col, Row, UncontrolledTooltip, Button
} from "reactstrap";
import { QuillEditor, FormInput, FormAutocomplete, SingleAutocompleteGrouping, FormTextArea, FormAutocompleteGrouping, AddFieldWithPopUpAutocomplete, FormRadio, NewFieldAutocomplete } from "_components/FormElements/FormInput";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { InputLabel, Select, TextField, FormControl, InputAdornment } from '@material-ui/core';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import { history } from '../../../_helpers';
import { subscriptionAction } from '_actions/subscription.action';
import { connect, useSelector, useDispatch } from 'react-redux';
import { commonService } from '_services/common.service';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { Spinner } from "views/Hoc/Spinner";
import SimpleHeader from "components/Headers/SimpleHeader";
import axios from 'axios';
import { eventService } from "_services";
import Moment from 'moment';

const Subscription = (props) => {
  const redux_data = useSelector(state => state.subscription);

  console.log("ratingId:", props.ratingId)
  const [packList, setPackList] = useState([]);
  const dispatch = useDispatch();

  let eventPayment = useSelector(state => state.event)?.eventPayment;

  if (localStorage.getItem('eventRedirect')) {
    let obj = JSON.parse(localStorage.getItem('eventRedirect'));
    eventPayment = obj?.paymentDetails;
  }


  const [value, setValue] = useState('Card');
  const [helperText, setHelperText] = useState('By default, Auto-Renewal is set to \'On\', for Card option.    You can change to \'Off\' from your Setting page, to avoid auto-renewal.');
  let [planDetails, setPlanDetails] = useState(null);
  let [coupan_amt, setCoupan_Amt] = useState(null);
  let [coupan_dis, setCoupan_Dis] = useState(null);
  let [coupan_code, setCoupan_code] = useState(null);
  let [amountpayable, setAmountpayable] = useState(null);
  let [coupan_error, setCoupan_error] = useState(null);
  let [isLoading, setLoading] = useState(true);
  let [wiretransfer, setWiretransfer] = useState(false);
  let [pendingWireTransfer, setPendingWireTransfer] = useState(false);
  const [isAddon, setIsaddon] = useState(false);

  const [isSecondary, setIsSecondary] = useState(false);

  const [billingAddress, setAddress] = useState({ VAT: null });

  const [payOption, setPayOption] = useState({ card: false, vat: false, coupon: false, invalidVat: false });

  const [currCode, setCurrCode] = useState(null);

  let [paymentAmt, setPaymentAmt] = useState({ totalDiscountedAmount: 0, couponAmount: 0, couponId: null, channelId: null, contactId: null, actualAmount: 0, finalAmount: 0, package: [], eventId: null, taxAmount: 0 });

  useEffect(() => {
    let userData = JSON.parse(localStorage.getItem('CHuser'));
    setLoading(true);
//console.log('Meena eventPayment == ', eventPayment["totalSplit"]);
setPendingWireTransfer(eventPayment["totalSplit"] ? (eventPayment["totalSplit"].filter(x =>  x?.paymentStatus == '0' && x?.wireTransfer=='1').length > 0 )?true:false : false);

//
    (async () => {
      setAddress({ ...billingAddress, VAT: eventPayment.vatNumber })

      if (props.ratingId) {
        getEventData(userData.channelId)
      }
      else if (eventPayment.isPrimary) {

        

        setPackList(eventPayment.package);
        setPaymentAmt({ ...paymentAmt, ...eventPayment });
      } else {


        const isAddon = eventPayment.filter(pay => pay.packageType == 2).length > 0 ? true : false;

        const isSecondary = eventPayment.filter(pay => pay.isSecondaryPackage).length > 0 ? true : false;

        setIsaddon(isAddon);

        setIsSecondary(isSecondary);

        setPackList(eventPayment);

        let offers = []; let vatPercentage; let offerAmount = 0; let vatNumber; let isVatCompany;

        if (isSecondary) {
          await commonService.postService({ eventId: eventPayment[0].eventId, offers: offers, channelTypeId: userData.channelTypeId, channelId: userData.channelId, contactId: userData.contactId }, '/channelAdmin/packageOffers').then(res => {
            //packOffers = res.data.offers;
            vatPercentage = res.data.hasVat;
            vatNumber = res.data.vatNumber;
            isVatCompany = res.data.isVatCompany;
            offerAmount = res.data.offers.filter(x => x.offerType == 1).length ? res.data.offers.filter(x => x.offerType == 1).reduce((a, curr) => parseInt(a) + parseInt(curr.couponOfferValue), 0) : 0;
          })

          paymentAmt.actualAmount = parseFloat(eventPayment.reduce((sum, x) => sum + (x.qty ? (+x.price * x.qty) : +x.price), 0)).toFixed(2);
          paymentAmt.package = eventPayment;
          paymentAmt.channelId = userData.channelId;
          paymentAmt.contactId = eventPayment[0].participants[0];
          paymentAmt.participants = eventPayment[0].participants;
          paymentAmt.finalAmount = paymentAmt.actualAmount;
          paymentAmt.eventId = eventPayment[0].eventId;

          paymentAmt.currCode = eventPayment.currCode;

          paymentAmt.intentAmount = vatPercentage ? (parseFloat(paymentAmt.actualAmount) + (parseFloat(paymentAmt.actualAmount) * (vatPercentage / 100))).toString() : paymentAmt.actualAmount.toString();

          paymentAmt.vatPercentage = vatPercentage;
          paymentAmt.isVatCompany = isVatCompany;
          paymentAmt.vatNumber = vatNumber ? vatNumber : '';
          paymentAmt.isSecondaryPackage = isSecondary;
        } else {
          await commonService.postService({ eventId: eventPayment[0].eventId, offers: offers, channelTypeId: userData.channelTypeId, channelId: userData.channelId, contactId: userData.contactId }, '/channelAdmin/packageOffers').then(res => {
            //packOffers = res.data.offers;
            vatPercentage = res.data.hasVat;
            vatNumber = res.data.vatNumber;
            isVatCompany = res.data.isVatCompany;
            offerAmount = res.data.offers.filter(x => x.offerType == 1).length ? res.data.offers.filter(x => x.offerType == 1).reduce((a, curr) => parseInt(a) + parseInt(curr.couponOfferValue), 0) : 0;
          })

          paymentAmt.actualAmount = parseFloat(eventPayment.reduce((sum, x) => sum + (x.qty ? (+x.price * x.qty) : +x.price), 0)).toFixed(2);
          paymentAmt.package = eventPayment;
          paymentAmt.channelId = userData.channelId;
          paymentAmt.contactId = userData.contactId;
          paymentAmt.finalAmount = paymentAmt.actualAmount;
          paymentAmt.eventId = eventPayment[0].eventId;

          paymentAmt.intentAmount = vatPercentage ? (parseFloat(paymentAmt.actualAmount) + (parseFloat(paymentAmt.actualAmount) * (vatPercentage / 100))).toString() : paymentAmt.actualAmount.toString();

          paymentAmt.vatPercentage = vatPercentage;
          paymentAmt.isVatCompany = isVatCompany;
          paymentAmt.vatNumber = vatNumber ? vatNumber : '';
        }


        console.log("eventPayment:", paymentAmt)
        setPaymentAmt({ ...paymentAmt });
      }


      if (eventPayment.currCode === 'USD') {
        setCurrCode('$')
      } else {
        setCurrCode('€')
      }

      setLoading(false);
    })();







    // getSubscriptionPrice();



  }, [])

  const getEventData = async (id) => {
    //setLoading(true);
    await axios.get('/channel/getEventListing').then(res => {
      let eventDetails = res.data.registrations.filter(x => x.channel && x.channel['channelId'] == id).length ? res.data.registrations.filter(x => x.channel && x.channel['channelId'] == id)[0] : {};
      // console.log("eventDetails:",eventDetails)

      paymentAmt.actualAmount = eventDetails.channel.package['price'];
      paymentAmt.package = [{ packageId: eventDetails.channel.package['packageId'], price: eventDetails.channel.package['price'], packageName: eventDetails.channel.package['packageName'] }];
      paymentAmt.channelId = id;
      paymentAmt.contactId = eventDetails.registeredBy;
      paymentAmt.finalAmount = paymentAmt.actualAmount;
      paymentAmt.eventId = eventDetails.eventId;
      paymentAmt.isSeller = eventDetails.channel.package['isSeller'];
      paymentAmt.isBuyer = eventDetails.channel.package['isBuyer'];


      setPackList(paymentAmt.package);
      setPaymentAmt({ ...paymentAmt, isPrimary: true });
    });
    // setLoading(false);
  }

  //console.log("payment:", paymentAmt)

  const getSubscriptionPrice = async () => {
    const subId = redux_data.select_plan;
    console.log(subId);
    setLoading(true);
    let res = await commonService.postService({
      subscriptionId: parseInt(subId.subscriptionId),
      paymentMethodId: 1,
      transactionId: null
    }, 'channel/subscription/subscribe_amt_evaluvate').catch(err => console.log('err', ToastsStore.error(err.response.data.message)));

    if (res) {
      planDetails = res.data;
      setPlanDetails(planDetails);
      setWiretransfer(false)
      console.log('plandet', planDetails);
      paymentAmt.planAmt = (planDetails.original_amount / 100).toFixed(2);
      paymentAmt.amountpayable = (planDetails.amount / 100).toFixed(2);
      paymentAmt.plan = planDetails.plan;
      paymentAmt.tax = (planDetails.taxAmount / 100).toFixed(2);
      paymentAmt.period = planDetails.period;
      paymentAmt.periodId = parseInt(planDetails.periodId);
      paymentAmt.planId = parseInt(planDetails.planId);
      paymentAmt.coupon_discount = (planDetails.coupon_discount / 100).toFixed(2);
      setPaymentAmt({ ...paymentAmt });
      props.subscriptionRedux(paymentAmt.amountpayable, 'PAYMENT_AMT');
      setAmountpayable(paymentAmt.amountpayable);
      if (parseInt(paymentAmt.planId) === 1) {
        setValue(null);
      }
    }
    setLoading(false);
  }

  const handleRadioChange = (event) => {
    //event.preventDefault();
    // props.subscriptionRedux(paymentAmt.amountpayable,'PAYMENT_AMT');
    setValue(event.target.value);
    if (event.target.value === 'Card') {
      setHelperText('By default, Auto-Renewal is set to \'On\', for Card option.    You can change to \'Off\' from your Setting page, to avoid auto-renewal.');
    } else if (event.target.value === 'PayPal') {
      setHelperText('You have paypal account.');
    } else {
      setHelperText('Wire Transfer details will be sent to you via email, once you click \'Subscribe\'.');
    }


  };

  const handleSave = async () => {

    let invalidVat = false;

    //  commented for  on time production issue
    // if (billingAddress.VAT && (paymentAmt.isBuyer == '1' || !paymentAmt?.isVatCompany)) {
    //   await axios.post('/setting/checkVat', { forEvent: true, vatNumber: billingAddress.VAT }).then(res => {
    //     if (res.data.valid != 1 && res.data.valid != 2) {
    //       invalidVat = true;
    //     }
    //   }).catch(err => console.log('checkvat error', err));
    // }

    if (billingAddress.VAT && (paymentAmt.isSeller == '1' && paymentAmt?.isVatCompany)) {
      await axios.post('/setting/checkVat', { forEvent: true, vatNumber: billingAddress.VAT }).then(res => {
        if (res.data.valid != 1 && res.data.valid != 2) {
          invalidVat = true;
        }
      }).catch(err => console.log('checkvat error', err));
    }



    let cardErr = false; let vatErr = false; let couponErr = false;
    // if(value!='Card')
    // cardErr=true;
    if (paymentAmt?.vatPercentage && !paymentAmt?.vatNumber && !billingAddress.VAT) {
      if (paymentAmt.isSeller == '1' && paymentAmt?.isVatCompany)
        vatErr = true;
    }

    // if(paymentAmt?.offers?.length > 0 && paymentAmt.offers?.filter(x => x.offerType == 1).length == 0 && !coupan_code){
    //   setPayOption({...payOption,coupon:true});
    // }

    if (cardErr || vatErr || invalidVat)
      setPayOption({ ...payOption, card: cardErr, vat: vatErr, coupon: couponErr, invalidVat: invalidVat });
    else {

      if (paymentAmt?.vatPercentage && !paymentAmt?.vatNumber && billingAddress.VAT) {

        let vatData = {
          cerificateTypeId: 2,
          cerificateName: 'VAT',
          cerificateNumber: billingAddress.VAT
        }

        await commonService.postService(vatData, 'channel/updateVat').catch(err => console.log('err', ToastsStore.error(err.response.data.message)));
      }

      if(paymentAmt?.eventChannelId == undefined) {
        let userData = JSON.parse(localStorage.getItem('CHuser'));
        let eventChannelId = await axios.post('/channel/getEventChannelId', {'eventId': paymentAmt.eventId, 'contactId':userData.contactId, 'channelId':userData.channelId}).then(res => {
          
          Object.assign(paymentAmt, {
            eventChannelId: res.data?.id,
          });    
        });
      }

      Object.assign(paymentAmt, {
        isAddon: isAddon,
        coupan_code: paymentAmt.coupan_code,
        totalDiscountedAmount: parseFloat(paymentAmt.couponAmount) + parseFloat(paymentAmt.offerAmount),
        taxAmount: (paymentAmt?.vatPercentage) ? paymentAmt.finalAmount * (paymentAmt?.vatPercentage / 100) : 0,
        address: billingAddress, vatNumber: billingAddress.VAT ? billingAddress.VAT : paymentAmt.vatNumber,
        finalAmount: paymentAmt.finalAmount //paymentAmt?.vatPercentage ? paymentAmt.finalAmount + (paymentAmt.finalAmount * (paymentAmt?.vatPercentage / 100)) : paymentAmt.finalAmount
      });

      console.log("paymentsss:", paymentAmt)
      dispatch({ type: 'EVENT_PAYMENT', data: paymentAmt });

      if (value === 'Wire Transfer') {
        subscription();
      } else if (value === 'PayPal') {
        history.push('/admin/eventPaypal');
      } else if (value === 'Alipay') {
        history.push('/admin/eventalipaypayment');
      } else if (value === 'Card') {
        history.push('/admin/eventstripepayment');
      } else {
        history.push('/admin/PaymentSuccess');
      }

    }




    // }
  }

  const dismissEvent = async () => {
    await axios.post('/channel/dismissEvent', { eventId: paymentAmt.eventId }).then(res => {
      ToastsStore.success("Event Dimissed Successfully");
      history.push('/admin/dashboard')
    }).catch(err => console.log('dismiss event error', err));
  }

  const subscription = async () => {
    let userData = JSON.parse(localStorage.getItem('CHuser'));

    const eventDetails = redux_data?.eventDetails;

    setLoading(true);
    if (paymentAmt.isPrimary) {
      // let payment = {
      //   type: 'Wire Transfer',
      //   isPaid: '1',
      //   paymentStatus: '1',
      //   paymentMethod: 2,
      //   finalAmount: parseFloat(paymentAmt.finalAmount),
      //   taxAmount: paymentAmt.taxAmount,
      //   vatNumber: paymentAmt.vatNumber,
      //   subTotal: paymentAmt.finalAmount,// - paymentAmt.taxAmount,
      //   totalDiscountedAmt: paymentAmt.totalDiscountedAmt,
      //   actualAmount: paymentAmt.actualAmount,
      //   packageId: paymentAmt.packageId,
      //   offers: paymentAmt?.offers.filter(o => o.offerType == 2 && o.offerParam == paymentAmt.coupan_code).length ? paymentAmt?.offers : paymentAmt?.offers.filter(o => o.offerType == 1),

      //   splitId: paymentAmt.splitId,
      //   totalSplit: paymentAmt.totalSplit,
      //   eventName: eventDetails?.event_name ? eventDetails.event_name : paymentAmt.event_name,

      //   coupan_code: paymentAmt.coupan_code,
      //   eventId: paymentAmt.eventId,
      //   paymentTransactionId: 'Wire Transfer',// paymentAmt.paymentIntent.id,

      // };

      //let result = await eventService.eventPaymentRegistration(payment);
      let result = await eventService.eventPaymentRegistration(2, paymentAmt, 'Wire Transfer');

      if (result) {
        // console.log("famt:", paymentAmt.finalAmount);

        const paymentData = { ...paymentAmt, channelId: userData.channelId, contactId: userData.contactId, paymentMethod: 2, type: 'Wire Transfer', subscriptionTypeId: 1, paymentIndent: 'Wire Transfer',splitId: eventPayment.splitId }
        // const subId = redux_data.select_plan;
        const response = await commonService.postService(
          paymentData
          , 'channel/subscription/eventSubscribe').catch(err => console.log('err', ToastsStore.error(err.response.data.message)));
        if (response) {

          ToastsStore.success("Wiretransfer Submitted Successfully");
          setLoading(false);
          history.push('/admin/PaymentSuccess');
        }
      }

    } else if (paymentAmt.isSecondaryPackage) {
      // let payment = {
      //   type: 'Wire Transfer',
      //   isPaid: '0',
      //   paymentStatus: '0',
      //   paymentMethod: 2,
      //   finalAmount: paymentAmt.finalAmount,
      //   taxAmount: paymentAmt.taxAmount,
      //   vatNumber: paymentAmt.vatNumber,
      //   subTotal: paymentAmt.finalAmount,// - paymentAmt.taxAmount,
      //   totalDiscountedAmt: paymentAmt.totalDiscountedAmt,
      //   actualAmount: paymentAmt.actualAmount,
      //   packageId: paymentAmt.package[0].packageId,
      //   splitId: paymentAmt.splitId,
      //   totalSplit: paymentAmt.totalSplit,
      //   eventName: paymentAmt.eventName,
      //   isSplit: paymentAmt.paymentMethod == 1 ? false : true,
      //   offers: paymentAmt?.offers?.filter(o => o.offerType == 2 && o.offerParam == paymentAmt.coupan_code).length ? paymentAmt?.offers.filter(o => o.offerParam == paymentAmt.coupan_code) : paymentAmt?.offers?.filter(o => o.offerType == 1),

      //   //offers: paymentDetails.coupan_code?paymentDetails.offers.filter(x => x.offerType==2):paymentDetails.offers.filter(x => x.offerType==1),


      //   coupan_code: paymentAmt.coupan_code,
      //   eventId: paymentAmt.eventId,
      //   paymentTransactionId: 'Wire Transfer',
      //   //pennyInvoiceId: paymentAmt.pennyInvoiceId

      // };

      let paidDetails = {
        contactId: userData.contactId,
        channelId: userData.channelId,
        packageId: paymentAmt.package[0].packageId,
        eventId: paymentAmt.eventId,
        paymentMethod: 1,
        splitId: 1,
        paymentDate: new Date(),
        paymentStatus: '0',
        createdAt: new Date(),
        paidAt: new Date(),
        wireTransfer: '1',
        totalAmount: paymentAmt.intentAmount,
        percentage: 100,
        splitAmount: parseInt(paymentAmt.intentAmount),
        currCode: paymentAmt.currCode
      }



      const paymentData = { ...paymentAmt, channelId: userData.channelId, contactId: userData.contactId, paymentMethod: 2, type: 'Wire Transfer', subscriptionTypeId: 1, paymentIndent: 'Wire Transfer', paidDetails: paidDetails }
      // const subId = redux_data.select_plan;
      const response = await commonService.postService(
        paymentData
        , 'channel/subscription/secondarySubscription').catch(err => console.log('err', ToastsStore.error(err.response.data.message)));
      if (response) {

        /*let eventData = {
          // name: userData.firstName + ' ' + userData.lastName,
          // email: userData.email,
          contactId: userData.contactId,
          channelId: userData.channelId,
          packageId: payment?.packageId,
          eventId: payment?.eventId,
          paymentTransactionId: payment?.paymentTransactionId,
          profileurl: process.env.REACT_APP_LOCALSITE,
          finalAmount: parseFloat(paymentAmt.intentAmount).toFixed(2).toString().replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1 ').replace('.', ','),
          subTotal: parseFloat(paymentAmt.actualAmount).toFixed(2).toString().replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1 ').replace('.', ','),
          taxAmount: parseFloat(payment?.taxAmount).toFixed(2).toString().replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1 ').replace('.', ','),
          vatNumber: payment?.vatNumber,
          actualAmount: payment?.actualAmount.toString().replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1 ').replace('.', ','),
          offer: payment?.offers,
          paymentMethod: payment?.paymentMethod,
          paymentStatus: payment?.paymentStatus,
          paymentOption: payment?.totalSplit?.length > 1 ? 2 : 1,

          totalSplit: [],//payment?.totalSplit,
          splitId: null,//payment?.splitId,
          isSplit: false,

          eventName: payment?.eventName

          //templateId: eventDetails.regTemplate
        };*/

        //await axios.post('mailerservice/eventPaymentMail', eventData);

        // let notifyData = {
        //   contactId: userData.contactId,
        //   channelId: userData.channelId,
        //   eventId: payment?.eventId,
        //   channelType: userData.channelTypeId,
        //   finalAmount: (parseFloat(payment?.finalAmount) + parseFloat(payment?.taxAmount)).toFixed(2).toString().replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1 ').replace('.', ','),
        // }

        // await axios.post('mailerservice/eventPaymentNotifyMail', notifyData);

        let result = await eventService.eventPaymentRegistration(2, paymentAmt, 'Wire Transfer');
        if (result) {
          ToastsStore.success("Payment Completed Successfully");
          setLoading(false);
          history.push('/admin/PaymentSuccess');
        } else {
          ToastsStore.success("Something Went Wrong");
          setLoading(false);
        }
      }

    } else {

      const paymentData = { ...paymentAmt, paymentMethod: 2, type: 'Wire Transfer', subscriptionTypeId: 1, paymentIndent: 'Wire Transfer' ,splitId: eventPayment.splitId}
      // const subId = redux_data.select_plan;
      const response = await commonService.postService(
        paymentData
        , 'channel/subscription/eventSubscribe').catch(err => console.log('err', ToastsStore.error(err.response.data.message)));
      if (response) {

        ToastsStore.success("Payment Completed Successfully");
        setLoading(false);
        history.push('/admin/PaymentSuccess');
      }
    }


  }



  const validateCoupan = async () => {
    setLoading(true);

    if (coupan_code) {

      let coupanData = await commonService.postService({
        //finalAmount: eventPayment.map(x => +x.price).reduce((sum, x) => sum + x),
        couponCode: coupan_code,
        eventId: paymentAmt.eventId,
        // subscriptionId:parseInt(subId.subscriptionId),
        // subscriptionPlanId:paymentAmt.planId,
        // subscriptionPeriodId:paymentAmt.periodId
      }, 'channel/subscription/eventcoupan_validate').catch(err => console.log('err', setCoupan_error(err.response.data.message)));
      setLoading(false);
      if (coupanData?.data?.couponAmount) {
        console.log('coupanData', coupanData);
        paymentAmt.finalAmount = paymentAmt.finalAmount - parseFloat(coupanData.data.couponAmount).toFixed(2);
        paymentAmt.vatAmount = paymentAmt.finalAmount * (20 / 100);
        // paymentAmt.totalDiscountedAmount = parseFloat(coupanData.data.couponAmount);
        paymentAmt.couponAmount = coupanData.data.couponAmount;
        paymentAmt.coupan_code = coupan_code;
        setPaymentAmt({ ...paymentAmt });
        dispatch({ type: 'EVENT_PAYMENT', data: paymentAmt });
        //props.subscriptionRedux(paymentAmt.amountpayable,'PAYMENT_AMT');
        setCoupan_Dis(coupanData.data.finalAmt.couponOfferValue);
        //  setCoupan_Amt(parseFloat(coupanData.data.finalAmt.couponDiscount).toFixed(2));
      } else {
        setCoupan_error(true);
      }
    } else {
      setCoupan_error(true);
      setLoading(false);

    }
  }

  const resetCoupon = () => {
    paymentAmt.finalAmount = paymentAmt.actualAmount;
    paymentAmt.totalDiscountedAmount = 0;
    paymentAmt.couponAmount = 0;
    setPaymentAmt({ ...paymentAmt });
    dispatch({ type: 'EVENT_PAYMENT', data: paymentAmt });
    setCoupan_Dis(null);
    //  setCoupan_Amt(null);
  }

  const vatNumberChange = (vatNumber) => {
    setAddress({ ...billingAddress, VAT: vatNumber });

    setPaymentAmt({ ...paymentAmt, vatNumber: vatNumber });

  }
 
  let amtsplit = (eventPayment?.totalSplit?.filter(ep => ep.contactId == eventPayment.contactId).length > 1)?true:false;

  return (
    <div className="main-content" id="PaymentConfirm">
      <SimpleHeader location={props.match} />
      <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore} />
      {isLoading && <Spinner />}
      <Container className="mt-4 col-lg-11 col-md-11 col-sm-12" fluid>
      {(!pendingWireTransfer)?
        <Row>
          <Col lg="7">

            {(paymentAmt?.isVatCompany) && //!paymentAmt?.vatNumber) &&
              <Row>
                <Col lg="12"> <Card>
                  <CardBody>
                    <div>
                      {/* <h1 className="ml-3">Your Billing Details</h1><hr className="m-0 ml-3 paycarhr" style={{ width: "14%" }} />
<p className="p-3 mb-0">
                    Fill the below address details
                  </p> */}
                      <form>
                        <div className="ml-3">
                          {/* <Row>
  <FormInput onChange={(e) => setAddress({...billingAddress,legalAddress:e.target.value})} className="comp-name mt-2 addrs billadress" id="legalAddress" name="legalAddress" label="Registered/Legal Address *" type="text" variant="outlined"  />
  </Row>
  <Row>
  <div className="col-sm-6 p-l-0" style={{paddingLeft:0}}>
              <FormInput onChange={(e) => setAddress({...billingAddress,country:e.target.value})} className="comp-name mt-4" id="country" name="country" label="Country *" type="text" variant="outlined" 
                    />
              </div>
              <div className="col-sm-6 p-r-0" style={{paddingRight:0}}>
              <FormInput onChange={(e) => setAddress({...billingAddress,state:e.target.value})} className="comp-name mt-4" id="state" name="state" label="State *" type="text" variant="outlined" 
                    />
              </div>
              <div className="col-sm-6 p-l-0" style={{paddingLeft:0}}>
              <FormInput onChange={(e) => setAddress({...billingAddress,zipCode:e.target.value})} className="comp-name mt-4" id="zipCode" name="zipCode" label="Zip/Pincode *" type="text" variant="outlined" 
                    />
              </div>
              <div className="col-sm-6 p-r-0" style={{paddingRight:0}}>
              <FormInput onChange={(e) => setAddress({...billingAddress,city:e.target.value})} className="comp-name mt-4" id="city" name="city" label="City *" type="text" variant="outlined" 
                    />
              </div>
              </Row> */}

                         <Row>                        
                            { (paymentAmt?.vatPercentage > 0) && <FormInput onChange={(e) => vatNumberChange(e.target.value)} className="comp-name mt-4" id="VAT" name="VAT" label="VAT Number *" type="text" variant="outlined" value={paymentAmt?.vatNumber ? paymentAmt?.vatNumber : billingAddress['VAT']}
                            />    }                        
                            {payOption.vat && <h5 style={{ "color": "red" }}>
                              Enter Vat Number
                            </h5>}
                            {payOption.invalidVat && <h5 style={{ "color": "red" }}>
                              Invalid Vat Number
                            </h5>}                          
                          </Row>
                        </div>
                      </form>
                    </div>

                  </CardBody>
                </Card></Col>
              </Row>}
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <h1 className="ml-3 mt-3">Payment Method</h1><hr className="m-0 ml-3 paycarhr" style={{ width: "14%" }} />
                    {/* <p className="p-3 mb-2">
                      {helperText}
                    </p> */}
                    <div className="ml-3 mt-3">
                      <RadioGroup onChange={handleRadioChange} value={value} row aria-label="position" name="position">
                        <div className="rowBox w-100">

                          <FormControlLabel value="Card" disabled={parseInt(paymentAmt.planId) === 1} control={<Radio color="primary" />} label="Card" />
                          <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} alt="...." className="float-right" src={require("assets/img/common/card.png")} />
                        </div>
                        {/* {value== 'Card' && 
            <CardBody className=" mt-1 pt-0">
            <Row className="justify-content-center pb-2">
        
        <Col lg="12" >
        <CardBody>
          <div >
            <Form className="form-primary" role="form">
            <Row>
                      <Col lg="12">
               <FormGroup className="mb-4">
                
                      
                        <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="outlined-adornment-amount">Email</InputLabel>
            <OutlinedInput
                id="outlined-adornment-amount"
                placeholder="Email"
                value={userData.email}
                disabled
                startAdornment={<InputAdornment position="start">
                    <i class="fas fa-envelope"></i>
                </InputAdornment>}
                labelWidth={60}
            />
            </FormControl>
              </FormGroup>
              </Col>
                  </Row>
           <Row>
               <Col>
        <StripePayment/>
        </Col>
           </Row>
            </Form>
          </div>
        </CardBody>
        </Col>
    </Row>
            </CardBody>} */}

                        {/* {(!isAddon) && */}
                        <div className="rowBox w-100">

                          <FormControlLabel value="Wire Transfer" control={<Radio color="primary" />} label="Wire Transfer" />
                          <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} className="float-right" alt="..." src={require("assets/img/common/wire.png")} />
                        </div>
                        {/* } */}

                        {/*  <div className="rowBox w-100">

                        <FormControlLabel disabled={parseInt(paymentAmt.planId) === 1} value="PayPal" control={<Radio color="primary" />} label="PayPal" />
                        <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} className="float-right" alt="..." src={require("assets/img/common/paypal.png")} />
                      </div>
                      <div className="rowBox w-100">

                        <FormControlLabel disabled={parseInt(paymentAmt.planId) === 1} value="Alipay" control={<Radio color="primary" />} label="Alipay" />
                        <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} className="float-right" alt="..." src={require("assets/img/common/alipay.png")} />
                      </div> */}

                      </RadioGroup>
                      {payOption.card && <h5 style={{ "color": "red" }}>
                        Select Payment Method
                      </h5>}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {/* {paymentAmt.paymentMethod == 2 && */}
            <Row>

              <Col lg="12">
                {amtsplit && <Card>
                  <CardBody>
                    <h1 className="ml-3 mt-3">Payment Split</h1><hr className="m-0 ml-3 paycarhr" style={{ width: "14%" }} />
                    {/* <p className="p-3 mb-2">
                      {helperText}
                    </p> */}

                    {packList?.map(x => <>
                      <Row>
                        <Col lg="4">
                          <h4 className="mt-3 mb-3" >{x.packageName}</h4>
                        </Col>
                        <Col lg="4">
                          <h4 className="text-center mt-3 mr-4 mb-3 amt">{x.price.replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1 ').replace('.', ',')} {currCode}</h4>
                        </Col>
                      </Row>

                      <Row>
                        {paymentAmt.discount > 0 && <>
                          <Col lg="4">
                            <h4 className="mt-3 mb-3" >Discount</h4>
                          </Col>
                          <Col lg="4">
                            <h4 className="text-center mt-3 mr-4 mb-3 amt">-{paymentAmt.discount.replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1 ').replace('.', ',')} {currCode}</h4>
                          </Col>

                        </>}
                      </Row>

                      <Row>
                        {paymentAmt.discount > 0 && <>

                          <Col lg="4">
                            <h4 className="mt-3 mb-3" >Final Amount</h4>
                          </Col>
                          <Col lg="4">
                            <h4 className="text-center mt-3 mr-4 mb-3 amt">{(parseFloat(x.price) - paymentAmt.discount).toString().replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1 ').replace('.', ',')} {currCode}</h4>
                          </Col></>}
                      </Row>


                    </>)}

                    <div className="ml-3 mt-3">

                        {paymentAmt?.totalSplit?.map(split => {

                        //let splitPrice = split.splitAmount;
                        let actualPrice = split.splitAmount;

                        if(paymentAmt.contactId == split.contactId) {

                        if (paymentAmt?.vatPercentage > 0) {
                          actualPrice = (parseFloat(split.splitAmount) + (parseFloat(split.splitAmount) * (paymentAmt?.vatPercentage / 100))).toFixed(2);
                        }

                        return <>
                          {amtsplit && <div className="rowBox w-100">

                            <Row>
                              <Col lg='6' className='pt-3'>
                                <h4 className="mt-3 mb-3">{"Installment: " + split.splitId}</h4>
                              </Col>
                            </Row>
                            {split.paymentDate &&
                              <Row>
                                <Col lg='6' className='pt-3'>
                                  <h4 cassName="mt-3 mb-3">{"Payment Date: " + Moment(split.paymentDate).format('DD-MM-YYYY')}</h4>
                                </Col>
                              </Row>}

                            <Row>
                              {paymentAmt?.vatPercentage > 0 && <>
                                <Col lg='3' className='pt-3'>
                                  <h4 className="mt-3 mb-3">{"Package: " + split.splitAmount.toString().replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1 ').replace('.', ',')} {currCode}</h4>
                                </Col>
                                <Col lg='3' className='pt-3'>
                                  <h4 className="mt-3 mb-3">{"Vat: " + (parseFloat(split.splitAmount) * (paymentAmt?.vatPercentage / 100)).toFixed(2).toString().replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1 ').replace('.', ',')} {currCode}</h4>
                                </Col></>}
                              <Col lg='3' className='pt-3'>
                                <h4 className="mt-3 mb-3">{"Due: " + actualPrice.toString().replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1 ').replace('.', ',')} {currCode}</h4>
                              </Col>
                              <Col lg='3' className='pt-3'>
                                <Button className="mt-1 mb-2 btn-apply" disabled={split.paymentStatus == 1 ? true : false} variant="outlined" size="m" color={split.paymentStatus == 1 ? "success" : "primary"} style={{ float: 'right' }}>
                                  {split.paymentStatus == 1 ? "Paid" : "Unpaid"}
                                </Button>
                              </Col>
                            </Row>
                        
                          </div>}
                        </> }
                      })}

                    </div>
                  </CardBody>
                </Card>
                }
              </Col>
            </Row>
            {/* } */}


          </Col>
          <Col lg="5" className="pt-3" >
            <div style={{ backgroundColor: "#d9d9d9", padding: "20px" }}>
              <Card>
                <CardBody>
                  <Row>
                    <Col lg="6">
                      <h2 className="mt-1 mb-3 d-flex ">Order Summary</h2></Col>
                    <Col lg="6">
                      {/* <h4 className=" mt-1 mb-3 d-flex justify-content-end" onClick={()=>history.push('/admin/Subscription')}>
            
            <a className="changeplan">Change Plan</a>
            </h4> */}
                    </Col></Row>


                  <Row>

                    {packList?.map(x => <>
                      <Col lg="8">
                        <h4 className="mt-3 mb-3" >{`${x.packageName} ${x.qty ? `(×${x.qty})` : ''} `}</h4>
                      </Col>
                      <Col lg="4" className="d-flex justify-content-start">
                        <h4 className="text-center mt-3 mr-4 mb-3 amt">{x.qty ? parseFloat((x.qty * +x.price)).toFixed(2).toString().replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1 ').replace('.', ',') : x.price.replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1 ').replace('.', ',')} {currCode}</h4>
                      </Col>

                      {paymentAmt.discount > 0 && <>
                        <Col lg="8">
                          <h4 className="mt-3 mb-3" >Discount</h4>
                        </Col>
                        <Col lg="4" className="d-flex justify-content-start">
                          <h4 className="text-center mt-3 mr-4 mb-3 amt">-{paymentAmt.discount.replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1 ').replace('.', ',')} {currCode}</h4>
                        </Col>

                        <Col lg="8">
                          <h4 className="mt-3 mb-3" >Final Amount</h4>
                        </Col>
                        <Col lg="4" className="d-flex justify-content-start">
                          <h4 className="text-center mt-3 mr-4 mb-3 amt">{(parseFloat(x.price) - paymentAmt.discount).toString().replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1 ').replace('.', ',')} {currCode}</h4>
                        </Col></>}

                    </>)}
                    {/* <Col lg="8">
  <h4 className="mt-3 mb-3">Package Cost</h4>
  </Col>
  <Col lg="4" className="d-flex justify-content-start">
  <h4 className="text-center mt-3 mr-5 mb-3 amt">{paymentAmt?.planAmt} €	</h4>
  </Col> */}
                    <hr className="m-0 ml-3 ordersumsplit" style={{ width: "100%" }} />

                    {paymentAmt.offers?.filter(o => o.offerType == 1)?.map(x => <>
                      <Col lg="8">
                        <h4 className="mt-3 mb-3">{x.couponDesc}</h4>
                      </Col>
                      <Col lg="4" className="d-flex justify-content-start">
                        <h4 className="text-center mt-3 mr-3 mb-3 amt">{`-${x.couponOfferValue} ${currCode}`}</h4>
                      </Col>
                    </>)}
                    <hr className="m-0 ml-3 ordersumsplit" style={{ width: "100%" }} />
                    {paymentAmt.coupan_code && paymentAmt.offers?.filter(o => o.offerType == 2 && o.offerParam == paymentAmt.coupan_code)?.map(x => <>

                      <Col lg="8">
                        <h4 className="mt-3 mb-3">{x.couponDesc}</h4>
                      </Col>
                      <Col lg="4" className="d-flex justify-content-start">
                        <h4 className="text-center mt-3 mr-3 mb-3 amt">{`-${x.couponOfferValue}`} {currCode}</h4>
                      </Col></>)}

                    <Col lg="8">
                      <h4 className="mt-3 mb-3">{(paymentAmt.paymentMethod == 1 || isAddon || isSecondary) ? "SUB TOTAL" : "Installment " + paymentAmt.splitId}</h4>
                    </Col>
                    <Col lg="4" className="d-flex justify-content-start">
                      <h4 className="text-center mt-3 mr-3 mb-3 amt">{paymentAmt.finalAmount.toString().replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1 ').replace('.', ',')} {currCode}</h4>
                    </Col>

                    {paymentAmt?.vatPercentage > 0 && <><Col lg="8">
                      <h4 className="mt-3 mb-3">Vat {paymentAmt.vatPercentage}%</h4>
                    </Col>
                      <Col lg="4" className="d-flex justify-content-start">
                        <h4 className="text-center mt-3 mr-5 mb-3 amt">{`${(parseFloat(paymentAmt.finalAmount) * (paymentAmt?.vatPercentage / 100)).toFixed(2).toString().replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1 ').replace('.', ',')} ${currCode}`}</h4>
                      </Col></>}
                    <hr className="m-0 ml-3 ordersumsplit" style={{ width: "100%" }} />


                    {/* <Col lg="8">
                      <h3 className="mt-3 mb-3">Discount</h3>
                    </Col>
                    <Col lg="4" className="d-flex justify-content-start">
                      <h3 className="text-center mt-3 mr-3 mb-3 amt">{paymentAmt?.discount ? paymentAmt.discount + (paymentAmt.finalAmount * (paymentAmt?.vatPercentage / 100)).toString().replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1 ').replace('.',',') : paymentAmt.finalAmount.toString().replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1 ').replace('.',',')} {currCode}</h3>
                    </Col> */}

                    <Col lg="8">
                      <h3 className="mt-3 mb-3">Total</h3>
                    </Col>
                    <Col lg="4" className="d-flex justify-content-start">
                      <h3 className="text-center mt-3 mr-3 mb-3 amt">{paymentAmt?.vatPercentage ? (parseFloat(paymentAmt.finalAmount) + (parseFloat(paymentAmt.finalAmount) * (paymentAmt?.vatPercentage / 100))).toFixed(2).toString().replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1 ').replace('.', ',') : paymentAmt.finalAmount.toString().replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1 ').replace('.', ',')} {currCode}</h3>
                    </Col>

                  </Row>
                </CardBody>
              </Card>

              {(paymentAmt?.offers?.length == 0 || paymentAmt.offers?.filter(x => x.offerType == 1).length == 0) &&
                <Card>
                  <CardBody>
                    <Row>
                      <Col lg="12">
                        <h3 className="mt-1 mb-2" >Promo Code</h3>
                      </Col>
                      <Col lg="12">
                        <div class="input-group mb-2">
                          <input onChange={e => setCoupan_code(e.target.value)} readOnly={coupan_dis} type="text" class="form-control" placeholder="Apply Promo Code" aria-label="Apply-prmotional-Code" aria-describedby="button-addon2" />
                          <div class="input-group-append">
                            {coupan_dis ?
                              <button class="btn btn-outline-primary" type="button" id="button-addon2" onClick={resetCoupon}>Remove</button> :
                              <button class="btn btn-outline-primary" type="button" id="button-addon2" onClick={validateCoupan}>Apply</button>}
                          </div>
                        </div>

                        {/* for valid coupon */}

                        {coupan_dis && <h5 className="couponvalid">
                          Promotional code applied! Avail {coupan_dis} {currCode} off on your package cost
                        </h5>}
                        {coupan_error && <h5 style={{ "color": "red" }}>
                          Coupon not valid
                        </h5>}
                      </Col>
                    </Row>
                  </CardBody>

                </Card>}

              <Card>
                <CardBody>
                  <Row>
                    <Col lg="6">
                      <h3 className="mt-1 mb-2" >AMOUNT PAYABLE</h3>
                      <h3 style={{ fontSize: '24px' }} className="mt-3 mb-3 amt">{paymentAmt?.vatPercentage ? (parseFloat(paymentAmt.finalAmount) + (parseFloat(paymentAmt.finalAmount) * (paymentAmt?.vatPercentage / 100))).toFixed(2).toString().replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1 ').replace('.', ',') : paymentAmt.finalAmount.toString().replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1 ').replace('.', ',')} {currCode}</h3>
                    </Col>
                    <Col className="col-lg-6 d-flex align-items-center d-flex justify-content-end">
                      {wiretransfer == true ? '' : <>
                        <Button onClick={handleSave} className="mt-1 mb-2 btn-apply" variant="outlined" size="m" color="primary">
                          Pay Now
                        </Button>
                        <Button onClick={dismissEvent} className="mt-1 mb-2 btn-apply" variant="outlined" size="m" color="primary">
                          Dismiss Event
                        </Button></>
                      }
                    </Col>
                  </Row>
                </CardBody>

              </Card>
            </div>
          </Col>

        </Row>
        :
        <Card className="p-3">
        <h1 className="ml-3 mt-3">WireTransfer Approval Waiting</h1><hr className="m-0 ml-3 paycarhr" style={{ width: "35%" }} />
        <h1 className="text-center"><i style={{ fontSize: '3rem', color: '#2c9db7' }} class="fa fa-check-circle" aria-hidden="true"></i>
        </h1>
        <Row className="justify-content-center pb-2">
            <Col lg="12"> 
              
                <CardBody style={{textalign:"center"}}>
                  <p>Your payment request via Wire Transfer has been already submitted. Kindly wait for admin approval. If payment has already been made, please reach out to the admin,
                  Send a mail to Alain Godet, <a href="mailto:algodet@channelhub.net">algodet@channelhub.net</a>
                  </p>
                </CardBody>
              
            </Col>
        </Row>  
        </Card>
        }

      </Container>
    </div>


  );
};



// export default Subscription;


function mapState(state) {
  console.log('state', state);
  const { general_data } = state.subscription;
  return { general_data };
}

const actionCreators = {
  subscriptionRedux: subscriptionAction.subscriptionRedux
};

export default connect(mapState, actionCreators)(Subscription); //AddgeneralForm;