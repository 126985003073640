import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import { Button, Modal } from "reactstrap";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { now } from 'moment';

const AnnualTurnOver = (props) => {

  const { open, handleClose, handleSave, currentValue } = props;
  const [editFlag, setEditFlag] = useState(false);
  const [year, setYear] = useState({});
  const yearList = {};

  let yearCount;

  useEffect(() => {
    for (yearCount = 1900; yearCount <= new Date().getFullYear(); yearCount++) {
      yearList[yearCount] = yearCount;
    }
    setYear(yearList);
    console.log("yearList:", year)
  }, [])


  let turnOver = [];
  if (currentValue.length > 0) {
    currentValue.map(x => {
      let obj = {
        //channelid: x.channelid,
        annualYear: x['year'] ? x.year : x.annualYear
      }
      if (x['turn_over']) {
        if (x.turn_over == 1 || x.turn_over == 2 || x.turn_over == 3 || x.turn_over == 4)
          Object.assign(obj, { AnnualTurnOver: +x.turn_over })
        else if (x.turn_over != 1 && x.turn_over != 2 && x.turn_over != 3 && x.turn_over != 4)
          Object.assign(obj, { annualTurn: x.turn_over })
      }
      else if (x['AnnualTurnOver'])
        Object.assign(obj, { AnnualTurnOver: +x.AnnualTurnOver })
      else if (x['annualTurn'])
        Object.assign(obj, { annualTurn: x.annualTurn })

      turnOver.push(obj);
    })

  }

  const [data, setData] = useState(turnOver);


  return (

    <div>
      <Modal className="modal-dialog-centered" id="popupedit" isOpen={open} size="lg">

        <div className="modal-header">
          <strong>Annual Turn Over</strong>
          <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={handleClose}>
            <span aria-hidden={true}>×</span>
          </button>
        </div>

        <div className="modal-body">

          <MaterialTable

            title="Annual Turn Over"
            columns={[
              { title: 'Annual Year', field: 'annualYear', lookup: year },
              {
                title: 'Annual Turn Over',
                field: 'AnnualTurnOver',
                lookup: { 1: '<1M€', 2: '1-10M€', 3: '10-100M€', 4: '>100M€' }
              },
              { title: 'Custom Value M€', field: 'annualTurn' }
            ]}
            data={data}
            editable={{
              onRowAdd: newData =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    if (newData['annualTurn'] && newData['AnnualTurnOver'])
                      delete newData.AnnualTurnOver;
                    setData([...data, newData]);
                    setEditFlag(true);
                    resolve();
                  }, 1000)
                }),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    const dataUpdate = [...data];
                    const index = oldData.tableData.id;
                    if (newData['annualTurn'] && newData['AnnualTurnOver'])
                      delete newData.AnnualTurnOver;
                    dataUpdate[index] = newData;
                    setData([...dataUpdate]);
                    setEditFlag(true);
                    resolve();
                  }, 1000)
                }),
              onRowDelete: oldData =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    const dataDelete = [...data];
                    const index = oldData.tableData.id;
                    dataDelete.splice(index, 1);
                    setData([...dataDelete]);
                    setEditFlag(true);
                    resolve()
                  }, 1000)
                }),
            }}
          />
        </div>

        <div className="modal-footer">
          {editFlag && <Button color="primary" type="button" onClick={() => handleSave(data)}>Save</Button>}
        </div>

      </Modal>

    </div >
  );
}

export default AnnualTurnOver;
