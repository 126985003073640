import React, { Fragment, useState, useEffect } from "react";
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, TimePicker } from "@material-ui/pickers";
import FormHelperText from '@material-ui/core/FormHelperText';
import { Card, CardBody, Row, Col, Container, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown, Button } from 'reactstrap';
import FormControl from '@material-ui/core/FormControl';
import { FormInput } from '_components/FormElements/FormInput';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { EventTravelSchema } from './validation';
import { ToastsStore, ToastsContainer } from 'react-toasts';
import { Spinner } from 'views/Hoc/Spinner';
import axios from "axios";

const Eventtravel = (props) => {

    const { register, handleSubmit, control, errors, watch, formState } = useForm({
        mode: 'onBlur',
        resolver: yupResolver(EventTravelSchema),
    });

    const [loading, setLoading] = useState(false);

    const [state, setState] = useState({
        arrivalDate: null,
        departureDate: null,
        arrivalTime: null,
        departureTime: null,
        arrivalMethod: null,
        arrivalType: null,
        departureType: null,
        arrFlightNumber: null,
        depFlightNumber: null,
    });

    const watchAll = watch(['arrivalFlightNumber', 'departureFlightNumber']);

    useEffect(() => {
        (async () => {
            await getEventTravelDetails();
        })();
    }, []);

    const handleChange = input => e => {
        state[input] = e.target.value;
        setState({ ...state });
    }

    const handleChangeSelect = (input, value) => {
        state[input] = value;
        errors[input] = null;
        setState({ ...state });
    }

    console.log('errprs', errors)

    const getEventTravelDetails = async () => {

        setLoading(true);

        await axios.get('channel/eventTravel/' + props.eventId).then(res => {
            if (res.data) {
                let travel = res.data;
                state.arrivalDate = travel.arrDate;
                state.departureDate = travel.depDate;
                state.arrivalTime = travel.arrTime;
                state.departureTime = travel.depTime;
                state.arrivalType = arrivalTypeOptions.filter(x => x.id == travel.arrMethod)[0];
                state.departureType = arrivalTypeOptions.filter(x => x.id == travel.depMethod)[0];
                state.arrFlightNumber = travel.arrFlightNumber;
                state.depFlightNumber = travel.depFlightNumber;
                setState({ ...state });
            }
        }).catch(err => {
            console.log('something went wrong');
            setLoading(false);
        })

        setLoading(false);

    }

    const save = async () => {

        let travelSaveFlag=false;

        setLoading(true);

        const data = {
            eventId: props.eventId,
            arrDate: state.arrivalDate,
            depDate: state.departureDate,
            arrTime: state.arrivalTime,
            depTime: state.departureTime,
            arrMethod: state.arrivalType.id,
            depMethod: state.departureType.id,
            arrFlightNumber: state.arrivalType.id == '3' ? state.arrFlightNumber : null,
            depFlightNumber: state.departureType.id == '3' ? state.depFlightNumber : null
        }

        let userData = JSON.parse(localStorage.getItem('CHuser'));


        await axios.post('/channel/eventTravel', data).then(res => {
            ToastsStore.success('Event Travel Data Saved Successfully...')

            travelSaveFlag=true;

            let arrTime = new Date(state.arrivalTime)
            let ahrs = arrTime.getUTCHours().toString();
            if(parseInt(ahrs)<=9)
				ahrs='0'+ahrs;

            let amins = arrTime.getUTCMinutes().toString();
            if(parseInt(amins)<=9)
				amins='0'+amins;

            let depTime = new Date(state.departureTime)
            let dhrs = depTime.getUTCHours().toString();
            if(parseInt(dhrs)<=9)
				dhrs='0'+dhrs;

            let dmins = depTime.getUTCMinutes().toString();
            if(parseInt(dmins)<=9)
				dmins='0'+dmins;


            
            const data = {
                eventId: props.eventId,
                arrDate: state.arrivalDate,
                depDate: state.departureDate,
                arrTime: ahrs+':'+amins,
                depTime: dhrs+':'+dmins,
                arrMethod: state.arrivalType.name,
                depMethod: state.departureType.name,
                isFlightTravel: state.arrivalType.id == '3' ? true : false,
                arrFlightNumber: state.arrivalType.id == '3' ? state.arrFlightNumber : null,
                depFlightNumber: state.departureType.id == '3' ? state.depFlightNumber : null,
                contactId: userData.contactId

            }

            axios.post('mailerservice/travelUpdateMail', data);
        }).catch(err => {
            ToastsStore.warning('Something went wrong');
            setLoading(false);
        });
        
        if(travelSaveFlag){

            let arrTime = new Date(state.arrivalTime)
            let ahrs = arrTime.getUTCHours().toString();
					if(parseInt(ahrs)<=9)
						ahrs='0'+ahrs;

                    let amins = arrTime.getUTCMinutes().toString();
					if(parseInt(amins)<=9)
						amins='0'+amins;

					let asec = arrTime.getUTCSeconds().toString();
					if(parseInt(asec)<=9)
						asec='0'+asec;

					let amsec = arrTime.getUTCMilliseconds().toString();
					if(parseInt(amsec)<=9)
						amins='0'+amsec;

			let depTime = new Date(state.departureTime)
                    let dhrs = depTime.getUTCHours().toString();
					if(parseInt(dhrs)<=9)
						dhrs='0'+dhrs;
						
                    let dmins = depTime.getUTCMinutes().toString();
					if(parseInt(dmins)<=9)
						dmins='0'+dmins;

					let dsec = depTime.getUTCSeconds().toString();
					if(parseInt(dsec)<=9)
						dsec='0'+dsec;

					let dmsec = depTime.getUTCMilliseconds().toString();
					if(parseInt(dmsec)<=9)
						dmins='0'+dmsec;

                    

        let travel={
            eventId: props.eventId,
            arrDate: state.arrivalDate+'T'+ahrs+':'+amins+':'+asec+'.'+amsec,
            depDate: state.departureDate+'T'+dhrs+':'+dmins+':'+dsec+'.'+dmsec,
            arrTime: state.arrivalDate+'T'+ahrs+':'+amins+':'+asec+'.'+amsec,
            depTime: state.departureDate+'T'+dhrs+':'+dmins+':'+dsec+'.'+dmsec,
        arrMethod: state.arrivalType.id,
        depMethod: state.departureType.id,
        arrFlightNumber: state.arrivalType.id == '3' ? state.arrFlightNumber : '',
        depFlightNumber: state.departureType.id == '3' ? state.depFlightNumber : '',
        channelId: userData.channelId,
        contactId: userData.contactId
        }

        let req = {
            url: process.env.REACT_APP_EVENTCATALYST_API + 'oauth/token'
          }
  
          let token = await axios.post('account/eventcatalystToken',req).then(res => {
            if (res.data?.error) {
              ToastsStore.warning('Something went wrong');
            } else {
              return res.data;
            }
          }).catch(err => {
            console.log('err', err)
          })

        let body = {
            token: token, //JSON.parse(localStorage.getItem('eventcatalyst_token')),
            url: process.env.REACT_APP_EVENTCATALYST_API + 'api/travel',
            body: travel
          }
  
        await axios.post('account/postEventTravel',body);
        }
        setLoading(false);
        await getEventTravelDetails();
    }

    return (
        <Card>
            {loading ? <Spinner /> :
                <Container>
                    <form noValidate onSubmit={handleSubmit(save)}>
                        <Card id='Events'>
                            <CardBody>
                                <div>
                                    <h4 className='text-center'>Travel Details</h4>
                                </div>
                                <Col lg='12' className='event-card p-3 mb-4'>
                                    <Row>
                                        <Col lg='4'>
                                            <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="date" name="arrivalDate" ref={register} value={state.arrivalDate} />
                                            <h4 >Arrival Date</h4>
                                            <Fragment>
                                                <FormControl>
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <KeyboardDatePicker
                                                            clearable
                                                            inputVariant="outlined"
                                                            value={state.arrivalDate}
                                                            placeholder="10/10/2018"
                                                            onChange={date => handleChangeSelect('arrivalDate', date)}

                                                            format="dd/MM/yyyy"
                                                            minDate="2022-05-15"
                                                            maxDate="2022-05-25"
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                    <FormHelperText style={{ color: '#f44336', fontSize: '0.75rem' }} >{errors.arrivalDate?.message}</FormHelperText>
                                                </FormControl>
                                            </Fragment>
                                            {/* <p className='mb-2'>Package Name : </p>
                                <p className='mb-2'>Price : </p> */}
                                        </Col>
                                        <Col lg='4'></Col>
                                        <Col lg='4'>

                                            <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="date" name="departureDate" ref={register} value={state.departureDate} />
                                            <h4>Departure Date</h4>
                                            {/* <p className='mb-2'>Package Name : </p>
                                <p className='mb-2'>Price : </p> */}
                                            <Fragment>
                                                <FormControl>
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <KeyboardDatePicker
                                                            clearable
                                                            inputVariant="outlined"
                                                            value={state.departureDate}
                                                            placeholder="10/10/2018"
                                                            onChange={date => handleChangeSelect('departureDate', date)}
                                                            minDate={state.arrivalDate ? state.arrivalDate : "2022-05-15"}
                                                            maxDate="2022-05-25"
                                                            format="dd/MM/yyyy"
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                    <FormHelperText style={{ color: '#f44336', fontSize: '0.75rem' }} >{errors.departureDate?.message}</FormHelperText>
                                                </FormControl>
                                            </Fragment>

                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg='4'>
                                            <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="time" name="arrivalTime" ref={register} value={state.arrivalTime} />
                                            <h4 className="mt-4">Arrival Time</h4>
                                            <Fragment>
                                                <FormControl>
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <TimePicker
                                                            inputVariant="outlined"
                                                            // label="Inline mode"
                                                            value={state.arrivalTime}
                                                            onChange={date => handleChangeSelect('arrivalTime', date)}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                    <FormHelperText style={{ color: '#f44336', fontSize: '0.75rem' }} >{errors.arrivalTime?.message}</FormHelperText>
                                                </FormControl>
                                            </Fragment>
                                        </Col>
                                        <Col lg='4'></Col>
                                        <Col lg='4'>
                                            <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="time" name="departureTime" ref={register} value={state.departureTime} />
                                            <h4 className="mt-4">Departure Time</h4>
                                            <Fragment>
                                                <FormControl>
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <TimePicker
                                                            inputVariant="outlined"
                                                            // label="Inline mode"
                                                            value={state.departureTime}
                                                            onChange={date => handleChangeSelect('departureTime', date)}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                    <FormHelperText style={{ color: '#f44336', fontSize: '0.75rem' }} >{errors.departureTime?.message}</FormHelperText>
                                                </FormControl>
                                            </Fragment>

                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg='4'>
                                            <h4 className="mt-4">Arrival Method</h4>
                                            <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="drop" name="arrivalType" ref={register} value={state.arrivalType?.id ? state.arrivalType.id : ''} />
                                            <UncontrolledDropdown className="p-0 drop" group size='lg' outlined>
                                                <DropdownToggle caret className="p-2" style={{ backgroundColor: '#ffff' }}>
                                                    {!state.arrivalType ? 'Select Method' : state.arrivalType.name}
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    {
                                                        arrivalTypeOptions.map(x => {
                                                            return <DropdownItem key={x.id} onClick={() => handleChangeSelect('arrivalType', x)}>{x.name}</DropdownItem>
                                                        }

                                                        )
                                                    }
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                            <FormHelperText style={{ color: '#f44336', fontSize: '0.75rem' }} >{errors.arrivalType?.message}</FormHelperText>
                                        </Col>
                                        <Col lg='4'></Col>
                                        <Col lg='4'>
                                            <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="drop" name="departureType" ref={register} value={state.departureType?.id ? state.departureType.id : ''} />
                                            <h4 className="mt-4">Departure Method</h4>
                                            <UncontrolledDropdown className="p-0 drop" group size='lg' outlined>
                                                <DropdownToggle caret className="p-2" style={{ backgroundColor: '#ffff' }}>
                                                    {!state.departureType ? 'Select Method' : state.departureType.name}
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    {
                                                        arrivalTypeOptions.map(x => {
                                                            return <DropdownItem key={x.id} onClick={() => handleChangeSelect('departureType', x)}>{x.name}</DropdownItem>
                                                        }

                                                        )
                                                    }
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                            <FormHelperText style={{ color: '#f44336', fontSize: '0.75rem' }} >{errors.departureType?.message}</FormHelperText>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg='4'>
                                            {state.arrivalType?.id == '3' && <><h4 className="mt-4">Flight Number</h4>
                                                <Fragment>
                                                    <FormControl>
                                                        <FormInput
                                                            id="arrivalFlightNumber"
                                                            type="text"
                                                            name="arrivalFlightNumber"
                                                            label="Flight Number *"
                                                            onChange={handleChange('arrFlightNumber')}
                                                            defaultValue={state.arrFlightNumber}
                                                            value={watchAll.arrivalFlightNumber}
                                                            control={control}
                                                            register={register}
                                                            error={errors.arrivalFlightNumber}

                                                        />
                                                    </FormControl>
                                                </Fragment>
                                            </>
                                            }
                                        </Col>
                                        <Col lg='4'></Col>
                                        <Col lg='4'>
                                            {state.departureType?.id == '3' && <><h4 className="mt-4">Flight Number</h4>
                                                <Fragment>
                                                    <FormControl>
                                                        <FormInput
                                                            id="departureFlightNumber"
                                                            type="text"
                                                            name="departureFlightNumber"
                                                            label="Flight Number *"
                                                            onChange={handleChange('depFlightNumber')}
                                                            defaultValue={state.depFlightNumber}
                                                            value={watchAll.departureFlightNumber}
                                                            control={control}
                                                            register={register}
                                                            error={errors.departureFlightNumber}
                                                        />
                                                    </FormControl>
                                                </Fragment>
                                            </>
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                                <p style={{ fontSize: '13px' }}><span style={{ fontWeight: 'Bold', fontSize: '14px' }}>Note:</span>&nbsp; Need an invitation for visa application? send an email to<a href="mailto:aurore@channelhub.net" style={{ color: 'rgb(82 95 127 / 91%)', fontWeight: 'bold' }}> aurore@channelhub.net</a> with the following information: Full name as shown on your passport / Birth date / Passport Issue date / Passport Expire date.</p>
<div className="eventname">
    <h3>Shuttle to Monte Carlo</h3>
    <p>Welcome desks will be available at Nice Airport in both terminals to assist you on your arrival day. Our hostess will guide you to the shuttle bus service to Monte Carlo.
If you arrive outside of the shuttle service timetable we'll do our best to find a suitable solution and will communicate with you by email.</p>

</div>
                            </CardBody>

                        </Card>
                        <div className="col-lg-12 text-center m-4">
                            <Button color="primary" type="submit">
                                Save
                            </Button>
                            {/* <Button color="primary" type="button" href="#">
                   Cancel
                  </Button> */}
                        </div>

                    </form>


                </Container>
            }
        </Card>
    );
};

export default Eventtravel;

const arrivalTypeOptions = [{
    id: '1', name: 'Car'
},
{
    id: '2', name: 'Train'
},
{
    id: '3', name: 'Flight'
}]