import React, { useState, useEffect } from "react";
import { Card, CardBody, CardHeader, Container, Col, Row, Badge, Button, UncontrolledTooltip } from "reactstrap";
import { FormRadio, FormCheckBox, FormAutocomplete, SelectAutocomplete } from "_components/FormElements/FormInput";
import { InputLabel, Select, TextField, FormControl } from '@material-ui/core';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { Nav, NavItem, NavLink, FormGroup, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown, } from "reactstrap";
import UserManageFilter from './UserManageFilter';
import { history } from '_helpers';
import axios from 'axios';
import { Spinner } from 'views/Hoc/Spinner';
import UserView from "views/Hoc/Dialog/UserView";
import { PlanUpgradeDialog } from "views/Hoc/Dialog/PlanUpgradeDialog";
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { commonService } from '_services/common.service';
import { userConstants } from '../../../../_constants';
import SimpleHeader from "components/Headers/SimpleHeader"
import { Dialog } from "views/Hoc/Dialog/GlobalDialog";
import ChangeMainDelegate from "views/Hoc/Dialog/ChangeMainDelegate";
var _ = require('lodash');

const Usermanage = (props) => {
  const [loading, setLoading] = useState(false);
  const [previlege, setPrevilege] = useState(null);
  const [sort, setSort] = React.useState(true);
  const [state, setState] = useState({
    userlist: [],
  });
  let [isModalPopup, setModalPopup] = useState(false);
  let [modalPopValue, setModalPopValue] = useState({ message: null, title: null, value: null, popUpId: null, second_message: null });


  const [searchState, setSearch] = useState({ search: null, dropdownList: [] });
  const [filterValueState, setFilterValue] = useState({ userType: [], tradeLocation: [], liveStatus: [] });
  const { control } = useForm({ mode: 'onBlur' });
  const [viewuser, setsviewuser] = useState(false);
  const [viewUserData, setUserData] = useState();
  const [userLimit, setUserLimit] = useState(true);
  const [userCount, setPrimayUserCount] = useState(0);


  const [planUpgrade, setplanUpgrade] = useState(false);
  // const [viewUserData, setUserData] = useState();


  // let [isUpgradePopup, setUpgradePopup]  = useState(false);
  // let [modalPopValue, setModalPopValue]  = useState();
  // const allowedLimit = JSON.parse(localStorage.getItem('CHuser')).featureList[userConstants.ADD_USER];
  let allowedLimit;
  let user = JSON.parse(localStorage.getItem('CHuser'));
  let fl = "featureList" in user ? true : false;
  let status = (fl && userConstants.ADD_USER in user.featureList) ? true : false;
  if (status)
    allowedLimit = user.featureList[userConstants.ADD_USER];

  const handleChangeSelect = (input, value) => {
    console.log('input, value', input, value);
    if (input === 'search') {
      searchState.search = value
      setSearch({ ...searchState })
    }
    if (input === 'userType') {
      filterValueState.userType = value;
      setFilterValue({ ...filterValueState })
    }
    if (input === 'tradeLocation') {
      filterValueState.tradeLocation = value
    }
    if (input === 'liveStatus') {
      filterValueState.liveStatus = value
    }
    setFilterValue({ ...filterValueState })
    console.log('filterValueState', filterValueState);
  }

  useEffect(() => {
    getUser();
    getAccessPrevilege();
  }, []);

  const getUser = () => {
    setLoading(true);
    (async () => {
      await axios.get('setting/getuser').then(res => {

        setState({ ...state, userlist: res.data });

        res.data.map((per, ind) => {
          if (per.role == 'Primary') {
            console.log('Unlimited userCount', per.total);
            setPrimayUserCount(per.total);
            setLoading(false);
            //getUserLimit();
          }

          if (per.role == 'Account Owner') {
            let lmt = per.userLimit;
            if (allowedLimit <= lmt && allowedLimit != 'Unlimited') {
              setplanUpgrade(true);
            }
          }


        });

      }).catch(err => {
        ToastsStore.error('No Users Found!');
        commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: 'setting/getuser' }, '/error')
      });
    })();

  }

  const getAccessPrevilege = () => {
    (async () => {
      await axios.get('user/userActionPrevilege/' + 12).then(res => {
        setPrevilege(res.data.length > 0 ? res.data[0] : 0);
      }).catch(err => {
        ToastsStore.error('No Access Found!');
      });
    })();
  }

  const deleteUser = async (contact) => {
    await axios.delete('setting/deleteUser/' + contact.contactId).then(async res => {
      ToastsStore.success('User Removed successfully')

      let data = {
        name: contact.firstName + ' ' + contact.lastName,
        companyName: user.companyName,
        updatedBy: user.firstName + ' ' + user.lastName,
        to: 'info@channelhub.net'
      }

      await axios.post('mailerservice/Delete_User_Mail', data).catch(err => console.log('mail not sent'));

      getUser();
    })
    console.log("DeleteUser:", contact.contactId);
  }

  const confirmPopup = (res) => {
    setModalPopup(false);
    if (res.popUpId == 1)
      deleteUser(res.value);
    else if (res.popUpId == 3)
      changeMainDelegate(res.value);
  }
  const closePopup = () => {
    setModalPopup(false);

    setOpenMainDelegate(false);
  };

  const deletePopup = async (contactid) => {

    //currently passing static eventId for checking

    let res = await axios.get('setting/checkIsMainDelegate/' + contactid.contactId).then(res => res.data.isMainDelegate).catch(err => {
      ToastsStore.warning('something went wrong');
    });

    modalPopValue.message = res ? 'Do you want to delete the user, This Contact is also main delegate for event' : 'Do you want to delete the user?';
    modalPopValue.title = res ? 'Delete Main Delegate User' : 'Delete User';
    modalPopValue.value = contactid;
    modalPopValue.popUpId = res ? 3 : 1;
    setModalPopValue({ ...modalPopValue });
    setModalPopup(true);
  }

  const [openMainDelegate, setOpenMainDelegate] = useState(false);
  const [mainDelegatePopValue, setMainDelegatePopValue] = useState({});

  const changeMainDelegate = (contact) => {
    setModalPopup(false);
    //setting eventid as static
    setMainDelegatePopValue({ deletedContactId: contact.contactId, eventId: 3 });
    setOpenMainDelegate(true);
  }

  const handleChangeMainDelegate = (res) => {
    setOpenMainDelegate(false);
    deleteUser(res);
  }

  const getUserLimit = () => {
    //   axios.get('setting/getuserLimits').then(res =>{ 
    //       if(res.data.allowed){
    //         setUserLimit(res.data.allowed);
    //       }     
    //   }).catch(err=> {
    //     commonService.postService({err:JSON.stringify(err),reqUrl:window.location.pathname,service:'setting/getuserLimits'},'/error');                     
    //  }); 
  }

  const resetFilterValue = () => {
    filterValueState.liveStatus = [];
    filterValueState.userType = [];
    filterValueState.tradeLocation = [];
    setFilterValue({ ...filterValueState });
    searchState.search = null;
    searchState.dropdownList = [];
    setSearch({ ...searchState })
  }
  const userview = (data) => {
    setUserData(data)
    setsviewuser(true);
  }

  const closeUserView = () => {
    setsviewuser(false);
  }
  const openUpgradeDialog = () => {

    //console.log('userLimit======', userLimit);
    if (allowedLimit > userCount || allowedLimit == 'Unlimited') {
      history.push({ pathname: '/admin/userManage/userform', state: { type: 3, addOrEdit: 'ADD' } });
    } else {
      setplanUpgrade(true);
    }
  }

  const closeplanUpgrade = () => {
    setplanUpgrade(false);
  }


  return (
    <div className="main-content">
      <Dialog open={isModalPopup} value={modalPopValue} handleSave={confirmPopup} handleClose={closePopup} />
      {openMainDelegate && <ChangeMainDelegate isOpen={openMainDelegate} handleClose={closePopup} handleSave={handleChangeMainDelegate} value={mainDelegatePopValue} />}
      <SimpleHeader location={props.match} />
      <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore} />
      {viewuser &&
        <UserView userData={viewUserData} closeUserView={closeUserView} />}
      {planUpgrade && <PlanUpgradeDialog closeUserView={closeplanUpgrade} />}
      {loading ? <Spinner /> : (
        <Container className="mt-4 col-lg-10 col-md-9 col-sm-12  " fluid>
          <Card>
            {/* <Row>
                        <Col lg="12">
                            <Nav style={{float:"right"}}>
                            <NavItem className="pl-2" style={{paddingTop:'1.5rem',float:"right"}}>
                <UncontrolledDropdown className="p-0" group>
                  <DropdownToggle caret className="p-2">
                    <h3
                      style={{ display: "inline-block" }}
                      className="font-weight-bold m-0"
                    >
                      <i className="fa fa-bars  mr-2" aria-hidden="true"></i>
                     {sort}
                    </h3>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                    
                      onClick={() => setSort(true)}
                    >
                     A-Z
                    </DropdownItem>
                    <DropdownItem
                    
                      onClick={() => setSort(false)}
                    >
                     Z-A
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
            </NavItem>
                            </Nav>
                        </Col>
                    </Row> */}
            <Row>
              {/* onClick={()=>openUpgradeDialog()}  */}
              {(user.roleId == 1 || (user.roleId != 1 && (previlege == 1 || previlege == 0))) && <Col lg="12" className="mt-4 d-flex justify-content-end"><div><Button color="primary" onClick={() => { history.push({ pathname: '/admin/userManage/userform', state: { type: 3, addOrEdit: 'ADD' } }) }}><i class="fas fa-user-plus"></i>  Add User</Button></div></Col>}
            </Row>
            <Row>
              {/* <Col lg="3">
                    <h3>Filter</h3>
                    <div className=" text-center mt-2">
                    <div className="form-group">
                      <FormAutocomplete
                        id="tradeLocation"
                        name="tradeLocation"
                        label="Trade Location"
                        options={[]}
                        placeholder="Select Location"
                        multiple
                        disableCloseOnSelect
                        limitTags={1}
                        control={control}                         
                        input='tradeLocation'
                        // value={filterValueState.tradeLocation}
                        // handleChangeSelect={handleChangeSelect}
                        open={true}
                        popupIcon=''
                        disablePortal={true}
                        size='small'
                        ListboxProps={{ style: { maxHeight: '8rem' } }}
                        />
                    </div>
                  </div>
                    <div style={{marginTop:"2rem"}}>
                    <UserManageFilter filterValueState={filterValueState} handleChangeSelect={handleChangeSelect}/>
                    </div>
                    </Col> */}
              <Col lg="12">


                <Card className="m-2">



                  {state.userlist.map((person, index) =>
                    <CardBody className="pad-less">
                      {person.userdata.length > 0 && (
                        <div key={index}><h3>{person.role} <span>({person.total})</span></h3></div>)}
                      {person.userdata.length > 0 && person.userdata.map((ul, i) =>
                        <Row key={i} style={{ padding: "1rem", borderTop: "1px solid #ebecf1" }}>
                          <Col lg="4" className="pad-less user-card">
                            <Row>
                              <Col lg="4" style={{ textAlign: "-webkit-center", paddingTop: "1rem" }}>
                                <div className="avatar avatar-xl rounded-circle">
                                  <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/default_image/contact.png"); }} src={ul.documentPath ? process.env.REACT_APP_CDN_URL + ul.documentPath : "http://d2maj8ifjo1lti.cloudfront.net/document/default_image/contact.png"} />
                                </div>
                              </Col>
                              <Col lg="8">
                                <h4 className='res-i'>{ul.firstName} {ul.lastName}</h4>
                                <p className='res-i' style={{ marginBottom: "0px" }}>{ul.email}</p>
                                <p className='res-i'>{ul.mobileNo}</p>
                              </Col>
                            </Row>
                          </Col>
                          <Col lg="8" className="pad-less ">
                            <Row>
                              <Col lg="3" className="user-card pt-4 d-flex justify-content-center"><h4>{ul.jobTitle}</h4></Col>
                              <Col lg="3" className="user-card pt-4 d-flex justify-content-center"><h4>{ul.country}</h4></Col>
                              <Col lg="3" className="user-card pt-4 d-flex justify-content-center">
                                {ul.isActive == 1 ? (
                                  <Badge className='outer_badge' style={{ width: "75%", height: "2rem", padding: "0.6rem", border: "1px solid #46b88f", color: "#46b88f" }}>
                                    Active
                                    <div className="tip"></div>
                                  </Badge>
                                ) : (
                                  <Badge className='outer_badge' style={{ width: "75%", height: "2rem", padding: "0.6rem", border: "1px solid #46b88f", color: "#46b88f" }}>
                                    Inactive
                                    <div className="tip"></div>
                                  </Badge>
                                )}
                              </Col>
                              <Col lg="3" className="user-card pt-4 d-flex justify-content-end">
                                <div className="res-i">
                                  <a href="#" id={'view-id' + ul.contactId} onClick={() => userview(ul)}><i class="far fa-eye" style={{ color: "#2c9db7" }}></i></a>
                                  {(ul.roleId != 1 && (previlege == 1 || previlege == 0)) && <a onClick={() => { history.push({ pathname: '/admin/userManage/edituser', state: { type: 3, addOrEdit: 'EDIT', contactId: ul.contactId } }) }}><i class="fas fa-pencil-alt ml-4" style={{ color: "#46b88f" }}></i></a>}
                                  {(user.roleId == 1 && ul.roleId != 1) && <a href="#" onClick={() => deletePopup(ul)}> <i class="fas fa-trash-alt ml-4" style={{ color: "#fb6340" }}></i></a>}
                                  <UncontrolledTooltip target={'view-id' + ul.contactId} placement='bottom'>
                                    View User
                                  </UncontrolledTooltip>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>)}



                    </CardBody>)}


                </Card>

              </Col>
            </Row>
          </Card>
        </Container>
      )}
    </div>
  );
};

export default Usermanage;

