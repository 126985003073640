import React, { useState, useEffect, useRef } from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { useForm } from "react-hook-form";
import { FormTextArea, FormInput, FormCheckBox, SingleAutocomplete, NewFieldAutocomplete, FormRadio } from "_components/FormElements/FormInput";
import { PdfCard, ImageCard,ReorderImages } from "views/Hoc/PdfCard";
import MultiUpload from "views/Hoc/MultiUpload";
import { useBeforeFirstRender } from '_hooks/index'
import axios from 'axios';
import { ImageLibraryMultipleUploads } from 'views/Hoc/Dialog/ImageLibraryMultipleUploads';
import { commonService } from '_services/common.service';
import { yupResolver } from '@hookform/resolvers/yup';
import { AddProductSchema } from '../profile/validation';
import { ArrayUploader } from 'views/Hoc/ProfileUpload';
import Rating from '@material-ui/lab/Rating';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Badge } from '@material-ui/core';
import { DocumentName } from 'views/Hoc/Dialog/DocumentName';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DistributionChannel from './DistributionChannel';
import { Spinner } from 'views/Hoc/Spinner';
import { PlanFeatureDialog } from "views/Hoc/Dialog/PlanFeatureDialog";
import { userConstants } from '../../../../src/_constants';
import { history } from '_helpers'
import { Dialog, ConfirmDialog } from "views/Hoc/Dialog/GlobalDialog";
import { MultiSelectImages, ImageLibrary } from 'views/Hoc/Dialog/MultiSelectImages';
import ReactQuill from "react-quill";
var _ = require('lodash');

const useStyles = makeStyles((theme) => ({
  formControl1: {
    minWidth: "100%",
  },
  formControl: {
    minWidth: "100%",
  },
  // margin: {
  //   margin: theme.spacing(1),
  // },
}));

const GeneralForm = (props) => {
  const { saveGeneralForm, tabLoading, isAdd, params, productDetail, saveTradeDetails, hasBrand } = props;


  const hiddenFileproductPos = useRef(null);
  const hiddenFileproductImg = useRef(null);
  const hiddenFileproductPack = useRef(null);
  let [awardImage, setAwardImage] = useState(false);
  const [error, setError] = useState({
    productImages: null,
    productPos: null,
    packagingImages: null,
    productBrochure: null,
  });
  const [reviewimage, setReviewImage] = useState(null);
  const [endUserimage, setEndUserImage] = useState(null);
  const [isdata, setData] = useState(true);
  const [endUserReview, setEndUserReview] = useState(false);
  const [endUserReviewDocId, setEndUserReviewDocId] = useState(null);
  const [endUserReviewId, setEndUserReviewId] = useState(null);
  const [productReview, setProductReview] = useState(false);
  const [productReviewDocId, setProductReviewDocId] = useState(null);
  const [productrReviewId, setProductReviewId] = useState(null);
  let [namePopup, setNamePopup] = useState(false);
  let [docName, setDocName] = useState(null);
  let [docId, setDocId] = useState(null);
  let [isModalPopup, setModalPopup] = useState(false);
  let [modalPopValue, setModalPopValue] = useState({ message: null, title: null, value: null, popUpId: null, second_message: null });
  const [planUpgrade, setplanUpgrade] = useState(false);
  const [upgradeType, setUpgradeType] = useState('productAdd');
  let [isUtube, setIsUtube] = useState(false);

  const handleCropClose = () => {
    setReviewImage(null);
    setEndUserImage(null);
  }
  const handleClick = (id) => {
   // debugger
    console.log("hidden", hiddenFileproductImg);
    if (id === 1) {
      mediaState.productImages.length < 5 ? hiddenFileproductImg.current.click() : error.productImages = "Maximum 5 files only allowed."; setError({ ...error });
    }
    if (id === 2) {
      mediaState.productPos.length < 5 ? hiddenFileproductPos.current.click() : error.productPos = "Maximum 5 files only allowed."; setError({ ...error });
    }
    if (id === 3) {
      mediaState.packagingImages.length < 5 ? hiddenFileproductPack.current.click() : error.packagingImages = "Maximum 5 files only allowed."; setError({ ...error });
    }
  };
  const [prodImage, setProdImage] = useState(false);
  const { register, handleSubmit, control, errors, watch, formState } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(AddProductSchema),
  });

  const classes = useStyles();

  const [state, setState] = useState({
    status: true,
    productName: null,
    tweetPitch: '',
    productDescription: '',
    productshortdec: '',
    productCategory: null,
    productCategoryOption: [],
    productBrand: {},
    productBrandOption: [],
    productCertification: '',
    awards: [],
    savedAwards:[],
    productWarranty: '2 Years',
    productLocalisation: '',
    videoUrl: '',
    productVisibility: true,
    productVisibility1: true,
    productVisibility2: true,
    review: [{ id: Math.random(), articleurl: null, reviewername: null, logo: null, documentUrl: null }],
    productexistingrating: [{ id: Math.random(), existproductrating: null, ratingproofvalidationname: null, ratingproofvalidationurl: null, ratingproofvalidationlogo: null, documentUrl: null }],
    deletedproductbrochure: [],
    deletedproductimage: [],
    deletedproductdisplayimage: [],
    deletedproductpackageimage: [],
    deletedAwards: [],
    deletedarticlereview: [],
    deletedexistingrating: [],
    deletedproducttag: [],
    keyTags: [],
    Keywords: null,

    productAttributes: '0',
    productType: null,
    priceDetails: '',
    businessCurrency: '',
    msrp: '',
    unit: '',
    MOQ: '',
    moqUnit: '',
    no_of_items_in_box: '',
    max_qty_for_sample: '',
    productPositioning: 'Entry',
    availability: '',
    text_about_sample: '',
    variantDetails: '',
    no_limit: false,
    activeProduct: 0,

    isApproved: '',
    approvalType: 0,
    isActiveOriginal: '0'
  });

  const [mediaState, setMediaState] = useState({
    productImages: [],
    productPos: [],
    packagingImages: [],
    productBrochures: []
  });

  const [showDrawer, setshowDrawer] = useState(false);
  const closeDrawer = () => {
    setshowDrawer(false);
  }
  const watchAll = watch();
  console.log('watchAll', watchAll);
  console.log('errors', errors);
  console.log('state', state);
  console.log('mediaState', mediaState);

  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [loading, setLoading] = useState(false);

  useBeforeFirstRender(() => {
    var userData = JSON.parse(localStorage.getItem('CHuser'));
    state.productType = userData.businessType.toString();
    (async () => {
      await axios.get('setting/getProductProfile/' + userData?.channelId).then(res => {
        state.productCategoryOption = res.data.productCat;
        state.productBrandOption = res.data.productBrand;
        state.activeProduct = res.data.activeProduct;

        // if(!props.ratingId)
        // featureValidation();
        
        setState({ ...state });
      }).catch(err => {
        commonService.postService({ err: err.response.data.message, reqUrl: window.location.pathname, service: 'setting/getProductProfile' }, '/error')
      });
    })();
  })

  useEffect(() => {
    if (!isAdd && productDetail) {
      setData(true);
      //console.log('productDetail', productDetail);
      let brand = {
        name: productDetail?.brand?.brandName,
        id: productDetail?.brand?.id.toString()
      }
      let category = {
        name: productDetail?.productCategory?.productCategorie,
        id: productDetail?.productCategory?.productCategorieId,
        groupid: productDetail?.productCategory?.productGroupId
      }
      let productexistingrating = [];
      if (productDetail?.productExistRating?.length > 0) {
        productDetail.productExistRating.map(x => {
          productexistingrating.push({
            id: Math.random(),
            existproductrating: x?.existproductrating,
            ratingproofvalidationname: x.ratingproofvalidationname,
            ratingproofvalidationurl: x.ratingproofvalidationurl,
            ratingproofvalidationlogo: (params.action === '1' || params.action === '2') ? x.ratingproofvalidationlogo?.documentPath : null,
            documentUrl: (params.action === '1' || params.action === '2') ? x.ratingproofvalidationlogo?.documentUrl : null,
            ratingproofvalidationlogoid: (params.action === '1' || params.action === '2') ? parseInt(x.ratingproofvalidationlogoid) : null,
            productexistratingid: (params.action === '1' || params.action === '2') ? parseInt(x.productexistratingid) : null
          })
        })
      } else {
        productexistingrating = [{ id: Math.random(), existproductrating: null, ratingproofvalidationname: null, ratingproofvalidationurl: null, ratingproofvalidationlogo: null, documentUrl: null }]
      }
      let review = []
      if (productDetail?.productArticleReview?.length > 0) {
        productDetail.productArticleReview.map(x => {
          review.push({
            id: Math.random(),
            articleurl: x.articleurl,
            reviewername: x.productreviewername,
            logo: (params.action === '1' || params.action === '2') ? x.reviewmagazinelogo?.documentPath : null,
            documentUrl: (params.action === '1' || params.action === '2') ? x.reviewmagazinelogo?.documentUrl : null,
            imageid: (params.action === '1' || params.action === '2') ? parseInt(x.reviewmagazinelogo?.documentId) : null,
            productarticlereviewid: (params.action === '1' || params.action === '2') ? parseInt(x?.productarticlereviewid) : null
          })
        })
      } else {
        review = [{ id: Math.random(), articleurl: null, reviewername: null, logo: null, documentUrl: null }]
      }

      // let award = [];
      // if (productDetail?.productAwards?.length > 0) {
      //   productDetail.productAwards.map(awd => {
      //     award.push({
      //       awardImg: (params.action === '1' || params.action === '2') ? awd?.document ? awd?.document?.documentPath : null : null,
      //       documentUrl: (params.action === '1' || params.action === '2') ? awd?.document ? awd?.document?.documentUrl : null : null,
      //       imageId: (params.action === '1' || params.action === '2') ? awd?.document ? awd?.document?.documentId : null : null,
      //       awardName: awd?.issuedBy,
      //       product_award_id: (params.action === '1' || params.action === '2') ? awd?.productAwardId : null,
      //       award_details: awd?.awardDetail,
      //       date: awd?.validUpto,
      //       id: Math.random(),
      //       lifeTime: awd?.isLifeTime === '0' ? false : true,
      //     })
      //   })
      // }

      state.awards=[];
      state.savedAwards=[];
      if(productDetail?.productAwards?.length){
        productDetail.productAwards.map(x => {
          if(x.documentId){
            x.documentUrl = x?.document?.documentUrl;
            state.awards.push(x);
            state.savedAwards.push(x);
            }

        })
      }
      let productTag = [];
      if (productDetail?.productTag?.length > 0) {
        productDetail.productTag.map(x => {
          productTag.push({ tag: x.tag, producttagid: parseInt(x.producttagid) })
        })
      }
      state.productName = params?.action === '1' ? productDetail?.product : productDetail?.product + ' - Copy';
      state.tweetPitch = productDetail?.productDetail?.usp?.replace(/<[^>]+>/g, '');
      state.productshortdec = productDetail?.productDetail?.productshortdec ? productDetail?.productDetail?.productshortdec : '';
      state.productDescription = productDetail?.productDetail?.productdesc;
      state.productCategory = category;
      state.productBrand = brand;
      state.productCertification = productDetail?.productDetail?.productCertifi;
      // state.awards = award;
      state.productWarranty = productDetail?.productDetail?.productWarranty;
      state.productLocalisation = productDetail?.productDetail?.productLocal;
      state.videoUrl = productDetail?.productDetail?.videourl;
      state.productVisibility = productDetail?.productSharePreference.some(x => x.sharetypeid === '3');
      state.productVisibility1 = productDetail?.productSharePreference.some(x => x.sharetypeid === '4');
      state.productVisibility2 = productDetail?.productSharePreference.some(x => x.sharetypeid === '5');
      state.review = review;
      state.productexistingrating = productexistingrating;
      state.keyTags = productTag;
      state.status = productDetail?.isactive == '1' ? true : false;
      state.isActiveOriginal = productDetail?.isactive == '1' ? '1' : '0'; //for checking this with status while saving to determine mail to be sent or not

      //trade details
      state.productAttributes = productDetail?.hasanyvariant ? productDetail?.hasanyvariant : '0';

      //businessType
      state.productType = productDetail?.businessType ? productDetail?.businessType : state.productType ;
      // state.priceDetails = productDetail?.,
      state.businessCurrency = parseInt(productDetail?.productPriceDetail?.businesscurrencyid);
      state.msrp = productDetail?.productPriceDetail?.retailerprice;
      state.unit = parseInt(productDetail?.productPriceDetail?.retailerpriceunitid);
      state.MOQ = productDetail?.productPackageInfo?.minorderquantity;
      state.moqUnit = productDetail?.productPackageInfo?.orderunitid;
      state.no_of_items_in_box = productDetail?.productPackageInfo?.noofpackageinbox;
      state.max_qty_for_sample = productDetail?.productPriceDetail?.samplethreshold;
      state.no_limit = productDetail?.productPriceDetail?.isunlimitsample ? productDetail?.productPriceDetail?.isunlimitsample === '0' ? false : true : false;
      state.productPositioning = productDetail?.productqualitylevel;
      state.availability = productDetail?.productDetail?.isavaialbleimmediate === '1' ? 'Immediate' : 'Available From';
      state.avaialblefrom = productDetail?.productDetail?.isavaialbleimmediate === '1' ?  state.selectedDate : productDetail?.productDetail?.avaialblefrom;
      state.text_about_sample = productDetail?.productDetail?.avaialbilitycomment;
      state.variantDetails = productDetail?.variantdetails;

      
      state.isApproved = productDetail?.isApproved;
      state.approvalType = productDetail?.approvalType;

      if(productDetail?.productDetail?.isavaialbleimmediate === '1' ){
        setSelectedDate(selectedDate); 
      }  else {
          setSelectedDate(productDetail?.productDetail?.avaialblefrom); 
      }
      // --------------------------------------------------------- //

      setState({ ...state });
      console.log('params', params);
      if (params.action === '1' || params.action === '2') {
        setImages();
      }
      setData(false);
    }
  }, []);

  // useEffect(() => {  
  //   if(!_.isEmpty(errors)&&formState.isSubmitted)
  //   {
  //     ToastsStore.error(JSON.stringify(errors));
  //     commonService.postService({err:JSON.stringify(errors),reqUrl:window.location.pathname,service:'validation_err'},'/error')    
  //   }
  // }, [errors])

  const featureValidation = async () => {
    setLoading(true);
    let user = JSON.parse(localStorage.getItem('CHuser'));
    let featureList = user.featureList||{}
    // for new products additions

    if (featureList[userConstants.NO_OF_PRODUCT] && (isAdd && state.activeProduct >= featureList[userConstants.NO_OF_PRODUCT])) {
      setplanUpgrade(true);
    } else if (featureList[userConstants.NO_OF_PRODUCT] && (!isAdd && state.activeProduct > featureList[userConstants.NO_OF_PRODUCT])) {
      setplanUpgrade(true);
    } setLoading(false);
  }

  const setImages = () => {
    let images = productDetail?.images;

    if (images?.PRODUCT?.length > 0) {
      mediaState.productImages = [];
      images.PRODUCT.map(x => {
        mediaState.productImages.push({ productId:+x.productId,documentUrl: x?.image?.documentUrl, image: x?.image?.documentPath, imageid: x?.imageid, productimageid: x?.productimageid,sortorder:+x.sortorder,filename: 'PRODUCT', });
      });
    }

    if (images?.DISPLAY?.length > 0) {
      mediaState.productPos = [];
      images.DISPLAY.map(x => {
        mediaState.productPos.push({ documentUrl: x?.image?.documentUrl, image: x?.image?.documentPath, imageid: x?.imageid, productimageid: x?.productimageid,sortorder:+x.sortorder, filename: 'DISPLAY', });
      });
    }

    if (images?.PACKING?.length > 0) {
      mediaState.packagingImages = [];
      images.PACKING.map(x => {
        mediaState.packagingImages.push({ documentUrl: x?.image?.documentUrl, image: x?.image?.documentPath, imageid: x?.imageid, productimageid: x?.productimageid,sortorder:+x.sortorder, filename: 'PACKING', });
      });
    }
    console.log('images.BROUCHER', images?.BROUCHER);
    if (images?.BROUCHER?.length > 0) {
      mediaState.productBrochures = [];
      images.BROUCHER.map(x => {
        mediaState.productBrochures.push({ documentId: x?.image?.documentId, document: x?.image, imageid: x?.imageid, productimageid: x?.productimageid, filename: 'BROUCHER', documentName: "BROUCHER PDF", name: x.name });
      });
    }
    setMediaState({ ...mediaState });
  }
  //functions for image
  const handleOnDrop = async (input, data, file_name) => {

    if(mediaState[input].length>4){
      error[input] = "Maximum 5 files only allowed."; setError({ ...error })
      return
    }

    if (data) {
      const maxSizeInBytes = 5048576;//5Mb
      if (data.size > maxSizeInBytes) {
        error[input] = "Image size exceeds the limit. Please choose a smaller image."; setError({ ...error });
        return
      }
      else{
        error[input] = ""; setError({ ...error });
      }
      tabLoading(true);
      console.log('datat', data);
      let file = data;
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        selectImg(input, reader.result, file_name)
      };
    }
  }

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  const selectImg = async (input, data, file_name) => {
    if (data.length > 0) {
      var random = Math.random();
      var fileName = random + '.png';
      var file = dataURLtoFile(data, fileName);
      const formData = new FormData();
      formData.append('file', file);
      let aws_folder = input === 'productImages' ? 'image' : input === 'productPos' ? 'display' : input === 'packagingImages' ? 'packaging' : '';
try{
      const resImagePath = await commonService.fileUploadService(formData, '/mediaupload/upload/' + aws_folder).catch(err => {
        commonService.postService({ err: err.response.data.message, reqUrl: window.location.pathname, service: '/mediaupload/upload/' }, '/error')
      });

      if (resImagePath) {
        if (input === 'productImages') {
          let temp = mediaState.productImages;
          temp.push({ image: resImagePath.data.url, documentUrl: process.env.REACT_APP_CDN_URL_TEMP + resImagePath.data.url, filename: file_name });
          mediaState[input] = temp;
          setMediaState({ ...mediaState });
        } else if (input === 'productPos') {
          let temp = mediaState.productPos;
          temp.push({ image: resImagePath.data.url, documentUrl: process.env.REACT_APP_CDN_URL_TEMP + resImagePath.data.url, filename: file_name });
          mediaState[input] = temp;
          setMediaState({ ...mediaState });
        } else if (input === 'packagingImages') {
          let temp = mediaState.packagingImages;
          temp.push({ image: resImagePath.data.url, documentUrl: process.env.REACT_APP_CDN_URL_TEMP + resImagePath.data.url, filename: file_name });
          mediaState[input] = temp;
          setMediaState({ ...mediaState });
        }
        console.log('mediaState', mediaState);
        tabLoading(false);
      } else {
        tabLoading(false);
      }
      }catch(err){
        ToastsStore.error('image exceed the desired limit');
        tabLoading(false);
      }
    }
    
  }
  const handlePreviewChange = (e, id) => {
    console.log('prof', e, e.target.files[0]);
    let data = e.target.files[0];
    if (data) {
      let file = data;
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        console.log('file', file)
        console.log('reader.result', reader.result)
        if (id === 1) {
          setReviewImage(reader.result);
        } else if (id === 2) {
          setEndUserImage(reader.result);
        }
      }
    }
  }

  const selectReviewImg = async (data, i, input) => {
    // console.log('data', data);
    console.log('i', i);
    console.log('input', input);
    if (data.length > 0) {
      var random = Math.random();
      var fileName = random + '.png';
      var file = dataURLtoFile(data, fileName);
      const formData = new FormData();
      formData.append('file', file);
      tabLoading(true);
      const resImagePath = await commonService.fileUploadService(formData, '/mediaupload/upload/account-s3').catch(err => {
        commonService.postService({ err: err.response.data.message, reqUrl: window.location.pathname, service: '/mediaupload/upload/account-s3' }, '/error')
      });
      if (resImagePath) {
        if (input === 'ProductReviews') {
          state.review.map(x => {
            console.log('check', x.id, i)
            if (x.id === i)
              x.documentUrl = process.env.REACT_APP_CDN_URL_TEMP + resImagePath.data.url;
            x.logo = resImagePath.data.url;
            if (x?.imageid) {
              x.imageid = null;
            }
          });
        } else if (input === 'EnduserReviews') {
          state.productexistingrating.map(x => {
            if (x.id === i)
              x.documentUrl = process.env.REACT_APP_CDN_URL_TEMP + resImagePath.data.url;
            x.ratingproofvalidationlogo = resImagePath.data.url;
            if (x?.ratingproofvalidationlogoid) {
              x.ratingproofvalidationlogoid = null;
            }
          });
        }
        setState({ ...state });
        tabLoading(false);
        handleCropClose();
      }
    }
  }

  const handleDateChange = (date) => {
    errors.date = null;
    setSelectedDate(date);
  };

  const handleChangeDescription = (value) => {
    setState({ ...state, productDescription: value });
  };


  const handleChange = input => async e => {
    if(input === 'moq')
    {
        e.target.value = e.target.value.split('').filter(char => !isNaN(char)).join('');
    }
    //console.log("add", e.target.value);
    // setState({...state, [input] : e.target.value});
    if (input === 'productVisibility' || input === 'productVisibility1' || input === 'productVisibility2') {
      setState({ ...state, [input]: !state[input] });
    }
    // else if (input == "productDescription") {
    //   setState({ ...state, [input]: e.target.value.slice(0, 2000) })
    // } 
    else if (input == "tweetPitch") {
      setState({ ...state, [input]: e.target.value.slice(0, 280) })
    } else if (input == "productshortdec") {
      setState({ ...state, [input]: e.target.value.slice(0, 140) })
    }
    else if (input == "productType") {
      var userData = JSON.parse(localStorage.getItem('CHuser'));
      errors.productType = null;
      if (userData.businessType != e.target.value && userData.businessType != 3) {
        changeBusinessType(userData.businessType, e.target.value);
      }
      else {
        state[input] = e.target.value;
        setState({ ...state });
      }

    }
    else if(input=="msrp"){
      if(e.target.value?.length && e.target.value?.slice(-1)[0]==','){
        state[input] = e.target.value;
        showCommaPopup();
        
      }else{
        state[input] = e.target.value;
  
      }
      setState({...state});
      
    }
    else {
      state[input] = e.target.value;
      setState({ ...state });
    }
  }
  const handleChangeReview = (i, input) => e => {
    console.log('e', e.target.value, e.target);
    if (input === 'reviewername' || input === 'articleurl' || input === 'logo') {
      let review = [...state.review];
      console.log('review', review);
      if (input === 'reviewername') {
        review[i].reviewername = e.target.value;
      }
      if (input === 'articleurl')
        review[i].articleurl = e.target.value;
      if (input === 'logo')
        review[i].logo = e.target.value;
      setState({ ...state, review: review })
    } else {
      let productexistingrating = [...state.productexistingrating];
      console.log('productexistingrating', productexistingrating);
      if (input === 'ratingproofvalidationname')
        productexistingrating[i].ratingproofvalidationname = e.target.value;
      if (input === 'ratingproofvalidationurl')
        productexistingrating[i].ratingproofvalidationurl = e.target.value;
      if (input === 'existproductrating')
        productexistingrating[i].existproductrating = parseInt(e.target.value);
      setState({ ...state, productexistingrating: productexistingrating });
    }
    console.log("add", e.target.value, e.target);
  }
  const handleChangeSelect = (input, value) => {
    if (input === 'productCategory') {
      state.productCategory = value
      setState({ ...state });
    }
    else if (input === 'productBrand') {   
      state.productBrand = value
      setState({ ...state });
    } else if (input == 'Keywords') {
      console.log('value', value);
      let name = value?.name;
      let tags = {
        tag: value.name
      }
      state.keyTags.push(tags);
      state.Keywords = null;
      setState({ ...state });
    }
    if (input !== 'productCategory' && input !== 'productBrand' && input !== 'Keywords') {
      state[input] = [value]
      setState({ ...state });
    }
  }
  const handleChangeReviews = async (input, value, id) => {
    console.log('value', value);
    console.log('id', id);
   if(input === 'EnduserReviews'){
    state.productexistingrating.map(x => {
      if (x.id === id){
        x.documentUrl = value.document.documentUrl;
        x.ratingproofvalidationlogo = value.document.documentUrl;
        x.ratingproofvalidationlogoid = value.document.documentId;
        }
    });
    setState({ ...state });
    }else if(input === 'ProductReviews'){
    state.review.map(x => {
      if (x.id === id){
        x.documentUrl = value.document.documentUrl;
        x.logo = value.document.documentUrl;
        x.imageid = value.document.documentId;
      }
    });
    setState({ ...state });
  }
}
  const handleChangeFileSelect = async (input, value, filename) => {
    if (input === 'productBrochures') {
      console.log('brochures');
      let temp = mediaState.productBrochures;
      let newFiles = value.filter(file => !mediaState.productBrochures.find(f => f.documentId === file.documentId));

      console.log('temp', temp, temp.length + newFiles.length)
      if (temp.length + newFiles.length <= 5) {
        temp.push(...newFiles);
        mediaState[input] = temp;
        setMediaState({ ...mediaState });
      }
      else {
        console.log('Maximum 5 Images, Accepts PDF only, File Size Should Not Exceed 5 mb');
      }
    }else if(input === 'awardsImages'){
      console.log('awardsImages');
      let temp = state.awards;
      let files=[];
      value.map(x=>{
        if(x?.channelDocumentId)
        files.push({  channelDocumentId:parseInt(x.channelDocumentId), channelId:x.channelId, documentUrl:x.document.documentUrl, documentId:x.documentId })
        else
        files.push({  id:parseInt(x.id), channelId:x.channelId, documentUrl:x.document.documentUrl, documentId:x.documentId })
      })
      let newFiles = files.filter(file => !state.awards.find(f => f.documentId === file.documentId));
      
      console.log('temp', temp, temp.length + newFiles.length)
        temp.push(...newFiles);
        if(state.deletedAwards?.length){
          let docIds= [];
          temp.map(x=>{
            if(x?.id)
            docIds.push(x.id);
          })
          console.log('docIds',docIds);
          state.deletedAwards=state.deletedAwards.filter(x=> !docIds.includes(x));
          }
        setState({...state, [input]:temp});
      console.log('state',state);
      console.log('mediaState',mediaState);
    }
    else {
     // debugger;
      let res = await handleOnDrop(input, value, filename);
      if (res) {
        console.log('res', res);
        // mediaState[input]=value;
        // setMediaState({...mediaState})
      };
    }
  }

  const pdfDelete = (data) => {
    let deleteState = data?.productimageid ? parseInt(data?.productimageid) : null;
    if (deleteState)
      state.deletedproductbrochure.push(data);
    let filteredState = mediaState.productBrochures.filter(item => parseInt(item?.document?.documentId) !== parseInt(data?.document?.documentId));
    if (filteredState) {
      mediaState.productBrochures = filteredState;
    }
    setMediaState({ ...mediaState });
    console.log('after delete', mediaState);
  }

  const handleChangeFileDelete = async (input, fileToBeRemoved) => {
    let filteredState = await mediaState[input].filter(item => item.filename !== fileToBeRemoved);
    if (filteredState) {
      mediaState[input] = filteredState;
      setMediaState({ ...mediaState });
      console.log('after delete', mediaState);
    }
  }

  // const handleChangeQuill1 = (content, delta, source, editor) => {
  //   state.tweetPitch = editor.getText();
  //     setState({...state});
  // }

  // const handleChangeQuill2 = (content, delta, source, editor) => {
  //   state.productDescription = editor.getText();
  //   setState({...state});
  // }
  // const handleChangeQuill3 = (content, delta, source, editor) => {
  //   state.productCertification = editor.getText();
  //   setState({...state});
  // }

  // const handleChangeQuill4 = (content, delta, source, editor) => {
  //   state.productWarranty = editor.getText();
  //   setState({...state});
  // }

  // const handleChangeQuill5 = (content, delta, source, editor) => {
  //   state.productLocalisation = editor.getText();
  //   setState({...state});
  // }

  // const handleChangeQuill6 = (content, delta, source, editor) => { 
  //   state.productshortdec = editor.getText();
  //   setState({...state});
  // }
  const addAwards = (data) => {
    console.log('data', data);
    let index = state.awards.findIndex((obj => obj.id == data.id));
    if (index != -1) {
      state.awards[index] = data;
      setState({ ...state, awards: state.awards });
      console.log('updated', data);
    } else {
      let temp = state.awards;
      temp.push(data);
      setState({ ...state, awards: temp });
      console.log('added', data);
    }
  }

  // const deleteAwards = (data) => {
  //   const deleteAward = state.awards.find((item) => {
  //     return item.id === data.id;
  //   });
  //   if (deleteAward?.product_award_id)
  //     state.deleteAward.push(parseInt(deleteAward.product_award_id));

  //   const filteredList = state.awards.filter((item) => {
  //     return item.id !== data.id;
  //   });
  //   state.awards = filteredList;
  //   setState({ ...state, awards: state.awards });
  // }
  const deleteAwards = (data) => {
    
    if(data?.productAwardId){
    state.savedAwards.filter((item) => {
      if(data.productAwardId == item.productAwardId)
        state.deletedAwards.push(item.productAwardId);
    });
    const filteredList = state.awards.filter((item) => item.productAwardId !== data.productAwardId);
    state.awards = filteredList;
    
    setState({...state, awards: state.awards, deletedAwards: state.deletedAwards});
  }else{
    const filteredList = state.awards.filter((item) => item.documentId !== data.documentId);
    state.awards = filteredList;
    setState({...state, awards: state.awards});
  }
  }

  const popUpBeforeSave = async () => {
    if (mediaState?.productImages?.length == 0) {
      confirmInactive();
    }
    else {
      save();
    }
  }
  const myRef = useRef(null);
  const save = async () => {
    
    try {
      let urllink =watchAll.videoUrl;
      console.log('urllink', urllink);
      setIsUtube(false);
      if(urllink){
      const samp = urllink.match(/^https?:\/\/([\w-]+\.\w{2})\/([\w-]{11})?/);
      console.log('samp', samp);
      if(samp){
      if (samp[1] !== 'youtu.be'){
      setIsUtube(true);
      myRef.current.scrollIntoView();
      return
      }
      }else{
        setIsUtube(true);
        myRef.current.scrollIntoView();
        return
      }
      }
      setLoading(true);
      tabLoading(true);
      
      // if(!props.ratingId)
      // featureValidation();

      let visibility = [];

      if (state.productVisibility)
        visibility.push({ sharetypeid: 3 });
      if (state.productVisibility1 && (state.productType == '1' || state.productType == '3'))
        visibility.push({ sharetypeid: 4 });
      if (state.productVisibility2 && (state.productType == '2' || state.productType == '3'))
        visibility.push({ sharetypeid: 5 });

      let productBasicInfo = {
        productgroup: +state.productCategory.groupid,
        productcategory: +state.productCategory.id,
        productbrand: hasBrand.length == 1 ? +hasBrand.brand : +state.productBrand.id,
        productname: watchAll.proname,
        productstatus: watchAll.status ? '1' : '0',
        productdesc: state.productDescription,
        productshortdec: state.productshortdec,
        productusp: state.tweetPitch,
        keydistributors: null,
        keyretailers: null,
        keyresellers: null,
        producttag: state.keyTags,
        businessType: +state.productType,
        
        isApproved: state.isApproved,
        approvalType: state.approvalType,
        isActiveOriginal: state.isActiveOriginal
      }
      // producttagid

      if (productBasicInfo?.producttag?.length === 0)
        delete productBasicInfo.producttag;


      let productArticleReview = {
        review: [],//[{name:null,articleurl:null,logo:null}],
        productexistingrating: []//[{existproductrating:null,ratingproofvalidationurl:null,ratingproofvalidationname:null,ratingproofvalidationlogo:null}],
      }

      state.review.map(x => {
        if (x.reviewername && x.articleurl) {
          let res = {
            reviewername: x.reviewername,
            articleurl: x.articleurl,
            logo: x.logo,
            imageid: x?.imageid ? parseInt(x?.imageid) : null,
            productarticlereviewid: x?.productarticlereviewid ? parseInt(x?.productarticlereviewid) : null
          }
          if (!res.productarticlereviewid)
            delete res.productarticlereviewid;
          // if (!res.imageid)
          //   delete res.imageid;
          // if (res.imageid) {
          //   delete res.imageid;
          //   delete res.logo;
          // }
          productArticleReview.review.push(res)
        }
      })
      state.productexistingrating.map(x => {
        if (x.ratingproofvalidationurl && x.ratingproofvalidationname) {
          let res = {
            existproductrating: x.existproductrating ? parseInt(x.existproductrating) : 5,
            ratingproofvalidationurl: x.ratingproofvalidationurl,
            ratingproofvalidationname: x.ratingproofvalidationname,
            ratingproofvalidationlogo: x.ratingproofvalidationlogo,
            productexistratingid: parseInt(x.productexistratingid),
            ratingproofvalidationlogoid: parseInt(x.ratingproofvalidationlogoid)
          }
          if (!res.ratingproofvalidationlogoid)
            delete res.ratingproofvalidationlogoid;
          if (!res.productexistratingid)
            delete res.productexistratingid;
          // if (res.ratingproofvalidationlogoid) {
          //   delete res.ratingproofvalidationlogoid;
          //   delete res.ratingproofvalidationlogo;
          // }
          productArticleReview.productexistingrating.push(res)
        }
      })
      if (productArticleReview?.review?.length === 0)
        delete productArticleReview.review
      if (productArticleReview.productexistingrating.length === 0)
        delete productArticleReview.productexistingrating;

      let productimage = [];
      if (mediaState?.productImages?.length > 0) {
        mediaState.productImages.map((x, i) => {
          if (!x?.imageid)
            productimage.push({ image: x.image, isdefault: i === 0 ? '1' : '0', sortorder: i + 1 })
        })
      }
      let productdisplayimage = [];
      if(mediaState.productPos?.length){
      mediaState.productPos.map(x => {
        if (!x?.imageid)
          productdisplayimage.push({ image: x.image });
      });
      }
      let productpackagingimage = [];
      if(mediaState.packagingImages?.length){
      mediaState.packagingImages.map(x => {
        if (!x?.imageid)
          productpackagingimage.push({ image: x.image });
      });
      }
      let productImages = {
        videourl: watchAll.videoUrl,
        productimage: productimage,
        productdisplayimage: productdisplayimage,
        productpackagingimage: productpackagingimage
      }
      let broucherList = mediaState.productBrochures;
      if (params?.action === '1') {
        if (productDetail?.images?.BROUCHER?.length > 0) {
          // let ids =[];
          // productDetail.images.BROUCHER.map(x=>{
          //   ids.push(parseInt(x.imageid))
          // });
          // broucherList=broucherList.filter(x=> !ids.includes(parseInt(x.documentId)));
          let productids = [];
          productDetail.images.BROUCHER.map(x => {
            productids.push(parseInt(x.productimageid))
          });
          state.deletedproductbrochure = state.deletedproductbrochure.filter(x => productids.includes(parseInt(x?.productimageid)));
        }
        if (state?.deletedproductbrochure?.length > 0) {
          let ids = [];
          mediaState.productBrochures.map(x => {
            ids.push(parseInt(x.documentId))
          });
          state.deletedproductbrochure = state.deletedproductbrochure.filter(x => !ids.includes(parseInt(x.documentId)));
        }
        setState({ ...state });
        setMediaState({ ...mediaState });
      }
      let productBrochure = {
        productpdf: broucherList?.length ? [].concat(broucherList.map(x => {
          return { imageid: parseInt(x.document.documentId), documentName: null, documentDescription: null, documentName: x.name, productimageid: x?.productimageid ? parseInt(x.productimageid) : null };
        })) : []
      }
      let deletedproductbrochure = [];
      state.deletedproductbrochure.map(x => {
        deletedproductbrochure.push(parseInt(x.productimageid));
      });
      if (productImages?.productdisplayimage?.length === 0)
        delete productImages.productdisplayimage;

      if (productImages?.productimage?.length === 0)
        delete productImages.productimage;

      if (productImages?.productpackagingimage?.length === 0)
        delete productImages.productpackagingimage;

      if (productBrochure?.productpdf?.length === 0)
        delete productBrochure.productpdf;

      let ProductInfo = {
        productCertifi: state.productCertification,
        productWarranty: state.productWarranty,
        productLocal: state.productLocalisation,
      }
      let productPreferences = {
        preferences: visibility,
        isshowtofreesub: "0",
      }
      // let award_details = state.awards ? [].concat(state.awards.map(x => {
      //   let res = {
      //     product_award_id: x?.product_award_id ? parseInt(x?.product_award_id) : null,
      //     details: x.award_details,
      //     issued_by: x.awardName,
      //     valid_upto: x.date !== '1' ? x.date : null,
      //     isLifeTime: x.lifeTime ? '1' : '0',
      //     imageId: x?.imageId ? parseInt(x?.imageId) : null,
      //     image: x.awardImg ? x.awardImg : null
      //   }
      //   if (res.imageId)
      //     delete res.image;
      //   return res;
      // })) : [];

      let awardDocumentIds = [];
      console.log('savedAwards', state.savedAwards);
      state.savedAwards.map(x=> awardDocumentIds.push(x.documentId));
      let award_details = state.awards.filter(x=> !awardDocumentIds.includes(x.documentId));
      let adwardsDocumentId =[];
      if(award_details?.length)
        award_details.map(x=>adwardsDocumentId.push({documentId:parseInt(x.documentId)}));
      let product = {
        productBasicInfo: productBasicInfo,
        productArticleReview: productArticleReview,
        productImages: productImages,
        productBrochure: productBrochure,
        ProductInfo: ProductInfo,
        productPreferences: productPreferences,
        award_details: adwardsDocumentId
      }
      if (product?.award_details?.length === 0)
        delete product.award_details

      let deleteList = {}
      if (!isAdd && params?.action == '1') {
        
        deleteList = {
          deletedproductbrochure: deletedproductbrochure,
          deletedproductimage: state.deletedproductimage,
          deletedproductdisplayimage: state.deletedproductdisplayimage,
          deletedproductpackageimage: state.deletedproductpackageimage,
          deleted_award_details: state?.deletedAwards?.length?state.deletedAwards:[],
          deletedarticlereview: state.deletedarticlereview,
          deletedexistingrating: state.deletedexistingrating,
          deletedproducttag: state.deletedproducttag
        }
        
        if (deleteList?.deletedproductbrochure?.length === 0)
          delete deleteList.deletedproductbrochure
        if (deleteList?.deletedproductimage?.length === 0)
          delete deleteList.deletedproductimage
        if (deleteList?.deletedproductdisplayimage?.length === 0)
          delete deleteList.deletedproductdisplayimage
        if (deleteList?.deletedproductpackageimage?.length === 0)
          delete deleteList.deletedproductpackageimage
        if (deleteList?.deleted_award_details?.length === 0)
          delete deleteList.deleted_award_details
        if (deleteList?.deletedarticlereview?.length === 0)
          delete deleteList.deletedarticlereview
        if (deleteList?.deletedexistingrating?.length === 0)
          delete deleteList.deletedexistingrating
        if (deleteList?.deletedproducttag?.length === 0)
          delete deleteList.deletedproducttag

        saveGeneralForm({ ...product, ...deleteList });
        saveTrade();
      } else {
        saveGeneralForm(product);
        saveTrade();
      }
      //  tabLoading(false);
    }
    catch (err) {
      console.log('err', err);
      ToastsStore.error('Something Went Wrong!');
      setLoading(false);
      tabLoading(false);
      commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: 'GeneralForm' }, '/error');
    }
  }


  const saveTrade = async () => {
    setLoading(true);
    let visibility = [];
    if (state.productVisibility)
      visibility.push({ sharetypeid: 3 });
    if (state.productVisibility1)
      visibility.push({ sharetypeid: 4 });

    let productAvailability = {
      isavaialbleimmediate: state.availability === 'Immediate' ? '1' : '0',
      avaialblefrom: state.availability !== 'Immediate' ? selectedDate : null,
      avaialbilitycomment: state.availability !== 'Immediate' ? state.text_about_sample : null,
    }

    let productPrice = {
      currencyid: parseInt(state.businessCurrency),
      retailerprice: +watchAll.msrp.replace(',', '.'),
      retailerpriceunitid: parseInt(state.unit),
      samplethreshold: +watchAll.max_qty_sample,
      isunlimitsample: watchAll.no_limit ? "1" : "0",
    }
    let productPackageInfo = {
      minorderquantity: +watchAll.moq,
      orderunitid: parseInt(state.moqUnit),
      packagesize: null,
      packageunit: null,
      noofpackageinbox: +watchAll.no_of_items_in_box,
      noofpackageinboxunitid: null,
      noofboxinpallet: null,
      noofboxinpalletunitid: null,
      approximateweightofpallet: null,
      approximateweightofpalletuniti: null,
      length: null,
      breadth: null,
      height: null,
      weight: null,
    }
    let productCode = {
      productCodeTypes: [],//[{productuniversalcodetype:null,code:null}],
      hasanyvariant: watchAll.productAttributes,
      variantdetails: watchAll.productAttributes === '1' ? watchAll.variantDetails : null,
    }

    let productPosition = {
      productqualitylevel: watchAll.productPositioning,
    }

    let product = {
      productCode: productCode,
      productPrice: productPrice,
      productPackageInfo: productPackageInfo,
      productPosition: productPosition,
      productAvailability: productAvailability,
    }
    console.log('product', product);
    saveTradeDetails(product);
    setLoading(false);
  }

  const deleteImg = (e, input) => {
    if (input === 'productPos') {
      error.productPos = null;
      mediaState.productPos = mediaState.productPos.filter(x => x.documentUrl !== e.documentUrl);
      if (e?.productimageid)
        state.deletedproductdisplayimage.push(parseInt(e.productimageid));
    } else if (input === 'packagingImages') {
      error.packagingImages = null;
      mediaState.packagingImages = mediaState.packagingImages.filter(x => x.documentUrl !== e.documentUrl);
      if (e?.productimageid)
        state.deletedproductpackageimage.push(parseInt(e.productimageid));
    } else if (input === 'productImages') {
      error.productImages = null;
      mediaState.productImages = mediaState.productImages.filter(x => x.documentUrl !== e.documentUrl);
      if (e?.productimageid)
        state.deletedproductimage.push(parseInt(e.productimageid));
    }
    setMediaState({ ...mediaState });
    setState({ ...state });
    setError({ ...error });
  }

  const deleteReview = (item, i, input) => {
    console.log(item, i, input);
    if (input === 'ProductReviews') {
      if (state.review.length === 1) {
        state.review[0].reviewername = Math.random();
        state.review[0].reviewername = null;
        state.review[0].articleurl = null;
        state.review[0].logo = null;
        state.review[0].documentUrl = null;
        setState({ ...state });
        // state.review= [{id: Math.random(), articleurl: null, reviewername:null, logo:null, documentUrl:null}];
      } else {
        state.review.splice(i, 1);
      }
      if (item?.productarticlereviewid)
        state.deletedarticlereview.push(parseInt(item.productarticlereviewid));
      setState({ ...state });
    } else if (input === 'EnduserReviews') {
      if (state.productexistingrating.length === 1) {
        state.productexistingrating = [{ id: Math.random(), existproductrating: null, ratingproofvalidationname: null, ratingproofvalidationurl: null, ratingproofvalidationlogo: null, documentUrl: null }]

      } else {
        state.productexistingrating.splice(i, 1);
      }
      if (item?.productexistratingid)
        state.deletedexistingrating.push(parseInt(item.productexistratingid));
      setState({ ...state });
    }

  }
  const addReview = (input) => {
    console.log(input);
    if (input === 'ProductReviews') {
      state.review.push({ id: Math.random(), articleurl: null, reviewername: null, logo: null, documentUrl: null })
    } else if (input === 'EnduserReviews') {
      state.productexistingrating.push({ id: Math.random(), existproductrating: null, ratingproofvalidationname: null, ratingproofvalidationurl: null, ratingproofvalidationlogo: null, documentUrl: null })
    }
    setState({ ...state });
  }
  const deleteKeyTag = (data) => {
    console.log('data', data);
    state.keyTags = state.keyTags.filter(x => x.tag !== data.tag);
    if (data?.producttagid)
      delete state.deletedproducttag.push(parseInt(data.producttagid));
    setState({ ...state });
  }

  const editName = (item) => {
    docName = item.name;
    setDocName(docName);
    docId = item.documentId;
    setDocId(docId);
    setNamePopup(true);
  }
  const docNameChange = async (docId, name) => {
    console.log('media', mediaState.productBrochures);
    console.log(docId, name);
    mediaState.productBrochures.map(x => {
      if (parseInt(x.documentId) === parseInt(docId))
        x.name = name;
    })
    namePopupClose();
  }
  const namePopupClose = () => {
    setNamePopup(false);
  };
  const closeplanUpgrade = () => {
    setplanUpgrade(false);
    history.push({ pathname: '/admin/Myproductlist' });
  }

  const confirmPopup = (res) => {
    setModalPopup(false);
    state['productType'] = res.value;
    setState({ ...state });
  }
  const closePopup = () => {
    setModalPopup(false);
    setActiveProductPop(false);
  };

  const changeBusinessType = (ctype, value) => {
    ctype = ctype == 1 ? 'B2C' : 'B2B';
    let btype = value == 1 ? 'B2C' : 'B2B';
    modalPopValue.message = 'Your are an "#' + ctype + '" company, But you are trying to switch your product to "#' + btype + '". Do you wants to consider your company as Both B2B and B2C ?';
    modalPopValue.title = 'Product Type';
    modalPopValue.value = value;
    modalPopValue.popUpId = 1;
    setModalPopValue({ ...modalPopValue });
    setModalPopup(true);
  }

  const [activeProductPop, setActiveProductPop] = useState(false);

  const confirmSavePopup = (value) => {
    setActiveProductPop(false);
    save();
  }

  const confirmInactive = () => {
    modalPopValue.message = "You haven't added product images so, we are moving your product to inactive. You can add product image later and make it active."
    modalPopValue.title = 'Product Status';
    modalPopValue.value = 'Confirm';
    modalPopValue.popUpId = 2;
    setModalPopValue({ ...modalPopValue });
    setActiveProductPop(true);
  }
  const openAwardMediaLib=()=>{
    if(state.awards.length>=5){
      ToastsStore.error('Maximum 5 Images');
    }else{
      setAwardImage(true);
    }
  }
  const openEndUser = (x)=>{
    console.log('x', x);
    setEndUserReviewId(x.id)
    setEndUserReviewDocId(x?.ratingproofvalidationlogoid);
      setEndUserReview(true);
  }
  const openProductReview = (x)=>{
    console.log('x', x);
    setProductReviewId(x.id)
    setProductReviewDocId(x?.imageid);
      setProductReview(true);
  }
  //console.log('watchaAll', watchAll);

    let [isCommaModalPopup, setCommaModalPopup] = useState(false);
    

    const confirmPopupComma = (res) => {
      setCommaModalPopup(false);
    
    }
    const closePopupComma = () => {
      setCommaModalPopup(false);
    
    };

    

    const showCommaPopup = (data) => {


    let message = <>
    <p>You are entering a comma in price, in CH we will consider , as .</p>
    
    </>

      modalPopValue.message = message;
      modalPopValue.title = 'Note : ';
      modalPopValue.popUpId = 1;
      setModalPopValue({ ...modalPopValue });
      setModalPopup(true);
    }

    const updateImages = (images) => {
      let result=images.map((img,index) => {
        return {productimageid:+img.productimageid,productId:+img.productId,sortorder:index+1,isdefault:index==0?'1':'0'}
      })
     commonService.postService(result, '/Product/sortproductimage')
      
    }

  return (
    <>
      {loading && <Spinner />}
      <div className="main-content">
        <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore} />
        <Dialog open={isModalPopup} value={modalPopValue} handleSave={confirmPopup} handleClose={closePopup} />
        <Dialog open={isCommaModalPopup} value={modalPopValue} handleSave={confirmPopupComma} handleClose={closePopupComma} />
        <ConfirmDialog open={activeProductPop} value={modalPopValue} handleSave={confirmSavePopup} handleClose={closePopup} />
        {planUpgrade && <PlanFeatureDialog closeUserView={closeplanUpgrade} UpgradeType={upgradeType} />}
        <DocumentName isOpen={namePopup} handleClose={namePopupClose} isEdit={true} handleSave={docNameChange} name={docName} docId={docId} />
        {awardImage && 
              <MultiSelectImages 
                id={3} 
                isOpen={awardImage}
                multiple={true}
                limit={5}
                acceptedFiles={['image/jpeg','image/png','image/gif']}
                getBrochuresIds={handleChangeFileSelect}
                input="awardsImages" aws_folderName="awards"
                handleClose={()=>{ setAwardImage(false) }}
                documentIds={state.awards.length>0?state.awards.map(x=>parseInt(x.documentId)):[]}
              />
              }
        {productReview && 
              <ImageLibrary 
                id={4} 
                isOpen={productReview}
                multiple={false}
                limit={1}
                acceptedFiles={['image/jpeg','image/png','image/gif']}
                getDocumentId={handleChangeReviews}
                input="ProductReviews" aws_folderName="ProductReviews"
                handleClose={()=>{ setProductReview(false) }}
                documentIds={[productReviewDocId]}
                selectedId={productrReviewId}
              />
              }
        {endUserReview && 
              <ImageLibrary 
                id={5} 
                isOpen={endUserReview}
                multiple={false}
                limit={1}
                acceptedFiles={['image/jpeg','image/png','image/gif']}
                getDocumentId={handleChangeReviews}
                input="EnduserReviews" aws_folderName="EnduserReviews"
                handleClose={()=>{ setEndUserReview(false) }}
                documentIds={[endUserReviewDocId]}
                selectedId={endUserReviewId}
              />
              }
        {(!isAdd && isdata) ? '' :
          <form noValidate onSubmit={handleSubmit(popUpBeforeSave)}>
            <Card>
              <CardHeader>
                <h3 className="text-center">Basic Info</h3>
                <p className="text-center">Enter your general product info like product name, category, USP, info and Keywords / Tags</p>
              </CardHeader>
              <CardBody  >
                <div className="row">
                  <div className="col-lg-8 col-sm-12">
                    <div className="form-group">
                      <FormInput
                        id="proname"
                        type="text"
                        name="proname"
                        label="Product Name *"
                        //onChange={handleChange('productName')}
                        register={register}
                        defaultValue={state.productName}
                        value={watchAll.proname}
                        error={errors.proname}
                      />
                    </div>
                  </div>
                  <div className="col-lg-2 col-6 pt-3 text-right"><h3>Status</h3></div>
                  <div className="col-lg-2 col-6 pt-3 ">
                    <div className="form-group">
                      <label className="custom-toggle custom-toggle-success">
                        <input type="checkbox" checked={state?.status} ref={register} name="status" onClick={() => setState({ ...state, status: !state.status })} />
                        <span className="custom-toggle-slider rounded-circle" data-label-off="Inactive" data-label-on="Active"></span>
                      </label>
                    </div>
                  </div>
                </div>

                <Row>
                  <div className="col-lg-8">
                    <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="productType" name="productType" ref={register} value={state.productType} />
                    <h4 style={{ textAlign: 'left' }}>Product's Type *
                      <span style={{ color: '#f44336', fontSize: '0.75rem' }}>{!!errors.productType && ' Please Select Product Type'}</span>
                    </h4>
                    <h5>B2B or B2C Products?</h5>
                  </div>
                  <div className="col-lg-4  ">
                    <div className="form-group">
                      <FormRadio
                        name="productType1"
                        id="productType"
                        className="text-sm mb-0"
                        label=""
                        options={businessTypeOptions}
                        control={control}
                        value={state.productType}
                        defaultValue={state.productType}
                        row
                        onChange={handleChange('productType')}
                      />

                    </div>
                  </div>
                </Row>



                <div className="row">
                  <div className="col-sm-12 ">
                    <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="productshortdec" name="productshortdec" ref={register} value={state.productshortdec} />
                    {!errors.productshortdec || (state.productshortdec?.length > 5 && state.productshortdec?.length < 140) ? <h4 style={{ textAlign: 'left' }}>Product short description *
                      {state.productshortdec.length > 0 && <span style={{ color: state.productshortdec?.length > 140 ? "red" : "#9ea2a5" }}> remaining characters  {140 - state.productshortdec.length}</span>}
                    </h4> :
                      <h4 style={{ textAlign: 'left' }}>Product short description *
                        {state.productshortdec.length > 0 && <span style={{ color: state.productshortdec?.length > 140 ? "red" : "#9ea2a5" }}> remaining characters  {140 - state.productshortdec.length}</span>}
                        <span style={{ color: '#f44336', float: 'right', fontSize: '0.75rem' }}>{
                          state.productshortdec?.length <= 1 ? 'Product Short Description can\'t be empty.' : state.productshortdec?.length <= 5 ? 'Product Description must have min 5 characters' : state.productshortdec?.length > 140 ? 'Product Description must have max 140 characters' : ''
                        }</span></h4>}
                    <div className="form-group">
                      <FormTextArea className="buytext22" placeholder="tell us in 2 or 3 words what’s the use of this product*" value={state.productshortdec} onChange={handleChange('productshortdec')} />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 ">
                    <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="tweetPitch" name="tweetPitch" ref={register} value={state.tweetPitch} />
                    {!errors.tweetPitch || (state.tweetPitch?.length > 5 && state.tweetPitch?.length < 280) ? <h4 >Your Tweet Pitch * (280 Characters Max)
                      {state.tweetPitch.length > 0 && <span style={{ color: state.tweetPitch?.length > 280 ? "red" : "#9ea2a5" }}> remaining characters  {280 - state.tweetPitch.length}</span>}
                    </h4> :
                      <h4 >Your Tweet Pitch * (280 Characters Max)
                        {state.tweetPitch.length > 0 && <span style={{ color: state.tweetPitch?.length > 280 ? "red" : "#9ea2a5" }}> remaining characters  {280 - state.tweetPitch.length}</span>}
                        <span style={{ color: '#f44336', float: 'right', fontSize: '0.75rem' }}>{
                          state.tweetPitch?.length <= 1 ? 'TweetPitch can\'t be empty.' : state.tweetPitch?.length <= 5 ? 'TweetPitch must have min 5 characters' : state.tweetPitch?.length > 280 ? 'TweetPitch must have max 280 characters' : ''
                        } </span> </h4>}

                    <div className="form-group">
                      <FormTextArea className="buytext22" placeholder="describe in 280 characters the unique selling proposition of your product" value={state.tweetPitch} onChange={handleChange('tweetPitch')} />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12 ">
                    {/* <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="productDescription" name="productDescription" ref={register} value={state.productDescription} />  */}
                    <h4 style={{ textAlign: 'left' }}>Full Description
                      {state.productDescription?.length > 0 && <span style={{ color: state.productDescription?.length > 2000 ? "red" : "#9ea2a5" }}> remaining characters  {2000 - state.productDescription.length}</span>}
                    </h4>
                    {/* {!errors.productDescription || (state.productDescription?.length > 5 && state.productDescription?.length <8000) ? <h4 style={{textAlign:'left'}}>Product Description *</h4> :
                  <h4 style={{textAlign:'left'}}>Product Description * <span style={{color: '#f44336', float: 'right', fontSize:'0.75rem'}}>{
                  state.productDescription?.length <= 1? 'Product Description can\'t be empty.' : state.productDescription?.length <= 5 ? 'Product Description must have min 5 characters' : state.productDescription?.length > 8000 ? 'Product Description must have max 8000 characters' : ''
                  } </span></h4> } */}
                    <div className="form-group">
                      
                    <ReactQuill
                        theme="snow"
                        placeholder="Tell us a bit more about your product, up to 2000 characters maximum *"
                        onChange={handleChangeDescription}
                        value={state.productDescription}
                        // ref={register}
                        id="productDescription"
                        name="productDescription"
                        modules={{
                          toolbar: [
                            ["bold", "italic", "underline"],
                            [
                              {
                                list: "ordered",
                              },
                              {
                                list: "bullet",
                              },
                            ],
                          ],
                        }}
                      />  
                      {/* <FormTextArea className="buytext4" placeholder="Product full description" value={state.productDescription} onChange={handleChange('productDescription')} /> */}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 ">
                    <div className="form-group">
                      <SingleAutocomplete
                        // focused={errors.ProductCategory ? true : false}
                        id="ProductCategory"
                        name="ProductCategory"
                        control={control}
                        register={register}
                        label="Product Category *"
                        input="productCategory"
                        defaultValue={state.productCategory}
                        value={state.productCategory}
                        disableCloseOnSelect
                        options={state.productCategoryOption}
                        getOptionLabel={(option) => option.name}
                        handleChangeSelect={handleChangeSelect}
                        errMessage={'Choose Product\'s Category.'}
                        error={errors.ProductCategory}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 ">
                    <div className="form-group">
                      <SingleAutocomplete
                        // limitTags={2}
                        id="ProductBrand"
                        name="ProductBrand"
                        control={control}
                        register={register}
                        label="Product Brand *"
                        input="productBrand"
                        defaultValue={state.productBrand}
                        value={state.productBrand}
                        // disabled={hasBrand.length == 1 ? true : false}
                        disableCloseOnSelect
                        options={state.productBrandOption}
                        getOptionLabel={(option) => option.name}
                        handleChangeSelect={handleChangeSelect}
                        errMessage={'Choose Product\'s Brand.'}
                        error={errors.ProductBrand}
                      />
                    </div>
                  </div>
                </div>

                <div className="w-100 overall">
                  <div className=" ">

                    {state.keyTags.length > 0 &&
                      <div>
                        <Row>
                          {state.keyTags.map(x => {
                            return (
                              <Col lg="3" className="p-0 m-0 ">
                                <div style={{ width: '80%' }}>
                                  <div className="fav-cl1 pointer" onClick={() => deleteKeyTag(x)}>X</div>
                                  <Badge className="B-prod1  fav-list">{x.tag}</Badge>
                                </div>
                              </Col>
                            )
                          })}
                        </Row>
                      </div>
                    }
                    <Row>
                      <div className="col-lg-12 fav-ser mt-3">
                        <div className="form-group">
                          <h4 style={{ textAlign: 'left' }}>Product keywords </h4>
                          <NewFieldAutocomplete
                            id="Keywords"
                            type="text"
                            label="only add keyword that you think can be used by buyers in the product search"
                            name="Product Keywords"
                            options={[]}
                            placeholder="Product Keywords"
                            // error={errors.postalcode}
                            disableCloseOnSelect
                            // limitTags={2}
                            //defaultValue={{name:'raja', id:1}}
                            control={control}
                            input='Keywords'
                            value={state.Keywords}
                            //     errMessage={CompanyError ? 'Company name already taken' : companyName[0]? 'Company name must be minimum 2 characters' : 'Please enter company name'}
                            //     error={errors.companyName ? errors.companyName : CompanyError}   
                            handleChangeSelect={handleChangeSelect}
                          />
                        </div>
                      </div>
                    </Row>
                  </div>
                </div>

                <Row>
                  <div class="col-lg-9 mt-3">
                    <h4 style={{ textAlign: 'left' }}>Price Details</h4>
                    <h5>Update your product prices or ordering details</h5>
                    <Row>
                      <div className="col-lg-6 pad-les  mt-3">
                        <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="busCurrency" name="busCurrency" ref={register} value={state.businessCurrency} />
                        <FormControl variant="outlined" className={classes.formControl1} error={!errors.busCurrency || state.businessCurrency ? false : true}>
                          <InputLabel id={!errors.busCurrency || state.businessCurrency ? 'demo-simple-select-outlined-label' : 'demo-simple-select-error-label'}>Business Currency</InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            onChange={handleChange('businessCurrency')}
                            label="Business Currency *"
                            value={state.businessCurrency}
                          >
                            <MenuItem value="">
                              <em>Select Business Currency</em>
                            </MenuItem>
                            <MenuItem value={1}>Euro</MenuItem>
                            <MenuItem value={2}>Dollar</MenuItem>
                          </Select>
                          <FormHelperText style={{ color: 'red' }}>{!errors.busCurrency || state.businessCurrency ? '' : errors.busCurrency.message}</FormHelperText>
                        </FormControl>
                      </div>
                      <div className="col-lg-6 pad-les  mt-3">
                        <div className="form-group">
                          <FormInput
                            id="MSRP *"
                            type="text"
                            name="msrp"
                            label="Suggested Retail Price *"
                            register={register}
                            value={watchAll.msrp}
                            defaultValue={state.msrp}
                            error={errors.msrp}
                            onChange={handleChange('msrp')}    
                          />
                        </div>
                      </div>
                      <div className="col-lg-3 pad-les  mt-3">
                        {/* <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="unit" name="unit" ref={register} value={state.unit} /> 
              <FormControl variant="outlined"  className={classes.formControl} error={!errors.unit || state.unit ? false : true }>
              <InputLabel id={!errors.unit || state.unit ? 'demo-simple-select-outlined-label' : 'demo-simple-select-error-label'}>Unit</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="unit"
                className="test"
                onChange={handleChange('unit')}
                label="Unit"
                name="unit"
                value={state.unit}
              >
                <MenuItem value={1}>Pcs</MenuItem>
                <MenuItem value={2}>Pack</MenuItem>
                <MenuItem value={3}>Gram</MenuItem>
                <MenuItem value={4}>Kgs</MenuItem>
                <MenuItem value={5}>Box</MenuItem>
              </Select>
              <FormHelperText style={{color: 'red'}}>{!errors.unit || state.unit ?  '' : errors.unit.message }</FormHelperText>
            </FormControl> */}

                      </div>
                    </Row>
                  </div>
                </Row>

                <Row>
                  <div className="col-lg-9">
                    <h4 style={{ textAlign: 'left' }}>Product's Variants</h4>
                    <h5>does the product exist is different colors, sizes?</h5>
                  </div>
                  <div className="col-lg-3  ">
                    <div className="form-group">
                      <FormRadio
                        name="productAttributes"
                        id="productAttributes"
                        className="text-sm mb-0"
                        label=""
                        options={radioGroupOptions3}
                        control={control}
                        value={state.productAttributes}
                        defaultValue={state.productAttributes}
                        row
                        onChange={handleChange('productAttributes')}
                      />

                    </div>
                  </div>
                </Row>
                {state.productAttributes === '1' ?
                  <div className="col-lg-12">
                    <div className="form-group">
                      <FormInput
                        id="variantDetails"
                        type="text"
                        name="variantDetails"
                        label="Variant Details *"
                        placeholder="Add variant details like colors, size, type and other"
                        register={register}
                        value={watchAll.variantDetails}
                        defaultValue={state.variantDetails}
                        error={errors.variantDetails}
                      />
                    </div>
                  </div> : ''}


              </CardBody>
            </Card>



            <Card className="mt-3">
              <CardHeader>
                <h3 style={{ textAlign: 'center' }}>Marketing</h3>
                <p className="text-center">Upload your product related pictures and videos. The number of pictures in connected to your Subcription Plan, Awards...</p>
              </CardHeader>
              <CardBody>

                {/* <div className="col-lg-12 ">
            <h4 style={{textAlign:'left',display:'inline-block'}}>Product Images</h4>
            <h5 style={{float:'right'}}>(Maximum 5 Images, Accepts Jpeg / Jpg / Png Only, Image Size Should Not Exceed 2 mb)</h5>
            <div className="form-group mt-3">        
                <MultiUpload multiple={true} limit={5} acceptedFiles={['image/*']} handleChangeFile={handleChangeFileSelect} handleChangeFileDelete={handleChangeFileDelete} input="productImages"/>
                 </div>           
            </div> */}


                {/* <div className="col-lg-12 ">
            <h4 style={{textAlign:'left',display:'inline-block'}}>Product POS Displays</h4>
            <h5 style={{float:'right'}}>(Maximum 5 Images, Accepts Jpeg / Jpg / Png Only, Image Size Should Not Exceed 2 mb)</h5>
            <div className="form-group mt-3">        
           
            <MultiUpload multiple={true} limit={5} acceptedFiles={['image/*']} handleChangeFile={handleChangeFileSelect} handleChangeFileDelete={handleChangeFileDelete} input="productPos"/>
                 </div>           
            </div> */}

                <div className="col-lg-12 " style={{ display: 'none' }}>
                  <h4 style={{ textAlign: 'left', display: 'inline-block' }}>Product Packaging Images</h4>
                  <h5 style={{ float: 'right' }}>(Maximum 5 Images, Accepts Jpeg / Jpg / Png Only, Image Size Should Not Exceed 5 mb)</h5>
                  <div className="form-group mt-3">
                    <MultiUpload multiple={true} limit={5} acceptedFiles={['image/*']} handleChangeFile={handleChangeFileSelect} handleChangeFileDelete={handleChangeFileDelete} input="packagingImages" />
                  </div>
                </div>

                <div className="col-lg-12 ">
                  <h4 style={{ textAlign: 'left', display: 'inline-block' }}>Product Images</h4>
                  <h5 style={{ float: 'right' }}>(Maximum 5 Images, Accepts Jpeg / Jpg / Png Only, Image Size Should Not Exceed 5 mb)</h5>
                  <span onClick={() => handleClick(1)} onDrop={(e)=>handleOnDrop('productImages', e.dataTransfer.files[0], e.dataTransfer.files[0].name)} >
                    {
                      !mediaState?.productImages?.length ?
                        <div className="form-group mt-3">
                          <div tabindex="0" class="MuiDropzoneArea-root">
                            <div class="MuiDropzoneArea-textContainer">
                              <p class="MuiTypography-root MuiDropzoneArea-text MuiTypography-h5">Drag and drop a file here or click</p>
                              <svg class="MuiSvgIcon-root MuiDropzoneArea-icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z"></path></svg>
                            </div></div></div> :
                        // <ImageCard list={mediaState.productImages} deleteImg={(e) => deleteImg(e, 'productImages')} />
                        <ReorderImages images={mediaState.productImages} callback={updateImages} deleteImg={(e) => deleteImg(e, 'productImages')} />
                    }
                  </span>
                  {error.productImages && <p style={{ color: 'red', float: 'right', textAlign: 'end', width: '100%' }}> {error.productImages} </p>}
                  <input type="file" accept="image/*" id="hiddenFileproductImg" ref={hiddenFileproductImg} onChange={(e) => handleChangeFileSelect('productImages', e.target.files[0], e.target.files[0].name)} style={{ display: 'none' }} />
                </div>
                <div className="col-lg-12 ">
                  <h4 style={{ textAlign: 'left', display: 'inline-block' }}>Product POS Displays</h4>

                  <h5 style={{ float: 'right' }}>(Maximum 5 Images, Accepts Jpeg / Jpg / Png Only, Image Size Should Not Exceed 5 mb)</h5>
                  <h5>Add point of sale / merchandising pictures showing the product in action</h5>
                  <span onClick={() => handleClick(2)} onDrop={(e)=>handleOnDrop('productPos', e.dataTransfer.files[0], e.dataTransfer.files[0].name)}>
                    {
                      !mediaState.productPos?.length ?
                        <div className="form-group mt-3">
                          <div tabindex="0" class="MuiDropzoneArea-root">
                            <div class="MuiDropzoneArea-textContainer">
                              <p class="MuiTypography-root MuiDropzoneArea-text MuiTypography-h5">Drag and drop a file here or click</p>
                              <svg class="MuiSvgIcon-root MuiDropzoneArea-icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z"></path></svg>
                            </div></div></div> :
                        // <ImageCard list={mediaState.productPos} deleteImg={(e) => deleteImg(e, 'productPos')} />
                        <ReorderImages images={mediaState.productPos} callback={updateImages} deleteImg={(e) => deleteImg(e, 'productPos')} />
                    }
                  </span>
                  {error.productPos && <p style={{ color: 'red', float: 'right', textAlign: 'end', width: '100%' }}> {error.productPos} </p>}
                  <input type="file" accept="image/*" id="hiddenFileproductPos" ref={hiddenFileproductPos} onChange={(e) => handleChangeFileSelect('productPos', e.target.files[0], e.target.files[0].name)} style={{ display: 'none' }} />
                </div>
                <div className="col-lg-12 ">
                  <h4 style={{ textAlign: 'left', display: 'inline-block' }}>Product Packaging Images</h4>
                  <h5 style={{ float: 'right' }}>(Maximum 5 Images, Accepts Jpeg / Jpg / Png Only, Image Size Should Not Exceed 5 mb)</h5>
                  <span onClick={() => handleClick(3)} onDrop={(e)=>handleOnDrop('packagingImages', e.dataTransfer.files[0], e.dataTransfer.files[0].name)}>
                    {
                      !mediaState.packagingImages?.length ?
                        <div className="form-group mt-3">
                          <div tabindex="0" class="MuiDropzoneArea-root">
                            <div class="MuiDropzoneArea-textContainer">
                              <p class="MuiTypography-root MuiDropzoneArea-text MuiTypography-h5">Drag and drop a file here or click</p>
                              <svg class="MuiSvgIcon-root MuiDropzoneArea-icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z"></path></svg>
                            </div></div></div> :
                        // <ImageCard list={mediaState.packagingImages} deleteImg={(e) => deleteImg(e, 'packagingImages')} />
                        <ReorderImages images={mediaState.packagingImages} callback={updateImages} deleteImg={(e) => deleteImg(e, 'packagingImages')} />
                    }
                  </span>
                  {error.packagingImages && <p style={{ color: 'red', float: 'right', textAlign: 'end', width: '100%' }}> {error.packagingImages} </p>}
                  <input type="file" accept="image/*" id="hiddenFileproductPack" ref={hiddenFileproductPack} onChange={(e) => handleChangeFileSelect('packagingImages', e.target.files[0], e.target.files[0].name)} style={{ display: 'none' }} />
                </div>
                <div className="col-sm-12" ref={myRef}>
                  <h4 style={{ textAlign: 'left', display: 'inline-block' }}>Product Videos</h4>
                  <h5 style={{float:'right'}}>(YouTube urls are only accepted)</h5>
                  <div className="form-group">
                    <FormInput
                      id="videourl"
                      type="text"
                      name="videoUrl"
                      label="Video Url"
                      register={register}
                      defaultValue={state.videoUrl}
                      value={watchAll.videoUrl}
                      error={errors.videoUrl ? errors.videoUrl : isUtube ? { message: "Please Enter YouTube Share Url", required: true } : ''}
                    //onChange={handleChange('videoUrl')}
                    />
                  </div>
                </div>

                <div className="col-lg-12 ">
                  <h4 style={{ textAlign: 'left' }}>Awards</h4>
                  <div className="form-group mt-3">

                    {/* <BrandCard award={true} addAwards={addAwards} deleteAwards={deleteAwards} awardList={state.awards} name="BMW" img="https://cdn.ces.tech/ces/media/events-experiences-images/innovation-awards/2020/honoree.png" isProductAward={true} /> */}

                    {/* Autocomplete with image */}
                    <div style={{display: 'none'}}><MultiUpload multiple={true} limit={1} acceptedFiles={['image/*']} handleChangeFile={handleChangeFileSelect} handleChangeFileDelete={handleChangeFileDelete} input="awardsImages"/></div>
                    <span onClick={ openAwardMediaLib }>
                      {
                      !state?.awards?.length ?
                      <div className="form-group mt-3">
                      <div tabindex="0" class="MuiDropzoneArea-root">
                      <div class="MuiDropzoneArea-textContainer">
                        <p class="MuiTypography-root MuiDropzoneArea-text MuiTypography-h5">Drag and drop a file here or click</p>
                        <svg class="MuiSvgIcon-root MuiDropzoneArea-icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z"></path></svg>
                        </div></div></div> 
                        :
                        <ImageCard list={state.awards} deleteImg={deleteAwards} />
                        }
                      </span>
                  </div>
                </div>

                <div className="col-lg-12 ">
                  <h4 style={{ textAlign: 'left', display: 'inline-block' }}>Product's Brochures and Technical spec sheets</h4>
                  <h5 style={{ float: 'right' }}>(Maximum 5 Images, Accepts PDF only, File Size Should Not Exceed 5 mb)</h5>
                  <span onClick={() => {
                    if (mediaState?.productBrochures?.length >= 5) {
                      ToastsStore.error('Maximum 5 Images');
                    } else {
                      setProdImage(true);
                    }
                  }}>
                    {
                      !mediaState?.productBrochures?.length ?
                        <div className="form-group mt-3">
                          <div tabindex="0" class="MuiDropzoneArea-root">
                            <div class="MuiDropzoneArea-textContainer">
                              <p class="MuiTypography-root MuiDropzoneArea-text MuiTypography-h5">Drag and drop a file here or click</p>
                              <svg class="MuiSvgIcon-root MuiDropzoneArea-icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z"></path></svg>
                            </div></div></div>
                        :
                        <PdfCard pdfList={mediaState.productBrochures} deletePdf={pdfDelete} editName={editName} />
                    }
                  </span>
                  {error.productBrochure && <p style={{ color: 'red', float: 'right', textAlign: 'end', width: '100%' }}> {error.productBrochure} </p>}
                  {prodImage && <ImageLibraryMultipleUploads id={1} isOpen={prodImage} multiple={true} limit={1} acceptedFiles={['application/pdf']} getBrochuresIds={handleChangeFileSelect} input="productBrochures" aws_folderName="broucher" handleClose={() => setProdImage(false)} documentIds={mediaState?.productBrochures?.length > 0 ? mediaState.productBrochures.map(x => parseInt(x.documentId)) : []} />}
                </div>

                {/* ---------------------------Product's Reviews------------------------------ */}
                <Card>
                  <CardHeader>
                    <h3 style={{ textAlign: 'center' }}>Product Reviews</h3>
                    <p className="text-center">Add any relevant product reviews from magazines, blogs or tech sites</p>
                  </CardHeader>
                  <CardBody>
                    {state.review && state.review.map((x, i) => <div className="row">
                      <div className="col-lg-6 col-md-6 col-xs-12 align-items-center" style={{ padding: "10px" }}>
                        <div className="row">
                          <FormInput
                            id={x.id}
                            label="Publication Name"
                            // name="reviewername"
                            name={`review[${i}].reviewername`}
                            className="prodrev"
                            value={watch[`review[${i}].reviewername`]}
                            onChange={handleChangeReview(i, 'reviewername')}
                            register={register}
                            error={errors?.review ? errors.review[i]?.reviewername : ''}
                            // error={errors?.review?errors?.review[0]?.reviewername:''}
                            defaultValue={x.reviewername}
                          />
                        </div>

                        <div className="row">
                          {/* <div className="col-lg-12"> */}
                          <FormInput
                            // name="articleurl"
                            name={`review[${i}].articleurl`}
                            id={x.id}
                            label="Article URl"
                            className="prodrev"
                            value={watch[`review[${i}].articleurl`]}
                            onChange={handleChangeReview(i, 'articleurl')}
                            register={register}
                            error={errors?.review ? errors.review[i]?.articleurl : null}
                            // error={errors[`review[${i}].articleurl`]?{message:errors[`review[${i}].articleurl`]?.message} : ''}
                            defaultValue={x.articleurl}
                            inputProps={{ maxLength: 255 }}
                          />
                          {/* </div> */}
                        </div>
                      </div>
                      <div id={x.id} className="col-lg-5 col-md-5 col-sm-12 pt-4 res-padr">
                        <span onClick={()=>openProductReview(x)}>
                        <ArrayUploader img={x.documentUrl ? x.documentUrl : 'http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'} handleChange={(e) => handlePreviewChange(e, 1)} image={reviewimage} selectImg={(e) => selectReviewImg(e, x.id, 'ProductReviews')} handleCropClose={handleCropClose} />
                        </span>
                        <p>Accepts Jpeg / Jpg / Png Only</p>
                        <p>(Max size allowed 200 Kb)</p>
                      </div>
                      <div className="col-lg-1 col-md-1 col-sm-1 pt-4 res-padr">
                        {i === 0 && <div onClick={() => addReview('ProductReviews')} >
                          <i class="fas fa-plus-circle faplus1"></i>
                        </div>}
                        {i !== 0 && <div onClick={() => deleteReview(x, i, 'ProductReviews')}>
                          <i class="fas fa-minus-circle faminus"></i>
                        </div>}
                      </div>
                    </div>)}
                  </CardBody>
                </Card>

                <Card>
                  <CardHeader>
                    <h3 style={{ textAlign: 'center' }}>End-User Review</h3>
                    <p className="text-center">Add any strong end-user reviews on various online shops</p>
                  </CardHeader>
                  <CardBody>
                    {state.productexistingrating && state.productexistingrating.map((x, i) => <div className="row">
                      <div className="col-lg-6 col-md-6 col-xs-12 align-items-center" style={{ padding: "10px" }}>
                      <div className="row">
                          <FormInput
                            id={x.id}
                            label="Rating Site Name"
                            className="prodrev"
                            placeholder="Rating Site Name"
                            // name="ratingproofvalidationname"
                            name={`productexistingrating[${i}].ratingproofvalidationname`}
                            value={watchAll[`productexistingrating[${i}].ratingproofvalidationname`]}
                            onChange={handleChangeReview(i, 'ratingproofvalidationname')}
                            register={register}
                            // error={errors.ratingproofvalidationname}
                            error={errors?.productexistingrating ? errors.productexistingrating[i]?.ratingproofvalidationname : ''}
                            defaultValue={x.ratingproofvalidationname}
                          />
                        </div>

                        <div className="row">
                          <FormInput
                            id={x.id}
                            label="End-user Rating Proof / Validation URL"
                            className="prodrev"
                            placeholder="Validation URL"
                            // name="ratingproofvalidationurl"
                            name={`productexistingrating[${i}].ratingproofvalidationurl`}
                            value={watchAll[`productexistingrating[${i}].ratingproofvalidationurl`]}
                            onChange={handleChangeReview(i, 'ratingproofvalidationurl')}
                            register={register}
                            // error={errors.ratingproofvalidationurl}
                            error={errors?.productexistingrating ? errors.productexistingrating[i]?.ratingproofvalidationurl : ''}
                            defaultValue={x.ratingproofvalidationurl}
                          />
                        </div>


                        <div className="mt-3">
                          <Box component="fieldset" mb={3} borderColor="transparent">
                            <Typography component="legend">Rating</Typography>
                            <Rating
                              name={`productexistingrating[${i}].existproductrating`}
                              value={watchAll[`productexistingrating[${i}].existproductrating`]}
                              defaultValue={x?.existproductrating}
                              onChange={handleChangeReview(i, 'existproductrating')}
                              // getLabelText={errors?.productexistingrating? errors.productexistingrating[i]?.existproductrating?.message:''}
                              inputRef={register}
                            />
                            {errors?.productexistingrating ? <div style={{ color: 'red', fontSize: '0.75rem' }}>{errors.productexistingrating[i]?.existproductrating?.message}</div> : ''}
                          </Box>
                        </div>

                      </div>
                      <div id={'logo-' + x.id + i} className="col-lg-5 col-md-5 col-sm-12 pt-4 res-padr">
                        <span onClick={()=>openEndUser(x)}>
                        <ArrayUploader img={x.documentUrl ? x.documentUrl : 'http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'} handleChange={(e) => handlePreviewChange(e, 2)} image={endUserimage} selectImg={(e) => selectReviewImg(e, x.id, 'EnduserReviews')} handleCropClose={handleCropClose} />
                        </span>
                        <p>Accepts Jpeg / Jpg / Png Only</p>
                        <p>(Max size allowed 200 Kb)</p>
                      </div>

                      <div className="col-lg-1 col-md-1 col-sm-1 pt-4 res-padr">
                        <div onClick={() => addReview('EnduserReviews')}>
                          <i class="fas fa-plus-circle faplus1"></i>
                        </div>
                        {i !== 0 && <div onClick={() => deleteReview(x, i, 'EnduserReviews')}>
                          <i class="fas fa-minus-circle faminus"></i>
                        </div>}
                      </div>

                    </div>)}
                  </CardBody>
                </Card>

              </CardBody>
            </Card>

            {/* ---------------------------Product's Shelve------------------------------ */}

            <Card>
              <CardHeader>
                <h3 style={{ textAlign: 'center' }}>Logistics & Import information</h3>
                <p className="text-center">Enter Products certification, product warranty, localization, MOQ, No.of.items</p>
              </CardHeader>
              <CardBody>

                <div className="col-sm-12 ">
                  <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="productCertification" name="productCertification" ref={register} value={state.productCertification} />
                  {!errors.productCertification || (state.productCertification.length > 5 && state?.productCertification?.length < 180) ? <h4>Product Certifications</h4> :
                    <h4>Product Certifications <span style={{ color: '#f44336', float: 'right', fontSize: '0.75rem' }}>{
                      state?.productCertification?.length <= 1 ? 'Product Certification can\'t be empty.' : state?.productCertification?.length <= 5 ? 'Product Certification must have min 5 characters' : state?.productCertification?.length > 180 ? 'Product Certification must have max 180 characters' : ''
                    }</span></h4>}
                  <div className="form-group">
                    <FormTextArea className="buytext22" placeholder="Add any technical certification that your product has received, ex CE, FCC, UL, RoHS etc" value={state.productCertification}
                      onChange={handleChange('productCertification')}
                    />
                  </div>
                </div>

                <div className="col-sm-12 ">
                  <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="productWarranty" name="productWarranty" ref={register} value={state.productWarranty} />
                  {!errors.productWarranty || (state?.productWarranty?.length > 5 && state?.productWarranty?.length < 180) ? <h4>Product Warranty</h4> :
                    <h4>Product Warranty <span style={{ color: '#f44336', float: 'right', fontSize: '0.75rem' }}>{
                      state?.productWarranty?.length <= 1 ? 'Product Warranty can\'t be empty.' : state?.productWarranty?.length <= 5 ? 'Product Warranty must have min 5 characters' : state?.productWarranty?.length > 180 ? 'Product Warranty must have max 180 characters' : ''
                    }</span> </h4>}
                  <div className="form-group">
                    <FormTextArea className="buytext22" placeholder="Enter Warranty Details" onChange={handleChange('productWarranty')} value={state.productWarranty}  />
                  </div>
                </div>

                <div className="col-sm-12 ">
                  <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="productLocalisation" name="productLocalisation" ref={register} value={state.productLocalisation} />
                  {!errors.productLocalisation || (state?.productLocalisation?.length > 5 && state?.productLocalisation?.length < 180) ? <h4>Product Localisation</h4> :
                    <h4>Product Localisation <span style={{ color: '#f44336', float: 'right', fontSize: '0.75rem' }}>{
                      state?.productLocalisation?.length <= 1 ? 'Product Localisation can\'t be empty.' : state?.productLocalisation?.length <= 5 ? 'Product Localisation must have min 5 characters' : state?.productLocalisation?.length > 180 ? 'Product Localisation must have max 180 characters' : ''
                    }</span></h4>}
                  <div className="form-group">
                    <FormTextArea className="buytext22" placeholder="If your product is delivered with an app, can you specify in which languages the app is available?" value={state.productLocalisation} onChange={handleChange('productLocalisation')} />
                  </div>
                </div>


                <Row>
                  <div class="col-lg-11">
                    {/* <h4 style={{textAlign:'left'}}>Price Details</h4> */}
                    {/* <h5>Update your product prices or ordering details</h5> */}
                    <Row>
                      <div className="col-lg-4 pad-les  mt-3" >
                        <Row>
                          <div className='col-lg-6 pad-less'>
                            <div className="form-group">
                              <FormInput
                                id="MOQ *"
                                type="text"
                                name="moq"
                                label="MOQ *"
                                onChange={handleChange('moq')}
                                register={register}
                                defaultValue={state.MOQ}
                                value={watchAll.moq}
                                error={errors.moq}
                              />
                            </div>
                          </div>
                          <div className='col-lg-6 pad-less'>
                            <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="moqUnit" name="moqUnit" ref={register} value={state.moqUnit} />
                            <FormControl variant="outlined" className={classes.formControl1} error={!errors.moqUnit || state.moqUnit ? false : true}>
                              <InputLabel id={!errors.moqUnit || state.moqUnit ? 'demo-simple-select-outlined-label' : 'demo-simple-select-error-label'}>Unit  * </InputLabel>
                              <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                className="test"
                                onChange={handleChange('moqUnit')}
                                label="Moq Unit *"
                                value={state.moqUnit}
                              >
                                <MenuItem value={5}>Pcs</MenuItem>
                                {/* <MenuItem value={2}>Pack</MenuItem>
                <MenuItem value={3}>Gram</MenuItem>
                <MenuItem value={4}>Kgs</MenuItem> */}
                                <MenuItem value={1}>Box</MenuItem>
                              </Select>
                              <FormHelperText style={{ color: 'red' }}>{!errors.moqUnit || state.moqUnit ? '' : errors.moqUnit.message}</FormHelperText>
                            </FormControl>


                          </div>
                        </Row>
                      </div>
                      <div className="col-lg-3 pad-les   mt-3">
                        <div className="form-group">
                          <FormInput
                            id="No of items in Box"
                            type="text"
                            name="no_of_items_in_box"
                            label="No of items in Box"
                            register={register}
                            defaultValue={state.no_of_items_in_box}
                            value={watchAll.no_of_items_in_box}
                          />
                        </div>
                      </div>
                      <div className="col-lg-3 pad-les  mt-3">
                        <div className="form-group">
                          <FormInput
                            id="Max qty for Sample orders"
                            type="text"
                            name="max_qty_sample"
                            label="Max qty for sample orders"
                            register={register}
                            control={control}
                            defaultValue={state.max_qty_for_sample}
                            value={watchAll.max_qty_for_sample}
                            disabled={watchAll.no_limit}
                            error={(!errors.max_qty_sample || !watchAll.no_limit) ? errors.max_qty_sample : ''}
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 pad-less  mt-3">
                        <div className="form-group">
                          <FormCheckBox
                            id="visible"
                            type="checkbox"
                            name="no_limit"
                            label="No limit"
                            checked={state.no_limit}
                            label1={{ textAlign: "left" }}
                            register={register}
                            onClick={() => {
                              state.no_limit = !state.no_limit
                              setState({ ...state })
                            }}
                            value={watchAll.no_limit}
                            defaultValue={state.no_limit}
                          />
                        </div>
                      </div>
                    </Row>
                  </div>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <h3 style={{ textAlign: 'center' }}>Product's Visibility</h3>
                <p className="text-center">Enter more details about product.</p>
              </CardHeader>
              <CardBody>
                <div className="col-lg-12">
                  <h4 style={{ textAlign: 'left' }}>Product's Visibility</h4>
                  <h5>You can set your preferences for product visibility to different user profiles</h5>
                </div>
                <Row>
                  <div className="col-lg-4  ">
                    <div className="form-group">
                      <FormCheckBox
                        id="productVisibility"
                        name="productVisibility"
                        label="Show to Distributor"
                        type="checkbox"
                        checked={state.productVisibility}
                        onClick={handleChange('productVisibility')}
                        value={state.productVisibility}
                      />
                    </div>
                  </div>

                  {(state.productType == 1 || state.productType == 3) && <div className="col-lg-4">
                    <div className="form-group">
                      <FormCheckBox
                        id="productVisibility1"
                        name="productVisibility"
                        label="Show to Retailer"
                        type="checkbox"
                        checked={state.productVisibility1}
                        value={state.productVisibility1}
                        onClick={handleChange('productVisibility1')}
                      />
                    </div>
                  </div>}

                  {(state.productType == 2 || state.productType == 3) && <div className="col-lg-4 ">
                    <div className="form-group">
                      <FormCheckBox
                        id="productVisibility2"
                        name="productVisibility"
                        label="Show to Reseller"
                        type="checkbox"
                        checked={state.productVisibility2}
                        value={state.productVisibility2}
                        onClick={handleChange('productVisibility2')}
                      />

                    </div>
                  </div>}

                </Row>

                {/* <Row>
                  <div className="col-lg-5">
                  <h4 style={{textAlign:'left'}}>Product Positioning</h4>
                  <h5>How do you position this product?</h5>
      </div>
      <div className="col-lg-7 ">
                  <div className="form-group">        
                  <FormRadio
                                name="productPositioning"
                                id="productPositioning"
                                className="text-sm mb-0"
                                label=""
                                options={radioGroupOptions1}
                                control={control}
                                defaultValue={state.productPositioning}
                                value={watchAll.productPositioning}
                                row
                                onChange={handleChange('productPositioning')}
                          />

                      </div>           
                  </div>
                  </Row> */}

                <Row>
                  <div className="col-lg-5">
                    <h4 style={{ textAlign: 'left' }}>Availability</h4>
                    <h5>Tell us the product availability details. You can also add comments to detail the deployment schedule in different regions</h5>
                  </div>
                  <div className="col-lg-7 ">
                    <div className="form-group">
                      <FormRadio
                        name="availability"
                        id="availability"
                        className="text-sm mb-0"
                        label=""
                        options={radioGroupOptions2}
                        control={control}
                        value={state.availability}
                        row
                        onChange={handleChange('availability')}
                      />
                    </div>
                  </div>
                  <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="date" name="date" ref={register} value={selectedDate && selectedDate != 'Invalid Date' ? selectedDate : ''} />
                </Row>
                {
                  state.availability === 'Available From' ?
                    <Row>
                      <div className="col-lg-4 mt-2">
                        <div className="awd-date">
                          <FormControl className={classes.margin}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="MM/dd/yyyy"
                                margin="normal"
                                id="date1"
                                name="date1"
                                defaultValue={selectedDate}
                                ref={register}
                                control={control}
                                value={selectedDate}
                                onChange={handleDateChange}
                                KeyboardButtonProps={{
                                  'aria-label': 'change date',
                                }}
                              />
                            </MuiPickersUtilsProvider>
                            <FormHelperText style={{ color: '#f44336', float: 'right', fontSize: '0.75rem' }} >{errors.date?.message}</FormHelperText>
                          </FormControl>
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="form-group">
                          <form>
                            <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="availableFromText" name="availableFromText" ref={register} value={state.text_about_sample} />
                            {/* {(!errors.availableFromText || state.text_about_sample?.length) ? '' : <span style={{ color: '#f44336', fontSize: '0.75rem', float: 'right' }}>please enter availablility comments</span>} */}
                            <textarea
                              class=" qtytext"
                              id="exampleFormControlTextarea1"
                              onChange={handleChange('text_about_sample')}
                              rows="3"
                              value={state.text_about_sample}
                              default={state.text_about_sample}
                              aria-label="empty textarea"
                              placeholder="Availability Comments">
                            </textarea>
                          </form>

                          {/* <TextareaAutosize  width = "100%" /> */}
                        </div>
                      </div>
                    </Row> : ''
                }
                {/* </CardBody>
          </Card> */}
              </CardBody>
            </Card>

            <Card>
              <CardHeader className="text-center">
                {/* <div onClick={()=>{setshowDrawer(true)}} style={{cursor:"pointer", color: '#32325d'}}><h3> */}
                <h3 style={{ textAlign: 'center' }}>Channel Description</h3>

                {/* </h3><br/> */}
                {/* </div> */}
              </CardHeader>
              <CardBody>
                <p className="text-center" >By default we clone the same information that are entered under your company profile for your current distribution channels and target countries. <span onClick={() => { setshowDrawer(true) }} style={{ textDecoration: 'underline', fontWeight: '600', cursor: "pointer" }}>Click here</span> if you wish to modify these information</p>
                {(!isAdd && isdata) ? '' : <DistributionChannel title="Channel Description" visible={showDrawer} closeDrawer={closeDrawer} isAdd={isAdd} params={params} productDetail={productDetail} productType={state.productType} />}


                <div className="text-center mt-4">
                  <button type="submit" className="btn btn-primary">Save</button>
                </div>
              </CardBody>
            </Card>
          </form>}
      </div>
    </>

  );
};

export default GeneralForm;

const radioGroupOptions3 = [
  {
    value: "1",
    label: "Yes",
  },
  {
    value: "0",
    label: "No",
  },

];

const radioGroupOptions2 = [
  {
    value: "Immediate",
    label: "Immediate",
  },
  {
    value: "Available From",
    label: "Available From",
  },

];

const radioGroupOptions1 = [
  {
    value: "Entry",
    label: "Entry Level",
  },
  {
    value: "Mid",
    label: "Mainstream ",
  },
  {
    value: "High",
    label: "Premium",
  },
];

const businessTypeOptions = [
  {
    value: "1",
    label: "B2C",
  },
  {
    value: "2",
    label: "B2B",
  },
  {
    value: "3",
    label: "Both",
  }
];
