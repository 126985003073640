
export const LocalStorageService = (function(){
    var _service;

    function _getService() {
        if(!_service) {
          _service = this;
          return _service
      }
      return _service
    }

    function _setToken(token) {
      localStorage.setItem('fo-auth-token', token);      
    }
    function _setAppToken(token) {
        localStorage.setItem('fo-app-token', token);        
    }
    function _getAccessToken() {
      return localStorage.getItem('fo-auth-token');
    }
    function _getAppToken() {
        return localStorage.getItem('fo-app-token');
    }
    function _getRefreshToken() {
      return localStorage.getItem('refresh_token');
    }
    function _clearToken() {
      let appToken=localStorage.getItem('fo-app-token');  
      let productInfo = JSON.parse(localStorage.getItem("startAddProd"));
      let productImages = JSON.parse(localStorage.getItem("uploadedImages"));
      let toShowImages = JSON.parse(localStorage.getItem("toShow"));   
      localStorage.clear();
      localStorage.setItem("startAddProd", JSON.stringify(productInfo));
      localStorage.setItem("uploadedImages", JSON.stringify(productImages));
      localStorage.setItem("toShow", JSON.stringify(toShowImages));
      _setAppToken(appToken);
    }
    
   return {
      getService : _getService,
      setToken : _setToken,
      getAccessToken : _getAccessToken,
      setAppToken : _setAppToken,
      getAppToken : _getAppToken,
      getRefreshToken : _getRefreshToken,
      clearToken : _clearToken
    }
})();
   