import React from "react";
import { Button,
Card,
CardHeader,
CardBody,
CardFooter,
CardImg,
CardImgOverlay,
CardTitle,
CardText,
ListGroupItem,
ListGroup,
Row,
Col } from "reactstrap";
import GuestFooter from "./GuestFooter";
import GuestNavBar from "./GuestNavbar";
import "../../../../assets/scss/custom/guestpage.scss"
import { Container } from "@material-ui/core";
import axios from 'axios';
import {history} from "_helpers/history";
import Title from "views/Hoc/Dialog/Title";
const GuestAbout = (props) => {

let path = props.location.pathname;

return (
<>
<GuestNavBar/>

{/* <div id='guest'>
  

<div className="gabout">

<Row className="slide1">
<Col lg="6" className="mt-6 text-center"></Col>
<Col lg="6" className="gabout2 pad-less">
<div className="gabout1">
<div className="gabout3">
<h1 className="text-uppercase text-center ">ABOUT</h1>
<p className="text-center mt-2"><strong>ChannelHub is the result of 20 years of experience</strong> </p>
<p className="text-center mt-2"> <strong>organizing International Trade Shows for the CE industry..</strong> </p>
<div className="text-center">
<button className="btn btn-primary" size="lg"  onClick={() => history.push('/Scheduler')}>Schedule a demo</button></div>
</div>
</div>
</Col>
</Row>
</div>
<Container>
<Card className='mt-3'> 
<Row>
<Col lg="2"></Col> 
  
<Col className="col-lg-8 justify-content-center mt-3 ">
<div className="text-center">
<h1 className="text-center ">Our Mission</h1>
<p>ChannelHub’s mission is to <strong>digitally connect Consumer Technology vendors and retailers to allow a faster and more productive way to bring innovative products to the market.</strong></p>
<p><span class="ql-size-small">ChannelHub enables vendors, distributors and retailers from all over the world to build trusted relationships in a fast and easy way, leveraging cutting edge technology with human expertise.</span></p>
</div>

</Col>

         <Col lg="2"></Col>    
        </Row>
            </Card>
            </Container>
            <Container>
         <Card className="">
         <Col lg="12">
         
         </Col>    
         </Card>
        
        
        <Card className="p-5">
        <div className="text-center p-1">
         <h1 >The Story Behind success</h1>
         </div>
        <Row>
      
        <Col lg="6" className="p-0">
     
    <CardBody>
        <p>  The story behind ChannelHub started 2 decades ago, when I was organizing trade events for the Consumer Tech channel across the globe. I’ve witnessed the headaches of vendors trying to find suitable international channel partners to grow their business. And, at the other end, seen countless retailers and distributors trying to find future “Shelve stars” by navigating amongst over abundant offers, me-too products, unchecked vendors, etc.</p>
        
        <p>When I had a chance to step back a few months ago, I realized the huge paradox in our industry: we breathe, eat and live in high-tech; but the way we sell or buy these products has not changed for more than 50 years. There is a widening gap between the speed of change in the product lifecycle and the way the channel business works and people make decisions.</p>
        <p>The digital transformation is affecting all industries: from automotive to tourism, from banking to food, from hospitality to entertainment… changing the way people buy and sell products and services and improving their productivity with digital solutions.</p>
        </CardBody>
           </Col>



        <Col lg="6">
        
    <CardBody>
        <p>Fact:&nbsp;apart from crowdfunding and Amazon there hasn’t been a lot of changes in our industry. It still takes months or even years for vendors to find the right channel partners, to signup a distribution deal or a listing deal with a retailer. And months for retailers to qualify a new product or supplier. All this because we work in an international environment where the risk factor is very high and the information is not easy to get. All this leads to endless due diligence process and longer time-to-market.</p>
        <p>Our industry is based on innovation. This innovation needs to travel at the speed of light … or at least at the speed of the internet, to reach the end customer anywhere on the globe. Retailers need to bring customers back to their stores with innovative products, vendors need to address a global market with maximum speed.</p>
        <p >ChannelHub is here to fill this gap. We have designed the platform to remove all the pain points that make your business life sometime so frustrating. Get all the necessary information at your fingertips and make sound decisions based on real facts!</p>
        <p><strong>Farouk Hemraj</strong></p>    
        </CardBody>
     </Col>

            </Row>
            </Card>
            </Container>
           
                <Container>
                 <Row>
                 <Col lg="12" className="p-0" >
                <Card >
           
                <div className="text-center p-5 ">
                <h1>Meet the team</h1>
                </div>
                </Card>
                </Col>    
               
                
                <Col lg="6" className="p-0">
                <Card>
                <Row className="justify-content-center">
                 <Col className="order-lg-2" lg="3">
              <div className="card-profile-image">
                <a href="#pablo" onClick={e => e.preventDefault()}>
                  <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}} 
                    alt="..."
                    className="rounded-circle"
                    src={require("assets/img/guestpage/CH-alain.jpg")}
                  />
                </a>
              </div>
            </Col>
          </Row>
          <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
            <div className="d-flex justify-content-between">
        
            </div>
          </CardHeader>
            
            <div className="text-center pt-4">
              <h5 className="h3">
              Alain Godet
              </h5>
              <div className="h5 font-weight-300">
                <i className="ni location_pin mr-2" />
                VP Customer Success
                <span className="font-weight-light"></span>
                <p className=" text-left p-4">Alain Godet is the founder of ITdistri, the largest IT and CE channel directory, operating since 2004, now a subsidiary of ChannelHub.
                    An experienced IT channel journalist and consultant specialized in distribution, Alain has more than two decades of experience.
                    Alain is an authority on technology routes-to-market. With a broad and deep knowledge of the EMEA channel landscape, his expertise spans both volume and value distribution.
                    Alain also has excellent links to channel press and magazines across the region.</p>
                </div>
                </div>
       
               </Card>
                </Col>

                <Col lg="6" className="p-0">
                <Card>
          <Row className="justify-content-center">
            <Col className="order-lg-2" lg="3">
              <div className="card-profile-image">
                <a href="#pablo" onClick={e => e.preventDefault()}>
                  <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}} 
                    alt="..."
                    className="rounded-circle"
                    src={require("assets/img/guestpage/CH-farouk.jpg")}
                  />
                </a>
              </div>
            </Col>
          </Row>
          <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
            <div className="d-flex justify-content-between">
             
            </div>
          </CardHeader>
            
            <div className="text-center pt-4">
              <h5 className="h3">
              Farouk Hemraj
                <span className="font-weight-light"></span>
              </h5>
              <div className="h5 font-weight-300">
                <i className="ni location_pin mr-2" />
                Managing director
                <span className="font-weight-light"></span>
               <p className=" text-left p-4">Farouk Hemraj is a serial entrepreneur with a background in international technology channels.
                Farouk was a co-founder of DISTREE Events, an organizer of international executive-level tech channel meetings, sold to InfoPro Digital in 2013.
                With a wealth of global channel experience and an extensive contact network spanning vendors, distributors and retailers, Farouk’s current business interests focus on accelerating channel development and delivering greater go-to-market efficiencies for tech brands.</p>
              </div>
            </div>
       
               </Card>   
                </Col>
                </Row>
              
                </Container>
              
        

</div> */}

<Title title="About Us" desc={["ChannelHub is the result of 20 years of experience organizing International Trade Shows for the CE industry"]} />

<section className="about-wrapper">
            <div className="container" style={{padding:'0'}}>
                <div className="row">
                    <div className="sub-about">
<section className="mission">
    <h3>Our Mission
</h3>
    <p>ChannelHub’s mission is to digitally connect Consumer Technology vendors and retailers to allow a faster and more productive way to bring innovative products to the market.

ChannelHub enables vendors, distributors, retailers and resellers from all over the world to build trusted relationships in a fast and easy way, leveraging cutting edge technology with human expertise.

</p>
</section>
<section className="activity">
    <h3>Our activities
</h3>
    <p>ChannelHub offers a full range of activities to help the tech channel community to grow their interactions and partnership.  

</p>
<Row>
    <Col  lg='6' className="pl-0">
<div className="activity-card" style={{backgroundColor:'#49a6c3'}}>
    <Row>
        <Col lg='6' md='8' sm='8'>
    <aside className="activity-left" >
        <h5>Online  Platform</h5>
        <h3>Channelhub.net</h3>
        <p>ChannelHub platform is the backbone of all our actvitiies. Offering extensive company profiles, product suggestions and matching solutions for both vendors and buyers</p>
    </aside>
    </Col>
    <Col lg='6' md='4' sm='4'>
        <figure className="activity-right">
        <img src="images/online_platform.jpg" alt="online_platform" title="online_platform" />

        </figure>
        </Col>
        </Row>
        <a href="https://www.channelhub.net/" target="_blank" title="Channelhub">Read More  </a>

</div>
</Col>
<Col lg='6 ' className="pr-0">
<div className="activity-card" style={{backgroundColor:'#03055c'}}>
    <Row>
        <Col lg='6' md='8' sm='8'>
    <aside className="activity-left" >
        <h5>In-person summits</h5>
        <h3>Channel Summit EMEA
Channel Summit MENA</h3>
        <p>Channel Summits are stand-alone events organised in 5-star hotels and offering 3 days of one-to-one meetings, conferences and networking in the most friendly and professional environment

</p>
    </aside>
    </Col>
    <Col lg='6' md='4' sm='4'>

        <figure className="activity-right">
        <img src="images/52899241637_98fc0c1c92_h.jpg" alt="52899241637_98fc0c1c92_h" title='52899241637_98fc0c1c92_h'  />
        </figure>
        </Col>
        </Row>
<a href="https://www.channel-summit.com/" target="_blank" title="channel-summit">Read More  </a>

</div>
</Col>
<Col lg='6' className="pl-0" style={{display:"none"}}>
<div className="activity-card" style={{backgroundColor:'#012d8e'}}>
    <Row>
    <Col lg='6' md='8' sm='8'>
    <aside className="activity-left">
        <h5>Side events</h5>
        <h3>Global Retail Connect</h3>
        <p>Global Retail Connect are held in conjunction with major industry trade shows in Berlin and Las vegas. They offer a condensed version of our summits etiher during the  evening or at breakfast times

</p>
    </aside>
    </Col>
    <Col lg='6' md='4' sm='4'>
        <figure className="activity-right">
        <img src="images/Global_Retail_connect.jpg" alt="Global_Retail_connect" title='Global_Retail_connect' />
        </figure>
        </Col>
        </Row>
        <a href="https://www.globalretailconnect.com/" target="_blank" title="Global Retail Connect">Read More  </a>

</div>
</Col>
<Col lg='6' className="pr-0" style={{display:"none"}}>
<div className="activity-card" style={{backgroundColor:'#0286ea'}}>
    <Row>
        <Col lg='6' md='8' sm='8'>
    <aside className="activity-left">
        <h5>Online Events</h5>
        <h3>Buyer Insider Session</h3>
        <p>Buyer Insider Session is a monthly programme where we twist the traditional roles and put channel buyers front and center to tell an audience of vendors how to do business with their company.

</p>
    </aside>
    </Col>
    <Col lg='6' md='4' sm='4'>
        <figure className="activity-right">
        <img src="images/Sebastian.jpg" alt="Sebastian" title='Sebastian' />
        </figure>
        </Col>
        </Row>
<a href="https://www.buyer-insider.com/" title="Buyer Insider" target="_blank" >Read More  </a>

</div>
</Col>
</Row>
</section>
<section className="founders">
    <h2>Meet The Founders</h2>
    <Row>
        <Col md='4'>
    <div className="founder-card">
        <figure>
            <img src="images/Farouk Hemraj official picture_edited.png" alt="Farouk Hemraj" title="Farouk Hemraj" />
        </figure>
        <h4>Farouk Hemraj</h4>
        <h5>Founder & CEO</h5>
        <a href="http://linkedin.com/in/faroukhemraj"  target='_blank'>
        <img src="images/linkedin.png" alt="linkedin" title="linkedin" />
            </a>
            <p>
            Farouk Hemraj is a serial entrepreneur with a background in international technology channels</p>
            <p>Farouk was the co-founders of DISTREE Events, an organizer of international executive-level tech channel meetings</p>
            <p>With a wealth of global channel experience and an extensive contact network spanning vendors, distributors and retailers, 
            </p>
    </div>
    </Col>
    <Col md='4'>

    <div className="founder-card">
    <figure>
            <img src="images/alain_edited.png" alt="Alain Godet" title="Alain Godet" />
        </figure>
        <h4>Alain Godet</h4>
        <h5>VP Customer success
</h5>
        <a href="http://linkedin.com/in/algodet"  target='_blank'>
        <img src="images/linkedin.png" alt="linkedin" title="linkedin" />
            </a>
            <p>
            Alain Godet is the founder of ITDistri.</p>
            <p>An experienced IT channel journalist and consultant specialized in distribution, Alain has more than two decades of experience.</p>
            <p>Alain is an authority on technology routes-to-market. With a broad and deep knowledge of the EMEA channel landscape, his expertise spans both volume and value distribution.
           </p>
    </div>
    </Col>
    <Col md='4'>

    <div className="founder-card">
        <figure>
        <img src="images/shuzha_edited.png" alt="Shuza Noirot" title="Shuza Noirot" />
        </figure>
        <h4>Shuza Noirot</h4>
        <h5>Sales Director</h5>
        <a href="http://linkedin.com/in/faroukhemraj"  target='_blank'>
        <img src="images/linkedin.png" alt="linkedin" title="linkedin" />

            </a>
            <p>
            Experienced Entrepreneur with a demonstrated history of working in the event industry both in IT & CE as well as Beauty and cosmetics</p>
            <p>Skilled in Negotiation, Marketing Management, Business Planning, Retail, and Sales.</p>
            <p>Strong business development professional with a MBA focused in Global Strategy from Stockholm University. 
            </p>
    </div>
    </Col>
    </Row>
</section>
                    </div>

                </div>
            </div>
        </section>

<GuestFooter/>

</>
);
}

export default GuestAbout;