import React ,{useState} from 'react';
import { Button, Modal } from "reactstrap";


export  const AccountDeletion=(props)=> {
const { Open,closeOpen}= props;
  
  const closeOpens=(e)=>{
    e.preventDefault();
    closeOpen(false);
}


  return (
           <div>
          <Modal className="modal-dialog-centered" contentClassName=""    
          isOpen={Open} size="md">
          
          <div className="modal-header chlconnect">
      
           <h1>Account Deletion</h1>
            <button aria-label="Close" className="close" data-dismiss="modal" type="button"           onClick={e=>closeOpens(e)}>
              <span aria-hidden={true}>×</span>
            </button>
          </div>
                <div className="modal-body text-left mt-4">
         
                <h1 style={{fontSize: '20px'}} className="modal-title text-center text-danger" id="modal-title-notification">WARNING</h1>
                <div className="justify-content mt-2 ">
          <div className=" mt-3">
        
          <h3><strong>Delete your account is permanent. </strong></h3>

          </div>

          <div className="mt-3 text-left ">
        
        <h3>When you delete your ChannelHub account, you won't be able to retrieve the content or information that you've shared on ChannelHub. Your company profile and all your users will also be deleted.</h3>
        </div>


        
        <div className="mt-2 text-left ">
        
        <h3>Do you want to continue?</h3>
        </div>
              
           
     
            </div>
              <div className="modal-footer ">
                  <div className="text-center">
                <Button color="secondary" data-dismiss="modal" type="button" onClick={e=>closeOpens(e)}>YES</Button>
                <Button className='btn btn-primary' data-dismiss="modal" type="button" onClick={e=>closeOpens(e)}>NO</Button>
                </div>
              </div>     
              </div>
        </Modal>
    </div>
   
  );
}
export  default AccountDeletion;