import React, { useState, useEffect, useRef } from "react";
import {
  Card, CardBody, CardHeader, Container, Col, Row, Modal, Button, UncontrolledTooltip
} from "reactstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { QuillEditor, FormInput, FormAutocomplete, SingleAutocompleteGrouping, FormTextArea, FormCheckBox, FormAutocompleteGrouping, AddFieldWithPopUpAutocomplete, NewFieldAutocomplete, FormRadio, } from "_components/FormElements/FormInput";
import MediaLibrary from "views/Hoc/Dialog/MediaLibrary";
import { BrandCard, ProfileCard } from "views/Hoc/BrandCard";
import { PdfCard, ImageCard } from "views/Hoc/PdfCard";
import { history } from '../../../_helpers';
import { dashboardActions } from '../../../_actions';
import { companyProfileSchema } from './validation';
import { InputLabel, Select, TextField, FormControl, InputAdornment } from '@material-ui/core';
import AddBrand from "views/Hoc/Dialog/AddBrand";
import { useBeforeFirstRender } from '_hooks/index'
import axios from 'axios';
import ImageLibrary from 'views/Hoc/Dialog/ImageLibrary';
import { ModalSpinner, Spinner } from 'views/Hoc/Spinner';
import MultiUpload from "views/Hoc/MultiUpload";
import { commonService } from '_services/common.service';
import { ImageLibraryMultipleUploads } from 'views/Hoc/Dialog/ImageLibraryMultipleUploads';
import { MultiSelectImages } from 'views/Hoc/Dialog/MultiSelectImages';
import VideoPreview from 'views/Hoc/Dialog/VideoPreview';
import { BannerUploader } from 'views/Hoc/ProfileUpload';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { DocumentName } from 'views/Hoc/Dialog/DocumentName';
import AddKeyPartner from "views/Hoc/Dialog/AddKeyPartner";
import { Dialog } from "views/Hoc/Dialog/GlobalDialog";
import bannerPic from '../../../assets/img/channelDetail/Default Banner.png';


var _ = require('lodash');


const DashboardEdit = (props) => {
  const { Open, CloseEdit, id, confirmSave } = props;
  const [prodImage, setProdImage] = useState(false);
  const [previlege, setPrevilege] = useState(null);
  let [comImage, setComImage] = useState(false);
  let [awardImage, setAwardImage] = useState(false);
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, control, errors, watch, setValue, formState } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(companyProfileSchema),
  });
  const [isAnnualTurn, setAnnualTurn] = useState(false);

  const [brandName, setBrandName] = useState('');
  const [openadd, openAddnew] = useState(false);
  const [openDist, setOpenDist] = useState(false);
  const [openRetailer, setOpenRetailer] = useState(false);

  const [initLoad, setInitLoad] = useState(true);
  const [initialVideo, setInitialVideo] = useState('');
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState('');
  const [imageSrc, setImageSrc] = useState('');
  const [bannerId, setBannerId] = useState('');
  let [namePopup, setNamePopup] = useState(false);
  let [docName, setDocName] = useState(null);
  let [docId, setDocId] = useState(null);

  var userData = JSON.parse(localStorage.getItem('CHuser'));
  var typeId = userData?.channelTypeId;
  var businessType = userData?.businessType;

  const [state, setState] = useState({
    yearOfEstablishment: '',
    companyRegNumber: '',
    companyLogo: null,
    userLogo: null,
    userLogoId: '',
    tweetPitch: '',
    introVideo: null,
    documentId: '',
    sourcing: '',
    companyDesc: "",
    awards: [],
    savedAwards: [],
    companyName: '',
    legalAddress: '',
    country: '',
    countryList: [],
    zipCode: '',
    city: '',
    phoneNumber1: '',
    phoneNumber2: '',
    mobile: '',
    email: '',
    websiteUrl: '',
    linkedIn: '',
    linkedinurl: '',
    insta: '',
    facebook: '',
    twitter: '',
    tradeUrls: '',
    cerificateName: '',
    isVat: '',
    regAddressId: '',
    billAddressId: '',
    b2bList: [{ id: Math.random(), value: '' }],
    deleted_B2B: [],
    certificateId: '',
    vatId: '',
    vatNumberCheck: '',
    vatNumber: '',
    vat_verified: 0,
    social_linkedin_id: null,
    social_instagram_id: null,
    social_twitter_id: null,
    social_facebook_id: null,
    deletedAwards: [],
    deletedDocuments: [],
    brand: [],
    BrandData: [],
    brandList: [],
    brandOption: [],
    brandListUser: [], //dont worry about it. it's dummy but dont remove it.,
    productValue: [{ childid: "4", childname: "AV accessories", groupid: "1", groupname: "AV", status: true }],
    productFamilies: [],
    no_of_skus: null,
    storeCount: null,
    domesticRevenue: null,
    internationalRev: null,
    no_of_staffs: '',
    annualTurnOver: '',
    annualTurnOverList: [],
    deleted_companyImages: [],

    areYouStartUp: '0',
    startUpType: '',

    DistributorProfileOption: [],
    DistributorProfileValue: [],

    keyTags: [],
    deletedTags: [],

    linkedIn_pre: 'https://www.linkedin.com/company/',
    insta_pre: 'https://www.instagram.com/',
    facebook_pre: 'https://www.facebook.com/',
    twitter_pre: 'https://twitter.com/',

    speciality: [],
    specialityOptions: [],

    ChannelProfileValue: [{ childid: "3", childname: "broadliner", status: true }],
    ChannelProfileOption: [],
    listOfServicesOption: [],
    listOfServiceValue: [],

    isOEM: '3',

    profile: '',
    areYouStartUp: '0',
    no_of_staffs: '',
    startUpType: '',
    sales: null,
    marketing: null,
    technical: null,
    purchase: null,
    domesticRevenue: null,
    internationalRev: null,
    frenchTech: 'No',
    annualTurnOver: '',
    productBrand: [],
    brand: [],
    brandList: [],
    brandOption: [],
    productGroup: [],
    productValue: [{ childid: "4", childname: "AV accessories", groupid: "1", groupname: "AV", status: true }],
    productFamilies: [],
    DistributorProfileOption: [],
    DistributorProfileValue: [],
    Distributor: [],
    DistributorList: [],
    RetailerProfileOption: [],
    Retailer: [],
    RetailerList: [],
    ChannelProfileValue: [{ childid: "3", childname: "broadliner", status: true }],
    ChannelProfileOption: [],
    listOfServicesOption: [],
    listOfServiceValue: [],
    groupCount: 0,
    no_of_skus: null,
    storeCount: null,
    no_of_distributors: null,
    no_of_retailers: null,
    distributorDesc: '',
    RetailerDesc: '',
    startUpType1: false,
    startUpType2: false,
    startUpType3: false,
    profileTypeId: '',
    KeyRetData: [],
    RetailerOptions: [],
    KeyDistData: [],
    DistributorOptions: [],
    testRetailerList: [],
    testDistributorList: [],
    deletedRetailer: [],
    deletedDistributor: [],
    speciality: [],
    specialityOptions: [],
    countryList: [],

    brandListUser: [], //dont worry about it. it's dummy but dont remove it.
    RetailerListUser: [],
    DistributorListUser: [],


    //keyreseller popup
    no_of_resellers: null,
    ResellerDesc: '',
    ResellerProfileValue: [],
    ResellerProfileOption: [],

    Reseller: [],
    ResellerList: [],
    KeyResData: [],
    ResellerOptions: [],
    testResellerList: [],
    deletedReseller: [],
    ResellerListUser: [],

    sellingCountries: [],
    sellingCountriesOption: [],
    targetCountries: [],
    targetCountriesOption: [],
    locationType: (typeId == 2 || typeId == 3) ? 'Target Locations' : 'Selling Locations',
    title: (typeId == 2 || typeId == 3) ? 'TARGET' : 'SELLING',
    sellingZones: [],
    EMEA: false,
    APAC: false,
    AMERICAS: false,
    EMEA1: false,
    APAC1: false,
    AMERICAS1: false,
    isCompanyPublic: '1'
  })



  let [isModalPopup, setModalPopup] = useState(false);
  let [modalPopValue, setModalPopValue] = useState({ message: null, title: null, value: null, popUpId: null, second_message: null, cid: null, JctId: null });
  //Multiupload
  const [mediaState, setMediaState] = useState({
    productBrochures: [],
    bannerImages: [],
    companyImages: []
  });

  //console.log("comstate:",state);

  const watchAll = watch(['companyName', 'CRN', 'VATN', 'websiteUrl', 'city', 'insta', 'LinkedinURL', 'linkedIn', 'zipCode', 'Yearestablishment', 'phoneNumber1', 'phoneNumber2', 'mobile', 'companyemail', 'tradeUrls', 'no_of_skus', 'storeCount', 'facebook', 'twitter', 'turnOver', 'staffCnt']);//'tradeUrls',

  //localStorage
  var userData = JSON.parse(localStorage.getItem('CHuser'));

  //for vat check
  let [vatCheck, setVatCheck] = useState(0);
  const myRef = useRef(null);

  useEffect(() => {
    getCompanyData();
    //getAccessPrevilege();
    getTradeInfo();
  }, [])

  let maturitylevel = false;

  const getTradeInfo = async () => {
    setLoading(true);
    // const countryUrl = '/common/getSignup/' + userData.channelTypeId;
    // await axios.get(countryUrl).then(res => {
    //   //console.log("profiledata:",res.data);          
    //   state.countryList = res.data.country;
    //   setState({ ...state });
    // });

    // await axios.get('setting/getInitialProfile').then(res => {
    //   state.productFamilies = res.data.productGroup;

    //   let key = ['Retailer'];
    //   state.ChannelProfileOption = res.data.profileList.filter(i => key.includes(i.groupname));

    //   let key1 = ['Reseller'];
    //   state.ResellerProfileOption = res.data.profileList.filter(i => key1.includes(i.groupname));

    //   setState({ ...state, ChannelProfileOption: state.ChannelProfileOption });
    //   setState({ ...state, ResellerProfileOption: state.ResellerProfileOption });

    // }).catch(err => {
    //   //ToastsStore.error('Company Not Found!')
    //   commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: 'setting/getInitialProfile' }, '/error')
    //   setLoading(false);
    // });

    const apiUrl = '/setting/tradeinfo';
    // await axios.get(apiUrl).then(res => {
    //   //console.log("profiledata:",res.data);          
    //   //state.countryList=res.data.country;
    //   state.isCompanyPublic = res.data.isCompanyPublic ? res.data.isCompanyPublic : '0';
    //   if (maturitylevel && res.data.maturitylevel) {
    //     res.data.maturitylevel.map(x => {
    //       if (x == 'PROTOTYPE') state.startUpType1 = true;
    //       if (x == 'S_BACKER') state.startUpType2 = true;
    //       if (x == 'S_VOLUME') state.startUpType3 = true;
    //       state.startUpType = 'set';
    //     })
    //   } else {
    //     if (res.data.maturitylevel)
    //       state.startUpType = res.data.maturitylevel[0];
    //   }

    //   state.DistributorOptions = res.data.keyPartners.keyDistributorOptions;
    //   state.RetailerOptions = res.data.keyPartners.keyRetailerOptions;
    //   state.DistributorList = res.data.channelKeyDistributor;
    //   state.RetailerList = res.data.channelKeyRetailer;
    //   state.listOfServicesOption = res.data.serviceListOptions;
    //   state.listOfServiceValue = res.data.serviceListValues;
    //   state.testRetailerList = res.data.channelKeyRetailer.map(x => x.id);
    //   state.testDistributorList = res.data.channelKeyDistributor.map(x => x.id);
    //   state.specialityOptions = res.data.specialityOptions.map(x => ({ id: x.productGroupId, name: x.productGroup }));
    //   state.speciality = res.data.channelSpeciality.map(x => ({ id: x.productGroup.id, name: x.productGroup.name }));

    //   if (res.data.profileType.length) {
    //     state.DistributorProfileValue = Object.assign(res.data.profileType[0], { firstLetter: res.data.profileType[0].groupname });
    //     state.DistributorProfileOption = state.DistributorProfileOption.map(x => {
    //       if (x.childid == state.DistributorProfileValue.childid)
    //         return { ...x, status: true }
    //       else
    //         return x;
    //     })
    //   }

    //   if (res.data.product.length && state.productFamilies.length) {
    //     state.productFamilies = state.productFamilies.map(x => {
    //       res.data.product.map(y => {
    //         if (x.groupid == y.groupid && x.childid == y.childid) {
    //           x.status = true;
    //         }
    //       })
    //       return x;
    //     })
    //   }

    //   //brandList:res.data.brand,productValue:res.data.product,storeCount:res.data.storeCount,no_of_skus:res.data.no_of_skus,no_of_staffs:res.data.empCnt.replace(/\s/g, "").trim(),annualTurnOver:res.data.turnoverRange.replace(/\s/g, "").trim(),
    //   //sales: res.data.sales?+res.data.sales:'', marketing: res.data.marketing?+res.data.marketing:'', technical: res.data.technical?+res.data.technical:'',
    //   //purchase: res.data.purchase?+res.data.purchase:'',domesticRevenue:typeId=='4'?res.data.turnoverOnStore:res.data.revD,internationalRev:typeId=='4'?res.data.onlineStore:res.data.revI,
    //   setState({
    //     ...state, ChannelProfileValue: (userData.channelTypeId == '4' || userData.channelTypeId == '5') ? res.data.profile[0] : res.data.profile,
    //     areYouStartUp: res.data.startup,
    //     no_of_distributors: res.data.no_of_distributors,
    //     no_of_retailers: res.data.no_of_retailers, startUpType1: state.startUpType1, startUpType2: state.startUpType2, startUpType3: state.startUpType3, startUpType: state.startUpType,
    //     profileTypeId: +res.data.profileTypeId, distributorDesc: res.data.keydistributors, RetailerDesc: res.data.keyretailers,
    //     frenchTech: res.data.isFrenchTech == '1' ? 'Yes' : 'No', productFamilies: state.productFamilies,
    //   });
    //   console.log('tradestate', state);
    //   // getTradeLoc();
    //   // setLoading(false);


    // }).catch(err => {
    //   //ToastsStore.error('Company Not Found!')
    //   //setLoading(false);
    //   commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: '/setting/tradeinfo' }, '/error')
    // });

    await axios.get('/setting/getChannelDetail').then(res => {
      state.no_of_staffs = res.data.empCnt ? res.data.empCnt : res.data.empCntRange.replace(/\s/g, "").trim();
      state.annualTurnOver = res.data.turnover ? res.data.turnover : res.data.turnoverRange.replace(/\s/g, "").trim();
      state.no_of_retailers = res.data.no_of_retailers;
      state.no_of_resellers = res.data.no_of_resellers;
      state.no_of_distributors = res.data.no_of_distributors;
      state.distributorDesc = res.data.keydistributors;
      state.RetailerDesc = res.data.keyretailers;
      state.ResellerDesc = res.data.keyresellers;
    }).catch(err => {
      commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: '/setting/getChannelProduct' }, '/error')
      //setLoading(false);
    });


    await axios.get('/setting/keyPartnerOptions').then(res => {
      // state.RetailerList = res.data.channelKeyRetailer;
      // state.ResellerList = res.data.channelKeyReseller;
      // state.DistributorList = res.data.channelKeyDistributor;

      // state.KeyDistData = res.data.keyPartners?.keyDistributorOptions;
      state.DistributorOptions = res.data.keyPartners?.keyDistributorOptions;
      //state.KeyRetData = res.data.keyPartners?.keyRetailerOptions;
      state.RetailerOptions = res.data.keyPartners?.keyRetailerOptions;
      //state.KeyResData = res.data.keyPartners?.keyResellerOptions;
      state.ResellerOptions = res.data.keyPartners?.keyResellerOptions;
      // state.testRetailerList = res.data.channelKeyRetailer.map(x => x.id);
      // state.testResellerList = res.data.channelKeyReseller.map(x => x.id);
      // state.testDistributorList = res.data.channelKeyDistributor.map(x => x.id);

      // state.DistributorOptions = state.KeyDistData;
      // state.DistributorList.map(x => {
      //   state.DistributorOptions = state.DistributorOptions.filter(y => y.id != x.id);
      // })

      // state.RetailerOptions = state.KeyRetData;
      // state.RetailerList.map(x => {
      //   state.RetailerOptions = state.RetailerOptions.filter(y => y.id != x.id);
      // })

      // state.ResellerOptions = state.KeyResData;
      // state.ResellerList.map(x => {
      //   state.ResellerOptions = state.ResellerOptions.filter(y => y.id != x.id);
      // })


      setState({ ...state });
    });

    // await axios.get('/setting/getChannelProfile').then(res => {

    //   let reseller = res.data.profile?.filter(x => x.groupname == 'Reseller');
    //   let retailer = res.data.profile?.filter(x => x.groupname == 'Retailer');

    //   state.ChannelProfileValue = retailer;
    //   state.ResellerProfileValue = reseller;
    //   setState({
    //     ...state, ChannelProfileValue: state.ChannelProfileValue,
    //     ResellerProfileValue: state.ResellerProfileValue,
    //     profileTypeId: +res.data.profileTypeId,
    //   });

    // }).catch(err => {
    //   commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: '/common/getSignup/' }, '/error')
    //   setLoading(false);
    // })

    // if (props.ratingId) {
    //   await getCompanyDataOld();
    // }

    setLoading(false);
  }

  //   const setKeyOptions = (input, keyname) => {
  //     let temp = [];
  //     let tempoptions = state[keyname];
  //     state[input + 'List'].map(x => {
  //       tempoptions = tempoptions.filter(y => y.id != x.id);
  //     })
  //     console.log('deleting brand', state[input + 'Options']);
  //     setState({ ...state, [input + 'Options']: tempoptions });
  //   }

  const getAccessPrevilege = () => {
    (async () => {
      await axios.get('user/userActionPrevilege/' + 2).then(res => {
        setPrevilege(res.data.length > 0 ? res.data[0] : 0);
      }).catch(err => {
        ToastsStore.error('No Access Found!');
      });
    })();
  }

  const getCompanyDataOld = async () => {
    const apiUrl = '/setting/getCompanyDataOld';

    await axios.get(apiUrl).then(res => {
      console.log('response', res.data);
      if (res.data) {
        state.brandList = res.data.brand ? res.data.brand : state.brandList;
        state.brandList.map(x => {
          state.brandOption = state.brandOption.filter(y => y.id != x.id);
        })

        state.companyLogo = res.data.channelLogo ? res.data.channelLogo : state.companyLogo;
        state.documentId = res.data.documentId ? res.data.documentId : state.documentId;
        state.introVideo = res.data.introvideoId ? res.data.introvideoId : state.introVideo;

        if (res.data.banner) {
          setImageSrc(res.data.banner);
          setBannerId(res.data.channelBannerId);
          setMediaState({ ...mediaState, bannerImages: [{ image: res.data.banner, id: res.data.channelBannerId }] });
        }

        if (res.data.introVideo)
          setInitialVideo(process.env.REACT_APP_CDN_URL + res.data.introVideo)
        state.yearOfEstablishment = res.data.estYear ? res.data.estYear : state.yearOfEstablishment;

        state.storeCount = res.data.storeCount ? res.data.storeCount : state.storeCount;
        state.no_of_skus = res.data.no_of_skus ? res.data.no_of_skus : state.no_of_skus;
        state.areYouStartUp = res.data.startup ? res.data.startup : state.areYouStartUp;
        if (res.data.maturitylevel?.length)
          state.startUpType = res.data.maturitylevel[0];
        state.domesticRevenue = res.data.revD ? res.data.revD : state.domesticRevenue;
        state.internationalRev = res.data.revI ? res.data.revI : state.internationalRev;
        state.no_of_staffs = res.data.empCnt ? res.data.empCnt : res.data.empCntRange ? res.data.empCntRange.replace(/\s/g, "").trim() : state.no_of_staffs;
        state.annualTurnOver = res.data.turnover ? res.data.turnover : res.data.turnoverRange ? res.data.turnoverRange.replace(/\s/g, "").trim() : state.annualTurnOver;

        if (res.data?.channelSocial?.length) {
          res.data.channelSocial.map(x => {
            if (x.socialSite.socialSiteId == 1) {
              state.social_linkedin_id = x.channelSocialLinkId;
              state.linkedIn = x.channelSocialLink?.split(/[?#]/)[0].replace(/\/$/, "").split('/').splice(-1, 1)[0];
            }
            if (x.socialSite.socialSiteId == 3) {
              state.social_instagram_id = x.channelSocialLinkId;
              state.insta = x.channelSocialLink?.split(/[?#]/)[0].replace(/\/$/, "").split('/').splice(-1, 1)[0];
            }

            if (x.socialSite.socialSiteId == 4) {
              state.social_twitter_id = x.channelSocialLinkId;
              state.twitter = x.channelSocialLink?.split(/[?#]/)[0].replace(/\/$/, "").split('/').splice(-1, 1)[0];
            }
            if (x.socialSite.socialSiteId == 5) {
              state.social_facebook_id = x.channelSocialLinkId;
              state.facebook = x.channelSocialLink?.split(/[?#]/)[0].replace(/\/$/, "").split('/').splice(-1, 1)[0];
            }

            if (x.socialSite.socialSiteId == 2)
              state.tradeUrls = x.channelSocialLink
          })
        }

        state.tweetPitch = res.data.detailDesc ? res.data.detailDesc.replace(/<[^>]+>/g, '') : state.tweetPitch;
        setState({ ...state });
      }
    })
    setInitLoad(false);
  }

  const getCompanyData = async () => {

    const countryUrl = '/common/getSignup/' + userData.channelTypeId;
    const apiUrl = '/setting/company';

    // await axios.get('/setting/getInitialProfile').then(async res => {

    //   // await getChannelProfile();
    //   state.company_regid = res.data.country[0].registrationcertificate;
    //   state.isVat = res.data.country[0].isVat;
    //   state.BrandData = res.data.productBrand;
    //   state.brandOption = res.data.productBrand;
    //   state.productFamilies = res.data.productGroup;
    //   state.brandOption.push({ name: 'Add Brand', id: 0, doc_id: '0', documentpath: 'https://d35w8j22j5uly8.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg' })



    //   await axios.get('/setting/getChannelDetail').then(res => {
    //     state.storeCount = res.data.storeCount;
    //     state.no_of_skus = res.data.no_of_skus;
    //     state.areYouStartUp = res.data.startup ? res.data.startup : '0';
    //     if (res.data.maturitylevel?.length)
    //       state.startUpType = res.data.maturitylevel[0];
    //     state.domesticRevenue = typeId == '4' ? res.data.turnoverOnStore : res.data.revD;
    //     state.internationalRev = typeId == '4' ? res.data.onlineStore : res.data.revI;
    //     state.no_of_staffs = res.data.empCnt ? res.data.empCnt : res.data.empCntRange.replace(/\s/g, "").trim();
    //     state.annualTurnOver = res.data.turnover ? res.data.turnover : res.data.turnoverRange.replace(/\s/g, "").trim();
    //     setState({ ...state });
    //   }).catch(err => {
    //     commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: '/setting/getChannelProduct' }, '/error')
    //   });

    //   await axios.get('/setting/getCertificateTag').then(res => {
    //     if (res.data) {
    //       state.keyTags = res.data.map(x => {
    //         let temp = {};
    //         temp.certificatetagid = x.certificatetagid;
    //         temp.tag = x.tag;
    //         return temp;
    //       });
    //       setState({ ...state });
    //     }
    //   });
    //   //setState({...state, brandOption:state.brandOption});
    // }).catch(err => {
    //   commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: '/setting/getInitialProfile' }, '/error')
    // })

    //   await axios.get('/setting/profileAndSpeciality').then(res => {
    //       state.specialityOptions = res.data.specialityOptions.map(x => ({id: x.productGroupId, name: x.productGroup}));
    //       state.speciality = res.data.channelSpeciality.map(x => ({id: x.productGroup.id, name: x.productGroup.name}));
    //       state.frenchTech = res.data.isFrenchTech == '1' ? 'Yes' : 'No';
    //       if(res.data.profileType.length){
    //         state.DistributorProfileValue = Object.assign(res.data.profileType[0], {firstLetter: res.data.profileType[0].groupname});
    //         state.DistributorProfileOption = state.DistributorProfileOption.map(x => {
    //           if(x.childid == state.DistributorProfileValue.childid)
    //             return {...x, status: true}
    //           else
    //             return x;
    //         })
    //       }

    //   })



    await axios.get(apiUrl).then(res => {

      state.companyLogo = res.data.channel.channelDetail.channelLogo?.documentPath;
      state.companyName = res.data.channel.companyName;
      state.documentId = res.data.channel.channelDetail.channelLogo?.documentId;
      state.introVideo = res.data.channel.channelDetail.welcomevideo?.documentId;
      state.isOEM = res.data.channel.isOEM ? res.data.channel.isOEM : '3';
      if (res.data.channel.channelDetail.welcomevideo)
        setInitialVideo(process.env.REACT_APP_CDN_URL + res.data.channel.channelDetail.welcomevideo.documentUrl)
      state.yearOfEstablishment = res.data.channel.channelDetail.estYear;
      state.legalAddress = res.data.channel.regAddress.address;
      state.regAddressId = res.data.channel.regAddressId;
      state.billAddressId = res.data.channel.billAddressId;
      //setValue('city', res.data.channel.regAddress.city)
      //setValue('zipCode', res.data.channel.regAddress.postalCode)       
      state.zipCode = res.data.channel.regAddress.postalCode;
      state.city = res.data.channel.regAddress.city;
      if (res.data.channel.channelCertificate) {
        res.data.channel.channelCertificate.map(x => {
          if (x.cerificateTypeId == '1') {
            state.companyRegNumber = x.cerificateNumber;
            state.certificateName = x.cerificateName;
            state.certificateId = x.channelCerificateId;
          }
          if (x.cerificateTypeId == '2') {
            state.vatNumber = x.cerificateNumber;
            state.vatNumberCheck = x.cerificateNumber;
            state.vat_verified = x.vat_verified;
            state.vatId = x.channelCerificateId;
          }
        })
      }

      if (props.ratingId || !state.cerificateName) {
        state.certificateName = state.company_regid;
      }

      mediaState.productBrochures = [];
      if (res.data.channelPdf?.length) {
        res.data.channelPdf.map(x => {
          mediaState.productBrochures.push(x);
        })
      }
      mediaState.companyImages = [];
      if (res.data.channelImages?.length) {
        res.data.channelImages.map(x => {
          mediaState.companyImages.push(x);
        })
      }

      setMediaState({ ...mediaState });
      // state.vatNumber=res.data.channel.channelCertificate[1].cerificateNumber;

      state.phoneNumber1 = res.data.channel.channelDetail.phone1 ? res.data.channel.channelDetail.phone1 : ''; //.substring(res.data.channel.channelDetail.phone1.indexOf('-')+1,res.data.channel.channelDetail.phone1.length);
      state.phoneNumber2 = res.data.channel.channelDetail.phone2 ? res.data.channel.channelDetail.phone2 : '';
      state.mobile = res.data.channel.channelDetail.mobileNo;
      if (res.data.channel.channelDetail.webSiteUrl)
        state.websiteUrl = res.data.channel.channelDetail.webSiteUrl.substring(0, 2) == '//' ? res.data.channel.channelDetail.webSiteUrl.substring(2, res.data.channel.channelDetail.webSiteUrl.length) : res.data.channel.channelDetail.webSiteUrl;
      state.email = res.data.channel.companyMail;
      state.awards = [];
      state.savedAwards = [];
      if (res.data.channel.channelAward) {
        res.data.channel.channelAward.map(x => {
          if (x.documentId) {
            x.documentUrl = process.env.REACT_APP_CDN_URL + x.awardImg;
            state.awards.push(x);
            state.savedAwards.push(x);
          }

        })
      }
      // if(state.awards.length>0){
      //   state.awards.map(x=> state.savedAwards.push({documentId:x.documentId, id:x.id}));
      // }
      if (res.data.channel?.channelSocial?.length) {
        res.data.channel.channelSocial.map(x => {
          if (x.socialSite.socialSiteId == 1) {
            state.social_linkedin_id = x.channelSocialLinkId;
            state.linkedIn = x.channelSocialLink?.split(/[?#]/)[0].replace(/\/$/, "").split('/').splice(-1, 1)[0];
          }
          if (x.socialSite.socialSiteId == 3) {
            state.social_instagram_id = x.channelSocialLinkId;
            state.insta = x.channelSocialLink?.split(/[?#]/)[0].replace(/\/$/, "").split('/').splice(-1, 1)[0];
          }

          if (x.socialSite.socialSiteId == 4) {
            state.social_twitter_id = x.channelSocialLinkId;
            state.twitter = x.channelSocialLink?.split(/[?#]/)[0].replace(/\/$/, "").split('/').splice(-1, 1)[0];
          }
          if (x.socialSite.socialSiteId == 5) {
            state.social_facebook_id = x.channelSocialLinkId;
            state.facebook = x.channelSocialLink?.split(/[?#]/)[0].replace(/\/$/, "").split('/').splice(-1, 1)[0];
          }

          if (x.socialSite.socialSiteId == 2)
            state.tradeUrls = x.channelSocialLink
        })
      }

      if (res.data.channel?.channelB2B?.length) {
        state.b2bList = (res.data.channel.channelB2B.map(x =>
          ({ channel_B2B_id: x.channelB2BId, value: x.channelB2B })
        ))
      }
      else {
        state.b2bList = [{ id: Math.random(), value: '' }];
      }

      state.tweetPitch = res.data.channel.channelDetail.detailDesc ? res.data.channel.channelDetail.detailDesc.replace(/<[^>]+>/g, '') : '';
      state.sourcing = res.data.channel.channelDetail.expectations ? res.data.channel.channelDetail.expectations : '';
      state.companyDesc = res.data.channel.channelDetail.companyDesc ? res.data.channel.channelDetail.companyDesc : '';
      state.country = { name: res.data.channel.country, id: res.data.channel.countryId };
      // state.insta=res.data.channel.channelSocial[0].channelSocialLink;
      // state.linkedIn=res.data.channel.channelSocial[1].channelSocialLink;

      if (res.data.linkedInBanner) {
        setImageSrc(res.data.linkedInBanner);
        setBannerId('dummy'); //we need this to use permanent cdn url
      }

      if (res.data.bannerImg?.documentPath) {
        setImageSrc(res.data.bannerImg?.documentPath);
        setBannerId(res.data.bannerImg?.documentId);
        setMediaState({ ...mediaState, bannerImages: [{ image: res.data.bannerImg?.documentPath, id: res.data.bannerImg?.documentId }] });
      }
      setState({ ...state });
      // setInitLoad(false);
      //setState({...state, countryList:res.data.country});
    }).catch(err => {
      commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: '/setting/company' }, '/error')
      setInitLoad(false)
    });


    // await axios.get('/setting/getChannelProduct').then(res => {
    //   if (res.data.productGroup.length && state.productFamilies.length) {
    //     state.productFamilies = state.productFamilies.map(x => {
    //       res.data.productGroup.map(y => {
    //         if (x.groupid == y.groupid && x.childid == y.childid) {
    //           x.status = true;
    //         }
    //       })
    //       return x;
    //     })
    //   }

    //   state.productValue = res.data.productGroup;
    //   setState({ ...state, productFamilies: state.productFamilies, productValue: state.productValue });

    // }).catch(err => {
    //   commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: '/setting/getChannelProduct' }, '/error')
    // });




    // await axios.get('/setting/getChannelBrand').then(res => {
    //   state.brandList = res.data.brand;

    //   state.brandList.map(x => {
    //     state.brandOption = state.brandOption.filter(y => y.id != x.id);
    //   })

    // }).catch(err => {
    //   commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: '/setting/getChannelBrand' }, '/error')
    // });

    await axios.get(countryUrl).then(res => {
      state.countryList = res.data.country;
      setState({ ...state });
    }).catch(err => {
      commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: '/common/getSignup/' }, '/error')
    });

    // if (props.ratingId) {
    //   await getCompanyDataOld();
    // }

    setInitLoad(false);

  }
  const pdfDelete = (data) => {
    let filteredState = mediaState.productBrochures.filter(item => {
      if (item.documentId == data.documentId && item.channelDocumentId) {
        let temp = state.deletedDocuments;
        temp.push(+item.channelDocumentId);
        setState({ ...state, deletedDocuments: temp });
      }
      return item.documentId != data.documentId
    });
    if (filteredState) {
      setMediaState({ ...mediaState, productBrochures: filteredState });
      console.log('after delete', mediaState);
    }
  }

  const setKeyOptions = (input, keyname) => {

    let tempoptions = state[keyname];
    state[input + 'List'].map(x => {
      tempoptions = tempoptions.filter(y => y.id != x.id);
    })

    setState({ ...state, [input + 'Option']: tempoptions });
  }

  const deleteImg = (e) => {
    console.log('e', e);
    // error.productPos=null;
    mediaState.companyImages = mediaState.companyImages.filter(x => x.documentId !== e.documentId);
    if (e?.companyMediaId)
      state.deleted_companyImages.push(parseInt(e.companyMediaId));

    setMediaState({ ...mediaState });
    setState({ ...state });
    // setError({...error});
  }

  const getChannelProfile = async () => {
    const apiUrl = '/setting/tradeinfo';
    await axios.get(apiUrl).then(res => {
      //console.log("profiledata:",res.data);          
      //state.countryList=res.data.country;
      if (res.data.maturitylevel)
        state.startUpType = res.data.maturitylevel[0];

      state.ChannelProfileValue = userData.channelTypeId == '4' ? res.data.profile[0] : res.data.profile;
      state.listOfServicesOption = res.data.serviceListOptions;
      state.listOfServiceValue = res.data.serviceListValues;

      //brandList:res.data.brand,productValue:res.data.product,storeCount:res.data.storeCount,no_of_skus:res.data.no_of_skus,no_of_staffs:res.data.empCnt.replace(/\s/g, "").trim(),annualTurnOver:res.data.turnoverRange.replace(/\s/g, "").trim(),
      //sales: res.data.sales?+res.data.sales:'', marketing: res.data.marketing?+res.data.marketing:'', technical: res.data.technical?+res.data.technical:'',
      //purchase: res.data.purchase?+res.data.purchase:'',domesticRevenue:typeId=='4'?res.data.turnoverOnStore:res.data.revD,internationalRev:typeId=='4'?res.data.onlineStore:res.data.revI,
      setState({
        ...state, ChannelProfileValue: state.ChannelProfileValue,
        profileTypeId: +res.data.profileTypeId,
        areYouStartUp: res.data.startup,
      });
      //console.log('tradestate',state);
      // getTradeLoc();
      // setLoading(false);


    }).catch(err => {
      //ToastsStore.error('Company Not Found!')
      commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: '/setting/tradeinfo' }, '/error')
    });
  }

  const selectedItem = (gname, check, optionName, optionValue) => {
    let opt = [];
    //console.log("check:",check,gname);
    if (check == true) {
      state[optionName].map((item) => {

        if (item.groupname == gname || item.status == true) {
          item.status = check;
          opt.push(item);
        }
      });
    }
    if (check == false) {
      console.log("check:", check);
      state[optionName].map((item) => {
        if (item.groupname == gname && state[optionValue].length > 0)
          item.status = check;
        // if(state.productValue.length==0)
        //     item.status=false;
      });

      opt = state[optionValue].filter((item) => item.groupname != gname);
      opt.map(option => option.status = true);
    }

    state[optionValue] = opt;
    setState({ ...state, [optionValue]: state[optionValue] });
    setState({ ...state, [optionName]: state[optionName] });

    // if(optionValue='ChannelProfileValue' && state.ChannelProfileValue.length==0)
    //       errors.relevantChannelProfiles=true;
    // else 
    if (optionValue = 'productValue' && state.productValue.length == 0)
      errors.productCat = true;
  }

  const deleteBrand = (id) => {
    const filteredList = state.brandList.filter((item) => item.id !== id);
    state.brandList = filteredList;
    setState({ ...state, brandList: state.brandList });
    if (state.brandList.length == 0) {
      setState({ ...state, brandValue: [] });
      errors.Brands = { message: 'Please select a Brand', type: 'required' };
    }
    setKeyOptions('brand', 'BrandData');
  }

  const deleteKeyTag = (data) => {
    console.log('data', data);
    state.keyTags = state.keyTags.filter(x => x.tag !== data.tag);
    if (data?.certificatetagid)
      delete state.deletedTags.push(parseInt(data.certificatetagid));
    setState({ ...state });
  }

  const openAdd = async (input, value) => {
    //console.log('opneadd',opneadd);
    await setBrandName(input);
    openAddnew(true);
  }

  const closeModalPopup = () => {
    setBrandName('');
    openAddnew(false);
    setOpenRetailer(false);
    setOpenDist(false);
  }

  const introVideoId = async (data) => {
    console.log('introVideoId', data.documentId);
    setInitialVideo('');
    setState({ ...state, introVideo: data.documentId });
    setLoading(true)
    let res = await commonService.postService({ id: data.documentId }, '/setting/updateIntroVideo')
      .catch(err => {
        ToastsStore.error('Something Went Wrong!');
        commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: '/setting/updateIntroVideo' }, '/error')
      });
    if (res) {
      ToastsStore.success('Intro video Saved Successfully!');
    }
    setLoading(false);
  }

  const getCompanyLogo = (resImagePath) => {
    setState({ ...state, companyLogo: resImagePath.document.documentPath, documentId: resImagePath.documentId });
  };

  const getUserLogo = (resImagePath) => {
    console.log("userLogo:", resImagePath.document.documentUrl);
    setState({ ...state, userLogo: resImagePath.document.documentUrl, userLogoId: resImagePath.documentId });
  };


  const [companyError, setCompanyError] = useState(false);
  useEffect(() => {
    setCompanyError(false);
  }, [watchAll.companyName]);


  //save like other forms
  const saveCompDist = async () => {

    let keyDistributors = []; let keyRetailer = []; let keyResellers = [];
    if (id == 'comppic')
      confirmSave(id, mediaState.companyImages);

    //company linkedin
    else if (id == 'linkIn')
      confirmSave(id, state.linkedIn);

    //user linkedin
    else if (id == 'uslinkIn')
      confirmSave(id, state.linkedinurl);

    //user photo
    else if (id == 'uspic')
      confirmSave(id, { userLogo: state.userLogo, userLogoId: parseInt(state.userLogoId) });

    else if (id == 'banner') {
      let bannerImage = mediaState?.bannerImages?.length ? [].concat(mediaState.bannerImages.map(x => {
        return { image: x.image, id: x.id ? x.id : '' }
      })) : [];
      confirmSave(id, bannerImage);
    }
    else if (id == 'ofcdoc') {
      let productBrochure = {
        productpdf: mediaState?.productBrochures?.length ? [].concat(mediaState.productBrochures.map(x => {
          return { channelDocumentId: x.channelDocumentId ? +x.channelDocumentId : null, documentId: +x.documentId, document: x.document.documentUrl, documentName: x.name };
        })) : [{ document: 'null' }]//{image:null,documentName:null,documentDescription:null}]
      }
      confirmSave(id, productBrochure.productpdf);
    }
    else if (id == 'intro')
      confirmSave(id, state.introVideo);

    else if (id == 'salespoint')
      confirmSave(id, { isPhysicalSales: state.isPhysicalSales, physicalSales: state.physicalSales });


    else if (id == 'keydist') {
      state.DistributorList.map(x => {
        let temp = {};
        if (x.active) {
          temp.keypartnerid = x.keypartnerid;
          temp.partnername = x.name;
          temp.existchannelid = x.id;
          temp.countryid = x.active.country.country_id;
          temp.image = x.documentPath ? x.documentPath : null;
          temp.imageid = x.documentPath ? x.active.logo.logo_id : null;
        } else if (x.old) {
          temp.keypartnerid = x.keypartnerid;
          temp.partnername = x.name;
          temp.oldchannelid = x.id;
          temp.countryid = x.old.country.country_id;
          temp.image = x.documentPath ? x.documentPath : null;
          temp.imageid = x.documentPath ? x.old.logo.logo_id : null;
        }
        else if (x.new) {
          temp.keypartnerid = x.keypartnerid;
          temp.partnername = x.name;
          temp.countryid = x.new.country.country_id;
          temp.image = x.documentPath ? x.documentPath : null;
          temp.imageid = x.documentPath ? x.new.logo.logo_id : null;
        }
        else if (x.channelkeypartnerid) {
          temp.channelkeypartnerid = x?.channelkeypartnerid;
          temp.countryid = x?.regaddresscountry?.countryId;
          temp.image = x.documentPath ? x.documentPath : null;
          temp.imageid = x.documentPath ? x.companylogo.documentId : null;
        }
        else if (x.isNew != '1') {
          if (x.oldchannelid)
            temp.oldchannelid = x.oldchannelid;
          else if (x.channelId)
            temp.existchannelid = x.channelId;

          temp.partnername = x.companyName;
          temp.image = x.companylogo ? x.companylogo.documentPath : null;
          temp.imageid = x.companylogo ? x.companylogo.documentId : null;
          temp.countryid = x?.regaddresscountry?.countryId;

        }
        if (x.isNew == '1') {

          if (x.documentpath)
            temp.image = x.documentpath;

          temp.partnername = x.name;
          temp.countryid = +x.countryId;
        }
        keyDistributors.push(temp);
      })
    }

    //for key retailer
    else if ((businessType == 1 || businessType == 3) && id == "retail") {

      state.RetailerList.map(x => {
        let temp = {};
        if (x.active) {
          temp.keypartnerid = x.keypartnerid;
          temp.partnername = x.name;
          temp.existchannelid = x.id;
          temp.countryid = x.active.country.country_id;
          temp.image = x.documentPath ? x.documentPath : null;
          temp.imageid = x.documentPath ? x.active.logo.logo_id : null;
        } else if (x.old) {
          temp.keypartnerid = x.keypartnerid;
          temp.partnername = x.name;
          temp.oldchannelid = x.id;
          temp.countryid = x.old.country.country_id;
          temp.image = x.documentPath ? x.documentPath : null;
          temp.imageid = x.documentPath ? x.old.logo.logo_id : null;
        }
        else if (x.new) {
          temp.keypartnerid = x.keypartnerid;
          temp.partnername = x.name;
          temp.countryid = x.new.country.country_id;
          temp.image = x.documentPath ? x.documentPath : null;
          temp.imageid = x.documentPath ? x.new.logo.logo_id : null;
        }
        else if (x.channelkeypartnerid) {
          temp.channelkeypartnerid = x?.channelkeypartnerid;
          temp.countryid = x?.regaddresscountry?.countryId;
          temp.image = x.documentPath ? x.documentPath : null;
          temp.imageid = x.documentPath ? x.companylogo.documentId : null;
        }
        else if (x.isNew != '1') {
          if (x.oldchannelid)
            temp.oldchannelid = x.oldchannelid;
          else if (x.channelId)
            temp.existchannelid = x.channelId;

          temp.partnername = x.companyName;
          temp.image = x.companylogo ? x.companylogo.documentPath : null;
          temp.imageid = x.companylogo ? x.companylogo.documentId : null;
          temp.countryid = x?.regaddresscountry?.countryId;

        } if (x.isNew == '1') {
          // temp.countryid = x.countryId;
          if (x.documentpath)
            temp.image = x.documentpath;

          temp.partnername = x.name;
          temp.countryid = +x.countryId;
        }

        keyRetailer.push(temp);
      })
    }
    //for key reseller
    if ((businessType == 2 || businessType == 3) && id == "retail") {

      state.ResellerList.map(x => {
        let temp = {};
        if (x.active) {
          temp.keypartnerid = x.keypartnerid;
          temp.partnername = x.name;
          temp.existchannelid = x.id;
          temp.countryid = x.active.country.country_id;
          temp.image = x.documentPath ? x.documentPath : null;
          temp.imageid = x.documentPath ? x.active.logo.logo_id : null;
        } else if (x.old) {
          temp.keypartnerid = x.keypartnerid;
          temp.partnername = x.name;
          temp.oldchannelid = x.id;
          temp.countryid = x.old.country.country_id;
          temp.image = x.documentPath ? x.documentPath : null;
          temp.imageid = x.documentPath ? x.old.logo.logo_id : null;
        }
        else if (x.new) {
          temp.keypartnerid = x.keypartnerid;
          temp.partnername = x.name;
          temp.countryid = x.new.country.country_id;
          temp.image = x.documentPath ? x.documentPath : null;
          temp.imageid = x.documentPath ? x.new.logo.logo_id : null;
        }
        else if (x.channelkeypartnerid) {
          temp.channelkeypartnerid = x?.channelkeypartnerid;
          temp.countryid = x?.regaddresscountry?.countryId;
          temp.image = x.documentPath ? x.documentPath : null;
          temp.imageid = x.documentPath ? x.companylogo.documentId : null;
        }
        else if (x.isNew != '1') {
          if (x.oldchannelid)
            temp.oldchannelid = x.oldchannelid;
          else if (x.channelId)
            temp.existchannelid = x.channelId;

          temp.partnername = x.companyName;
          temp.image = x.companylogo ? x.companylogo.documentPath : null;
          temp.imageid = x.companylogo ? x.companylogo.documentId : null;
          temp.countryid = x?.regaddresscountry?.countryId;

        }
        if (x.isNew == '1') {

          if (x.documentpath)
            temp.image = x.documentpath;

          temp.partnername = x.name;
          temp.countryid = +x.countryId;
        }
        keyResellers.push(temp);
      })
    }



    let data = {};
    if (keyDistributors.length) {
      data = Object.assign(data, { channelKeyDistributor: keyDistributors, no_of_distributors: state.no_of_distributors })
    }

    if (keyRetailer.length) {
      data = Object.assign(data, { channelKeyRetailer: keyRetailer, no_of_retailers: state.no_of_retailers })
    }

    if (keyResellers.length) {
      data = Object.assign(data, { channelKeyReseller: keyResellers, no_of_resellers: state.no_of_resellers })
    }



    //console.log("state.RetailerList:", id, data);
    if (keyDistributors.length || keyRetailer.length || keyResellers.length)
      confirmSave(id, data);




    // await commonService.postService(mediaState.companyImages, '/setting/updateCompanyImages').then(res => {
    //   ToastsStore.success('Saved Successfully!');
    //   setLoading(false);
    //   setInitLoad(true)
    // });





    // if (userData.roleId == 1 || (userData.roleId != 1 && (previlege == 1 || previlege == 0))) {
    //   setLoading(true);

    //   let checkData = {
    //     companyName: state.companyName
    //   }

    //   let checkname = await axios.post('setting/checkCompanyName', checkData).catch(err => {
    //     ToastsStore.warning('Something went wrong');
    //   });

    //   if (!checkname.data.isAvailable) {
    //     setCompanyError(true);
    //     ToastsStore.warning('Company Name Already Taken.')
    //     setLoading(false);
    //     return;
    //   }

    //   try {
    //     //setting certificates
    //     let tax_cert = [];
    //     let reg_cert = [];
    //     reg_cert.push({ name: state.certificateName, id: state.companyRegNumber, channel_certificate_id: parseInt(state.certificateId) });
    //     if (state.isVat == '1') {
    //       if (state.vatNumber != state.vatNumberCheck || state.vat_verified != '1') {

    //         await axios.post('/setting/checkVat', { vatNumber: state.vatNumber }).then(res => {
    //           state.vat_verified = res.data.valid;
    //         }).catch(err => console.log('checkvat error', err));
    //       }

    //       if (state.vat_verified != 1 && state.vat_verified != 2) {
    //         setVatCheck(1);
    //         myRef.current.scrollIntoView();
    //         setLoading(false);
    //         return false;
    //       }

    //       tax_cert.push({ name: 'VAT', id: state.vatNumber, channel_certificate_id: parseInt(state.vatId), vat_verified: state.vat_verified == '1' ? '1' : '0' });
    //     } else {
    //       setVatCheck(0);
    //     }


    //     let certificates = {
    //       companyRegNumber: state.companyRegNumber,
    //       certificateName: state.certificateName,
    //       vatNumber: state.isVat == '1' ? state.vatNumber : null,
    //       registration_certificate: reg_cert,
    //       vat_verified: state.vat_verified == '1' ? '1' : '0'
    //     }

    //     if (tax_cert.length)
    //       certificates = Object.assign(certificates, { tax_certificate: tax_cert });
    //     let awardDocumentIds = [];
    //     console.log('savedAwards', state.savedAwards);

    //     if (state.savedAwards?.length)
    //       state.savedAwards.map(x => awardDocumentIds.push(x.documentId));

    //     let award_details = state.awards.filter(x => !awardDocumentIds.includes(x.documentId));
    //     let deleted_award_details = state.deletedAwards.length ? state.deletedAwards : [];

    //     let award = {
    //       award_details: award_details,
    //       deleted_award_details: deleted_award_details
    //     }

    //     //setting banner
    //     let bannerImage = mediaState?.bannerImages?.length ? [].concat(mediaState.bannerImages.map(x => {
    //       return { image: x.image, id: x.id ? x.id : '' }
    //     })) : [];//[{image:null}],

    //     //setting broucher
    //     let productBrochure = {
    //       productpdf: mediaState?.productBrochures?.length ? [].concat(mediaState.productBrochures.map(x => {
    //         return { channelDocumentId: x.channelDocumentId ? +x.channelDocumentId : null, documentId: +x.documentId, document: x.document.documentUrl, documentName: x.name };
    //       })) : [{ document: 'null' }]//{image:null,documentName:null,documentDescription:null}]
    //     }


    //     let media = {
    //       channel_document: productBrochure.productpdf,
    //       bannerImage: bannerImage,
    //       companyImages: mediaState.companyImages,  //setting company images
    //       deleted_companyImages: state.deleted_companyImages
    //     }

    //     if (media?.companyImages?.length === 0)
    //       delete media.companyImages

    //     if (media?.deleted_companyImages?.length === 0)
    //       delete media.deleted_companyImages

    //     if (state?.deletedDocuments?.length)
    //       media = Object.assign(media, { deleted_channel_documents: state.deletedDocuments });




    //     //social profiles
    //     let socialProfile = {
    //       social_linkedin_url: state.linkedIn,
    //       social_instagram_url: state.insta,
    //       social_linkedin_id: state.social_linkedin_id,
    //       social_googleplus_id: null,
    //       social_instagram_id: state.social_instagram_id,
    //       contact_linkedin_id: null,
    //       social_twitter_url: state.twitter,
    //       social_twitter_id: state.social_twitter_id,
    //       social_facebook_url: state.facebook,
    //       social_facebook_id: state.social_facebook_id,
    //     };

    //     if (state.b2bList.length) {
    //       state.b2bList = [].concat(state.b2bList.map(x => {

    //         if (x.channel_B2B_id && x.value)
    //           return { B2B_url: x.value, channel_B2B_id: x.channel_B2B_id }
    //         else if (x.channel_B2B_id && !x.value) {
    //           state.deleted_B2B.push(x.channel_B2B_id);
    //           return null;
    //         } else if (x.value && !x.channel_B2B_id) {
    //           return { B2B_url: x.value }
    //         }

    //       }));
    //       state.b2bList = state.b2bList.filter((el) => el != null);
    //       socialProfile = Object.assign(socialProfile, { deleted_B2B: state.deleted_B2B.length ? [... new Set(state.deleted_B2B)] : [null] });
    //       if (state.b2bList.length)
    //         socialProfile = Object.assign(socialProfile, { B2B_url: state.b2bList });
    //     }

    //     let marketing = {
    //       socialProfile: socialProfile,
    //       company_website_url: state.websiteUrl,
    //       award: award,
    //       certificatesList: state.keyTags.filter(el => !el.certificatetagid),
    //       deletedTags: state.deletedTags,
    //       deleted_companyImages: state.deleted_companyImages,
    //       companyImages: mediaState.companyImages,
    //       // company_mobile_no: watchAll.mobile,
    //       // company_email: watchAll.email,
    //       bannerImage: bannerImage,
    //     }

    //     if (mediaState?.companyImages?.length === 0)
    //       delete marketing.companyImages

    //     if (state.deleted_companyImages?.length === 0)
    //       delete marketing.deleted_companyImages

    //     //setting documents

    //     let document = {
    //       channel_document: productBrochure.productpdf,
    //       company_introvideo: state.introVideo,
    //     }

    //     if (state.deletedDocuments?.length)
    //       document = Object.assign(document, { deleted_channel_documents: state.deletedDocuments });

    //     let finalData = {
    //     //   companyRegistration: companyRegistration,
    //     //   tradeInformation: tradeInformation,
    //       //  channels: channels,
    //       marketing: marketing,
    //     //   facts: facts,
    //       document: document,
    //       ratingId: props.ratingId ? props.ratingId : null,
    //       annualTurnOver: state.annualTurnOver
    //     }

    //     if (props.ratingId) {

    //       props.setFinalData(finalData);

    //       if (userData.businessType != state.isOEM) {
    //         let existing = JSON.parse(localStorage.getItem('CHuser'));
    //         existing['businessType'] = +state.isOEM;
    //         localStorage.setItem('CHuser', JSON.stringify(existing));
    //       }

    //       props.changeNav(3);
    //       setLoading(false);
    //     } else {


    //       //uploading to api
    //       await commonService.postService(finalData, '/setting/update_company_profile').then(res => {
    //         ToastsStore.success('Saved Successfully!');

    //         setLoading(false);
    //         setInitLoad(true)

    //         if (props.ratingId) {
    //           history.push('/admin/dashboard');
    //         }

    //         if (userData.businessType != state.isOEM) {
    //           let existing = JSON.parse(localStorage.getItem('CHuser'));
    //           existing['businessType'] = +state.isOEM;
    //           localStorage.setItem('CHuser', JSON.stringify(existing));
    //         }

    //         getCompanyData();
    //       }).catch(err => {
    //         ToastsStore.error('Something Went Wrong!');
    //         console.log('erroncompany', err);
    //         setLoading(false);
    //         commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: 'updateTradeInfo' }, '/error');
    //       })

    //     }
    //   }
    //   catch (err) {
    //     ToastsStore.error('Something Went Wrong!');
    //     console.log('erroncompany', err);
    //     setLoading(false);
    //     commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: 'updateTradeInfo' }, '/error');
    //   }
    // }
    // else {
    //   modalPopValue.message = 'You are restricted to Modify and Save Company Info';
    //   modalPopValue.second_message = 'PLEASE CONTACT ACCOUNT OWNER'
    //   modalPopValue.title = 'Access Denied';
    //   setModalPopValue({ ...modalPopValue });
    //   setModalPopup(true);
    // }


  }

  const handleChange = input => e => {
    if (input == 'tradeUrls') {
      let b2b = [...state.b2bList];
      console.log('b2b', b2b, e.target.value, e.target);
      b2b[e.target.id].value = e.target.value;
      setState({ ...state, b2bList: b2b })
    }
    else if (input == 'tweetPitch') {
      setState({ ...state, [input]: e.target.value.slice(0, 280) });
    }
    else if (input == 'companyDesc') {
      setState({ ...state, [input]: e.target.value.slice(0, 2000) });
    }
    else if (input == "linkedIn" || input == "twitter" || input == "facebook" || input == "insta") {
      setState({ ...state, [input]: e.target.value.slice(state[input + '_pre'].length,) });
      errors[input] = false;
    }
    else if (input == 'linkedinurl') {
      setState({ ...state, [input]: e.target.value });
      //errors[input] = false;
    }
    else if (input == 'annualTurnOver' || input == 'no_of_staffs') {
      let temp = input == 'annualTurnOver' ? 'turnOver' : 'staffCnt';
      document.getElementById(temp).value = null;
      errors[temp] = null;
      setState({ ...state, [input]: e.target.value });
    } else if (input == 'turnOver' || input == 'staffCnt') {
      let temp = input == 'turnOver' ? 'annualTurnOver' : 'no_of_staffs';
      setState({ ...state, [temp]: e.target.value });
    }
    else {
      if (input == 'vatNumber') {
        setVatCheck(0);
      }
      setState({ ...state, [input]: e.target.value });
    }
    console.log("add", e.target.value, e.target);

  }


  const handleChangeQuill1 = (content, delta, source, editor) => {
    setState({ ...state, 'tweetPitch': editor.getText().trim().replace(/[\r\n]+/gm, "") });
  }

  const handleChangeQuill2 = (content, delta, source, editor) => {
    setState({ ...state, 'sourcing': editor.getText().trim().replace(/[\r\n]+/gm, "") });
  }

  const handleChangeSelect = async (input, value) => {
    if (input == 'country') {
      setLoading(true);
      await commonService
        .getServices("/setting/getVat/" + value?.id)
        .then((res) => {
          state.isVat = res.data.country[0].isVat;
        })
        .catch((err) => {
          commonService.postService(
            {
              err: JSON.stringify(err),
              reqUrl: window.location.pathname,
              service: "/setting/getVat",
            },
            "/error"
          );
        });
      state[input] = value;
      setState({ ...state });
      setLoading(false);
    }
    else if (input == 'productFamily') {
      state.productFamilies.map((item) => {
        if (value.length == 0)
          item.status = false;
        else {
          // value.map(v => {          
          //   // if(v.childname!=item.childname)
          //   //   item.status=false;                 
          //    if(v.childname==item.childname)            
          //     item.status=true;                       

          // });      

          if (value.filter(e => e.childname == item.childname).length)
            item.status = true;
          else
            item.status = false;
        }
      });

      value.map(option => option.status = true);
      state.productValue = value
      setState({ ...state, productFamilies: state.productFamilies });
      setState({ ...state, productValue: state.productValue });

      // state.productValue= value
      // setState({...state, productValue:state.productValue});
      if (state.productValue.length == 0)
        errors.productValue = true;

      let Group = _.uniq(state.productValue.map(x => x.groupid));
      state.groupCount = Group.length;
      setState({ ...state, groupCount: state.groupCount });



    }
    else if (input == 'brand' || input == 'brands' || input == 'Distributor' || input == 'Retailer' || input == 'Reseller') {
      if (input == 'brands')
        input = 'brand';
      if (value.name === 'Add Brand' && value.id === 0) {
        setBrandName('');
        openAdd(true);
        return <></>;
      }
      if (value.hasOwnProperty('isNew')) {
        state[input] = value;
      } else {
        value.isNew = '0';
        state[input] = value;
      }

      setState({ ...state, [input]: state[input] });
      let temp = state[input];
      temp = state[input + 'List'].filter(x => x.id == temp.id);
      if (temp.length) {
        console.log('already entered');
      } else {
        console.log('no entry')
        if (value.isNew == '1')
          state[input + 'ListUser'].push(state[input]);
        state[input + 'List'].push(state[input]);
        state[input + 'Option'] = state[input + 'Options'].filter(x => x.id != value.id);
        setState({ ...state, [input + 'List']: state[input + 'List'], [input + 'ListUser']: state[input + 'ListUser'] });
      }

      state[input] = [];
      setState({ ...state });
      console.log("brandList:", state[input + 'list']);
    }
    else if (input == 'relevantChannelProfile') {
      state.ChannelProfileOption.map((item) => {
        if (value.length == 0)
          item.status = false;
        else {
          // value.map(v => {          
          //   // if(v.childname!=item.childname)
          //   //   item.status=false;                 
          //    if(v.childname==item.childname)            
          //     item.status=true;                       

          // });
          if (value.filter(e => e.childname == item.childname).length)
            item.status = true;
          else
            item.status = false;
        }
      });

      value.map(option => option.status = true);
      state.ChannelProfileValue = value
      setState({ ...state, ChannelProfileOption: state.ChannelProfileOption });
      setState({ ...state, ChannelProfileValue: state.ChannelProfileValue });

      if (state.ChannelProfileValue.length == 0)
        errors.relevantChannelProfiles = true;
      // state.ChannelProfileValue= value
      // setState({...state, ChannelProfileValue:state.ChannelProfileValue});
    }
    else if (input == 'relevantRetailerProfile') {
      state.ChannelProfileValue = value
      setState({ ...state, ChannelProfileValue: state.ChannelProfileValue });
    }
    else if (input == 'certifications') {
      console.log('value', value);
      let name = value?.name;
      let tags = {
        tag: value.name
      }
      state.keyTags.push(tags);
      state.certifications = null;
      setState({ ...state });
    }
    else {
      if (input == "speciality" && value.length <= 3)
        setState({ ...state, [input]: value });
      else if (input != "speciality")
        setState({ ...state, [input]: value });

    }

  }

  console.log('satet', state);
  //console.log('watchAll', watchAll);
  //console.log('media', mediaState);

  const onSubmit = data => {
    if (data) {
      console.log("data:", data);
    }
  }

  // useEffect(() => {  
  //   if(!_.isEmpty(errors)&&formState.isSubmitted)
  //   {
  //     let error = Object.assign({}, errors);
  //     if(errors.tradeUrls){
  //       error.tradeUrls = error.tradeUrls.filter(x => x ? true : false);
  //     }

  //     ToastsStore.error(JSON.stringify(error));
  //     commonService.postService({err:JSON.stringify(error),reqUrl:window.location.pathname,service:'validation_err'},'/error')    
  //   }
  // }, [errors])

  //maturity level single or multiple decision making code
  //Award
  const addAwards = (data) => {
    console.log('data', data);
    let index = state.awards.findIndex((obj => obj.id == data.id));
    if (index != -1) {
      if (state.awards[index].isOld)
        state.awards[index] = Object.assign(data, { documentId: state.awards[index].documentId, isOld: true });
      else
        state.awards[index] = Object.assign(data, { documentId: state.awards[index].documentId });
      setState({ ...state, awards: state.awards });
      console.log('updated', data);
    }
    else {
      let temp = state.awards;
      temp.push(Object.assign(data, { documentId: null }));
      setState({ ...state, awards: temp });
      console.log('added', data);
    }
  }

  const deleteAwards = (data) => {
    if (data?.id) {
      state.savedAwards.filter((item) => {
        if (data.id == item.id)
          state.deletedAwards.push(item.id);
      });
      const filteredList = state.awards.filter((item) => item.id !== data.id);
      state.awards = filteredList;
      setState({ ...state, awards: state.awards, deletedAwards: state.deletedAwards });
    } else {
      const filteredList = state.awards.filter((item) => item.documentId !== data.documentId);
      state.awards = filteredList;
      setState({ ...state, awards: state.awards });
    }
  }

  const handleChangeFileSelect = async (input, value, filename) => {
    if (input === 'productBrochures') {
      console.log('brochures');
      let temp = mediaState.productBrochures;
      let newFiles = value.filter(file => !mediaState.productBrochures.find(f => f.documentId === file.documentId));

      console.log('temp', temp, temp.length + newFiles.length)
      if (temp.length + newFiles.length <= 5) {
        temp.push(...newFiles);
        setMediaState({ ...mediaState, [input]: temp });
      }
      else {
        console.log('Maximum 5 Images, Accepts PDF only, File Size Should Not Exceed 5 mb');
      }
    } else if (input === 'companyImages') {
      console.log('companyImages');
      let temp = mediaState.companyImages;
      let files = [];
      value.map(x => {
        files.push({ channelDocumentId: parseInt(x.channelDocumentId), channelId: x.channelId, documentUrl: x.document.documentUrl, documentPath: x.document.documentPath, documentId: x.documentId })
      })
      let newFiles = files.filter(file => !mediaState.companyImages.find(f => f.documentId === file.documentId));

      console.log('temp', temp, temp.length + newFiles.length)
      if (temp.length + newFiles.length <= 5) {
        temp.push(...newFiles);
        setMediaState({ ...mediaState, [input]: temp });
      }
      else {
        console.log('Maximum 5 Images, Accepts PDF only, File Size Should Not Exceed 5 mb');
      }
      console.log('mediaState', mediaState);
    } else if (input === 'awardsImages') {
      console.log('awardsImages');
      let temp = state.awards;
      let files = [];
      value.map(x => {
        if (x?.channelDocumentId)
          files.push({ channelDocumentId: parseInt(x.channelDocumentId), channelId: x.channelId, documentUrl: x.document.documentUrl, documentId: x.documentId })
        else
          files.push({ id: parseInt(x.id), channelId: x.channelId, documentUrl: x.document.documentUrl, documentId: x.documentId })
      })
      let newFiles = files.filter(file => !state.awards.find(f => f.documentId === file.documentId));

      console.log('temp', temp, temp.length + newFiles.length)
      temp.push(...newFiles);
      if (state.deletedAwards?.length) {
        let docIds = [];
        temp.map(x => {
          if (x?.id)
            docIds.push(x.id);
        })
        console.log('docIds', docIds);
        state.deletedAwards = state.deletedAwards.filter(x => !docIds.includes(x));
      }
      setState({ ...state, [input]: temp });
      console.log('state', state);
      console.log('mediaState', mediaState);
    }
    else {
      let res = await handleOnDrop(input, value, filename);
      if (res) {
        console.log('res', res);
        setMediaState({ ...mediaState, [input]: value })
      };
    }
  }

  const handleChangeFileDelete = async (input, fileToBeRemoved) => {
    let filteredState = await mediaState[input].filter(item => item.filename != fileToBeRemoved);
    if (filteredState) {
      setMediaState({ ...mediaState, [input]: filteredState });
      console.log('after delete', mediaState);
    }
  }

  //functions for image
  const handleOnDrop = async (input, data, file_name) => {
    if (data) {
      setLoading(true);
      console.log('datat', data);
      let file = data;
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        console.log('file', file)
        console.log('reader.result', reader.result)
        selectImg(input, reader.result, file_name)
        //setOpen(false);          
      };

    }
  }

  const handleChangeBanner = event => {
    const data = event.target.files[0];
    console.log('files', data)

    if (data && data['type'].split('/')[0] === 'image') {
      console.log('datat', data);
      let file = data;
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        console.log('file', file)
        console.log('reader.result', reader.result)
        setImage(
          reader.result
        );
        console.log('image', image)
        //setOpen(false);          
      };

    }

  };

  const selectBannerImg = async (data) => {
    if (data) {
      setImage('');
      //converting base64 string to file format.
      var random = Math.random();
      var fileName = random + '.png';
      var file = dataURLtoFile(data, fileName);
      const formData = new FormData();
      formData.append('file', file);
      setLoading(true);
      const resImagePath = await commonService.fileUploadService(formData, '/mediaupload/upload/' + 'banner');
      console.log('resImagePath', resImagePath);
      if (resImagePath) {
        setImageSrc(resImagePath.data.url);
        setBannerId('');
        setMediaState({ ...mediaState, 'bannerImages': [{ image: resImagePath.data.url }] });
        setLoading(false);
      }

    }

  }

  function dataURLtoFile(dataurl, filename) {

    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  const addNewB2B = () => {
    setState({ ...state, b2bList: [...state.b2bList, { id: Math.random(), value: '' }] });
  }

  const delB2B = (id) => {
    console.log('id', id);
    let del = state.deleted_B2B;
    state.b2bList.map((x, i) => {
      if (id == i && x.channel_B2B_id)
        del.push(x.channel_B2B_id);
    })
    let temp = state.b2bList.filter((r, i) => i !== id);
    setState({ ...state, b2bList: temp, deleted_B2B: del });
  }

  const selectImg = async (input, data, file_name) => {
    if (data.length > 0) {

      //converting base64 string to file format.
      var random = Math.random();
      var fileName = random + '.png';
      var file = dataURLtoFile(data, fileName);
      const formData = new FormData();
      formData.append('file', file);

      let aws_folder = input === 'bannerImages' ? 'banner' : '';


      const resImagePath = await commonService.fileUploadService(formData, '/mediaupload/upload/' + aws_folder);
      if (resImagePath) {
        if (input === 'bannerImages') {
          let temp = mediaState?.bannerImages;
          temp.push({ image: resImagePath.data.url, filename: file_name })
          setMediaState({ ...mediaState, [input]: temp });
        }
        setLoading(false);

      } else {
        loading = false;
        setLoading(loading);
      }

    }
    // setOpen(false);
  }
  const editName = (item) => {
    docName = item.name;
    setDocName(docName);
    docId = item.documentId;
    setDocId(docId);
    setNamePopup(true);
  }
  const docNameChange = async (docId, name) => {
    console.log('media', mediaState.productBrochures);
    console.log(docId, name);
    mediaState.productBrochures.map(x => {
      if (parseInt(x.documentId) === parseInt(docId))
        x.name = name;
    })
    namePopupClose();
  }
  const namePopupClose = () => {
    setNamePopup(false);
  };
  const openMediaLib = () => {
    if (mediaState.companyImages.length >= 5) {
      ToastsStore.error('Maximum 5 Images');
    } else {
      setComImage(true);
    }
  }
  const openAwardMediaLib = () => {
    if (state.awards.length >= 5) {
      ToastsStore.error('Maximum 5 Images');
    } else {
      setAwardImage(true);
    }
  }

  const closePopup = () => {
    setModalPopup(false);
  };

  const confirmPopup = (res) => {

    setModalPopup(false);
  }

  const closeTurnOver = () => {
    setAnnualTurn(false);
  };

  const saveTurnOver = async (value) => {
    setAnnualTurn(false);
    state.annualTurnOverList = value;
    setState({ ...state });
    console.log("saveproduct:", value);

  };
  const handleClose = () => {

  }
  const revomeProfileImage = () => {
    setState({ ...state, userLogo: null, userLogoId: null });
  }

  let linkedIn = props.location?.state?.linkedIn ? props.location?.state?.linkedIn : false;

  const [opneadd, opneAddnew] = useState(false);
  const [openReseller, setOpenReseller] = useState(false);

  const deleteBrand2 = (id) => {
    const filteredList = state.RetailerList.filter((item) => {
      if (item.id == id && item.keypartnerid) {
        let temp = state.deletedRetailer;
        temp.push(+item.keypartnerid);
        setState({ ...state, deletedRetailer: temp });
      }
      if (item.id == id && item.isNew == '1') {
        state.RetailerListUser = state.RetailerListUser.filter(x => x.id != item.id);
      }
      return item.id !== id
    });
    state.RetailerList = filteredList;
    setState({ ...state, RetailerList: state.RetailerList, RetailerListUser: state.RetailerListUser });
    if (state.RetailerList.length == 0) {
      setState({ ...state, Retailer: [] });
    }
    setKeyOptions('Retailer', 'KeyRetData');
  }

  const deleteBrand3 = (id) => {
    const filteredList = state.ResellerList.filter((item) => {
      if (item.id == id && item.keypartnerid) {
        let temp = state.deletedReseller;
        temp.push(+item.keypartnerid);
        setState({ ...state, deletedReseller: temp });
      }
      if (item.id == id && item.isNew == '1') {
        state.ResellerListUser = state.ResellerListUser.filter(x => x.id != item.id);
      }
      return item.id !== id
    });
    state.ResellerList = filteredList;
    setState({ ...state, ResellerList: state.ResellerList, ResellerListUser: state.ResellerListUser });
    if (state.ResellerList.length == 0) {
      setState({ ...state, Reseller: [] });
    }
    setKeyOptions('Reseller', 'KeyResData');
  }

  const opneAdd2 = async (input, value) => {
    console.log('opneadd', opneadd);
    await setBrandName(input);
    setOpenRetailer(true);
  }
  const opneAdd1 = async (input, value) => {
    console.log('opneadd', opneadd);
    await setBrandName(input);
    setOpenDist(true);
  }

  const opneAdd3 = async (input, value) => {
    console.log('opneadd', opneadd);
    await setBrandName(input);
    setOpenReseller(true);
  }
  const deleteBrand1 = (id) => {
    const filteredList = state.DistributorList.filter((item) => {

      if (item.id == id && item.keypartnerid) {
        let temp = state.deletedDistributor;
        temp.push(+item.keypartnerid);
        setState({ ...state, deletedDistributor: temp });
      }
      if (item.id == id && item.isNew == '1') {
        state.DistributorListUser = state.DistributorListUser.filter(x => x.id != item.id)
      }
      return item.id !== id
    });
    state.DistributorList = filteredList;

    setState({ ...state, DistributorList: state.DistributorList, DistributorListUser: state.DistributorListUser });
    if (state.DistributorList.length == 0) {
      setState({ ...state, Distributor: [] });
      // errors.Distributor={message: 'Please select Distributor', type:'required'};  
    }
    setKeyOptions('Distributor', 'KeyDistData');
  }

  return (
    <div className="main-content">
      <Modal
        className="modal-dialog-centered"
        isOpen={Open}
        size="xl"
      >
        <div className="modal-header">
          {/* <h3>Connect</h3> */}
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={CloseEdit}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body  d-flex justify-content-center pt-1 pb-1">
          <Card className="w-100 mb-0">
            <CardBody>
              <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore} />
              <Dialog open={isModalPopup} value={modalPopValue} handleSave={confirmPopup} handleClose={closePopup} />
              <DocumentName isOpen={namePopup} handleClose={namePopupClose} isEdit={true} handleSave={docNameChange} name={docName} docId={docId} />
              {initLoad ? <Spinner /> : <Container className="mt-4 " fluid>
                {comImage &&
                  <MultiSelectImages
                    id={1}
                    isOpen={comImage}
                    multiple={true}
                    limit={5}
                    acceptedFiles={['image/jpeg', 'image/png', 'image/gif']}
                    getBrochuresIds={handleChangeFileSelect}
                    input="companyImages" aws_folderName="companyImages"
                    handleClose={() => { setComImage(false) }}
                    documentIds={mediaState?.companyImages.length > 0 ? mediaState.companyImages.map(x => parseInt(x.documentId)) : []}
                  />
                }
                <form noValidate onSubmit={handleSubmit(saveCompDist)}>
                  {loading && <div style={{ top: '50%', left: '50%', zIndex: '1000', }}><Spinner /></div>}
                  <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="typeId" ref={register} name="typeId" value={typeId} control={control} />
                  {/* -----------------------------Point Of Sales------------------------------ */}
                  {id == "salespoint" &&
                    <Card>
                      {/* <CardBody> */}
                      <Row>
                        <Col lg='6'>
                          <div className="form-group text-left text-sm mb-0">
                            <h4>Do you have physical point of sales? </h4>
                            <FormRadio
                              name="isPhysicalSales"
                              className="text-sm mb-0"
                              options={radioGroupOptions}
                              control={control}
                              row
                              value={state.isPhysicalSales}
                              onChange={handleChange('isPhysicalSales')}
                            />
                          </div>
                        </Col>
                        {state.isPhysicalSales === '1' && <Col lg='6'>
                          <div className="form-group mt-4 mb-0">
                            <FormInput
                              id="physicalSales"
                              type="number"
                              name="physicalSales"
                              label="how many"
                              register={register}
                              error={errors.physicalSales}
                              defaultValue={state.physicalSales}
                              value={watchAll.physicalSales}
                              onChange={handleChange('physicalSales')}
                            />
                          </div>
                        </Col>}
                      </Row>
                      {/* </CardBody> */}
                    </Card>}


                  {/* -----------------------------Point Of Sales------------------------------ */}
                  {/* ------------------------------------profile of end customers------------------------ */}
                  {id == "endcust" &&
                    <Card>
                      <Row>
                        <Col lg='6'>
                          <div className="form-group text-left text-sm mb-0">
                            <h4>Profile of your end-customers?  </h4>
                            {/* <FormRadio
                    name="endCustomers"
                    className="text-sm mb-0"
                    options={radioGroupOptions1}
                    control={control}
                    // row
                    value={state.endCustomers} 
                    onChange={handleChange('endCustomers')}
                     /> */}
                            <div className="chck">
                              <FormCheckBox
                                id="Public sector"
                                type="checkbox"
                                label="Public sector"
                                //checked={x.status}
                                label1={{ textAlign: "justify" }}
                              //onClick={handleChange(x.name)}
                              //register={register}
                              />
                            </div>
                            <div className="chck">
                              <FormCheckBox
                                id="Education"
                                type="checkbox"
                                label="Education"
                                //checked={x.status}
                                label1={{ textAlign: "justify" }}
                              //onClick={handleChange(x.name)}
                              //register={register}
                              />
                            </div>
                            <div className="chck">
                              <FormCheckBox
                                id="Major accounts/ Enterprise"
                                type="checkbox"
                                label="Major accounts/ Enterprise"
                                //checked={x.status}
                                label1={{ textAlign: "justify" }}
                              //onClick={handleChange(x.name)}
                              //register={register}
                              />
                            </div>
                            <div className="chck">
                              <FormCheckBox
                                id="Midcaps"
                                type="checkbox"
                                label="Midcaps"
                                //checked={x.status}
                                label1={{ textAlign: "justify" }}
                              //onClick={handleChange(x.name)}
                              //register={register}
                              />
                            </div>
                            <div className="chck">
                              <FormCheckBox
                                id="SMB"
                                type="checkbox"
                                label="SMB"
                                //checked={x.status}
                                label1={{ textAlign: "justify" }}
                              //onClick={handleChange(x.name)}
                              //register={register}
                              />
                            </div>
                            <div className="chck">
                              <FormCheckBox
                                id="Micro-companies / self-employed"
                                type="checkbox"
                                label="Micro-companies / self-employed"
                                //checked={x.status}
                                label1={{ textAlign: "justify" }}
                              //onClick={handleChange(x.name)}
                              //register={register}
                              />
                            </div>
                            <div className="chck">
                              <FormCheckBox
                                id="Household"
                                type="checkbox"
                                label="Household"
                                //checked={x.status}
                                label1={{ textAlign: "justify" }}
                              //onClick={handleChange(x.name)}
                              //register={register}
                              />
                            </div>
                            <div className="chck">
                              <FormCheckBox
                                id="other (please specify)"
                                type="checkbox"
                                label="other (please specify)"
                                //checked={x.status}
                                label1={{ textAlign: "justify" }}
                              //onClick={handleChange(x.name)}
                              //register={register}
                              />
                            </div>
                          </div>
                          {state.endCustomers === '8' && <div className="form-group mt-4 mb-0">
                            <FormInput
                              id="otherEndCustomer"
                              type="text"
                              name="otherEndCustomer"
                              label="other"
                              register={register}
                              error={errors.otherEndCustomer}
                              defaultValue={state.otherEndCustomer}
                              value={watchAll.otherEndCustomer}
                              onChange={handleChange('otherEndCustomer')}
                            />
                          </div>}
                        </Col>
                      </Row>
                    </Card>}
                  {/* ------------------------------------profile of end customers------------------------ */}
                  {/* -----------------------------linkedin-------------------- */}
                  {id == "linkIn" &&
                    <Card>
                      <CardBody>
                        <h4 className='mt-4'>Company LinkedIn url</h4>
                        <Row>
                          <Col lg="12">
                            <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="linkedIn" ref={register} name="linkedIn" value={state.linkedIn} defaultValue={state.linkedIn} control={control} />
                            <a style={{ float: 'right', marginTop: '1rem' }} href={state.linkedIn_pre + state.linkedIn} target="_blank">View Link</a>
                            <FormInput className="comp-name mt-4" id="outlined-search" name="linkedIn1" label="Linkedin Handle" type="text" variant="outlined" value={state.linkedIn_pre + state.linkedIn}
                              onChange={handleChange('linkedIn')}
                              // register={register}
                              // error={!(/^(?=[a-zA-Z0-9._-]{0,100}$)(?!.*[_.-]{2})[^_.-].*[^_.-]$/.test(state.linkedIn)) && state.linkedIn ? {message:'Please Enter Valid LinkedIn Handle', required: true} : errors.linkedIn}
                              error={errors.linkedIn}
                              defaultValue={state.linkedIn_pre + state.linkedIn}
                            />
                          </Col>
                        </Row>


                      </CardBody>
                    </Card>}
                  {/* --------------------------welcome--------------------------- */}
                  {id == "intro" &&
                    <Card className='mt-4'>
                      <CardHeader>
                        <h3 className="text-left">Welcome Video</h3>
                        <p className="text-left">Add an introduction video that buyers will view on your company profile (max 90 seconds).</p>
                      </CardHeader>
                      <CardBody>
                        <div className="form-group">
                          <form action="/file-upload" className="dropzone">
                            <div className="fallback">
                              <div style={{ flex: 1, display: 'flex' }}><MediaLibrary text="Upload Intro Video" introVideoId={introVideoId} id='1' size={2} />
                                {initialVideo && <span style={{ textDecoration: 'underline', color: 'orange', display: 'flex', alignItems: 'center', marginLeft: '2rem', cursor: "pointer" }} onClick={() => setOpen(true)}>Preview Video</span>}</div>
                              <VideoPreview videoModalClose={() => setOpen(false)} isOpen={open} url={initialVideo} />
                            </div>
                          </form>
                        </div>
                      </CardBody>
                    </Card>
                  }
                  {/* -------------------------pic---------------------------- */}
                  {id == "comppic" &&
                    <Card>
                      <CardBody>

                        <div className="col-lg-12">
                          <h4 style={{ textAlign: 'left' }}>Your Company Pictures</h4>
                          <h5 style={{ float: 'right' }}>(Maximum 5 Images, Accepts Jpeg / Jpg / Png, File Size Should Not Exceed 5 mb)</h5>
                          <p>Add your office, factory, warehouse etc.. pictures</p>
                          {/* This below div is just used to inherit mui-dropzone css classes */}
                          <div style={{ display: 'none' }}><MultiUpload multiple={true} limit={1} acceptedFiles={['image/*']} handleChangeFile={handleChangeFileSelect} handleChangeFileDelete={handleChangeFileDelete} input="companyImages" /></div>
                          <span onClick={openMediaLib}>
                            {
                              !mediaState?.companyImages?.length ?
                                <div className="form-group mt-3">
                                  <div tabindex="0" class="MuiDropzoneArea-root">
                                    <div class="MuiDropzoneArea-textContainer">
                                      <p class="MuiTypography-root MuiDropzoneArea-text MuiTypography-h5">Drag and drop a file here or click</p>
                                      <svg class="MuiSvgIcon-root MuiDropzoneArea-icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z"></path></svg>
                                    </div></div></div>
                                :
                                <ImageCard list={mediaState.companyImages} deleteImg={deleteImg} />
                            }
                          </span>
                        </div>
                      </CardBody>
                    </Card>}
                  {/* -------------------------Banner-------------------------- */}
                  {id == "banner" && <Card>
                    <CardBody>

                      <div className="col-lg-12 ">
                        <h4 style={{ textAlign: 'left', display: 'inline-block' }}>Your Banner Image (this banner will be displayed on your public profile)</h4>
                        <h5 style={{ float: 'right' }}>(Accepts Jpeg / Jpg / Png Only, Image Size Should Not Exceed 5 mb)</h5>
                        <div className="form-group mt-3" style={{ height: 'auto' }}>
                          {/* <MultiUpload multiple={true} limit={1} acceptedFiles={['image/*']} handleChangeFile={handleChangeFileSelect} handleChangeFileDelete={handleChangeFileDelete} input="bannerImages"/> */}
                          <BannerUploader img={bannerId ? process.env.REACT_APP_CDN_URL + imageSrc : imageSrc ? process.env.REACT_APP_CDN_URL_TEMP + imageSrc : bannerPic}
                            handleChange={handleChangeBanner} selectImg={selectBannerImg} image={image} handleCropClose={e => setImage('')}
                          />
                          {loading && <div style={{
                            position: 'relative', display: 'block',
                            top: '50%',
                            left: '50%',
                            zIndex: '1000',
                          }}><Spinner /></div>}
                        </div>
                        {/* <div className="ban-preview mt-3">
                     <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  src={bannerId ? process.env.REACT_APP_CDN_URL+imageSrc : imageSrc ? process.env.REACT_APP_CDN_URL_TEMP+imageSrc : 'http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'}/>
                    </div>       */}
                        <div className="text-right"><span><i>* 2048 X 400 (Use a resolution of 5:1)</i></span></div>

                      </div>
                    </CardBody>
                  </Card>}


                  {/* ---------------------Doc------------------------------ */}
                  {id == "ofcdoc" &&
                    <Card>
                      <CardBody>
                        <div className="col-lg-12">
                          <h4 style={{ textAlign: 'left', display: 'inline-block' }}>Official Documents</h4>
                          <h5 style={{ float: 'right' }}>(Maximum 5 Images, Accepts PDF only, File Size Should Not Exceed 5 mb)</h5>
                          {/* This below div is just used to inherit mui-dropzone css classes */}
                          <div style={{ display: 'none' }}><MultiUpload multiple={true} limit={1} acceptedFiles={['image/*']} handleChangeFile={handleChangeFileSelect} handleChangeFileDelete={handleChangeFileDelete} input="bannerImages" /></div>
                          <span onClick={() => {
                            if (mediaState?.productBrochures?.length >= 5) {
                              ToastsStore.error('Maximum 5 Images');
                            } else {
                              setProdImage(true);
                            }
                          }}>
                            {
                              !mediaState?.productBrochures?.length ?
                                <div className="form-group mt-3">
                                  <div tabindex="0" class="MuiDropzoneArea-root">
                                    <div class="MuiDropzoneArea-textContainer">
                                      <p class="MuiTypography-root MuiDropzoneArea-text MuiTypography-h5">Drag and drop a file here or click</p>
                                      <svg class="MuiSvgIcon-root MuiDropzoneArea-icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z"></path></svg>
                                    </div></div></div>
                                :
                                <PdfCard pdfList={mediaState.productBrochures} deletePdf={pdfDelete} editName={editName} />
                            }
                          </span>

                          {prodImage && <ImageLibraryMultipleUploads id={1} isOpen={prodImage} multiple={true} limit={1} acceptedFiles={['application/pdf']} getBrochuresIds={handleChangeFileSelect} input="productBrochures" aws_folderName="broucher" handleClose={() => setProdImage(false)} documentIds={mediaState.productBrochures.length > 0 ? mediaState.productBrochures.map(x => parseInt(x.documentId)) : []} />}

                        </div>

                      </CardBody>
                    </Card>}
                  {/* -------------------------yourpicture---------------------- */}
                  {id == "uspic" &&
                    <Card>
                      <CardBody>
                        <Row>
                          <Col lg="12">
                            <h3>Your Picture {!errors.userLogo || state.userLogo ? '' : <span style={{ color: '#f44336', fontSize: '0.75rem' }}>   Please Select Profile Image</span>}</h3>
                            <Row>
                              <Col lg="5" style={{ padding: "0px" }}>
                                <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="userLogo" name="userLogo" ref={register} value={state.userLogo} />
                                <div>
                                  <ImageLibrary userLogo={true} getDocumentId={getUserLogo} aws_folderName='account-s3' id={2} size={1}
                                    img={state.userLogo ? state.userLogo : "http://d2maj8ifjo1lti.cloudfront.net/document/default_image/contact.png"} />
                                </div>
                                {/* <h1 onClick={revomeProfileImage} >remove</h1> */}


                              </Col>
                              <Col style={{ paddingTop: "2.5rem" }} lg="7">
                                <span>Accepts Jpeg / Jpg / Png Only</span>
                                <br></br>
                                <span>(Max size allowed 200 Kb)</span>
                                {(state.documentId && state.documentId != 1) && <div class="d-block mt-1">
                                  <i onClick={revomeProfileImage} id="imgr" class="fa fa-trash pointer" aria-hidden="true"></i>
                                  <UncontrolledTooltip delay={0} placement="bottom" target="imgr">Remove Picture</UncontrolledTooltip>
                                </div>}
                              </Col>
                            </Row>
                          </Col>
                        </Row>

                      </CardBody>
                    </Card>}
                  {/* --------------------------linkedin user-------------------------- */}
                  {id == "uslinkIn" &&
                    <Card>
                      <CardBody>
                        <Row>
                          <div className="col-lg-12">
                            <h4 className='mt-4'>Personal LinkedIn url</h4>
                            <div className="form-group">
                              <FormInput
                                id="LinkedinURL"
                                type="text"
                                label="Linkedin URL"
                                name="LinkedinURL"
                                register={register}
                                error={errors.LinkedinURL}
                                defaultValue={state.linkedinurl}
                                value={watchAll.LinkedinURL}
                                onChange={handleChange('linkedinurl')}
                                autoFocus={linkedIn}
                              />
                            </div>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>}

                  {/* -------------------------disreret-------------------------- */}
                  {id == "keydist" &&
                    <Card>
                      <CardHeader>
                        <h3 className="text-center">Key Distributors</h3>
                        <p className="text-center"></p>
                      </CardHeader>

                      <CardBody>
                        <div className="row">
                          <div className="col-lg-12 ">
                            {/* Key Distributors */}

                            <div className="col-lg-4 col-md-6 col-sm-12">
                              <h4 >Current number of Distributors </h4>
                              <FormInput className="comp-name mt-3" id="no_of_distributors" name="no_of_distributors" label="No. of Distributors *" type="number" variant="outlined" value={watchAll.no_of_distributors}
                                error={errors.no_of_distributors}
                                defaultValue={state.no_of_distributors}
                                onChange={handleChange('no_of_distributors')}
                                inputRef={register}
                              />
                            </div>
                            <br />

                            <div className="row">
                              <div className="col-lg-12 ">
                                <h4 >Key Distributors </h4>
                                <div className="form-group mt-3">

                                  {state.DistributorList[0] && <BrandCard name="BMW" deleteBrand={deleteBrand1} brandList={state.DistributorList} img={"https://d35w8j22j5uly8.cloudfront.net/document/brand/KVw8QS0n_1545236009520_1545236013342.png"} />}

                                  {/* <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="DistributorList" name="DistributorList" ref={register} value={state.DistributorList[0] ? 'data' : ''} />  */}
                                  {/* Autocomplete with image */}
                                  <AddFieldWithPopUpAutocomplete
                                    limitTags={2}
                                    name="Distributor"
                                    id="Distributor"
                                    options={state.DistributorOptions}//{state.DistributorProfileOption}
                                    defaultValue={state.Distributor}
                                    value={state.Distributor}
                                    getOptionLabel={option => option.name}
                                    renderOption={option => (
                                      <>
                                        <div style={{
                                          display: '-webkit-inline-flex',
                                          padding: '1px 6px',
                                          width: '75px',
                                          margin: '10px 5px 0 0',
                                          float: 'left',
                                          border: '2px solid #e7e7e7',
                                          borderRadius: '5px',
                                          height: '38px'
                                        }}>

                                          <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} style={{
                                            maxWidth: '63px', backgroundColor: '#fff',
                                            maxHeight: '34px',
                                            height: 'auto',
                                            width: 'auto',
                                            margin: '0 auto',
                                            cursor: 'pointer'
                                          }} src={option.documentpath ? option.documentpath : process.env.REACT_APP_CDN_URL + "document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"} />    </div>
                                        <span>
                                          {option.name}
                                        </span>
                                      </>
                                    )}
                                    renderInput={(params) => (
                                      <TextField {...params} variant="outlined" label="Key Distributors" placeholder="Key Distributors" />
                                    )}
                                    label="Key Distributors"
                                    placeholder="Key Distributors"
                                    input="Distributor"
                                    control={control}
                                    register={register}
                                    // errMessage={'Please Select  Key Distributors'}
                                    // error={errors.Distributor&&!state.DistributorList[0]? errors.Distributor : (errors.DistributorList && !state.DistributorList[0]) ? true : false}
                                    handleChangeSelect={handleChangeSelect}
                                    opneAdd={opneAdd1}
                                  />
                                  {brandName && <AddKeyPartner aws_folderName="brandlogo" opneadd={openDist} closeModalPopup={closeModalPopup} brandName={brandName} handleChangeSelect={handleChangeSelect} partner_type={3} input="Distributor" countryList={state.countryList} userList={state.DistributorListUser} />}

                                </div>
                              </div>
                            </div>

                            <div className="row ">
                              <div className="col-lg-12" >
                                {/* <h4>Others Key Distributors Description</h4> */}
                                <div className="form-group mt-3">
                                  <form>
                                    <textarea

                                      class="border-0"
                                      id="exampleFormControlTextarea1"
                                      onChange={handleChange('distributorDesc')}
                                      rows="1"
                                      value={state.distributorDesc}
                                      aria-label="empty textarea"
                                      aria-hidden="true"
                                    // placeholder="Others Key Distributors Description"
                                    >
                                    </textarea>
                                  </form>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </CardBody>
                    </Card>}


                  {(businessType == 1 || businessType == 3) && id == "retail" &&
                    <Card>
                      <CardHeader>
                        <h3 className="text-center">Retailers Channel</h3>
                        <p className="text-center"></p>
                      </CardHeader>
                      <CardBody>
                        {/* <div className="col-lg-12 ">
                          <h4 >Profile of your Retailers  *</h4>
                          <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="relevantChannelProfiles" name="relevantChannelProfiles" ref={register} value={state.ChannelProfileValue.length ? state.ChannelProfileValue[0].childname : ''} />
                          <div className="form-group">
                            <FormAutocompleteGrouping
                              id="relevantProfiles"
                              name="relevantProfiles"
                              label="Channel Profiles * "
                              multiple={true}
                              Options={state.ChannelProfileOption}
                              placeholder="Channel Profiles"
                              input='relevantChannelProfile'
                              errMessage={'Please Select Channel Profile'}
                              error={!errors.relevantChannelProfiles || state.ChannelProfileValue.length ? false : true}
                              value={state.ChannelProfileValue}

                              optionName='ChannelProfileOption'
                              optionValue='ChannelProfileValue'
                              defaultValue={state.ChannelProfileValue}
                              selectedItem={selectedItem}

                              handleChangeSelect={handleChangeSelect}
                              control={control}
                              register={register}
                            //defaultValue={state.ChannelProfileValue}
                            />

                          </div>
                        </div> */}

                        <div className="row">
                          <div className="col-lg-12 ">

                            <div className="col-lg-4 col-md-6 col-sm-12">
                              <h4 >Current number of retail partners </h4>
                              <FormInput className="comp-name mt-3" name="no_of_retailers" id="no_of_retailers" label="No. of Retailers *" type="number" variant="outlined" value={watchAll.no_of_retailers}
                                onChange={handleChange('no_of_retailers')}
                                defaultValue={state.no_of_retailers}
                                error={errors.no_of_retailers}
                                inputRef={register}
                              />
                            </div>
                            <br />


                            <h4 >Key Retailers </h4>
                            <div className="form-group mt-3">
                              {state.RetailerList[0] && <BrandCard name="BMW" deleteBrand={deleteBrand2} brandList={state.RetailerList} img={"https://d35w8j22j5uly8.cloudfront.net/document/brand/KVw8QS0n_1545236009520_1545236013342.png"} />}
                              <AddFieldWithPopUpAutocomplete
                                limitTags={2}
                                name="Retailers"
                                id="Retailers"
                                options={state.RetailerOptions} //{state.RetailerProfileOption}
                                defaultValue={state.Retailer}
                                value={state.Retailer}
                                getOptionLabel={option => option.name}
                                renderOption={option => (
                                  <>
                                    <div style={{
                                      display: '-webkit-inline-flex',
                                      padding: '1px 6px',
                                      width: '75px',
                                      margin: '10px 5px 0 0',
                                      float: 'left',
                                      border: '2px solid #e7e7e7',
                                      borderRadius: '5px',
                                      height: '38px'
                                    }}>

                                      <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} style={{
                                        maxWidth: '63px', backgroundColor: '#fff',
                                        maxHeight: '34px',
                                        height: 'auto',
                                        width: 'auto',
                                        margin: '0 auto',
                                        cursor: 'pointer'
                                      }} src={option.documentpath ? option.documentpath : process.env.REACT_APP_CDN_URL + "document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"} />    </div>
                                    <span>
                                      {option.name}
                                    </span>
                                  </>
                                )}
                                renderInput={(params) => (
                                  <TextField {...params} variant="outlined" label="Retailers" placeholder="Retailers" />
                                )}
                                label="Key Retailers"
                                placeholder="Key Retailers"
                                input="Retailer"
                                control={control}
                                register={register}
                                // errMessage={'Please Select a Key Retailers'}
                                // error={errors.Retailers&&!state.RetailerList[0]? errors.Retailers : (errors.retailerList && !state.RetailerList[0]) ? true : false}
                                handleChangeSelect={handleChangeSelect}
                                opneAdd={opneAdd2}
                              />
                              {brandName && <AddKeyPartner aws_folderName="brandlogo" opneadd={openRetailer} closeModalPopup={closeModalPopup} brandName={brandName} handleChangeSelect={handleChangeSelect} partner_type={4} input="Retailer" countryList={state.countryList} userList={state.RetailerListUser} />}

                            </div>

                            {/* <div className="row">
                              <div className="col-lg-12">
                                <h4>Others Key Retailers Description</h4>
                                <div className="form-group mt-3">
                                  <form>
                                    <textarea
                                      class="qtytext buytext2"
                                      aria-label="maximum height"
                                      id="exampleFormControlTextarea1"
                                      onChange={handleChange('RetailerDesc')}
                                      rows="3"
                                      value={state.RetailerDesc}
                                      aria-label="empty textarea"
                                      placeholder="Others Key Retailers Description">
                                    </textarea>
                                  </form>
                                </div>
                              </div>
                            </div> */}





                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  }



                  {(businessType == 2 || businessType == 3) && id == "retail" &&
                    <Card>

                      <CardBody>




                        <div className="row">
                          <div className="col-lg-12 ">


                            <CardHeader>
                              <h3 className="text-center">Reseller Channels </h3>
                              <p className="text-center"></p>
                            </CardHeader>

                            {/* <div className="col-lg-12 mt-4 ">
                              <h4 >Profile of your Resellers  *</h4>
                              <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="relevantResellerProfiles" name="relevantResellerProfiles" ref={register} value={state.ResellerProfileValue.length ? state.ResellerProfileValue[0].childname : ''} />
                              <div className="form-group">
                                <FormAutocompleteGrouping
                                  id="resellerProfiles"
                                  name="resellerProfiles"
                                  label="Reseller Profiles * "
                                  multiple={true}
                                  Options={state.ResellerProfileOption}
                                  placeholder="Channel Profiles"
                                  input='relevantResellerProfile'
                                  errMessage={'Please Select Channel Profile'}
                                  error={!errors.relevantResellerProfiles || state.ResellerProfileValue.length ? false : true}
                                  value={state.ResellerProfileValue}

                                  optionName='ResellerProfileOption'
                                  optionValue='ResellerProfileValue'
                                  defaultValue={state.ResellerProfileValue}
                                  selectedItem={selectedItem}

                                  handleChangeSelect={handleChangeSelect}
                                  control={control}
                                  register={register}
                                //defaultValue={state.ChannelProfileValue}
                                />

                              </div>
                            </div> */}




                            {/* Add Key Reseller */}


                            <div className="col-lg-4 col-md-6 col-sm-12">
                              <h4 >Current number of Reseller partners </h4>
                              <FormInput className="comp-name mt-3" name="no_of_resellers" id="no_of_resellers" label="No. of Resellers *" type="number" variant="outlined" value={watchAll.no_of_resellers}
                                onChange={handleChange('no_of_resellers')}
                                defaultValue={state.no_of_resellers}
                                error={errors.no_of_resellers}
                                inputRef={register}
                              />
                            </div>
                            <br />

                            <h4 >Key Resellers </h4>
                            <div className="form-group mt-3">

                              {state.ResellerList[0] && <BrandCard name="BMW" deleteBrand={deleteBrand3} brandList={state.ResellerList} img={"https://d35w8j22j5uly8.cloudfront.net/document/brand/KVw8QS0n_1545236009520_1545236013342.png"} />}

                              {/* <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="retailerList" name="retailerList" ref={register} value={state.RetailerList[0] ? 'data' : ''} />  */}
                              {/* Autocomplete with image */}
                              <AddFieldWithPopUpAutocomplete
                                limitTags={2}
                                name="Resellers"
                                id="Resellers"
                                options={state.ResellerOptions} //{state.RetailerProfileOption}
                                defaultValue={state.Reseller}
                                value={state.Reseller}
                                getOptionLabel={option => option.name}
                                renderOption={option => (
                                  <>
                                    <div style={{
                                      display: '-webkit-inline-flex',
                                      padding: '1px 6px',
                                      width: '75px',
                                      margin: '10px 5px 0 0',
                                      float: 'left',
                                      border: '2px solid #e7e7e7',
                                      borderRadius: '5px',
                                      height: '38px'
                                    }}>

                                      <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} style={{
                                        maxWidth: '63px', backgroundColor: '#fff',
                                        maxHeight: '34px',
                                        height: 'auto',
                                        width: 'auto',
                                        margin: '0 auto',
                                        cursor: 'pointer'
                                      }} src={option.documentpath ? option.documentpath : process.env.REACT_APP_CDN_URL + "document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"} />    </div>
                                    <span>
                                      {option.name}
                                    </span>
                                  </>
                                )}
                                renderInput={(params) => (
                                  <TextField {...params} variant="outlined" label="Resellers" placeholder="Resellers" />
                                )}
                                label="Key Resellers"
                                placeholder="Key Resellers"
                                input="Reseller"
                                control={control}
                                register={register}
                                // errMessage={'Please Select a Key Retailers'}
                                // error={errors.Retailers&&!state.RetailerList[0]? errors.Retailers : (errors.retailerList && !state.RetailerList[0]) ? true : false}
                                handleChangeSelect={handleChangeSelect}
                                opneAdd={opneAdd3}
                              />
                              {brandName && <AddKeyPartner aws_folderName="brandlogo" opneadd={openReseller} closeModalPopup={closeModalPopup} brandName={brandName} handleChangeSelect={handleChangeSelect} partner_type={5} input="Reseller" countryList={state.countryList} userList={state.ResellerListUser} />}

                            </div>



                          </div>
                        </div>


                        {/* <div className="row">
                          <div className="col-lg-12">
                            <h4>Others Key Resellers Description</h4>
                            <div className="form-group mt-3">
                              <form>
                                <textarea
                                  class="qtytext buytext2"
                                  id="exampleFormControlTextarea1"
                                  onChange={handleChange('ResellerDesc')}
                                  rows="3"
                                  value={state.ResellerDesc}
                                  aria-label="empty textarea"
                                  placeholder="Others Key Resellers Description">
                                </textarea>
                              </form>
                            </div>
                          </div>
                        </div> */}
                      </CardBody>
                    </Card>
                  }

                </form>
              </Container>}
            </CardBody>
          </Card>


        </div>
        <div className="modal-footer">
          <Button
            color="secondary"
            data-dismiss="modal"
            type="button"
            onClick={CloseEdit}
          >
            Close
          </Button>
          <div className="text-center">
            <button onClick={() => saveCompDist()} className="btn btn-primary" >Save</button>
          </div>

        </div>
      </Modal>
    </div>

  );
};

export default DashboardEdit;

const startUp = [
  { label: 'Prototype', id: 1, value: 'PROTOTYPE' },
  { label: 'Shipping to first backers', id: 2, value: 'S_BACKER' },
  { label: 'Shipping in volume', id: 3, value: 'S_VOLUME' },
];


const radioGroupOptions = [
  {
    value: "1",
    label: "Yes",
  },
  {
    value: "0",
    label: "No",
  },

];
const radioGroupOptions1 = [
  {
    value: "1",
    label: "B2C",
  },
  {
    value: "2",
    label: "B2B",
  },
  {
    value: "3",
    label: "Both",
  }
];