import React from 'react';
import CustomizedSteppers from './Stepper';
import "./Signup.scss"



export const SignUpHeader = (props) => {

    let email = props.location?.state?.email ? props.location?.state?.email : '';
       return (
           <div>
               <CustomizedSteppers fromGuest={email}/>    
           </div>
       );

}

export default SignUpHeader;