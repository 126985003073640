import React, { useState, useEffect } from 'react';
import { Card, CardBody, Row, Col, Button, UncontrolledTooltip } from "reactstrap";
import { ToastsContainer, ToastsStore } from 'react-toasts';
import axios from 'axios';

export const ContactListing = (props) => {
  const { data, title } = props;
  const [showMore, setMore] = useState(false);
  let logo = 'http://d2maj8ifjo1lti.cloudfront.net/document/default_image/contact.png';
  useEffect(() => {
  }, []);
  const more_click = () => {
    if (!showMore) setMore(true);
    else setMore(false);
  }
  if (data?.length > 0 || data.length > 0) {
    let _remain = [];
    let _elements;
      _elements = data?.slice(0, 4);
      _remain = data?.slice(4, data.length);
    const contact = (data) => {
      return (<>
        {data.length > 0 &&
          <>{data.map((x) => {
            return (<Col xl="3" lg="4" xs="12" md='6'>
              <Card id="contacthover">
                <div className="detv-contact">
                  <span>
                    <div className={"conne-img"}>
                      <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/default_image/contact.png"); }} className="img-fluid" alt="contact img" src={x?.documenturl ? x?.documenturl : logo} />
                    </div>
                    <div className="conn-cont">
                      <h3 style={{ marginTop: "0.5rem" }}> {x.firstName + ' ' + x.lastName} </h3>
                      <p className={'contactTitle'} style={{ marginBottom: "0.3rem" }}> {x.jobTitle} </p>
                      <a href={'/admin/mynetwork/channelDetail/' + x.channelId} ><p className={'contactTitle'} style={{ marginBottom: "0.3rem" }}> {x.companyName} </p></a>
                      <h5 className={'mt-2'}>{x.country}</h5>
                    </div>
                  </span>
                  {/* <div style={{ textAlign: "center" }} className="conn-btn">

                    {!!x.record?.sociallink?.length ?
                      x.sociallink.map(y => (
                        <>
                          {!!y.contactSocialLink ? <a id={'social-id' + x.contactId} onClick={() => {
                            let url = y.contactSocialLink;
                            if (!url.match(/^https?:\/\//i) && !url.match(/^http?:\/\//i)) {
                              url = '//' + url;
                            }
                            window.open(url, '_blank')
                          }}>
                            <Button className="btn-icon-only mr-2 i-link" color="twitter" type="button">
                              <span className="btn-inner--icon">
                                <i className="fab fa-linkedin" />
                              </span>
                            </Button>
                            <UncontrolledTooltip target={"social-id" + x.contactId} placement='bottom'>
                              linkedin
                            </UncontrolledTooltip>
                          </a>
                            :
                            <a data-placement="top" id={'social-id' + x.contactId}>
                              <Button className="btn-icon-only mr-2 i-linkDisabled" type="button">
                                <span className="btn-inner--icon">
                                  <i className="fab fa-linkedin" />
                                </span>
                              </Button>
                              <UncontrolledTooltip target={"social-id" + x.contactId} placement='bottom'>
                                {'LinkedIn url not available for this contact.'}
                              </UncontrolledTooltip>
                            </a>
                          }</>
                      )) :
                      <a data-placement="top" id={'social-id' + x.contactId}>
                        <Button className="btn-icon-only mr-2 i-linkDisabled" type="button">
                          <span className="btn-inner--icon">
                            <i className="fab fa-linkedin" />
                          </span>
                        </Button>
                        <UncontrolledTooltip target={"social-id" + x.contactId} placement='bottom'>
                          {'LinkedIn url not available for this contact.'}
                        </UncontrolledTooltip>
                      </a>
                    }
                  </div> */}
                </div>
              </Card>
            </Col>)
          })}</>
        }
      </>
      )
    }
    return (
      <Card>
        <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore} />
        <CardBody className="">
          <h3> {title} </h3>
          <div >
            <Row>
              {contact(_elements)}
            </Row>
            {showMore &&
              <Row>
                {contact(_remain)}
              </Row>
            }
          </div>
          {data?.length > 4 && <h3 onClick={more_click} className='float-right text-primary' style={{ cursor: 'pointer' }}>{!showMore ? 'Show More' : 'Show Less'}</h3>}
        </CardBody>
      </Card>
    )
  }
}
