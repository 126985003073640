
import React, { useState, useEffect } from "react";
import axios from 'axios';
// node.js library that concatenates classes (strings)
// javascipt plugin for creating charts
// react plugin used to create charts
// reactstrap components
import { commonService } from '_services/common.service';
import {
  Progress,
  Container,
  Row, Badge,
  Col, CardBody, Card, Button, ListGroupItem,
  ListGroup, Table, UncontrolledTooltip, UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle, ButtonGroup
} from "reactstrap";
import { Link } from 'react-router-dom';
import SimpleHeader from "components/Headers/SimpleHeader"
import ProggressBar from "./progress"
import { useBeforeFirstRender } from '_hooks/index'
import { history } from '_helpers/history';
// import {Wiretransfer,Failedtransaction} from 'views/pages/Landing-pages/Transaction'
// import {Userprofileform,AddgeneralForm} from 'views/pages/profile/Settings/Userprofileform'
// import Navbarg from 'views/pages/GuestPage/GuestNavbar/Navbarg'
// import GuestBlog from "../GuestPage/GuestNavbar/GuestBlog";
import { ModalSpinner } from "views/Hoc/Spinner";
import GuestprodSpec from "../GuestPage/GuestNew/GuestProdSpec";
import CardHeader from "reactstrap/lib/CardHeader";
import PerfectScrollbar from 'react-perfect-scrollbar'
import { ProfileViewConnect } from "views/Hoc/Dialog/ProfileViewConnect";
import { Dashslider, DashVenslider, DashEventlist } from './Dashslider'
import DashboardEdit from "./DashboardEdit";

let datas = [];

const localStorageWrapper = {
  getItem: (key) => {
    let user = localStorage.getItem(key);

    if (key == "CHuser" && window.location.host.indexOf("inspect.") < 0) {
      //page
      let selectuser = localStorage.getItem("selectCHUser");
      if (selectuser != "null" && selectuser != null) {
        return selectuser;
      }
    }
    return user;

  },
  setItem: (key, value) => {
    localStorage.setItem(key, value)
  }
}

const NewDashboard = (props) => {
  const [activity, setActivity] = useState('Today');
  const [featureArr, setFeatureArr] = useState([]);
  const [planDetails, setPlanDetails] = useState([]);
  // let userData = JSON.parse(localStorage.getItem('CHuser'));
  let userData = JSON.parse(localStorageWrapper.getItem('CHuser'));
  let profileViewAccess;
  const [planUpgrade, setplanUpgrade] = useState(false);
  let [connect, setconnect] = useState(false);
  let [title, setTitle] = useState('');
  const [loading, setLoading] = useState(true);

  const [state, setState] = useState({
    leads: '0',
    inbound: '0',
    outbound: '0',
    favorites: '0',
    views: '0',
    profileViews: '0',
    unknownProfileViews: '0',
    productViews: '0',
    balance: '0',
    profileStrength: '0',
    planType: '',
    start: '',
    end: '',
    featureList: [],
    profileList: [],
    productList: [],
    profile_fav_List: [],
    product_fav_List: [],
    graphData: [],
    isPlanExpired: false,
    channels: [],
    products: [],
    news: [],
    profileScore: '0',
    eventData: [],
    profileMetrics: [],
    jobTitle: '',
    linkedIn: '',
    // linkedIn_pre: 'https://www.linkedin.com/company/'
  });

  useEffect(() => {

    //go to event manage before event start

    // let dashboard = userData?.channelTypeId==2 ? 'vendorDashboard' : 'BuyerDashboard';

    // let existing = JSON.parse(localStorage.getItem('CHuser'));

    //   if(existing.isLoggedEventUser?false:true&&userData.isEvent=='1')
    //   {       
    //       if(userData.isEvent=='1'&&userData.hasTravel=='1')
    //       {            
    //         existing['isLoggedEventUser'] = '1';
    //         localStorage.setItem('CHuser', JSON.stringify(existing));
    //         history.push('/admin/' + dashboard + '/eventManage/3');
    //       }
    //       else if(userData.isEvent=='1'&&userData.hasTravel=='0')
    //       {
    //         existing['isLoggedEventUser'] = '1';
    //         localStorage.setItem('CHuser', JSON.stringify(existing));
    //         history.push('/admin/' + dashboard + '/eventManage/2');
    //       }
    //   }

    //for event user redirection
    // checkEventRedirection();

    getDashboardData();
    //getSubscriptionPlanDetails();

  }, []);

  const checkEventRedirection = async () => {
    let res = await commonService.getServices('/channel/checkEventRedirection').catch(err => console.log('err', err));

    let existing = JSON.parse(localStorage.getItem('CHuser'));

    if (existing.isLoggedEventUser ? false : true && res.data.length) {

      existing['isLoggedEventUser'] = '1';
      localStorage.setItem('CHuser', JSON.stringify(existing));
      history.push('/admin/events/eventManagement/' + res.data[0].eventId);


    }

  }

  const getDashboardData = async () => {
    setLoading(true);
    let res = await commonService.getServices('/channel/getDashboardcount').catch(err => console.log('err', err));
    //let subs = await commonService.getServices('/channel/subscription/getPlansDetails');
    if (res) {
      // state.inbound = res.data.inbound_count;
      // state.outbound = res.data.outbound_count;
      state.favorites = res.data.favo;
      // state.productViews = res.data.product_visit;
      state.profileViews = res.data.profile_visit;
      state.unknownProfileViews = res.data.unknown_profile_visit;
      state.profileList = res.data.profile_activity;
      state.productList = res.data.product_activity;
      // state.product_fav_List = res.data.product_fav_activity;
      // state.profile_fav_List = res.data.profile_fav_activity;
      // state.feature_list_view = res.data.featureList;

      state.leads = res.data.leads;//suggestion_vendor_count + res.data.suggestion_dist_count + res.data.suggestion_retail_count + res.data.productsugg;
      state.balance = res.data.walletamt ? res.data.walletamt : 0;
      state.profileStrength = res.data.profile_strength ? res.data.profile_strength : 0;
      state.profileScore = userData.channelTypeId == 2 ? 55 : 65; //res.data.profileStrength ? res.data.profileStrength : 
      state.planType = res.data.subscriptionPlanType ? res.data.subscriptionPlanType : 'Free';
      state.jobTitle = res.data.jobTitle;
      state.linkedIn = res.data.linkedIn;
      state.start = res.data.startedAt ? new Date(res.data.startedAt) : '';
      state.start = state.start ? state.start.getDate() + ' ' + state.start.toLocaleString('default', { month: 'long' }) + ' ' + state.start.getFullYear() : '';
      state.end = res.data.endAt ? new Date(res.data.endAt) : '';
      state.end = state.end ? state.end.getDate() + ' ' + state.end.toLocaleString('default', { month: 'long' }) + ' ' + state.end.getFullYear() : '';
      // if(Object.keys(res).length !== 0 && res.constructor !== Object)
      //   state.featureList = subs?.data?.result?.subscriptionExtraFeature
      state.graphData = res.data.graphData;

      var curentDate = new Date();
      var planExpireAt = new Date(res.data.endAt);
      state.isPlanExpired = (+curentDate.getTime() > +planExpireAt.getTime()) ? true : false;
      // setState({ ...state });
      console.log('ers', res.data);
    }
    setState({ ...state });


    res = await commonService.getServices('/channel/latestVendors').catch(err => console.log('err', err));

    if (res) {
      state.channels = res.data.channels;
      state.products = res.data.products;
      //setState({ ...state });
    }
    setState({ ...state });
    // setLoading(false);

    let result = await commonService.getServices('/channel/eventData').catch(err => console.log('err', err));

    if (result) {
      //console.log("eventData:", eventData);
      state.eventData = result.data.eventData;
      state.profileMetrics = result.data.profileMetrics;

      //update profile score
      if (userData.channelTypeId == 3 && +state.profileMetrics[0].keypartners)
        state.profileScore = parseInt(state.profileScore) + 10;
      if (state.profileMetrics[0].bannerURL || state.profileMetrics[0].channelBannerId)
        state.profileScore = parseInt(state.profileScore) + 5;

      if (userData.channelTypeId == 3 && +state.profileMetrics[0].channellinkedurl)
        state.profileScore = parseInt(state.profileScore) + 3;
      if (state.profileMetrics[0].userlinkedurl)
        state.profileScore = parseInt(state.profileScore) + 5;
      if (state.profileMetrics[0].contact_picture)
        state.profileScore = parseInt(state.profileScore) + 5;

      if (userData.channelTypeId != 3 && +state.profileMetrics[0].company_picture)
        state.profileScore = parseInt(state.profileScore) + 5;

      if (state.profileMetrics[0].introvideoId)
        state.profileScore = parseInt(state.profileScore) + 5;

      if (userData.channelTypeId == 3 && +state.profileMetrics[0].document)
        state.profileScore = parseInt(state.profileScore) + 2;

      //retailer
      if (state.profileMetrics[0].physicalSales && (userData.channelTypeId == 4 || userData.channelTypeId == 5))
        state.profileScore = state.profileScore + 10;

      // incremented Profile value for othernuser
      if(userData.isOtherUser == '1' ){
        if (state.profileMetrics[0].document)
          state.profileScore = parseInt(state.profileScore) + 10;

        if (state.profileMetrics[0].bannerURL || state.profileMetrics[0].channelBannerId)
          state.profileScore = parseInt(state.profileScore) + 5;
      }


      await axios.put('channel/updateProfileScore/', {
        profileScore: state.profileScore,
        channelId: userData.channelId
      })
      // setState({ ...state });
    }

    setState({ ...state });

    let news = await commonService.getServices('/channel/news').catch(err => console.log('err', err));

    if (news) {
      //console.log("news:", news);
      state.news = news.data.items;
      setState({ ...state });
    }
    setLoading(false);

    // let etData = await commonService.getServices('/channel/eventData').catch(err => console.log('err', err));

    // if (etData) {
    //   console.log("eventData:", etData);
    //   state.eventData = etData.data.eventData;
    //   setState({ ...state });
    // }


    handleChange('Today');
  }

  const handleChange = async (filter) => {
    setActivity(filter);
    await axios.post('/channel/dashboardActivity', { all: filter == 'All' ? '1' : '0' }).then(res => {
      state.profileList = res.data.profile_activity;
      state.productList = res.data.product_activity;
      // state.product_fav_List = res.data.product_fav_activity;
      // state.profile_fav_List = res.data.profile_fav_activity;
      setState({ ...state });
    }).catch(err => console.log('err', err));
  }

  const getSubscriptionPlanDetails = async () => {
    await commonService.getServices('/channel/subscription/getPlansDetails').then(res => {
      //  console.log('subscriptionExtraFeature');
      //  console.log(res.data.category);
      //  console.log('=========================================');
      setFeatureArr(res.data.category);
      let planDetails = {}; let resu = res.data.result;
      planDetails.planType = resu.subscription.subscriptionPlan.subscriptionPlanType ? resu.subscription.subscriptionPlan.subscriptionPlanType : 'Free';

      planDetails.start = resu.startedAt ? new Date(resu.startedAt) : '';
      planDetails.startedAt = planDetails.start ? new Date(planDetails.start).getDate() + ' ' + planDetails.start.toLocaleString('default', { month: 'long' }) + ' ' + planDetails.start.getFullYear() : '';

      planDetails.endAt = resu.endAt ? new Date(resu.endAt) : '';
      planDetails.end = planDetails.endAt ? planDetails.endAt.getDate() + ' ' + planDetails.endAt.toLocaleString('default', { month: 'long' }) + ' ' + planDetails.endAt.getFullYear() : '';
      setPlanDetails(planDetails);
      console.log('=========================================');
      console.log(planDetails);
      console.log('=========================================');
    }).catch(err => {

      //commonService.postService({err:JSON.stringify(err),reqUrl:window.location.pathname,service:'/channel/subscription/getPlansDetails'},'/error');                     
    });
  }
  const openFeaturedUrls = async (data) => {

    if (profileViewAccess == 'yes') {
      if (data == 'profileView') {
        history.push({ pathname: '/admin/profileView/', state: { viewId: '1' } })
      } else if (data == 'productViews') {
        history.push({ pathname: '/admin/profileView/', state: { viewId: '2' } })
      } else if (data == 'fav') {
        history.push({ pathname: '/admin/profileView/', state: { viewId: '3' } })
      }
    } else {
      setplanUpgrade(true);
    }
  }

  const upgrade = async (data) => {
    history.push('/admin/channel/channelDetail/' + data);
  }

  const closeplanUpgrade = () => {
    setplanUpgrade(false);
  }
  const openplanUpgrade = () => {
    setplanUpgrade(true);
  }

  const closePopup = () => {
    setconnect(false);
  };

  const [Open, setOpen] = useState(false)
  const [editId, setEditId] = useState(false)
  const closeEdit = () => {
    setOpen(false);
  };
  const openEdit = (id) => {
    setEditId(id)
    setOpen(true)
  }

  const confirmSave = (id, data) => {
    setOpen(false);

    (async () => {

      if (id == 'comppic') {
        await commonService.postService(data, '/setting/updateCompanyImages').then(res => {
          if (res.data) {
            state.profileScore = parseInt(state.profileScore) + 5;
            state.profileMetrics[0].company_picture = 1;
            axios.put('channel/updateProfileScore/', {
              profileScore: state.profileScore,
              channelId: userData.channelId
            })
            setState({ ...state });
          }
        }).catch(err => {

        });
      }
      else if (id == 'linkIn') {
        await commonService.postService({ social_linkedin_url: data }, '/setting/updateCompanySocial').then(res => {
          if (res.data) {
            state.profileScore = parseInt(state.profileScore) + 3;
            state.profileMetrics[0].channellinkedurl = 1;
            axios.put('channel/updateProfileScore/', {
              profileScore: state.profileScore,
              channelId: userData.channelId
            })
            setState({ ...state });
          }
        }).catch(err => {

        });
      }
      else if (id == 'uspic') {

        await commonService.postService({ userLogoId: data.userLogoId }, '/user/updateUserPicture').then(res => {

          if (res.data) {
            state.profileScore = parseInt(state.profileScore) + 5;
            state.profileMetrics[0].contact_picture = data.userLogoId;
            axios.put('channel/updateProfileScore/', {
              profileScore: state.profileScore,
              channelId: userData.channelId
            })
          }

          //update profile picture everywhere
          let existing = JSON.parse(localStorage.getItem('CHuser'));
          existing['documentUrl'] = data.userLogo;
          localStorage.setItem('CHuser', JSON.stringify(existing));
          setState({ ...state });


        }).catch(err => {

        });
      }
      else if (id == 'uslinkIn') {
        await commonService.postService({ social_linkedin_url: data }, '/user/updateUserSocial').then(res => {
          if (res.data) {
            state.profileScore = parseInt(state.profileScore) + 5;
            state.profileMetrics[0].userlinkedurl = 1;
            axios.put('channel/updateProfileScore/', {
              profileScore: state.profileScore,
              channelId: userData.channelId
            })
            setState({ ...state });
          }
        }).catch(err => {

        });
      }
      else if (id == 'banner') {
        await commonService.postService(data, '/setting/updateCompanyBanner').then(res => {
          if (res.data) {
            state.profileScore = parseInt(state.profileScore) + 5;
            state.profileMetrics[0].bannerURL = 1;
            axios.put('channel/updateProfileScore/', {
              profileScore: state.profileScore,
              channelId: userData.channelId
            })
            setState({ ...state });
          }
        }).catch(err => {

        });
      }
      else if (id == 'ofcdoc') {
        await commonService.postService(data, '/setting/updateCompanyDocument').then(res => {
          if (res.data) {
            state.profileScore = parseInt(state.profileScore) + 2;
            state.profileMetrics[0].document = 1;
            axios.put('channel/updateProfileScore/', {
              profileScore: state.profileScore,
              channelId: userData.channelId
            })
            setState({ ...state });
          }
        }).catch(err => {

        });
      }
      else if (id == 'intro') {
        await commonService.postService({ company_introvideo: data }, '/setting/updateCompanyVideo').then(res => {
          if (res.data) {
            state.profileScore = parseInt(state.profileScore) + 5;
            state.profileMetrics[0].introvideoId = 1;
            axios.put('channel/updateProfileScore/', {
              profileScore: state.profileScore,
              channelId: userData.channelId
            })
            setState({ ...state });
          }
        }).catch(err => {

        });
      }

      //unused
      // else if (id == 'keydist') {
      //   await commonService.postService(data, '/setting/updateKeyPartner').then(res => {
      //     if (res.data) {
      //       state.profileScore = parseInt(state.profileScore) + 5;
      //       state.profileMetrics[0].keydistributor = 1;
      //       axios.put('channel/updateProfileScore/', {
      //         profileScore: state.profileScore,
      //         channelId: userData.channelId
      //       })
      //       setState({ ...state });
      //     }
      //   }).catch(err => {

      //   });
      // }

      else if (id == 'salespoint') {
        await commonService.postService(data, '/setting/updateSalesPoint').then(res => {
          if (res.data) {
            state.profileScore = parseInt(state.profileScore) + 10;
            state.profileMetrics[0].physicalSales = 1;
            axios.put('channel/updateProfileScore/', {
              profileScore: state.profileScore,
              channelId: userData.channelId
            })
            setState({ ...state });
          }
        }).catch(err => {

        });
      }

      else if (id == 'endcust') {
        await commonService.postService(data, '/setting/updateEndCust').then(res => {
          if (res.data) {
            state.profileScore = parseInt(state.profileScore) + 10;
            state.profileMetrics[0].endCustomer = 1;
            axios.put('channel/updateProfileScore/', {
              profileScore: state.profileScore,
              channelId: userData.channelId
            })
            setState({ ...state });
          }
        }).catch(err => {

        });
      }

      else if (id == 'retail') {
        await commonService.postService(data, '/setting/updateKeyPartner').then(res => {
          if (res.data) {
            state.profileScore = parseInt(state.profileScore) + 10;
            state.profileMetrics[0].keypartners = 1;
            axios.put('channel/updateProfileScore/', {
              profileScore: state.profileScore,
              channelId: userData.channelId
            })
            setState({ ...state });
          }
        }).catch(err => {

        });
      }

    })();
    setLoading(false);
  }
  return (
    <>
      {loading ? <ModalSpinner /> :
        <>
          <SimpleHeader location={props.match} triggerSecondFunction={getDashboardData} />
          <div id='newdashboard' className="main-content">
            {Open === true &&
              <DashboardEdit Open={Open} CloseEdit={closeEdit} confirmSave={confirmSave} id={editId} />}

            <Container className="mt-4" fluid>
              {/* <Row>
                <Col className="card-wrapper" lg="12" md="12" sm="12" >
                  <Card>
                    <CardBody style={{ padding: '1rem' }} >
                      <h3 className="mb-2">Hello </h3>
                      <p className="mb-0">Congratulations, your application to join ChannelHub has been approved. We now need to collect some additional information about your company, yourself, your products and your target export markets. Note that a you need to reach a minimum of 65% profile strength to receive suggestions.</p>
                    </CardBody>
                  </Card>
                </Col> 
              </Row> */}
              <Row >


                <Col className="card-wrapper des-wrapper" lg="3" md="12" sm="12" >

                  <Card className="">
                    <CardBody className="pb-2">
                      <a href="#pablo" onClick={e => e.preventDefault()}>
                        <img
                          onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/default_image/contact.png"); }}
                          alt="..."
                          className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                          src={userData?.documentUrl ? userData?.documentUrl : 'http://d2maj8ifjo1lti.cloudfront.net/document/default_image/contact.png'}//{require("assets/img/theme/team-1.jpg")}
                          style={{ width: "140px" }}
                        />
                      </a>
                      <div className="pt-4 text-center">
                        <h5 className="h3 title">
                          <span className="d-block mb-1">{userData?.firstName + ' ' + userData?.lastName}</span>
                          <small className="h4 font-weight-light text-muted">
                            {state.jobTitle}
                          </small>
                        </h5>
                        <div className="mt-3">
                          <ButtonGroup className='res-link' aria-label="Basic example" role="group">
                            {state.linkedIn &&
                              <a href={state.linkedIn} target="_blank">
                                <Button style={{ padding: "0.7rem" }} className="active linkedin mt-1 mb-1" type="button">
                                  <span className="btn-inner--icon mr-1">
                                    <i style={{ color: "white" }} class="fab fa-linkedin-in" aria-hidden="true"></i>
                                  </span>
                                </Button></a>}
                            {/* <Button className="active linkedin mt-1 mb-1" type="button" >
                            <span className="btn-inner--text">SHARE</span>
                          </Button> */}
                          </ButtonGroup>
                        </div>
                      </div>
                      {userData.isOtherUser == null && <Row>
                        <div className="col">
                          <div className="card-profile-stats d-flex justify-content-center">
                            <div className="pointer" onClick={() => history.push('/admin/channel')}>
                              <span className="heading">{state.leads}</span>
                              <span className="description">Suggestion</span>
                            </div>
                            <div className="pointer" onClick={() => history.push({ pathname: '/admin/profileView/', state: { profileViews: state.profileViews, unknownProfileViews: state.unknownProfileViews, viewId: '1' } })}>
                              <span className="heading"> {state.profileViews} </span>
                              <span className="description">Profile Views</span>
                            </div>
                            <div className="pointer" onClick={() => history.push({ pathname: '/admin/FollowerView/', state: { viewId: '3' } })}>
                              <span className="heading">{state.favorites}</span>
                              <span className="description">Followers</span>
                            </div>
                          </div>
                        </div>
                      </Row>}
                      {/* <Row>
                    <div className="col-12 pl-0">
                    <span className="heading text-muted d-block">Name</span>
                          <span className="newdesc d-block mb-2">Ryan Tompson</span>
                    </div>
                    <div className="col-12 pl-0">
                    <span className="heading text-muted d-block">Email</span>
                          <span className="newdesc d-block mb-2">Tompson@email.com</span>
                    </div>
                  </Row> */}
                    </CardBody>
                  </Card>

                  {/* <Card>
              <CardHeader className="pt-3 pb-2">
                <Row>
                  <Col xs="8">
                    <h3  className="mb-2 text-left">Activity</h3>
                  </Col>
                  <Col xs="4">
                    <UncontrolledDropdown className="p-0" group>
                      <DropdownToggle caret className="p-1">
                        <p  className="mb-2 text-left" style={{ display: "inline-block" ,lineHeight:'0.7'}} >
                        {activity}       
                        </p>
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem onClick={e => handleChange('Today')}>Today</DropdownItem>
                        <DropdownItem onClick={e => handleChange('All')}>All</DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </Col>
                </Row>

              </CardHeader>
              <PerfectScrollbar>
              <CardBody id="newdash">
                <div
                  className="timeline timeline-one-side timelineDash"
                  data-timeline-axis-style="dashed"
                  data-timeline-content="axis"
                >
                  {state.profileList?.map(x => (
                    <div onClick={() => profileViewAccess == 'yes' ? history.push({ pathname: '/admin/profileView/', state: { viewId: '1' } }) : openplanUpgrade()} className="timeline-block">
                      <span className={"timeline-step badge-secondary " + (profileViewAccess == 'no' ? 'isConfidential' : '')} >
                
                        <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} src={x.profile_image ? process.env.REACT_APP_CDN_URL + x.profile_image : process.env.REACT_APP_CDN_URL + 'document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'} className="rounded-circle" style={{ width: '50px' }}/>
                      </span>
                      <div className={"timeline-content"} >
                        
                        <h5 className={"mt-1 mb-0 " + (profileViewAccess == 'no' ? 'isConfidential' : '')}>{x.name}</h5>
                        <span className={"text-sm mt-1 mb-0 " + (profileViewAccess == 'no' ? 'isConfidential' : '')}>
                        "{x.name}" of "{x.companyName}" </span><span className="text-sm mt-1 mb-0"> visited your company profile
                        </span>

                      </div>
                    </div>
                  ))}
                </div>
              </CardBody>
              </PerfectScrollbar>
            </Card> */}

                  <Card>
                    <CardHeader style={{ fontWeight: '600' }}>
                      Latest News
                    </CardHeader>
                    <PerfectScrollbar>
                      <CardBody id='lstnews'>

                        {state.news?.map(x =>
                          <div class="media-comment media pb-2">
                            <img alt="..." class="avatar avatar-lg media-comment-avatar rounded-circle" src={"https://static.wixstatic.com/media/" + x.coverImage.split("/")[3]} />
                            <a href={`https://www.channel-summit.com/post/${x.slug}`} target="_blank">
                              <div class="media">
                                <div class="media-comment-text w-100">
                                  <h6 class="h5 mt-0">{x.title + " by " + x.author.nickname}</h6>
                                  <div className="NewsContainer">
                                    <p class="text-sm lh-160 w-100">{x.plainContent}</p>
                                  </div>
                                  <div class="icon-actions mt-2">
                                    <a class="like active" href="#pablo">
                                      <i class="far fa-heart"></i>
                                      <span class="text-muted">{x.likeCount}</span></a>
                                    <a href="#pablo"><i class="far fa-comment-alt"></i>
                                      <span class="text-muted">{x.commentCount}</span></a>
                                    <span class="text-muted">{new Date(x.publishedDate).toString().substring(3, 15) + " - " + x.timeToRead + "min"}</span>
                                  </div></div></div></a></div>
                        )}


                      </CardBody>
                    </PerfectScrollbar>
                  </Card>
                  {/* <Card>
              <CardHeader>
                    Latest Vendors
              </CardHeader>
              <PerfectScrollbar>
                        <CardBody id='lstven'>
                        <div
                  className="timeline timeline-one-side timelineDash"
                  data-timeline-axis-style="dashed"
                  data-timeline-content="axis"
                >
                    <div style={{ margin: '1em 0' }} className="timeline-block">
                      <span className="timeline-step  timeline-step1 badge-secondary " >
                
                        <img src={require("assets/img/theme/team-1.jpg")} className="rounded-circle" style={{ width: '50px' }}/>
                      </span>
                      <div className={"timeline-content"} >
                        
                        <h5 className={"mt-1 mb-0 " }>Ryan Tompson</h5>
                        <span className={"text-sm mt-1 mb-0 "}>
                        Ryan Tompson of Tompson </span><span className="text-sm mt-1 mb-0"> visited your company profile
                        </span>

                      </div>
                    </div>
                  
                    <div style={{ margin: '1em 0' }} className="timeline-block">
                      <span className={"timeline-step badge-secondary " }>
                       
                        <img src={require("assets/img/theme/team-1.jpg")} className="rounded-circle" style={{ width: '50px' }} alt="..." />
                      </span>
                      <div className="timeline-content">
                
                        <h5 className={"mt-1 mb-0 "}>Ryan Tompson</h5>
                        <span className={"text-sm mt-1 mb-0 "}>
                        Ryan Tompson of Tompson visited your company product 
                        </span>
                        <span className="text-sm mt-1 mb-0"> visited your company product </span>
                        <span className={"text-sm mt-1 mb-0 "}>
                         Tompson
                        </span>
                      </div>
                    </div>

                    <div style={{ margin: '1em 0' }} className="timeline-block">
                      <span className={"timeline-step badge-secondary " }>
                       
                        <img src={require("assets/img/theme/team-1.jpg")} className="rounded-circle" style={{ width: '50px' }} alt="..." />
                      </span>
                      <div className="timeline-content">
                
                        <h5 className={"mt-1 mb-0 "}>Ryan Tompson</h5>
                        <span className={"text-sm mt-1 mb-0 "}>
                        Ryan Tompson of Tompson visited your company product 
                        </span>
                        <span className="text-sm mt-1 mb-0"> visited your company product </span>
                        <span className={"text-sm mt-1 mb-0 "}>
                         Tompson
                        </span>
                      </div>
                    </div>
      
                    <div style={{ margin: '1em 0' }} className="timeline-block">
                      <span className={"timeline-step badge-secondary " }>
                       
                        <img src={require("assets/img/theme/team-1.jpg")} className="rounded-circle" style={{ width: '50px' }} alt="..." />
                      </span>
                      <div className="timeline-content">
                
                        <h5 className={"mt-1 mb-0 "}>Ryan Tompson</h5>
                        <span className={"text-sm mt-1 mb-0 "}>
                        Ryan Tompson of Tompson visited your company product 
                        </span>
                        <span className="text-sm mt-1 mb-0"> visited your company product </span>
                        <span className={"text-sm mt-1 mb-0 "}>
                         Tompson
                        </span>
                      </div>
                    </div>
                </div>
                        </CardBody>
                      </PerfectScrollbar> 
            </Card> */}
                </Col>

                <Col lg="9" md="12" sm="12" >


                  <Col className="p-0" lg="12" md="12" sm="12" >
                    <div class="row">
                      <Col lg='12' className='p-0'>
                        <Card className='desk-event'>
                          <div className='card-overlaydash'></div>
                          <CardBody id='upEve'>
                            <h1 className="pb-3 pt-3">Channelhub Latest Event</h1>
                            {state.eventData.length > 0 && <DashEventlist eventData={state.eventData} />}
                          </CardBody>
                        </Card>
                      </Col>
                      {/* <div class="col-xl-4 col-md-6 p-0">
              <div class="card card-stats">
           
                <div class="card-body">
                  <div class="row">
                    <div class="col curs-point" onClick={() => openFeaturedUrls('profileView')}>
                      <h5 class="card-title text-uppercase text-muted mb-0">Profile Views</h5>
                      <span class="h2 font-weight-bold mb-0">{state.profileViews}</span>
                    </div>
                    <div class="col-auto">
                      <div class="Counticon icon-shape bg-gradient-red text-white rounded-circle shadow">
                      <i class="fa fa-user" aria-hidden="true"></i>
                      </div>
                    </div>
                  </div>
                  <p class="mt-3 mb-0 text-sm">
                    <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> 3.48%</span>
                    <span class="text-nowrap">Since last month</span>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-md-6">
              <div class="card card-stats">
         
                <div class="card-body">
                  <div class="row">
                    <div class="col curs-point"  onClick={() => openFeaturedUrls('productViews')}>
                      <h5 class="card-title text-uppercase text-muted mb-0">Product Views</h5>
                      <span class="h2 font-weight-bold mb-0">{state.productViews}</span>
                    </div>
                    <div class="col-auto">
                      <div class="Counticon icon-shape bg-gradient-orange text-white rounded-circle shadow">
                      <i class="fab fa-product-hunt"></i>
                      </div>
                    </div>
                  </div>
                  <p class="mt-3 mb-0 text-sm">
                    <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> 3.48%</span>
                    <span class="text-nowrap">Since last month</span>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-md-6">
              <div class="card card-stats">
              
                <div class="card-body">
                  <div class="row">
                    <div class="col curs-point" onClick={() => history.push({ pathname: '/admin/settings', state: { dashboard: 'balance' } })}>
                      <h5 class="card-title text-uppercase text-muted mb-0">Balance</h5>
                      <span class="h2 font-weight-bold mb-0"><span>€</span>{state.balance}</span>
                    </div>
                    <div class="col-auto">
                      <div class="Counticon icon-shape bg-gradient-green text-white rounded-circle shadow">
                      <i class="fas fa-money-bill-alt"></i>
                      </div>
                    </div>
                  </div>
                  <p class="mt-3 mb-0 text-sm">
                    <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> 3.48%</span>
                    <span class="text-nowrap">Since last month</span>
                  </p>
                </div>
              </div>
            </div> */}
                    </div>
                    <ProfileViewConnect isOpen={connect} handleClose={closePopup} title={title} />

                    <Col className="p-0" lg="12" md="12" sm="12" >
                      <Card className="p-0">
                        <CardHeader className="pt-3 pb-2">

                          <Row className="align-items-center">
                            <div className="col">
                              <h3 className="mb-2 text-left">Complete Your Profile </h3>
                            </div>
                            <div className="col-auto">
                              <Button className="btn-sm" color="primary" outline type="button" onClick={() => history.push('/admin/channel/channelDetail/' + userData.channelId)}>
                                Increase Your Profile Strength
                              </Button>
                            </div>
                          </Row>
                        </CardHeader>
                        <PerfectScrollbar>
                          <CardBody>
                            <div className="row ">
                              <div className="col-lg-3" style={{ alignSelf: "center" }}>
                                {/* <img src="https://icon-library.com/images/secure-icon-png/secure-icon-png-27.jpg"/> */}
                                <ProggressBar value={state.profileScore} />
                              </div>
                              <div className="col-lg-1"></div>
                              <div className="col-lg-8">

                                <div>
                                  <Progress
                                    max="100"
                                    value={state.profileScore}
                                    color="success"
                                  />
                                  <span className="completion mr-2 h4 text-muted">Your profile completion is at<span style={{ fontWeight: 900, fontSize: "1.2rem", marginLeft: '0.5rem' }}>{state.profileScore}%</span> </span>
                                </div>

                                <div
                                  className="timeline timeline-one-side dashtime ml-3"
                                  data-timeline-axis-style="solid"
                                  data-timeline-content="axis"
                                >
                                  <div className="timeline-block mt-0 mb-0">
                                    <span className="timeline-step dashtimestep badge-success">
                                      <i class="fas fa-check"></i>
                                    </span>
                                    <div className="timeline-content ml-3">
                                      <h5 className="mt-4 mb-0 curs-point" onClick={() => history.push('/admin/channel/channelDetail/' + userData.channelId)}>Mandatory fields updated: 65%
                                        {/* <i id="accont" class="fa fa-exclamation-circle" aria-hidden="true"></i> */}
                                      </h5> 
                                       {/* {state?.profileScore < 100 && <UncontrolledTooltip delay={0} placement="bottom" target="accont">Account Created</UncontrolledTooltip>} */}
                                    </div>
                                  </div>

                                  {/* for dist */}
                                  {userData.channelTypeId == 3 &&
                                    <div className="timeline-block mt-0 mb-0">
                                      <span className={`timeline-step dashtimestep ${(state.profileMetrics.length > 0 && +state.profileMetrics[0].keypartners) ? 'badge-success' : 'badge-danger'}`}>
                                        {(state.profileMetrics.length > 0 && +state.profileMetrics[0].keypartners) ? <i class="fas fa-check"></i> : <i class="fas fa-times"></i>}
                                      </span>
                                      <div className="timeline-content ml-3">
                                        {/* <h5 className="mt-4 mb-0 curs-point" onClick={() => { userData.channelTypeId == 3 ? history.push('/admin/companyprofiledist/') : history.push('/admin/companyprofileretres/') }}>Add your Key retailer or resellers customers: 10%  */}
                                        <h5 className="mt-4 mb-0 curs-point">Add your Key retailer or resellers customers: 10%
                                          {(state.profileMetrics.length > 0 && +state.profileMetrics[0].keypartners) ? <></> :
                                            <i id="accont" onClick={() => openEdit("retail")} class="fas fa-pen ml-2" aria-hidden="true"></i>}
                                        </h5>
                                      </div>
                                    </div>
                                  }

                                  {/* for retailter */}
                                  {(userData.channelTypeId == 4 && (userData.businessType == 1 || userData.businessType == 3)) &&
                                    <div className="timeline-block mt-0 mb-0">
                                      <span className={`timeline-step dashtimestep ${(state.profileMetrics.length > 0 && +state.profileMetrics[0].physicalSales) ? 'badge-success' : 'badge-danger'}`}>
                                        {(state.profileMetrics.length > 0 && +state.profileMetrics[0].physicalSales) ? <i class="fas fa-check"></i> : <i class="fas fa-times"></i>}
                                      </span>
                                      <div className="timeline-content ml-3">
                                        {/* <h5 className="mt-4 mb-0 curs-point" onClick={() => { userData.channelTypeId == 3 ? history.push('/admin/companyprofiledist/') : history.push('/admin/companyprofileretres/') }}>Do you have point of sales: 10% <i id="accont" class="fas fa-pen ml-2" aria-hidden="true"></i> */}
                                        <h5 className="mt-4 mb-0 curs-point">Do you have point of sales: 10%
                                          {(state.profileMetrics.length > 0 && +state.profileMetrics[0].physicalSales) ? <></> :
                                            <i id="accont" onClick={() => openEdit("salespoint")} class="fas fa-pen ml-2" aria-hidden="true"></i>}
                                        </h5>
                                      </div>
                                    </div>
                                  }

                                  {/* for reseller */}
                                  {/* {(userData.channelTypeId == 5 && (userData.businessType == 2 || userData.businessType == 3)) &&
                                    <div className="timeline-block mt-0 mb-0">
                                      <span className={`timeline-step dashtimestep ${(state.profileMetrics.length > 0 && +state.profileMetrics[0].physicalSales) ? 'badge-success' : 'badge-danger'}`}>
                                        {(state.profileMetrics.length > 0 && +state.profileMetrics[0].physicalSales) ? <i class="fas fa-check"></i> : <i class="fas fa-times"></i>}
                                      </span>
                                      <div className="timeline-content ml-3"> */}
                                  {/* <h5 className="mt-4 mb-0 curs-point" onClick={() => { userData.channelTypeId == 3 ? history.push('/admin/companyprofiledist/') : history.push('/admin/companyprofileretres/') }}>Profile of your end-customers: 10% <i id="accont" class="fas fa-pen ml-2" aria-hidden="true"></i> */}
                                  {/* <h5 className="mt-4 mb-0 curs-point">Profile of your end-customers: 10%
                                          {(state.profileMetrics.length > 0 && +state.profileMetrics[0].endCustomer) ? <></> :
                                            <i id="accont" onClick={() => openEdit("endcust")} class="fas fa-pen ml-2" aria-hidden="true"></i>}
                                        </h5>
                                      </div>
                                    </div>
                                  } */}


                                  {/* for dist & vendor */}
                                  {(userData.channelTypeId == 3 || userData.isOtherUser == null ) &&
                                    <div className="timeline-block mt-0 mb-0">
                                      <span className={`timeline-step dashtimestep ${(state.profileMetrics.length > 0 && +state.profileMetrics[0].channellinkedurl === 1) ? 'badge-success' : 'badge-danger'}`}>
                                        {(state.profileMetrics.length > 0 && +state.profileMetrics[0].channellinkedurl === 1) ? <i class="fas fa-check"></i> : <i class="fas fa-times"></i>}
                                      </span>
                                      <div className="timeline-content ml-3">
                                        {/* <h5 className="mt-4 mb-0 curs-point" onClick={() => { userData.channelTypeId == 3 ? history.push('/admin/companyprofiledist/') : history.push('/admin/companyprofileretres/') }}>Add your company Linkedin url: 3% <i id="accont" class="fas fa-pen ml-2" aria-hidden="true"></i> */}
                                        <h5 className="mt-4 mb-0">Add your Company Linkedin url: 3%
                                          {(state.profileMetrics.length > 0 && +state.profileMetrics[0].channellinkedurl === 1) ? <></> :
                                            <i onClick={() => openEdit("linkIn")} id="accont" class="fas fa-pen ml-2 curs-point" aria-hidden="true"></i>}
                                        </h5>
                                      </div>
                                    </div>}

                                  {/* for all */}
                                  { userData.isOtherUser == null  &&
                                  <div className="timeline-block mt-0 mb-0">
                                    <span className={`timeline-step dashtimestep ${(state.profileMetrics.length > 0 && state.profileMetrics[0].userlinkedurl) ? 'badge-success' : 'badge-danger'}`}>
                                      {(state.profileMetrics.length > 0 && state.profileMetrics[0].userlinkedurl) ? <i class="fas fa-check"></i> : <i class="fas fa-times"></i>}
                                    </span>
                                    <div className="timeline-content ml-3">
                                      {/* <h5 className="mt-4 mb-0 curs-point" onClick={() => history.push('/admin/profileform/')}>Add your personal Linkedin url: 5% <i id="accont" class="fas fa-pen ml-2" aria-hidden="true"></i> */}
                                      <h5 className="mt-4 mb-0">Add your personal Linkedin url: 5%
                                        {(state.profileMetrics.length > 0 && state.profileMetrics[0].userlinkedurl) ? <></> :
                                          <i id="accont" onClick={() => openEdit("uslinkIn")} class="fas fa-pen ml-2 curs-point" aria-hidden="true"></i>}
                                      </h5>
                                    </div>
                                  </div>
      }


                                  {/* for all */}
                                  { userData.isOtherUser == null  &&
                                  <div className="timeline-block mt-0 mb-0">
                                    <span className={`timeline-step dashtimestep ${(state.profileMetrics.length > 0 && state.profileMetrics[0].contact_picture) ? 'badge-success' : 'badge-danger'}`}>
                                      {(state.profileMetrics.length > 0 && state.profileMetrics[0].contact_picture) ? <i class="fas fa-check"></i> : <i class="fas fa-times"></i>}
                                    </span>
                                    <div className="timeline-content ml-3">
                                      {/* <h5 className="mt-4 mb-0 curs-point" onClick={() => history.push('/admin/profileform/')}>Add your picture: 5% <i id="accont" class="fas fa-pen ml-2" aria-hidden="true"></i> */}
                                      <h5 className="mt-4 mb-0">Add your picture: 5%
                                        {(state.profileMetrics.length > 0 && state.profileMetrics[0].contact_picture) ? <></> :
                                          <i onClick={() => openEdit("uspic")} id="accont" class="fas fa-pen ml-2 curs-point" aria-hidden="true"></i>}
                                      </h5>
                                    </div>
                                  </div>
      }

                                  {/* for all */}
                                  { userData.isOtherUser == null  &&
                                  <div className="timeline-block mt-0 mb-0">
                                    <span className={`timeline-step dashtimestep ${(state.profileMetrics.length > 0 && state.profileMetrics[0].introvideoId) ? 'badge-success' : 'badge-danger'}`}>
                                      {(state.profileMetrics.length > 0 && state.profileMetrics[0].introvideoId) ? <i class="fas fa-check"></i> : <i class="fas fa-times"></i>}
                                    </span>
                                    <div className="timeline-content ml-3">
                                      {/* <h5 className="mt-4 mb-0 curs-point" onClick={() => { userData.channelTypeId == 3 ? history.push('/admin/companyprofiledist/') : history.push('/admin/companyprofileretres/') }}>Add a short video: 5%<i id="accont" class="fas fa-pen ml-2" aria-hidden="true"></i> */}
                                      <h5 className="mt-4 mb-0">Add a short video: 5%
                                        {(state.profileMetrics.length > 0 && state.profileMetrics[0].introvideoId) ? <></> :
                                          <i onClick={() => openEdit("intro")} id="accont" class="fas fa-pen ml-2 curs-point" aria-hidden="true"></i>}
                                      </h5>
                                    </div>
                                  </div>
      }

                                  {/* for vendor, retailer & reseller */}
                                 
                                  {(userData.channelTypeId != 3 && userData.isOtherUser == null ) &&
                                    <div className="timeline-block mt-0 mb-0">
                                      <span className={`timeline-step dashtimestep ${(state.profileMetrics.length > 0 && +state.profileMetrics[0].company_picture) ? 'badge-success' : 'badge-danger'}`}>
                                        {(state.profileMetrics.length > 0 && +state.profileMetrics[0].company_picture) ? <i class="fas fa-check"></i> : <i class="fas fa-times"></i>}
                                      </span>
                                      <div className="timeline-content ml-3">
                                        {/* <h5 className="mt-4 mb-0 curs-point" onClick={() => { userData.channelTypeId == 3 ? history.push('/admin/companyprofiledist/') : history.push('/admin/companyprofileretres/') }}>Add company pictures: 5%<i id="accont" class="fas fa-pen ml-2" aria-hidden="true"></i> */}
                                        <h5 className="mt-4 mb-0">Add company pictures: 5%
                                          {(state.profileMetrics.length > 0 && +state.profileMetrics[0].company_picture) ? <></> :
                                            <i id="accont" onClick={() => openEdit("comppic")} class="fas fa-pen ml-2 curs-point" aria-hidden="true"></i>}
                                        </h5>
                                      </div>
                                    </div>}
    

                                  {/* for all */}
                                  <div className="timeline-block mt-0 mb-0">
                                    <span className={`timeline-step dashtimestep ${(state.profileMetrics.length > 0 && (state.profileMetrics[0].bannerURL || state.profileMetrics[0].channelBannerId)) ? 'badge-success' : 'badge-danger'}`}>
                                      {(state.profileMetrics.length > 0 && (state.profileMetrics[0].bannerURL || state.profileMetrics[0].channelBannerId)) ? <i class="fas fa-check"></i> : <i class="fas fa-times"></i>}
                                    </span>
                                    <div className="timeline-content ml-3">
                                      {/* <h5 className="mt-4 mb-0 curs-point" onClick={() => { userData.channelTypeId == 3 ? history.push('/admin/companyprofiledist/') : history.push('/admin/companyprofileretres/') }}>Add a banner to your public profile: 5%<i id="accont" class="fas fa-pen ml-2" aria-hidden="true"></i> */}
                                      <h5 className="mt-4 mb-0">Add a banner to your public profile: {userData.isOtherUser == '1' ?'10%':'5%'}
                                        {(state.profileMetrics.length > 0 && (state.profileMetrics[0].bannerURL || state.profileMetrics[0].channelBannerId)) ? <></> :
                                          <i id="accont" onClick={() => openEdit("banner")} class="fas fa-pen ml-2 curs-point" aria-hidden="true"></i>}
                                      </h5>
                                    </div>
                                  </div>

                                  {/* for dist & vendor */}
                                  { (userData.channelTypeId == 3 || userData.isOtherUser == '1' ) &&
                                    <div className="timeline-block mt-0 mb-0">
                                      <span className={`timeline-step dashtimestep ${(state.profileMetrics.length > 0 && +state.profileMetrics[0].document) ? 'badge-success' : 'badge-danger'}`}>
                                        {(state.profileMetrics.length > 0 && +state.profileMetrics[0].document) ? <i class="fas fa-check"></i> : <i class="fas fa-times"></i>}
                                      </span>
                                      <div className="timeline-content ml-3">
                                        {/* <h5 className="mt-4 mb-0 curs-point" onClick={() => { userData.channelTypeId == 3 ? history.push('/admin/companyprofiledist/') : history.push('/admin/companyprofileretres/') }}>Add official document: 2% <i id="accont" class="fas fa-pen ml-2" aria-hidden="true"></i> */}
                                        <h5 className="mt-4 mb-0">Add official document: {userData.isOtherUser == '1' ?'10%':'2%'}
                                          {(state.profileMetrics.length > 0 && +state.profileMetrics[0].document) ? <></> :
                                            <i onClick={() => openEdit("ofcdoc")} id="accont" class="fas fa-pen ml-2 curs-point" aria-hidden="true"></i>}
                                        </h5>
                                      </div>
                                    </div>}

                                </div>
                              </div>
                            </div>

                          </CardBody>
                        </PerfectScrollbar>
                      </Card>
                    </Col>


                  </Col>
                  <div className="card-wrapper res-wrapper"  >

                    <Card className="">
                      <CardBody className="pb-2">
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          <img
                            onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/default_image/contact.png"); }}
                            alt="..."
                            className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                            src={userData?.documentUrl ? userData?.documentUrl : 'http://d2maj8ifjo1lti.cloudfront.net/document/default_image/contact.png'}//{require("assets/img/theme/team-1.jpg")}
                            style={{ width: "140px" }}
                          />
                        </a>
                        <div className="pt-4 text-center">
                          <h5 className="h3 title">
                            <span className="d-block mb-1">{userData?.firstName + ' ' + userData?.lastName}</span>
                            <small className="h4 font-weight-light text-muted">
                              {state.jobTitle}
                            </small>
                          </h5>
                          <div className="mt-3">
                            <ButtonGroup className='res-link' aria-label="Basic example" role="group">
                              {state.linkedIn &&
                                <a href={state.linkedIn} target="_blank">
                                  <Button style={{ padding: "0.7rem" }} className="active linkedin mt-1 mb-1" type="button">
                                    <span className="btn-inner--icon mr-1">
                                      <i style={{ color: "white" }} class="fab fa-linkedin-in" aria-hidden="true"></i>
                                    </span>
                                  </Button></a>}
                              {/* <Button className="active linkedin mt-1 mb-1" type="button" >
                                 <span className="btn-inner--text">SHARE</span>
                               </Button> */}
                            </ButtonGroup>
                          </div>
                        </div>
                        <Row>
                          <div className="col">
                            <div className="card-profile-stats d-flex justify-content-center">
                              <div className="pointer" onClick={() => history.push('/admin/channel')}>
                                <span className="heading">{state.leads}</span>
                                <span className="description">Suggestion</span>
                              </div>
                              <div className="pointer" onClick={() => history.push({ pathname: '/admin/profileView/', state: { profileViews: state.profileViews, unknownProfileViews: state.unknownProfileViews, viewId: '1' } })}>
                                <span className="heading"> {state.profileViews} </span>
                                <span className="description">Profile Views</span>
                              </div>
                              <div className="pointer" onClick={() => history.push({ pathname: '/admin/FollowerView/', state: { viewId: '3' } })}>
                                <span className="heading">{state.favorites}</span>
                                <span className="description">Followers</span>
                              </div>
                            </div>
                          </div>
                        </Row>
                        {/* <Row>
                         <div className="col-12 pl-0">
                         <span className="heading text-muted d-block">Name</span>
                               <span className="newdesc d-block mb-2">Ryan Tompson</span>
                         </div>
                         <div className="col-12 pl-0">
                         <span className="heading text-muted d-block">Email</span>
                               <span className="newdesc d-block mb-2">Tompson@email.com</span>
                         </div>
                       </Row> */}
                      </CardBody>
                    </Card>

                    {/* <Card>
                   <CardHeader className="pt-3 pb-2">
                     <Row>
                       <Col xs="8">
                         <h3  className="mb-2 text-left">Activity</h3>
                       </Col>
                       <Col xs="4">
                         <UncontrolledDropdown className="p-0" group>
                           <DropdownToggle caret className="p-1">
                             <p  className="mb-2 text-left" style={{ display: "inline-block" ,lineHeight:'0.7'}} >
                             {activity}       
                             </p>
                           </DropdownToggle>
                           <DropdownMenu>
                             <DropdownItem onClick={e => handleChange('Today')}>Today</DropdownItem>
                             <DropdownItem onClick={e => handleChange('All')}>All</DropdownItem>
                           </DropdownMenu>
                         </UncontrolledDropdown>
                       </Col>
                     </Row>
     
                   </CardHeader>
                   <PerfectScrollbar>
                   <CardBody id="newdash">
                     <div
                       className="timeline timeline-one-side timelineDash"
                       data-timeline-axis-style="dashed"
                       data-timeline-content="axis"
                     >
                       {state.profileList?.map(x => (
                         <div onClick={() => profileViewAccess == 'yes' ? history.push({ pathname: '/admin/profileView/', state: { viewId: '1' } }) : openplanUpgrade()} className="timeline-block">
                           <span className={"timeline-step badge-secondary " + (profileViewAccess == 'no' ? 'isConfidential' : '')} >
                     
                             <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} src={x.profile_image ? process.env.REACT_APP_CDN_URL + x.profile_image : process.env.REACT_APP_CDN_URL + 'document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'} className="rounded-circle" style={{ width: '50px' }}/>
                           </span>
                           <div className={"timeline-content"} >
                             
                             <h5 className={"mt-1 mb-0 " + (profileViewAccess == 'no' ? 'isConfidential' : '')}>{x.name}</h5>
                             <span className={"text-sm mt-1 mb-0 " + (profileViewAccess == 'no' ? 'isConfidential' : '')}>
                             "{x.name}" of "{x.companyName}" </span><span className="text-sm mt-1 mb-0"> visited your company profile
                             </span>
     
                           </div>
                         </div>
                       ))}
                     </div>
                   </CardBody>
                   </PerfectScrollbar>
                 </Card> */}

                    <Card>
                      <CardHeader style={{ fontWeight: '600' }}>
                        Latest News
                      </CardHeader>
                      <PerfectScrollbar>
                        <CardBody id='lstnews'>

                          {state.news?.map(x =>
                            <div class="media-comment media pb-2">
                              <img alt="..." class="avatar avatar-lg media-comment-avatar rounded-circle" src={"https://static.wixstatic.com/media/" + x.coverImage.split("/")[3]} />
                              <a href={`https://www.channel-summit.com/post/${x.slug}`} target="_blank">
                                <div class="media">
                                  <div class="media-comment-text w-100">
                                    <h6 class="h5 mt-0">{x.title + " by " + x.author.nickname}</h6>
                                    <div className="NewsContainer">
                                      <p class="text-sm lh-160 w-100">{x.plainContent}</p>
                                    </div>
                                    <div class="icon-actions mt-2">
                                      <a class="like active" href="#pablo">
                                        <i class="far fa-heart"></i>
                                        <span class="text-muted">{x.likeCount}</span></a>
                                      <a href="#pablo"><i class="far fa-comment-alt"></i>
                                        <span class="text-muted">{x.commentCount}</span></a>
                                      <span class="text-muted">{new Date(x.publishedDate).toString().substring(3, 15) + " - " + x.timeToRead + "min"}</span>
                                    </div></div></div></a></div>
                          )}


                        </CardBody>
                      </PerfectScrollbar>
                    </Card>
                    {/* <Card>
                   <CardHeader>
                         Latest Vendors
                   </CardHeader>
                   <PerfectScrollbar>
                             <CardBody id='lstven'>
                             <div
                       className="timeline timeline-one-side timelineDash"
                       data-timeline-axis-style="dashed"
                       data-timeline-content="axis"
                     >
                         <div style={{ margin: '1em 0' }} className="timeline-block">
                           <span className="timeline-step  timeline-step1 badge-secondary " >
                     
                             <img src={require("assets/img/theme/team-1.jpg")} className="rounded-circle" style={{ width: '50px' }}/>
                           </span>
                           <div className={"timeline-content"} >
                             
                             <h5 className={"mt-1 mb-0 " }>Ryan Tompson</h5>
                             <span className={"text-sm mt-1 mb-0 "}>
                             Ryan Tompson of Tompson </span><span className="text-sm mt-1 mb-0"> visited your company profile
                             </span>
     
                           </div>
                         </div>
                       
                         <div style={{ margin: '1em 0' }} className="timeline-block">
                           <span className={"timeline-step badge-secondary " }>
                            
                             <img src={require("assets/img/theme/team-1.jpg")} className="rounded-circle" style={{ width: '50px' }} alt="..." />
                           </span>
                           <div className="timeline-content">
                     
                             <h5 className={"mt-1 mb-0 "}>Ryan Tompson</h5>
                             <span className={"text-sm mt-1 mb-0 "}>
                             Ryan Tompson of Tompson visited your company product 
                             </span>
                             <span className="text-sm mt-1 mb-0"> visited your company product </span>
                             <span className={"text-sm mt-1 mb-0 "}>
                              Tompson
                             </span>
                           </div>
                         </div>
     
                         <div style={{ margin: '1em 0' }} className="timeline-block">
                           <span className={"timeline-step badge-secondary " }>
                            
                             <img src={require("assets/img/theme/team-1.jpg")} className="rounded-circle" style={{ width: '50px' }} alt="..." />
                           </span>
                           <div className="timeline-content">
                     
                             <h5 className={"mt-1 mb-0 "}>Ryan Tompson</h5>
                             <span className={"text-sm mt-1 mb-0 "}>
                             Ryan Tompson of Tompson visited your company product 
                             </span>
                             <span className="text-sm mt-1 mb-0"> visited your company product </span>
                             <span className={"text-sm mt-1 mb-0 "}>
                              Tompson
                             </span>
                           </div>
                         </div>
           
                         <div style={{ margin: '1em 0' }} className="timeline-block">
                           <span className={"timeline-step badge-secondary " }>
                            
                             <img src={require("assets/img/theme/team-1.jpg")} className="rounded-circle" style={{ width: '50px' }} alt="..." />
                           </span>
                           <div className="timeline-content">
                     
                             <h5 className={"mt-1 mb-0 "}>Ryan Tompson</h5>
                             <span className={"text-sm mt-1 mb-0 "}>
                             Ryan Tompson of Tompson visited your company product 
                             </span>
                             <span className="text-sm mt-1 mb-0"> visited your company product </span>
                             <span className={"text-sm mt-1 mb-0 "}>
                              Tompson
                             </span>
                           </div>
                         </div>
                     </div>
                             </CardBody>
                           </PerfectScrollbar> 
                 </Card> */}
                  </div>

                  <Row>
                    <Col lg='12' className='p-0'>
                      <Card>
                        <CardHeader style={{ fontWeight: '600' }}>
                          Latest Vendors
                        </CardHeader>
                        {/* <PerfectScrollbar> */}
                        <CardBody id=''>
                          <DashVenslider upgrade={upgrade} channels={state.channels} />
                        </CardBody>
                        {/* </PerfectScrollbar>                     */}
                      </Card>
                    </Col>

                    <Col lg='12' className='p-0'>
                      <Card>
                        <CardHeader style={{ fontWeight: '600' }}>
                          Latest Products
                        </CardHeader>
                        {/* <PerfectScrollbar> */}
                        <CardBody id=''>
                          <Dashslider products={state.products} />
                        </CardBody>
                        {/* </PerfectScrollbar>                     */}
                      </Card>
                    </Col>
                  </Row>
                </Col>





              </Row>
            </Container>
          </div>
        </>
      }
    </>
  );
}


export default NewDashboard;