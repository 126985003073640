import React, { useState, useEffect, useRef } from "react";
import { Card, CardBody, CardHeader, Container, Col, Row, Badge, UncontrolledTooltip, Button } from "reactstrap";
import { FormRadio, FormCheckBox, FormInput } from "_components/FormElements/FormInput";
import { InputLabel, Select, TextField, FormControl } from '@material-ui/core';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { PasswordSchema } from '../validation';
import AccountDeletion from "views/Hoc/Dialog/AccountDeletion";
import ChangeContact from "views/Hoc/Dialog/ChangeContact";
import axios from 'axios';
import { dashboardActions } from '../../../../_actions';
import { Spinner } from 'views/Hoc/Spinner';
import { history } from '../../../../_helpers';
import { FeatureListDialog } from "views/Hoc/Dialog/FeatureDialog";
import { commonService } from '_services/common.service';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import SimpleHeader from "components/Headers/SimpleHeader"
var _ = require('lodash');

const Settings = (props) => {

    const { handleSubmit } = useForm({
        mode: 'onBlur',
    });

    const { register, handleSubmit: handleSubmit1, control, errors, watch, reset } = useForm({
        mode: 'onBlur',
        resolver: yupResolver(PasswordSchema),
    });

    let typeid = JSON.parse(localStorage.getItem('CHuser')).channelTypeId;
    let businessType = JSON.parse(localStorage.getItem('CHuser')).businessType;

    let companyType = [{ id: 2, name: 'VENDOR', status: false }, { id: 3, name: 'DISTRIBUTOR', status: false }, { id: 4, name: 'RETAILER', status: false }, { id: 5, name: 'RESELLER', status: false }];

    const [changePass, setChangePass] = useState(false);

    let watchAll = watch(['currentPass', 'newPassword', 'rePassword']);

    if (typeid == 2) {
        if (businessType == 1)
            companyType = [{ id: 3, name: 'DISTRIBUTOR', status: true }, { id: 4, name: 'RETAILER', status: true }];
        if (businessType == 2)
            companyType = [{ id: 3, name: 'DISTRIBUTOR', status: true }, { id: 5, name: 'RESELLER', status: true }];
        if (businessType == 3)
            companyType = [{ id: 3, name: 'DISTRIBUTOR', status: true }, { id: 4, name: 'RETAILER', status: true }, { id: 5, name: 'RESELLER', status: true }];
    }

    const [state, setState] = useState({
        isVisible: 'true',
        paymentOption: [],
        paymentTerm: [],
        currency: [],
        visibleOption: companyType.filter(x => x.id != typeid),
        contactName: '',
        jobTitle: '',
        country: '',
        userId: null,
        profileLogo: '',
        otherInfo: '',
        amount: '',
        selectedCurrency: '1',
        rcamount: '',
        autoRenewal: true,
        featureList: [],
        isPlanExpired: false,
        userCount: null,

        //password
        currentPass: '',
        newPassword: '',
        rePassword: ''
    });

    const myRef = useRef();

    const [accDelete, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [OpenContact, setOpenContact] = useState(false)
    const [OpenFeatureList, setOpenFeatureList] = useState(false)

    let dashboard = props.location.state?.dashboard ? props.location.state?.dashboard : '';

    const openDelete = (e) => {
        e.preventDefault();
        setOpen(true);
    }
    const closeOpen = () => {
        setOpen(false);
    }
    const openContact = (e) => {
        e.preventDefault();
        setOpenContact(true);
    }
    const closeContact = () => {
        setOpenContact(false);
    }

    const openFeatureList = (e) => {
        e.preventDefault();
        setOpenFeatureList(true);
    }
    const closeFeatureDialog = () => {
        setOpenFeatureList(false);
    }

    const setDefaultContact = async (data) => {

        await axios.post('user/setDefaultContact/', Object.assign(data, { currentOwner: state.userId })).then(async res => {
            await axios.get('user/defaultcontact').then(res => {
                setState({ ...state, userCount: res.data.userCount, userId: res.data.user.userId, contactName: res.data.user.contact.firstName + " " + res.data.user.contact.lastName, jobTitle: res.data.user.contact.jobTitle, country: res.data.user.contact.country.country, profileLogo: res.data.user.contact.document?.documentPath });
            });
        });
        setOpenContact(false)
    }

    const setDefaultFeatureDialog = input => async e => {
        var subscriptionId = JSON.parse(localStorage.getItem('CHuser')).subscriptionId;
        await axios.post('/channel/subscription/getPlansDetailsById/', { subscriptionId: +subscriptionId }).then(async res => {
            setState({ ...state, featureList: res.data });
        }).catch(err => {
            commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: '/channel/subscription/getPlansDetailsById' }, '/error');
        });
    }

    const handleChange = input => e => {
        // if(input=='amount')
        // {
        //     state.rcamount=e.target.value;
        //     setState({...state});
        // }  
        if (input == 'autoRenewal') {
            if (state.autoRenewal == false)
                state.autoRenewal = true;
            else if (state.autoRenewal == true)
                state.autoRenewal = false
            setState({ ...state });
        }
        else if (input == 'curr') {
            state.selectedCurrency = e.target.value;
            setState({ ...state });
        }
        else if (input == "L/C" || input == "T/T" || input == "D/D" || input == "Western Union" || input == "Wire Transfer" || input == "Others") {
            console.log("poption:", state.paymentOption);
            state.paymentOption.map(x => {
                if (x.paymenttype == input && x.status == false)
                    x.status = true;
                else if (x.paymenttype == input && x.status == true)
                    x.status = false;
            });
            setState({ ...state });
        }
        else if (input == "Net 30" || input == "Net 60" || input == "Cash In Advance" || input == "Cash On Delivery") {
            state.paymentTerm.map(x => {
                if (x.paymentterm == input && x.status == false)
                    x.status = true;
                else if (x.paymentterm == input && x.status == true)
                    x.status = false;
            });
            setState({ ...state });
        }
        else if (input == "VENDOR" || input == "DISTRIBUTOR" || input == "RETAILER" || input == "RESELLER") {
            state.visibleOption.map(x => {
                if (x.name == input && x.status == false)
                    x.status = true;
                else if (x.name == input && x.status == true)
                    x.status = false;
            });
            setState({ ...state });
        }
        else
            setState({ ...state, [input]: e.target.value });
    }

    //console.log("sstate:",state);

    useEffect(() => {

        try {
            setLoading(true);
            (async () => {
                await axios.get('user/defaultcontact').then(res => {
                    state.contactName = res.data.user.contact.firstName + " " + res.data.user.contact.lastName;
                    state.jobTitle = res.data.user.contact.jobTitle;
                    state.country = res.data.user.contact.country.country;
                    state.profileLogo = res.data.user.contact.document?.documentPath;
                    state.userCount = res.data.userCount;
                    state.userId = res.data.user.userId;
                }).catch(err => {
                    commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: 'user/defaultcontact' }, '/error');
                });

                await axios.get('setting/currency').then(res => {
                    console.log("payment:", res);
                    state.currency = res.data;
                }).catch(err => {
                    commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: 'setting/currency' }, '/error');
                });

                await axios.get('setting/getpaymentoption').then(async res => {
                    let data = res.data?.paymentOption; let term = res.data?.paymentTerm; let poption = []; let pterm = [];
                    let share = [];
                    await axios.get('setting/settings').then(res => {
                        let key = res.data?.paymentType?.map(x => +x.paymentoptiontypeid);
                        let key1 = res.data?.paymentTerms?.map(x => +x.paymenttermid);
                        let key2 = res.data?.share?.map(x => +x.sharetypeid + 1);
                        state.isVisible = res.data.shareStatus ? res.data.shareStatus?.toString() : state.isVisible;
                        state.plan = res.data.channelSubscription.plan;
                        state.planId = res.data.channelSubscription.planId;
                        let planId = res.data.channelSubscription.planId;
                        state.userWalletAmount = res.data.userWalletAmount;
                        state.endat = (planId === 1) ? 'Lifetime' : res.data.channelSubscription.endat;

                        var curentDate = new Date();
                        var planExpireAt = new Date(res.data.channelSubscription.endat);

                        state.isPlanExpired = (+curentDate.getTime() > +planExpireAt.getTime()) ? true : false;
                        let cur = res.data.currencyId?.toString();
                        let renewal = res.data.autoRenewalOff;

                        data.map(x => {
                            key.map(y => {
                                if (y == +x.paymentoptiontypeid)
                                    x.status = true;
                            })
                            poption.push(x);
                        });

                        term.map(x => {
                            key1.map(y => {
                                if (y == +x.paymenttermid)
                                    x.status = true;
                            })
                            pterm.push(x);
                        });

                        state.visibleOption.map(x => {
                            if (key2.filter(y => y == x.id).length)
                                x.status = true;
                            else
                                x.status = false;
                            // key2.map(y => {
                            //     if (y == +x.id)
                            //         x.status = true;
                            // })
                            share.push(x);
                        });


                        setState({ ...state, paymentOption: poption, paymentTerm: pterm, visibleOption: share, isVisible: state.isVisible, selectedCurrency: cur, otherInfo: res.data.otherOptions, autoRenewal: renewal })
                    }).catch(err => {
                        commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: 'setting/settings' }, '/error');
                    });

                }).catch(err => {
                    commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: 'setting/getpaymentoption' }, '/error');
                });

                setLoading(false);

                if (!loading && dashboard)
                    myRef.current.scrollIntoView({ behavior: 'smooth' });

            })();
        }
        catch (err) {
            //  console.log("settingerr:",err);
            // ToastsStore.error('User Product Not Found!');
            commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: 'setInitialData' }, '/error');
        }

    }, []);

    // useEffect(() => {  
    //     if(!_.isEmpty(errors)&&formState.isSubmitted)
    //     {
    //       ToastsStore.error(JSON.stringify(errors));
    //       commonService.postService({err:JSON.stringify(errors),reqUrl:window.location.pathname,service:'validation_err'},'/error')    
    //     }
    //   }, [errors])

    const save = async () => {
        try {
            setLoading(true);

            if (!state.selectedCurrency || state.selectedCurrency == 'Choose your currency') {
                throw "currency"
            }

            var userData = JSON.parse(localStorage.getItem('CHuser'));

            const paymentTerms = state.paymentTerm.reduce((a, o) => (o.status && a.push({ paymenttermid: +o.paymenttermid }), a), [])

            const paymentOption = state.paymentOption.reduce((a, o) => (o.status && a.push({ paymentoptiontypeid: +o.paymentoptiontypeid }), a), [])

            const share = state.visibleOption.reduce((a, o) => (o.status && a.push({ sharetypeid: +o.id }), a), [])

            //console.log("paymentTerms:",paymentTerms);

            let finalData = {
                channelType: +userData?.channelTypeId,
                currencyId: +state.selectedCurrency,
                // otherOptions: state.otherInfo,
                // paymentTerms: paymentTerms,
                // paymentType: paymentOption,
                //sampleRequests: [{preferencetypeid: 1}]
                share: share,
                shareStatus: (state.isVisible === 'true')
            }
            //console.log("result:",finalData);

            let result = await dashboardActions.updateSettingInfo(finalData).catch(err => {
                ToastsStore.error('Something Went Wrong!');
                setLoading(false);
                commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: 'updateSettingInfo' }, '/error');
            });

            if (result) {
                setLoading(false);
                ToastsStore.success("Saved Successfuly...");
                axios.get('algorithm/channel/' + userData.contactId);
                if (typeid != 2) {
                    await axios.get('algorithm/product/' + userData.contactId);
                    //axios.post('mailerservice/Product_Suggestion_Mail', { contactId: user.contactId, channelId: user.channelId });
                }

            }
        }
        catch (err) {
            if (err == 'currency')
                ToastsStore.error('Please Select Default Currency!');
            else
                ToastsStore.error('Something Went Wrong!');
            setLoading(false);
            commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: 'updateSettingInfo' }, '/error');
        }

    }

    const updatePassword = async () => {
        setLoading(true);

        let data = {
            password: state.currentPass,
            newPassword: state.newPassword
        }

        await axios.post('/setting/updatePassword', data).then(res => {
            if (res.data.status) {
                ToastsStore.success('Password Updated Successfully!');
                setState({ ...state })
                setChangePass(false);
                reset({ currentPass: '', rePassword: '', newPassword: '' });
            } else {
                ToastsStore.error('Current Password is wrong!');
            }
        }).catch(err => {
            ToastsStore.error('Something went wrong');
            setLoading(false);
        });

        setLoading(false);
    }

    return (
        <div className="main-content">
            <SimpleHeader location={props.match} />
            <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore} />
            {loading ? <Spinner /> : (
                <Container className="mt-4 col-lg-10 col-md-9 col-sm-12  " fluid>
                    <form key={"settings"} noValidate onSubmit={handleSubmit(save)}>

                        {/* <Card>
                   <CardHeader>
                    <h3 className="text-center">Payment Options</h3>
                    <p className="text-center">Enter the various payment options you accept when trading with your channel partners</p>
                   </CardHeader>
                   <CardBody>
                   <Col lg="12">
                       <h4>Payment Type</h4>
                       <Row>
                           <Col lg="10">
                               <Row>

                            {state.paymentOption.map(x => (
                               <Col lg="2">
                               <div className="chck">
                                    <FormCheckBox
                                        id={x.paymenttype}
                                        type="checkbox"
                                        label={x.paymenttype}
                                        checked={x.status}
                                        label1={{textAlign:"justify"}}
                                        onClick={handleChange(x.paymenttype)}
                                        //register={register}
                                    />
                                </div>
                               </Col>
                               ))}                            
                               
                               </Row>
                           </Col>
                       </Row>
                       </Col>
                    <Col lg="12">
                       <h4 className="mt-4">Payment Terms</h4>
                       <Row>
                           <Col lg="10">
                               <Row>
                            {state.paymentTerm.map(x => (
                               <Col lg="3">
                               <div className="chck">
                                    <FormCheckBox
                                        id={x.paymentterm}
                                        type="checkbox"
                                        label={x.paymentterm}
                                        checked={x.status}
                                        label1={{textAlign:"justify"}}
                                        onClick={handleChange(x.paymentterm)}
                                        //register={register}
                                    />
                                </div>
                               </Col>
                            ))}

                               
                               </Row>
                           </Col>
                       </Row>
                       </Col>
                       <Row>
                            <Col lg="12">
                            <div className="form-group mt-4">            
                                <form>
                                <textarea 
                                className="settingstext1" 
                                id="Other Information" 
                                onChange={handleChange('otherInfo')}
                                rows="3" 
                                value={state.otherInfo}
                                aria-label="empty textarea"
                                placeholder="Other Information">
                                </textarea>
                                </form> 
                            </div>
                            </Col>
                       </Row>
                   </CardBody>
               </Card> */}

                        {/* <Card>
                    <CardHeader>
                    <h3 className="text-center">Stripe Details</h3>
                    <p className="text-center">Stripe is our third-party payment gateway. Add or create your stipe account for receiving direct payments samples orders you'll get through ChannelHub. ChannelHub is not taking any commission on these payments</p>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col lg="4"></Col>
                            <Col lg="4">
                            <div className="text-center">
                                <button type="submit" className="btn btn-primary stripe" >Connect with Stripe</button> 
                            </div>  
                            </Col>
                            <Col lg="4"></Col>
                        </Row>
                    </CardBody>
                </Card> */}
                        <Card>
                            {/* <CardHeader> */}
                                {/* <h3 className="text-center" ref={myRef}>E-Wallet</h3>
                                <p className="text-center">Use your e-wallet to recharge credit that you can use for various options on your account.</p> */}
                                {/* <p className="text-center">Stripe is our third-party payment gateway. Add or create your stipe account for receiving direct payments samples orders you'll get through ChannelHub. ChannelHub is not taking any commission on these payments</p> */}
                            {/* </CardHeader> */}
                            {/* <CardBody>
                                <Row>
                                    <Col lg="6">
                                        <h4>Current balance</h4>
                                        <h4> {state.userWalletAmount} <i class="fas fa-euro-sign"></i></h4>
                                    </Col>
                                    <Col lg="3">
                                       
                                    </Col>
                                    <Col lg="3" className="mt-2">
                                        <div className="text-center">
                                            <a href="#" className="ml-4" onClick={() => history.push('/admin/payment/wallet')}>
                                                <button type="submit" className="btn btn-primary stripe" >Recharge</button>
                                            </a>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody> */}
                        </Card>
                        <Card>
                            <CardHeader>
                                <h3 className="text-center">Account Settings</h3>
                                <p className="text-center">Add setting and preferences to your account.</p>
                            </CardHeader>
                            <CardBody>
                                {/* <Col lg="6">
                                    <h4 className="mt-4">Default Currency *</h4>
                                    <FormControl variant="outlined" style={{ width: "100%", marginTop: "1rem" }}>
                                        <InputLabel htmlFor="outlined-age-native-simple">Default Currency *</InputLabel>
                                        <Select
                                            native
                                            value={state.selectedCurrency}
                                            //value={state.age}
                                            onChange={handleChange('curr')}
                                            label="Default Currency *"
                                            inputProps={{
                                                name: 'Default Currency',
                                                id: 'Default Currency',
                                            }}

                                        >
                                            <option aria-label="None" value="Choose your currency" />
                                            {state.currency?.map(x => (
                                                <option value={x.currencyid}>{x.currency}</option>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col> */}
                                <Row>
                                    <Col lg="6">
                                        <h4 className="mt-4">My Subscription Plan</h4>
                                        <Badge className='outer_badge' style={{ width: "30%", height: "2rem", padding: "0.6rem 0rem" }}>
                                            {state.plan}
                                        </Badge>
                                        {((typeid == 2 && state.planId === 1) || (typeid == 2 && state.isPlanExpired == true && state.planId > 1)) ? <a href="#" className="ml-4" onClick={() => history.push('/admin/Subscription')}>Upgrade Plan</a> : ''}

                                    </Col>
                                    <Col lg="3">
                                        <h4 className="mt-4">Account Expiry Date</h4>
                                        <p>{state.endat}</p>
                                    </Col>

                                    {/* <Col lg="3">
                                
                                <a className="ml-6" href="#" onClick={e=>openFeatureList(e)} style={{color:"#30a2e4"}}>
                                    Feature Value
                                </a>
                                <FeatureListDialog closeFeatureDialog={closeFeatureDialog} Open={OpenFeatureList} setDefaultFeatureDialog={setDefaultFeatureDialog}/>

                            </Col> */}

                                </Row>
                                <Row className="account_status_btn">
                                    <Col lg="6">
                                        <h4 className="mt-4">Account Status</h4>
                                        <Badge style={{ width: "30%", height: "2rem", padding: "0.6rem", color: "#fff", backgroundColor: "#2c9db7", marginBottom: "5px" }}>
                                            ON
                                        </Badge>
                                        <button onClick={e => openDelete(e)} type="submit" className="btn btn-warning ml-4" >Delete Account</button>

                                        <AccountDeletion Open={accDelete} closeOpen={closeOpen} />


                                    </Col>
                                    <Col lg="6">
                                        <h4 className="mt-4">Auto Renewal</h4>
                                        <label className="custom-toggle custom-toggle-success">
                                            <input defaultChecked={state.autoRenewal} type="checkbox" name="status" onChange={handleChange('autoRenewal')} />
                                            <span className="custom-toggle-slider rounded-circle" data-label-off="Inactive" data-label-on="Active"></span>
                                        </label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="8">
                                        <h4 className="mt-4">Default contact person on your company profile</h4>
                                        <Row>
                                            <Col lg="2" className="pad-less">
                                                <div className="set-prof">
                                                    <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} src={state.profileLogo ? process.env.REACT_APP_CDN_URL + state.profileLogo : process.env.REACT_APP_CDN_URL + 'document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'} />
                                                </div>
                                            </Col>
                                            <Col lg="10">
                                                <h4>{state.contactName}</h4>
                                                <span>{state.jobTitle}</span><br />
                                                <span>{state.country}</span>

                                                {state.userCount > 1 &&
                                                    <a className="ml-6" href="#" onClick={e => openContact(e)} style={{ color: "#30a2e4" }}>Change</a>
                                                }

                                                <ChangeContact closeContact={closeContact} Open={OpenContact} setDefaultContact={setDefaultContact} />

                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                {/* <Row>
                                    <Col lg="6">
                                        <h4 className="mt-4">Visibility of your Company <span id='algorithm'><i class="far fa-handshake didy"></i></span>
                                            <UncontrolledTooltip className="handshak" delay={0} placement="bottom" target="algorithm">This field is included in the matching algorithm</UncontrolledTooltip></h4>
                                        <p>Do you wish to share your Public Company Profile with other Members?</p>
                                        <div className="form-group">
                                            <FormRadio
                                                name="isVisible"
                                                id="isVisible"
                                                className="text-sm mb-0"
                                                label=""
                                                options={radioGroupOptions3}
                                                control={control}
                                                value={state.isVisible}
                                                //defaultValue={state.isVisible}
                                                row
                                                onChange={handleChange('isVisible')}
                                            />

                                        </div>

                                        
                                        {typeid == 2 && (<>
                                            <p>Suggestion you wish to see</p>
                                            {state.visibleOption.map(x => (
                                                <div className="chck">
                                                    <FormCheckBox
                                                        id={x.name}
                                                        type="checkbox"
                                                        label={x.name}
                                                        checked={x.status}
                                                        label1={{ textAlign: "justify" }}
                                                        onClick={handleChange(x.name)}
                                                    //register={register}
                                                    />
                                                </div>
                                            ))}

                                        </>)}
                                    </Col>
                                </Row> */}
                                {/* <p className='mt-4 ml-3' > Manage Password <span className='curs-point' style={{color: "#30a2e4"}} onClick={() => setChangePass(prev => !prev)}>({!changePass ? 'Change' : 'Cancel'})</span></p>
                                <Row>

            {
                !changePass && <>
                <div className="col-lg-6">
            <div className="form-group">
            <FormInput
            id="Password" 
            type="password" 
            label="Password *" 
            name="Password" 
            //control={control}
            //register={register}
            //error={errors.jobposition}
            //defaultValue={jobPosition}
            value="**********"
            disabled={true}
            //onChange={handleChange('jobPosition')}
            />
            </div>
            </div>
                </>
            }
                                
        {changePass && 
        <>
        <div className="col-lg-6">
        <div className="form-group">
        <FormInput
            id="Current Password" 
            type="password" 
            label="Current Password *" 
            name="currentPass" 
            control={control}
            register={register}
            error={errors.currentPass}
            defaultValue={state.currentPass}
            value={watchAll.currentPass}
            onChange={handleChange('currentPass')}
         />
        </div>
        </div>
        <div className="col-lg-6">
        <div className="form-group">
        <FormInput
            id="New Password" 
            type="password" 
            label="New Password *" 
            name="newPassword" 
            control={control}
            register={register}
            error={errors.newPassword}
            defaultValue={state.newPassword}
            value={watchAll.newPassword}
            onChange={handleChange('newPassword')}
         />
        </div>

        <div className="form-group">
        <FormInput
            id="Confirm Password" 
            type="password" 
            label="Confirm Password *" 
            name="rePassword" 
            control={control}
            register={register}
            error={errors.rePassword}
            defaultValue={state.rePassword}
            value={watchAll.rePassword}
            onChange={handleChange('rePassword')}
         />
        </div>
        </div>

        <Button className="btn btn-primary" onClick={handleSubmit1(updatePassword)}>Update Password</Button>
        
        </>
        }
</Row> */}
                                <div className="text-center">
                                    <button type="submit" className="btn btn-primary" >Save</button>
                                </div>
                            </CardBody>
                        </Card>
                    </form>
                </Container>
            )}
        </div>
    );
};
const radioGroupOptions3 = [
    {
        value: 'true',
        label: "Yes",
    },
    {
        value: 'false',
        label: "No",
    },];

export default Settings;