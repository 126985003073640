import React, { useEffect, useMemo, useState } from "react";
import AwardVideoItemMENA from "./AwardVideoItem_MENA24";
import axios from 'axios';

import { Spinner } from "views/Hoc/Spinner";
import { SelectAutocomplete } from '_components/FormElements/FormInput';
import { useForm } from "react-hook-form";
import AwardS3VideoItem from "./AwardS3VideoItem";
import {Card,CardBody, Row,Col, CardHeader} from "reactstrap";

// import defaultSports from "./AwardsData";
//Filter list by category in React JS
export default function Event_Video_Template({ eventId }) {
    // Default Value
    const Awards = [
        { companyname: "Bang & Olufsen", productname: "BANG & OLUFSEN BEOLAB 8", embedId: "2t6rfI3fwZ8", category: "Audio", awardlogo: "/images/Audio_winner2024.png" },
        { companyname: "NutriCook", productname: "Smart Air Fryer Grill XL", embedId: "qAyaBMrmx7c", category: "Electronics and IoT", awardlogo: "/images/Electronicsiot_winner2024.png" },
        { companyname: "GAC TECHNOLOGY GROUP", productname: "HP BP900 - UST Interactive Projector", embedId: "9Gb3KnH0f_o", category: "Entertainment", awardlogo: "/images/Entertainment_winner2024.png" },
        { companyname: "Onsor Technologies", productname: "AMAD", embedId: "6E6fAcADJNk", category: "Gaming & Computing", awardlogo: "/images/GamingComputing_winner2024.png" },
        { companyname: "Manhattan", productname: "Aluminium Gas Spring Dual Monitor", embedId: "sS5vvAErNtM", category: "IT Peripherals & Accessories", awardlogo: "/images/IT Peripherals& Accessories_winner2024.png" },
        { companyname: "Riley Scooters", productname: "ULTRA-FOLDING & Battery Detachable", embedId: "1EO_4zTjFO4", category: "Lifestyle", awardlogo: "/images/Lifestyle_winner2024.png" },
        { companyname: "Belkin  International Inc.", productname: "Auto-Tracking Stand Pro with Dockkit", embedId: "SIagGepUgLY", category: "Mobile Products", awardlogo: "/images/Mobile_winner2024.png" },

    ]
    const [catList, setCatList] = useState([]);

    const [selectedCategory, setSelectedCategory] = useState();
    const { control } = useForm({ mode: 'onBlur' });
    const [loading, setLoading] = useState(false);
    const [catArr, setCatArr] = useState([]);

    const TemplateData = async () => {
        await axios.get('/channelAdmin/Video_Template/' + eventId).then(res => {
            if (res.data.length) {
                setCatList(res.data);
                let catOption = []
                let checkArr = []
                res.data.map((x)=>{
                    if (checkArr.indexOf(x.category) === -1 && x.category !== '')
                    {   checkArr.push(x.category);
                        catOption.push({'name':x.category}) 
                    } 
                    
                });
                catOption = catOption.filter(function(item, pos, self) {
                    
                    return self.indexOf(item) == pos;
                }) 
                setCatArr(catOption)
            }
        });
        
    }

    useEffect(() => {

        (async () => {
            setLoading(true);
            await TemplateData();
            setLoading(false);
        })();


    }, [])

    // Function to get filtered list
    function getFilteredList() {
        // Avoid filter when selectedCategory is null
        if (!selectedCategory) {
            return catList;
        }
        let filteredItem = catList.filter((item) => item.category === selectedCategory);
        console.log('filteredItem : ', filteredItem)
       
        filteredItem.sort((a, b) => a.order - b.order);
        console.log('filteredItem : ', filteredItem)
        return filteredItem
    }

    // Avoid duplicate function calls with useMemo
    var filteredList = useMemo(getFilteredList, [selectedCategory, catList]);

    // const handleCategoryChange = (input, value) => {
    //     //console.log('event : ', input, value);
    //     //setSelectedCategory(event.target.value);
    // }
    const handleCategoryChange = (input, value) => {
        console.log(input, value)
        if(!value?.name){
            setSelectedCategory(null);
        } else {
            setSelectedCategory(value.name);
        }
        
    }

    const changeVideo = (e) => {
        
    }
// console.log('catArr : ', catArr);
// filteredCatItem = [];
catArr.map((element, index) => {
    console.log('catArr element : ', element.name)
    console.log('catArr index : ', index)
    //filteredList = useMemo(getFilteredList, [element.name, catList]);
    //filteredCatItem[element.name] = catList.filter((item) => item.category === element.name);
});

    return (
        <>
            {(loading && catList.length > 0) ? <Spinner /> :
                <div className="awards-wrapper">

                <div className="cat-list">
                    
                         {(eventId == 39)?   
                                   <> 
                                 
                                   
                                   <Card className="item-container">
                                    <div className='row'>
                                    
                                        <div className='col-sm-6'>
                                       
                                            <div className='pic-wrap'>
                                                
                                           
                                                <div className='pic-img' style={{marginTop:'20px'}}>
                                                <img  alt="..." src={process.env.REACT_APP_CDN_URL+'Event/EMEA25/attending_event.png'} />
                                                </div>
                                              
                        
                                            </div>
                                        </div>
                                        <div className='col-sm-6' style={{top:'100px', textAlign:"left"}}>               
                                            <div className='pic-wrap'>
                                                {/* <h4>Video</h4>
                                                <YoutubeEmbed  embedId="TbEFm5MikAI"  />  */}
                                                                                                
                                                 <div  > To get your personal photo gallery, register on this link: <a href="https://channelhub.premagic.com/channel-summit-emea-2025-v3ca/register/" target='_blank '>https://channelhub.premagic.com/channel-summit-emea-2025-v3ca/register/</a></div>
                                                 
                                                 <div style={{marginTop:'30px'}}>  To get all the event pictures click here:<a href=" https://channelhub.premagic.com/share/Channel-Summit-EMEA-2025/#/" target='_blank '> https://channelhub.premagic.com/share/Channel-Summit-EMEA-2025/#/</a></div>
                                            </div>
                                            
                                        </div>       
                        
                                    </div>
                                    </Card>
                                   
                                   
                                     </>
                                : ''}
                        
                    </div>

                    <div className="filter-container">

                        <span><b>Filter by Category:</b></span>

                        <SelectAutocomplete
                            id="category-list"
                            type="text"                
                            label="Category"
                            name="category-list"                
                            options={catArr}
                            placeholder="Category"
                            getOptionSelected={(options, value) => options.name === value.name}
                            disableCloseOnSelect
                            limitTags={2}
                            style={{width:"27%"}}
                            control={control}                         
                            input='category-list'
                            handleChangeSelect={handleCategoryChange}
                        />   

                        {/* <select
                            name="category-list"
                            id="category-list"
                            defaultValue=""
                            onChange={handleCategoryChange}
                        >
                            <option value="">All</option>
                            <option value="Audio" >Audio</option>
                            <option value="Electronics and IoT">Electronics and IoT</option>
                            <option value="Entertainment" selectedCategory>Entertainment</option>
                            <option value="Gaming & Computing">Gaming & Computing</option>
                            <option value="IT Peripherals & Accessories">IT Peripherals & Accessories</option>
                            <option value="Lifestyle">Lifestyle</option>
                            <option value="Mobile Products">Mobile Products</option>
                        </select> */}
                    </div>
             
                    <div className="cat-list">
                      
                        

                        {(selectedCategory == null || selectedCategory == '' && catArr.length > 0) ? catArr.map(x=>(
                            <div className="row">
                            <Row className="align-items-center">
                                <Col lg="12" md="12" sm="12" >
                                <div class='set-image h-100' style={{ height: "100% !important", marginTop: "56px", padding: "14px"}}>
                                <h2  style={{ color:"#2566e8"}}>{x.name}</h2> 
                                </div>
                                </Col>
                                 {(catList.filter((item) => item.category === x.name)).sort((a, b) => a.order - b.order).map((element, index) => (
                                element.isS3Object ? <AwardS3VideoItem {...element} key={index} /> :<AwardVideoItemMENA {...element} key={index} />

                                 ))} 

                                
                            </Row>
                            </div>
                            )) :
                            <>
                            <div className="row">
                            <Row className="align-items-center">
                                <Col lg="12" md="12" sm="12" >
                                <div class='set-image h-100' style={{ height: "100% !important", marginTop: "56px", padding: "14px"}}>
                                <h2  style={{ color:"#2566e8"}}>{selectedCategory}</h2> 
                                </div>
                                </Col>
                            {filteredList.map((element, index) =>  (
                                element.isS3Object ? <AwardS3VideoItem {...element} key={index} /> :<AwardVideoItemMENA {...element} key={index} />

                                 ))} </Row></div></>

                            }

                          
                        

                    </div>
                </div>
            }</>
    );
}