import React, { useState } from 'react';
import { FormCheckBox} from "_components/FormElements/FormInput";

import {
  Button, Modal
} from "reactstrap";
export  const AskmeetingPopup=(props)=> {
  const {isOpen, handleClose, handleSave, value } = props;
  let [checked, setChecked] = useState(true);
  const handleChange = (e) => {
    console.log('e',e);
    setChecked(!checked);
  };
  const proceed =()=>{
    if(checked){
    localStorage.setItem('ask_Meeting_show_Again','1');
    }
    handleSave(value)
  }
  return (
    <div>
      <Modal className="modal-dialog-centered" isOpen={isOpen}>
          <div className="modal-header text-center">
           <h2 className="text-center">Ask Meeting</h2>
            <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={ handleClose}>
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
           <strong><h4>This service provided by ChannelHub is a supplement to your current plan. The process works as follows:</h4></strong>
           <h4>1. Only confirmed and achieved call (phone or skype) will be counted and charged.</h4>
           <h4>2. First you order a credit of 500€ to cover the cost of 5 achieved calls.</h4>
           <h4>3. ChannelHub team will contact the buyer and will present your company’s profile and products</h4>
           <h4>4. If there is an interest, we’ll organize a call at your convenient time and date.</h4>
           <h4>5. If the call is confirmed and realized, we’ll deduct 100€ from your wallet.</h4>
          </div>

                              <div className="ml-5">
                                <div> 
                                    <FormCheckBox
                                    id="checked"
                                  type="checkbox"
                                 label1={{textAlign:"justify"}}
                                 checked={checked}
                                // register={register}
                                value={'checked'}
                                label="Do not ask me again, Proceed automatically"
                                onClick={(e)=>handleChange(e)}
                                 />  
                                </div>
                              
                                </div>
                               
                             
                         <div className="modal-footer">
                    
          <Button color="primary" type="button" onClick={proceed}>Proceed</Button>
          </div>
        </Modal>
    </div>
  );
}
