import React, { useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Container } from "reactstrap";
import emealogo from 'assets/img/guestpage/LOGO_CHANNEL_SUMMIT_EMEA.png'
import ifalogo from 'assets/img/guestpage/Logo_Global_Retail_IFA.png'
import apaclogo from 'assets/img/guestpage/Logo_ChannelConnect APAC.png'
import { history } from "_helpers";
import EventTabs from './EventTabs'
import Vendorbuyerlist from './Vendorbuyerlist';
import SimpleHeader from "components/Headers/SimpleHeader";
import { useSelector } from 'react-redux';
import { CodeOutlined } from '@material-ui/icons';
import EventMeetings from './EventMeetings';
import { Fallback } from '_components/ErrorElements/FallBack';
import EventAgenda from '../Buyer-Session/EventAgenda';

import EventPresentationslide from './EventPresentationslides';
import EventPresentationMENA from './EventPresentation_MENA24';
import BestAwardsVideos from './BestAwardsVideos';
import BestAwardsVideosMENA from './BestAwardsVideo_MENA24';
import PicturesVideos from './picturesvideos';
import PicturesVideosMENA from './picturesvideos_MENA24';
import BestAwardVideos_EMEA24 from './BestAwardVideos_EMEA24';
import Event_Pdf_Template from './PdfTemplate';
import Event_Video_Template from './VideoTemplate';
import EventKeyNoteAndPresentation from '../Events/EventKeyNoteAndPresentation';
const PrevEventlisting = (props) => {


    const userData = JSON.parse(localStorage.getItem('CHuser'));


    const [template, setTemplate] = useState(null);
    let eventDetails = useSelector(state => state.event)?.eventDetails;
    const [hasPackage, sethasPackage] = useState('')
    const [eventId, setevenId] = useState('')
    const [eventType, seteventType] = useState('')
    const [contactId, setcontactId] = useState('')
    let userSelect = JSON.parse(localStorage.getItem('selectCHUser'));
    if (userSelect == null) {
        userSelect = JSON.parse(localStorage.getItem('CHuser'));
    }

    if (!eventDetails) {

        eventDetails = JSON.parse(localStorage.getItem('eventDetails'));
    }

    const getTabs = (id, template) => {
        console.log('here 1111')
        switch (id) {
            case '0':
                return <EventAgenda hasPackage={eventDetails.hasPackage} eventId={eventDetails.channel.eventId} contactId={userSelect.contactId} />
            case '1':
                return <Vendorbuyerlist id={userData.channelTypeId != 2 ? 'vendors' : 'buyers'} eventId={eventDetails?.eventId} isSeller={eventDetails?.channel['package'].isSeller == '1' ? true : false} />

            case '2':
                return <EventMeetings eventId={eventDetails?.eventId} isSeller={eventDetails?.channel['package'].isSeller == '1' ? true : false} />


            case '3': {
                if (template === "Pdf Template")
                    return <Event_Pdf_Template eventId={eventDetails?.eventId} />
                else if ((template === "Video Template"))
                    return <Event_Video_Template eventId={eventDetails?.eventId} />
                //Once below events are populated with data in table then remove else part
                else {
                    if (eventDetails?.eventId == 6)
                        return <EventPresentationslide />
                    else if (eventDetails?.eventId == 15)
                        return <EventPresentationMENA />
                }
            }


            case '4': {
                if (template === "Pdf Template")
                    return <Event_Pdf_Template eventId={eventDetails?.eventId} />
                else if ((template === "Video Template"))
                    return <Event_Video_Template eventId={eventDetails?.eventId} />
                //Once below events are populated with data in table then remove else part
                else {
                    if (eventDetails?.eventId == 6)
                        return <BestAwardsVideos />
                    else if (eventDetails?.eventId == 15)
                        return <BestAwardsVideosMENA />
                    if (eventDetails?.eventId == 14) {
                        return <BestAwardVideos_EMEA24 />
                    }
                    if (eventDetails?.eventId == 39) {
                        return <PicturesVideos eventId={eventDetails?.eventId}/>
                    }
                }

            }


            case '5': {
                if (template === "Pdf Template")
                    return <Event_Pdf_Template eventId={eventDetails?.eventId} />
                else if ((template === "Video Template"))
                    return <Event_Video_Template eventId={eventDetails?.eventId} />
                //Once below events are populated with data in table then remove else part
                else {
                    if (eventDetails?.eventId == 6)
                        return <PicturesVideos />
                    else if (eventDetails?.eventId == 15)
                        return <PicturesVideosMENA />
                    else {
                        return <Event_Pdf_Template eventId={eventDetails?.eventId} />
                    }
                }
            }
            case '8':
                return <Event_Pdf_Template eventId={eventDetails?.eventId} />
            case '9':
                return <EventKeyNoteAndPresentation /> 
            

            // case '5':
            //     return <EventPresentationslide />  


        default:
            return <EventMeetings eventId={eventDetails?.eventId} isSeller={eventDetails?.channel['package'].isSeller == '1' ? true : false} />


            // case '6':
            //     return <EventAwards />
            // case '7':
            //     return <MeetingScheduler id={userData.channelTypeId != 2 ? 'vendors' : 'buyers'} eventId={eventDetails?.eventId} />
            // case '8':
            //     return <MySchedule id={userData.channelTypeId != 2 ? 'vendors' : 'buyers'} eventId={eventDetails?.eventId} />
            // default:
            //     return eventDetails?.eventStatus == 3 ? <Vendorbuyerlist id={userData.channelTypeId != 2 ? 'vendors' : 'buyers'} eventId={eventDetails?.eventId} /> : <ManageUser />
        }
    }

    const [activeTab, setActiveTab] = useState('1');
    // const [activeTab, setActiveTab] = useState(useSelector(state => state.event)?.eventDetails?'1':'2');

    const changeTab = (id, template) => {
        if (template != null)
            setTemplate(template);
        setActiveTab(id);
    }


    const errorHandler = (error, errorInfo) => {
        console.log('Logging:', error, errorInfo);

    }



    return (
        <>
            <SimpleHeader location={props.match} />
            <div id="listing" className="main-content">
                {eventDetails?.eventId == 3 &&
                    <div className='text-center'>
                        <img src={emealogo} width={130} />
                        <h1 className='text-center mt-3 mb-3'>Channel Summit Details</h1>
                    </div>}

                {eventDetails?.eventId == 4 &&
                    <div className='text-center'>
                        <img src={ifalogo} width={130} />
                        <h1 className='text-center mt-3 mb-3'>Global Retail Connect at IFA</h1>
                    </div>}
                {eventDetails?.eventId == 5 &&
                    <div className='text-center'>
                        <img src={apaclogo} width={130} />
                        <h1 className='text-center mt-3 mb-3'>Channel Connect 1-to-1 EMEA-APAC</h1>
                    </div>}


                {/* : <p className='text-center mt-3 mb-3' style={{ fontSize: '13px' }}><b>IMPORTANT:</b> The buyer list is for review only, not for sending meeting requests. The meeting scheduler will open on May 2nd. <br />In the meantime you can create various lists and save your selection using the "Favorite" icon</p> */}
                <ErrorBoundary FallbackComponent={Fallback} onError={errorHandler}>
                    <Container className="col-12 list-tab" fluid>


                        <EventTabs activeTab={activeTab} eventDetails={eventDetails} changeTab={changeTab} channelTypeId={userData.channelTypeId} />


                        {getTabs(activeTab, template)}

                        {/* <RegisterEve/> */}
                        {/* <ManageUser/> */}
                        {/* <Vendorbuyerlist/> */}
                        {/* <Eventtravel/> */}
                    </Container>
                </ErrorBoundary>
            </div>
        </>
    );
};

export default PrevEventlisting;