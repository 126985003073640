import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import {
  Button, Modal,Row,Col,Card
} from "reactstrap";
import { LaptopWindows } from '@material-ui/icons';
const B2blink=(props)=> {
  
  const {open, handleClose, value } = props;
  //console.log(props)
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
 

  return (
    <div>
      <Modal className="modal-dialog-centered" isOpen={open}>
          <div className="modal-header">
           <strong>B2B Url's</strong>
            <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={handleClose}>
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body awd-bdy pt-0">
            <Card>
                {value.map(item=>(
                  <Row>
                  <Col lg='12' className='text-left p-0 mt-3'>
                      <div className='awd-list'>
                      <h4 className='text-center' style={{color:'#2c9db7',cursor:'pointer'}}  onClick={()=>{
                let url=item.channelB2B;
                  if (!url.match(/^https?:\/\//) || !url.match(/^http?:\/\//)) {
                    url = '//' + url;
                    console.log('url',url);
                  }
                  window.open(url,'_blank');
                }}> {item?.channelB2B} </h4>
                      </div>
                  </Col>
              </Row>
                ))}
            </Card>
          </div>
          <div className="modal-footer">
          {/* <Button color="primary" type="button" onClick={()=>handleSave(value)}>Ok</Button> */}
          <Button color="secondary" data-dismiss="modal" type="button" onClick={handleClose}>Close</Button>
          </div>
        </Modal>
    </div>
  );
}
export default B2blink;