import React, { Fragment, useState, useEffect } from "react";
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, TimePicker } from "@material-ui/pickers";
import FormHelperText from '@material-ui/core/FormHelperText';
import { Card, CardBody, Row, Col, Container, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown, Button } from 'reactstrap';
import FormControl from '@material-ui/core/FormControl';
import { FormInput } from '_components/FormElements/FormInput';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { EventTravelSchema } from './validation';
import { ToastsStore, ToastsContainer } from 'react-toasts';
import { Spinner } from 'views/Hoc/Spinner';
import axios from "axios";
// import ReactPlayer from "react";


const EventKeyNoteAndPresentation = (props) => {

    const [loading, setLoading] = useState(false);

    useEffect(() => {
     
    }, []);
  //  console.log('errprs', errors)

 

    return (
      // <Container>
     
        <Card>
          <Col xs-12>
            <div className="eventaward">
            <h2>Channel Summit EMEA 2025</h2>
            <span className="eventspan"><span></span></span>
            <p style={{width:"80%"}}>All presentation slides will be available after you completed the event survey. Please check your mailbox for the survey email we sent on Saturday March 8th with subject: Channel Summit EMEA: Slides and Survey.</p>
           </div>
          
          
         
           </Col>
        </Card>
        // </Container>
    );
};

export default EventKeyNoteAndPresentation;