import React, { useState, useEffect } from 'react';
import { FormInput } from "_components/FormElements/FormInput";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { signupschema } from './Validation';

import { InputAdornment, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import axios from 'axios';
import { Dialog } from "views/Hoc/Dialog/GlobalDialog";

export default function Step5(props) {

  let comp = {};

  const { register, errors, watch, handleSubmit } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(signupschema)
  });


  const { } = props;

  const { email, password, rePassword, errState, fromGuest, eventId } = props.values;

  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);

  const handleClickShowPassword1 = () => { setShowPassword1(!showPassword1); }
  const handleClickShowPassword = () => { setShowPassword(!showPassword); }

  const watchEmail = watch('companyemail', email);
  const watchPassword = watch('password', password);
  const watchRepassword = watch('rePassword', rePassword);

  let [isModalPopup, setModalPopup] = useState(false);
  let [modalPopValue, setModalPopValue] = useState({ message: null, title: null, value: null, popUpId: null, second_message: null });

  const confirmPopup = (res) => {
    setModalPopup(false);
  }
  const closePopup = () => {
    setModalPopup(false);
  };

  const emailAlreadyFound = () => {
    let msg = eventId ? 'This email is matching an existing user on Channel Hub platform, please connect to your account in order to apply for the event.' : 'This email address is already present on Channel Hub, please use the Sign-in module.';
    let loginUrl = eventId ? 'Login?eventId=1' : 'Login';
    modalPopValue.message = <>{msg}<span><a href={process.env.REACT_APP_LOCALSITE + loginUrl}>Click here to sign in</a></span></>;
    modalPopValue.title = 'Email Already Present';
    modalPopValue.popUpId = 1;
    setModalPopValue({ ...modalPopValue });
    setModalPopup(true);
  }


  useEffect(() => {
    setCheckMail(0);
  }, [watchEmail])

  const [checkMailId, setCheckMail] = useState(0);
  const onSubmit = (data) => {
    console.log('email', watchEmail)
    console.log('pass', watchPassword)
    console.log('repass', watchRepassword)
    checkMail(2);
  }
  console.log('errors', errors)
  const checkMail = (id) => {
    const apiUrl = 'account/checkmail/' + watchEmail;

    axios.get(apiUrl).then(res => {
      console.log('res', res.data)
      if (res.data.isavialble == true) {
        if (id == 2) {
          props.assignValues({
            email: watchEmail,
            password: watchPassword,
            rePassword: watchRepassword,
            fromGuest: watchEmail
          });
          props.getNewSignup();
        }
      }
      else {
        console.log('error already taken mail')
        setCheckMail(1)
        emailAlreadyFound();
      }
    })

  }



  const handleChangemail = input => e => {
    var re = /\S+@\S+\.\S+/;
    if (re.test(e.target.value))
      checkMail(1);
  }

  return (

    <div className="main-content">
      <div className="container" style={{ padding: '0' }}>
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <Dialog open={isModalPopup} value={modalPopValue} handleSave={confirmPopup} handleClose={closePopup} />
            <h5 className='mob-signhead' style={{ display: 'none' }}>Login Details</h5>
            <div className="col-lg-12">
              <div className="form-group">
                <FormInput
                  id="companyemail"
                  type="email"
                  label="Enter your Email *"
                  name="companyemail"
                  register={register}
                  value={watchEmail}
                  onChange={handleChangemail('email')}
                  error={errors.companyemail ? errors.companyemail : checkMailId ? { message: "Email Id already taken", required: true } : ''}

                  defaultValue={email}
                />
              </div>
            </div>



            {/* <div className="row">
        <div className="col-lg-12"> */}
            {/* <div className="col-lg-6">
              <div className="form-group">
                <FormInput
                  id="password"
                  type={showPassword ? "text" : "password"}
                  label="Password *"
                  name="password"
                  error={errState.password != '' ? { message: errState.password, type: 'required' } : errors.password}
                  value={watchPassword}
                  //onChange={handleChange('password')}
                  register={register}
                  InputProps={{}}
                  defaultValue={password}

                />
              </div>
            </div>



            <div className="col-lg-6">
              <div className="form-group">
                <FormInput
                  id="rePassword"
                  type={showPassword1 ? "text" : "password"}
                  label="Confirm Password *"
                  name="rePassword"
                  error={errState.rePassword != '' ? { message: errState.rePassword, type: 'required' } : errors.rePassword}
                  value={watchRepassword}
                  //onChange={handleChange('rePassword')}
                  register={register}
                  defaultValue={rePassword}
                  hintText="Password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword1}
                        // onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword1 ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </div>
              {/* </div>
             </div>  */}

            {/* </div>
            <div>
              <div className="col-lg-12">
                <span style={{ fontSize: "0.9rem" }}> *Note: Password must contain at least 1 uppercase(A-Z), 1 lowercase(a-z), 1 number(0-9) and 1 special character(*$@^)</span>
              </div>
            </div> */}
          </div>
          <button type="submit" className="btn btn-primary ch-btn-blue right-but" variant="contained" color="primary" >
            Next
          </button>
        </form>

      </div>
    </div>

  )
}
const yes = [
  { title: 'jgfygfjy' },
  { title: 'Nokhvv' },
]

