import React, { useState, useEffect } from "react";
import { Col, Container, Row, Card, CardBody, Button, UncontrolledTooltip } from "reactstrap";
import { yupResolver } from '@hookform/resolvers/yup';
import { FormTextArea, FormCheckBox, FormAutocomplete, FormInput } from "_components/FormElements/FormInput";
import { useForm } from "react-hook-form";
import { helpSupportSchema } from "./validation"
import Title from "views/Hoc/Dialog/Title";
import SimpleHeader from "components/Headers/SimpleHeader"
import { commonService } from '_services/common.service';
import { Spinner } from "views/Hoc/Spinner";
import { history } from '_helpers/history';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { GuestPopup } from "views/Hoc/Dialog/GlobalDialog";
const Support = (props) => {
  const { control, errors, register, watch, handleSubmit } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(helpSupportSchema),
  });
  let [state, setState] = useState({ subject: null, message: null, typeOfSupport: {}, typeOfSupportList: [], isChecked: true });
  const [err, setErr] = useState(false);
  let [isLoading, setLoading] = useState(true);
  let [contactInfo, setContactInfo] = useState(null);
  var userData = JSON.parse(localStorage.getItem('CHuser'));
  console.log('watch', watch);
  console.log('error', errors);

  useEffect(() => {
    getTypeOfSupportList();
  }, []);
  const getTypeOfSupportList = async () => {
    setLoading(true);
    let res = await commonService.getServices('complain/category/ContactType').catch(err => setLoading(false));
    let data = res.data;
    state.typeOfSupportList = data;
    setState({ ...state });
    let contactData = await commonService.getServices('user/contact/' + userData.contactId).catch(err => setLoading(false));
    if (contactData) {
      contactInfo = contactData.data;
      setContactInfo(contactInfo);
    }
    setLoading(false);
  }
  const handleChangeSelect = (input, value) => {
    state[input] = value;
    setState({ ...state });
  }
  const handleChange = (e, input) => {
    console.log(e.target.value);
    console.log('state', state);
    if (input === 'checkbox') {
      state.isChecked = !state.isChecked;
      state.isChecked ? setErr(false) : setErr(true);
    } else {
      state[input] = e.target.value;
    }
    setState({ ...state });
  }
  const save = async () => {
    console.log('state', state);
    if (!state.isChecked) {
      setErr(true);
      return false;
    }
    setLoading(true);
    let data = {
      complainttype: "ContactType",
      channelid: parseInt(userData.channelId),
      userid: parseInt(userData.userId),
      channeltype: parseInt(userData.channelTypeId) === 2 ? 'VENDOR' : parseInt(userData.channelTypeId) === 3 ? 'DISTRIBUTOR' : 'RETAILER',
      firstname: userData.firstName,
      lastname: userData.lastName,
      email: userData.email,
      phoneno: contactInfo.phone1,
      mobileno: contactInfo.mobileNo,
      // countryid: +userData.countryId,
      subject: state.subject,
      detaildesc: state.message,
      iprurl: "",
      complaintcategoryid: parseInt(state.typeOfSupport.id),
      compalainDocument: []
    }
    console.log('state', state);
    let contactData = await commonService.postService(data, 'complain').catch(err => setLoading(false));
    if (contactData) {
      ToastsStore.success('Success!...');

      commonService.postService({
        email: data.email,
        // countryId: data.countryid,
        // country:userData.count,
        phoneno: data.phoneno,
        mobileno: data.mobileno,
        name:data.firstname+" "+data.lastname,
        detaildesc: data.detaildesc,
        companyName:userData.companyName,
        channelId:data.channelid,
        subject: data.subject,
        type_of_support: state.typeOfSupport.name,
        to: 'info@channelhub.net',

      },'mailerservice/Help_Support_Mail').catch(err => setLoading(false));

      gotoPage()
    } else {
      ToastsStore.warning('Something went wrong!...');
    }
    setLoading(false);
  }
  const gotoPage = () => {
    history.push('/admin/dashboard');
  }

  //for privacy pop
  let [modalPopValue, setModalPopValue] = useState({ message: null, title: null, value: null, popUpId: null, second_message: null });
  const [openPop, setOpenPop] = useState(false);

  const popUpok = (value) => {
    setOpenPop(false);
  }

  const openDialogs = (id) => {

console.log('id', id)
    if(id == '2'){
      modalPopValue.title = "Privacy Policy";
      modalPopValue.message = "Privacy Policy";
      modalPopValue.popUpId = id;
    }
    if(id == '3'){
      modalPopValue.title = "Terms & Conditions";
      modalPopValue.message = "Terms & Conditions";
      modalPopValue.popUpId = id;
    }

    setModalPopValue({...modalPopValue});
    setOpenPop(true);
  }

  const closePopup = () => {
    setOpenPop(false);
  }

  return (
    <>
      <SimpleHeader location={props.match} />
      <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore} />
      <GuestPopup open={openPop} value={modalPopValue} handleSave={popUpok} handleClose={closePopup} />
      <Title title="HELP/SUPPORT" desc={[""]} />
      <Container className="mt-4 col-lg-12 col-md-9 col-sm-12   container-fluid" fluid>

        {isLoading ? <Spinner /> : <Card className=" col-lg-12 col-sm-12 mt-2  container numcard pad-less ">
          <CardBody >
            <Row >
              <Col lg="6" className="pad-less">
                <Row>
                  <Col lg="12">
                    <FormInput
                      className="comp-name mt-2"
                      id="subject"
                      name="subject"
                      label="Subject *"
                      placeholder="Subject"
                      type="text"
                      variant="outlined"
                      value={state.subject}
                      defaultValue={state.subject}
                      error={errors.subject}
                      register={register}
                      value={state.subject}
                      onChange={(e) => handleChange(e, 'subject')}
                    />
                  </Col>
                  <Col lg="12" className="mt-4 mb-4">
                    <FormAutocomplete
                      id="typeOfSupport"
                      name="typeOfSupport"
                      label="Type of Support *"
                      options={state.typeOfSupportList}
                      placeholder="Select Support"
                      // multiple
                      disableCloseOnSelect
                      blurOnSelect
                      // limitTags={1}
                      control={control}
                      input='typeOfSupport'
                      value={state.typeOfSupport}
                      handleChangeSelect={handleChangeSelect}
                      errMessage={'Select Type Of Support'}
                      error={errors.typeOfSupport}
                      defaultValue={state.typeOfSupport}
                    />
                  </Col>
                </Row>
              </Col>
              <Col lg="6" sm="12" className="text-center ">
                <div className=" mx-auto text-center">
                  <FormTextArea
                    className="buytext2"
                    name="message"
                    id="message"
                    rows="3"
                    type="textarea"
                    label=" "
                    placeholder="Message *"
                    register={register}
                    value={watch.message}
                    error={errors.message}
                    onChange={(e) => handleChange(e, 'message')}
                  />
                </div>
              </Col>
            </Row>
            <div>
              <div className="chck1">
                <FormCheckBox
                  id="txt"
                  type="checkbox"
                  // label="Yes, I have read and accept the general terms of use"
                  label1={{ textAlign: "justify" }}
                  label={err ? labelErrText : labelText}
                  value={state.isChecked}
                  checked={state.isChecked}
                  register={register}
                  onClick={(e) => handleChange(e, 'checkbox')}
                />

              </div>
              <div className="text-center mt-4">
                <p> By sending this form, I agree that my information may be processed by ChannelHub for the creation and monitoring of my account and to access online services. I can exercise my rights of access, rectification, deletion and portability by writing to privacy@channel-hub.io with proof of my identity. For more information, see our
                   <strong><span style={{cursor: 'pointer'}} onClick={() => openDialogs(2)} >Privacy Policy.</span></strong></p>
                   </div>
            </div>

            <div className="text-center mt-4">
              <button type="submit" className="btn btn-primary" onClick={handleSubmit(save)} >Submit</button>
              <button type="submit" className="btn btn-warning" onClick={gotoPage}>Cancel</button>
            </div>

          </CardBody >
        </Card>}
      </Container>


    </>




  );
}

const labelText = <span>Yes, I have read and accept the general terms of use</span>;
const labelErrText = <span style={{ color: 'red' }}>Yes, I have read and accept the general terms of use</span>;
export default Support;