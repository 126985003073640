import React, { useEffect, useState } from 'react';
import { Link} from 'react-router-dom';
import Logo from 'views/pages/LoginPage/login-logo.js';
import axios from 'axios';
import { history } from '_helpers/history';
export const Pending = (props) => {
  let eventId = props.location?.state?.eventId ? props.location?.state?.eventId : '';

  let backgroundBn ='https://d35w8j22j5uly8.cloudfront.net/document/company/banner/1663070240735_1663070240901_1663072157521.png';

  useEffect(()=>{
    getEventDetails();
  },[])
const [bannerbg,setbannerbg]=useState(null);
const [logobg,setlogobg]=useState(null);


  const getEventDetails = async () => {
  if(eventId){
    let res = await axios.get('/channelAdmin/getEventById/' + eventId).then(res => {

      console.log('eventdetail',res.data)
      backgroundBn=process.env.REACT_APP_CDN_URL+res.data[0].banner.documentPath;
      let logoback=process.env.REACT_APP_CDN_URL+res.data[0].logo.documentPath;
      setbannerbg(backgroundBn);
      setlogobg(logoback);

    })

      .catch(err => {
        console.log(err);
    });
  }

}

  return (
    <div className="main-content">
      {/* <Logo/> */}
      <div className="head-logo" style= {eventId ? {height:"9rem"} : {}} >
        <Link to="/" >
          {/* <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} className={eventId ? "" :"navImg"} alt="ch-logo" src={eventId ? require('assets/img/guestpage/LOGO_CHANNEL_SUMMIT_EMEA.png') : require('assets/img/brand/ch-logo.svg')} style= {eventId ? {width:"100px",height:"100px"} : {}} /> */}
          
         

          <img className={eventId ? "" :"navImg"} alt="ch-logo" src={eventId? logobg : require('assets/img/brand/ch-logo.svg')} style= {eventId ? {width:"100px",height:"100px"} : {}} />

        </Link>
        </div>
      {eventId ?
      <div class={`header mt-1    signuptophead pt-lg-6`} style= {eventId ? {height:"500px",backgroundSize:"cover",backgroundImage:`url(${bannerbg})`} : {}}>
      {eventId && <div className='card-overlay'></div>}
    
      {eventId ? <></> :
      <div className="separator separator-bottom separator-skew zindex-100">
        <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <polygon className="fill-default" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>}
    </div>:<div class={`header signup-header-bg mt-1   signuptophead pt-lg-6`} style= {eventId ? {height:"450px"} : {}}>
      {eventId && <div className='card-overlay'></div>}
    
      {eventId ? <></> :
      <div className="separator separator-bottom separator-skew zindex-100">
        <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <polygon className="fill-default" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>}
    </div>}

    

    <div className={`container ${eventId ? 'evebghgt' : 'mt--8'} pb-5`}>
    <div className="row justify-content-center">
    <div className="col-lg-10 col-md-12">
    <div className="card bg-secondary border-0 mb-0">  
  
    <div className="card-body px-lg-5 py-lg-5">     

    <div className="header-body text-center mb-7">
          <div className="row justify-content-center">
            <div className="col-xl-12 col-lg-10 col-md-12">
            <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  src={require("assets/img/brand/wait.png")}/> 
              <h1 className="text-black text-lead text-marg">Thank you for your application.</h1>
              <h1 className="text-black text-lead">Our team will review your profile and come back to you shortly.</h1>
            </div>
          </div>
          <div className="text-center mt-2" >
          <button  className="btn btn-primary text-center" variant="contained" color="primary" onClick = {() => history.push('/')}>
            Click Here to HOME PAGE 
          </button></div>
          
        </div>




                 </div>
             
              </div>
              </div>
              </div>
              </div>
              </div> 
              
  );
}
export default Pending;