import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Navbarg from "components/Navbars3/GuestNavbar";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardImg,
    CardImgOverlay,
    CardTitle,
    CardText,
    ListGroupItem,
    ListGroup,
    Row,
    Col
  } from "reactstrap";
import { Container } from '@material-ui/core';
import Footer from './Footer';
import axios from 'axios';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

export default function GuestSalesrep(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  let path = props.location.pathname;

  return (
<>

<Navbarg path={path}/>
 <div className="">
 <div className='vmain'>
            <Row>
                <Col lg="6" className="pad-less vcolmn"></Col>
                <Col lg="6" className="pad-less vcolmn">
                    <div className='vpart vpart1'>
                        <div className='vpart-slide'>
                        <h1 className="text-uppercase text-center ">SALES REPS – BUSINESS DEVELOPERS</h1>
                        <p className="text-center mt-2"><strong>ChannelHub is the first platform connecting vendors and specialized sales reps / business developers for the consumer tech market across several geographies.</strong> </p>
                        <div className="text-center vpart-button">
                        <button className="btn btn-primary" size="lg">Schedule a demo</button>
                        <button className="btn btn-default" size="lg">Apply For Free <p>No credit card required</p></button>
                        </div>
                        </div>
                    </div>
                </Col>
            </Row>
            </div>

 </div>
 

  <div className="yban profad">
                <Container >
                   
                        <Col lg='12' className='p-7'>
                            <h1>Why ?</h1>
                            <p><strong>The consumer tech market has dramatically changed over the past 10 years, releasing numerous talented sales executives in market; many of them starting their own consultancy business, sharing their experience and address book for the benefit of new brands.</strong></p>

                      
                        <p ><strong>The consumer tech market has dramatically changed over the past 10 years, releasing numerous talented sales executives in market; many of them starting their own consultancy business, sharing their experience and address book for the benefit of new brands.</strong></p>

                        </Col>
               
                </Container>
            </div>

       





  <div className="mt-4">
       <div className="text-center p-4">
      <h1 className="tet-center">Check our network of sales reps & business developers</h1>
      </div>
  <Row>
  <Col lg="4">
       <Card>
     <CardBody>
      <Row className="align-items-center">
        <Col className="col-auto">

          <a
            className="avatar avatar-xl rounded-circle"
            href="#pablo"
            onClick={e => e.preventDefault()}
          >
            <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}} 
              alt="..."
              src={require("assets/img/guestpage/CH-alain.jpg")}
            />
          </a>
        </Col>
        <Col lg="3"></Col>
        <Col lg="3">
        <Button color="primary" size="sm" type="button">
            Connect
          </Button>
          </Col>
          <Col className="col-auto">

</Col>
</Row>
        <div className="p-2">
        <table striped bordered hover>
        <tbody>
            <tr class="">
                <th class="avia-desc-col">Name:</th>
                <td class="">B.A.</td>
                </tr>
                <tr class="">
                    <th class="avia-desc-col">Geographical Coverage:</th>
                    <td class="">The Netherlands</td>
                    </tr>
                    <tr class="">
                        <th class="avia-desc-col">In business since:</th>
                        <td class="">2016</td>
                        </tr><tr class="">
                            <th class="avia-desc-col">Reference provided:</th>
                            <td class="">No</td>
                            </tr>
                            </tbody>
                    </table>
                    </div>
    

     <div className="mt-2">
     <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Product Categories</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
            sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Key Brands(Past & Present)</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
            sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Key Retail Access</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
            sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Service provided</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
            sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Channel Speciality</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
            sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
    </div> 
    </CardBody>
    </Card>
    </Col>

    <Col lg="4">
       <Card>
     <CardBody>
      <Row className="align-items-center">
        <Col className="col-auto">

          <a
            className="avatar avatar-xl rounded-circle"
            href="#pablo"
            onClick={e => e.preventDefault()}
          >
            <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}} 
              alt="..."
              src={require("assets/img/guestpage/CH-alain.jpg")}
            />
          </a>
        </Col>
        <Col lg="3"></Col>
        <Col lg="3">
        <Button color="primary" size="sm" type="button">
            Connect
          </Button>
          </Col>
          <Col className="col-auto">

</Col>
</Row>
        <div className="p-2">
        <table striped bordered hover>
        <tbody>
            <tr class="">
                <th class="avia-desc-col">Name:</th>
                <td class="">B.A.</td>
                </tr>
                <tr class="">
                    <th class="avia-desc-col">Geographical Coverage:</th>
                    <td class="">The Netherlands</td>
                    </tr>
                    <tr class="">
                        <th class="avia-desc-col">In business since:</th>
                        <td class="">2016</td>
                        </tr><tr class="">
                            <th class="avia-desc-col">Reference provided:</th>
                            <td class="">No</td>
                            </tr>
                            </tbody>
                    </table>
                    </div>
    

     <div className="mt-2">
     <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Product Categories</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
            sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Key Brands(Past & Present)</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
            sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Key Retail Access</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
            sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Service provided</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
            sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Channel Speciality</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
            sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
    </div> 
    </CardBody>
    </Card>
    </Col>

    <Col lg="4">
       <Card>
     <CardBody>
      <Row className="align-items-center">
        <Col className="col-auto">

          <a
            className="avatar avatar-xl rounded-circle"
            href="#pablo"
            onClick={e => e.preventDefault()}
          >
            <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}} 
              alt="..."
              src={require("assets/img/guestpage/CH-alain.jpg")}
            />
          </a>
        </Col>
        <Col lg="3"></Col>
        <Col lg="3">
        <Button color="primary" size="sm" type="button">
            Connect
          </Button>
          </Col>
          <Col className="col-auto">

</Col>
</Row>
        <div className="p-2">
        <table striped bordered hover>
        <tbody>
            <tr class="">
                <th class="avia-desc-col">Name:</th>
                <td class="">B.A.</td>
                </tr>
                <tr class="">
                    <th class="avia-desc-col">Geographical Coverage:</th>
                    <td class="">The Netherlands</td>
                    </tr>
                    <tr class="">
                        <th class="avia-desc-col">In business since:</th>
                        <td class="">2016</td>
                        </tr><tr class="">
                            <th class="avia-desc-col">Reference provided:</th>
                            <td class="">No</td>
                            </tr>
                            </tbody>
                    </table>
                    </div>
    

     <div className="mt-2">
     <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Product Categories</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
            sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Key Brands(Past & Present)</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
            sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Key Retail Access</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
            sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Service provided</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
            sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Channel Speciality</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
            sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
    </div> 
    </CardBody>
    </Card>
    </Col>

    <Col lg="4">
       <Card>
     <CardBody>
      <Row className="align-items-center">
        <Col className="col-auto">

          <a
            className="avatar avatar-xl rounded-circle"
            href="#pablo"
            onClick={e => e.preventDefault()}
          >
            <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}} 
              alt="..."
              src={require("assets/img/guestpage/CH-alain.jpg")}
            />
          </a>
        </Col>
        <Col lg="3"></Col>
        <Col lg="3">
        <Button color="primary" size="sm" type="button">
            Connect
          </Button>
          </Col>
          <Col className="col-auto">

</Col>
</Row>
        <div className="p-2">
        <table striped bordered hover>
        <tbody>
            <tr class="">
                <th class="avia-desc-col">Name:</th>
                <td class="">B.A.</td>
                </tr>
                <tr class="">
                    <th class="avia-desc-col">Geographical Coverage:</th>
                    <td class="">The Netherlands</td>
                    </tr>
                    <tr class="">
                        <th class="avia-desc-col">In business since:</th>
                        <td class="">2016</td>
                        </tr><tr class="">
                            <th class="avia-desc-col">Reference provided:</th>
                            <td class="">No</td>
                            </tr>
                            </tbody>
                    </table>
                    </div>
    

     <div className="mt-2">
     <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Product Categories</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
            sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Key Brands(Past & Present)</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
            sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Key Retail Access</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
            sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Service provided</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
            sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Channel Speciality</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
            sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
    </div> 
    </CardBody>
    </Card>
    </Col>
    <Col lg="4">
       <Card>
     <CardBody>
      <Row className="align-items-center">
        <Col className="col-auto">

          <a
            className="avatar avatar-xl rounded-circle"
            href="#pablo"
            onClick={e => e.preventDefault()}
          >
            <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}} 
              alt="..."
              src={require("assets/img/guestpage/CH-alain.jpg")}
            />
          </a>
        </Col>
        <Col lg="3"></Col>
        <Col lg="3">
        <Button color="primary" size="sm" type="button">
            Connect
          </Button>
          </Col>
          <Col className="col-auto">

</Col>
</Row>
        <div className="p-2">
        <table striped bordered hover>
        <tbody>
            <tr class="">
                <th class="avia-desc-col">Name:</th>
                <td class="">B.A.</td>
                </tr>
                <tr class="">
                    <th class="avia-desc-col">Geographical Coverage:</th>
                    <td class="">The Netherlands</td>
                    </tr>
                    <tr class="">
                        <th class="avia-desc-col">In business since:</th>
                        <td class="">2016</td>
                        </tr><tr class="">
                            <th class="avia-desc-col">Reference provided:</th>
                            <td class="">No</td>
                            </tr>
                            </tbody>
                    </table>
                    </div>
    

     <div className="mt-2">
     <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Product Categories</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
            sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Key Brands(Past & Present)</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
            sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Key Retail Access</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
            sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Service provided</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
            sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Channel Speciality</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
            sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
    </div> 
    </CardBody>
    </Card>
    </Col>

    <Col lg="4">
       <Card>
     <CardBody>
      <Row className="align-items-center">
        <Col className="col-auto">

          <a
            className="avatar avatar-xl rounded-circle"
            href="#pablo"
            onClick={e => e.preventDefault()}
          >
            <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}} 
              alt="..."
              src={require("assets/img/guestpage/CH-alain.jpg")}
            />
          </a>
        </Col>
        <Col lg="3"></Col>
        <Col lg="3">
        <Button color="primary" size="sm" type="button">
            Connect
          </Button>
          </Col>
          <Col className="col-auto">

</Col>
</Row>
        <div className="p-2">
        <table striped bordered hover>
        <tbody>
            <tr class="">
                <th class="avia-desc-col">Name:</th>
                <td class="">B.A.</td>
                </tr>
                <tr class="">
                    <th class="avia-desc-col">Geographical Coverage:</th>
                    <td class="">The Netherlands</td>
                    </tr>
                    <tr class="">
                        <th class="avia-desc-col">In business since:</th>
                        <td class="">2016</td>
                        </tr><tr class="">
                            <th class="avia-desc-col">Reference provided:</th>
                            <td class="">No</td>
                            </tr>
                            </tbody>
                    </table>
                    </div>
    

     <div className="mt-2">
     <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Product Categories</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
            sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Key Brands(Past & Present)</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
            sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Key Retail Access</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
            sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Service provided</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
            sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Channel Speciality</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
            sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
    </div> 
    </CardBody>
    </Card>
    </Col>

    <Col lg="4">
       <Card>
     <CardBody>
      <Row className="align-items-center">
        <Col className="col-auto">

          <a
            className="avatar avatar-xl rounded-circle"
            href="#pablo"
            onClick={e => e.preventDefault()}
          >
            <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}} 
              alt="..."
              src={require("assets/img/guestpage/CH-alain.jpg")}
            />
          </a>
        </Col>
        <Col lg="3"></Col>
        <Col lg="3">
        <Button color="primary" size="sm" type="button">
            Connect
          </Button>
          </Col>
          <Col className="col-auto">

</Col>
</Row>
        <div className="p-2">
        <table striped bordered hover>
        <tbody>
            <tr class="">
                <th class="avia-desc-col">Name:</th>
                <td class="">B.A.</td>
                </tr>
                <tr class="">
                    <th class="avia-desc-col">Geographical Coverage:</th>
                    <td class="">The Netherlands</td>
                    </tr>
                    <tr class="">
                        <th class="avia-desc-col">In business since:</th>
                        <td class="">2016</td>
                        </tr><tr class="">
                            <th class="avia-desc-col">Reference provided:</th>
                            <td class="">No</td>
                            </tr>
                            </tbody>
                    </table>
                    </div>
    

     <div className="mt-2">
     <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Product Categories</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
            sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Key Brands(Past & Present)</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
            sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Key Retail Access</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
            sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Service provided</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
            sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Channel Speciality</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
            sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
    </div> 
    </CardBody>
    </Card>
    </Col>


    <Col lg="4">
       <Card>
     <CardBody>
      <Row className="align-items-center">
        <Col className="col-auto">

          <a
            className="avatar avatar-xl rounded-circle"
            href="#pablo"
            onClick={e => e.preventDefault()}
          >
            <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}} 
              alt="..."
              src={require("assets/img/guestpage/CH-alain.jpg")}
            />
          </a>
        </Col>
        <Col lg="3"></Col>
        <Col lg="3">
        <Button color="primary" size="sm" type="button">
            Connect
          </Button>
          </Col>
          <Col className="col-auto">

</Col>
</Row>
        <div className="p-2">
        <table striped bordered hover>
        <tbody>
            <tr class="">
                <th class="avia-desc-col">Name:</th>
                <td class="">B.A.</td>
                </tr>
                <tr class="">
                    <th class="avia-desc-col">Geographical Coverage:</th>
                    <td class="">The Netherlands</td>
                    </tr>
                    <tr class="">
                        <th class="avia-desc-col">In business since:</th>
                        <td class="">2016</td>
                        </tr><tr class="">
                            <th class="avia-desc-col">Reference provided:</th>
                            <td class="">No</td>
                            </tr>
                            </tbody>
                    </table>
                    </div>
    

     <div className="mt-2">
     <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Product Categories</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
            sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Key Brands(Past & Present)</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
            sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Key Retail Access</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
            sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Service provided</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
            sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Channel Speciality</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
            sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
    </div> 
    </CardBody>
    </Card>
    </Col>

    </Row>
    </div>
    <Footer/>
 
   </>
  );
};
