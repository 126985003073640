import { dashboardConstants } from '../_constants';
import { dashboardService } from '../_services';
//import { history } from '../_helpers';


export const dashboardActions = {
    dashboard_count,
    companyInfo,
    userInfo,
    addFirstProduct,
    updateCompanyInfo,
    updateTradeInfo,
    updateSettingInfo,
    
   // logout,
//    register,
  //  getAll,
    //delete: _delete
};


function dashboard_count() {


   return dispatch => {
      //  dispatch(request());

      dashboardService.dashboard_count()
            .then(               
                   // console.log("result:",count.data),
                   dasboard_count => dispatch(success(dasboard_count)),
        //        error => dispatch(failure(error.toString()))              
            );
    };

    //function request() { return { type: dashboardConstants.COUNT_REQUEST } }
    function success(dasboard_count) { return { type: dashboardConstants.COUNT_SUCCESS, dasboard_count } }
    //function failure(error) { return { type: dashboardConstants.COUNT_FAILURE, error } }
}

function companyInfo(company) {
  console.log("company:",company);
  return dashboardService.company_profile_save(company.data);
}

function updateCompanyInfo(company) {
  console.log("company:",company);
  return dashboardService.update_company_profile_save(company.data);
}

function userInfo(user) {
  console.log("company:",user);
  return dashboardService.user_profile_save(user.data);
}

function addFirstProduct(product,ratingId) {
  console.log("ratingId:",ratingId);
  return dashboardService.addFirstProduct(product,ratingId);
}

function updateTradeInfo(product){
  console.log('updateTradeInfo', product);
  return dashboardService.updateTradeInfo(product);
}

function updateSettingInfo(data){
  //console.log('updateTradeInfo', product);
  return dashboardService.updateSettingInfo(data);
}
// function logout() {
//     userService.logout();
//     return { type: userConstants.LOGOUT };
// }

