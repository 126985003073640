
import React, { useState, useEffect } from "react";
import axios from 'axios';
import { commonService } from '_services/common.service';
// node.js library that concatenates classes (strings)
// javascipt plugin for creating charts
// react plugin used to create charts
// reactstrap components
import {

  Container,
  Row,
  Col, CardBody, Card, ListGroupItem,
  ListGroup,
} from "reactstrap";
import { Link } from 'react-router-dom';
import SimpleHeader from "components/Headers/SimpleHeader"
import ProggressBar from "./progress"
import { useBeforeFirstRender } from '_hooks/index'
import { history } from '_helpers/history';
// import {Wiretransfer,Failedtransaction} from 'views/pages/Landing-pages/Transaction'
// import {Userprofileform,AddgeneralForm} from 'views/pages/profile/Settings/Userprofileform'
// import Navbarg from 'views/pages/GuestPage/GuestNavbar/Navbarg'
// import GuestBlog from "../GuestPage/GuestNavbar/GuestBlog";
import { ModalSpinner } from "views/Hoc/Spinner";
import GuestprodSpec from "../GuestPage/GuestNew/GuestProdSpec";
import { ToastsStore } from "react-toasts";

const data = [
  {
    icon: 'fa fa-building', heading: 'Complete your company Profile', isCompleted: false, order: 1, warningIcon: 'timeline-step badge-warning', successIcon: 'timeline-step badge-success',
    desc: 'Tell us about the company you represent, its background, product specialities, brands, social links etc..'
  },
  {
    icon: 'fa fa-user', heading: 'Update your personal information', isCompleted: true, order: 2, warningIcon: 'timeline-step badge-warning', successIcon: 'timeline-step badge-success',
    desc: 'Your Personal information: tell us about you, your responsibilities, contact details, your communication preferences.'
  },
]

let datas = [];
const Dashboard = (props) => {

  let [profile, setProfile] = useState([]);

  const [firstTwoStep, setFirstTwoStep] = useState(0);

  let [score, setScore] = useState(0);
  let to = props.location?.state?.to ? props.location?.state?.to : '';
  //localStorage
  let userData = JSON.parse(localStorage.getItem('CHuser'));
  let typeId = userData?.channelTypeId;
  const [loading, setLoading] = useState(true);

  const setAlgorithm = () => {
    let existing = JSON.parse(localStorage.getItem('CHuser'));
    existing['isAlgorithm'] = '1';
    localStorage.setItem('CHuser', JSON.stringify(existing));
  }

  useEffect(() => {
    if (!localStorage.getItem('fo-auth-token') && !userData)
      history.push('/');
    let typeId = userData?.channelTypeId;
    //let first_completion_count=0;
    let profile_completion_count = userData?.profile_completion_count;

    let scoreValue = 0;
    (async () => {
      if ((userData.channelTypeId != 2 && profile_completion_count != 2) || (userData.channelTypeId == 2 && profile_completion_count != 4)) {
        let completion_count = await axios.get('/setting/checkUserStatus/' + userData?.channelId);
        profile_completion_count = completion_count.data;

        let existing = JSON.parse(localStorage.getItem('CHuser'));
        existing['profile_completion_count'] = profile_completion_count;
        localStorage.setItem('CHuser', JSON.stringify(existing));

        //first_completion_count=completion_count.data;
      }


      if ((userData.channelTypeId == 2 && profile_completion_count == 4) || (userData.channelTypeId != 2 && profile_completion_count == 2)) {
        if (userData.isAlgorithm == 0 || userData.isAlgorithm == null) {
          await axios.get('algorithm/channel/' + userData.contactId).then(async channel => {
            if (userData.channelTypeId != 2) {
              await axios.get('algorithm/product/' + userData.contactId).then(async product => {
                if (product)
                  axios.put('user/isAlgorithm/' + userData.userId);
                await setAlgorithm();
                window.location.href = '/admin/BuyerDashboard';
                // history.push('/admin/channel');
              })
            }
            else if (userData.channelTypeId == 2 && channel) {
              axios.put('user/isAlgorithm/' + userData.userId);
              await setAlgorithm();
              window.location.href = '/admin/vendorDashboard';
            }
          }).catch(err => {
            if (userData.channelTypeId != 2)
              window.location.href = '/admin/BuyerDashboard';
            // history.push('/admin/channel');
            else if (userData.channelTypeId == 2)
              //history.push('/admin/vendorDashboard');
              window.location.href = '/admin/vendorDashboard';
          });
        }
        else {
          // if((userData.channelTypeId == 2&&first_completion_count==4)||(userData.channelTypeId != 2&&first_completion_count==2))      
          //     window.location.href = userData.channelTypeId == 2 ? '/admin/vendorDashboard' : '/admin/BuyerDashboard';
          // else

          if (localStorage.getItem('eventRedirect') && JSON.parse(localStorage.getItem('eventRedirect')).firstLogin == '1') {
            let obj = JSON.parse(localStorage.getItem('eventRedirect'));
            localStorage.setItem('eventRedirect', JSON.stringify({ ...obj, firstLogin: null }));
            history.push(obj.redirectPage);
          }
          else if (localStorage.getItem('eventRedirect') && JSON.parse(localStorage.getItem('eventRedirect')).firstLogin == '0') {
            let obj = JSON.parse(localStorage.getItem('eventRedirect'));
            localStorage.setItem('eventRedirect', JSON.stringify({ ...obj, firstLogin: null }));
            history.push(obj.redirectPage);
          }
          else if (localStorage.getItem('eventDetails') && JSON.parse(localStorage.getItem('eventDetails')).firstLogin == '1') {
            let obj = JSON.parse(localStorage.getItem('eventDetails'));
            localStorage.setItem('eventDetails', JSON.stringify({ ...obj, firstLogin: null }));
            history.push('events/prevEvent');
          }
          else if (localStorage.getItem('eventDetails') && JSON.parse(localStorage.getItem('eventDetails')).firstLogin == '0') {
            let obj = JSON.parse(localStorage.getItem('eventDetails'));
            localStorage.setItem('eventDetails', JSON.stringify({ ...obj, firstLogin: null }));
            history.push('admin/events/prevEvent');
          }
          else
            userData.channelTypeId == 2 ? history.push('/admin/vendorDashboard') : history.push('/admin/BuyerDashboard');
        }

      }
      else {
        //get New ProfileStrength
        await axios.get('/channel/profileStrength').then(res => {
          score = res.data;
          setScore(score);
        });

        let eventInfo; let packOffers = []; let vatPercentage; let vatAmount; let offerAmount; let vatNumber; let isVatCompany;
        let totalSplit=[]; let currentSplit={};
        let unpaidPaymentList;let paidCount;
        let offersFlag = false;
        await axios.get('/channel/checkEventSignup').then(async res => {
          // console.log("eventsignup:", res.data);
          
          if (res.data) {
            eventInfo = res.data["eventInfo"][0];
            unpaidPaymentList = res.data["totalSplit"].filter(x => x.eventId == eventInfo.eventId && x.contactId == userData.contactId && x.paymentStatus == '0' && x.wireTransfer==0);

            paidCount =  res.data["totalSplit"].filter(x => x.eventId == eventInfo.eventId && x.contactId == userData.contactId && x.paymentStatus == '1').length;

            let allPaymentList = res.data["totalSplit"].filter(x => x.eventId == eventInfo.eventId && x.contactId == userData.contactId);

            totalSplit = allPaymentList.sort((a, b) => a.splitId - b.splitId);
            currentSplit = unpaidPaymentList.sort((a, b) => a.splitId - b.splitId)[0];
          }

          if (eventInfo?.price > 0 && unpaidPaymentList.length){// && eventInfo.paymentstatus != 1) {//} && res.data[0].offerIds && res.data[0].offerIds != '') {
            let offers = eventInfo?.offerIds != '' ? eventInfo?.offerIds.split(',') : [];
            await commonService.postService({ eventId:eventInfo.eventId,offers: offers,channelTypeId:userData.channelTypeId,regType:userData.eventRegisterAs==1?'Seller':'Buyer', channelId: userData.channelId, contactId: userData.contactId }, '/channelAdmin/packageOffers').then(res1 => {
              //console.log("res1:",res1.data);
              offersFlag = true;
              packOffers = res1.data.offers;
              vatPercentage = res1.data.hasVat;
              vatNumber = res1.data.vatNumber;
              isVatCompany = res1.data.isVatCompany;
              offerAmount = res1.data.offers.filter(x => x.offerType == 1).length ? res1.data.offers.filter(x => x.offerType == 1).reduce((a, curr) => parseInt(a) + parseInt(curr.couponOfferValue), 0) : 0;
            })
          }
        })

        await axios.get('/setting/profileList/' + userData?.channelId + '/' + typeId + '').then(res => {
          //console.log("profile:", res);
          // res.data.map((item) => {
          //   if (item.isCompleted)
          //     scoreValue += parseInt(item.value);
          // });


          if (typeId == 2) {
            //for vendor check company & user profiles should completed before add product
            let fistTwoStep = res.data.filter(x => {
              return (x.isCompleted == true && (x.order == 1 || x.order == 2));
            });
            setFirstTwoStep(fistTwoStep.length);
          }

          let pcount = res.data.filter(x => {
            return x.isCompleted == true;
          })
          //console.log("filck:", pcount, res.data.length);
          if (pcount.length == res.data.length && typeId == 2 && to != 'dashboard') {
            history.push('/admin/vendorDashboard');
          }
          else if (typeId != 2 && pcount.length == res.data.length) {
            history.push('/admin/BuyerDashboard');
          } else if (eventInfo?.price > 0 && unpaidPaymentList.length && paidCount==0){// && eventInfo.paymentstatus != 1) {


            const paymentDetails = {
              actualAmount: eventInfo.price,
              //package: [{ pacakgeId: eventInfo.packageId, price: eventInfo.price, packageName: eventInfo.packageName}],
              channelId: userData.channelId,
              contactId: userData.contactId,
              // finalAmount: eventInfo.price - offerAmount,
              finalAmount: currentSplit?.splitAmount,
              eventId: eventInfo.eventId,
              packageId: eventInfo.packageId,
              package: [{ packageId: eventInfo.packageId, packageName: eventInfo.packageName, price: eventInfo.price }],
              event_name: eventInfo.name,
              isPrimary: true,
              offerAmount: offerAmount,
              offers: packOffers,
              vatPercentage: vatPercentage,
              isVatCompany: isVatCompany,
              vatNumber: vatPercentage ? vatNumber : '',
              splitId: currentSplit?.splitId,
              paymentMethod: currentSplit?.paymentMethod,
              currCode:currentSplit?.currCode,
              pennyInvoiceId:currentSplit.pennyInvoiceId,
              totalSplit: totalSplit,
              discount:currentSplit.discount

            }

            localStorage.setItem('eventRedirect', JSON.stringify({ paymentDetails: paymentDetails }));
            history.push('/admin/Subscription/EventPayment');
          }
          profile = res.data;
          setProfile(profile);

          //score = scoreValue;
          //setScore(score);
          setLoading(false);
        }).catch((err) => {
          console.log(err)
          setLoading(false);
        });
      }
    })();

  }, []);


  return (
    <>
      {loading ? (
        <ModalSpinner />
      ) : (
        <>
          <SimpleHeader location={props.match} />
          <div id="dashboard" className="main-content">
            <Container className="mt-4" fluid>
              <Row>
                <Col className="card-wrapper" lg="12" md="12" sm="12">
                  <Card>
                    <CardBody style={{ padding: "1rem" }}>
                      <h3 className="mb-2">Hello {userData?.firstName}</h3>
                      <p className="mb-0">
                        Congratulations, your application to join ChannelHub has
                        been approved. We now need to collect some additional
                        information about your company, yourself, your products
                        and your target export markets. Note that a you need to
                        reach a minimum of 65% profile strength to receive
                        suggestions.
                      </p>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col className="card-wrapper" lg="12" md="12" sm="12">
                  <Card className="pl-3">
                    <CardBody>
                      <Row className="align-items-center">
                        <Col className="col-lg-8 col-md-12 col-sm-12">
                          <ListGroup flush>
                            {/* <Link to="admin/completeProfile"> */}
                            <div
                              className="timeline timeline-one-side"
                              data-timeline-axis-style="dashed"
                              data-timeline-content="axis"
                            >
                              {profile &&
                                profile.map((item) => (
                                  <div className="timeline-block">
                                    {/* for success of step */}
                                    {/* <span className="timeline-step badge-success">
                      <i className="ni ni-check-bold" /> */}
                                    <span
                                      className={
                                        item.isCompleted
                                          ? "timeline-step badge-success"
                                          : "timeline-step badge-warning"
                                      }
                                    >
                                      <i
                                        class={item.icon}
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                    <ListGroupItem
                                      className="list-group-item-action"
                                      tag="a"
                                    >
                                      {item.value !== "10" ? (
                                        <Link
                                          to={{
                                            pathname: "/admin/completeProfile",
                                            index: {
                                              value: +item.order,
                                              isCompleted: item.isCompleted
                                                ? true
                                                : false,
                                            },
                                          }}
                                        // onClick={e => item.isCompleted && (+item.order != 6) ? e.preventDefault() : ''}
                                        >
                                          <Row className="align-items-center">
                                            <Col className="col-auto"></Col>
                                            <div className="col ml--2">
                                              <div className="d-flex justify-content-between align-items-center">
                                                <div>
                                                  <h4 className="mb-0 text-sm">
                                                    {item.heading}
                                                  </h4>
                                                </div>
                                                <div className="text-right text-muted">
                                                  <i
                                                    class="fa fa-chevron-right"
                                                    aria-hidden="true"
                                                  ></i>
                                                </div>
                                              </div>
                                              <p className="text-sm mb-0">
                                                {item.desc}
                                              </p>
                                            </div>
                                          </Row>
                                        </Link>
                                      ) : (
                                        <Row className="align-items-center">
                                          <Col className="col-auto"></Col>
                                          <div className="col ml--2">
                                            <div className="d-flex justify-content-between align-items-center">
                                              <div>
                                                <h4 className="mb-0 text-sm">
                                                  {item.heading}
                                                </h4>
                                              </div>
                                              <div className="text-right text-muted">
                                                <i
                                                  class="fa fa-chevron-right"
                                                  aria-hidden="true"
                                                ></i>
                                              </div>
                                            </div>
                                            <p className="text-sm mb-0">
                                              {item.desc}
                                            </p>
                                            <div className="">
                                              {profile[0].isCompleted ===
                                                true && profile[1].isCompleted === true ? (
                                                <Link
                                                  to={{
                                                    //pathname:"/admin/Myproductlist/Productform",
                                                    pathname:'/admin/Myproductlist/AddProduct',
                                                    index: {
                                                      value: +item.order,
                                                      isCompleted:
                                                        item.isCompleted
                                                          ? true
                                                          : false,
                                                    },
                                                  }}
                                                // onClick={e => item.isCompleted && (+item.order != 6) ? e.preventDefault() : ''}
                                                >
                                                  <span class="badge badge-info badge-pill">
                                                    Add Single Product
                                                  </span>
                                                </Link>
                                              ) : (
                                                <span
                                                  class="badge badge-info badge-pill"
                                                  onClick={() =>
                                                    ToastsStore.error(
                                                      "please complete company profile first and personal profile"
                                                    )
                                                  }
                                                >
                                                  Add Single Product
                                                </span>
                                              )}

                                              {profile[0].isCompleted ===
                                                true &&
                                                profile[1].isCompleted ===
                                                true ? (
                                                <Link
                                                  to={{
                                                    pathname:
                                                      "/admin/startupprodimport",
                                                    index: {
                                                      value: +item.order,
                                                      isCompleted:
                                                        item.isCompleted
                                                          ? true
                                                          : false,
                                                    },
                                                  }}
                                                // onClick={e => item.isCompleted && (+item.order != 6) ? e.preventDefault() : ''}
                                                >
                                                  <span class="badge badge-primary badge-pill">
                                                    Add Bulk Products
                                                  </span>
                                                </Link>
                                              ) : (
                                                <span
                                                  class="badge badge-primary badge-pill"
                                                  onClick={() =>
                                                    ToastsStore.error(
                                                      "please complete company profile first and personal profile"
                                                    )
                                                  }
                                                >
                                                  Add multiple product
                                                </span>
                                              )}
                                            </div>
                                          </div>
                                        </Row>
                                      )}
                                    </ListGroupItem>
                                  </div>
                                ))}
                            </div>
                          </ListGroup>
                        </Col>
                        <Col className="col-lg-3">
                          <div className="row justify-content-center">
                            <h3 className="mb-3">Profile Strength</h3>
                            <ProggressBar value={score} />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              {/* <Initialprofile/> */}
            </Container>
          </div>
        </>
      )}
      {/* <Wiretransfer/> */}
      {/* <Userprofileform/> */}
      {/* <Footer/> */}
      {/* <Navbarg/> */}
      {/* { <GuestBlog/>} */}
    </>
  );
}


export default Dashboard;