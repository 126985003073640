
import React,{useState} from "react";
import classnames from "classnames";
import {Collapse,NavbarToggler,UncontrolledDropdown,DropdownToggle,DropdownMenu,DropdownItem, Card, CardBody,Button,ButtonGroup } from "reactstrap";
import axios from 'axios';
import { Link } from "react-router-dom";
const GuestNavbar =(props)=> {
  const [state, setstate] = useState(0);
  const [collapsed, setCollapsed] = useState(true);
  const toggleNavbar = () => setCollapsed(!collapsed);
    return (
      <>
<nav id="guestNav" className="navbar navbar-expand-md navbar-light bg-light bg-nav sticky-top">
          <Link to="/" className="navbar-brand saf-nav">
        <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  className="navImg" alt="ch-logo" src={require('assets/img/brand/ch-logo.svg')}/>
          </Link>
        <NavbarToggler onClick={toggleNavbar} className="mr-2 " />
        <Collapse className="taketr" isOpen={!collapsed} navbar>
               <div className="navbar-nav navbar-center fitt hov-less">
                   <Link to="/iambuyer" className="nav-item nav-link mr-3"><i class="fas fa-person-booth" style={{color:"#2c9db7"}}></i>  I'm a Buyer  </Link>
                   <Link to="/iamvendor" className="nav-item nav-link mr-3"><i class="fa fa-industry" style={{color:"#2c9db7"}} ></i>  I'm a Vendor  </Link>
                   <Link to="/Login" className="nav-item nav-link"><i className="fas fa-sign-in-alt"></i> Login  </Link>
            </div>
            <UncontrolledDropdown nav inNavbar className="float-right flr ">
                   <DropdownToggle nav caret>
                   <Link  to="" className="nav-item nav-link mr-3"><i class="fa fa-calendar" style={{color:"#2c9db7"}} ></i>Events</Link>
                    </DropdownToggle>
         
                     <DropdownMenu right>
                       {/* <DropdownItem >
                       <div onClick={()=>{
                         window.open("https://www.buyer-insider.com/",'_blank');
                       }} className="nav-item nav-link">Buyer Insider Sessions</div> 
                     </DropdownItem>
                   <DropdownItem divider />
                       <DropdownItem >
                       <div onClick={()=>{
                         window.open("https://www.globalretailconnect.com/",'_blank');
                       }} className="nav-item nav-link">Global Retail Connect</div> 
                     </DropdownItem>
                   <DropdownItem divider /> */}
                   <DropdownItem >
                       {/* <div  onClick={()=>{
                         window.open("https://www.channel-summit.com/",'_blank');
                       }} className="nav-item nav-link">Channel Summit</div>  */}
                        <div  onClick={()=>{
                         window.open("https://www.channel-summit.com/emea",'_blank');
                       }} className="nav-item nav-link">Channel Summit EMEA</div> 
                        <div  onClick={()=>{
                         window.open("https://www.channel-summit.com/mena",'_blank');
                       }} className="nav-item nav-link">Channel Summit MENA</div> 
                     </DropdownItem>
             
              </DropdownMenu>
            </UncontrolledDropdown>
            
          
        </Collapse>
    </nav>
    
    {/* <Card className="mb-0">
    <CardBody className="text-right">
    <ButtonGroup className="btn-group-toggle" data-toggle="buttons">
          <Button className={classnames({ active: state === 0 })} color="secondary" onClick={() => setstate(0)}>
            <input
              autoComplete="off"
              name="options"
              type="radio"
              value={state=== 0}
            />
            Consumer Products
          </Button>
          <Button className={classnames({ active: state === 1 })} color="secondary" onClick={() => setstate(1)}>
            <input
              autoComplete="off"
              name="options"
              type="radio"
              value={state=== 1}
            />
            B2B Products
          </Button>
         
        </ButtonGroup>
    </CardBody>
    </Card> */}
      </>
    );
  }
export default GuestNavbar;