import React, { useState } from "react";
import { Card,CardBody,FormGroup,Form, Container,Col,Row,Table,Button } from "reactstrap";
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import FilledInput from '@material-ui/core/FilledInput';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
const PayCard = (props) => {
   
return (
<div className="main-content payCard" id="PaymentConfirm">
{/* <Title desc={desc} /> */}
        <Container className="mt-4 col-lg-9 col-md-9 col-sm-12" fluid>
   
        <Card className="p-3">
        <h1 className="ml-3 mt-3">Pay With Card</h1>
        <hr className="m-0 ml-3 paycarhr" style={{width: "14%"}}/>
            <Row className="justify-content-center pb-5">
        
                <Col lg="8" >
                <CardBody>
                  <div className="mt-4">
                    <Form className="form-primary" role="form">
                    <Row>
                      <Col lg="12">
                      <FormGroup>
                        
                              
                      <FormControl fullWidth variant="outlined">
          <InputLabel htmlFor="outlined-adornment-amount">Email</InputLabel>
          <OutlinedInput
            id="outlined-adornment-amount"
            placeholder="Email"
            startAdornment={<InputAdornment position="start">
                <i class="fas fa-envelope"></i>
            </InputAdornment>}
            labelWidth={60}
          />
        </FormControl>
                      </FormGroup>
                      <FormGroup>
                      <FormControl fullWidth variant="outlined">
          <InputLabel htmlFor="outlined-adornment-amount">Card Number</InputLabel>
          <OutlinedInput
            id="outlined-adornment-amount"
            placeholder="Card number"
            startAdornment={<InputAdornment position="start">
                <i class="ni ni-credit-card"></i>
            </InputAdornment>}
            labelWidth={20}
          />
        </FormControl>
                      </FormGroup>
                      </Col>
                          </Row>
                      <Row>
                        <Col sm="6">
                          <FormGroup>
                          <FormControl fullWidth variant="outlined">
          <InputLabel htmlFor="outlined-adornment-amount">MM/YY</InputLabel>
          <OutlinedInput
            id="outlined-adornment-amount"
            placeholder="MM/YY"
            startAdornment={<InputAdornment position="start">
                <i class="ni ni-credit-card"></i>
            </InputAdornment>}
            labelWidth={20}
          />
        </FormControl>
                          </FormGroup>
                        </Col>
                        <Col sm ="6">
                          <FormGroup>
                          <FormControl fullWidth variant="outlined">
          <InputLabel htmlFor="outlined-adornment-amount">CCV</InputLabel>
          <OutlinedInput
            id="outlined-adornment-amount"
            placeholder="CCV"
            startAdornment={<InputAdornment position="start">
                <i class="ni ni-lock-circle-open"></i>
            </InputAdornment>}
            labelWidth={20}
          />
        </FormControl>
                          </FormGroup>
                        </Col>
                      </Row>
                   <Row>
                       <Col>
                       <Button block color="primary" type="button">
                      Pay €999.00
                      </Button>
                       </Col>

                   </Row>
                    </Form>
                  </div>
                </CardBody>
                </Col>
            </Row>
              </Card>
              </Container>
      </div>
    
   
  );
};



export default PayCard;