import React, { useState } from 'react';
import { Document, Page ,pdfjs } from 'react-pdf';

function PdfViewer(props) {
  const { pdf }=props;
  pdfjs.GlobalWorkerOptions.workerSrc =  
  `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`; 
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
 
  return (
    <div id="pdfPreview">
      <Document
      file={pdf} 
        onPassword={()=>{}}
        onLoadSuccess={onDocumentLoadSuccess}
        renderMode="svg"
      >
      
        <Page   scale={1.5} pageNumber={1} />
      </Document>
      {/* <div style={{position:'relative'}} className="BrandName">Pdf 
{props.edit &&
       <i  aria-hidden="true" class="fas fa-edit" style={{position: 'absolute',
right: '0px',zIndex: 9999}}></i>}
</div> */}
    </div>
  );
}

export default PdfViewer;