import React,{useEffect,useState} from "react";
import { Col, Container, Row, Card,CardBody,Button,UncontrolledTooltip ,ListGroupItem, ListGroup, } from "reactstrap";
import logo from 'assets/img/brand/ch-logo.svg'

import { yupResolver } from '@hookform/resolvers/yup';
import { FormTextArea,FormCheckBox,FormAutocomplete,FormInput  } from "_components/FormElements/FormInput";
import { useForm} from "react-hook-form";
import { companyProfileSchema } from "./validation"
import { commonService } from '_services/common.service';
import {cartSchema} from './validation';
import { ModalSpinner } from "views/Hoc/Spinner";

const BillingAddress = (props) => {
    const {control, errors,register, handleSubmit,watch} = useForm({      
        mode:'onBlur',  
        resolver: yupResolver(cartSchema),
      });
      const check = () => {
        // setTandc(!tandc);
        // setErr(tandc);
        // console.log('tandc', tandc)
      }
    const [state,setState] = useState([]);
    const [loading, setLoading] = useState(false);
    const [editData,setEditData] = useState({
      name:'',
      city:'',
      address:'',
      pincode:'',
      phone:'',
      altphone:'',
      country:'',      
      addressid:0,
      countryOption:[]      
    });
    const [flag,setFlag] = useState(false);
    const [addressId, setAddressId] = useState('');
    let {addFlag, selectBilling}=props;
    
        
    useEffect(() => {      
        getCountry();
        getBillingAddress();
    }, []);

    useEffect(() => {    
      setFlag(false);        
    }, [addFlag]);
    
    const getBillingAddress= async()=>{
        let res  = await commonService.getServiceWithParams({
          type:'billing',          
        },'/addressbook');        
      setState(res.data);        
      if(res && res.data.length == 0){
        let res1 = await commonService.getServices('channel/billingAddress').then(res => res.data).catch(err => console.log('errorBilling', err));
        if(JSON.stringify(res1)!=='{}'){
          let temp = {
            name:'',
            city:res1.city,
            address:res1.address,
            pincode:res1.postalCode,
            phone:'',
            altphone:'',
            country:{id: res1.countryId, name: res1.country.country},      
            addressid:0,      
          }
          setEditData({...editData, ...temp});
        }
        setFlag(true);
      }
      setLoading(false);
    }

    let watchAll = watch(['name', 'address', 'city', 'pincode', 'phone']);

    const getCountry= async()=>{
      let res  = await commonService.getServices('user/country');              
      editData.countryOption = res.data;
      setEditData({...editData,countryOption:res.data});        
    }

    const addressEdit= async(id)=>{
      setFlag(false);

      let res  = await commonService.getServices('/addressbook/'+id);            
      let country=editData.countryOption.filter(x => x.id==res.data.countryid);       
      setEditData({...editData,name:res.data.name,
        city:res.data.city,
        pincode:res.data.postalcode,
        address:res.data.address,
        phone:res.data.contactno1,
        country:country[0],
        addressid:res.data.addressid,
        altphone:res.data.contactno2});
        
      setFlag(true);

    }

    console.log('errors', errors);

    const addressDelete= async(id)=>{
      setLoading(true);
      await commonService.deleteService('/addressbook/'+id).catch(err => setLoading(false));
      getBillingAddress();
      setFlag(false);
    }

    const handleChange = input => e => {          
      setEditData({...editData, [input] : e.target.value});
    }

    const handleChangeSelect = (input,value) => {             
      setEditData({...editData, [input]:value});   
    }

    const save = async() => {
      setLoading(true);
      let id=editData.addressid;
      let data={
        name:editData.name,
        primary_contactno:editData.phone,
        alternative_contactno:editData.altphone?editData.altphone:'',
        address: editData.address,
        countryId:+editData.country.id,
        postalCode:editData.pincode,
        city:editData.city,
        isshippingaddress:false,
        isbillingaddress:true,
      }
      
      if(id!=0)
      {
        await commonService.putService(data,'/addressbook/'+id).catch(err => setLoading(false));                
        getBillingAddress();
        setFlag(false);
      }
      else
      {
        await commonService.postService(data,'/addressbook').catch(err => setLoading(false));        
        getBillingAddress();
        setFlag(false);
      }
      reset();
      //console.log("save:",res);
    }

    const reset = () => {  
      setEditData({...editData,
        name:'',
        city:'',
        address:'',
        pincode:'',
        phone:'',
        altphone:'',
        country:'',      
        addressid:0,      
      });           
    
      setFlag(false);
    }

    const handleChange1 = input => e => {
      setAddressId(e.target.value);
      props.setData({'billingAddressId': e.target.value});
      let billing = state.filter(x => x.addressid == e.target.value);
      let country=editData.countryOption.filter(x => x.id==billing[0].countryid);       
      selectBilling({name:billing[0].name,
        city:billing[0].city,
        pincode:billing[0].postalcode,
        address:billing[0].address,
        phone:billing[0].contactno1,
        country:country[0],
        addressid:billing[0].addressid,
        altphone:billing[0].contactno2});
    }

    
  return (<>
    {loading ? <ModalSpinner /> : 
      <>
    <div className="listbill">
  
                      <Row>
                        <Col lg="5" className="pad-less">
                          <Row>
                            <Col lg="4"><h3 className="text-center">Name</h3></Col>
                            <Col lg="4"><h3 className="text-center">Address</h3></Col>
                            <Col lg="4"><h3 className="text-center">City</h3></Col>
                          </Row>
                        </Col>
                        <Col lg="7" className="pad-less">
                        <Row>
                            <Col lg="3"><h3 className="text-center">Country</h3></Col>
                            <Col lg="3"><h3 className="text-center">Pincode</h3></Col>
                            <Col lg="3"><h3 className="text-center">Phone / Mobile No</h3></Col>
                            <Col lg="2"><h3 className="text-center">Action</h3></Col>
                            <Col lg="1"><i class="fas fa-plus-circle faplus1 mt-3"></i></Col>
                          </Row>
                        </Col>
                      </Row> 
                      </div>
                      <Card className="w-100 mt-3 listbill1">
                    <CardBody>

                  <ListGroup className="list my--3" flush>
                      <ListGroupItem className="px-0">
                   
                   {state.map(item => (
                         <Row>
                        <Col lg="5" className="pad-less">
                          <Row>
                            <Col lg="4">
                              <Row>
                              <Col lg="4" className="pt-3">
                            <div className="">
                              <FormCheckBox
                              id={"billingcheck"+item.addressid}
                              type="checkbox"
                              // label={err ? labelErrText : labelText}
                              // label1={{textAlign:"justify"}}
                              register={register}
                              // onClick={check}
                              value={item.addressid}
                              checked={addressId == item.addressid}
                              onChange={handleChange1('address')}
                              /></div></Col>
                              <Col lg="8" className="pad-less">
                              <h4 className="text-center">{item.name}</h4></Col></Row></Col>
                            <Col lg="4"><h4 className="text-center">{item.address}</h4></Col>
                            <Col lg="4"><h4 className="text-center">{item.city}</h4></Col>
                          </Row>
                        </Col>
                        <Col lg="7" className="pad-less">
                        <Row>
                            <Col lg="3"><h4 className="text-center">{item.country.country}</h4></Col>
                            <Col lg="3"><h4 className="text-center">{item.postalcode}</h4></Col>
                            <Col lg="3"><h4 className="text-center">{item.contactno1}</h4></Col>
                            <Col lg="2">
                              <Row>  
                              <Col lg="6" className=" bill text-right pad-less "><i class="fas fa-eye f mt-3" onClick={() => addressEdit(item.addressid)}></i></Col>
                              <Col lg="6" className="bill text-right pad-less "><i class="fas fa-trash-alt  mt-3" onClick={() => addressDelete(item.addressid)}></i></Col>
                              </Row>
                              </Col>
                              <Col lg="1"></Col>
                          </Row>
                        </Col>
                      </Row>                  
                  ))}

                      </ListGroupItem>
                    </ListGroup>
                </CardBody>
        </Card>

            
            <Card className=" col-lg-12 col-md-8 col-sm-6 container-fluid numcard">
          
              <CardBody >
              <div className=" row">
               <Col lg="4"></Col>
               <Col lg="4"></Col>
               <Col lg="2"></Col>
               <Col lg="2"><button type="submit" onClick={() => setFlag(true)} className="btn btn-primary mb-3">Add Billing Address</button></Col></div>

               {flag && <>
              <form noValidate onSubmit={handleSubmit(save)}>
              <Row >
               <Col lg="6" className="pad-less">
                   <Row>
                       <Col lg="12">
                       <FormInput 
                        className="comp-name mt-2" 
                        id="name" 
                        name="name" 
                        label="Name *"
                        placeholder="Test Distribitor" 
                        type="text" 
                        variant="outlined" 
                        input="name"
                        value={watchAll['name']} 
                        defaultValue={editData.name} 
                        error={errors.name}
                        register={register}
                        onChange={handleChange('name')}
                        //value={watchAll.companyName}

                        />
      
                       </Col>
                       <Col lg="12"  className="mt-4">
                       
                       <FormAutocomplete
                        id="country"
                        name="country"
                        label="Country"
                        options={editData.countryOption}
                        placeholder="Select Country"
                        // multiple
                        // disableCloseOnSelect
                        // blurOnSelect
                        limitTags={1}
                        control={control}                         
                        input='country'
                        value={ editData.country }
                        handleChangeSelect={handleChangeSelect}
                        errMessage={'Please Select Country'}
                        error={errors.country }
                        defaultValue={editData.country}
                        register={register}
                        />
                      
                      </Col>
                   </Row>
                 
               </Col> 
               <Col lg="6" sm="6" className="text-center ">
                <div className=" mx-auto text-center">            
                <FormTextArea
                  className="buytext2"
                  name="address"
                  id="address" 
                  rows="3"
                  type="textarea"
                  label=" "
                  placeholder="Address *"
                  input="address"
                  value={watchAll['address']}
                  defaultValue={editData.address} 
                  error={errors.address}
                  register={register}
                  onChange={handleChange('address')}
                 />
                </div>
                </Col>  
                </Row>

                <Row >
                   <Col lg="6" className="mt-2">
                   <FormInput
                        // className="comp-name mt-2" 
                        id="city"
                        name="city"
                        label="City *"
                        // options={state.countryList}
                        // placeholder="Select Country"
                        // multiple
                        // disableCloseOnSelect
                        // blurOnSelect
                        limitTags={1}
                        control={control}                         
                        input="city"
                        value={watchAll['city']}
                        defaultValue={editData.city}
                        error={errors.city}
                        register={register}
                        onChange={handleChange('city')}
                        // errMessage={'Please Select Country'}
                        // error={errors.country }
                        // defaultValue={state.country}
                        />
                      
                      
                    </Col>
                       
                       
                    <Col lg="6"  className="mt-2">
                      <FormInput 
                        // className="comp-name mt-2" 
                        id="pincode" 
                        name="pincode" 
                        label="Pincode *"
                        placeholder="" 
                        type="text" 
                        variant="outlined" 
                        input="pincode"
                        value={watchAll['pincode']} 
                        defaultValue={editData.pincode} 
                        error={errors.pincode}
                        register={register}
                        onChange={handleChange('pincode')}
                        // value={state.companyName} defaultValue={state.companyName} error={errors.companyName}
                        // register={register}
                        // value={watchAll.companyName}

                        />
                    </Col>
                   </Row>


                   <Row>
                    <Col lg="6">
                    <FormInput 
                    className="comp-name mt-4" 
                    id="phone" 
                    name="phone" 
                    label="Phone/Mobile No *" 
                    type=""
                    placeholder="123-467-890" 
                    input="phone"
                    value={watchAll['phone']} 
                    defaultValue={editData.phone} 
                    error={errors.phone}
                    register={register}
                    onChange={handleChange('phone')}
                    // variant="outlined" 
                    // value={state.phoneNumber1} defaultValue={state.phoneNumber1}
                    // onChange={handleChange('phoneNumber1')}
                    />
                    </Col>
                    <Col lg="6">
                    <FormInput 
                    className="comp-name mt-4" 
                    id="altphone" 
                    name="altphone" 
                    label="Alternate Contact No" 
                    type=""
                    placeholder="123-467-890" 
                    input="altphone"
                    value={editData.altphone} defaultValue={editData.altphone} error={errors.altphone}
                    register={register}
                    onChange={handleChange('altphone')}
                    // variant="outlined" 
                    // value={state.phoneNumber2} 
                    // onChange={handleChange('phoneNumber2')}
                    />
                    </Col>
                    </Row>
           
                    
                       <div className="text-center mt-6">
            <button type="submit" className="btn btn-primary"  >Save</button> {/* onClick={() => save(editData.addressid)} */}
            <button  className="btn btn-warning" onClick={() => reset()}>cancel</button> 
            </div>      
            </form>
            </> 
                    } 

              </CardBody >
            </Card>
            
           
            
    </>}
    </>
  );
}

export default BillingAddress;