import React, { useState, useEffect } from 'react';
import { FormInput } from "_components/FormElements/FormInput";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { resetPwdschema } from '../../pages/SignupPage/Validation';
import { commonService } from '_services/common.service';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import Logo from 'views/pages/LoginPage/login-logo.js';
import { CardBody, Container } from "reactstrap";
import { Card } from 'antd';
import { Spinner } from "views/Hoc/Spinner";
import { InputAdornment, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { history } from '../../../_helpers/history';
export const SetPassword = (props) => {
  let [formValue, setFormValue] = useState({ password: null, rePassword: null });
  let [isLoading, setLoading] = useState(true);
  let [exp_err, setExp_error] = useState(false);
  // let [matchErr, setMatchErr] = useState(false);
  // const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);

  const handleClickShowPassword1 = () => { setShowPassword1(!showPassword1); }
  const { register, handleSubmit, errors } = useForm({
    mode: 'onChange',
    resolver: yupResolver(resetPwdschema),
  });
  useEffect(() => {
    checkToken();
  }, [])

  const checkToken = async () => {
    setLoading(true);
    console.log("forgotOnSubmit:", props.match.params.token);
    let res = await commonService.getServices('account/setPassword/' + props.match.params.token).catch(err => setExp_error(true));
    setLoading(false);
    if (res) {
      // setEmail(null);
    }
  }
  const onSubmit = async (data) => {
    setLoading(true);
    console.log("forgotOnSubmit:", data);
    let res = await commonService.postService({ password: data.password }, 'account/setPassword/' + props.match.params.token).catch(err => ToastsStore.warning('Something went wrong!...'));
    ToastsStore.success('You have set your password,Please login to continue')
    setLoading(false);
    if (res) {
      setTimeout(()=>history.push('/login'),2000);
    }
  }
  return (
    <div className="main-content">
      {isLoading && <Spinner />}
      <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore} />
      <Container fluid className="mt-8 justify-content-center wek-cent">
        <Card className=" col-5 mt-12 container-fluid numcard">
          {!exp_err ? <CardBody>
            <div className="text-center mt-2">
              <Logo />
              <h3>Create your password</h3>
            </div>
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
              <div className="text-center mt-4">

                <div className="col-lg-12">
                  <div className="form-group">
                    <FormInput
                      id="password"
                      type="password"
                      label="Enter New Password"
                      name="password"
                      error={errors.password}
                      value={formValue.password}
                      register={register}
                      InputProps={{}}
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <FormInput
                      id="rePassword"
                      type={showPassword1 ? "text" : "password"}
                      label="Confirm Password"
                      name="rePassword"
                      error={errors.rePassword}
                      value={formValue.rePassword}
                      register={register}
                      defaultValue=''
                      hintText="Password"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword1}
                            >
                              {showPassword1 ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  </div>
                </div>
                <div className="text-center mt-4">
                  <button type="submit" className="btn btn-warning">Set Password</button>
                </div>
                <div className="col-lg-12 mt-3">
                <span style={{ fontSize: "0.7rem" }}><strong>*Note: Password must contain at least 1 uppercase(A-Z), 1 lowercase(a-z), 1 number(0-9) and 1 special character(*$@^)</strong></span>
                </div>
              </div>
            </form>
          </CardBody> :
            <p style={{ color: 'red' }}>Token expired</p>}
        </Card>
      </Container>
    </div>
  );
}
export default SetPassword;