import * as yup from "yup";

const helpSupportSchema = yup.object().shape({
    subject: yup.string().required("Please Enter Subject"),
    message: yup.string().required('Please Enter Message'),
    typeOfSupport: yup.string().required('Select Type Of Support'),
});

const guestSupportSchema = yup.object().shape({
    firstname: yup.string().required("Please Enter First Name"),
    lastname: yup.string().required("Please Enter Last Name"),
    email: yup.string().email("Mail id invalid").required("Please Enter Email"),
    phoneno: yup.string().required("Please Enter Phone"),
    mobileno: yup.string().required("Please Enter Mobile"),
    country: yup.string().required("Please Select Country"),
    subject: yup.string().required("Please Enter Subject"),
    message: yup.string().required('Please Enter Message'),
    typeOfSupport: yup.string().required('Please Select Type Of Support')
});

const cartSchema = yup.object().shape({
    name: yup.string().required("Please Enter Name"),
    country: yup.string().required("Please Select Country"),
    address: yup.string().required('Please Enter Address'),
    city: yup.string().required('Please Enter City'),
    pincode: yup.string().required('Please Enter Postal Code'),
    phone: yup.string().required('Please Enter Phone Number'),
})

export {helpSupportSchema, guestSupportSchema, cartSchema};