import React, { useState, useEffect } from "react";
import { ListItem } from "_components/Listing/ListItem";
import { Badge } from "reactstrap";
import { Card, CardBody, Row, Col } from "reactstrap";
import TablePagination from '@material-ui/core/TablePagination';
import { TablePaginationActions } from 'views/Hoc/pagination/PaginationActions';
import { commonService } from '_services/common.service';
import { Spinner } from "views/Hoc/Spinner";
import CountryFlag from "views/Hoc/countryFlag/countryFlag";
import Favourities from 'views/Hoc/Dialog/Favourities'
import { history } from '../../../_helpers/history';
import axios from 'axios';

export const FavChannelListing = (props) => {
  const { groupId, callTabCount } = props;
  let [page, setPage] = useState(0);
  let [rowsPerPage, setRowsPerPage] = useState(10);
  let [total, setTotal] = useState(0);
  let [isLoading, setLoading] = useState(true);
  //   let user = JSON.parse(localStorage.getItem('CHuser'));
  let [data, setData] = useState([]);
  //   let [isUpgradePopup, setUpgradePopup]  = useState(false);

  useEffect(() => {
    if (groupId)
      callChannelList();
  }, []);

  const callChannelList = () => {
    page = 0;
    setPage(page);
    getChannelList();
  }
  const getChannelList = async () => {
    setLoading(true);
    let res = await commonService.postService('', 'channel/chfavorite/' + groupId).catch(err => setLoading(false));
    if (res) {
      data = res.data.result;
      setData(data);
      // total=res.data.total;
      // setTotal(total);
      console.log('data', data);
      if(data.length===0)
      props.openFavChannelGroup();
      setLoading(false);
    } else {
      data = [];
      setData(data);
      total = 0;
      setTotal(total);
      setLoading(false);
    }
  }

  const handleChangePage = (event, newPage) => {
    page = newPage;
    setPage(page);
    getChannelList();
  };

  const handleChangeRowsPerPage = (event) => {
    rowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(rowsPerPage);
    callChannelList();
  };
  const reCall = () => {
    getChannelList();
    callTabCount();
  }
  // const openUpgradePopup = ()=>{
  //   setUpgradePopup(true);
  // }
  // const closeUpgradePopup = ()=>{
  //   setUpgradePopup(false);
  // }
  return (

    <div id="iner" className="main-content mt-3">
      {/* <UpgradeModal open={isUpgradePopup} value={null} handleSave={closeUpgradePopup} handleClose={closeUpgradePopup}/> */}
      {(data.length <= 0 && !isLoading) && <div className="no-records ">
        {/* <span>No Records</span> */}
        <div className="no-img text-center">
          <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  className="img-fluid" alt="ch-logo" src={require("../../../assets/img/medialibrary/no-data.png")} /></div>
      </div>}
      {isLoading ? <Spinner /> : <>
        {data.length > 0 && data.map((item, index) => {
          let logo = "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg";
          // let isConfidential = (((parseInt(data?.isconfidential) !== null && parseInt(data?.isconfidential) === 0) || (applyBlur && parseInt(data?.isconfidential) !==1) || (parseInt(data?.isconfidential) === null && data?.status === 'NonSuggestion' && user.planId==='1'))  && user.channelTypeId !== '4'&& parseInt(data?.channeltypeid) !==2)
          return (
            <Row className="justify-content-center">
              <Col className="card-wrapper ct-example" lg="12" md="12" sm="12" >
                <Card>
                  {/* <CardHeader>
                  <h3 className="mb-0">Styles</h3>
                </CardHeader> */}
                  <CardBody>
                    <Row className="align-items-center">
                      <Col className="col-lg-auto col-md-auto col-sm-12 mobi-res">
                        <a
                          style={{ width: "130px", height: "130px", border: "1px solid #b8b8b8", backgroundColor: "white" }}
                          className={"avatar avatar-xl mb-2"}
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}} 
                            alt="..."
                            src={item.logo ? 'http://d2maj8ifjo1lti.cloudfront.net/' + item.logo : logo}
                          />
                        </a>
                        <div className="pointer" onClick={() => history.push('/admin/favourites/channelDetail/' + item.cid)}>
                          <Badge style={{ backgroundColor: "#2c9db7", color: "white", fontSize: "0.8000rem", textTransform: "capitalize", width: "100%", }}>
                            +details
                          </Badge>
                        </div>
                        {item.webSiteUrl && <div className="text-center">
                          <a href={item.webSiteUrl} target="blank">
                            <i className="fas fa-globe-asia mr-1"></i>Website
                          </a>
                        </div>}
                      </Col>
                      <div className="col-lg-9 col-md-9 col-sm-12 mobi-res3">
                        <div className="row">
                          <div className="col-lg-2 col-md-2 col-sm-12 mobi-res1  res-padr1">
                            <Badge
                              style={{
                                backgroundColor: "#FF6347",
                                color: "white",
                                fontSize: "0.9000rem",
                              }}
                            >
                              {item.channelType}
                            </Badge>
                          </div>
                          {/* flag */}
                          <div className="col-auto col mobi-res2">
                            <a className="avatar avatar-xs rounded-circle">
                              {/* <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  alt="..." src={item.contacts.map((x) => { if(x.isDefaultContact=="1")return x.documentPath })?"http://d2maj8ifjo1lti.cloudfront.net/"+item.contacts.map((x) => { if(x.isDefaultContact=="1")return x.documentPath }):"http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"}/> */}
                              <CountryFlag code={item.isoCode} />
                            </a>
                          </div>

                          <div style={{ paddingLeft: "0px" }} className={"col-lg-4 col-md-3 col-sm-12 mobi-res pad-01"}>
                            <h3 className="mb-0">{item.country}</h3>
                          </div>
                        </div>
                        {/* First Row End */}
                        <Row>
                          <Col className="col-lg-auto col-md-auto col-sm-12 mobi-res2">
                            <h3 className={"mb-0 text-uppercase"}>
                              <a
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                {item.companyName}
                              </a>
                            </h3>
                          </Col>
                        </Row>
                        {/* Seoncd Row End */}
                        <Row>
                          <Col className="col-lg-7 col-md-6 col-sm-12">
                            <h4>
                              Specialty in/Categories:
                              <p style={{ display: "inline" }}>
                                {item.company_cat && <ListItem elements={item.company_cat} index={'cat' + index} limit={4} />}
                              </p>
                            </h4>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="col-auto">
                            <h3 className="mb-0 text-uppercase">
                              <a
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                Tweet Pitch
                              </a>
                            </h3>
                            <p>
                              {item.detailDesc.replace(/<[^>]+>/g, '')}
                            </p>
                          </Col>
                        </Row>
                      </div>

                      <Col className="col-lg-1 col-md-1 col-sm-12 mobi-res5">
                        {<Favourities channelId={item.cid} isFavorite={groupId} getChannelList={reCall} isFavoriteList={true} />}
                        <div className="pointer" onClick={() => history.push('/admin/favourites/channelDetail/' + item.cid)}>
                          <i
                            style={{ fontSize: "2em", cursor: "pointer" }}
                            className="far fa-eye float-right"
                          ></i>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>)
        })}
        {(data.length > 0 && data.length > 10) &&
          <TablePagination
            component="div"
            count={total}
            page={page}
            onChangePage={handleChangePage}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[10, 20, 30, 40]}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        }
      </>}
    </div>

  );
};
export default FavChannelListing;
