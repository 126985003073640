import React, { useState, useMemo, useEffect } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { Card, CardBody, Form, Container, Col, Row, Button } from "reactstrap";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import "_components/Stripe/styles.scss";
import { ToastsContainer, ToastsStore } from 'react-toasts';
import useResponsiveFontSize from "_components/Stripe/useResponsiveFontSize.js";
import { commonService } from '_services/common.service';
import { subscriptionAction } from '_actions/subscription.action';
import { connect, useSelector } from 'react-redux';
import { history } from '../../../../_helpers';
import { Spinner } from "views/Hoc/Spinner";
import FormGroup from '@material-ui/core/FormGroup';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
//import "./styles.scss";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY);

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4"
          }
        },
        invalid: {
          color: "#9e2146"
        }
      },
      hidePostalCode: true
    }),
    [fontSize]
  );

  return options;
};
const CardFormInter = () => {
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  let [isLoading, setLoading] = useState(false);
  const redux_data = useSelector(state => state.subscription);
  let [iscardComplete, setIsCardComplete] = useState(false);
  let userData = JSON.parse(localStorage.getItem('CHuser'));
  useEffect(() => {
    const subId = redux_data.select_plan;
    if (!subId) {
      history.push('/admin/Subscription');
      return;
    }
  }, [])
  const handleSubmit = async event => {
    event.preventDefault();
    setLoading(true);
    if (!stripe || !elements || !iscardComplete) {
      return;
    }

    let res = await commonService.postService({ amount: parseFloat(redux_data.pay_amt.replace(',', '.')) }, 'channel/subscription/createPaymentIntend').catch(err => console.log('err', ToastsStore.error(err.response.data.message)));
    if (!res) {
      return;
    }
    let paymentIntentSecret = res.data.paymentIntentSecret;
    const paymentResult = await stripe.confirmCardPayment(paymentIntentSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          email: userData.email,
          name: userData.firstName + ' ' + userData.lastName, //'Jenny Rosen',
        },
      },
    });
    if (paymentResult.error) {
      setLoading(false);
     // console.log(paymentResult.error.message);
      ToastsStore.error(paymentResult.error.message);
    } else {
      if (paymentResult.paymentIntent.status === "succeeded") {
      //  console.log(paymentResult);
        const subId = redux_data.select_plan;
        const response = await commonService.postService({
          subscriptionId: parseInt(subId.subscriptionId),
          paymentMethodId: parseInt(1),
          transactionId: null,
          paymentIntent: paymentResult.paymentIntent.id,
          couponCode: redux_data.couponCode || null
        }, 'channel/subscription/subscribe').catch(err => console.log('err', ToastsStore.error(err.response.data.message)));
        if (response) {
          ToastsStore.success("Payment Completed Successfully");
          let userData = JSON.parse(localStorage.getItem('CHuser'));
          // userData.subscriptionId=subId.subscriptionId;
          // userData.paymentMethodId=1;

          //console.log('this is testing data');

          //console.log('***************', userData);


          userData.planId = response.data.planId;
          userData.subscriptionId = +response.data.channelSubscription.subscriptionId;
          userData.featureList = response.data.channelSubscription.keyFeature;
          userData.planExpireAt = response.data.channelSubscription.endAt;
          userData.planName = redux_data.select_plan.title + ' - ' + redux_data.select_plan.month;
          userData.paymentMethodId = parseInt(1);
          localStorage.setItem('CHuser', JSON.stringify(userData));
          //console.log('***************', userData);

          await commonService.getServices('algorithm/channel').catch(err => console.log(err.response.data.message, ToastsStore.error('unable to create suggestion')));

          setLoading(false);
          history.push('/admin/PaymentSuccess');
        }
      }
    }

    // console.log(localStorage.getItem('stripe_payment_secret'));
    //  await stripe.handleCardPayment(localStorage.getItem('stripe_payment_secret')).then(function(result) {
    //     console.log(result)
    //   });;





    //console.log("[PaymentMethod] na vanthutaga", payload);
  };



  return (<div>
    <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore} />
    {isLoading && <Spinner />}
    <form onSubmit={handleSubmit}>

      Card details
      <CardElement
        options={options}
        onReady={() => {
          console.log("CardElement [ready]");
        }}
        onChange={event => {
          console.log("CardElement [change]", event);
          setIsCardComplete(event.complete);
        }}
        onBlur={() => {
          console.log("CardElement [blur]");
        }}
        onFocus={() => {
          console.log("CardElement [focus]");
        }}
      />

      <button type="submit" disabled={!stripe || !iscardComplete}>
        Pay
      </button>
    </form></div>
  );
};

const StripePayment = () => {
  let userData = JSON.parse(localStorage.getItem('CHuser'));
  return (
    <Container className="stripe1">
      <Card>
        <div className=" mt-1 pt-2">
          <div className="stripepay text-center">
            <Row className="justify-content-center pb-2">

              <Col lg="12" >
                <div className="pt-3">
                  <div >
                    <Form className="form-primary" role="form">
                      <Row>
                        <Col lg="12">
                          <FormGroup className="mb-4">


                            <FormControl fullWidth variant="outlined">
                              <InputLabel htmlFor="outlined-adornment-amount">Email</InputLabel>
                              <OutlinedInput
                                id="outlined-adornment-amount"
                                placeholder="Email"
                                value={userData.email}
                                disabled
                                startAdornment={<InputAdornment position="start">
                                  <i class="fas fa-envelope"></i>
                                </InputAdornment>}
                                labelWidth={60}
                              />
                            </FormControl>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="12">
                          <Elements stripe={stripePromise}>
                            <CardFormInter />
                          </Elements>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </div>
              </Col>
            </Row>

          </div>
        </div>
      </Card>
    </Container>

  );
};

function mapState(state) {
  const general_data = state.subscription;
  return general_data;
}

const actionCreators = {
  subscriptionRedux: subscriptionAction.subscriptionRedux
};

export default connect(mapState, actionCreators)(StripePayment);
