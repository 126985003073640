import React from 'react';
import YoutubeEmbed from './YoutubeEmbed';
import { Card, Col, Row } from "reactstrap";


const EventPresentationslide = () => {
    
    return (
        <div>
            <Card>
                {/* <div className='row'>
                    {itemData.map((data)=>(
                    <div className='col-md-3'>
                        <Card>
                            <div className='presentslide-image'>
                                <img src={data.img} />
                            </div>
                        </Card>
                    </div>
                    ))
                }
                </div> */}
                <div className='row'>
                  <div className='video-wrap'>
<YoutubeEmbed  embedId="0qQUaQstKfg"  />
 
                  </div>

                </div>
            </Card>
        </div>
    )
}
// YoutubeEmbed.propTypes = {
//   embedId: PropTypes.string.isRequired
// };
export default EventPresentationslide;
