import React, { useState, useEffect } from "react";
import { Card,CardBody,CardHeader, Container,FormGroup ,Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup ,UncontrolledTooltip} from "reactstrap";
  import classnames from "classnames";
import { useForm} from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import {QuillEditor,FormTextArea,FormInput, FormAutocomplete,FormRadio,SingleAutocomplete,FormAutocompleteGrouping,AddFieldWithPopUpAutocomplete} from "_components/FormElements/FormInput";
import Avatar  from 'react-avatar-edit';
import MediaLibrary from "views/Hoc/Dialog/MediaLibrary";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import {BrandCard,ProfileCard} from "views/Hoc/BrandCard";
import {FileUploaderBtn,ProfileUploader} from "views/Hoc/ProfileUpload";

import { history } from '../../../../_helpers';
import { dashboardActions } from '../../../../_actions';
import { InitialprofileSchema } from '../validation';

import InputAdornment from '@material-ui/core/InputAdornment';
import { Aws_service } from '.../../_services/aws-s3.service';
import AddBrand from "views/Hoc/Dialog/AddBrand";
import {useBeforeFirstRender} from '_hooks/index'
import axios from 'axios';
import ImageLibrary from 'views/Hoc/Dialog/ImageLibrary';

const InitialprofileDistributor = (props) => {
  
  const [value, setValue] = React.useState(0);
  const { register, handleSubmit, control, errors, watch } = useForm({      
    mode:'onBlur',  
    resolver: yupResolver(InitialprofileSchema),
  });

  const [state, setState] = useState({
    yearEstablished : '',
    areYouStartUp : '0',
    no_of_staffs : '',    
    annualTurnOver : '',
    companyRegNumber : '',
    productBrand : [],
    vatNumber : '',
    companyLogo : null,
    tweetPitch : '',
    introVideo : null,
    documentId : '',
    companyDescription : '',    
    brandValue : [],
    brandList:[],
    brandOption : [],
    productGroup:[],
    productValue : [],
    productFamilies : [],
    ChannelProfileValue:[],
    ChannelProfileOption : [],
    company_regid: null,
    isVat: null,
    no_of_skus : null,
    
  })

  const deleteBrand = (id) =>{    
    const filteredList = state.brandList.filter((item) => item.id !== id);    
    state.brandList=filteredList;
    setState({...state, brandList:state.brandList});

    if(state.brandList.length==0)
    {
      setState({...state, brandValue: []});
      errors.Brands={message: 'Please select a Brand', type:'required'};  
    }
  }

    //localStorage
    var userData = JSON.parse(localStorage.getItem('CHuser'));

  useBeforeFirstRender(() => {
    (async() =>{  
  await axios.get('setting/getInitialProfile/'+userData?.channelId).then(res =>{      
    state.productFamilies=res.data.productGroup;
    state.company_regid=res.data.country[0].registrationcertificate;
    state.isVat=res.data.country[0].isVat;
    state.ChannelProfileOption=res.data.profileList;

    state.brandOption=res.data.productBrand;

    setState({...state, brandOption:state.brandOption});
    setState({...state, productFamilies:state.productFamilies});
    setState({...state, ChannelProfileOption:state.ChannelProfileOption});
    setState({...state, company_regid:state.company_regid});
    setState({...state, isVat:state.isVat});
    
    
  })
    })();
    
})
  
const introVideoId = (data)=>{
  console.log(data.documentId);
}
  
const getCompanyLogo = (resImagePath) => {
  //console.log('event', resImagePath)
  // const resImagePath = await Aws_service.fileUpload(event.target.files[0], 'account-s3/introVideo');
  setState({...state, companyLogo: resImagePath.document.documentUrl, documentId: resImagePath.documentId});
  //console.log('resImagePath',resImagePath);
};
  

  const [stateForLogo, setStateForLogo] = useState({
    preview: null,
    src: ''
  });

  console.log('preview', stateForLogo.preview)

  const onCrop = (preview) => {
    setStateForLogo({
      ...stateForLogo, preview: preview
    })
  }

  const onClose = () => {
    setStateForLogo({
      ...stateForLogo, preview: null
    })
  }

  const onBeforeFileLoad = (elem) => {
    if(elem.target.files[0].size > 7771680){
      alert("File is too big!");
      elem.target.value = "";
    };
  }

  const watchAll = watch();


  const save = async() => {

      let data = {
        yearEstablished : +watchAll.Yearestablishment,
        areYouStartUp : state.areYouStartUp,
        no_of_staffs : state.no_of_staffs,
        annualTurnOver : state.annualTurnOver,
        certificateName: state.company_regid,
        companyRegNumber : watchAll.CRN,
        productBrand : state.productBrand,        
        vatNumber : state.isVat=='1'?watchAll.VATN:null,
        companyLogo : state.documentId,
        tweetPitch : state.tweetPitch,
        introVideo : state.introVideo,
        companyDescription : state.companyDescription,
        brands : state.brandList.map(x => +x.id),
        productGroup : state.productValue.map(x => +x.groupid),
        productCat: state.productValue.map(x => +x.childid),
        relevantChannelProfile : state.ChannelProfileValue.map(x => +x.childid),
        no_of_skus : +watchAll.no_of_skus,
        ratingId: +props.ratingId,
        channelId:userData?.channelId
      }

     let result=await dashboardActions.companyInfo({data});
     console.log('saveresuult', result);

     history.push('admin/dashboard');
    
      console.log('save', data);
  }
  
  const handleChange = input => e => {
    console.log("add",e.target.value);  
    setState({...state, [input] : e.target.value});
  }
  

  const handleChangeQuill1 = (content, delta , source, editor) => { 
    setState({...state, 'tweetPitch' : editor.getText()});
  }

  const handleChangeQuill2 = (content, delta , source, editor) => { 
    setState({...state, 'companyDescription' : editor.getText()});
  }
  const handleChangeQuill3 = (content, delta , source, editor) => { 
    setState({...state, 'tradeCompanyDescription' : editor.getText()});
  }

  const handleChangeSelect = (input,value) => {
    if(input=='ProductFamily'){
      state.productValue= value
      setState({...state, productValue:state.productValue});
    }
    else if(input=='relevantChannelProfile'){
      state.ChannelProfileValue= value
      setState({...state, productValue:state.ChannelProfileValue});
    }

    else if(input=='brands'){
      state.brandValue= value      
      setState({...state, brandValue:state.brandValue});
      state.brandList.push(state.brandValue);
      setState({...state, brandList:state.brandList});
      console.log("brandList:",state.brandList);
    }
    if(input!='ProductFamily'&&input!='relevantChannelProfile'&&input!='brands')
      setState({...state, [input]:[value]});
  }

  const [opneadd, opneAddnew] = useState(false);
  const [brandName, setBrandName] = useState('');
  const opneAdd = (input,value) => {   
  console.log('opneadd',opneadd);
  setBrandName(input);
 opneAddnew(true);
 }  
  const closeModalPopup=()=>{
    opneAddnew(false);
  }
  
console.log('satet', state);
//console.log('watchAll', watchAll)

const onSubmit = data => {    
if (data) {        
    console.log("data:",data);
}    
}
console.log('errrors', errors);

return (
<div className="main-content">

        <Container className="mt-4 col-lg-9 col-md-9 col-sm-12  " fluid>
        <form noValidate onSubmit={handleSubmit(save)}>
              <Card    >
            
              {/* <Card> */}
                   
              <CardHeader>
                 <h3 className="text-center">Facts and Figures</h3>
                  <p className="text-center">Tell us about your company legal informations</p>
                  </CardHeader>
                  <CardBody  >
                     
              <div className="row">
           <div className="col-lg-6 col-md-6 col-sm-12 ">
            <div className="form-group">        
                    <FormInput 
                       id="Yearestablishment"
                       type="number"
                       name="Yearestablishment"
                       label="Year Established *"
                       register={register}
                       error={errors.Yearestablishment}
                       defaultValue={state.yearEstablished}
                       value={watchAll.yearEstablished} 
                       onChange={handleChange('yearEstablished')}
                    /> 
             </div>           
    </div>
    {/* <div className="col-lg-12 col-md-6 col-sm-12  ">
            <div className="form-group">        
                    <FormInput 
                        id="isstartup"
                        type="number"
                        name="number"
                      
                         /> 
            </div>           
     </div> */}

     <div className="col-lg-3 col-md-3 col-sm-12  row ">
            <div className="form-group text-center text-sm mb-0"> 
            <h4>Are you a startup *</h4>       
            <FormRadio
                    name="startup"
                    className="text-sm mb-0"
            
                    options={radioGroupOptions}
                    control={control}
                    row
                    value={state.areYouStartUp} 
                    onChange={handleChange('areYouStartUp')}
                    // onChange={handleChange('isOEM')}
                    // value={isOEM}
                     />
                 </div>           
            </div>

            <div className="col-lg-3 col-md-3 col-sm-12  ">
            <div className="form-group text-center text-sm mb-0">        
            {state.areYouStartUp === '1' ? (
                     <FormAutocomplete
                     id="productBrand"
                      name="productBrand"
                      //defaultstate={["fdsafds","fsdafds"]}
                      label="Maturity Level"
                      options={startUp}
                      placeholder="Select Maturity Level"
                      // register={register}
                      // error={errors.postalcode}
                      //multiple
                      disableCloseOnSelect
                      BlurOnSelect
                      limitTags={2}
                      // getOptionSelected={(options) => {                            
                      //     if (!isMultiple) {                               
                      //      return (options.id == pfamily);  
                      //     }                                        
                      //     return productFamilies[0];                           
                      //  }}

                      getOptionSelected={(options, value) => options.name === value.name}
                      defaultValue={state.productBrand[0]}
                      control={control}                         
                      input='productBrand'
                      value={  state.productBrand[0] }
                      register={register}
                      errMessage={'Please Select Maturity Level'}
                      //error={errState.productFamilies?true:false}
                      //error={productFamilies[0].length || errState.productFamilies!='' ?false:true}
                      error={errors.productBrand }
                      handleChangeSelect={handleChangeSelect}
                      
                     /> 
          ) : ''}

          
                 </div>           
            </div>
     </div>
   

{/* Staffs {state.no_of_staffs ? <div>No of staffs*</div> : <div style={{color: 'red'}}>No of staffs* required</div>} */ }

<div className="row" > 
 <div className="col-lg-8">
 <h4  className="text-sm mb-0" >No of Staffs *{!errors.no_of_staffs || state.no_of_staffs ? '' : <span style={{color: '#f44336', fontSize:'0.75rem'}}>   Please Select or Enter No Of Employees</span>}</h4>
 <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="no_of_staffs" ref={register} name="no_of_staffs" value={state.no_of_staffs} control={control} />
 <div className="row" > 
    <div className="col-lg-3 col-md-3 col-xs-3 mt-3 text-center  res-sm" >
           <input className={state.no_of_staffs==='1-10' ? "btn btn-secondary text-center btn-lg-sm-md  active" : "btn btn-secondary text-center btn-lg-sm-md" }
                  type="button" id="emprange" name="emprange"  
                  value="1-10" onClick={handleChange('no_of_staffs')} />
    </div>
    <div className="col-lg-3 col-md-3 col-xs-3 mt-3 text-center  res-sm"  >
        <input className={state.no_of_staffs==='11-15' ? "btn btn-secondary text-center btn-lg-sm-md  active" : "btn btn-secondary text-center btn-lg-sm-md" } 
                  type="button" id="emprange" name="emprange" 
                  value="11-15" onClick={handleChange('no_of_staffs')} />
    </div> 
    <div className="col-lg-3 col-md-3 col-xs-3 mt-3 text-center res-sm" >
        <input className={state.no_of_staffs==='51-100' ? "btn btn-secondary text-center btn-lg-sm-md  active" : "btn btn-secondary text-center btn-lg-sm-md" } 
                  type="button" id="emprange" name="emprange" 
                  value="51-100" onClick={handleChange('no_of_staffs')} />
    </div>
    <div className="col-lg-3 col-md-3 col-xs-3 mt-3 text-center  res-sm" >
         <input className={state.no_of_staffs==='100-Above' ? "btn btn-secondary text-center btn-lg-sm-md  active" : "btn btn-secondary text-center btn-lg-sm-md" }
                  type="button" id="emprange" name="emprange" 
                  value="100-Above" onClick={handleChange('no_of_staffs')} />
    </div>
    </div> 
      </div>
    <div className="col-lg-4 col-sm-12 mt-4 "> 
    <div className="form-group  mt-2"> 
                     
                     <FormInput 
                         id="empcnt"
                         type="number"
                         name="empcnt"
                         label="Enter Custom Value *"
                         placeholder="Enter Your Value"
                         InputProps={{
                          startAdornment: <InputAdornment position="start"></InputAdornment>
                        }}
                         value={state.no_of_staffs}
                         onChange={handleChange('no_of_staffs')}
                     />
              </div>
              </div>
          </div>

{/* Annual Turnover {state.annualTurnOver ? <div>Annual Turnover*</div> : <div style={{color:'red'}}>Annual Turnover*</div>} */}

<div className="row ">
<div className="col-lg-8 col-md-8">
<h4 className="text-sm mb-0">Annual Turnover *{!errors.annualTurnOver || state.annualTurnOver ? '' : <span style={{color: '#f44336', fontSize:'0.75rem'}}>   Please Select or Enter Annual Turnover</span>}</h4>   
<input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="annualTurnOver" name="annualTurnOver" ref={register} value={state.annualTurnOver} /> 
<div className="row "> 
    <div className="col-lg-3 col-md-3 col-xs-3 mt-3 text-center res-sm ">
        <input className={state.annualTurnOver==='<1M€' ? "btn btn-secondary text-center btn-lg-sm-md  active" : "btn btn-secondary text-center btn-lg-sm-md"}                   type="button" id="emprange" name="emprange"
                  value="<1M€"  onClick={handleChange('annualTurnOver')}/></div>
  <div className="col-lg-3 col-md-3 col-xs-3 mt-3 text-center res-sm ">
        <input className={state.annualTurnOver==='1-10M€' ? "btn btn-secondary text-center btn-lg-sm-md  active" : "btn btn-secondary text-center btn-lg-sm-md"} 
                  type="button" id="emprange" name="emprange"
                  value="1-10M€"  onClick={handleChange('annualTurnOver')}/></div>
  <div className="col-lg-3 col-md-3 col-xs-3 mt-3 text-center res-sm  ">
        <input className={state.annualTurnOver==='10-100M€' ? "btn btn-secondary text-center btn-lg-sm-md  active" : "btn btn-secondary text-center btn-lg-sm-md"} 
                  type="button" id="emprange" name="emprange"
                  value="10-100M€" onClick={handleChange('annualTurnOver')}/></div>
 <div className="col-lg-3 col-md-3 col-xs-3 mt-3 pl-4 text-center res-sm  ">
        <input className={state.annualTurnOver==='>100M€' ? "btn btn-secondary text-center btn-lg-sm-md  active" : "btn btn-secondary text-center btn-lg-sm-md"} 
                  type="button" id="emprange" name="emprange"
                  value=">100M€" onClick={handleChange('annualTurnOver')}/></div>
   </div>
    </div>

  
  
                <div className="col-lg-4 col-md-4 col-xs-3 mt-4 ">
         <div className="form-group "> 
                     
                     <FormInput 
                         id="empcnt"
                         type="number"
                         name="empcnt"
                         label="Enter Value in M€"
                         InputProps={{
                           startAdornment: <InputAdornment position="start">M€</InputAdornment>
                         }}
                         value={state.annualTurnOver}
                         onChange={handleChange('annualTurnOver')}
                     />
              </div>
              </div>
                </div>
            

<div className="row mt-3"> 
    <div className="col-sm-6 mt-3">
            <div className="form-group">            

           <label className="form-control-label" htmlFor="">
          
           </label>
           <InputGroup className="" >
             <InputGroupAddon style={{width:'30%'}} addonType="prepend">
             <InputGroupText id="certificateName" className="justify-content-center ellipsisText"  style={{width:'100%'}}> {state.certificateName}</InputGroupText>
             </InputGroupAddon>
             <UncontrolledTooltip target="certificateName" placement='bottom'>
             {state.certificateName}
                      </UncontrolledTooltip>
             <FormInput style={{width:'70%'}}  className=""
                   id="CRN"
                   type="text"
                   name="CRN"
                   label="Company registration number*"
                   register={register}
                   error={errors.CRN}
                   defaultValue={state.companyRegNumber}
                   value={watchAll.CRN}
                   onChange={handleChange('companyRegNumber')}
             />
           </InputGroup>
       
             </div>  

                
  
               </div>
    <div className="col-sm-6 mt-3">
    <div className="form-group">            

<label className="form-control-label" htmlFor="">

</label>
{state.isVat=='1' && (
  <InputGroup  className="">
  <InputGroupAddon style={{width:'20%'}} addonType="prepend">
    <InputGroupText id="Vat" className="justify-content-center" style={{width:'100%'}}>VAT</InputGroupText>
  </InputGroupAddon>
  <FormInput style={{width:'80%'}}
  className=""
  id="VATN"
  type="text"
  name="VATN"
  label="VAT number"
  register={register}
  error={errors.VATN}
  defaultValue={state.vatNumber}
  value={watchAll.VATN}
  onChange={handleChange('vatNumber')}
    type="text"
  />
</InputGroup> )}

  </div>  
    </div>
</div>
</CardBody>
</Card>
         


      {/* ---------------------------Marketing information------------------------------           */}
      <Card>
      

      <CardHeader className="col-lg-12 justify-content-center">
                  <h3 className="text-center">Marketing Information</h3>
                  <p className="text-center">Introduce you to your audience</p>
                  </CardHeader>
          {/* <CardBody className="Profad"> */}
                 
          <CardBody >      
<div className="row"> 
    <div className="col-sm-12">
    <div className="row">
    <div className="col-sm-6">
    <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="companyLogo" name="companyLogo" ref={register} value={state.companyLogo} /> 
    <h4>Company Logo *{!errors.companyLogo || state.companyLogo ? '' : <span style={{color: '#f44336', fontSize:'0.75rem'}}>   Please Select Company Logo</span>}</h4>
        <div>
       {/* <Avatar
          width={320}
          height={295}
          src={stateForLogo.src}
          onCrop={onCrop}
          onClose={onClose}
          onBeforeFileLoad={onBeforeFileLoad}
          
       /> */}
       {/* <ProfileUploader img={state.companyLogo ? 'https://channelhub-dev-public.s3-eu-west-1.amazonaws.com/'+state.companyLogo : "http://d2maj8ifjo1lti.cloudfront.net/document/brand/KVw8QS0n_1545236009520_1545236013342.png"} handleChange={getCompanyLogo}/> */}
       <ImageLibrary getDocumentId={getCompanyLogo} aws_folderName='CompanyLogo' id={1}
               img={state.companyLogo ? state.companyLogo : "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"}/>
   </div>
      
        

        

    </div>
    <div className="col-sm-6 mt-4">
        <span>Accepts Jpeg / Jpg / Png Only</span>
        <br></br>
        <span>(Max size allowed 200 Kb)</span>
        <span className="d-block">(* Image resolution should be 200 * 200)</span>
        </div> 
         </div>
                  
    </div>
     
</div>
<div className="row"> 
                <div className="col-sm-12 mt-4 ">
                <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="tweetPitch" name="tweetPitch" ref={register} value={state.tweetPitch} /> 
                {!errors.tweetPitch || (state.tweetPitch.length > 5 && state.tweetPitch.length < 280) ? <h4 style={{fontSize:'1rem'}}>Your Company - Tweet Pitch *</h4> :
    <h4>Your Company - Tweet Pitch * <span style={{color: '#f44336', float: 'right', fontSize:'0.75rem'}}>{
                  state.tweetPitch.length <= 1? 'TweetPitch can\'t be empty.' : state.tweetPitch.length <= 5 ? 'TweetPitch must have min 5 characters' : state.tweetPitch.length > 280 ? 'TweetPitch must have max 280 characters' : ''
                  }</span></h4> 
                }
          <div className="form-group">
          <FormTextArea className="buytext1"  placeholder="Tell us about your company background and vision -max 280 characters *" handleChange={handleChangeQuill1}/>
        </div>
        </div>
</div>
<div className="row"> 
         <div className="col-sm-12 ">
         <h4 style={{fontSize:'1rem'}}>Add an introduction video (90 seconds)</h4>
          <div className="form-group">
          <form action="/file-upload" className="dropzone">
  <div className="fallback">
  </div>
</form>
        </div>
        </div>
</div>

<div className="row"> 
        
        {/* <h4 style={{textAlign:'center',fontSize:'1rem'}}>Add your company description (2000 characters)</h4> */}
        
     

                  <div className="col-sm-12 ">
          <div className="form-group">
            {/* <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="companyDescription" name="companyDescription" ref={register} value={state.companyDescription} /> 
            {!errors.companyDescription || (state.companyDescription.length > 5 && state.companyDescription.length < 2000) ?  :
            <h4>Add your company description (2000 characters) <span style={{color: '#f44336', float: 'right', fontSize:'0.75rem'}}>{
              state.companyDescription.length <= 1? 'Company Description can\'t be empty.' : state.companyDescription.length <= 5 ? 'Company Description must have min 5 characters' : state.companyDescription.length > 2000 ? 'Company Description must have max 2000 characters' : ''
            }</span></h4>  
            } */}
            <h4>Add your company description (2000 characters)</h4>
          <FormTextArea className="buytext2" placeholder="Tell more about your company" handleChange={handleChangeQuill2}
          register={register} name="quill" id="quill" 
        />
        </div>
        </div>
</div>
</CardBody>
</Card>

                {/* ---------------------------Trade information information------------------------------           */}
                <Card>
                <CardHeader>
                <h3 className="text-center">Trade Information</h3>
                  <p className="text-center">Key informations for showing matching</p>
                  </CardHeader>
                  <CardBody>
                  <div className="col-lg-12 ">
            <h4 >Profile * </h4>
            <div className="form-group text-center text-sm mb-0">        
         
                      <SingleAutocomplete
                      id="Profiledist"
                      name="Profiledist"
                      //defaultstate={["fdsafds","fsdafds"]}
                      label="Profile"
                      options={startUp}
                      placeholder=""
                 
                      disableCloseOnSelect
                      BlurOnSelect
                      limitTags={2}
                

                      getOptionSelected={(options, value) => options.name === value.name}
                      defaultValue={state.productBrand[0]}
                      control={control}                         
                      input='productBrand'
                      value={  state.productBrand[0] }
                      register={register}
                      errMessage={'Please Select Maturity Level'}
                      //error={errState.productFamilies?true:false}
                      //error={productFamilies[0].length || errState.productFamilies!='' ?false:true}
                      error={errors.productBrand }
                      handleChangeSelect={handleChangeSelect}
                      
                      />
               </div>           
            </div>
  
            <div className="col-lg-12 mt-3">
          
                  <h5 >Speciality</h5>
            <div className="form-group">               
 
               <FormAutocomplete
               id="ProductGroup"
               name="ProductGroup"
               label="Speciality *"
               Options={state.productFamilies}
               placeholder="Product Family"
            //    input='ProductFamily'
            //    errMessage='Please Select Product Family'
               error={errors.ProductGroup}
               value={state.productValue}
               handleChangeSelect={handleChangeSelect}
               control={control}
               register={register}
               defaultValue={state.productValue}
               />
        </div>
        </div>      


    
  <div className="col-lg-12 ">
            <h4 >Brands *</h4>
            <div className="form-group mt-3">        
           
            {state.brandList[0] && <BrandCard  name="BMW" deleteBrand={deleteBrand} brandList={state.brandList}  img={"http://d2maj8ifjo1lti.cloudfront.net/document/brand/KVw8QS0n_1545236009520_1545236013342.png"}/>}

            <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="brandList" name="brandList" ref={register} value={state.brandList[0] ? 'data' : ''} /> 
{/* Autocomplete with image */}
<AddFieldWithPopUpAutocomplete
        limitTags={2}
        name="Brands"
        id="Brands"
        options={state.brandOption}
        defaultValue={state.brandValue}
        value={state.brandValue}
        getOptionLabel={option => option.name}
        renderOption={option => (
         <>
         <div style={{display: '-webkit-inline-flex',
    padding: '1px 6px',
    width: '75px',
    margin: '10px 5px 0 0',
    float: 'left',
    border: '2px solid #e7e7e7',
    borderRadius: '5px',
    height: '38px'}}>

            <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  style={{maxWidth: '63px',backgroundColor: '#fff',
    maxHeight: '34px',
    height: 'auto',
    width: 'auto',
    margin: '0 auto',
    cursor: 'pointer'}} src={option.documentpath} />    </div>
          <span>
            {option.name}
          </span>
          </>
        )}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" label="Brands" placeholder="Brands" />
        )}
        label="Brands *"
        placeholder="Brands"
        input="brands"
        control={control}
        register={register}
        errMessage={'Please Select a Brand'}
        error={errors.Brands }
        handleChangeSelect={handleChangeSelect}
        opneAdd={opneAdd}
      />
{brandName && <AddBrand aws_folderName="brands" opneadd={opneadd} closeModalPopup={closeModalPopup} brandName={brandName}/>}

                 </div>           
            </div>

       
          {/* <h5 style={{color:'green'}}>Download to view List of Product Families and relevant Categories<h5/> */}

            <div className="col-lg-12 mt-3">
            <h4 >Product Family *</h4>
                  <h5 >Select the products families that you offer</h5>
            <div className="form-group">               
 
               <FormAutocompleteGrouping
               id="ProductGroup"
               name="ProductGroup"
               label="Product Family *"
               Options={state.productFamilies}
               placeholder="Product Family"
               input='ProductFamily'
               errMessage='Please Select Product Family'
               error={errors.ProductGroup}
               value={state.productValue}
               handleChangeSelect={handleChangeSelect}
               control={control}
               register={register}
               defaultValue={state.productValue}
               />
        </div>
        </div>
       
                  {/* <p style={{textAlign:'center',fontSize:'0.8rem'}}>Enter the number of products that you handle</p> */}
                  <div className="col-lg-12 ">
                  <h4 >Relevant Channel Profiles *</h4>
            <div className="form-group">        
            <FormAutocompleteGrouping
              id="relevantProfiles"
              name="relevantProfiles"                        
              label="Channel Profiles * "
              Options={state.ChannelProfileOption}
              placeholder="Channel Profiles"                        
              input='relevantChannelProfile'              
              errMessage={'Please Select Channel Profile'}
              error={errors.relevantProfiles }
              value={state.ChannelProfileValue}              
              handleChangeSelect={handleChangeSelect}
              control={control}
              register={register}
              defaultValue={state.ChannelProfileValue}
              />
            
                 </div>           
            </div>

          
                  {/* <h5 style={{color:'green'}}>Download to view List of Product Families and relevant Categories<h5/> */}

 

   
        <div className="text-center">
        <button type="submit" className="btn btn-primary" >Save</button> {/*onClick={save}*/}
          </div>  
 
    
        
          
        
          </CardBody>
    
              {/* </Card> */}
          </Card>
          </form>
              </Container>
      </div>
    
   
  );
};
const top100Films = [
  { name: 'The Shawshank Redemption', id: 1994 },
  { name: 'The Godfather', id: 1972 },
  { name: 'The Godfather: Part II', id: 1974 },
  { name: 'The Dark Knight', id: 2008 },
  { name: '12 Angry Men', id: 1957 },
  { name: "Schindler's List", id: 1993 },
  { name: 'Pulp Fiction', id: 1994 },
  { name: 'The Lord of the Rings: The Return of the King', id: 2003 },
  { name: 'The Good, the Bad and the Ugly', id: 1966 },
  { name: 'Fight Club', id: 1999 },
  { name: 'The Lord of the Rings: The Fellowship of the Ring', id: 2001 },
  { name: 'Star Wars: Episode V - The Empire Strikes Back', id: 1980 },
  { name: 'Forrest Gump', id: 1994 },
  { name: 'Inception', id: 2010 },
  { name: 'The Lord of the Rings: The Two Towers', id: 2002 },
  { name: "One Flew Over the Cuckoo's Nest", id: 1975 },
  { name: 'Goodfellas', id: 1990 },
  { name: 'The Matrix', id: 1999 },
  { name: 'Seven Samurai', id: 1954 },
  { name: 'Star Wars: Episode IV - A New Hope', id: 1977 },
  { name: 'City of God', id: 2002 },
  { name: 'Se7en', id: 1995 },
  { name: 'The Silence of the Lambs', id: 1991 },
  { name: "It's a Wonderful Life", id: 1946 },
  { name: 'Life Is Beautiful', id: 1997 },
  { name: 'The Usual Suspects', id: 1995 },
  { name: 'Léon: The Professional', id: 1994 },
  { name: 'Spirited Away', id: 2001 },
  { name: 'Saving Private Ryan', id: 1998 },
  { name: 'Once Upon a Time in the West', id: 1968 },
  { name: 'American History X', id: 1998 },
  { name: 'Add New', id: 0 },
];

const startUp = [
  {name : 'Prototype', id: 1},
  {name : 'Shipping to first backers', id: 2},
  {name : 'Shipping in volume', id: 3},
];

const radioGroupOptions = [
  {
    value: "1",
    label: "Yes",
  },
  {
    value: "0",
    label: "No",
  },
 
];


export default InitialprofileDistributor;

