import React,{useState,useEffect} from "react";
import SimpleImageSlider from "react-simple-image-slider";
import { FormGroup,
  Form,  CardHeader,
    Card, CardBody,Row, Col,Button,CardTitle,CardText,CardImg ,ButtonGroup } from "reactstrap";
import GuestNavBar from "./GuestNavbar/GuestNavbar";
// import Footer from "./GuestNavbar/Footer";


import { Link } from "react-router-dom";
import ScrollAnimation from 'react-animate-on-scroll';
import GuesProducts from "./GuesProducts";
import GuestContact from "./GuestContact";
import GuestFooter from "./GuestNavbar/GuestFooter";
import GuestCategorys from "./GuestCategorys";
import GuestTestimonial from "./GuestTestimonial";
import GuestProductlist from "./GuestNew/GuestProductlist";
import GuestVideo from "./GuestNew/GuestVideo";
// import Guesttopics from "./GuestNew/Guesttopics";
 
import {commonService} from "_services/common.service";
import GuestTopSearch from "../GuestPage/GuestNavbar/GuestTopSearch";
import Guestevent from "./Guestevent";
import queryString from 'query-string';
import { history } from '../../../_helpers';
import firstImg from 'assets/img/guestpage/business.jpg';
import secondImg from 'assets/img/guestpage/closeup.jpg';
import thiredImg from 'assets/img/guestpage/desk-gadgets.jpg';
import fourth from 'assets/img/guestpage/smartphone-with-earphones.jpg';  //Taiwan
import Newssection from "./Newssection";
import { DashVenslider } from "../dashboards/Dashslider";
import axios from "axios";
import NextEventsSlider from "../Home/NextEventsSlider";
import HomeLatestBuyer from "../Home/HomeLatestBuyer";
import HomeLatestVendor from "../Home/HomeLatestVendor";
// import Newslist from './Newslist'
export const GuestDashboard = props => {  

  const [collapsed, setCollapsed] = useState(true);

  const toggleNavbar = () => setCollapsed(!collapsed);
  const [prodFam, setProdFam] = useState([]);
  let [businessType, setBusinessType] = useState(1);

  // useEffect(() => {
  //   getProducts();
  //   /** added for social share 
  //    * 
  //    */
  //   let param = (new URLSearchParams(window.location.search));
  //   if(param.get("productDetailView")){
  //     history.push('/productDetailView/'+param.get("productDetailView"));
  //   } else if(param.get("channelId")){
  //     history.push('/channelDetailView/'+param.get("channelId"));
  //   } 
  // }, [businessType]);

  const [state, setState] = useState({
      options: [],
      company: [],
      type: '',
    });
  const toggleButton = (id) => {
    if(id!=businessType){
      setBusinessType(id);
    }
  }

  const getProducts = async() => {
    await commonService.getServices('guest/getProducts/'+businessType).then(res => {
      let temp = [];
      if(res.data?.productGroup){
        res.data.productGroup.map(x => {
          temp.push({name: x.groupname, id: x.groupid});
        })
      }
      setProdFam(temp);
    });
  }

 
  let selectedStyle = {backgroundColor:'#EEA746',minWidth:'174px'};
  let normalStyle = {backgroundColor:'#fff',minWidth:'174px'};
  //var ImgURL=secondImg;
 

  const [ImgURL, setImgURL] = useState({});
 
  useEffect(() => {
    const images = [
      { url: secondImg },
      { url: firstImg},
      { url: thiredImg},
      { url: fourth}
    ];
    let x = Math.floor((Math.random() * 4) + 0);
      const ImgURL1 = {
        background: "linear-gradient(to right,#2ca5cdc7, #e8870194), url("+images[x].url+") no-repeat top center"
     };
     setImgURL(ImgURL1);
     getAllTopCompany();
  }, [])
  const getAllTopCompany = async() => {
    await axios.get('guest/getAllTopCompany').then(res => {
        
          state.company = res.data;
          //console.log('state.company = ', state.company);
          setState({ ...state, company: state.company })
    });
  }
  return (
    <div className="main-content">
<GuestNavBar/>



{/* <div class="hero-image">
  <div class="hero-text">
  <Form>
  <FormGroup className="inputSize">
                            <InputGroup className="input-group-merge">
                              <Input placeholder="Search for a brand, a product..." type="text"/>
                              <InputGroupAddon className="pointer" addonType="append">
                                <InputGroupText>
                                <i class="fas fa-search"></i>
                                </InputGroupText>
                              </InputGroupAddon>
                            </InputGroup>
                          </FormGroup>
                        </Form>
  </div> 

</div> */}
{/* <div className="subhero-image">
<button className="btn btn-md ml-2 vendorList"> SEE ALL VENDOR LIST </button>
</div> */}
<Card className="hero-section mb-0 text-center" style={ImgURL}>
 
  <Form className="topSearchge1">
      <GuestTopSearch btype={businessType} company={state.company}/>
  </Form>
</Card>
{/* <GuestProductlist btype={businessType}/> */}
 
    <NextEventsSlider  /> 
    <HomeLatestBuyer/>
    <HomeLatestVendor/>
              

             
<GuestVideo btype={businessType} homePage={true}/>
<Newssection/>
{/* <Guestevent/> */}
{/* <Guesttopics/> */}
{/* <GuestTestimonial/> */}
<GuestContact/>

{/* <Footer/> */}
<GuestFooter/>
{/* <Newslist/> */}
</div>

              
  );
}
export default GuestDashboard;