import React, {useState} from 'react';
import {Card,CardBody, Row,Col, CardHeader} from "reactstrap";
import ProfileZoom from 'views/Hoc/Dialog/profiezoom';
import axios from 'axios';
const Companypiclist = (props) => {
  let [isImg, setImgOpen]  = useState(false);
  let [imgUrl, setImgUrl]  = useState(null);
    const imageModalClose = () => {
        isImg=false;
        setImgOpen(isImg);
      };
      const previewImg =(data)=>{
        imgUrl = data.documentUrl;
        setImgUrl(imgUrl);
        isImg=true;
        setImgOpen(isImg);
      }
    return (
        <div >
            <ProfileZoom className="companypic-zoom" imgModalClose={imageModalClose} isOpen={isImg} url={imgUrl}/>
           
            <h3 className='mb-4 ml-4'>Company Pictures</h3>
        <Row className="align-items-center">
            {props.images.map(x=>(
                <Col lg="2" md="4" sm="12" onClick={()=>previewImg(x)}>
                <Card >
                <CardBody className='p-0'>
                <div class='key-card w-100 m-0 p-3' style={{cursor:'pointer',boxShadow:'none',border:'none'}}>
                    <div class='set-image h-100'>
                    <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  src={x.documentUrl} />
                    </div>
                </div>
            </CardBody>
            </Card>
                </Col>
            ))}
            </Row>
        </div>
    );
};

export default Companypiclist;