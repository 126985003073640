import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Spinner } from "views/Hoc/Spinner";

const Event_Pdf_Template = ({ eventId }) => {


    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    const TemplateData = async () => {
        await axios.get('/channelAdmin/Pdf_Template/' + eventId).then(res => {
            if (res.data.length) {
                setData(res.data);
            }
        });
    }

    useEffect(() => {

        (async () => {
            setLoading(true);
            await TemplateData();
            setLoading(false);
        })();


    }, [])
    return (
        <>
            {(loading && data.length > 0) ? <Spinner /> :

                <div className='picvideo-wrap'>
                    <div className='row'>
                        {data.map(item =>
                            <div style={{ margin: "20px" }}>
                                <div className='menapic-wrap'>
                                    <div className='menapic-img'>
                                        <img src={item.logo} />
                                    </div>
                                    <span style={{ paddingLeft: "15px", fontSize: "18px", color: "#333" }}>{item.title}</span><br />
                                    <a className='btn btn-primary' style={{ marginTop: "20px", marginLeft: "20px" }} download={item.title} href={item.pdfPath} id="downloadClick" title={item.title} >{item.buttonLabel}</a>

                                </div>
                            </div>
                        )}
                    </div>
                </div>}
        </>
    )
}
export default Event_Pdf_Template;