import Dashboard from "views/pages/dashboards/Dashboard.js";
import ComingSoon from "views/pages/dashboards/ComingSoon.js";
import Channel from "views/pages/Channel-Listing/Channel-List.js";
import ChannelDetail from "views/pages/Channel-Listing/ChannelDetail.js";
import ProductDetail from "views/pages/Channel-Listing/ProductDetail.js";
import ProfileWrapper from 'views/pages/profile/PendingProfiles/ProfileWrapper';
import Listing from "views/pages/Channel-Listing/ListingWrapper";
import help from "views/pages/SettingsTab/help";
import Product from "views/pages/Channel-Listing/Product-List";
import Notification from "views/pages/Notification/Notification";
import Favourites from "views/pages/Favourites/Favourites";
import Events from "views/pages/Events/Events";
import EventsNew from "views/pages/Events/EventNew";
import ProfileSettings from "views/pages/profile/Settings/SettingsWrapper"
import Productform from 'views/pages/product/Products'
import Myproductlist from 'views/pages/product/Myproductlist'
import Subscription from "views/pages/profile/PendingProfiles/Subscription";
import PaymentConfirm from "views/pages/profile/PendingProfiles/PaymentConfirm";
import EventPaymentConfirm from "views/pages/Events/EventPaymentConfirm";

import PayCard from "views/pages/profile/PendingProfiles/PayCard";
import PaypalPayment from "views/pages/User-SubscriptionPage/payments/paypal";
import PaymentSuccess from "views/pages/profile/PendingProfiles/PaymentSuccess";
import PaymentError from "views/pages/profile/PendingProfiles/paymentError";
import Postbuyneed from 'views/Hoc/Dialog/PostbuyingneedPopup'
import Addcart from 'views/pages/product/Addcart'
import StripeAlipayPayment from "views/pages/User-SubscriptionPage/payments/stripeAlipay";
import StripePayment from "views/pages/User-SubscriptionPage/payments/StripePayment";
import EventStripePayment from "views/pages/User-SubscriptionPage/Eventpayments/StripePayment";
import EventAlipayPayment from "views/pages/User-SubscriptionPage/Eventpayments/stripeAlipay";
import EventPaypalPayment from "views/pages/User-SubscriptionPage/Eventpayments/paypal";

import SearchWrapper from "views/pages/Searchlist/SearchWrapper";
import Prodimport from 'views/pages/product/Import'
import Addprodtab from 'views/pages/product/Addprodtab';
import Support from "views/pages/User-SubscriptionPage/HelpReport/Support";
import CheckoutTab from 'views/pages/User-SubscriptionPage/HelpReport/CheckoutTab'
import ProfileSetupaccess from "views/Hoc/Dialog/ProfileSetupaccess";
import { Ordersuccess } from 'views/pages/Landing-pages/Ordersuccess';
import { Transaction } from 'views/pages/Landing-pages/Transaction';
import Userprofileform from 'views/pages/profile/Settings/Userprofileform'
import WalletConfirm from "views/pages/profile/DigitalWallet/WalletConfirm";
import DigitalWallet from "views/pages/profile/DigitalWallet/DigitalWallet";
import Wallet from 'views/pages/profile/DigitalWallet/Wallet';
import VendorDashboard from "views/pages/dashboards/VendorDashboard";
import VendornewDashboard from "views/pages/dashboards/VendornewDasboard";
import BuyerDashboard from "views/pages/dashboards/NewDashboard"
import ProfileViewListing from "views/pages/dashboards/ProfileViewListingWrapper";
import Usermanage from 'views/pages/profile/Settings/Usermanage';
import Settings from 'views/pages/profile/Settings/Settings';
import RetailReseller from 'views/pages/companyProfiles/Retail-Reseller';
import CompanyProfileDist from "views/pages/profile/Settings/CompanyProfileDist";
import EventSubscription from "views/pages/Events/EventSubscription";
import EventUsers from "views/pages/Events/EventUsers";
import ManageEventUsers from "views/pages/Events/ManageEventUsers";
import EventPending from "views/pages/Events/EventPending";
import { ManageUser } from "views/pages/Events/ManageUser";
import Eventlistingwrapper from "views/pages/Events/Eventlistingwrapper";
import EventManagement from "views/pages/Events/EventManagement";
import BuyerSessionManagement from "views/pages/Buyer-Session/EventManagement";
import PrevEventlisting from "views/pages/Prev-Event/Eventlistingwrapper";

import Nonmemdetail from "views/pages/Channel-Listing/Nonmemdetail";
import ProductImport from "views/pages/product/ProductImport";
import StartupProdImport from "views/pages/StartupProdImport/StartProdImport"
import NewProdImport from "views/pages/StartupProdImport/NewProdImport"
import { StartProdImportSuccess } from "views/pages/StartupProdImport/StartProdImportSuccess";
import LinkedInCallback from "views/pages/Events/LinkedInCallback ";
import OtherUserProfile from "views/pages/profile/Settings/OtherUserProfile";
import OtherUserChannelDetail from "views/pages/Channel-Listing/OtherUserChannelDetail.js";

import ProductManage from 'views/pages/product/ProductManage';

const userData = JSON.parse(localStorage.getItem('CHuser'));

const routes = [
  {
    collapse: false,
    name: "Dashboard",
    icon: "fa fa-th-large sideicon-color",
    state: "dashboardsCollapse",
    path: "/dashboard",
    miniName: "D",
    component: Dashboard,
    status: "1",
    layout: "/admin",
    // views: [
    //   {
    //     path: "/dashboard",
    //     name: "Dashboard",
    //     miniName: "D",
    //     component: Dashboard,
    //     layout: "/admin"
    //   },
    //   {
    //     path: "/ComingSoon",
    //     name: "Alternative",
    //     miniName: "A",
    //     component: ComingSoon,
    //     layout: "/admin"
    //   }
    // ]
  },
  {
    collapse: false,
    name: "Suggestion",
    icon: "fa fa-lightbulb sideicon-color",
    state: "examplesCollapse",
    path: "/channel",
    miniName: "S",
    component: Listing,
    status: "25",
    layout: "/admin",
  },
  // {
  //       collapse: false,
  //       name: "Search",
  //       icon: "fa fa-lightbulb sideicon-color",
  //       state: "examplesCollapse",
  //       path: "/search/:id",
  //       miniName: "S",
  //       component: Listing,
  //       layout: "/admin"
  // },
  {
    collapse: false,
    name: "My Network",
    icon: "fa fa-wifi sideicon-color",
    state: "examplesCollapse",
    path: "/mynetwork",
    miniName: "My",
    component: Listing,
    status: "21",
    layout: "/admin",
  },

  {
    collapse: false,
    name: "My Products",
    status: "50",
    icon: "fa fa-list-alt sideicon-color",
    state: "examplesCollapse",
    path: "/Myproductlist",
    miniName: "M",
    component: Myproductlist,
    layout: "/admin",
  },
  {
    collapse: false,
    name: "Events",
    icon: "fa fa-circle sideicon-color",
    state: "examplesCollapse",
    path: "/events",
    miniName: "A",
    // component: userData?.bofo == '1' ? EventsNew : Events,
    component: EventsNew,
    status: "1",
    layout: "/admin",
  },
  // {
  //   collapse: false,
  //   name: "Events New",
  //   icon: "fa fa-circle sideicon-color",
  //   state: "examplesCollapse",
  //   path: "/eventsnew",
  //   miniName: "A",
  //   component: EventsNew,
  //   status: '1',
  //   layout: "/admin"
  // },
  // {
  //   collapse: false,
  //   name: "Pitch-your-Product",
  //   icon: "fa fa fa-trophy sideicon-color",
  //   state: "examplesCollapse",
  //   path: "/ComingSoon",
  //   miniName: "A",
  //   component: ComingSoon,
  //   status:'1',
  //   layout: "/admin"
  //   },

  // {
  //   collapse: false,
  //   name: "Post Your Buying Need",
  //   icon: "fa fa fa-paper-plane sideicon-color",
  //   state: "examplesCollapse",
  //   path: "/Postbuyneed",
  //   miniName: "P",
  //   component: Postbuyneed,
  //   status: '1',
  //   layout: "/admin"
  // },

  // {
  //   collapse: false,
  //   name: "Invitations",
  //   icon: "fa fa-address-book sideicon-color",
  //   state: "examplesCollapse",
  //   path: "/ComingSoon",
  //   miniName: "A",
  //   component: ComingSoon,
  //   status: '1',
  //   layout: "/admin"
  // },
  {
    collapse: true,
    name: "Help / Report",
    status: "1",
    icon: "ni ni-map-big sideicon-color",
    state: "mapsCollapse",
    views: [
      {
        path: "/support",
        name: "Support",
        miniName: "S",
        component: Support,
        status: "1",
        layout: "/admin",
      },
      {
        path: "/channel/productDetail/:id",
        component: ProductDetail,
        layout: "/admin",
      },
      {
        path: "/channel/productDetail/:id/:topsearch",
        component: ProductDetail,
        layout: "/admin",
      },
      {
        path: "/channel/productDetail/:productId/Addcart",
        component: Addcart,
        layout: "/admin",
      },
      {
        //myproductlist to channel and product detail breadcrumb
        path: "/Myproductlist/productDetail/:id",
        component: ProductDetail,
        layout: "/admin",
      },
      {
        path: "/Myproductlist/channelDetail/:id",
        component: ChannelDetail,
        layout: "/admin",
      },
      {
        path: "/Myproductlist/channelDetail/:channelId/productDetail/:productId",
        component: ProductDetail,
        layout: "/admin",
      },
      {
        path: "/Myproductlist/productDetail/:productId/channelDetail/:channelId",
        component: ChannelDetail,
        layout: "/admin",
      },
      {
        path: "/Myproductlist/channelDetail/:channelId/productDetail/:productId/EditProduct",
        //component: Addprodtab,
        component: ProductManage,
        layout: "/admin",
      },
      {
        path: "/Myproductlist/channelDetail/:channelId/productDetail/:productId/CloneProduct",
        component: Addprodtab,
        layout: "/admin",
      },
      {
        path: "/Myproductlist/productDetail/:productId/EditProduct",
       // component: Addprodtab,
        component:ProductManage,
        layout: "/admin",
      },
      {
        path: "/Myproductlist/productDetail/:productId/CloneProduct",
        component: Addprodtab,
        layout: "/admin",
      },
      {
        //for public profile breadcrumb
        path: "/PublicProfile/:channelId",
        component: ChannelDetail,
        layout: "/admin",
      },
      {
        //mynetworks
        path: "/mynetwork/channelDetail/:id",
        component: ChannelDetail,
        layout: "/admin",
      },
      {
        //adv search
        path: "/advancedsearch/channelDetail/:id",
        component: ChannelDetail,
        layout: "/admin",
      },
      {
        path: "/channel/channelDetail/:id",
        component: ChannelDetail,
        layout: "/admin",
      },
      {
        path: "/profileView/channelDetail/:id",
        component: ChannelDetail,
        layout: "/admin",
      },
      {
        path: "/channel/channelDetail/:id/:topsearch",
        component: ChannelDetail,
        layout: "/admin",
      },
      {
        path: "/favourites/channelDetail/:id",
        component: ChannelDetail,
        layout: "/admin",
      },
      {
        path: "/favourites/productDetail/:id",
        component: ProductDetail,
        layout: "/admin",
      },
      {
        path: "/mynetwork/productDetail/:id",
        component: ProductDetail,
        layout: "/admin",
      },
      {
        path: "/channel/channelDetail/:channelId/productDetail/:productId",
        component: ProductDetail,
        layout: "/admin",
      },
      {
        path: "/favourites/channelDetail/:channelId/productDetail/:productId",
        component: ProductDetail,
        layout: "/admin",
      },
      {
        path: "/mynetwork/channelDetail/:channelId/productDetail/:productId",
        component: ProductDetail,
        layout: "/admin",
      },
      {
        path: "/channel/productDetail/:productId/channelDetail/:channelId",
        component: ChannelDetail,
        layout: "/admin",
      },
      {
        path: "/favourites/productDetail/:productId/channelDetail/:channelId",
        component: ChannelDetail,
        layout: "/admin",
      },
      {
        path: "/mynetwork/productDetail/:productId/channelDetail/:channelId",
        component: ChannelDetail,
        layout: "/admin",
      },
      {
        path: "/completeProfile",
        component: ProfileWrapper,
        layout: "/admin",
      },
      {
        path: "/notify",
        component: Notification,
        layout: "/admin",
      },
      {
        path: "/favourites",
        component: Favourites,
        layout: "/admin",
      },
      {
        path: "/userManage/userform",
        component: Userprofileform, //Productform,
        layout: "/admin",
      },
      {
        path: "/Subscription/Payment",
        component: PaymentConfirm,
        layout: "/admin",
      },
      {
        path: "/Subscription/EventPayment",
        component: EventPaymentConfirm,
        layout: "/admin",
      },
      {
        path: "/Paycard",
        component: PayCard,
        layout: "/admin",
      },
      {
        path: "/Paypal",
        component: PaypalPayment,
        layout: "/admin",
      },
      {
        path: "/Paymentsuccess",
        component: PaymentSuccess,
        layout: "/admin",
      },
      {
        path: "/linkedinconnect",
        component: LinkedInCallback,
        layout: "/admin",
      },
      {
        path: "/Addcart",
        component: Addcart,
        layout: "/admin",
      },
      {
        path: "/channel/channelDetail/:channelId/productDetail/:productId/Addcart",
        component: Addcart,
        layout: "/admin",
      },
      {
        path: "/channel/productDetail/:productId/Addcart",
        component: Addcart,
        layout: "/admin",
      },
      //cart from favourite
      {
        path: "/favourites/channelDetail/:channelId/productDetail/:productId/Addcart",
        component: Addcart,
        layout: "/admin",
      },
      //cart from mynetwork
      {
        path: "/mynetwork/channelDetail/:channelId/productDetail/:productId/Addcart",
        component: Addcart,
        layout: "/admin",
      },
      {
        path: "/favourites/productDetail/:productId/Addcart",
        component: Addcart,
        layout: "/admin",
      },
      {
        path: "/mynetwork/productDetail/:productId/Addcart",
        component: Addcart,
        layout: "/admin",
      },
      {
        path: "/alipaypayment",
        component: StripeAlipayPayment,
        layout: "/admin",
      },
      {
        path: "/stripepayment",
        component: StripePayment,
        layout: "/admin",
      },
      {
        path: "/eventstripepayment",
        component: EventStripePayment,
        layout: "/admin",
      },
      {
        path: "/eventalipaypayment",
        component: EventAlipayPayment,
        layout: "/admin",
      },
      {
        path: "/eventPaypal",
        component: EventPaypalPayment,
        layout: "/admin",
      },
      {
        path: "/searchResult/:id",
        component: SearchWrapper,
        layout: "/admin",
      },
      {
        path: "/Myproductlist/import",
        component: Prodimport,
        layout: "/admin",
      },
      {
        path: "/Myproductlist/productimport",
        component: ProductImport,
        layout: "/admin",
      },
      {
        path: "/Myproductlist/AddProduct",
        //component: Addprodtab,
        component: ProductManage,
        layout: "/admin",
      },
      {
        path: "/checkout",
        component: CheckoutTab,
        layout: "/admin",
      },
      {
        path: "/advancedsearch",
        component: Listing,
        layout: "/admin",
      },
      {
        path: "/Subscription",
        component: Subscription,
        layout: "/admin",
      },
      {
        path: "/SignupWarning",
        component: ProfileSetupaccess,
        layout: "/admin",
      },
      {
        path: "/Myproductlist/EditProduct",
        //component: Addprodtab,
        component: ProductManage,
        layout: "/admin",
      },
      {
        path: "/Myproductlist/CloneProduct",
        component: Addprodtab,
        layout: "/admin",
      },
      {
        path: "/ordersuccess",
        component: Ordersuccess,
        layout: "/admin",
      },
      {
        path: "/transaction",
        component: Transaction,
        layout: "/admin",
      },
      {
        path: "/profileform",
        component: Userprofileform,
        layout: "/admin",
      },
      {
        path: "/userManage/edituser",
        component: Userprofileform,
        layout: "/admin",
      },
      {
        path: "/payment/:type",
        component: WalletConfirm,
        layout: "/admin",
      },
      {
        path: "/paymentconfirm/:id/:type",
        component: DigitalWallet,
        layout: "/admin",
      },
      {
        path: "/vendorDashboard",
        component: VendornewDashboard,
        layout: "/admin",
      },
      {
        path: "/BuyerDashboard",
        component: BuyerDashboard,
        layout: "/admin",
      },
      {
        path: "/ewallet/:cid/:JctId",
        component: Wallet,
        layout: "/admin",
      },
      {
        path: "/FollowerView",
        component: ProfileViewListing,
        layout: "/admin",
      },
      {
        path: "/profileView",
        component: ProfileViewListing,
        layout: "/admin",
      },
      {
        path: "/userManage",
        component: Usermanage,
        layout: "/admin",
      },
      {
        path: "/settings",
        component: Settings,
        layout: "/admin",
      },
      {
        path: "/profilesettings",
        component: ProfileSettings,
        layout: "/admin",
      },
      {
        path: "/companyprofileretres",
        component: RetailReseller,
        layout: "/admin",
      },
      {
        path: "/companyprofiledist",
        component: CompanyProfileDist,
        layout: "/admin",
      },
      {
        path: "/eventSubscription/:eventId",
        component: EventSubscription,
        layout: "/admin",
      },
      {
        path: "/eventSubscription/:eventId/eventUsers",
        component: EventUsers,
        layout: "/admin",
      },
      {
        path: "/events/eventManage",
        component: Eventlistingwrapper, //ManageEventUsers,
        layout: "/admin",
      },
      {
        path: "/events/eventManagement/:id",
        component: EventManagement, //ManageEventUsers,
        layout: "/admin",
      },
      //access prev event
      {
        path: "/events/prevEvent",
        component: PrevEventlisting, //ManageEventUsers,
        layout: "/admin",
      },
      //buyer sesssion
      {
        path: "/events/buyersession/:id",
        component: BuyerSessionManagement, //ManageEventUsers,
        layout: "/admin",
      },
      {
        path: `/${userData?.channelTypeId == 2 ? "vendorDashboard" : "BuyerDashboard"
          }/eventManage/:id`,
        component: Eventlistingwrapper, //ManageEventUsers,
        layout: "/admin",
      },
      // {
      //   path: `/${
      //     userData?.channelTypeId == 2 ? "vendorDashboard" : "BuyerDashboard"
      //   }/eventSubscription/:eventId`,
      //   component: EventSubscription,
      //   layout: "/admin",
      // },

      {
        path: "/vendorDashboard/eventSubscription/:eventId",
        component: EventSubscription,
        layout: "/admin",
      },

      {
        path: "/BuyerDashboard/eventSubscription/:eventId",
        component: EventSubscription,
        layout: "/admin",
      },
      {
        path: "/eventPending",
        component: EventPending,
        layout: "/admin",
      },
      {
        path: "/PaymentError",
        component: PaymentError,
        layout: "/admin",
      },
      {
        path: "/nonmemberDetail/:id",
        component: Nonmemdetail,
        layout: "/admin",
      },
      {
        //new events test path
        path: "/eventsnew",
        component: EventsNew,
        layout: "/admin",
      },
      // {
      //   //new events test path
      //   path: "*",
      //   component: PageNotFound,
      //   layout: "/admin"
      // },
      {
        path: "/startupprodimport",
      //  component: StartupProdImport,
        component: NewProdImport,
        layout: "/admin",
      },
      {
        path: "/startupprodimport/startupprodimportsuccess",
        component: StartProdImportSuccess,
        layout: "/admin",
      },
      {
        path: "/manageUserProfile",
        component: OtherUserProfile,
        layout: "/admin",
      },
      {
        path: "/OtherUserPublicProfile/:channelId",
        component: OtherUserChannelDetail,
        layout: "/admin",
      },
    ],
  },
];

export default routes;