import React, { useEffect, useState } from 'react';
import { Button, Modal } from "reactstrap";
import { Card, CardBody, ListGroupItem, ListGroup, Row, Col } from "reactstrap";
import axios from 'axios';
export const Connect = (props) => {
  const { isOpen, handleClose, channelId, openConnectPopUp, contactList } = props;
  let logo = 'http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg';
  // const openPopUp=async (contact, status, title)=>{
  //   UpdateChannelData.connectionStatusTypeId=status;
  //   UpdateChannelData.channelJCTId=parseInt(contact.channelJCTId);
  //   UpdateChannelData.contactid=contact.record.contactId;
  //   openConnectPopUp(UpdateChannelData);
  // }
  return (
    <div>
      <Modal
        className="modal-dialog-centered"
        isOpen={isOpen}
        size="lg"
      >
        <div className="modal-header">
          <h3>Connect</h3>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={handleClose}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body  d-flex justify-content-center pt-1 pb-1">
          <Card className="w-100 mb-0">
            <CardBody>
              {contactList?.records?.length > 0 && <ListGroup className="list my--3" flush>
                {contactList?.records?.map((x) => {
                  return (
                    <ListGroupItem className="px-0">
                      <Row className="align-items-center">
                        <Col className="col-auto">
                          <a className="avatar rounded-circle" href="#pablo" onClick={e => e.preventDefault()}>
                            <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} alt="..." src={x.record.documenturl ? x.record.documenturl : logo} />
                          </a>
                        </Col>
                        <div className="col ml--2">
                          <h4 className="mb-0">
                            <a href="#pablo" onClick={e => e.preventDefault()}>
                              {(x.record.firstName).trim() + ' ' + x.record.lastName}
                              <a className="avatar flag rounded-circle ml-2">
                                {/* <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  alt="..." src="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/4x3/gb.svg"/> */}
                                {/* <CountryFlag code={contact.}/> */}
                              </a>
                            </a>
                          </h4>
                          <small >{x.record.jobTitle}</small>
                        </div>
                        <Col className="col-auto">
                          {(parseInt(x?.relationId) === 1 || parseInt(x?.relationId) === 0) ?
                            <Button color="primary" size="sm" type="button" onClick={() => openConnectPopUp(x, 7, 'Connect')}>
                              Connect
                            </Button> :
                            parseInt(x?.relationId) === 5 ? 'Dismissed' : 'Connected'}
                        </Col>
                      </Row>
                    </ListGroupItem>
                  )
                })
                }
              </ListGroup>}
            </CardBody>
          </Card>


        </div>
        <div className="modal-footer">
          <Button
            color="secondary"
            data-dismiss="modal"
            type="button"
            onClick={handleClose}
          >
            Close
          </Button>

        </div>
      </Modal>
    </div>
  );
}
