import React, { useState, useEffect } from 'react';
import { FormInput, FormAutocomplete, FormRadio } from "_components/FormElements/FormInput";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { signupschema1 } from './Validation';

import { AsyncNewFieldAutocomplete, FormAutocompleteGrouping } from '_components/FormElements/FormInput';

import axios from 'axios';
import { Dialog } from "views/Hoc/Dialog/GlobalDialog";

export default function Step2(props) {

  let comp = {};
  const { handleChange, handleChangeSelect, selectedItem } = props;


  const { firstName, lastName, email, productFamilies, isOEM, companyName, jobPosition, password, rePassword, channelTypeId, eventName, eventId } = props.values;
  const { companyList, productGroup } = props.staticValues;
  const { register, handleSubmit, control, errors, watch, setValue } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(signupschema1),
  });

  const watchCompanyName = watch('companyName', companyName);
  const watchFirstName = watch('firstName', firstName);
  const watchLastName = watch('lastName', lastName);
  const watchJobPosition = watch('jobposition', jobPosition);

  //let job = jobPosition;

  console.log('watchJobPosition', watchJobPosition)
  const [CompanyError, setCompanyError] = useState(0);

  const [associate, setAssociate] = useState(true);

  let [companyHsList, setCompanyHsList] = useState(companyList?.length ? companyList : []);
  let [channelName, setChannelName] = useState([]);

  console.log('error', errors);
  console.log('company', errors.companyName);

  //company name already taken

  let [isModalPopup, setModalPopup] = useState(false);
  let [modalPopValue, setModalPopValue] = useState({ message: null, title: null, value: null, popUpId: null, second_message: null });

  const confirmPopup = (res) => {
    setModalPopup(false);
    setAssociate(false);
    handleChangeSelect('signUpUser', { channelId: res.ch_id, userId: res.user_id, eventRegistration: res.eventRegistration });
    // if (res.id == 1) {
    //   handleChangeSelect('signUpUser', { channelId: res.ch_id, userId: res.user_id });
    //   props.handleNext();
    // }
  }
  const closePopup = () => {
    handleChangeSelect('associateUser', false);
    setModalPopup(false);
  };



  const companyAlreadyFound = (data, id) => {

    let normalmessage = <>
      <p>There is an existing account on ChannelHub for <b>{comp.name}</b> under the user <b><i>{data.firstName + '***' + ' ***' + data.lastName}</i></b>, please contact this user to add you under the same company.</p>

      <p>If you wish to claim the ownership of this account please contact <b><i>Alain Godet</i></b> at <a href={"mailto:algodet@channelhub.net"}>algodet@channelhub.net</a>, Thanks.</p>
    </>;



    let eventmessage = <>
      <p>There is an existing delegate registered for the Event to represent <b>{comp.name}</b>. The current delegate is <b><i>{data.firstName + '***' + ' ***' + data.lastName}</i></b>, please contact this user to find out the options to additional delegates to join the event.</p>

      <p>You can also use our chat to know more about our options to join the event, Thanks.</p>
    </>

    let msg = <p>
      You are trying to signup under an existing ChannelHub member company. Do you wish to be associated with this company?
    </p>

    modalPopValue.message = msg; // eventId && data.eventRegistration ? eventmessage : normalmessage;
    modalPopValue.title = 'Company Already Registered' //eventId && data.eventRegistration ? `Company Already Confirmed for ${eventName}` : 'Company Already Registered';
    modalPopValue.popUpId = 1;
    setModalPopValue({ ...modalPopValue, ...data, id: id });
    setModalPopup(true);
  }

  useEffect(() => {
    setCompanyError(0);
  }, [watchCompanyName]);

  useEffect(() => {
    // getCompanyName();
    // console.log("isOEM:",isOEM,channelTypeId)
    if (companyName.length > 0) {
      comp = companyName[0];
      checkCompanyName(3);
    }
  }, [])


  if (companyName.length > 0)
    comp = companyName[0];
  else
    comp = { name: "", id: "0" };

  const checkCompanyName = async (id) => {
    const apiUrl = 'account/checkCompanyName/';

    await axios.post(apiUrl, {
      name: comp.name,
      eventId: eventId
    }).then(res => {

      if (res.data.isavialble == true) {
        //console.log('res:', res);
        if(res.data.signUpStatusId == 15 && res.data.contactCount == 0){
          setAssociate(true);
        } else {
          setAssociate(false);
        }        
        if (id == 1) {
          //handleChangeSelect('signUpUser', { channelId: res.ch_id, userId: res.user_id });
          props.handleNext();
        }
        if (typeof (res.data.isavialble) == "object" && id == 3)
          handleChangeSelect('signUpUser', {signUpStatusId:res.signUpStatusId,accountContactId:res.accountContactId,channelId: res.ch_id, userId: res.user_id });

      }
      else {
        console.log('error already taken mail')
        // setCompanyError(1);    
        if (id == 1 && associate == false) {
          props.handleNext();
          //handleChangeSelect('signUpUser', { channelId: res.ch_id, userId: res.user_id });
        }
        // else if (id == 3) {
        //   setAssociate(false);
        //   handleChangeSelect('signUpUser', { channelId: res.ch_id, userId: res.user_id });
        // }
        else
          companyAlreadyFound(res.data.isavialble, id);
      }



    })

  }

  const onSubmit = data => {
    checkCompanyName(1);
  }


  console.log('comp', comp)
  let isMultiple = false;
  var pfamily = 1;
  //console.log("productGroup:",productGroup);
  // if(productFamilies.length>0 && productFamilies[0].length>1)
  //     isMultiple=true;
  // else if(productFamilies.length>0 && productFamilies[0].length)   
  //     pfamily=productFamilies[0][0].id;


  //console.log("prod_fam",productFamilies)
  //console.log('eva',productFamilies[0] ? productFamilies[0] : [{name: '', id: ''}]);

  const getCompanyName = async () => {
    const apiUrl = '/account/getCompanyName';
    axios.get(apiUrl).then(res => {
      channelName = [];
      console.log('res.data', res.data);
      res.data.map(x => {
        if (x.name)
          channelName.push(x.name.trim());
      });
      setChannelName(channelName);
      console.log('channelName', channelName);
    });
  };

  let handleInput = '';

  const onChangeHandle = async value => {
    // this default api does not support searching but if you use google maps or some other use the value and post to get back you reslut and then set it using setOptions 
    console.log(value);
    handleInput = value;

    const apiUrl = '/Hubspot/getCompanyListSearch?search=' + value;
    axios.get(apiUrl).then(res => {
      if (!!companyList.length)
        companyHsList = companyList;
      companyHsList.push(...res.data);
      setCompanyHsList(companyHsList);
      console.log('companyHsList', companyHsList);
    });
  };
  let [open, setOpen] = React.useState(false);
  useEffect(() => {
    if (!open) {
      companyHsList = companyList;
      setCompanyHsList(companyHsList);
    }
  }, [open]);

  const handleChangeCompName = (input, value) => {
    if (value)
      comp = value;
    else
      comp = { name: "", id: "0" };
    if (comp.name)
      checkCompanyName(2)
    handleChangeSelect(input, value);
  }

  const handleKeyDown = (event) => {
    switch (event.key) {
      case "Tab": {
        if (handleInput.length > 0) {
          handleChangeCompName('companyName', { name: handleInput });
          setValue('companyName', { name: handleInput });
        }
        break;
      }
      case "Enter": {
        if (handleInput.length > 0) {
          handleChangeCompName('companyName', { name: handleInput });
          setValue('companyName', { name: handleInput });
        }
        break;
      }
      default:
    }
  };

  return (
    <div className="main-content">
      <div className="container " style={{padding:'0'}}>
        <Dialog open={isModalPopup} value={modalPopValue} handleSave={confirmPopup} handleClose={closePopup} />
        <h5 className='mob-signhead' style={{display:'none'}}>User Details</h5>

        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="typeId" ref={register} name="typeId" value={associate ? 1 : 0} control={control} />
          <div className="row">
            <div className="col-lg-12">
              <div className="form-group">
                <AsyncNewFieldAutocomplete
                  id="companyName"
                  type="text"
                  label="Company Name *"
                  name="companyName"
                  options={companyHsList}
                  onChangeHandle={onChangeHandle}
                  channelName={channelName}
                  open={open}
                  setOpen={(val) => {
                    open = val;
                    setOpen(open)
                  }}
                  placeholder="companyName"
                  defaultValue={comp}
                  disableCloseOnSelect
                  limitTags={2}
                  control={control}
                  input='companyName'
                  value={comp}
                  register={register}
                  errMessage={CompanyError ? 'Company name already taken' : companyName[0] ? 'Company name must be minimum 2 characters' : 'Please enter company name'}
                  error={errors.companyName ? errors.companyName : CompanyError}
                  handleChangeSelect={handleChangeCompName}
                  handleKeyDown={handleKeyDown}
                />
              </div>
            </div>

            <div className="col-lg-6">
              <div className="form-group">
                <FormInput
                  id="firstName"
                  type="text"
                  name="firstName"
                  label="First Name *"
                  //placeholder="Password"
                  register={register}
                  defaultValue={firstName}
                  error={errors.firstName}
                  value={watchFirstName}
                  onChange={handleChange('firstName')}
                />
              </div>
            </div>


            <div className="col-lg-6">
              <div className="form-group">
                <FormInput
                  id="lastName"
                  type="text"
                  name="lastName"
                  label="Last Name *"
                  //placeholder="Password"
                  register={register}
                  error={errors.lastName}
                  value={watchLastName}
                  defaultValue={lastName}
                  onChange={handleChange('lastName')}
                />
              </div>
            </div>

            <div className="col-lg-12">
              <div className="form-group">
                <FormInput
                  id="jobposition"
                  type="text"
                  label="Job Position *"
                  name="jobposition"
                  control={control}
                  register={register}
                  error={errors.jobposition}
                  defaultValue={jobPosition}
                  value={watchJobPosition}
                  onChange={handleChange('jobPosition')}
                />
              </div>
            </div>

            {/* {associate && <>
              <div className="col-lg-12 ">
                <div className="form-group">
                  <FormRadio
                    name="Products/OEM"
                    label="Are you offering B2B or B2C Products? (B2C are consumer oriented products, B2B are business oriented products)  *"
                    options={radioGroupOptions}
                    control={control}
                    row
                    onChange={handleChange('isOEM')}
                    // disabled={channelTypeId == '5' || channelTypeId == '4'}
                    value={isOEM}
                  />
                </div>
              </div>

              <div className="col-lg-12">
                <div className="form-group">
                  <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="productCat" name="productCat" ref={register} value={productFamilies.length ? productFamilies[0].childname : ''} />
                  <FormAutocompleteGrouping
                    id="product_categories"
                    name="product_categories"
                    label="Product Families are you selling *"
                    Options={productGroup}
                    placeholder="Select Product Families"
                    input='productFamilies'
                    errMessage={'Please Select a Product Family'}
                    error={!errors.productCat || productFamilies.length ? false : true}
                    defaultValue={productFamilies}
                    value={productFamilies}
                    limitTags={2}
                    optionName='productGroup'
                    optionValue='productFamilies'
                    selectedItem={selectedItem}

                    handleChangeSelect={handleChangeSelect}
                    control={control}
                    register={register}

                  />
                </div>
              </div>
            </>} */}


          </div>
          <button type="submit" className="btn btn-primary ch-btn-blue right-but" variant="contained" color="primary" >
            Next
          </button>
        </form>

      </div>
    </div>

  )
}

const radioGroupOptions = [
  {
    value: "1",
    label: "B2C",
  },
  {
    value: "2",
    label: "B2B",
  },
  {
    value: "3",
    label: "Both",
  }
];

