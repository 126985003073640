import React, { useEffect, useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import  "_components/Stripe/styles.scss";
import {ToastsContainer, ToastsStore} from 'react-toasts';
import { subscriptionAction } from '_actions/subscription.action';
import { connect, useSelector  } from 'react-redux';
import { commonService } from '_services/common.service';
import { history } from '../../../../_helpers';
import { Spinner } from "views/Hoc/Spinner";
import { Card,CardBody,Col,Row} from "reactstrap";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY);

const CardFormInter = (props) =>  {
  const stripe = useStripe();
  let [transactionId, setSubscription_transactionId] = useState(null);
  const elements = useElements();
  const redux_data = useSelector(state => state.subscription);
  let [loading, setLoading] = useState(false);
  
  let userData = JSON.parse(localStorage.getItem('CHuser'));
  let src_Charge = null;
 // this.state = {transactionId: null};
  useEffect(() => {
    const subId = redux_data.select_plan;
    // if(!subId){
    //   history.push('/admin/Subscription');
    //   return;
    // }

    first();
      }, [])
  const handleSubmit = async event => {
        event.preventDefault();

        if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
        }
        subscription();
  };


   // first create subscription to check is valid subscription
   // if subscription is allowed then create source
  const subscription =async () => {
    setLoading(true);
    const subId = redux_data.select_plan;          
    let res = await commonService.postService({
      subscriptionId:parseInt(subId.subscriptionId),
      paymentMethodId:4,
      //transactionId:null,
      couponCode:redux_data.couponCode||null
   }, 'channel/subscription/subscribe').catch(err =>console.log('err',  ToastsStore.error(err.response.data.message)));
   if(res){
     setLoading(false);
        userData.subscriptionId=subId.subscriptionId;
        userData.paymentMethodId=4;
        userData.planName = redux_data.select_plan.title +' - '+ redux_data.select_plan.month;
        localStorage.setItem('CHuser', JSON.stringify(userData));
       
        //redux_data.transactionId = res.data.transactionId;
        // setSubscription_transactionId(res.data.transactionId);
        // console.log('++++++++++++++++++++++props.subscriptionRedux++++++++++++++++++++++++');
        // console.log(props.subscriptionRedux);
        // props.subscriptionRedux(res.data.transactionId,'SUBS_TANSACTIONID');

            userData.planId = res.data.planId;
            userData.subscriptionId = +res.data.channelSubscription.subscriptionId;
            userData.featureList = res.data.channelSubscription.keyFeature;
            userData.planExpireAt = res.data.channelSubscription.endAt
            localStorage.setItem('CHuser', JSON.stringify(userData));


        localStorage.setItem('lasttransactionId', res.data.transactionId);
        localStorage.setItem('pay_amt', (redux_data.pay_amt) * 100);
        const paymentSource =  await stripe.createSource({
          type: 'alipay',
          amount: (redux_data.pay_amt) * 100,
          currency: 'eur',
          redirect: {
          return_url: window.location.origin+'/admin/alipaypayment',
          },
        }).then(function(result) { console.log(result);
            if(result.error){
            console.log(result.error.message);
            
            }
            window.open(result.source.redirect.url, "_self" );
            return result;
        });

   }
  }
  
  const first = async () => {  
    
    var url_string =  window.location.href
    var url = new URL(url_string);
    var params = url.searchParams.get("client_secret");
    var status = url.searchParams.get("redirect_status");
    let alipay_source =  url.searchParams.get("source");
    if(params && params != null && status == 'succeeded'){  setLoading(true);
        
        let alipay_client_secret =  url.searchParams.get("client_secret");
        let isCharged = await commonService.postService({
            amount:+localStorage.getItem('pay_amt'),   //+((redux_data.pay_amt) * 100),
            currency:'eur',
            sourceId:alipay_source,
            channel_transId:+localStorage.getItem('lasttransactionId')
       }, 'channel/subscription/stipeCreatecharge').catch(err =>console.log('err',  ToastsStore.error(err.response.data.message)));

       //console.log(isCharged)
       if(isCharged.data.e){
        ToastsStore.error(isCharged.data.e.code);
        setLoading(false);
        history.push('/admin/PaymentError');
       } else {
       if(isCharged.data){
          if(isCharged.data.charge.status === 'succeeded'){
            commonService.getServices('algorithm/channel').catch(err =>console.log(err.response.data.message,  ToastsStore.error('unable to create suggestion')));
            // window.location.href = window.location.origin+'/admin/Paymentsuccess';
            history.push('/admin/Paymentsuccess');
            
          } else {
            // window.location.href = window.location.origin+'/admin/PaymentError';
            history.push('/admin/PaymentError');
          }
        }
       }
      } else if(status == 'failed'){  //failed transaction

      let isCharged = await commonService.postService({
        amount:+localStorage.getItem('pay_amt'),   //+((redux_data.pay_amt) * 100),
        currency:'eur',
        response:params +' &status '+ status +' &source '+ alipay_source,
        paymentType:4,
        transactionId:+localStorage.getItem('lasttransactionId')
      }, 'channel/subscription/failedTransaction').catch(err =>console.log('err',  ToastsStore.error(err.response.data.message)));


        history.push('/admin/PaymentError');
    }

    
    };
    

  return ( <div><ToastsContainer position="top_right" autoClose={5000} store={ToastsStore}/>
     {loading?<Spinner/>:
     <Card className="p-3"> <form onSubmit={handleSubmit}>
     <h1 className="ml-3 mt-3">Pay With Alipay</h1><hr className="m-0 ml-3 paycarhr" style={{    width: "14%"}}/>
     <Row className="justify-content-center pb-5">
                 
                 <Col lg="auto" >
                 <CardBody className="text-center">
                 <button className="btn btn-primary" type="submit" disabled={!stripe}>
         Proceed Alipay
       </button>
     </CardBody>
     </Col>
     </Row>
     </form>
     </Card>}
 
  
    
    </div>
  );
};




const EventStripeAlipayPayment = () => {
  const redux_data = useSelector(state => state.subscription);
  useEffect(() => {
    const subId = redux_data.select_plan;
    // if(!subId){
    //   history.push('/admin/Subscription');
    //   return;
    // }
      }, [])
  return (
    <Elements stripe={stripePromise}>
    <CardFormInter />
  </Elements>
    
  );
};


// export default StripeAlipayPayment;

function mapState(state) {
  console.log('state', state);
  const general_data= state.subscription;
  return general_data;
}

const actionCreators = {
  subscriptionRedux: subscriptionAction.subscriptionRedux
};

export default connect(mapState,actionCreators)(EventStripeAlipayPayment);

