export const userConstants = {
    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
    
    LOGOUT: 'USERS_LOGOUT',

    GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USERS_GETALL_FAILURE',

    DELETE_REQUEST: 'USERS_DELETE_REQUEST',
    DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
    DELETE_FAILURE: 'USERS_DELETE_FAILURE' ,
    
    ///SubscriptionCharcteristicFeature
	PROFILE_PUBLIC_VIEW:22,
	PRODUCT_CH_HOMEPAGE:21,
	COMPANY_FEATURE_BRAND_HOMEPAGE:20,
	PRODUCT_ON_CATLOG_LISTING:19,
	PRODUCT_ON_SOCIALMEDIA:18, // OFFLINE
	PRODUCT_IN_SUGGESTION_TO_BUYER:17,
	SOCIAL_SHARING:16,
	NO_OF_PRODUCT:15,
	BUYING_SEND_CONNECTION_REQUEST:14,
	ACCESS_TO_DATABASE:13,
	SUGGESTION_BUYER:12,
	SAVE_PROFILE_TO_FAVOURITE:11,
	FAVOURITE_BUYER_INVITE_FOR_EVENT:10,
	FAVOURITE_BUYER_NOTIFY_WHEN_CONNECT_TO_ACCOUNT:9,
	DASHBOARD_PROFILE_PRODUCT:8,
	FOLLOW_THIS_BRAND:7,
	ADD_USER:6,
	DEDICATE_ACCOUNT_MANAGER:5
	
};
