import React, { Fragment, useState, useEffect } from "react";
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, TimePicker } from "@material-ui/pickers";
import FormHelperText from '@material-ui/core/FormHelperText';
import { Card, CardBody, Row, Col, Container, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown, Button } from 'reactstrap';
import FormControl from '@material-ui/core/FormControl';
import { FormInput } from '_components/FormElements/FormInput';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { EventTravelSchema } from './validation';
import { ToastsStore, ToastsContainer } from 'react-toasts';
import { Spinner } from 'views/Hoc/Spinner';
import axios from "axios";
// import ReactPlayer from "react";


const EventAwards = (props) => {

    const [loading, setLoading] = useState(false);

    useEffect(() => {
     
    }, []);
  //  console.log('errprs', errors)

 

    return (
      // <Container>
     
        <Card>
          <Col xs-12>
            <div className="eventaward">
            <h2>Pitch Your Product Awards 2022</h2>
            <span className="eventspan"><span></span></span>
            <p style={{width:"80%"}}>Get to know the exhibitors and find out more about their brand and products. Vendors present live for 5 minutes and demo their latest products and innovations. Delegates are invited to check out their presentations and submit their vote for the Best Product Award.  Categories include Audio, Video, Gaming & Computing, IT Peripherals & Accessories, Smart Tech and Smart Home.</p>
           </div>
           <div className="col-xs-12 eventaward">
             <h2>Introduction & Opening Keynote by GfK</h2>
             <span className="eventspan"><span></span></span>
             <div className="eventvideo">
<iframe src="https://player.vimeo.com/video/710248219?h=bfac02bc08&amp;app_id=122963" width="800" height="400" frameborder="0" allowfullscreen title="Intro Channel HUB.mp4"></iframe>
</div> </div>
            <div className="eventaward">
            <h5>IT Peripherals & Accessories</h5>
            <p>Adesso - <span>CyberTrack K4</span></p>
            <p>Port - <span>​​VIZIBL</span></p>
            <p>Zendure - <span>SuperBase Pro 2000​</span></p>
            <p>ACT Connectivity - <span>ACT Full HD Conference Camera​</span></p>
            <p>IRIS - <span>IRIScan Desk 6 Business Win/mac​ </span></p>
            <p>Urban Factory - <span>ERGO MAX Ergonomic Vertical Mouse ​</span></p>
            <p>Badgy - <span>Badgy200</span></p>
            <div className="eventvideo">
            <iframe src="https://player.vimeo.com/video/710252480?h=81937b59f0&amp;app_id=122963" width="800" height="400" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="Channel HUB Partie 1_IT Peripherals et Accessories.mp4"></iframe>

           </div>
           </div>
            <div className="eventaward">
            <h5>Smart Tech</h5>
            <p>Aura Air - <span>Breathe Easy</span></p>
            <p>SkateFlash - <span>Avantsee</span></p>
            <p>ELARI - <span>KidPhone 4G Wink​</span></p>
            <p>IRIS - <span>IRIScan Desk 6 Business Win/mac​</span> </p>
            <p>Platum - <span>KPF </span>​</p>
            <p>SoFlow- <span>SO1 Pro E-Scooter​​</span></p>
            <p>Y Brush - <span>Premium Pack </span></p>
            <p>LIVALL -  <span>VOE21 ​&​ LTS21 Open Ear Headphone</span></p>
            <p>ALPMARS - <span>escooter</span></p>
            <div className="eventvideo">

            <iframe src="https://player.vimeo.com/video/710255274?h=da3b5f5abf&amp;app_id=122963" width="800" height="400" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="Channel HUB Partie 2_Smart Tech.mp4"></iframe>
       </div>
           </div>
            <div className="eventaward">
            <h5>Smart Home
</h5>
            <p>Bimar - <span>Wifi PTC Fan Heater</span></p>
            <p>CoClean - <span>Cordless Stick Vacuum S1</span></p>
            <p>DAAN.TECH -<span>Bob​</span></p>
            <p>Swann - <span>4 Camera 8 Channel Security System​</span> </p>
            <p>Véritable® - <span>SMART Indoor Garden​</span></p>
            <div className="eventvideo">
            <iframe src="https://player.vimeo.com/video/710256998?h=94f19ac55d&amp;app_id=122963" width="800" height="400" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="Channel HUB Partie 3_Smart Home.mp4"></iframe>

            </div>
           </div>
            <div className="eventaward">
            <h5>Audio / Video</h5>
            <p>HP - <span>DP ONE​</span></p>
            <p>Urbanista - <span>SEOUL mobile gaming earphones</span></p>
            <p>Trevi - <span>Karaoke Amplifier Speaker with Fire Flame​</span></p>
            <p>Philips - <span>PicoPix Max TV​​</span> </p>
            <p>Agfa Photo - <span>Realikids Instant Cam​ </span>​</p>
            <p>Aiwa - <span>All-in-one amplifier</span></p>
            <p>Mymanu - <span>Clik S​</span></p>
            <p>T’nb - <span>PKSTUDIO​</span></p>

            <iframe src="https://player.vimeo.com/video/710258136?h=74d2ba0129&amp;app_id=122963" width="800" height="400" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="Channel HUB Partie 4_Audio et Video.mp4"></iframe>
</div>
<div className="eventaward">
            <h5>Mobile Products</h5>
            <p>Tellur - <span>Green Qi wireless fast desk charger​ 15W​</span></p>
            <p>Energizer - <span>Hard Case H621S​</span></p>
            <p>Hammer- <span>Explorer Plus Eco with external battery</span></p>
            <p>iFixit - <span>Pro Tech Tool Kit</span> </p>
            <p>Celly - <span>Ghostclick ​</span></p>
            <p>Pocketbook - <span>ereaders</span></p>
            <p>Sandberg - <span>Solar 4-Panel Powerbank 25000</span></p>
            <p>UV Consulting - <span>GRAB & GO ECO-FRIENDLY MOBILE PHONE ACCESSORIES​</span></p>
            <p>Shiftcam - <span>SnapGrip</span>​</p>
            <p>Valenta - <span>Clear Cover Mag Safe iPhone Series</span>​</p>

            <iframe src="https://player.vimeo.com/video/710260056?h=fb2251cfbe&amp;app_id=122963" width="800" height="400" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="Channel HUB Partie 5_Mobile Products.mp4"></iframe>
           </div>

            <div className="eventaward">
            <h5>Gaming & Computing</h5>
            <p>AQIYRS - <span>The Great Annihilator</span>​</p>
            <p>Playseat - <span>Trophy</span></p>
            <p>Arozzi Occhio - <span>Webcam &</span></p>
            <p>Arozzi Occhio - <span>True Privacy™ Ring Light Webcam</span> </p>
            <p>XTRFY - <span>K5 COMPACT</span></p>
            <p>Ordissimo - <span>Celia</span></p>
            <p>Mymanu - <span>Clik S​</span></p>
            <p>RiotPWR - <span>RP1950X Cloud​</span></p>

            <iframe src="https://player.vimeo.com/video/710261648?h=83bf30bbbc&amp;app_id=122963" width="800" height="400" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="Channel HUB Partie 6_Gaming and Computing + Conclusion"></iframe>
</div>
          
          
         
           </Col>
        </Card>
        // </Container>
    );
};

export default EventAwards;