import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useForm } from "react-hook-form";
import { commonService } from '_services/common.service';
import { ListGroupItem, ListGroup } from 'reactstrap';
import { FormAutocomplete } from "_components/FormElements/FormInput";
import Slider from '@material-ui/core/Slider';
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));



const ChannelFilter = (props) => {
  //   const classes = useStyles();

  const { activeTab, handleChangeSelect, filterValueState, resetFilterValue } = props;
  const [filterOptionState, setFilterOption] = useState({ PositionOption: [{ name: 'MID', id: '1' }, { name: 'HIGH', id: '2' }], Brand_Option: [], Profile_Option: [], Location_Option: [], Family_Option: [], Category_Option: [], });
  const user = JSON.parse(localStorage.getItem('CHuser'));
  const { control } = useForm({ mode: 'onBlur' });

  let viewId = props.viewId;

  useEffect(() => {
    getSearchAggregation();
    //getOptions();
    setFlag(true);
  }, [])

  const [flag, setFlag] = useState(false);

  useEffect(() => {
    //console.log("parent:", filterValueState.parent);
    if ((flag && filterValueState.categories?.length) || (filterValueState.brand?.length == 0 && filterValueState.location?.length == 0 && filterValueState.categories?.length == 0)) {
      getSearchAggregation();
    }
  }, [filterValueState.categories]);

  useEffect(() => {
    if ((flag && filterValueState.brand?.length) || (filterValueState.brand?.length == 0 && filterValueState.location?.length == 0 && filterValueState.categories?.length == 0)) {
      getSearchAggregation();
    }
  }, [filterValueState.brand]);

  useEffect(() => {
    if ((flag && filterValueState.location?.length) || (filterValueState.brand?.length == 0 && filterValueState.location?.length == 0 && filterValueState.categories?.length == 0)) {
      getSearchAggregation();
    }
  }, [filterValueState.location]);

  // const getOptions = async () => {
  //   await axios.get('guest/country').then(res => {
  //     filterOptionState.Location_Option = res.data;

  //   })
  //   setFilterOption({ ...filterOptionState });
  // }

  const getSearchAggregation = async () => {

    let data;
    data = {
      categoryId: filterValueState.categories ? filterValueState.categories.map(x => x.id) : [],
      brandId: filterValueState.brand ? filterValueState.brand.map(x => x.id) : [],
      locationId: filterValueState.location ? filterValueState.location.map(x => x.id) : [],
    }

    let products = await commonService.filterPostService({ data }, '/guest/buyersaggr/' + viewId + '/' + filterValueState.parent);

    if (products.length > 0) {
      let data = products;

      if (filterValueState.parent == 'categories' || filterValueState.parent == 'location' || filterValueState.brand?.length == 0) {
        filterOptionState.Brand_Option = await commonService.filterPostService({
          data
        }, '/guest/brandsFilter');
        setFilterOption({ ...filterOptionState });
      }

      if (filterValueState.parent == 'categories' || filterValueState.parent == 'brand' || filterValueState.location?.length == 0) {
        filterOptionState.Location_Option = await commonService.filterPostService({
          data
        }, '/guest/countryFilter');
        setFilterOption({ ...filterOptionState });
      }

      if (filterValueState.parent == 'brand' || filterValueState.parent == 'location' || filterValueState.categories?.length == 0) {
        filterOptionState.Category_Option = await commonService.filterPostService({
          data
        }, '/guest/categoriesFilter');
        setFilterOption({ ...filterOptionState });
      }


      // filterOptionState.Category_Option=await commonService.getServices('/guest/brandsFilter').then(res => res.data); 
      // setFilterOption({...filterOptionState});    

    }



    // if(data){
    //   let options = data.data;
    //   if(options){   
    //  filterOptionState.Brand_Option=options.brand;
    //  filterOptionState.Family_Option=options.family;
    //  filterOptionState.Category_Option=options.category;
    //  filterOptionState.Profile_Option=options.profile;
    //  filterOptionState.Location_Option=options.location;
    //  setFilterOption({...filterOptionState});
    //  }
    //  }
    console.log('filterOptionState', filterOptionState);
  }

  const useStyles = makeStyles({
    root: {
      width: "100%",
    },
  });
  function valuetext(value) {
    return `${value}°C`;
  }

  const classes = useStyles();
  const [value, setValue] = React.useState([20, 37]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const style = { height: '18rem' };

  return (
    <div className='fav-ser1 p-2'>
      <div className={classes.root}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>Categories</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ListGroup>
                <ListGroupItem style={filterOptionState.Category_Option.length > 0 ? style : {}}>
                  <div className=" text-center mt-2">
                    <div className="form-group">
                      <FormAutocomplete
                        id="Positioning"
                        name="Positioning"
                        label=""
                        options={filterOptionState.Category_Option}
                        getOptionSelected={(options, value) => options.name === value.name}
                        placeholder="Type Categories"
                        multiple
                        // disableCloseOnSelect
                        limitTags={1}
                        control={control}
                        input='categories'
                        value={filterValueState.categories}
                        open={true}
                        popupIcon=''
                        disablePortal={true}
                        size='small'
                        ListboxProps={{ style: { maxHeight: '16rem' } }}
                        handleChangeSelect={handleChangeSelect}
                      />
                    </div>
                  </div>
                </ListGroupItem>
              </ListGroup>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>Brand</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ListGroup>
                <ListGroupItem style={filterOptionState.Brand_Option.length > 0 ? style : {}}>
                  <div className=" text-center mt-2">
                    <div className="form-group">
                      <FormAutocomplete
                        id="Positioning"
                        name="Positioning"
                        label=""
                        options={filterOptionState.Brand_Option}
                        getOptionSelected={(options, value) => options.name === value.name}
                        placeholder="Type Brand"
                        multiple
                        // disableCloseOnSelect
                        limitTags={1}
                        control={control}
                        input='brand'
                        value={filterValueState.brand}
                        open={true}
                        popupIcon=''
                        disablePortal={true}
                        size='small'
                        ListboxProps={{ style: { maxHeight: '16rem' } }}
                        handleChangeSelect={handleChangeSelect}
                      />
                    </div>
                  </div>
                </ListGroupItem>
              </ListGroup>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>Trade Location</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ListGroup>
                <ListGroupItem style={filterOptionState.Location_Option.length > 0 ? style : {}}>
                  <div className=" text-center mt-2">
                    <div className="form-group">
                      <FormAutocomplete
                        id="Positioning"
                        name="Positioning"
                        label=""
                        options={filterOptionState.Location_Option}
                        getOptionSelected={(options, value) => options.name === value.name}
                        placeholder="Type Location"
                        multiple
                        // disableCloseOnSelect
                        limitTags={1}
                        control={control}
                        input='location'
                        value={filterValueState.location}
                        open={true}
                        popupIcon=''
                        disablePortal={true}
                        size='small'
                        ListboxProps={{ style: { maxHeight: '16rem' } }}
                        handleChangeSelect={handleChangeSelect}
                      />
                    </div>
                  </div>
                </ListGroupItem>
              </ListGroup>
            </Typography>
          </AccordionDetails>
        </Accordion>


      </div>
    </div>
  );
}

export default ChannelFilter;