import React, { useState, useEffect } from "react";
import { TextField, Checkbox, RadioGroup, Radio, FormControlLabel, FormControl, FormLabel } from "@material-ui/core";
import Rating from '@material-ui/lab/Rating';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { Controller } from "react-hook-form";
//import {Signup} from "views/pages/SignupPage/Signup.scss";
import { makeStyles } from '@material-ui/core/styles';

import MuiPhoneNumber from 'material-ui-phone-number';
import OTPInput, { ResendOTP } from "otp-input-react";
import ReactPhoneInput from 'react-phone-input-2'
import ReactQuill from "react-quill";
//import 'react-phone-input-2/lib/material.css'

// import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ListSubheader from '@material-ui/core/ListSubheader';
import { useTheme } from '@material-ui/core/styles';
import { VariableSizeList } from 'react-window';
import { Typography } from '@material-ui/core';
import Popper from "@material-ui/core/Popper";

var _ = require('lodash');

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
}));



export const FormInput = ({ register, error, ...inputprops }) => {
  const classes = useStyles();
  return <>
    <TextField autoComplete='off' helperText={error?.message} error={!!error} variant="outlined" className={classes.root}
      inputRef={register}
      {...inputprops}
    />

  </>
}

export const FormFileInput = ({ register, id, label, error, ...inputprops }) => {
  const classes = useStyles();
  return <>
    <label htmlFor={id}>{label}</label>
    <TextField helperText={error?.message} error={!!error} variant="outlined" className={classes.root}
      inputRef={register}
      {...inputprops}
    />

  </>
}


export const FormTextArea = ({ register, length, error, label, id, ...inputprops }) => {
  return <>
    <label htmlFor={id}>{label}</label>
    <textarea className="form-control"
      ref={register}
      id={id}
      maxLength={length}
      {...inputprops}
    />
    {error && <div style={{ color: 'red', textAlign: 'left', fontSize: '0.75rem' }}>{error.message}</div>}
  </>
}

export const FormSelect = ({ register, defaultstate, error, label, id, options, handleChangeSelect, ...inputprops }) => {

  const [state, setState] = useState(defaultstate);
  useEffect(() => {
    handleChangeSelect('selev', state);
  }, [state])
  return <>
    <label htmlFor={id}>{label}</label>
    <select className="form-control"
      ref={register}
      id={id}
      value={state}
      onChange={e => setState(e.target.value)}
      //onBlur={e=>setState(state=>state.concat(e.target.value))}
      disabled={!options.length}
      {...inputprops}
    >
      <option>All</option>
      {options.map(item =>
        <option key={item} value={item}>{item}</option>)}
    </select>

    {error && <div>{error.message}</div>}
  </>
}

export const FormButton = ({ value, register, label, id, className, ...inputprops }) => {
  return <>
    <label htmlFor={id}>{label}</label>
    <button className={className}
      ref={register}
      id={id}
      {...inputprops}
    >
      {value}
    </button>
  </>
}

export const FormMultiSelect = ({ register, defaultstate, error, label, id, options, ...inputprops }) => {
  const [state, setState] = useState(defaultstate);

  return <>
    <label htmlFor={id}>{label}</label>
    <select multiple={true} className="form-control"
      ref={register}
      id={id}
      value={state}
      onChange={e => { e.persist(); setState(state => state.concat(e.target.value)) }}
      //onBlur={e=>setState(state=>state.concat(e.target.value))}
      disabled={!options.length}
      {...inputprops}
    >
      <option>All</option>
      {options.map(item =>
        <option key={item} value={item}>{item}</option>)}
    </select>

    {error && <div>{error.message}</div>}
  </>
}

export const FormCheckBox = ({ register, error, label, id, ...inputprops }) => {
  return <>
    <div className="custom-control custom-control-alternative custom-checkbox">
      <input
        className="custom-control-input"

        name={id}
        id={id}
        ref={register}
        {...inputprops}
      />
      <label
        className="custom-control-label" htmlFor={id}>
        {label}
      </label>
    </div>
  </>
}

export const FormAutocomplete = ({ errMessage, name, input, error, label, placeholder, defaultValue, control, handleChangeSelect, ...inputprops }) => {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  // console.log("input:",input);
  //const { address, street, country, postalCode, websiteUrl, product_categories, isOEM, switchphone } = props.values;

  return <React.Fragment>
    <Controller
      render={({ onChange }) => (
        <Autocomplete
          // inputRef={register}  
          {...inputprops}
          getOptionLabel={(option) => option.name}
          renderOption={(option, { selected }) => (
            <React.Fragment>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.name}
            </React.Fragment>
          )}
          renderInput={(params) => (
            <TextField {...params} helperText={error ? errMessage : ''} register={inputprops.register} error={error} variant="outlined" autoComplete='off' label={label} placeholder={placeholder} />

          )}
          onChange={(e, data) => {
            handleChangeSelect(input, data);
            onChange(data)
          }}
          disableClearable={true}
        />
      )}

      onChange={([, data]) =>
        data
      }
      defaultValue={defaultValue}
      name={name}
      control={control}

    />

  </React.Fragment>
}

export const FormAutocompleteGrouping = ({ selectedItem, optionName, optionValue, value, name, limitTags, id, input, Options, label, multiple, control, placeholder, defaultValue, isGroup, ...inputprops }) => {
  
  const [inputValue, setInputValue] = React.useState("");
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const styles = (theme) => ({
    popper: {
      maxWidth: "fit-content"
    }
  });
  const PopperMy = function (props) {
    return <div {...props} style={styles.popper} placement="bottom-start" />;
  };

  const options = Options.map((option) => {

    
    const firstLetter = option.groupname;

    return {

      firstLetter,
      ...option,
    };
  });
  const renderGroup = (params) => [

    <div style={{ fontWeight: 600, color:'red' }} key={params.id} component="div">


      <Checkbox
        icon={icon}
        style={{ marginLeft: '-30px' }}
        checkedIcon={checkedIcon}
        checked={(defaultValue.filter(x => x.groupname == params.group).length > 0) ? true : false}
        onChange={e => { selectedItem(params.group, e.target.checked, optionName, optionValue) }}
      />
      <h4 className="dropdownText">
        {params.group}</h4>
    </div>,
    params.children,
  ];
  return <React.Fragment>
    <Controller
      render={({ onChange }) => (
        <Autocomplete
          multiple={multiple != undefined ? multiple : true}
          {...inputprops}
          PopperComponent={PopperMy}
          id={id}
          ListboxComponent={ListboxComponent}

          onInputChange={(event, value, reason) => {
            if ( event && event.type === 'blur' ) {
              setInputValue('');
            } else if ( reason !== 'reset' ) {
              setInputValue(value);
            }
          }}

          inputValue={inputValue}
          
          options={options.sort((a, b) =>
            -b.groupname.localeCompare(a.groupname) || -b.childname.localeCompare(a.childname))
            // options.sort((a, b) =>
            //   -b.groupname.localeCompare(a.groupname))
          }

          value={value.sort((a, b) =>
            -b.groupname.localeCompare(a.groupname) || -b.childname.localeCompare(a.childname))
          }


          getOptionSelected={(options, value) => {
            if (options.childname === value.childname && options.groupname === value.groupname) { options.status = true; return true; } else { options.status = false; return false; }
          }}


          disableCloseOnSelect

          disablePortal={true}
          limitTags={limitTags ? limitTags : 3}
          renderGroup={renderGroup}
          disableClearable={true}
          groupBy={(option) => option.firstLetter}
          getOptionLabel={(option) => (isGroup && isGroup == 0) ? option.childname : option.groupname + "-" + option.childname}
          //value={value}
          defaultValue={defaultValue}
          renderOption={(option) => (
            <React.Fragment>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 3 }}
                checked={option.status}
              />
              <span className="dropdownText1">
                {option.childname}</span>
            </React.Fragment>
          )}
          renderInput={(params) => (
            <TextField {...params} inputRef={inputprops.register} helperText={inputprops.error ? inputprops.errMessage : ''} error={inputprops.error} variant="outlined" autoComplete='on'  label={label} placeholder={placeholder}

            />
          )}
          onChange={(e, data) => {
            
            inputprops.handleChangeSelect(input, data);
            onChange(data);
           
          }}
        />
      )}
      onChange={([, data]) =>
        data
      }
      defaultValue={defaultValue}
      value={value}
      name={name}
      control={control}
    />
  </React.Fragment>
}

export const FormAutocompleteGroupingRegion = ({ selectedItem, optionName, optionValue, value, name, limitTags, id, input, Options, label, multiple, control, placeholder, defaultValue, isGroup, ...inputprops }) => {
  console.log("isGroup:", id);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const styles = (theme) => ({
    popper: {
      maxWidth: "fit-content"
    }
  });
  const PopperMy = function (props) {
    return <div {...props} style={styles.popper} placement="bottom-start" />;
  };

  const options = Options.map((option) => {

    const firstLetter = option.groupname;

    return {

      firstLetter,
      ...option,
    };
  });
  const renderGroup = (params) => [

    <div style={{ fontWeight: 600, color:'red' }} key={params.id} component="div">


      <Checkbox
        icon={icon}
        // style={{ marginLeft: '-30px' }}
        checkedIcon={checkedIcon}
        checked={(defaultValue.filter(x => x.groupname == params.group).length > 0) ? true : false}
        onChange={e => { selectedItem(params.group, e.target.checked, optionName, optionValue) }}
      />
      <h4 className="dropdownText">
        {params.group}</h4>
    </div>,
    params.children,
  ];
  return <React.Fragment>
    <Controller
      render={({ onChange }) => (
        <Autocomplete
          multiple={multiple != undefined ? multiple : true}
          {...inputprops}
          PopperComponent={PopperMy}
          id={id}
          ListboxComponent={ListboxComponent}

          options={options}
          //hide for event 23
          // options={options.sort((a, b) =>
          //   -b.groupname.localeCompare(a.groupname) || -b.childname.localeCompare(a.childname))
          //   // options.sort((a, b) =>
          //   //   -b.groupname.localeCompare(a.groupname))
          // }

          value={value.sort((a, b) =>
            -b.groupname.localeCompare(a.groupname) || -b.childname.localeCompare(a.childname))
          }


          getOptionSelected={(options, value) => {
            if (options.childname === value.childname && options.groupname === value.groupname) { options.status = true; return true; } else { options.status = false; return false; }
          }}


          disableCloseOnSelect

          disablePortal={true}
          limitTags={limitTags ? limitTags : 3}
          renderGroup={renderGroup}
          disableClearable={true}
          groupBy={(option) => option.firstLetter}
          getOptionLabel={(option) => (isGroup && isGroup == 0) ? option.childname : option.groupname + "-" + option.childname}
          //value={value}
          defaultValue={defaultValue}
          renderOption={(option) => (
            <React.Fragment>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 3 }}
                checked={option.status}
              />
              <span className="dropdownText1">
                {option.childname}</span>
            </React.Fragment>
          )}
          renderInput={(params) => (
            <TextField {...params} inputRef={inputprops.register} helperText={inputprops.error ? inputprops.errMessage : ''} error={inputprops.error} variant="outlined" autoComplete='off' label={label} placeholder={placeholder}

            />
          )}
          onChange={(e, data) => {
            inputprops.handleChangeSelect(input, data);
            onChange(data);
            console.log('hi');
          }}
        />
      )}
      onChange={([, data]) =>
        data
      }
      defaultValue={defaultValue}
      value={value}
      name={name}
      control={control}
    />
  </React.Fragment>
}

export const FormAutocompleteGroupingCountries = ({ selectedItem, optionName, optionValue, value, name, limitTags, id, input, Options, label, multiple, control, placeholder, defaultValue, isGroup, ...inputprops }) => {
  
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const styles = (theme) => ({
    popper: {
      maxWidth: "fit-content"
    }
  });
  const PopperMy = function (props) {
    return <div {...props} style={styles.popper} placement="bottom-start" />;
  };

  const options = Options.map((option) => {

    
    const firstLetter = option.groupname;

    return {

      firstLetter,
      ...option,
    };
  });
  const renderGroup = (params) => [

    <div style={{ fontWeight: 600, color:'red' }} key={params.id} component="div">


      <Checkbox
        icon={icon}
       // style={{ marginLeft: '-30px' }}
        checkedIcon={checkedIcon}
        checked={(defaultValue.filter(x => x.groupname == params.group).length > 0) ? true : false}
        onChange={e => { selectedItem(params.group, e.target.checked, optionName, optionValue) }}
      />
      <h4 className="dropdownText">
        {params.group}</h4>
    </div>,
    params.children,
  ];
  return <React.Fragment>
    <Controller
      render={({ onChange }) => (
        <Autocomplete
          multiple={multiple != undefined ? multiple : true}
          {...inputprops}
          PopperComponent={PopperMy}
          id={id}
          ListboxComponent={ListboxComponent}

          options={options}

        //  inputValue={inputValue}
          
          // options={options.sort((a, b) =>
          //   -b.groupname.localeCompare(a.groupname) || -b.childname.localeCompare(a.childname))
          //   // options.sort((a, b) =>
          //   //   -b.groupname.localeCompare(a.groupname))
          // }

          value={value.sort((a, b) =>
            -b.groupname.localeCompare(a.groupname) || -b.childname.localeCompare(a.childname))
          }


          getOptionSelected={(options, value) => {
            if (options.childname === value.childname && options.groupname === value.groupname) { options.status = true; return true; } else { options.status = false; return false; }
          }}


          disableCloseOnSelect

          disablePortal={true}
          limitTags={limitTags ? limitTags : 3}
          renderGroup={renderGroup}
          disableClearable={true}
          groupBy={(option) => option.firstLetter}
          getOptionLabel={(option) => (isGroup && isGroup == 0) ? option.childname : option.groupname + "-" + option.childname}
          //value={value}
          defaultValue={defaultValue}
          renderOption={(option) => (
            <React.Fragment>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 3 }}
                checked={option.status}
              />
              <span className="dropdownText1">
                {option.childname}</span>
            </React.Fragment>
          )}
          renderInput={(params) => (
            <TextField {...params} inputRef={inputprops.register} helperText={inputprops.error ? inputprops.errMessage : ''} error={inputprops.error} variant="outlined" autoComplete='on'  label={label} placeholder={placeholder}

            />
          )}
          onChange={(e, data) => {
            
            inputprops.handleChangeSelect(input, data);
            onChange(data);
           
          }}
        />
      )}
      onChange={([, data]) =>
        data
      }
      defaultValue={defaultValue}
      value={value}
      name={name}
      control={control}
    />
  </React.Fragment>
}


export const SingleAutocompleteGrouping = ({ name, limitTags, id, input, Options, label, multiple, control, placeholder, defaultValue, ...inputprops }) => {
  console.log("isMulti:", name, multiple);
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const options = Options.map((option) => {

    const firstLetter = option.groupname;
    return {

      firstLetter,
      ...option,
    };
  });
  const renderGroup = (params) => [
    <div style={{ fontWeight: 600 }} key={params.id} component="div">
      {/* <Checkbox
    icon={icon}
    checkedIcon={checkedIcon}
    //checked= {selected}
    /> */}
      {params.group}
    </div>,
    params.children,
  ];
  return <React.Fragment>
    <Controller
      render={({ onChange }) => (
        <Autocomplete
          multiple={multiple != undefined ? multiple : true}
          id={id}
          ListboxComponent={ListboxComponent}

          // options={(input == 'DistributorProfileValue') ? options.sort((a, b) =>
          //   -b.groupname.localeCompare(a.groupname) || -b.childname.localeCompare(a.childname)) :
          //   options.sort((a, b) =>
          //     -b.groupname.localeCompare(a.groupname))
          // }

          options={options.sort((a, b) =>
            -b.groupname.localeCompare(a.groupname) || -b.childname.localeCompare(a.childname))
          }

          //options={options.sort((a, b) => -b.groupname.localeCompare(a.groupname))}

          getOptionSelected={(options, value) => options.childname === value.childname && options.groupname === value.groupname}
          disableCloseOnSelect
          limitTags={3}
          renderGroup={renderGroup}
          disableClearable={true}
          {...inputprops}
          groupBy={(option) => option.firstLetter}
          getOptionLabel={(option) => option.groupname + "-" + option.childname}
          renderOption={(option, { selected }) => (
            <React.Fragment>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 3 }}
                checked={selected}
              />
              {option.childname}
            </React.Fragment>
          )}
          renderInput={(params) => (
            <TextField {...params} inputRef={inputprops.register} helperText={inputprops.error ? inputprops.errMessage : ''} error={inputprops.error} variant="outlined" autoComplete='off' label={label} placeholder={placeholder}

            />
          )}
          onChange={(e, data) => {
            inputprops.handleChangeSelect(input, data);
            onChange(data);
            console.log('hi');
          }}
        />
      )}
      onChange={([, data]) =>
        data
      }
      defaultValue={defaultValue}
      name={name}
      control={control}
    />
  </React.Fragment>
}

export const SingleAutocomplete = ({ errMessage, name, input, error, label, placeholder, register, defaultValue, control, handleChangeSelect, ...inputprops }) => {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;


  //const { address, street, country, postalCode, websiteUrl, product_categories, isOEM, switchphone } = props.values;

  return <React.Fragment>
    <Controller

      render={({ onChange }) => (
        <Autocomplete
          //ref={inputprops.register}  
          {...inputprops}
          getOptionLabel={(option) => option.name}
          blurOnSelect
          renderInput={(params) => (
            <TextField inputRef={register}  {...params} helperText={error ? errMessage : ''} error={error} variant="outlined" autoComplete='off' label={label} placeholder={placeholder} />

          )}
          onChange={(e, data) => {
            handleChangeSelect(input, data);
            onChange(data)
          }}
        />
      )}

      onChange={([, data]) =>
        data
      }
      defaultValue={defaultValue}
      name={name}
      control={control}

    />

  </React.Fragment>
}

export const FormRadio = (props) => {
  const { name, label, options } = props;
  return (
    <React.Fragment>
      <Controller
        as={
          <FormControl component="fieldset">
            <FormLabel component="legend">{label}</FormLabel>
            <RadioGroup name={name} {...props}>
              {options.map((item) => (
                <FormControlLabel
                  style={{ color: 'darkgrey' }}
                  key={item.value}
                  value={item.value}
                  control={<Radio />}
                  label={item.label}
                />
              ))}
            </RadioGroup>
          </FormControl>}
        name={name}
        control={props.control}
        defaultValue=""
        label={label}
        {...props}
      />
    </React.Fragment>
  );
}
export const NewFieldAutocomplete = ({ errMessage, name, error, label, placeholder, defaultValue, control, options, handleChangeSelect, input, ...inputprops }) => {
  const filter = createFilterOptions();
  return <React.Fragment>
    <Controller
      render={({ onChange }) => (
        <Autocomplete
          // value={defaultValue}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);

            // Suggest the creation of a new value
            if (params.inputValue !== '') {
              filtered.push({
                inputValue: params.inputValue,
                name: `Add "${params.inputValue}"`,
              });
            }

            return filtered;
          }}
          selectOnFocus
          clearOnBlur
          blurOnSelect
          handleHomeEndKeys
          {...inputprops}
          options={options}

          // getOptionLabel={(options) => options.name}
          getOptionLabel={(option) => {
            //Value selected with enter, right from the input
            if (typeof option === 'string') {
              return option;
            }
            //Add "xxx" option created dynamically
            if (option.inputValue) {
              return option.inputValue;
            }
            //Regular option
            return option.name;
          }}
          renderOption={(option) => option.name}
          style={{ width: '100%' }}
          freeSolo
          renderInput={(params) => (
            <TextField {...params} helperText={error ? errMessage : ''} error={error} label={label} variant="outlined" />

          )}
          onChange={(event, newValue) => {
            if (typeof newValue === 'string') {

              handleChangeSelect(input, { name: newValue });
              onChange({ name: newValue })
            } else if (newValue && newValue.inputValue) {

              handleChangeSelect(input, { name: newValue.inputValue });
              onChange({ name: newValue.inputValue })
            } else {
              handleChangeSelect(input, newValue)
              onChange(newValue)
            }
          }}
        />
      )}
      onChange={([, data]) => data}
      defaultValue={defaultValue}
      name={name}
      control={control}
    />
  </React.Fragment>
}

//search autoComplete without filter

export const SelectAutocomplete = ({ errMessage, name, error, label, placeholder, defaultValue, control, options, handleChangeSelect, input, ...inputprops }) => {
  //const filter = createFilterOptions();
  return <React.Fragment>
    <Controller

      render={({ onChange }) => (
        <Autocomplete required
          options={options}
          {...inputprops}
          blurOnSelect
          getOptionLabel={option => option.name}
          renderOption={option => (
            <span>
              {option.name}
            </span>
          )}
          renderInput={params => (
            <TextField
              {...params}
              label={label}
              variant="outlined"
              helperText={error ? errMessage : ''} error={error}
            />
          )}
          onChange={(e, data) => {
            handleChangeSelect(input, data);
            onChange(data);
          }}
        />
      )}

      onChange={([, data]) => data}
      defaultValue={defaultValue}
      name={name}
      control={control}
    />

  </React.Fragment>
}


//Phone number

export const PhoneInput1 = ({ name, id, mobile, register, country_code, error, label, placeholder, defaultValue, control, options, handleChangeSelect, input, Class, ...inputprops }) => {

  const [phone, setPhone] = useState(mobile);

  const handleOnChange = value => {
    setPhone(value);
    handleChangeSelect('mobile', phone);
    console.log("mob", mobile);
    console.log('cc', country_code)
  }

  useEffect(() => {
    handleChangeSelect('mobile', phone);
  }, [phone])
  return <>
    <MuiPhoneNumber
      inputClass={Class}
      name={name}
      id={id}
      class={Class}
      error={error}
      label={label}
      defaultCountry={country_code.toLowerCase()}
      value={phone}
      ref={register}
      onChange={handleOnChange}
    />
  </>
  // <PhoneInput
  // country={country_code.toLowerCase()}
  // value={phone}
  // onChange={handleOnChange}
  // />

}


export const PhoneInput2 = ({ name, id, mobile, isValid, register, defaultErrorMessage, country_code, error, label, placeholder, defaultValue, control, options, handleChangeSelect, input, ...inputprops }) => {

  const [phone, setPhone] = useState(mobile);

  // const handleOnChange = value => {
  //   setPhone(value);
  //   handleChangeSelect('mobile', phone);
  //   console.log("mob",mobile);
  //   console.log('cc', country_code)
  // }

  const handleOnChange = (value, data, event, formattedValue, country) => {
    handleChangeSelect(name, [value, formattedValue, value.slice(data.dialCode?.length)]);
    console.log('country', data);

  }

  return <>
    <ReactPhoneInput
      name={name}
      id={id}
      placeholder={placeholder}
      error={error}
      label={label}
      country={country_code?.toLowerCase()}
      value={phone}
      register={register}
      ref={register}
      onChange={handleOnChange}
      isValid={isValid}
      defaultErrorMessage={defaultErrorMessage}
      enableTerritories={true}
      {...inputprops}
    // isValid={(value, country) => {

    //     if(country && value){
    //     console.log(value);
    //     console.log(country);
    //     let formatted = country.format.replace(/[&\/\\#, +()$~%'":*?<>{}-]/g, '');;
    //     console.log('from', formatted);
    //     if(value.length === formatted.length)
    //       return true;
    //     else
    //       return 'Enter valid mobile number! ';
    //     }
    // }}
    // country='us'
    // regions={['north-america', 'carribean']}
    />
  </>
  // <PhoneInput
  // country={country_code.toLowerCase()}
  // value={phone}
  // onChange={handleOnChange}
  // />

}


export const OtpInput = (props) => {
  const [OTP, setOTP] = useState("");
  let fieldSize = 6;

  useEffect(() => {
    props.handleChangeSelect('verified', 0);
  }, [OTP]);

  const renderTime = remainingTime => {
    return <span>{remainingTime} seconds remaining</span>;
  };

  const renderButton = buttonProps => {
    return <button {...buttonProps} style={{ backgroundColor: buttonProps.remainingTime ? 'grey' : '#e87d2e' }}>Resend Code</button>;
  };

  useEffect(() => {
    if (OTP.length == fieldSize) {
      console.log("OTPS:", OTP);
      props.matchOTP(OTP);
    }

  }, [OTP])

  return <>
    <OTPInput
      value={OTP}
      onChange={setOTP}
      autoFocus
      OTPLength={fieldSize}
      otpType="number"
      disabled={false}
    />
    <ResendOTP maxTime={props.maxTime} onResendClick={() => props.reSendOTP()} renderButton={renderButton} />
  </>
}



export const QuillEditor = (props) => {

  useEffect(() => {
  }, [])

  return <>
    <ReactQuill
      theme="snow"
      placeholder={props.placeholder}
      onChange={props.handleChange}
      defaultValue={props.value}
      ref={props.register}
      name={props.name}
      id={props.id}
      modules={{
        toolbar: [
          ["bold", "italic"],
          ["link", "blockquote", "code", "image"],
          [
            {
              list: "ordered"
            },
            {
              list: "bullet"
            }
          ]
        ]
      }}
    />
  </>
}

export const AddFieldWithPopUpAutocomplete = ({ errMessage, name, register, error, label, placeholder, defaultValue, control, options, handleChangeSelect, input, opneAdd, ...inputprops }) => {
  const filter = createFilterOptions();
  return <React.Fragment>
    <Controller
      render={({ onChange }) => (
        <Autocomplete
          value={defaultValue}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);

            // Suggest the creation of a new value
            if (params.inputValue !== '' && filtered.filter(x => x.name?.toLowerCase() == params.inputValue?.toLowerCase()).length == 0) {
              filtered.push({
                inputValue: params.inputValue,
                name: `Add "${params.inputValue}"`,
                documentpath: "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"
              });
            }
            console.log('filtered', filtered);
            console.log('params', params);
            return filtered;
          }}
          selectOnFocus
          clearOnBlur
          // blurOnSelect
          disableCloseOnSelect={true}
          handleHomeEndKeys
          ListboxComponent={ListboxComponent}
          {...inputprops}
          options={options}
          disableClearable={true}
          // getOptionLabel={(options) => options.name}
          getOptionLabel={(option) => {
            //Value selected with enter, right from the input
            if (typeof option === 'string') {
              return option;
            }
            //Add "xxx" option created dynamically
            if (option.inputValue) {
              return option.inputValue;
            }
            //Regular option
            return option.name;
          }}
          // renderOption={(option) => option.name}
          style={{ width: '100%' }}
          freeSolo
          renderInput={(params) => (
            <TextField {...params} helperText={error ? errMessage : ''} error={error} variant="outlined" autoComplete='off' label={label} placeholder={placeholder} />

          )}
          onChange={(event, newValue) => {
            if (typeof newValue === 'string') {
              console.log('if', newValue)
              setTimeout(() => {
                opneAdd()
                handleChangeSelect(input, { name: newValue });
                onChange({ name: newValue })
              });
            } else if (newValue && newValue.inputValue) {
              console.log('else if', newValue);
              opneAdd(newValue.inputValue)
              //handleChangeSelect(input,{name: newValue.inputValue});
              onChange({ name: newValue.inputValue })
            } else {
              console.log('else', newValue)
              handleChangeSelect(input, newValue)
              onChange(newValue)
            }
          }}
        />
      )}
      onChange={([, data]) => data}
      defaultValue={defaultValue}
      name={name}
      control={control}
      register={register}
    />
  </React.Fragment>
}
export const RatingInput = (props) => {
  const { value } = props;
  return <>
    <Rating name="size-medium" defaultValue={0} size="small" value={value ? value : 0} />
  </>

}


////////////////For ListboxComponent

const LISTBOX_PADDING = 8; // px

function renderRow(props) {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING,
    },
  });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
  const { children, id, ...other } = props;
  const itemData = React.Children.toArray(children);
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
  console.log("itemdata:", props);
  let itemCount;
  // if (id == 'Brands-popup')
  //   itemCount = itemData.length > 100 ? 100 : itemData.length;//itemData.length;
  // else
  itemCount = itemData.length

  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child) => {
    if (React.isValidElement(child) && child.type === ListSubheader) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

export const Asynchronous = ({ errMessage, error, label, placeholderopen, options, clearOption, onChangeHandle, name, control, handleChangeSelect, input, open, defaultValue, ...inputprops }) => {
  const loading = open && options.length === 0;
  const filter = createFilterOptions();
  return <React.Fragment>
    <Controller
      render={({ onChange }) => (
        <Autocomplete
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            if (params.inputValue !== '') {
              filtered.push({
                inputValue: params.inputValue,
                name: `Add "${params.inputValue}"`,
              });
            }

            return filtered;
          }}
          getOptionLabel={(option) => {
            //Value selected with enter, right from the input
            if (typeof option === 'string') {
              return option;
            }
            //Add "xxx" option created dynamically
            if (option.inputValue) {
              return option.inputValue;
            }
            //Regular option
            return option.name;
          }}
          id="asynchronous-demo"
          style={{ width: 300 }}
          open={open}
          onOpen={clearOption}
          onClose={clearOption}
          getOptionSelected={(option, value) =>
            option.name === value.name
          }

          options={options}
          renderOption={(option) => option.name}
          loading={loading}
          renderInput={params => (
            <TextField
              {...params}
              label="Asynchronous"
              variant="outlined"
              onChange={ev => {
                // dont fire API if the user delete or not entered anything
                if (ev.target.value !== "" || ev.target.value !== null) {
                  onChangeHandle(ev.target.value);
                }
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                )
              }}
            />
          )}

          onChange={(event, newValue) => {
            if (typeof newValue === 'string') {

              handleChangeSelect(input, { name: newValue });
              onChange({ name: newValue })
            } else if (newValue && newValue.inputValue) {

              handleChangeSelect(input, { name: newValue.inputValue });
              onChange({ name: newValue.inputValue })
            } else {
              handleChangeSelect(input, newValue)
              onChange(newValue)
            }
          }}
        />
      )}
      onChange={([, data]) => data}
      defaultValue={defaultValue}
      name={name}
      control={control}
    />
  </React.Fragment>
}

export const AsyncNewFieldAutocomplete = ({ errMessage, name, error, label, placeholder, defaultValue, control, options, handleChangeSelect, input, onChangeHandle, open, setOpen,
  channelName, handleKeyDown,
  ...inputprops }) => {
  const loading = open && options.length === 0;
  const filter = createFilterOptions();
  return <React.Fragment>
    <Controller
      render={({ onChange }) => (
        <Autocomplete
          filterOptions={(options, params) => {
            options = _.uniqBy(options, 'name');
            // options=options.filter(x=> !channelName.includes(x.name));
            console.log('options', options);
            const filtered = filter(options, params);
            if (params.inputValue !== '' && filtered.length == 0) {
              filtered.push({
                inputValue: params.inputValue,
                name: <><button className="btn btn-primary btn-sm p-2 mr-2"> Add </button> {params.inputValue}</>,
              });
            }
            return filtered;
          }}
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          selectOnFocus
          clearOnBlur
          blurOnSelect
          handleHomeEndKeys
          {...inputprops}
          ListboxComponent={ListboxComponent}
          options={options}
          getOptionLabel={(option) => {
            //Value selected with enter, right from the input
            if (typeof option === 'string') {
              return option;
            }
            //Add "xxx" option created dynamically
            if (option.inputValue) {
              return option.inputValue;
            }
            //Regular option
            return option.name;
          }}
          renderOption={(option) => option.name}
          style={{ width: '100%' }}
          freeSolo
          renderInput={(params) => {

            return (
              <TextField
                {...params}
                helperText={error ? errMessage : ''}
                error={error}
                label={label}
                variant="outlined"
                onChange={ev => {
                  // dont fire API if the user delete or not entered anything
                  if (ev.target.value !== "" || ev.target.value !== null) {
                    onChangeHandle(ev.target.value);
                  }
                }}
                onKeyDown={handleKeyDown}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  )
                }}
              />
            )

          }}
          onChange={(event, newValue) => {
            if (typeof newValue === 'string') {

              handleChangeSelect(input, { name: newValue });
              onChange({ name: newValue })
            } else if (newValue && newValue.inputValue) {

              handleChangeSelect(input, { name: newValue.inputValue });
              onChange({ name: newValue.inputValue })
            } else {
              handleChangeSelect(input, newValue)
              onChange(newValue)
            }
          }}
        />
      )}
      onChange={([, data]) => data}
      defaultValue={defaultValue}
      name={name}
      control={control}
    />
  </React.Fragment>
}

ListboxComponent.propTypes = {
  children: PropTypes.node,
};