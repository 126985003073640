import React, { useState, useEffect, useRef } from "react";
import { ListItem } from "_components/Listing/ListItem";
import { withStyles } from "@material-ui/core/styles";
import {
    Badge, DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown, UncontrolledTooltip
} from "reactstrap";

import MenuItem from "@material-ui/core/MenuItem";
// import Select from "@material-ui/core/Select";
import InputBase from "@material-ui/core/InputBase";
import { Progress } from "reactstrap";
import { Card, CardBody, Row, Col } from "reactstrap";
import TablePagination from '@material-ui/core/TablePagination';
import { TablePaginationActions } from 'views/Hoc/pagination/PaginationActions';
import { commonService } from '_services/common.service';
import { Spinner } from "views/Hoc/Spinner";
import CountryFlag from "views/Hoc/countryFlag/countryFlag";
import Favourities from 'views/Hoc/Dialog/Favourities'
import { UpgradeModal } from "views/Hoc/Dialog/GlobalDialog";
import { Favorite } from "@material-ui/icons";
import { history } from '../../../_helpers/history';
import { channelAction } from '_actions/channel.action';
import { connect, useSelector } from 'react-redux';
import { ContactListing } from "views/Hoc/contact-list/contactList";
import NotificationAlert from "react-notification-alert";
import axios from 'axios';
import PageNotFound from "../PageNotFound/PageNotFound";
const BootstrapInput = withStyles((theme) => ({
    root: {
        "label + &": {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: "relative",
        backgroundColor: theme.palette.background.paper,
        border: "1px solid #ced4da",
        fontSize: 16,
        width: "100%",
        padding: "5px 26px 5px 12px",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            "-apple-system",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(","),
        "&:focus": {
            borderRadius: 4,
            borderColor: "#80bdff",
            boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
    },
}))(InputBase);

export const EventMeetings = (props) => {
    const { filterValueState, activeTab, search, channelFlag, toBreadCrumb,eventId,isSeller } = props;
    let [page, setPage] = useState(0);
    let [rowsPerPage, setRowsPerPage] = useState(40);
    let [total, setTotal] = useState(0);
    let [isLoading, setLoading] = useState(true);
    let [applyBlur, setApplyBlur] = useState(false);
    let user = JSON.parse(localStorage.getItem('CHuser'));
    let [data, setData] = useState([]);
    let [isUpgradePopup, setUpgradePopup] = useState(false);
    let [modalPopValue, setModalPopValue] = useState({ cid: null, JctId: null });
    let [sort, setSort] = useState('asc');
    const notificationAlert = useRef(null);
    let planId = user?.planId;
    let fromIndex = 0;
    const redux_data = useSelector(state => state.channelListing);
    let [totalTabCount, setTotalTabCount] = useState(redux_data?.tab_count || null);
    useEffect(() => {

        if (redux_data.sorting) {
            if (sort !== redux_data.sorting) {
                // setSort(redux_data.sorting);
                sort = redux_data.sorting;
                setSort(sort);
                callChannelList();
            }
        }
    }, [redux_data.sorting]);
    useEffect(() => {
        //if (activeTab)
            callChannelList();
    }, []);

    console.log("raj:", activeTab);
    const callChannelList = () => {
        page = 0;
        setPage(page);
        getChannelList();
    }
    const getChannelList = async () => {
        // applyBlur = user.planId === '1' && (activeTab === '9' || activeTab === '10') && (user.channelTypeId === '2');// || user.channelTypeId === '3');
        // setApplyBlur(applyBlur);
        //console.log('search.name:',search,search.name);
        let postData = {
            filter: {},//getFilterData(),
            order: sort ? sort : 'asc',
            search: search ? search.name : null,
            offset: page * rowsPerPage,
            // offset:1,
            limit: rowsPerPage,
            isSeller:isSeller,
            user: {
                contactId: user?.contactId,
                channelId: user?.channelId,
                channelTypeId: user?.channelTypeId,
                userId: user?.userId,
                eventId:eventId
            }
        }
        setLoading(true);
        let res = [];

        res = await commonService.postService(postData, 'channel/getEventMeeting/1' + '/' + eventId).catch(err => setLoading(false));
        
        if (res) {

            data = res.data.results;
            setData(data);
            total = res.data.total;
            setTotal(total);
            props.channelRedux(res.data.master, 'CHANNELS');
            // if (redux_data?.tab_count)
            //     setReduxCount();
            setLoading(false);
        } else {
            data = [];
            setData(data);
            total = 0;
            setTotal(total);
            setLoading(false);
        }
    }

    const getCount = async () => {
        let res = await commonService.getServices('/channel/getTapCount/' + user.contactId);
        totalTabCount = res.data;
        setTotalTabCount(totalTabCount);
        let counts = {
            confirm_count: totalTabCount.counts?.confirm_count,
            outbound_count: totalTabCount.counts.outbound_count,
            inbound_count: totalTabCount.counts.inbound_count,
            request_count: totalTabCount.counts.request_count,
            savelater_count: totalTabCount.counts.savelater_count,
            search_count: totalTabCount.counts.search_count,
            suggestion_vendor_count: totalTabCount.counts.suggestion_vendor_count,
            suggestion_dist_count: totalTabCount.counts.suggestion_dist_count,
            suggestion_retail_count: totalTabCount.counts.suggestion_retail_count,
            suggestion_reseller_count: totalTabCount.counts.suggestion_reseller_count,
            product_innovation_count: totalTabCount.product_innovation_count,
            product_others_count: totalTabCount.product_others_count,
            all_product_count: totalTabCount.all_product_count,
            product_suggestion_count: totalTabCount.product_suggestion_count,
            nonmember_count: totalTabCount.nonmember_count,
            favorite_count: totalTabCount.favorite_count,
            dismiss_count: totalTabCount.dismiss_count
        }
        props.channelRedux(counts, 'TABCOUNT');
    }

    const setReduxCount = async () => {
        getCount();
        let count = redux_data?.tab_count;
        console.log('activeTab', activeTab);
        console.log('total', total);
        switch (parseInt(activeTab)) {
            case 1:
                // if(count?.confirm_count)
                count.confirm_count = total;
                break;
            case 2:
                // if(count?.outbound_count)
                count.outbound_count = total;
                break;
            case 3:
                // if(count?.inbound_count)
                count.inbound_count = total;
                break;
            case 4:
                // if(count?.request_count)
                count.request_count = total;
                break;
            case 5:
                // if(count?.savelater_count)
                count.savelater_count = total;
                break;
            case 8:
                // if(count?.suggestion_vendor_count || count?.suggestion_vendor_count===0)
                count.suggestion_vendor_count = total;
                break;
            case 9:
                // if(count?.suggestion_dist_count)
                count.suggestion_dist_count = total;
                break;
            case 10:
                // if(count?.suggestion_retail_count)
                count.suggestion_retail_count = total;
                break;
            case 15:
                count.nonmember_count = total;

            default:
                break;
        }
        props.channelRedux(count, 'TABCOUNT');
    }

    const getFilterData = () => {
        let filter = {
            specialityId: null,
            brandId: null,
            countryId: null,
            locationId: null,
            retailerprofileId: null,
            resellerprofileId: null,
            productCategoryId: null,
            turnOver: null,
            connectionStatusId: null,
            typeId: null
        };
        if (filterValueState.Speciality && filterValueState.Speciality.length > 0) {
            filter.specialityId = filterValueState.Speciality.map(x => parseInt(x.id));
        }
        if (filterValueState.Brand && filterValueState.Brand.length > 0) {
            filter.brandId = filterValueState.Brand.map(x => parseInt(x.id));
        }
        if (filterValueState.Company_Origin && filterValueState.Company_Origin.length > 0) {
            filter.countryId = filterValueState.Company_Origin.map(x => parseInt(x.id));
        }
        if (filterValueState.Target_Location && filterValueState.Target_Location.length > 0) {
            filter.locationId = filterValueState.Target_Location.map(x => parseInt(x.id));
        }
        if (filterValueState.Retailer_Profile && filterValueState.Retailer_Profile.length > 0) {
            filter.retailerprofileId = filterValueState.Retailer_Profile.map(x => parseInt(x.id));
        }
        if (filterValueState.Reseller_Profile && filterValueState.Reseller_Profile.length > 0) {
            filter.resellerprofileId = filterValueState.Reseller_Profile.map(x => parseInt(x.id));
        }
        if (filterValueState.productCategory && filterValueState.productCategory.length > 0) {
            filter.productCategoryId = filterValueState.productCategory.map(x => parseInt(x.id));
        }
        if (filterValueState.Annual_Turnover) {
            filter.turnOver = filterValueState.Annual_Turnover.id;
        }
        if (filterValueState.connectionStatusId) {
            filter.connectionStatusId = filterValueState.connectionStatusId.id;
        }
        if (filterValueState.typeId) {
            filter.typeId = filterValueState.typeId.id;
        }
        return filter;
    }

    const handleChangePage = (event, newPage) => {
        page = newPage;
        setPage(page);
        getChannelList();
    };

    const updateRating = (data) =>{
        callChannelList();
    }

    const handleChangeRowsPerPage = (event) => {
        rowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(rowsPerPage);
        callChannelList();
    };
    const openUpgradePopup = (event) => {
        modalPopValue.cid = event.target.id;
        modalPopValue.JctId = event.target.parentNode.id;
        setModalPopValue({ ...modalPopValue });
        setUpgradePopup(true);
    }
    const closeUpgradePopup = () => {
        setUpgradePopup(false);
    }
    const notify = (type, message) => {
        let options = {
            place: "tr",
            message: (
                <div className="alert-text">
                    <span className="alert-title" data-notify="title">
                        {/* {" "}
                {type} */}
                    </span>
                    <span data-notify="message">
                        {message}
                    </span>
                </div>
            ),
            type: type,
            // icon: "ni ni-bell-55",
            autoDismiss: 5
        };
        notificationAlert.current.notificationAlert(options);
    };

    const downloadBrand = async () => {
         var fileDownload = require('js-file-download');
        setLoading(true);
        // let sortby = [];
        // if (sort_by.length) {
        //   sort_by.map(x => {
        //     sortby.push(x.id);
        //   })
        // } else {
        //   sortby = ['brandName'];
        // }
        axios({
          url: 'channel/downloadMeetingContact/'+eventId,
          method: 'GET',
        //   params: { data: data },
          responseType: 'blob', // Important
        }).then((response) => {
          fileDownload(response.data, `MeetingContact.xlsx`);
          setLoading(false);
        }).catch((err)=>{
            setLoading(false);
            notify('warning', 'Please rate all meeting contacts to download');
        });
    
      }
    

    return (


<Card>
    
    <Row><Col>             <button style={{float:"right"}} class="btn btn-primary btn-sm p-2 m-3" type="button" onClick={() => downloadBrand()}><i class="fa fa-download" aria-hidden="true"></i> Download Meetings</button>
</Col></Row>
    <Row><Col>
         <div>
            <NotificationAlert ref={notificationAlert} />
            <UpgradeModal open={isUpgradePopup} value={modalPopValue} handleSave={closeUpgradePopup} handleClose={closeUpgradePopup} />
            {(data.length <= 0 && !isLoading) && <div className="no-records ">
                {/* <span>No Records</span> */}
                {/* <div className="no-img text-center">
                    <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} className="img-fluid" alt="ch-logo" src={require("../../../assets/img/medialibrary/no-data.png")} /></div> */}
          <PageNotFound></PageNotFound>
            </div>
            }
            {isLoading ? <Spinner /> : <>
                {data.length > 0 && data.map((item, index) => {
                    let contactList = [];

                    if ( fromIndex < data.length) {
                        contactList = data?.slice(fromIndex, ((4 * index) + 4)); // clone array          
                        fromIndex = ((4 * index) + 4);
                        console.log("fromIndex:", contactList);
                    }


                    let logo = "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg";
                    //let isConfidential = (((parseInt(data?.isconfidential) !== null && parseInt(data?.isconfidential) === 0) || (applyBlur && parseInt(data?.isconfidential) !==1) || (parseInt(data?.isconfidential) === null && data?.status === 'NonSuggestion' && user.planId==='1'))  && user.channelTypeId !== '4'&& parseInt(data?.channeltypeid) !==2)


                    //  let isConfidential = ((item.isconfidential === 1 && item.isconfidential !== null && item.isconfidential !==0 ) || (item.status === 'NonSuggestion' && user.planId==='1') && user.channelTypeId !== '4'&& parseInt(data?.channeltypeid) !==2);

                    // let isConfidential = ((item.isconfidential === 1 && item.isconfidential !== null && item.isconfidential !==0 ) || (item.status === 'NonSuggestion' && user.planId==='1') && user.channelTypeId !== '4'&& parseInt(data?.channeltypeid) !==2);


                    //let isConfidential = (((item.isconfidential === 1 ) || (item.status === 'NonSuggestion' && user.planId==='1') && user.channelTypeId !== '4'&& parseInt(data?.channeltypeid) !==2))?false:true;

                    let isConfidential = (user.planId == 1 && user.channelTypeId == 2 && item.isconfidential == 1) ? false : true;


                    // if(activeTab == 2){
                    //   isConfidential = true;
                    // }
                    if (contactList.length > 0) {
                        return <Row className="justify-content-center">
                            <Col className="card-wrapper ct-example" lg="12" md="12" sm="12" >
                                <ContactListing data={contactList}  title='' channelTypeId={3} totalTabCount={redux_data?.tab_count} channelId={39} mynetwork={true} getChannelContact={getChannelList} notify={notify} updateRating={updateRating}/>
                            </Col>
                        </Row>
                    }
                })}
                {total > 10 &&
                    <TablePagination
                        component="div"
                        count={total}
                        page={page}
                        onChangePage={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        rowsPerPageOptions={[10, 20, 30, 40]}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                    />
                }
            </>}
        </div>
            </Col></Row></Card>
        
        
        

    );
};
function mapState(state) {
    const general_data = state.channelListing;
    return general_data;
}

const actionCreators = {
    channelRedux: channelAction.channelRedux
};

export default connect(mapState, actionCreators)(EventMeetings);