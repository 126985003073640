import React, { useState, useEffect } from "react";
import { Col, Container, Row, Card, CardBody } from "reactstrap";
import { yupResolver } from '@hookform/resolvers/yup';
import { FormTextArea, FormCheckBox, FormAutocomplete, FormInput } from "_components/FormElements/FormInput";
import { useForm } from "react-hook-form";
import { guestSupportSchema } from "./validation"
import Title from "views/Hoc/Dialog/Title";
import GuestNavbar from "views/pages/GuestPage/GuestNavbar/GuestNavbar";
import { commonService } from '_services/common.service';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { history } from '_helpers/history';
import { Spinner } from "views/Hoc/Spinner";
import { GuestPopup } from "views/Hoc/Dialog/GlobalDialog";
const HelpSupport = () => {
  const { control, errors, register, watch, handleSubmit } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(guestSupportSchema),
  });
  const [err, setErr] = useState(false);
  let [isLoading, setLoading] = useState(true);
  let [stateList, setStateList] = useState({ typeOfSupportList: [], countryList: [] });
  let [state, setState] = useState({
    firstname: null,
    lastname: null,
    email: null,
    phoneno: null,
    mobileno: null,
    country: null,
    subject: null,
    message: null,
    typeOfSupport: null,
  })
  console.log('watch', watch);
  console.log('error', errors);

  useEffect(() => {
    getTypeOfSupportList();
  }, []);
  const getTypeOfSupportList = async () => {
    setLoading(true);
    let res = await commonService.getServices('master/ContactType').catch(err => setLoading(false));
    let data = res.data;
    stateList.typeOfSupportList = data;
    let cty = await commonService.getServices('common/country').catch(err => setLoading(false));
    if (cty) {
      stateList.countryList = cty.data;
    }
    setStateList({ ...stateList });
    setLoading(false);
  }
  const handleChangeSelect = (input, value) => {
    state[input] = value;
    setState({ ...state });
  }
  const handleChange = (e, input) => {
    console.log(e.target.value);
    console.log('state', state);
    if (input === 'checkbox') {
      state.isChecked = !state.isChecked;
      state.isChecked ? setErr(false) : setErr(true);
    } else {
      state[input] = e.target.value;
    }
    setState({ ...state });
  }

  const save = async () => {
    console.log('state', state);
    if (!state.isChecked) {
      setErr(true);
      return false;
    }
    setLoading(true);
    let data = {
      complainttype: "ContactType",
      channeltype: "GUEST",
      firstname: state.firstname,
      lastname: state.lastname,
      email: state.email,
      phoneno: state.phoneno,
      mobileno: state.mobileno,
      countryid: parseInt(state.country.id),
      subject: state.subject,
      detaildesc: state.message,
      iprurl: "",
      complaintcategoryid: parseInt(state.typeOfSupport.id),
      compalainDocument: []
    }
    let contactData = await commonService.postService(data, 'master/complain').catch(err => setLoading(false));
    if (contactData) {
      ToastsStore.success('Success!...');

      commonService.postService({
        email: data.email,
        countryId: data.countryid,
        phoneno: data.phoneno,
        mobileno: data.mobileno,
        name:data.firstname+" "+data.lastname,
        companyName:"GUEST",
        detaildesc: data.detaildesc,
        country:state.country.name,
        subject: data.subject,
        type_of_support: state.typeOfSupport.name,
        to: 'info@channelhub.net',

      },'mailerservice/Help_Support_Mail').catch(err => setLoading(false));

      setTimeout(() => gotoPage(), 1000);
    } else {
      ToastsStore.warning('Failed to post your complaint');
      setLoading(false);
    }
  }
  const gotoPage = () => {
    history.push('/');
  }
  let [modalPopValue, setModalPopValue] = useState({ message: null, title: null, value: null, popUpId: null, second_message: null });
  const [openPop, setOpenPop] = useState(false);

  const popUpok = (value) => {
    setOpenPop(false);
  }
  const openDialogs = (id) => {

    console.log('id', id)
    if (id == '2') {
      modalPopValue.title = "Privacy Policy";
      modalPopValue.message = "Privacy Policy";
      modalPopValue.popUpId = id;
    }
    if (id == '3') {
      modalPopValue.title = "Terms & Conditions";
      modalPopValue.message = "Terms & Conditions";
      modalPopValue.popUpId = id;
    }

    setModalPopValue({ ...modalPopValue });
    setOpenPop(true);
  }
  const closePopup = () => {
    setOpenPop(false);
  }
  return (
    <>
      <GuestNavbar />
      <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore} />
      <GuestPopup open={openPop} value={modalPopValue} handleSave={popUpok} handleClose={closePopup} />
      <Title className=" col-lg-9 col-sm-6 mt-2  container numcard pad-less " title="Help/Support" desc={[""]} />
      <Container className="mt-4 col-lg-12 col-md-9 col-sm-12   container-fluid" fluid>
        {isLoading ? <Spinner /> : <Card className=" col-lg-12 col-sm-6 mt-2  container numcard pad-less ">
          <CardBody >
            <Row>
              <Col lg="6">
                <FormInput
                  className="comp-name mt-3"
                  id="firstname"
                  name="firstname"
                  label="First Name *"
                  placeholder="First Name"
                  type="text"
                  variant="outlined"
                  value={state.firstname}
                  defaultValue={state.firstname}
                  error={errors.firstname}
                  register={register}
                  value={watch.firstname}
                  onChange={(e) => handleChange(e, 'firstname')}
                />
              </Col>
              <Col lg="6">
                <FormAutocomplete
                  className="comp-name mt-3"
                  id="country"
                  name="country"
                  label="Country *"
                  options={stateList.countryList}
                  placeholder="Select Country"
                  // multiple
                  disableCloseOnSelect
                  blurOnSelect
                  // limitTags={1}
                  control={control}
                  input='country'
                  value={state.country}
                  handleChangeSelect={handleChangeSelect}
                  errMessage={'Please Select Country'}
                  error={errors.country}
                  defaultValue={state.country}
                />
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <FormInput
                  className="comp-name mt-3"
                  id="lastname"
                  name="lastname"
                  label="Last Name *"
                  placeholder="Last Name"
                  type="text"
                  variant="outlined"
                  value={state.lastname}
                  defaultValue={state.lastname}
                  error={errors.lastname}
                  register={register}
                  value={watch.lastname}
                  onChange={(e) => handleChange(e, 'lastname')}
                />
              </Col>
              <Col lg="6">
                <FormInput
                  className="comp-name mt-3"
                  id="phoneno"
                  name="phoneno"
                  label="Phone *"
                  placeholder="phoneno"
                  type="number"
                  variant="outlined"
                  value={state.phoneno}
                  defaultValue={state.phoneno}
                  error={errors.phoneno}
                  register={register}
                  value={watch.phoneno}
                  onChange={(e) => handleChange(e, 'phoneno')}
                />
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <FormInput
                  className="comp-name mt-3"
                  id="email"
                  name="email"
                  label="Email *"
                  placeholder="Email"
                  type="text"
                  variant="outlined"
                  value={state.email}
                  defaultValue={state.email}
                  error={errors.email}
                  register={register}
                  value={watch.email}
                  onChange={(e) => handleChange(e, 'email')}
                />
              </Col>
              <Col lg="6">
                <FormInput
                  className="comp-name mt-3"
                  id="mobileno"
                  name="mobileno"
                  label="Mobile Phone *"
                  placeholder="Mobile Phone"
                  type="number"
                  variant="outlined"
                  value={state.mobileno}
                  defaultValue={state.mobileno}
                  error={errors.mobileno}
                  register={register}
                  value={watch.mobileno}
                  onChange={(e) => handleChange(e, 'mobileno')}
                />
              </Col>
            </Row>
            <Row>
              <Col lg="6" className="pad-less">
                <Row>
                  <Col lg="12">
                    <FormInput
                      className="comp-name mt-2"
                      id="subject"
                      name="subject"
                      label="Subject *"
                      placeholder="Subject"
                      type="text"
                      variant="outlined"
                      value={state.subject}
                      defaultValue={state.subject}
                      error={errors.subject}
                      register={register}
                      value={state.subject}
                      onChange={(e) => handleChange(e, 'subject')}
                    />
                  </Col>
                  <Col lg="12" className="mt-3">
                    <FormAutocomplete
                      id="typeOfSupport"
                      name="typeOfSupport"
                      label="Type of Support *"
                      options={stateList.typeOfSupportList}
                      placeholder="Select Support"
                      // multiple
                      disableCloseOnSelect
                      blurOnSelect
                      // limitTags={1}
                      control={control}
                      input='typeOfSupport'
                      value={state.typeOfSupport}
                      handleChangeSelect={handleChangeSelect}
                      errMessage={'Select Type Of Support'}
                      error={errors.typeOfSupport}
                      defaultValue={state.typeOfSupport}
                    />
                  </Col>
                </Row>

              </Col>
              <Col lg="6" sm="6" className="text-center ">
                <div className=" mt-2 text-center">
                  <FormTextArea
                    className="buytext3"
                    name="message"
                    id="message"
                    rows="3"
                    type="textarea"
                    label=" "
                    placeholder="Message *"
                    register={register}
                    value={watch.message}
                    error={errors.message}
                    onChange={(e) => handleChange(e, 'message')}
                  />
                </div>
              </Col>
            </Row>
            <div>
              <Row>
                <Col lg="1" >
                  <div className="chck2">
                    <FormCheckBox
                      id="txt"
                      type="checkbox"
                      // label="Yes, I have read and accept the general terms of use"
                      label1={{ textAlign: "justify" }}
                      // label={err ? labelErrText : labelText}
                      value={state.isChecked}
                      checked={state.isChecked}
                      register={register}
                      onClick={(e) => handleChange(e, 'checkbox')}
                    />
                  </div>
                </Col>
                <Col lg="5" className="mt-4">
                  <p> <span className={err ? 'red' : null}>Yes, I have read and accept the</span> <a style={{ cursor: 'pointer' }} onClick={() => openDialogs(3)}>general terms of use</a></p>
                  {err && <p style={{ color: 'red' }}>Please accept terms of use</p>}
                </Col>
                <Col lg="6"></Col>
              </Row>
              <div className="text-center mt-4">
                <p>By sending this form, I agree that my information may be processed by ChannelHub for the creation and monitoring of my account and to access online services. I can exercise my rights of access, rectification, deletion and portability by writing to privacy@channel-hub.io with proof of my identity. For more information, see our
                  <strong><span style={{ cursor: 'pointer' }} onClick={() => openDialogs(2)} >Privacy Policy.</span></strong></p>
              </div>

            </div>

            <div className="text-center mt-4">
              <button type="submit" className="btn btn-primary" onClick={handleSubmit(save)}>Submit</button>
              <button type="submit" className="btn btn-warning" onClick={gotoPage}>Cancel</button>
            </div>


          </CardBody >
        </Card>}
      </Container>


    </>




  );
}
export default HelpSupport;