import * as yup from "yup";

const loginschema = yup.object().shape({
  email: yup.string().email("Mail id invalid").required("Enter Email"),
  password: yup.string().required("Enter Password").transform(value => value && value.trim()).min(8, "Password too short"),
});


const loginschema1 = yup.object().shape({
  age: yup.number().max(2, "smaller").required(),
});

export { loginschema, loginschema1 };