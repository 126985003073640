import React from 'react';
import PageNotFound from 'views/pages/PageNotFound/PageNotFound';

export const Fallback = () => {
    return (

        <div id="iner" className="main-content mt-3">
            {/* <div className="no-records ">
                <span>No Records</span>
                <div className="no-img text-center">
                    <img className="img-fluid" alt="ch-logo" src="/warning-icon.jpg" /></div>
                <h1 color='red'>Oops, Something went wrong. Please try again later</h1>
            </div> */}
            <PageNotFound></PageNotFound>
        </div>
    )
}