import React, { useEffect, useState } from 'react';
import { Nav, NavItem, NavLink, Row, Col, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledTooltip, UncontrolledDropdown, } from "reactstrap";
import classnames from "classnames";
import axios from 'axios';
import { Spinner } from "views/Hoc/Spinner";

const Past_Event_Tabs = [{ id: '1', label: "Participants", checked: true, customName: null, hideorsoon: null, url: null },
{ id: '2', label: "My Meeting Contacts", checked: true, customName: null, hideorsoon: null, url: null },
{ id: '3', label: "Keynote Presentation", checked: true, customName: null, hideorsoon: null, url: null },
{ id: '4', label: "Best Product Winner", checked: true, customName: null, hideorsoon: null, url: null },
{ id: '5', label: "Pictures", checked: true, customName: null, hideorsoon: null, url: null }];

const EventTabs = ({ activeTab, changeTab, channelTypeId, eventDetails }) => {

  const [loading, setLoading] = useState(false);
  const [PastTabs, setPastTabs] = useState([]);

  const EventTabFlags = async () => {

    await axios.get('/channelAdmin/EventTabsFlag/' + eventDetails.eventId + '/all').then(res => {
      if (res.data["result"].filter(tab => tab.tabType === "PastEvent").length > 0) {
        let data = res.data["result"].filter(tab => tab.tabType === "PastEvent").sort((a, b) => a.id - b.id);
        setPastTabs(data);
      }
      else
        setPastTabs((tab) => {
          return res.data["master"].filter(tab => tab.tabType === "PastEvent").sort((a, b) => a.id - b.id).map(x => {
            x.checked = true;
            x.customName = null;
            x.hideorsoon = null;
            x.url = null;
            x.template = null;
            return x;
          })
        })
    });
  }

  useEffect(() => {

    (async () => {
      setLoading(true);
      await EventTabFlags();
      setLoading(false);
    })();


  }, [])

  return (
    <>
      {(loading && PastTabs.length > 0) ? <Spinner /> :
        <Nav tabs className="list-tab" id='Event'>
          {/* before event code */}

          {/* <NavItem className="list-adtab" onClick={() => changeTab('0')}>
        <NavLink className={classnames({ active: activeTab == '0' })}>
          <h4>Event Agenda</h4>

        </NavLink>
      </NavItem> */}


          {PastTabs?.map(tab => {
            return <>
              {(tab.hideorsoon === '1' || tab.checked) &&
                <NavItem className="list-adtab" onClick={() => tab.checked ? tab.url != null ? window.open(tab.url, '_blank').focus() : changeTab(tab.id, tab.template) : ""}>
                  <NavLink className={classnames({ active: activeTab == tab.id })}>
                    <h4 id={"tab" + tab.id}>{tab.customName == null ? tab.label : tab.customName}</h4>
                    {(tab.checked == false && tab.hideorsoon === '1') &&
                      <UncontrolledTooltip target={"tab" + tab.id} placement='bottom'> Coming soon </UncontrolledTooltip>}
                  </NavLink>
                </NavItem>
              } </>
          })}





          {/* <NavItem className="list-adtab">
           <a className='nav-link' href='https://www.flickr.com/photos/67672940@N05/albums/with/72177720308306710' target='_blank'><h4>Pictures</h4></a>
       </NavItem> */}








        </Nav>
      }
    </>
  );
};

export default EventTabs;