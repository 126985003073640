
export function subscription(state = {}, action) {

    switch (action.type) {
      case 'PLAN':
      {
        const subscription_plan = action.data;
        return {          
            ...state,subscription_plan:subscription_plan
        };
      }
      case 'AMOUNT':
          {
              const subscription_amt = action.data;      
              return {              
                ...state,subscription_amt:subscription_amt
              };
            }
      case 'SELECT_PLAN':
          {
              const select_plan = action.data;      
              return {                
                ...state,select_plan:select_plan
              };
            }
  
      case 'PAYMENT_AMT':
          {
              const pay_amt = action.data;      
              return {                
                ...state,pay_amt:pay_amt
              };
          }

          case 'COUPON_CODE':
            {
                const couponCode = action.data;      
                return {                
                  ...state,couponCode:couponCode
                };
            }
            case 'SUBS_TANSACTIONID':
              {
                  const subscription_transactionId = action.data;      
                  return {                
                    ...state,subscription_transactionId:subscription_transactionId
                  };
              }       
        
      default:
        return state
    }
  }