
import React, { useEffect, useState } from "react";
import { Container, Row, Col, CardBody, Card, CardImg, Nav, NavItem, NavLink, Button } from "reactstrap";
import GuestNavBar from 'views/pages/GuestPage/GuestNavbar/GuestNavbar';
import PubTitle from "views/Hoc/Dialog/PubTitle";
import { history } from '../../../_helpers/history';
import EventChannelListing from "./EventChannelListing";
// import EventListingFilter from "./EventListingFilter";
import { CardHeader } from "@material-ui/core";
import Vendorbuyerlist from './Vendorbuyerlist';
import EventListing from "./EventListing";
import { getContainerUtilityClass } from "@mui/system";
import axios from 'axios';
import { userData } from "_reducers/userData.reducer";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';



export const MeetingScheduler = ({ activeTab, eventId, eventType, hasPackage, ...props }) => {

    const [allMeet, setAllMeet] = useState(0);
    const [count, setCount] = useState({});
    const userData = JSON.parse(localStorage.getItem('CHuser'));

    useEffect(() => {
        getCount();
    }, [])

    const getCount = async () => {
        const userData = JSON.parse(localStorage.getItem('CHuser'));

        let result = await axios.get('channel/eventRequestCount/' + eventId)
            .then(res => { return res.data.filter(x => (x.senderChannelId == userData.channelId || x.receiverChannelId == userData.channelId)) })
            .catch(err => console.log("getcount:", err));
        setCount({
            mysubmitted: result?.filter(req => (req.status == 3 && req.senderContactId == userData.contactId)).length,
            myincoming: result?.filter(req => (req.status == 3 && req.receiverContactId == userData.contactId)).length,
            myconfirmed: result?.filter(req => (req.status == 4 && (req.senderContactId == userData.contactId || req.receiverContactId == userData.contactId))).length,
            mydeclained: result?.filter(req => (req.status == 5 && (req.senderContactId == userData.contactId || req.receiverContactId == userData.contactId))).length,

            teamsubmitted: result?.filter(req => (req.status == 3 && (req.senderContactId != userData.contactId && req.receiverContactId != userData.contactId))).length,
            teamincoming: result?.filter(req => (req.status == 3 && (req.senderContactId != userData.contactId && req.receiverContactId != userData.contactId))).length,
            teamconfirmed: result?.filter(req => (req.status == 4 && req.receiverContactId != userData.contactId)).length,
            teamdeclained: result?.filter(req => (req.status == 5 && (req.senderContactId != userData.contactId && req.receiverContactId != userData.contactId))).length,

        })

    }

    return (
        <>
            <div id='Favourites' className="main-content">

                <Card>

                    <h3 className="m-3">Your Meetings</h3>

                  
                        <CardBody>
                            <Row className="justify-content-center">
                                <Button style={{ "margin-bottom": "10px" }} className="btn-sin btn btn-primary" variant="contained" onClick={() => setAllMeet(1)}>Make a meeting request/View full list of participants available for meeting
                                </Button>
                            </Row>
                            <br />
                           
                            {/* <Col>

                                <Row className="p-2 justify-content-center">
                                    <Col lg="3">
                                    </Col>

                                    {(eventType == 2)  &&
                                        <Col lg="2" style={{ textAlign: "center" }}>
                                            <Button className="btn-sin btn btn-primary" variant="contained" onClick={() => setAllMeet(2)}>Submitted</Button>
                                        </Col>}

                                    {(eventType == 2) &&
                                        <Col lg="2" style={{ textAlign: "center" }}>
                                            <Button className="btn-sin btn btn-primary" variant="contained" onClick={() => setAllMeet(3)}>Incoming</Button>
                                        </Col>}


                                    <Col lg="2" style={{ textAlign: "center" }}>
                                        <Button className="btn-sin btn btn-primary" variant="contained" onClick={() => setAllMeet(4)}>Confirmed</Button>
                                    </Col>

                                    <Col lg="2" style={{ textAlign: "center" }}>
                                        <Button className="btn-sin btn btn-primary" variant="contained" onClick={() => setAllMeet(5)}>Cancelled/Declined</Button>
                                    </Col>
                                </Row>
                                <Row className="p-4 border-bottom justify-content-center">
                                    <Col xs lg="3">
                                        <h3>My Meetings</h3>
                                    </Col>
                                    {(eventType == 2) &&
                                        <Col xs lg="2" style={{ textAlign: "center" }}>
                                            {count.mysubmitted}
                                        </Col>}

                                    {(eventType == 2) &&
                                        <Col xs lg="2" style={{ textAlign: "center" }}>
                                            {count.myincoming}
                                        </Col>}

                                    <Col lg="2" style={{ textAlign: "center" }}>
                                        {count.myconfirmed}
                                    </Col>
                                    <Col xs lg="2" style={{ textAlign: "center" }}>
                                        {count.mydeclained}
                                    </Col>
                                </Row>
                                <br />
                                <Row className="p-3 justify-content-center">
                                    <Col xs lg="3">
                                        <h3>My Team's Meetings</h3>
                                    </Col>
                                    {(eventType == 2) &&
                                        <Col xs lg="2" style={{ textAlign: "center" }}>
                                            {count.teamsubmitted}
                                        </Col>}

                                    {(eventType == 2) &&
                                        <Col xs lg="2" style={{ textAlign: "center" }}>
                                            {count.teamincoming}
                                        </Col>}

                                    <Col lg="2" style={{ textAlign: "center" }}>
                                        {count.teamconfirmed}
                                    </Col>
                                    <Col xs lg="2" style={{ textAlign: "center" }}>
                                        {count.teamdeclained}
                                    </Col>
                                </Row>

                            </Col> */}
                            <TableContainer className="meeting-table" component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table" >
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="right"><Button className="btn-sin btn btn-primary" variant="contained" onClick={() => setAllMeet(2)}>Submitted</Button></TableCell>
            <TableCell align="right"><Button className="btn-sin btn btn-primary" variant="contained" onClick={() => setAllMeet(3)}>Incoming</Button></TableCell>
            <TableCell align="right"><Button className="btn-sin btn btn-primary" variant="contained" onClick={() => setAllMeet(4)}>Confirmed</Button></TableCell>
            <TableCell align="right"><Button className="btn-sin btn btn-primary" variant="contained" onClick={() => setAllMeet(5)}>Cancelled/Declined</Button></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            <TableRow  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}  >
              <TableCell component="th" scope="row" style={{fontWeight:'bold'}}>My Meetings</TableCell>
              <TableCell align="right">{count.mysubmitted}</TableCell>
              <TableCell align="right">{count.myincoming}</TableCell>
              <TableCell align="right">{count.myconfirmed}</TableCell>
              <TableCell align="right">{count.mydeclained}</TableCell>
            </TableRow>
            <TableRow  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}  >
              <TableCell component="th" scope="row" style={{fontWeight:'bold'}}>My Team's Meetings</TableCell>
              <TableCell align="right">{count.teamsubmitted}</TableCell>
              <TableCell align="right">{count.teamincoming}</TableCell>
              <TableCell align="right">{count.teamconfirmed}</TableCell>
              <TableCell align="right">{count.teamdeclained}</TableCell>
            </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
                        </CardBody>
                </Card>


               



                {allMeet === 1 &&

                    <Card>
                        <CardBody>
                            <Vendorbuyerlist id={props.id} eventId={eventId} getCount={getCount} fromMeeting={true} />
                        </CardBody>
                    </Card>}

                    
                {(eventType == 2) && ((allMeet === 3 && count.myincoming) || (allMeet === 2 && count.mysubmitted) || (allMeet === 5 && count.mydeclained) || (allMeet === 4 && count.myconfirmed)) &&

                    <Row className="justify-content-center">
                        <Col style={{ backgroundColor: "#f8f9fe" }} lg="9">
                            <Card>
                                <CardBody>
                                    <EventListing type={props.id === 'buyers' ? 'buyer' : 'vendor'} eventType={eventType} eventId={eventId} status={allMeet} getCount={getCount} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>}
            </div>
        </>
    );
}

export default MeetingScheduler;