import React from 'react';
import { Nav, NavItem, NavLink, Row, Col, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown, } from "reactstrap";
import classnames from "classnames";

const EventTabs1 = ({ activeTab, changeTab, channelTypeId, eventDetails }) => {

// console.log("eventDetails:",eventDetails)

  return (
    <Nav tabs className="list-tab" id='Event'>
      {/* before event code */}

      {eventDetails.eventStatus == 1 || eventDetails.eventStatus == 2 &&
        <NavItem className="list-adtab" onClick={() => changeTab('1')}>
          <NavLink className={classnames({ active: activeTab == '1' })}>
            <h4>Registration</h4>

          </NavLink>
        </NavItem>}

      {eventDetails.eventStatus == 1 || eventDetails.eventStatus == 2 &&
        <NavItem className="list-adtab" onClick={() => changeTab('4')}>
          <NavLink className={classnames({ active: activeTab == '4' })}>
            <h4>Agenda</h4>

          </NavLink>
        </NavItem>
      }

      {(eventDetails.eventStatus == 1 || eventDetails.eventStatus == 2 && channelTypeId == '2'
   ) &&
        <NavItem className="list-adtab" onClick={() => changeTab('7')}>
          <NavLink className={classnames({ active: activeTab == '7' })}>
            <h4>Meeting Scheduler </h4>

          </NavLink>
        </NavItem>
      }

      {(eventDetails.eventStatus == 1 || eventDetails.eventStatus == 2 && channelTypeId == '2') &&
      <NavItem className="list-adtab" onClick={() => changeTab('8')}>
        <NavLink className={classnames({ active: activeTab == '8' })}>
          <h4>My Schedule</h4>

        </NavLink>
      </NavItem>
       } 


       {eventDetails.eventStatus == 1 || eventDetails.eventStatus == 2 && eventDetails.hasTravel=='1' &&
        <NavItem className="list-adtab" onClick={() => changeTab('2')}>
          <NavLink className={classnames({ active: activeTab == '2' })}>
            <h4>Travel</h4>

          </NavLink>
         </NavItem>} 


      <NavItem className="list-adtab" onClick={() => changeTab('3')}>
        <NavLink className={classnames({ active: activeTab == '3' })}>
          <h4>List of {channelTypeId == '2' ? 'Buyers' : 'Vendors'}</h4>

        </NavLink>
      </NavItem>

      {eventDetails.eventStatus == 3 &&
        <NavItem className="list-adtab" onClick={() => changeTab('5')}>
          <NavLink className={classnames({ active: activeTab == '5' })}>
            <h4>My meeting contacts</h4>

          </NavLink>
        </NavItem>}

      {(eventDetails.eventStatus == 3 && eventDetails.eventId != 4) &&
        <NavItem className="list-adtab" onClick={() => changeTab('6')}>
          <NavLink className={classnames({ active: activeTab == '6' })}>
            <h4>Best Product Pitches</h4>

          </NavLink>
        </NavItem>}


    </Nav>
  );
};

export default EventTabs1;