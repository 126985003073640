import React, {useState, useEffect} from 'react';
import { Button } from "reactstrap";
const videoType = 'video/webm';

export const Rerocder=(props)=> {
    const { open, handleChange } = props;
    let videoEl = React.useRef(null);
    let [chunks, setChucks]  = useState([]);
    let [recording, setRecording]  = useState(false);
    let [loading, setLoading]  = useState(false);
    let [videos, setvideos]  = useState([]);
    let [mediaStream, setMediaStream] = useState(null);
    let [uploadVideo, setUploadVideo] = useState(null);
useEffect(() => {

    if (!videoEl || open!=='2') {
      return
    }
  }, [open])

  const startRecording=()=> {
    setLoading(true);
    setRecording(true);
    // Older browsers might not implement mediaDevices at all, so we set an empty object first
        if (navigator.mediaDevices === undefined) {
          navigator.mediaDevices = {};
        }

        // Some browsers partially implement mediaDevices. We can't just assign an object
        // with getUserMedia as it would overwrite existing properties.
        // Here, we will just add the getUserMedia property if it's missing.

        if (navigator.mediaDevices.getUserMedia === undefined) {
          navigator.mediaDevices.getUserMedia = function(constraints) {

            // First get ahold of the legacy getUserMedia, if present
            var getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

            // Some browsers just don't implement it - return a rejected promise with an error
            // to keep a consistent interface
            if (!getUserMedia) {
              setLoading(false);
              return Promise.reject(new Error('getUserMedia is not implemented in this browser'));
            }
            setLoading(false);
            // Otherwise, wrap the call to the old navigator.getUserMedia with a Promise
            return new Promise(function(resolve, reject) {
              getUserMedia.call(navigator, constraints, resolve, reject);
            });
          }
        }
    navigator.mediaDevices.getUserMedia({video:true, audio:{ echoCancellation: {exact: false} }})
    .then(stream => {
      setLoading(false);
      let video = videoEl.current
      video.srcObject = stream
      video.play();
      mediaStream = new MediaRecorder(stream, {
          mimeType: videoType,
        });
        setChucks(chunks);
        setMediaStream(mediaStream);
        mediaStream.ondataavailable = e => {
          if (e.data && e.data.size > 0) {
            chunks.push(e.data);
          }
        };
      setChucks(chunks);
      mediaStream.start(10);
    })
  }

  const stopRecording=()=> {
    const stream = videoEl.current.srcObject;
    console.log('stream',stream);
    const tracks = stream.getTracks();
  
    tracks.forEach(function(track) {
      track.stop();
    });
  
    videoEl.current.srcObject = null;
    mediaStream.stop();
    setRecording(false);
    saveVideo();
  }

  const saveVideo=()=> {
    const blob = new Blob(chunks, {type: videoType});
    var random = Math.random(); 
    var fileName =random +'.webm';
    var file = new File([blob], fileName, {
        type: 'video/webm'
      });
      setUploadVideo([file]);
    console.log('blob', blob);
    const videoURL = window.URL.createObjectURL(blob);
    setvideos(videoURL);
  }

  const deleteVideo=(videoURL)=> {
    // filter out current videoURL from the list of saved videos
    videos = [];
    setvideos(videos);
  }

  const confirmVideo=(videoURL)=> {
    console.log('videoURL', videoURL);
    handleChange(uploadVideo)
  }
//   const handleCanPlay=()=> {
//     videoEl.current.play();
//   }
const progress=(e) =>{
  console.warn('Time' + parseInt(e))
}
    return (<>
       
      <div className="camera video-recorder">
        {recording && <video
          style={{width: 400}}
          ref={videoEl}
          onProgress={e => progress(e)}>
          Video stream not available.
        </video>}
        {!loading &&<div>
          {!recording && <Button variant="outlined" color="info" onClick={e => startRecording(e)}>Start Recording</Button>}
          {recording && <Button variant="outlined" color="warning" onClick={e => stopRecording(e)}>Stop Recording</Button>}
        </div>}
        {videos.length>0 &&
        <div>
          <h3>Recorded videos:</h3>
          {/* {videos.map((videoURL, i) => ( */}
            <div key="video">
              <video style={{width: 200}} src={videos} autoPlay loop />
              <div>
                <Button variant="outlined" color="warning" onClick={() => deleteVideo(videos)}>Delete</Button>
                <Button variant="outlined" color="primary" onClick={() => confirmVideo(videos)}>Confirm</Button>
              </div>
            </div>
          {/* ))} */}
        </div>}
      </div>
      </>
    )
}