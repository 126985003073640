import { history } from '_helpers';
import React from 'react'
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useLocation } from "react-router-dom";

export const StartProdImportSuccess = () => {
  const location = useLocation();
  const prodRows = location.state.data;
  console.log('tttttttt',prodRows)
  const imageCount=prodRows.filter(product=>product.productImages.productimage.length>0)

  return (
    <div className="main-content" id="PaymentConfirm">
      {/* <Title desc={desc} /> */}
      <Container className="mt-4 col-lg-9 col-md-9 col-sm-12  " fluid>
        <Card className="p-3">
          <h1 className="ml-3 mt-3">Success</h1>
          <hr className="m-0 ml-3 paycarhr" style={{ width: "14%" }} />
          <h1 className="text-center">
            <i
              style={{ fontSize: "3rem", color: "#2c9db7" }}
              class="fa fa-check-circle"
              aria-hidden="true"
            ></i>
          </h1>
          <Row className="justify-content-center pb-2">
            <Col lg="8">
              <CardBody>
                {imageCount.length === prodRows.length && (
                  <p>
                    Your product has been successfully added and has been put
                    with <span style={{ fontWeight: "bold" }}>Pending</span>{" "}
                    status until our team can review it. Once approved it will
                    be moved as{" "}
                    <span style={{ fontWeight: "bold" }}>Active</span> and
                    you'll be able to add more details i.e. adding more
                    pictures, press reviews, awards, specific geographical
                    target etc...
                  </p>
                )}
                {imageCount.length < prodRows.length &&
                  imageCount.length > 0 && (
                    <p>
                      Few of your products has been added on your account with a
                      status{" "}
                      <span style={{ fontWeight: "bold" }}>Incomplete</span>{" "}
                      because they are missing a product picture and Other
                      product are pending approval with us. You can edit the
                      product page at any time to add images. Once done the
                      product will be moved to{" "}
                      <span style={{ fontWeight: "bold" }}>Pending</span> status
                      until our team can review it. Once approved it will be
                      moved as{" "}
                      <span style={{ fontWeight: "bold" }}>Active</span> and
                      you'll be able to add more details i.e. adding more
                      pictures, press reviews, awards, specific geographical
                      target etc...
                    </p>
                  )}
                {imageCount.length === 0 && (
                  <p>
                    Your product has been added on your account with a status{" "}
                    <span style={{ fontWeight: "bold" }}>Incomplete</span>{" "}
                    because it's missing a product picture. You can edit the
                    product page at any time to add images. Once done the
                    product will be moved to{" "}
                    <span style={{ fontWeight: "bold" }}>Pending</span> status
                    until our team can review it. Once approved it will be moved
                    as <span style={{ fontWeight: "bold" }}>Active</span> and
                    you'll be able to add more details i.e. adding more
                    pictures, press reviews, awards, specific geographical
                    target etc...
                  </p>
                )}
              </CardBody>
            </Col>
          </Row>
          <div className="text-center">
            <Button
              color="primary"
              type="button"
              onClick={() => history.push("/admin/dashboard")}
            >
              Continue
            </Button>
          </div>
        </Card>
      </Container>
    </div>
  );
}
