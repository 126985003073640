import React, { useState, Component } from "react";
import { Card,CardBody,CardHeader, Container,Col,Row,Table } from "reactstrap";
import axios from 'axios';
import "../../../../assets/scss/custom/subscription.scss";
import {Spinner} from 'views/Hoc/Spinner';

var featureLabel = '';
var defaultSub = {};
class Subscription extends Component {
 
  constructor() {
    super();
    this.state = {
      name: "Subscription"
    };
    this.subscriptionPlans = this.getSubscriptionPlans.bind(this);
   // this.onClick = this.shoot.bind(this);
  }

  shoot(id) {
    console.log(id);
  }
  componentDidMount() {
    this.getSubscriptionPlans();
  }

  async getSubscriptionPlans() {
    let userData = JSON.parse(localStorage.getItem('CHuser'));
    let channelTypeId=userData?.channelTypeId;
    console.log(userData);
    let data = await axios
      .get('channel/subscription/subscriptionPlanAllPeriod/'+channelTypeId)
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        console.log(error);
      });
    this.setState({ subscriptionPlans: data.data });
   // this.setState({ featureLabel: data.data.featureLabel });
  }
  onPlanClick(event) {
   var element = document.getElementById("class0");
   element.classList.toggle("inactive");
   element.id = 'null';
   event.currentTarget.classList.remove("inactive");
    event.currentTarget.classList.add("class0");
    event.currentTarget.id = 'class0';
   // 
   localStorage.setItem('subscriptionId', event.currentTarget.value)
   console.log(event.currentTarget.value)

}

onSubscribe(event){
  window.location.href="user-subscription/"
}

onPeriodClick = param => event => {  console.log(defaultSub);
  localStorage.setItem('subscriptionId', +defaultSub[param])
  var element = document.getElementById("subactive");
  element.classList.remove('active');
  element.id = 'null';
  event.currentTarget.classList.remove('inactive');
  event.currentTarget.classList.add("active");
  event.currentTarget.id = 'subactive';

  var ele = document.getElementsByClassName('subscriptionplan_active')[0];
  
  ele.classList.remove('subscriptionplan_active');
  ele.classList.add('subscriptionplan_inactive');
  //event.currentTarget.classList.add('subscriptionplan_active');
  console.log(param);
   var element = document.getElementById(param);
   element.classList.remove('subscriptionplan_inactive');
   element.classList.add('subscriptionplan_active');


  
}

render() {
     const { subscriptionPlans } = this.state;
     var count = 0;
     const subscriptionTable = []; // create subscription plan
     let periods = []; // to hold plan periods eg : yearly, monthly
     let plans = []; // plan descriptions
     var periodTitle = '';
     var planTitle = '';
     var planTitleArr = [];
     var descArr = [];
     var featureArr = []
     let subscriptionFeature = []
     let subs = [];
     let  splittable = [];
     
   
     for(let flag in subscriptionPlans) {
        if(flag == 'featureLabel'){
          //let featureArr = {}
            for(let fkey in subscriptionPlans[flag]) {
              //subscriptionFeature[subscriptionPlans[flag][fkey].subscriptionExtraFeatureCategoryId] = []
              
              featureArr[subscriptionPlans[flag][fkey].subscriptionExtraFeatureCategoryId] = subscriptionPlans[flag][fkey].subscriptionExtraFeatureCategory
              subscriptionFeature.push(featureArr)
              
            }
        } 
    } 

     for(let flag in subscriptionPlans) {
     
      var keybase = 1;
   
      if(flag == 'subscriptionPlans'){
        subs = subscriptionPlans[flag];
       
        for(let key in subs) {
        if((subs[key].planDetails).length > 0){ 
          periodTitle = subs[key].period.periodDesc;
          var divCls = (key == 0)?'active':'inactive';

          var periodIdCls = (key == 0)?'subactive' :'null';

          var mainCls = 'subscriptionplan_'+divCls;

          periods.push(<td class={divCls} id={periodIdCls} value={periodTitle} style={{verticalAlign:"middle"}} onClick={this.onPeriodClick(periodTitle)}><h3 className="text-center">{periodTitle} </h3> </td>)
          
          var periodKey = [];
          let subscriptionTitle = [];
          let catArr = {}
          for(let plan in subs[key].planDetails) {
            planTitle = subs[key].planDetails[plan].subscription[0].subscriptionPlan.subscriptionPlanType;
            let planPrice = subs[key].planDetails[plan].subscription[0].amount;
            var subscriptionId = subs[key].planDetails[plan].subscription[0].subscriptionId; 
            var butCls = (plan == 0)? 'planbutt class'+plan:'planbutt btn btn-primary';
            var butId = (plan == 0)? 'class'+plan:'null';
            if(plan == 0 && key == 0){ localStorage.setItem('subscriptionId', subscriptionId); }
            if(plan == 0){
              defaultSub[periodTitle] = subscriptionId;
            }
            periodKey.push(<td class="buttonTitle">{planTitle}<br></br><button type="button" id={butId}  className={butCls} value={subscriptionId} onClick={this.onPlanClick}><h3 className="pre-rate">{planPrice}<i class="fas fa-euro-sign"></i>/<span>{periodTitle}</span></h3></button><hr/></td>)
          }
          
          for(let plan in subs[key].planDetails) {
            // planTitle = subs[key].planDetails[plan].subscription[0].subscriptionPlan.subscriptionPlanType;
          
            // subscriptionTitle.push(<td style={{verticalAlign:"middle"}}><h3 className="text-center">{planTitle}</h3></td>)
            var features = subs[key].planDetails[plan].extraFeatures;
            var subscriptionId = subs[key].planDetails[plan].subscription[0].subscriptionId; 
           
            catArr[subscriptionId]={}
            for(let feature in features[0]) {
              var catId = features[0][feature].subscriptionExtraFeatureCategoryId;
              
              catArr[subscriptionId][catId] = features[0][feature].feature_value;
              
              //var displayFlag = subs[key].planDetails[plan].extraFeatures;
              //subscriptionTitle.push(<td style={{verticalAlign:"middle"}}><h3 className="text-center">{planTitle}</h3></td>)
            }
          }
          console.log('features[feature]', catArr);//console.log(catArr);
          let plansDescr = [];
         
         for(let pdd in featureArr){
          
          let planvalue = [];
         
          var title = <td><div><h3 className="text-center">{featureArr[pdd]}</h3></div></td>;
          
            for(let tiltleval in catArr){ 
             
              //if(tiltleval == pdd) {
               // console.log('tiltleval', tiltleval, 'pdd', pdd, 'subscriptionId', subscriptionId);
                planvalue.push(<td>{catArr[tiltleval][pdd]}</td>);
              //}
            }
           plansDescr.push(<tr>{title}{planvalue}</tr>);
         }
        //  planTitleArr.push(<tr className="unt-hr"><td></td>{subscriptionTitle}</tr>)
          //planTitleArr.push()
          //planTitleArr.push(plans);  
         // planTitleArr.push(plansDescr); 
         planTitleArr.push(<table class= {mainCls} id={periodTitle}><tbody><tr><td></td>{periodKey}</tr>{plansDescr}<tr><td></td></tr></tbody></table>)
          
        } 
        } splittable.push(planTitleArr)
    }

    
    //  descArr.push(planTitleArr);
    //  descArr.push(plans);
    } 
    
    var titleRow = <tr><td className="emp-tr1" style={{verticalAlign:"middle",textAlign:"initial"}} colspan="3"><h1>Select Your Premium Plan</h1><hr/></td></tr>


    subscriptionTable.push(<table borderless responsive className="tab-1">{titleRow}<tr colspan="4" className="unt-hr"><table class="periodTitleTable"><tr>{periods}</tr></table></tr><tr colspan="4">{splittable}</tr></table>)

   
return (
<div className="main-content">
        <Container className="mt-4 col-lg-9 col-md-9 col-sm-12  " fluid>
    <Card>
        <CardBody>
            <div className="pre-plan"> 
                <div className="pre-001">   
                {subscriptionTable}      
                </div>
                <div class="center-div">
                <button class="sub_submit" onClick={this.onSubscribe}>Next</button>
                </div>
            </div>           
        </CardBody>
    </Card>
              </Container>
      </div>
    
   
  );
}
};



export default Subscription;