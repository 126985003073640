import React, { useState, useEffect, useRef } from 'react';
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { Card, CardBody, CardHeader, Row, Col, CardImg, Container, Button, UncontrolledTooltip } from "reactstrap";
import { commonService } from '_services/common.service';
import { Spinner } from "views/Hoc/Spinner";
import { Badge } from "reactstrap";
import { Categories, ExpandString, Profile, Certificates } from "views/Hoc/text-tags/TextTags";
import { KeyBrands, BrandListing, ChannelProducts } from "views/Hoc/keyBrands/KeyBrands";
import  AwardsProfile from "views/pages/awardsprofile";
// import { Awardlist } from "views/Hoc/Dialog/Awardlist";
import Channelpiclist from 'views/Hoc/Companypiclist'
import { ContactListing } from "views/Hoc/contact-list/contactList";
import { OfficialDocument } from "views/Hoc/officialDocuments/officialDocuments";
import NotificationAlert from "react-notification-alert";
import VideoPreview from 'views/Hoc/Dialog/VideoPreview';
import CountryFlag from 'views/Hoc/countryFlag/countryFlag';
import Awardlist from 'views/Hoc/Dialog/Awardlist';
import B2blink from 'views/Hoc/Dialog/B2blink';
import Moment from 'moment';
import OtherUserProfileEdit from "views/pages/Channel-Listing/Globaleditdrawer";
import ModalImageCropper from 'views/Hoc/ModalImageCropper';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import FavouritiesChannelDetail from 'views/Hoc/Dialog/FavouritiesChannelDetail'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { history } from '../../../_helpers/history';
import { channelAction } from '_actions/channel.action';
import { connect, useSelector } from 'react-redux';
import axios from 'axios';

import bannerPic from '../../../assets/img/channelDetail/Default Banner.png';

const OtherUserChannelDetail = (props) => {
  console.log("public:", props.location?.pathname?.split('/')[2]);

  let [loading, setLoading] = useState(true);
  let [id, setId] = useState(props.match.params.id ? props.match.params.id : props.match.params.channelId);
  const [image, setImage] = useState('');
  let [channelDetails, setChannelDetails] = useState(null);
  let [contactList, setContactList] = useState([]);
  let [brandList, setBrandList] = useState([]);
  let [productList, setProductList] = useState([]);
  let [similarProductList, setSimilarProductList] = useState([]);
  let userData = JSON.parse(localStorage.getItem('CHuser'));
  const [prod_show, more] = useState(false);
  let [isVideo, setVideoOpen] = useState(false);
  let [videoUrl, setVideoUrl] = useState(null);
  let [awardPopupOpen, setAwardPopupOpen] = useState(false);
  let [b2bPopupOpen, setb2bPopupOpen] = useState(false);
  const notificationAlert = useRef(null);
  const [imageSrc, setImageSrc] = useState('');
  const [showDrawer, setshowDrawer] = useState(false);
  const [drawerType, setDrawerType] = useState(null);
  let [channelBasicInfo, setChannelBasicInfo] = useState({
    channelB2B: [],
    channelLogo: null,
    address: null,
    city: null,
    companyName: null,
    aboutUs: null,
    country: null,
    isoCode: null,
    phoneno: null,
    webSiteUrl: null,
    introvideo: null,
    socialLinks: [],

    linkedIn_pre: 'https://www.linkedin.com/company/',
    insta_pre: 'https://www.instagram.com/',
    facebook_pre: 'https://www.facebook.com/',
    twitter_pre: 'https://twitter.com/',

  })
  let [tradeInformation, setTradeInformation] = useState({
    productCategories: [],
    speciality: [],
    ChannelProfileValue: [],
    RetailerProfileValue: [],
    ResellerProfileValue: [],
    DistributorProfileValue: [],
    SellingLocation: [],
    profileType: null
  });
  let [factInfo, setFactInfo] = useState({
    empCnt: null,
    empCntRange: null,
    estYear: null,
    no_of_skus: null,
    startup: null,
    storeCount: null,
    turnover: null,
    turnoverRange: null,
  });
  let [switchValue, setSwitchValue] = useState(0);
  const redux_data = useSelector(state => state.channelListing);
  let Channel_List = redux_data?.channel;

  let [businessType, setBusinessType] = useState(null);

  //redirecting route for decision making
  let to = props.location?.pathname?.split('/')[2] ? props.location?.pathname?.split('/')[2] : 'channel';



  useEffect(() => {
    id = props.match.params.id ? props.match.params.id : props.match.params.channelId;
    getChannelDetails(id);
  }, [])
  const getChannelDetails = async (id) => {

    let res = await commonService.getServices('setting/companyuser/' + id).catch(err => console.log('err', err));
    if (res) {
      let result = res.data;
      channelDetails = res.data.result;
      let banner = res.data.banner;
      console.log('result ======> ', result)
      //business type 
      // setBusinessType(channelDetails.isOEM)

      // console.log(channelDetails.isConfidential, '----- && ----- ', userData.planId)
      setChannelDetails(channelDetails);
      getChannelContact(id);
      channelBasicInfo.channelB2B = result?.channelB2B ? result?.channelB2B : [];
      channelBasicInfo.channelLogo = channelDetails?.channelDetail?.channelLogo?.documentUrl;
      channelBasicInfo.address = channelDetails?.regAddress?.address
      channelBasicInfo.city = channelDetails?.regAddress?.city;
      channelBasicInfo.zip = channelDetails?.regAddress?.postalCode;

      channelBasicInfo.companyName = channelDetails?.companyName;
      channelBasicInfo.aboutUs = channelDetails?.channelDetails?.detailDesc;
      channelBasicInfo.country = channelDetails?.country?.country;
      channelBasicInfo.isoCode = channelDetails?.country?.isoCode;
      channelBasicInfo.phoneno=channelDetails?.channelDetail?.phone1;

      channelBasicInfo.contactName=channelDetails?.contact.firstName;
      //channelBasicInfo.phoneno=channelDetails?.channelDetail?.phone1;
      // //text formatting for phone number
      // channelBasicInfo.phoneno = channelDetails?.phoneno ? channelDetails?.phoneno.split('-').length > 1 ?
      //   channelDetails.phoneno : (channelDetails.phoneCode.substr(1,) == channelDetails.phoneno.substr(0, channelDetails.phoneCode.substr(1,).length)) ?
      //     channelDetails.phoneCode + '-' + channelDetails.phoneno.slice(channelDetails.phoneCode.substr(1,).length,) : channelDetails.phoneCode + '-' + channelDetails.phoneno : '';
      channelBasicInfo.webSiteUrl = channelDetails?.channelDetail?.webSiteUrl;
     // channelBasicInfo.introvideo = channelDetails?.introvideo;
      channelBasicInfo.socialLinks = channelDetails?.channelSocial?.socialLinks;
       setChannelBasicInfo({ ...channelBasicInfo });
       setImageSrc(banner?.documentPath ? process.env.REACT_APP_CDN_URL + banner?.documentPath : null);

      // tradeInformation.productCategories = channelDetails?.tradeInformation?.existingCategories;
      // tradeInformation.speciality = channelDetails.channelTypeId.toString() === '3' ? channelDetails?.tradeInformation?.speciality : [];


      // tradeInformation.ChannelProfileValue = channelDetails?.tradeInformation?.customerProfile;
      // tradeInformation.RetailerProfileValue = channelDetails?.tradeInformation?.retailerProfile;
      // tradeInformation.ResellerProfileValue = channelDetails?.tradeInformation?.resellerProfile;

      
      // tradeInformation.SellingLocation = channelDetails.channelTypeId==5?channelDetails?.locations?.RESELLING:channelDetails.channelTypeId==4?channelDetails?.locations?.RETAIL:channelDetails?.locations?.SELLING;

      // tradeInformation.profileType = channelDetails.profileType;
      // setTradeInformation({ ...tradeInformation });

      factInfo.empCnt = channelDetails?.channelDetail?.empCnt;
      factInfo.empCntRange = channelDetails?.channelDetail?.empCntRange;
      factInfo.estYear = channelDetails?.channelDetail?.estYear;
      factInfo.no_of_skus = channelDetails?.channelDetail?.no_of_skus;
      factInfo.startup = channelDetails?.channelDetail?.startup;
      factInfo.storeCount = channelDetails?.channelDetail?.storeCount;
      factInfo.turnover = res?.data?.turnover;
      factInfo.turnoverRange = channelDetails?.turnOver;
      factInfo.isPhysicalSales=channelDetails?.isPhysicalSales;

      setFactInfo({ ...factInfo });


       setLoading(false)
    }
  }
  const hiddenFileInput = React.useRef(null);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };


  const getChannelContact = async (id) => {
    setLoading(true);
    contactList = [];
    setContactList(contactList);
    let res = await commonService.postService('', 'channel/channelContacts/' + id).catch(err => console.log('err', err));
    if (res) {
      contactList = res.data;
      setContactList(contactList);
    }
    setLoading(false);
  }

  const handleChangeBanner = event => {
    const data = event.target.files[0];
    console.log('files', data)

    if (data && data['type'].split('/')[0] === 'image') {
      console.log('datat', data);
      let file = data;
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        console.log('file', file)
        console.log('reader.result', reader.result)
        setImage(
          reader.result
        );
        console.log('image', image)
        //setOpen(false);          
      };

    }
  };

  const selectImg = async (data) => {
    if (data) {
      setImage('');
      //converting base64 string to file format.
      var random = Math.random();
      var fileName = random + '.png';
      var file = dataURLtoFile(data, fileName);
      const formData = new FormData();
      formData.append('file', file);
      setLoading(true);
      const resImagePath = await commonService.fileUploadService(formData, '/mediaupload/upload/' + 'banner');
      console.log('resImagePath', resImagePath);
      if (resImagePath) {
        const path = await commonService.postService({ bannerUrl: resImagePath.data.url }, '/setting/updateBannerImage/');
        if (path) {
          setImageSrc(path?.data?.documentUrl);
          ToastsStore.success('Banner image updated!');
        } else {
          ToastsStore.error('Something Went Wrong!');
        }
        setLoading(false);
      }

    }
  }

  function dataURLtoFile(dataurl, filename) {

    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }
  const setDrawerId = (data) => {
    setDrawerType(data);
    setshowDrawer(true);
  }
  const closeDrawer = () => {
    setshowDrawer(false);
  }
  const changedData = (data, id) => {
    console.log(data);
    console.log(id);
    setLoading(true);
    if (id === 1) {
      channelBasicInfo.channelB2B = data?.B2B_url ? data?.B2B_url : [];
      channelBasicInfo.channelLogo = data.company_logo;
      channelBasicInfo.address = data.reg_address
      channelBasicInfo.city = data.reg_reg_city;
      channelBasicInfo.companyName = data.company_name;
      channelBasicInfo.aboutUs = data.company_about;
      channelBasicInfo.country = data.country;
      channelBasicInfo.isoCode = data.isoCode;
      channelBasicInfo.phoneno = data.company_phone1;
      channelBasicInfo.webSiteUrl = data.company_website_url;
      channelBasicInfo.introvideo = data.introvideo;
      channelBasicInfo.socialLinks = [];
      if (data?.social_instagram_url)
        channelBasicInfo.socialLinks.push({ social: 'Instagram', link: data.social_instagram_url });
      if (data?.social_linkedin_url)
        channelBasicInfo.socialLinks.push({ social: 'Linkedin', link: data.social_linkedin_url });
      if (data?.social_twitter_url)
        channelBasicInfo.socialLinks.push({ social: 'Twitter', link: data.social_twitter_url });
      if (data?.social_facebook_url)
        channelBasicInfo.socialLinks.push({ social: 'Facebook', link: data.social_facebook_url });
      setChannelBasicInfo({ ...channelBasicInfo });
    } else if (id === 2) {
      factInfo.empCnt = data?.employeesCount;
      factInfo.empCntRange = data?.employeesRange;
      factInfo.estYear = data?.year_of_estabilishment;
      factInfo.no_of_skus = data?.no_of_skus;
      factInfo.startup = data?.startup;
      factInfo.storeCount = data?.storeCount;
      factInfo.turnover = data?.annualTurnover;
      factInfo.turnoverRange = data?.annualTurnoverRange;
      setFactInfo({ ...factInfo });
    } 
    setLoading(false);
  }
  const closeAwardPopUp = () => {
    setb2bPopupOpen(false);
  }

  const notify = (type, message) => {
    let options = {
      place: "tr",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {/* {" "}
                {type} */}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      // icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };
  return (
    <div id='channel-detail' className="main-content">
      <NotificationAlert ref={notificationAlert} />
      {/* <VideoPreview videoModalClose={videoModalClose} isOpen={isVideo} url={videoUrl}/> */}
      <SimpleHeader location={props.match} />
      <Container className="mt-4" fluid>
      {loading ? <Spinner /> : <>
        <OtherUserProfileEdit title="other Company Edit" visible={showDrawer} closeDrawer={closeDrawer} changedData={changedData} type={drawerType} />
        <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore} />
        <B2blink open={b2bPopupOpen} handleClose={closeAwardPopUp} value={channelBasicInfo?.channelB2B} />
     
        <Row >
            <Col className="card-wrapper" lg="12" md="12" sm="12" >
            
              <Card >
                {(userData.channelId !== id && !props.match.params.topsearch) && <> <div className='left-arrow leftar-wh'>
                 
                </div>
                  <div className='right-arrow rightar-wh'>
                   
                  </div></>}
                <CardImg
                  alt="..."
                  style={{ height: "350px", objectFit: "cover" }}
                  src={imageSrc ? imageSrc : bannerPic}
                  top
                />
                <input type="file"
                  ref={hiddenFileInput}
                  accept="image/*"
                  onChange={handleChangeBanner}
                  style={{ display: 'none' }}
                />
                {image ? <ModalImageCropper isOpen={true} src={image} handleChange={selectImg} handleCropClose={() => setImage('')} type="banner" /> : <></>}
                
                {userData.channelId === id && <div className='tet curs-point' onClick={handleClick}>
                  <i class="fas fa-camera"></i>
                </div>}
                {(channelDetails.channelTypeId != 2 || channelDetails?.subscriptionPlan) && <div className="plan-badge">
                  <Row>
                    <Col lg="9" className="pad-less c-1 col-9">
                      <div className="plan-txt">
                        <h3>{parseInt(channelDetails.channelTypeId) !== 2 ? 'Member since ' + Moment(channelDetails?.createdAt).format('yyyy') : channelDetails?.subscriptionPlan}</h3>
                      </div>
                    </Col>
                    <Col lg="3" className="pad-less c-1 col">
                      <div className="plan-txt1"><i class="fas fa-bookmark"><i class="fas fa-star str-i"></i></i></div>
                    </Col>
                  </Row>
                </div>}
                {userData.channelId !== id && <div className='cnect-brnd'>
                  <FavouritiesChannelDetail channelTypeId={channelDetails.channelTypeId} channelId={channelDetails.channelId} isFavorite={channelDetails.favorite} />
                 
                </div>}
              </Card>
            </Col>
          </Row>
          <div style={{ marginTop: '-2.5rem'}}>
            <Card className='rounded-0'>

              <CardBody>
                {userData.channelId === id && <i class="fas fa-pencil-alt ch-edit" onClick={() => setDrawerId('5')} style={{ cursor: "pointer" }}></i>}

                <Row className="align-items-center">
                  <Col className="col-lg-auto col-md-auto col-sm-12 complog">
                    <a
                      style={{ width: "150px", height: "150px", padding: "5px", border: "1px solid #b8b8b8", backgroundColor: "white" }}
                      className="avatar avatar-xl mb-2"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }}
                        alt="..."
                        src={channelBasicInfo?.channelLogo ? channelBasicInfo?.channelLogo : "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"}
                      />
                    </a>
                  </Col>
                  <div className="col-lg-3 col-md-12 col-sm-12">
                    <h2 className='text-uppercase mt-6 mobile-head'>{channelBasicInfo?.companyName}</h2>


                    <p className='text-uppercase mb-1'>since {factInfo.estYear} </p>
                    <div className='badges mb-3'>
                      <Badge>
                        {channelDetails?.channelType}
                      </Badge>
                    
                    </div>

                    <div className='mb-3'>
                     
                      <Badge className='outer_badge'>
                        {channelBasicInfo?.country}
                      </Badge>
                    </div>
                    <div className="mb-3">
                       {channelBasicInfo?.socialLinks?.map(x => {
                        return (<>
                          {(x.social === 'Twitter') && <a onClick={() => {
                            let url = x.social === 'Twitter' ? channelBasicInfo.twitter_pre + x.link.replace(/\/$/, "").split('/').splice(-1, 1) : x.link;
                            if (!url.match(/^https?:\/\//i) && !url.match(/^http?:\/\//i)) {
                              url = '//' + url;
                            }
                            window.open(url, '_blank')
                          }}>
                            <Button className="btn-icon-only mr-2" color="twitter" type="button">
                              <span className="btn-inner--icon">
                                <i className="fab fa-twitter" />
                              </span>
                            </Button>
                          </a>}
                          {(x.social === 'Linkedin' && x?.link) ? <a onClick={() => {
                            let url = channelBasicInfo.linkedIn_pre + x.link.replace(/\/$/, "").split('/').splice(-1, 1);
                            if (!url.match(/^https?:\/\//i) && !url.match(/^http?:\/\//i)) {
                              url = '//' + url;
                            }
                            window.open(url, '_blank')
                          }}>
                            <Button className="btn-icon-only mr-2 i-link" color="twitter" type="button">
                              <span className="btn-inner--icon">
                                <i className="fab fa-linkedin" />
                              </span>
                            </Button>
                          </a>
                            :
                            <>
                              {(x.social !== 'Twitter' && x.social !== 'Instagram' && x.social !== 'Twitter' && x.social === 'Linkedin') && <a data-placement="top" id={'linked-in' + id} onClick={() => {
                              }}>
                                <Button className="btn-icon-only mr-2 i-linkDisabled" type="button" onClick={() => {
                                  if (userData?.channelId == id)
                                    setDrawerId('11')
                                }}>
                                  <span className="btn-inner--icon">
                                    <i className="fab fa-linkedin" />
                                  </span>
                                </Button>
                                <UncontrolledTooltip target={'linked-in' + id} placement='bottom'>
                                  {userData?.channelId == id ? 'Update your LinkedIn url for better profile' : 'LinkedIn url not available for this channel.'}
                                </UncontrolledTooltip>
                              </a>}
                            </>
                          }
                          {(x.social === 'Instagram' && x?.link) && <a onClick={() => {
                            let url = channelBasicInfo.insta_pre + x.link.replace(/\/$/, "").split('/').splice(-1, 1);
                            if (!url.match(/^https?:\/\//i) && !url.match(/^http?:\/\//i)) {
                              url = '//' + url;
                            }
                            window.open(url, '_blank')
                          }}>
                            <Button className="btn-icon-only mr-2 i-insta" color="instagram" type="button">
                              <span className="btn-inner--icon">
                                <i className="fab fa-instagram"></i>
                              </span>
                            </Button>
                          </a>}

                          {(x.social === 'Facebook' && x?.link) && <a onClick={() => {
                            let url = channelBasicInfo.facebook_pre + x.link.replace(/\/$/, "").split('/').splice(-1, 1);
                            if (!url.match(/^https?:\/\//i) && !url.match(/^http?:\/\//i)) {
                              url = '//' + url;
                            }
                            window.open(url, '_blank')
                          }}>
                            <Button className="btn-icon-only rounded-circle" color="facebook" type="button">
                              <span className="btn-inner--icon">
                                <i className="fab fa-facebook"></i>
                              </span>
                            </Button>
                          </a>}
                        </>
                        )
                      })}              
                     
                    </div>                   
                  </div>
                  <div className={channelBasicInfo?.introvideo ? 'col-lg-5 col-md-auto col-sm-12 ml--3 ' : 'col-lg-8 col-md-auto col-sm-12 ml--3'}>
                  <div className='cust-pad'>
                    <p className='mb-1 font-weight-700'>About us </p>
                    <Row><p className="w-100"> {channelBasicInfo?.aboutUs?.replace(/<[^>]+>/g, '')} </p></Row>
                    <Row><Col lg="8" className="pad-less">
                      <h4 style={{ color: '#b8b8b8' }}>Address</h4>
                      <Row><p> {channelBasicInfo?.address}, {channelBasicInfo?.city}, {channelBasicInfo?.zip} </p> </Row></Col>
                      <Col lg="4">
                        <h4 style={{ color: '#b8b8b8' }}>Phone Number</h4>
                        <Row><p className='mt1-5'><CountryFlag code={channelBasicInfo.isoCode} /></p> <p className='ml-2 res-ph'> {channelBasicInfo?.phoneno} </p></Row></Col>

                      <Col lg="4">
                        <h4 style={{ color: '#b8b8b8' }}>Contact Name</h4>
                        <Row><p className='ml-2 res-ph'> {channelBasicInfo?.contactName} </p></Row></Col>
                    </Row>
                    <Row><h4 style={{ color: '#b8b8b8' }}>Web Site</h4></Row>
                    <Row>
                      <p className='mb-1'>{channelBasicInfo?.webSiteUrl}<a href=''
                      onClick={(e) => {
                        e.preventDefault();
                        let url = channelBasicInfo?.webSiteUrl;
                        if (!url.match(/^https?:\/\//i)) {
                          if (!url.match(/^http?:\/\//i))
                            url = '//' + url;
                        }
                        window.open(url, '_blank')
                      }} className="">{channelDetails?.webSiteUrl?.replace(/^\W+/, '')} </a></p>
                      </Row>
                    
                    {!!channelBasicInfo?.channelB2B?.length && channelBasicInfo?.channelB2B.slice(0, 2).map(x =>
                      <p className='mb-1'><a href=''
                        onClick={(e) => {
                          e.preventDefault();
                          let url = x?.channelB2B;
                          if (!url.match(/^https?:\/\//i)) {
                            if (!url.match(/^http?:\/\//i))
                              url = '//' + url;
                          }
                          window.open(url, '_blank')
                        }} className="">{x?.channelB2B?.replace(/^\W+/, '')} </a></p>

                    )}

                    {channelBasicInfo?.channelB2B?.length > 2 && <h4 onClick={() => setb2bPopupOpen(true)} className=" mr-2" color="primary">
                      <span>
                       
                        View More
                      </span>
                    </h4>}
                    </div>
                  </div>
                  


                </Row>

              </CardBody>


            </Card>
          </div>
          {<Card >
            <CardHeader className="p-3">
              <h3 className="ml-2 mb-4">Facts & Figures
                {userData.channelId === id && <i class="fas fa-pencil-alt ch-edit" onClick={() => setDrawerId('2')} style={{ cursor: "pointer" }}></i>}
              </h3>
              <Row>
                <Col>
                  <Row>
                    <Col lg='3' className='pad-less'>
                      <div className='fact-icon'>
                        <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} src={require("assets/img/icons/establish-icon-png.png")} />
                      </div>
                    </Col>
                    <Col lg='9' className='pr-0'>
                      <h1 className="mb-0 fa-s">{factInfo?.estYear != null ? (new Date().getFullYear() == parseInt(factInfo.estYear))?1:new Date().getFullYear() - parseInt(factInfo.estYear) : 'NC'}</h1>
                      <h5 className="m-0 text-uppercase text-muted">Years In Business</h5>
                    </Col>
                  </Row>
                </Col>

                {/* <Col>
                  <Row>
                    <Col lg='3' className='pad-less'>
                      <div className='fact-icon'>
                        <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} src={require("assets/img/icons/turnover.png")} />
                      </div>
                    </Col>
                    <Col lg='9' className='pr-0'>
                      <h1 className="mb-0 fa-s">{factInfo?.turnoverRange ? factInfo?.turnoverRange : factInfo?.turnover ? factInfo?.turnover + ' M€' : 'NC'}</h1>
                      <h5 className="m-0 text-uppercase text-muted">Annual Turnover</h5>
                    </Col>
                  </Row>
                </Col> */}

                <Col>
                  <Row>
                    <Col lg='3' className='pad-less'>
                      <div className='fact-icon'>
                        <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} src={require("assets/img/icons/employess-icon.png")} />
                      </div>
                    </Col>
                    <Col lg='9' className='pr-0'>
                      <h1 className="mb-0 fa-s">{factInfo?.empCntRange ? factInfo?.empCntRange : factInfo?.empCnt ? factInfo?.empCnt : "NC"}</h1>
                      <h5 className="m-0 text-uppercase text-muted">No. Of Employees</h5>
                    </Col>
                  </Row>
                </Col>
                
                {parseInt(channelDetails?.channelTypeId) === 2 && <Col>
                  <Row>
                    <Col lg='3' className='pad-less'>
                      <div className='fact-icon'>
                        <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} src={require("assets/img/icons/SKU.png")} />
                      </div>
                    </Col>
                    <Col lg='9' className='pr-0'>
                      <h1 className="mb-0 fa-s">{factInfo?.no_of_skus ? factInfo?.no_of_skus : "NC"}</h1>
                      <h5 className="m-0 text-uppercase text-muted">{channelDetails?.channelTypeId === '2' ? "Total No of Sku" : "No of Sku"}</h5>
                    </Col>
                  </Row>
                </Col>}



                

               
              </Row>
              <Row>
                <Col>
                {contactList?.records?.length > 0 && <div id="connectWith">{userData.channelId === id && <i class="fas fa-pencil-alt ch-edit1 mt-4"></i>}
            <ContactListing publicProfile={props.location?.pathname?.split('/')[2]} data={contactList} title={channelDetails?.channelTypeId === '2' ? "Connect With Us" : "List of contacts"} channelTypeId={channelDetails?.channelTypeId} channelId={id} getChannelContact={getChannelContact} notify={notify} />
          </div>}
                </Col>
              </Row>

            </CardHeader>
          </Card>}
        </>
      }
      </Container>
    </div>

  );
}

function mapState(state) {
  const general_data = state.channelListing;
  return general_data;
}

const actionCreators = {
  channelRedux: channelAction.channelRedux
};

export default connect(mapState, actionCreators)(OtherUserChannelDetail);
