import React from "react";
import { Spin } from 'antd';
//import 'antd/dist/antd.css';
export const Spinner = props => {  

  
  return (
    <div className="fp-container">
    <Spin size="large" className="fp-loader" spinning={true} />
  </div>
  );
}

export const ModalSpinner = props => {  

  
  return (
    <div className="modalSpinner">
    <Spin size="large"  spinning={true} />
  </div>
  );
}

export const Gearspin = props => {  

  
  return (
    <div className="fp-containergear">
      <div className="firstsection w-100">
              <h1 className="geartitle1 text-center">We are setting up your account and suggestion list.</h1>
     <h1 className="geartitle1 text-center"  style={{fontSize:"1.3rem"}}>Please wait for some moment</h1>
     </div>
    <img  src={require("../../assets/img/gear.svg")}/> 
    <div className="sSecondection">
     <p className="text-center mt-2 loading">
    You will be redirected soon
    </p></div>
  </div>
  );
}