import React, { useState, useEffect } from 'react';
import { Row } from "reactstrap";
import {FormCheckBox} from "_components/FormElements/FormInput";
import { Button, Modal } from "reactstrap";

const EventPackageOptions = (props) => {
  const {  isOpen, closeModalPopup, changedData }=props;

  const {selectedPackageOptions, selectedMarketingOptions, packageOptions, marketingOptions} = props.data;

  const [state, setState] = useState({
    selectedPackageOptions: selectedPackageOptions,
    selectedMarketingOptions: selectedMarketingOptions
  });

  const handleChangeSelect = (input, value) => {
    if(input == 'selectedMarketingOptions' || input == "selectedPackageOptions"){
      if(state[input].includes(value)){
        state[input] = state[input].filter(x => x.optionId != value.optionId);
      }else{
        state[input].push(value);
      }
      console.log('selectedoptions', state)
      setState({...state});
    }
  }

  const save = () => {
    if(state.selectedMarketingOptions?.length)
    changedData('selectedMarketingOptions', state.selectedMarketingOptions);
    if(state.selectedPackageOptions?.length)
    changedData('selectedPackageOptions', state.selectedPackageOptions);
    closeModalPopup();
  }
  

    return (
  
  <Modal
className="modal-dialog-centered"
isOpen={isOpen} >
<div className="modal-header">
<h3 className="text-center">Select Package Options</h3>
<button
aria-label="Close"
className="close"
data-dismiss="modal"
type="button"
onClick={closeModalPopup}
>
<span aria-hidden={true}>×</span>
</button>
</div>
<div className="modal-body">
<div className="row">          
          {
            !!marketingOptions?.length && 
            
            <div>
            <h4>Select Marketing Options</h4>

            {marketingOptions.map(x => 
            
                     <div className="chck">
                     <FormCheckBox
                      id={x.optionId}
                      type="checkbox"
                      label={x.text + ' - ' + x.value}
                      //label1={{textAlign:"justify"}}
                      //register={register}
                      checked={state.selectedMarketingOptions.includes(x)}
                      onChange={() => handleChangeSelect('selectedMarketingOptions', x)}
                      />
                     </div>
                     
              ) }
              </div>
          }
  
          {
            !!packageOptions?.length &&
            
            <div>
            <h4>Select Package Options</h4>
            
            {packageOptions.map(x => 
            
                     <div className="chck">
                     <FormCheckBox
                      id={x.optionId}
                      type="checkbox"
                      label={x.text + ' - ' + x.value}
                      //label1={{textAlign:"justify"}}
                      //register={register}
                      checked={state.selectedPackageOptions.includes(x)}
                      onChange={() => handleChangeSelect('selectedPackageOptions', x)}
                      />
                     </div>
                     
              ) }
              </div>
          }
          
</div>

</div>

<div className="modal-footer">
<Button
color="secondary"
data-dismiss="modal"
type="button"
onClick={closeModalPopup}
>
Close
</Button>
<Button
className='btn btn-primary'
data-dismiss="modal"
type="button"
onClick={save}
>
Save
</Button>
</div>
</Modal>



    );
};

export default EventPackageOptions;