import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { dashboard } from './dashboard.reducer';
import { users } from './users.reducer';
import { adduser } from './adduser.reducer';
import { alert } from './alert.reducer';
import { subscription } from './subscription.reducer';
import { channelListing } from './channel-listing.reducer';
import { distributionChannel } from './distributionChannel.reducer';
import { event } from './events.reducer';
import { userData } from './userData.reducer';
const rootReducer = combineReducers({
  authentication,
  dashboard,
//  registration,
  users,
  alert,
  adduser,
  subscription,
  channelListing,
  distributionChannel,
  event,
  userData
});

export default rootReducer;