import PerfectScrollbar from 'react-perfect-scrollbar'
import { commonService } from '_services/common.service';
import {

    Container,
    Row,
    Col,CardBody,Card,ListGroupItem,
    ListGroup,Badge
    } from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader";
import React, { useState, useEffect, useRef } from 'react';
import Title from "views/Hoc/Dialog/Title";
import axios from 'axios';
import Moment from 'moment';
import { history } from '../../../_helpers/history';
import { ChannelDismiss } from 'views/Hoc/Dialog/ChannelDismiss';

const Notification =(props)=> {
 let [isLoading, setLoading] = useState(true);
 
 const [state,setState]=useState({ resData:[] });
  useEffect(() => {
    getMessage();

  }, []);
    const getMessage = async() => {
     
      let res = await commonService.getServices('notification/getMessages').catch(err => setLoading(false));
      if (res) {
        setState({ ...state, resData:res.data });
      }
    }

    const dismiss = async(x) => {
      let body = {
        notificationId: x
      };
      let res = await commonService.postService(body, 'notification/updateMessage').catch(err => setLoading(false));
      if (res) {

        setState({ ...state, resData:res.data });
      }
    }
    
return (
 <>
<SimpleHeader location={props.match}/>
<div id='Notification' className="main-content">
<Title title="Notification" desc={["Your Day to Day Notification from other channels and ChannelHub"]} />
        <Container className="mt-4" fluid>
<Row>
          <Col lg="12" md="12" sm="12" >
          <PerfectScrollbar>

          {state.resData.map(x => ( 
            ((+x.notificationType == 1)?<Card>
            <div class="cardcolor">
              <CardBody>
              <ListGroup flush>
          <ListGroupItem  className="list-group-item-action"
            href="#pablo"
            onClick={e => e.preventDefault()}
           tag="a">
            <Row>
                <Col className="col-xs-12">
                  {/* <div className="col-sm-2 col-xs-12"> */}
                  <a href="#" onClick={() => dismiss(x.notificationId)}> Dismiss </a>
                  <div className="notilist">
                  <li class="col-lg-1 col-sm-2 col-xs-12 float-left" >
                
              <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}} 
                  className="avatar rounded-circle notilogo"
                  alt="..."
                  src=""                  
                />
                  </li>
                <li class="col-lg-9 col-sm-8 col-xs-12 float-left">
                <h4 className="mb-0 text-sm d-inline-block">Subscription </h4>  
                <Badge className="badge badge-blue text-capitalize" onClick={() => history.push('/admin/Subscription')}> Upgrade </Badge>
                {/* <p className="text-sm mb-0">  You have <span className="fg-blue">147</span>  new suggestion(s) </p> */}
         <p className="notidetail">{x.messages}</p>
                </li>
                {/* </div> */}
                <li class="col-lg-2 col-md-2 col-sm-12 col-xs-12 float-left">
                  {/* <small className="clk text-muted mt-1"><i class='fas fa-clock notitime'></i>2 hrs ago</small> */}
                  <br/>
                <small className="clk text-muted mt-1">{ Moment(x.createdAt).format('D-M-yyyy')}</small></li>
                </div>
                </Col>
                </Row>
                </ListGroupItem>
                </ListGroup>
              </CardBody>
              </div>
            </Card>
           
           :'')
           
           ))}
        
            {/* <h4 className="text-center fg-blue" style={{cursor:'pointer'}}>View More</h4> */}
          </PerfectScrollbar>
         
</Col>
</Row>

{/* product approval*/}
<Row>
          <Col lg="12" md="12" sm="12" >
          <PerfectScrollbar>

          {state.resData.map(x => ( 
            ((+x.notificationType == 2)?<Card>
            <div class="cardcolor">
              <CardBody>
              <ListGroup flush>
          <ListGroupItem  className="list-group-item-action"
            href="#pablo"
            onClick={e => e.preventDefault()}
           tag="a">
            <Row>
                <Col className="col-xs-12">
                  {/* <div className="col-sm-2 col-xs-12"> */}
                  <a href="#" onClick={() => dismiss(x.notificationId)}> Dismiss</a>
                  <div className="notilist">
                  <li class="col-lg-1 col-sm-2 col-xs-12 float-left" >
                
              <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}} 
                  className="avatar rounded-circle notilogo"
                  alt="..."
                  src=""
                 />
                  </li>
                <li class="col-lg-9 col-sm-8 col-xs-12 float-left">
                <h4 className="mb-0 text-sm d-inline-block">Product </h4>  
                <Badge className="badge badge-blue text-capitalize" onClick={() => history.push('/admin/Myproductlist')}> view </Badge>
                {/* <p className="text-sm mb-0">  You have <span className="fg-blue">147</span>  new suggestion(s) </p> */}
         <p className="notidetail">{x.messages}</p>
                </li>
                {/* </div> */}
                <li class="col-lg-2 col-md-2 col-sm-12 col-xs-12 float-left">
                  {/* <small className="clk text-muted mt-1"><i class='fas fa-clock notitime'></i>2 hrs ago</small> */}
                  <br/>
                <small className="clk text-muted mt-1">{ Moment(x.createdAt).format('D-M-yyyy')}</small></li>
                </div>
                </Col>
                </Row>
                </ListGroupItem>
                </ListGroup>
              </CardBody>
              </div>
            </Card>
           
           :'')
           
           ))}
        
            {/* <h4 className="text-center fg-blue" style={{cursor:'pointer'}}>View More</h4> */}
          </PerfectScrollbar>
         
</Col>
</Row>


<Row>
          <Col lg="12" md="12" sm="12" >
          <PerfectScrollbar>

          {state.resData.map(x => ( 
            ((+x.notificationType == 3)?<Card>
            <div class="cardcolor">
              <CardBody className='notification'>
              <ListGroup flush>
          <ListGroupItem  className="list-group-item-action"
            href="#pablo"
            onClick={e => e.preventDefault()}
           tag="a">
            <Row>
                <Col className="col-xs-12">
                  {/* <div className="col-sm-2 col-xs-12"> */}
                 
                  <div className="notilist">
                  <li class="col-lg-1 col-sm-2 col-xs-12 float-left" >
                
              <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}} 
                  className="avatar rounded-circle notilogo"
                  alt="..."
                  src=""
                 />
                  </li>
                <li class="col-lg-9 col-sm-8 col-xs-12 float-left">
                <h4 className="mb-0 text-sm d-inline-block">Event </h4>  
                <Badge className="badge badge-blue text-capitalize" onClick={() => history.push('/admin/Myproductlist')}> view </Badge>
                {/* <p className="text-sm mb-0">  You have <span className="fg-blue">147</span>  new suggestion(s) </p> */}
         <p className="notidetail">{x.messages}</p>
                </li>
                {/* </div> */}
                <li class="col-lg-2 col-md-2 col-sm-12 col-xs-12 float-left">
                  {/* <small className="clk text-muted mt-1"><i class='fas fa-clock notitime'></i>2 hrs ago</small> */}
                  <br/>
                <small className="clk text-muted mt-1">{ Moment(x.createdAt).format('D-M-yyyy')}</small></li>
                </div>
                </Col>
                </Row>
                </ListGroupItem>
                </ListGroup>
              </CardBody>
              </div>
            </Card>
           
           :'')
           
           ))}
        
            {/* <h4 className="text-center fg-blue" style={{cursor:'pointer'}}>View More</h4> */}
          </PerfectScrollbar>
         
</Col>
</Row>

</Container>
</div>
</>
);
}


export default Notification;