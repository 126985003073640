import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { loginschema } from './validation';
import { InputAdornment, IconButton } from '@material-ui/core';
import { Email, Lock } from '@material-ui/icons';
import { userActions } from '_actions/user.actions';
import { LocalStorageService } from '_services';
import { connect } from 'react-redux';
import { FormInput, FormButton, FormCheckBox } from "_components/FormElements/FormInput";
import logo from 'assets/img/brand/ch-logo.svg';
import { history } from '../../../_helpers';
import SignupDrawer from 'views/pages/SignupPage/SignupDrawer';
import axios from 'axios';
import { dashboardService, userService } from '../../../_services';
import { userConstants, dashboardConstants } from '../../../_constants';
import { invalid } from "moment";
import Moment from 'moment';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { GuestPopup } from 'views/Hoc/Dialog/GlobalDialog';
import Dialog from 'views/Hoc/Dialog/GlobalDialog';
// import { userService } from '../_services';
import { useLocation } from 'react-router';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Login = props => {

  //from event
  let query = useQuery();

  let eventId = query.get("eventId");

  // popup dialog start

  let [modalPopVal, setModalPopVal] = useState({ message: null, title: null, value: null, popUpId: null, second_message: null });
  const [openPop, setOpenPop] = useState(false);

  let [isModalPopup, setModalPopup]  = useState(false);
  let [modalPopValue, setModalPopValue]  = useState({message:null, title:null, value:null, popUpId:null });
  
  const popUpok = (value) => {
    setOpenPop(false);
  }

  const openDialogs = (id) => {

    if (id == '3') {
      modalPopValue.title = "Terms & Conditions";
      modalPopValue.message = "Terms & Conditions";
      modalPopValue.popUpId = id;
    }

    setModalPopValue({ ...modalPopValue });
    setOpenPop(true);
  }
  const openModalPopUp = (data) => {
    modalPopVal.message= 'Please use the chat if you need to reset the password';
    modalPopVal.title= "Reset Password";
    modalPopVal.value= { data };
    modalPopVal.popUpId= 1;
    setModalPopVal({...modalPopVal})
    setModalPopup(true);
};
  const closePopup = () => {
    setOpenPop(false);
    setModalPopup(false);
  }


  // popup dialog close



  const [tandc, setTandc] = useState(false);
  const [err, setErr] = useState(false);
  const [invalidAuth, setInvalidAuth] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const handleClickShowPassword1 = () => { setShowPassword1(!showPassword1); }
  const fromGuest = props.location?.state?.email ? props.location?.state?.email : '';

  const handleClick = (e) => {
    setErr(true);
    e.preventDefault();
  }

  const terms = () => {
    setTandc(!tandc);
    setErr(tandc);
    console.log('tandc', tandc)
  }

  const [showDrawer, setshowDrawer] = useState(false);
  console.log("text", showDrawer);
  const closeDrawer = () => {
    setshowDrawer(false);
  }

  const labelText = <span>By clicking on signup now for free you agree on the ChannelHub's <span className="tandc" onClick={(e) => { e.preventDefault(); openDialogs('3') }}>Terms and Conditions</span></span>;
  const labelErrText = <span style={{ color: 'red' }}>By clicking on signup now for free you agree on the ChannelHub's <span className="tandc" onClick={(e) => { e.preventDefault(); openDialogs('3') }}>Terms and Conditions</span></span>;

  const [apptoken, setApptoken] = useState("");
  // const { loggingIn } = props;      

  const { register, handleSubmit, control, errors } = useForm({
    //    mode:'onBlur',
    resolver: yupResolver(loginschema)
  });
  const onSubmit = async (data) => {
    if (data) {
      //  let res = await props.login(data.email,data.password,apptoken);
      signIn(data.email, data.password, apptoken);
      //  console.log('resp', res);
      //console.log("data:",data);
    }
  }
  const signIn = async (username, password, apptoken) => {

    const localStorageService = LocalStorageService.getService();
    userService.login(username, password, apptoken)
      .then(
        async user => {
          // dispatch(success(user));

          // dashboardService.dashboard_count()
          //       .then(
          //              dasboard_count => dispatch(count(dasboard_count)),                          
          //       );


          localStorageService.setToken(user.data.token);
          let lastLogin = user.data.user.lastLogin;
          var lastLogindate=new Date(lastLogin).getDate();
          var lastLoginmonth=new Date(lastLogin).getMonth()+1;
          var lastLoginyear=new Date(lastLogin).getFullYear();
    
          var startDate = Moment(lastLogindate+'.'+lastLoginmonth+'.'+lastLoginyear, "DD.MM.YYYY");
          var todate=new Date().getDate();
          var tomonth=new Date().getMonth()+1;
          var toyear=new Date().getFullYear();
          var endDate = Moment(todate+'.'+tomonth+'.'+toyear, "DD.MM.YYYY");
          let difDay = endDate.diff(startDate, 'days');
          console.log('diss', difDay);
          if(difDay>=90)
          localStorage.setItem('buyerPopup', '1');
          else
          localStorage.setItem('buyerPopup', '0');
          let channelView = localStorage.getItem('channelView');

          if (user.data.user.signUpStatusId === "14")
            history.push('/Pending');
          if (user.data.user.signUpStatusId === "15" && user.data.user.lastLogin == null) {
            history.push('/Verification');
          }
          if (user.data.user.signUpStatusId === "15" && user.data.user.lastLogin != null) {
            if (eventId || props.match.params?.id == '1')
              history.replace('/admin/events');
            else if (channelView)
              history.push(channelView);
            else
              history.push('/admin/');
          }
          // if (user.data.user.signUpStatusId === "15" && user.data.user.isAlgorithm == null) {
          //   axios.get('algorithm/channel/' + user.data.user.contactId);
          //   if (user.data.user.channel?.channelTypeId != 2) {
          //     await axios.get('algorithm/product/' + user.data.user.contactId);
          //     //axios.post('mailerservice/Product_Suggestion_Mail',{contactId:user.data.user.contactId,channelId:user.data.user.contactId});
          //   }
          // }

          return true;
        },
        error => {
          //console.log("loginerr:",error);
          setInvalidAuth(true);
          return false;
        }
      );
    // };
  }
  useEffect(() => {
    const loggedIn = localStorage.getItem('user');
    if (loggedIn) {
      const user = JSON.parse(loggedIn);
      setApptoken(user.token);
      history.replace('/admin/dashboard');
    }

    const localStorageService = LocalStorageService.getService();
    axios.post('/app', {
      clientKey: "clientAppFE",
      secretKey: "!$Ch@nnelHub#*&"
    }).then(res => {
      localStorageService.setAppToken(res.data.Authorization);
      setApptoken(res.data.Authorization);
    });
  }, []);
  
  if (localStorage.getItem('fo-auth-token')) {
    history.replace('admin/dashboard');
    return null;
  }
  else
    return (
      <div class="main-content">
        <Dialog open={isModalPopup} value={modalPopVal} handleSave={closePopup} handleClose={closePopup}></Dialog>
        <GuestPopup open={openPop} value={modalPopValue} handleSave={popUpok} handleClose={closePopup} />
        <div className="head-logo mt-2">
          <Link to="/" >
            <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} className="img-fluid" alt="ch-logo" src={require("../../../assets/img/medialibrary/no-data.png")} className="navImg" alt="ch-logo" src={require('assets/img/brand/ch-logo.svg')} />
          </Link></div>
        <div class="header signup-header-bg loginpad">
          <div class="container">
            <div class="header-body text-center mb-4">
              <div class="row justify-content-center">
                <div class="col-xl-10 col-lg-6 col-md-8 px-5">
                  <h1 class="text-white">Welcome!</h1>
                  <p class="text-lead text-white">ChannelHub is the game changer for Consumer Tech and IT Channels.
                    ChannelHub is a business network connecting vendors, distributors, retailers and resellers around the globe dramatically squeezing time-to-market and lowering prospection and scouting costs</p>
                </div>
              </div>
            </div>
          </div>
          <div class="separator separator-bottom separator-skew zindex-100">
            <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
              <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
            </svg>
          </div>
        </div>

        <div class="container logmarginbotom pb-5">
          <div class="row justify-content-center">
            <div class="col-lg-5 col-md-7">
              <div class="card bg-secondary border-0 mb-0">

                <div class="card-body px-lg-5 py-lg-5">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div class="form-group mb-3" >
                      <div class="input-group input-group-merge input-group-alternative">
                        <FormInput
                          id="email"
                          type="email"
                          name="email"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Email />
                              </InputAdornment>
                            ),
                          }}
                          placeholder="Email Id *"
                          register={register}
                          error={errors.email}
                          defaultValue={fromGuest}
                        />
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="input-group input-group-merge input-group-alternative">
                        <FormInput
                          id="password"
                          type={showPassword1 ? "text" : "password"}
                          name="password"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Lock />
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword1}
                                // onMouseDown={handleMouseDownPassword}
                                >
                                  {showPassword1 ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                          placeholder="Password *"
                          register={register}
                          error={errors.password}
                        />
                      </div>
                    </div>

                    {/* <span className="alert-icon">
                          <i className="ni ni-like-2" />
                        </span>
                        <span className="alert-text ml-1">
                          <strong>Warning!</strong> This is a warning alert—check it
                          out!
                        </span> */}
                    {invalidAuth && <p style={{ color: "red", textAlign: "center", fontSize: '.9rem', fontWeight: 600, marginTop: '-1rem' }}>!Please check your email and password</p>}


                    {/* <FormCheckBox 
                    id="customCheckLogin"
                    type="checkbox"
                    label="Remember me"
                    register={register}
                    /> */}
                    {/* <a className="forgot" href="dev-fo.channelhub.tech">Forgot Password</a> */}
                    {/* Please use the chat if you need to reset the password */}
                    <a className="forgot" 
                    onClick={() => { setshowDrawer(true) }}
                    // onClick={() => { openModalPopUp(true) }}
                     style={{ cursor: "pointer" }}>Forgot Password?</a>

                    <SignupDrawer title="Forgot Password" visible={showDrawer} closeDrawer={closeDrawer} />
                    {/* <Row className=""> */}

                    <FormButton
                      className="btn-sin btn btn-primary"
                      id="loginbutton"
                      name="loginbutton"
                      value="Sign in"
                    />

                    <div class="text-center login-btn">
                      <hr className="login-hr" />

                      <div className="signup-col">
                        <span>Not registered yet? </span><br />
                        <span>create your account in 2 minutes for free</span>

                        <div className="agree">
                          <Link to="/signup" onClick={!tandc ? handleClick : ''} className="btn btn-link"><button class="btn btn-primary" type="button">Signup now for free</button></Link>
                          {/* <SignupDrawer text="Signup now for free" title="Create Your Account"/> */}
                        </div>
                        <div className="chck">
                          <FormCheckBox
                            id="Terms-conditions"
                            type="checkbox"
                            label={err ? labelErrText : labelText}
                            label1={{ textAlign: "justify" }}
                            register={register}
                            onClick={terms}
                          /></div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}


function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login
};

const connectedLoginPage = connect(mapState, actionCreators)(Login);
// function count(dasboard_count) { return { type: dashboardConstants.COUNT_SUCCESS, dasboard_count } }
export { connectedLoginPage as Login };
