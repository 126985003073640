import React, { useState, useEffect, useRef } from 'react';
import SimpleHeader from "components/Headers/SimpleHeader.js";
import {Card,CardBody,Row,Col,Container,CardHeader, Button, UncontrolledTooltip } from "reactstrap";
import { commonService } from '_services/common.service';
import Slider from "react-slick";
import { Spinner } from "views/Hoc/Spinner";
import { KeyProducts } from "views/Hoc/keyBrands/KeyBrands";
import { useDispatch } from 'react-redux'
import ReactImageMagnify from 'react-image-magnify';
import {ProductVideoPlayer} from 'views/Hoc/Dialog/ProductVideoPlayer';
import GuestNavBar from 'views/pages/GuestPage/GuestNavbar/GuestNavbar';
import Guestslide from 'views/pages/GuestPage/GuestNew/GuestsliderProductOnly'
import ProductSlider from "../GuestPage/GuestNew/productSlider";
import GuestReview from "../GuestPage/GuestNew/GuestReview";
import GuestViewContact from "../../Hoc/Dialog/GuestViewContact";
import GuestContact from "../../pages/GuestPage/GuestContact";
import Footer from '../GuestPage/GuestNavbar/Footer';
import GuestFooter from "../GuestPage/GuestNavbar/GuestFooter";
import { ProductDetailImg } from 'views/Hoc/Dialog/ProductDetailImg';
import GuestprodSpec from '../GuestPage/GuestNew/GuestProdSpec';
import { SocialShareDialog } from "views/Hoc/Dialog/SocialShareDialog";
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Helmet from 'react-helmet';
import Zoom from '@material-ui/core/Zoom';
import axios from 'axios';
import { browserName, browserVersion } from "react-device-detect";
    const PublicProductDetail = (props) => {
      const dispatch=useDispatch();
      let [loading, setLoading]  = useState(true);
      let [isEvent, setEvent]  = useState(false);
      const [image, setImage] = useState();
      let [productDetails, setChannelDetails ] = useState(null);
      let [imageGallery, setImageGallery] = useState([]);
      let [isVideo, setVideoOpen]  = useState(false);
      let [videoUrl, setVideoUrl]  = useState(null);
      let [selectedImg, setSelectedImg] = useState({cat:'Product', index:1});
      let [productList, setProductList] = useState([]);
      let [similarProduct, setSimilarProduct] = useState([]);
      let [productImg, setproductImg] = useState(false);
      let [ImageIndex, setindex] = useState(1);
      let [isSocialShare, setSocialShareOpen] = useState(false);
      let [shareData, setShareData] = useState([]);
      const [login, setLogin] = useState(false);
      const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        arrows: true,
        vertical: true,
        verticalSwiping: true,
    
      };
      useEffect(() => {        
        setLoading(true);
        if(props.match.path ==='/productView/:id'){
          isEvent=true;
          setEvent(isEvent);
        }
        getProductDetails();
        setTimeout(() => {
          imageZoom("myimage", "myresult");              
        }, 7000);

    },[props.location.pathname] )


      //for product description

      const productDescRef = useRef(null);
      const uspRef = useRef(null);

      useEffect(() => {
        if(productDescRef.current){
          
          // let desc = productDetails?.productDetail?.productdesc ? productDetails?.productDetail?.productdesc.replace(/<[^>]+>/g, ' ') : '';
          // productDescRef.current.innerHTML = '<p>'+desc?.replace(/\.\s/g, '.</p><p>')+'</p>';

          let desc = productDetails?.productDetail?.productdesc ? productDetails?.productDetail?.productdesc.replace(/<[^>]+>/g, ' ') : '';
          productDescRef.current.innerHTML=desc;
          // desc = desc?.split('.');
    
          // desc = desc?.splice(0, desc?.length-1);
          // let temp = '';
          // desc.map(x => {
          //   if (x?.trim()?.length > 1)
          //     temp += '<li>' + x + '.</li>'
          // });
          // console.log('prod desc', desc, temp);
          // productDescRef.current.innerHTML = '<ul>' + temp + '</ul>';
        }

        if(uspRef.current){
          let temp = productDetails?.productDetail?.usp.replace(/<[^>]+>/g, '');
          
          uspRef.current.innerHTML = temp;
        }       
        updatenavigation();
      }, [productDescRef.current, uspRef.current])

      //end product description
      

      const updatenavigation= async()=>{
        let navigationData = {}
        navigationData.browser = browserName + ' , Version :'+browserVersion;;
        navigationData.navigatedPage = window.location;
        axios.post('/guest/updateNavigationHistory', navigationData);
      }
      const getProductDetails= async()=>{
        let res = await commonService.getServices('public/product/'+ props.match.params.id).catch(err =>console.log('err', err));
          if(res){
            productDetails = res.data;
            setChannelDetails(productDetails);
            setGalleryImage();
            setImage(productDetails?.defaultImage?.image?.documentUrl);
            getChannelProduct(productDetails.channelId);
            getSimilarProduct(productDetails.channelId);
            setLoading(false);
          }
      }
      const getSimilarProduct= async(channelId)=>{
        let res = await commonService.getServiceWithParams({isPublic:!isEvent},'public/channel-similarproducts/'+ channelId).catch(err =>console.log('err', err));
          if(res){
            similarProduct = res.data;
            setSimilarProduct(similarProduct);
          }else{
            similarProduct = [];
            setSimilarProduct(similarProduct);
          }
      }
      const getChannelProduct= async(channelId)=>{
        let res = await commonService.postService({excludedProductSku:props.match.params.id, isPublic:!isEvent}, 'public/channel-products/'+ channelId).catch(err =>console.log('err', err));
          if(res){
            productList = res.data;
            setProductList(productList);
          }else{
            productList = [];
            setProductList(productList);
          }
      }
      const setGalleryImage=()=>{
        imageGallery=[];
        if(productDetails?.images?.PRODUCT)
        imageGallery.push(...productDetails?.images?.PRODUCT);
        if(productDetails?.images?.DISPLAY?.length>0)
        imageGallery.push(...productDetails?.images?.DISPLAY);
        if(productDetails?.images?.PACKING?.length>0)
        imageGallery.push(...productDetails?.images?.PACKING);
        if(productDetails?.productDetail?.videourl && productDetails?.productDetail?.videourl!=='')
        imageGallery.push({imageid:0, image:{documentUrl:productDetails?.productDetail?.videourl.replace("watch?v=", "embed/"), documentTypeId:3}});
        setImageGallery(imageGallery);
      }
        
          const previewVideo =(urllink)=>{
            const samp = urllink.match(/^https?:\/\/([\w-]+\.\w{2})\/([\w-]{11})?/);
            console.log('samp',samp);
            if (samp) {
              if (samp[1] === 'youtu.be') {
                let embedLink = 'https://www.youtube.com/embed/';
                urllink = embedLink + samp[2];
              }
              console.log(samp);
            }

            videoUrl = urllink;
            setVideoUrl(videoUrl);
            setVideoOpen(true);
          }
          const videoModalClose = () => {
            setVideoOpen(false);
          };

          const imageZoom=(imgID, resultID)=> {
            var img, lens, result, cx, cy;
            img = document.getElementById(imgID);
            result = document.getElementById(resultID);
            lens = document.createElement("DIV");
            lens.setAttribute("class", "img-zoom-lens");
            if (!img) {
              setTimeout(() => {
                imageZoom("myimage", "myresult");              
              }, 5000);
          
            }else{

         
            img.parentElement.insertBefore(lens, img);
            cx = result.offsetWidth / lens.offsetWidth ;
            cy = result.offsetHeight / lens.offsetHeight ;
            result.style.backgroundImage = "url('" + img.src + "')";
            result.style.backgroundSize = (img.width * cx) + "px " + (img.height * cy) + "px";
            const moveLens=(e)=> {
              var pos, x, y;
              e.preventDefault();
              pos = getCursorPos(e);
              x = pos.x - (lens.offsetWidth / 2);
              y = pos.y - (lens.offsetHeight / 2);
              if (x > img.width - lens.offsetWidth) {x = img.width - lens.offsetWidth;}
              if (x < 0) {x = 0;}
              if (y > img.height - lens.offsetHeight) {y = img.height - lens.offsetHeight;}
              if (y < 0) {y = 0;}
              lens.style.left = x + "px";
              lens.style.top = y + "px";
              result.style.backgroundPosition = "-" + (x * cx) + "px -" + (y * cy) + "px";
            }
            lens.addEventListener("mousemove", moveLens);
            img.addEventListener("mousemove", moveLens);
            lens.addEventListener("touchmove", moveLens);
            img.addEventListener("touchmove", moveLens);
            
            const getCursorPos=(e)=> {
              var a, x = 0, y = 0;
              e = e || window.event;
              a = img.getBoundingClientRect();
              x = e.pageX - a.left;
              y = e.pageY - a.top;
              x = x - window.pageXOffset;
              y = y - window.pageYOffset;
              return {x : x, y : y};
            }
          }
        }
        const SetImages=(e,val, cat, i)=>{
            e.preventDefault();
            setImage(val);
            selectedImg.cat = cat==='Image'?'Product':cat==='Packing'?'Packaging':'Display';
            selectedImg.index = i;
            setSelectedImg(selectedImg);
            }
            const handleClose=()=>{
              setLogin(false);
            }
      const imageProps = {
        smallImage: {
          alt: 'Phasellus laoreet',
          isFluidWidth: true,
          src: image,
          width: '100%',
          height: '100%'
        },
        largeImage: {
          src: image,
          width: 1200,
          height: 1800
        },
        enlargedImageContainerDimensions: {
          width: '100%',
          height: '100%'
      },
        enlargedImageContainerStyle: { background: '#fff', zIndex: 9 },
      };
      const openLogin=()=>{
        localStorage.removeItem('guest-product-fav', props.match.params.id);
        localStorage.removeItem('guest-channel-fav', props.match.params.id);
        localStorage.setItem('guest-product-fav', props.match.params.id);
        setLogin(true);
      }

      const openChannelDetail = (id) => {
        let win = window.open('/channelDetailView/'+id);
        win.focus();
      }
      const closeImg=()=>{
        console.log('productImg',productImg,'img',image)
        productImg=!productImg;
setproductImg(productImg)
      }
      const OpenImg=()=>{
setproductImg(true)
      }
      const preImages=(e)=>{
        if (imageGallery.length>ImageIndex  && ImageIndex>0) {
          let val=ImageIndex-1;
        setindex(val);
        setImage(imageGallery[ImageIndex].image?.documentUrl);
        }else{
          setindex(0);
          setImage(imageGallery[0].image?.documentUrl);
        }
      }
      const nextImages=(e)=>{
    
        if (imageGallery.length-1>=ImageIndex) {
          let val=ImageIndex+1;
        setindex(val);
        setImage(imageGallery[ImageIndex].image?.documentUrl);
      }else{
        setindex(0);
        setImage(imageGallery[0].image?.documentUrl);
      }
      }

      const socialShareModalClose = () => {
        setSocialShareOpen(false);
      };
      const OpenSocialShareDialog = () => {
        let productData = {'title' : productDetails?.product , 'url': window.location.href,  'SKU': props.match.params.id}
        setShareData(productData);
        setSocialShareOpen(true);
      }
      
const [open, setOpen] = React.useState(false);

const handleTooltipClose = () => {
  setOpen(false);
};

const handleTooltipOpen = () => {
  OpenSocialShareDialog();
  setOpen(true);
};

// const HtmlTooltip = withStyles((theme) => ({
//   tooltip: {
   
//     maxWidth: "100%",
//     borderRadius: "0px",
//   backgroundColor: "white",
//   color: "black",
//   padding: "0px 0px",
//   boxShadow: "0 15px 35px rgb(50 50 93 / 20%), 0 5px 15px rgb(0 0 0 / 17%)",
//   border: "0 solid rgba(0,0,0,.2)",
//   borderRadius: ".4375rem",
//   outline: "0",
//   },
// }))(Tooltip);
        return (
            <div className="main-content">
              {/* added for share image section */}
              <Helmet>
                {/* <title>Channel Hub {productDetails?.product}</title> */}
                <meta name="title" content={productDetails?.product}/>    
                <meta property="og:title" content={productDetails?.product}/>
                <meta name="description" content={productDetails?.productDetail?.usp.replace(/<[^>]+>/g, '')} />
                <meta property="og:description" content={productDetails?.productDetail?.usp.replace(/<[^>]+>/g, '')} />
             {/*<meta property="og:image" content='https://d35w8j22j5uly8.cloudfront.net/siteimages/apple-touch-icon.png' /> */}
                <meta property="og:url" content={window.location.href} />
               </Helmet>
               {/* added for share image section */} 
              <GuestNavBar/>
              <ProductVideoPlayer videoModalClose={videoModalClose} isOpen={isVideo} url={videoUrl}/>
              
              <ProductDetailImg handleClose={closeImg} isOpen={productImg} ProductImgs={image} />
            {loading?<Spinner/>:
            <Container className="mt-4" fluid>
        <div id='product-detail'>
        <Row >
          <Col className="card-wrapper" lg="1" md="1" sm="1" xs='4' >
        
          <Card  style={{height:"97%"}}>
     
        
          <CardBody className="p-1">
        
          <div  class="d-flex flex-column bd-highlight  mb--3  pb-5 pt-5" style={{    overflow: "hidden"}}>
          <Slider {...settings}>
        
          {imageGallery?.length>0 && imageGallery?.map((item, i)=>{
            return(
              <div className="product-imgs mb-3 active-img">
                {item?.image?.documentTypeId===3?
                <div onClick={()=>previewVideo(item?.image?.documentUrl)}>
                  <i style={{fontSize: '2rem',position:'absolute',top:'20%',left:'38%',color: '#FF6347'}} class="fa fa-play-circle" aria-hidden="true"></i>
                  <video src={item?.image?.documentUrl} style={{width:'100%', height:'auto'}}></video>
                  </div>
                :
                <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  className="img-fluid" alt="ch-logo" src={require("../../../assets/img/medialibrary/no-data.png")}  alt="...." onClick={ e => SetImages(e,item?.image?.documentUrl, item?.productimagetype, i)} src={item?.image?.documentUrl}/>}
              </div>
             )})}

</Slider>
</div>
               </CardBody>
           </Card>

           </Col>

           <Col lg="6" md="5" sm="5" xs='8'>
          <Card className="mb-2"  style={{height:"97%"}}>
          <CardBody className="p-1 prod-img productdetImg" style={{textAlign: "-webkit-center"}}>
          <i class="fa fa-arrow-left leftarr pointer"  onClick={ e => preImages(e)} aria-hidden="true"></i>
        {/* <ReactImageMagnify {...imageProps} /> */}
        <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  className="img-fluid" alt="ch-logo" src={require("../../../assets/img/medialibrary/no-data.png")}   onClick={() => {OpenImg()}} src={image}/>
        <i class="fa fa-arrow-right rightar pointer" aria-hidden="true" onClick={ e => nextImages(e)}></i>

               </CardBody>
           </Card>
           </Col>
           <Col className="pl-0" lg="5" md="4" sm="4" >
          <Card   style={{height:"97%"}}>
          <CardBody className="p-5">
    <div id="pprodh">
      
        <h1 className="mb-2 text-center font-weight-500">{productDetails?.product}</h1>
        <h3 style={{cursor: 'pointer'}} onClick={() => {openChannelDetail(productDetails?.channelId)}}>Vendor : <span className="gestcomptitle">{productDetails?.companyName}</span></h3>
        <h3>Short Description : <span className="gestcomptitle prodheadfontsize">{productDetails?.productDetail?.productshortdec}</span></h3>
        {productDetails?.productDetail?.usp && <h3>Tweet Pitch :  <span ref={uspRef} className="gestcomptitle"></span> </h3>}
        {/* <h3 className="mt-3" style={{textAlign:'justify'}}>{productDetails?.productDetail?.productshortdec}</h3> */}
        <h3 className="mt-4 mb-4">
        Suggested Retail Price: <span className="gestcomptitle prodheadfontsize">{productDetails?.productPriceDetail?.businesscurrencyid==='1'?' €':' $'} {productDetails?.productPriceDetail?.retailerprice}</span>
       </h3>

    </div>

<div className="d-flex justify-content-between mt-2">
<div>
<h3> Category:<h3 className="ml-2 mr-4 d-inline gestcomptitle prodheadfontsize" >
  {productDetails?.productCategory?.productCategorie}
 </h3>
 {/* Product Position:<h3 className="ml-2 d-inline" style={{color:'#747475'}}>
   {productDetails?.productqualitylevel === 'Entry' && 'Entry Level'}
   {productDetails?.productqualitylevel === 'Mid' && 'Mainstream'}
   {productDetails?.productqualitylevel === 'High' && 'Premium'}
 </h3> */}
 </h3>

  
</div>

</div>
{productDetails?.productDetail?.productCertifi &&<> <h3 className="mt-3">Product Certifications</h3>
        <h3 className="mt-1" style={{textAlign:'justify'}}>{productDetails?.productDetail?.productCertifi}</h3></>}
{productDetails?.productAwards?.length>0 && <div style={{overflow:'hidden'}}> <KeyProducts title="Awards" data={productDetails?.productAwards}/></div>}
{/* {productDetails?.productKeyRetailer?.length>0 &&<div style={{overflow:'hidden'}}>  <KeyProducts title="Key Retailers" data={productDetails?.productKeyRetailer} clickKeyRetailer={clickKeyRetailer}/></div>}

{productDetails?.productKeyReseller?.length>0 &&<div style={{overflow:'hidden'}}>  <KeyProducts title="Key Resellers" data={productDetails?.productKeyReseller} clickKeyRetailer={clickKeyRetailer}/></div>} */}
    <div class='d-flex align-items-center mt-6'>
        <Button className='butn-f pt-2' onClick={openLogin}><i class="fas fa-user-plus mr-2"></i>Follow</Button>

        {/* <div class='d-flex productIcon mt-6'> */}
    <div class='ml-3'>
    {/* <ClickAwayListener onClickAway={handleTooltipClose}>
            <div className="socialboxed">
              <HtmlTooltip arrow TransitionComponent={Zoom}
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={handleTooltipClose}
                open={open}
                disableFocusListener
                disableHoverListener
               
                title={
                  <React.Fragment>
                    <SocialShareDialog data={shareData}/>
                  </React.Fragment>
                }
              >
                   <div data-placement="top" id="Share" className="Icon2" onClick={handleTooltipOpen}>
                      <i class="fas fa-share-alt"></i>
                    </div>
    
              </HtmlTooltip>
            </div>
          </ClickAwayListener> */}
                    {/* <UncontrolledTooltip delay={0} placement="top" target="Share">Share with a Colleague</UncontrolledTooltip> */}

                  </div>
    </div>
    

    
               </CardBody>
           </Card>
           </Col>
           </Row>
<Row>
<Col lg="12">
<Card>
        <CardHeader className="p-3">
        <h2 className="text-center m-0 text-uppercase">Product Details</h2>
        </CardHeader>
        </Card></Col>
    <Col lg="12">
         <Card>
          <CardBody>
            {/* <h3 className="mb-4">Product Details</h3> */}
                <h3 className="prodcompanytitle">Product Description</h3>
                <p ref={productDescRef} className="description text-justify">
                {/* {productDetails?.productDetail?.productdesc&&productDetails?.productDetail?.productdesc.replace(/<[^>]+>/g, ' ')} */}
                </p>
          </CardBody>
        </Card>
         </Col>
         </Row>
         </div>
         
    {(productList.length>0||similarProduct.length>0)&&<Card>

      {productList.length>0&&<><CardHeader style={{border:'none'}}>
        <h2 className='text-uppercase text-center'>More Products from {productDetails?.companyName}</h2>
      </CardHeader>
      <ProductSlider products={productList} />
       </>}
     {similarProduct.length>0&&<>
     <CardHeader style={{border:'none'}}>
        <h2 className='text-uppercase text-center'>Similar Products</h2>
      </CardHeader>
      <ProductSlider products={similarProduct} /></>}
    </Card>}
    {/* {similarProduct.length>0&&<Card>
      <CardHeader className='p-3'>
        <h2 className='text-uppercase text-center'>Similar Products</h2>
      </CardHeader>
      <ProductSlider products={similarProduct} />
    </Card>} */}
    {!isEvent&&<div id='product-detail' className="main-content">
    <GuestViewContact type="buyer" open={login} handleClose={handleClose}/>
    <GuestprodSpec/>
    <GuestReview type={2} productSku={props.match.params.id} productId={productDetails?.productId}/>
    <GuestContact/>
    <div className="guestDividcontainer"> 
                <div className="GuestDivider">

                </div>
                </div>
    <GuestFooter/>
    </div>}
    </Container>
    }
            </div>
         );
    }
export default PublicProductDetail;