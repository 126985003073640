import React, { useEffect, useState } from 'react';
import { Button, Modal } from "reactstrap";
import { Card, CardBody } from "reactstrap";
import { history } from '../../../_helpers/history';
import axios from 'axios';
import { FormAutocompleteGrouping } from "_components/FormElements/FormInput";
import { commonService } from '_services/common.service';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { BuyerPopupCatSchema } from '../../pages/SignupPage/Validation';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { Spinner } from 'views/Hoc/Spinner';
var _ = require('lodash');
const BuyerInitial = (props) => {
  const { isOpen, handleClose } = props;
  const [loading, setLoading] = useState(false);
  var userData = JSON.parse(localStorage.getItem('CHuser'));
  let typeId = parseInt(userData?.channelTypeId);
  let contactId = parseInt(userData.contactId);
  let [state, setState] = useState({productOptions:[], productValue:[]});
  let logo = 'http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg';
  const { register, control, errors, handleSubmit } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(BuyerPopupCatSchema),
  });
  useEffect(() => {
    if(isOpen)
    setProductFamilies();
  }, [isOpen]);

  const setProductFamilies = async() => {
    setLoading(true);
        const apiUrl = '/setting/getInitialProfile';
        await axios.get(apiUrl).then(res => {
          state.productOptions = res.data.productGroup;
          setState({...state});
        }).catch(err => {
          setLoading(false);
          commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: 'setting/getInitialProfile' }, '/error');
        });
        await axios.get('/setting/getContactProduct/' + contactId).then(res => {
          console.log('contatc', res.data);
          state.productValue = res.data.productGroup;
        }).catch(err => {
          setLoading(false);
          commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: 'setting/getContactProduct' }, '/error');
        });
        state.productOptions = state.productOptions.map(x => {
          state.productValue.map(y => {
            if (x.groupid == y.groupid && x.childid == y.childid)
              x.status = true;
          })
          return x;
        })
        setState({...state});
        setLoading(false);
  }
  const handleChangeSelect = (input, value) => {
        console.log(input, value)
        if (input === 'product') {
          state.productOptions.map((item) => {
            if (value.length === 0)
              item.status = false;
            else {
              if (value.filter(e => e.childname == item.childname).length)
                item.status = true;
              else
                item.status = false;
    
            }
          });
          value.map(option => option.status = true);
          state.productValue = value
          setState({ ...state });
    
          if (state.productValue.length === 0)
            errors.productCat = true;
        }
  }

  const selectedItem = (gname, check, optionName, optionValue) => {
        let opt = [];
        console.log("check:", check, gname);
        if (check === true) {
          state[optionName].map((item) => {
            console.log("groupname:", item.groupname);
            if (item.groupname === gname || item.status === true) {
              item.status = check;
              opt.push(item);
            }
          });
        }
        if (check === false) {
          console.log("check:", check);
          state[optionName].map((item) => {
            if (item.groupname === gname && state[optionValue].length > 0)
              item.status = check;
          });
          opt = state[optionValue].filter((item) => item.groupname !== gname);
          opt.map(option => option.status = true);
        }
        state[optionValue] = opt;
        setState({ ...state, [optionValue]: state[optionValue] });
        setState({ ...state, [optionName]: state[optionName] });
    
        if (optionValue === 'productValue' && state.productValue.length === 0)
          errors.productCat = true;
  }
  const save = async() =>{
    try{
      setLoading(true);
      let trade = state.productValue;
      
      let productCategories = [];
      let productFamilies = [];
      trade.map(x => {
        let obj = {
          productFamilId: x.groupid,
          productCategoriesId: x.childid
        }
        productCategories.push(obj);
      });
      productFamilies = _.uniq(trade.map(x => +x.groupid));
      productFamilies = productFamilies.map(x => ({ id: x }));
      let data = {
        productFamilies:productFamilies,
        productCategories: productCategories
      }
      await axios.patch('user/interestProduct/' + contactId, data).then(res=>{
        setLoading(false);
        ToastsStore.success('Saved successfully!...');
        handleClose()
      }).catch(err => {
        setLoading(false);
        commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: 'setting/buyerpopupproductcat' }, '/error');
      });
    }
    catch (err) {
      console.log("err:", err);
      ToastsStore.error('Something Went Wrong!');
      setLoading(false);
      commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: 'LoginBuyerPopup' }, '/error');
    }
  }
  return (
    <div>
      {loading && <Spinner />}
      <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore} />
      <Modal
        className="modal-dialog-centered"
        isOpen={isOpen}
        size="lg"
      >
        <form noValidate onSubmit={handleSubmit(save)}>
        <div className="modal-header">
          {/* <h3>Connect</h3> */}
          <h2>Please update your product categories of interest</h2>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={handleClose}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body  d-flex justify-content-center pt-1 pb-1">
          <Card className="w-100 mb-0">
            <CardBody className='p-0'>
                <p>Our matching algorithm will propose you both vendors and product suggestions. The algorithm is using a combination of criteria (marked with <span id='algorithm1'><i class="far fa-handshake didy1"></i></span>
                      such as geography, channel profile, interest for startup product etc..)</p>
                <p>But most important are the <u><b className="curs-point" onClick={()=>{
                  localStorage.setItem('buyerPopup', '0');
                  history.push('/admin/profileform')
                  }}>product categories you are interested in</b></u>. And this is a user-based criteria and not company-based. Therefore it’s important to review this section in order to receive the most relevant matches.</p>
                {/* <div className='prodinter mb-4'><img className='img-fluid' src={require("assets/img/channelDetail/prodinter.png")} /></div> */}
                <div className="col-lg-12 ">
                  <div className="form-group">
                  <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="productCat" name="productCat" ref={register} value={state.productValue.length ? state.productValue[0].childname : ''} />
                  <FormAutocompleteGrouping
                  id="ProductResponsibilities"
                  name="ProductResponsibilities"
                  label="Product Interest"
                  Options={state.productOptions}
                  optionName='productOptions'
                  optionValue='productValue'
                  placeholder="Product Interest"
                  errMessage='Please Select Product'
                  error={!errors.productCat || state.productValue.length ? false : true}
                  defaultValue={state.productValue}
                  value={state.productValue}
                  input="product"
                  selectedItem={selectedItem}
                  handleChangeSelect={handleChangeSelect}
                  control={control}
                  register={register}
                  />
                  </div>
                  </div>
                <p>You can find this section under “<b className="curs-point" onClick={()=>{
                  localStorage.setItem('buyerPopup', '0');
                  history.push('/admin/profileform')
                  }}>My Profile</b>” and update the list at your convenience.</p>
            </CardBody>
          </Card>


        </div>
        <div className="modal-footer">
        <Button color="primary" type="submit">
                      Save
                    </Button>
          <Button
            color="secondary"
            data-dismiss="modal"
            type="button"
            onClick={handleClose}
          >
            Close
          </Button>

        </div>
        </form>
      </Modal>
    </div>
  );
}
export default  BuyerInitial;