import React, { useEffect, useState, useMemo } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import  "_components/Stripe/styles.scss";
import {ToastsContainer, ToastsStore} from 'react-toasts';
import { subscriptionAction } from '_actions/subscription.action';
import { connect, useSelector  } from 'react-redux';
import { commonService } from '_services/common.service';
import { history } from '../../../../_helpers';
import { Spinner } from "views/Hoc/Spinner";
import { Card,CardBody,FormGroup,Form, Container,Col,Row,Table,Button } from "reactstrap";
import PaypalExpressBtn from 'react-paypal-express-checkout';
import useResponsiveFontSize from "_components/Stripe/useResponsiveFontSize.js";
import { eventService } from '_services/event.service';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY);

const useOptions = () => {
    const fontSize = useResponsiveFontSize();
    const options = useMemo(
      () => ({
        style: {
          base: {
            fontSize,
            color: "#424770",
            letterSpacing: "0.025em",
            fontFamily: "Source Code Pro, monospace",
            "::placeholder": {
              color: "#aab7c4"
            }
          },
          invalid: {
            color: "#9e2146"
          }
        },
        hidePostalCode: true
      }),
      [fontSize]
    );
  
    return options;
  };
const CardFormInter = (props) =>  {
  const {id}=props
  var url_string =  window.location.href
    var url = new URL(url_string);
    var params = url.searchParams.get("id");
    
    console.log('pra',props.type);
    let event_redux = useSelector(state => state.event);
    console.log('event_redux', event_redux)

  const stripe = useStripe();
  
  const elements = useElements();
  let [loading, setLoading] = useState(false);
  const options = useOptions();
  let [iscardComplete, setIsCardComplete] = useState(false);
  let userData = JSON.parse(localStorage.getItem('event'));
  let src_Charge = null;
  let [paymentType, setPaymentType] = useState(props.type);
  const [state,setState]=useState({
    rcamount:JSON.parse(localStorage.getItem('loc_rc_amount'))
    
  }); 
 // this.state = {transactionId: null};
  useEffect(() => {
    //const subId = redux_data.select_plan;
    // if(!subId){
    //   history.push('/admin/Subscription');
    //   return;
    // }    
    
            
            checkAlipayAuthResponse();
      }, [])
  const handleSubmit = async event => {
        event.preventDefault();

        if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
        }
        AlipaycreateSource();
  };


    // first create subscription to check is valid subscription
    // if subscription is allowed then create source
    const AlipaycreateSource =async () => { 
        localStorage.setItem('pay_amt', (state.rcamount));
        const paymentSource =  await stripe.createSource({
            type: 'alipay',
            amount: state.rcamount * 100,
            currency: 'eur',
            redirect: {
            return_url: window.location.origin+'/walletRecharge/'+state.rcamount,
            },
        }).then(function(result) { console.log(result);
            if(result.error){
            console.log(result.error.message);
            
            }
            window.open(result.source.redirect.url, "_self" );
            return result;
        });
    }
  
  const checkAlipayAuthResponse = async () => {  
    var url_string =  window.location.href
    var url = new URL(url_string);
    var params = url.searchParams.get("client_secret");
    if(params && params != null) {   
        setLoading(true);
        let alipay_source =  url.searchParams.get("source");
        let alipay_client_secret =  url.searchParams.get("client_secret");
        const response = await commonService.postService({
          paymentId:4,    
          captureid:alipay_source,
          amount:localStorage.getItem('pay_amt'),
          paymentType:paymentType,
          paymentMethod:4
        }, 'walletRecharge/completeRecharge').catch(err =>console.log('err',  ToastsStore.error(err.response.data.message)));
        if(response){
          ToastsStore.success("Success!");
          setLoading(false);
          if(paymentType == 'event') {
          eventransactionUpdate(response.data.result.otherPaymentId,4);
          } else {
           history.push('/admin/profilesettings');
          }
        }
      } else { 
       // let getparams = url_string.split('/');
        state.rcamount= JSON.parse(localStorage.getItem('loc_rc_amount')); //getparams[getparams.length - 1];
        setState({...state});
      }
    };
    /************************* stripe pament */

    
    const handleStripePayment = async (event) => { 
     
      event.preventDefault();
          setLoading(true);
          if (!stripe || !elements || !iscardComplete) {
            return;
          }
          let res = await commonService.postService({amount: +state.rcamount * 100, paymentId:1, type:paymentType}, 'walletRecharge/confirmWalletRecharge').catch(err =>console.log('err',  ToastsStore.error(err.response.data.message)));
          if(!res){
            return;
          }
          let paymentIntentSecret =res.data.secret;
          const paymentResult = await stripe.confirmCardPayment(paymentIntentSecret, {
          payment_method: {
            card:  elements.getElement(CardElement),
            billing_details: {
              name: 'Jenny Rosen',
            },
          },
        });
        if (paymentResult.error) {
          setLoading(false);
          console.log(paymentResult.error.message);
          ToastsStore.error(paymentResult.error.message);
        } else {
          if (paymentResult.paymentIntent.status === "succeeded") {
            console.log(paymentResult);
            const response = await commonService.postService({
              paymentIntent:paymentResult.paymentIntent,
              amount:+state.rcamount,
              paymentType:paymentType,
              paymentMethod:3
            }, 'walletRecharge/completeRecharge').catch(err =>console.log('err',  ToastsStore.error(err.response.data.message)));
                if(response){
                  ToastsStore.success("Success!");
                  
                  setLoading(false);
                 if(paymentType == 'event') {
                   console.log(response.data.result.otherPaymentId);
                  eventransactionUpdate(response.data.result.otherPaymentId, 3);
                  } else {
                  history.push('/admin/profilesettings');
                  }
                }
            }
        }
    }
    /******** paypal started */
    const onSuccess = (payment) => {
        // now create transaction & invoice for completed transaction
        console.log("The payment was succeeded!", payment);
    }

    const onCancel = (data) => {
        // User pressed "cancel" or close Paypal's popup!
        console.log('The payment was cancelled!', data);
        ToastsStore.error('The payment was cancelled!');
        // You can bind the "data" object's value to your state or props or whatever here, please see below for sample returned data
    }

    const onError = (err) => {
        // The main Paypal's script cannot be loaded or somethings block the loading of that script!
        console.log("Error!", err);
        // Because the Paypal's main script is loaded asynchronously from "https://www.paypalobjects.com/api/checkout.js"
        // => sometimes it may take about 0.5 second for everything to get set, or for the button to appear
    }

    let env = 'production'; // you can set here to 'production' for production sandbox for sandbox
    let currency = 'EUR'; // or you can set this value from your props or state
    let total = state.rcamount; // same as above, this is the total amount (based on currency) to be paid by using Paypal express checkout
    // Document on Paypal's currency code: https://developer.paypal.com/docs/classic/api/currency_codes/

    const client = {
      // sandbox:    'AQM4L2V5c6aHPPaqBmJ2MVcVbPYOWzTs9tUldOqlP1efbmDvunTfeBSc7NdOOaiAXLv8PlQwlkFNJnNZ',
        production: process.env.REACT_APP_SANDBOX,
    }

    const eventransactionUpdate = (transactionId, method) => {
      let paymentData = {
        paymentStatus : '1',
        paymentTransactionId : transactionId,
        paymentMethod : method
      }
      eventService.eventRegistration(paymentData)
    }

  return ( <div><Container className="mt-4 col-lg-9 col-md-9 col-sm-12  " fluid>
      <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore}/>
     {loading&&<Spinner/>}
     
     {id==2 &&

           <Card className="p-3"><form onSubmit={handleSubmit}>
    <h1 className="ml-3 mt-3">Pay With Alipay</h1><hr className="m-0 ml-3 paycarhr" style={{    width: "14%"}}/>
    <Row className="justify-content-center pb-5">
                
                <Col lg="auto" >
                <CardBody>
                <button className="btn btn-primary" type="submit" disabled={!stripe}>
        Proceed Alipay
      </button>
    </CardBody>
    </Col>
    </Row>

 
    </form></Card>}

    
    {id==1 &&
    <Card className="p-3">
    <h1 className="ml-3 mt-3">Pay With Paypal</h1><hr className="m-0 ml-3 paycarhr" style={{    width: "14%"}}/>
    <Row className="justify-content-center pb-5">
                
                <Col lg="auto" >
                <CardBody>
    <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore}/>
    <PaypalExpressBtn env={env} client={client} currency={currency} total={total} onError={onError} onSuccess={onSuccess} onCancel={onCancel} />
    </CardBody>
    </Col>
    </Row>
    </Card>
}
{id==3 &&
    <Card className="p-3">
    <h1 className="ml-3 mt-3">Pay With Card</h1><hr className="m-0 ml-3 paycarhr" style={{    width: "14%"}}/>
    <form className="mt-3" onSubmit={handleStripePayment}>
      <p className="text-center">
        Card details </p>
        <CardElement
          options={options}
          onReady={() => {
            console.log("CardElement [ready]");
          }}
          onChange={event => {
            console.log("CardElement [change]", event);
            setIsCardComplete(event.complete);
          }}
          onBlur={() => {
            console.log("CardElement [blur]");
          }}
          onFocus={() => {
            console.log("CardElement [focus]");
          }}
        />
      
      <button className="btn btn-primary" type="submit" disabled={!stripe || !iscardComplete}>
        Pay
      </button>
    </form>
          </Card>
}
    </Container>
    </div>






  );
};



const DigitalWallet = (props) => {
  let id=props.match.params.id
  let paymentType = props.match.params.type;
  return (
    <Elements stripe={stripePromise}>
    <CardFormInter id={id} type={paymentType}/>
  </Elements>
    
  );
};



export default DigitalWallet;

