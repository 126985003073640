import React, { useEffect, useState } from 'react';
import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import { Card, CardBody, Row, Col, Button, CardHeader, Modal } from "reactstrap";
import ReactQuill, { Quill } from "react-quill";
import { TextField } from '@material-ui/core';



import Avatar from "@material-ui/core/Avatar";
import { Description } from '@material-ui/icons';
import { now } from 'moment';


const arr = [{ productCategorieId: 10, name: 'mobile phones and smartphones' }, { productCategorieId: 20, name: 'notebooks' }];


let idCounter = 0;
const createRandomRow = () => {
    idCounter += 1;
    return {
        id: idCounter, product: '', category: '', productCategorieId: null, brand: '', detailDesc: '', detailDesc1: '', shortDesc: '', shortDesc1: '', tweetPitch: '', tweetPitch1: '',

        businessType: 'Both', hasVariant: false, variant: '', currency: '', retailPrice: null, sampleCount: false, sampleOrder: null, minOrderQuantityUnit: '', isAvailableImmediate: '',

        availableFrom: null, availabilityComment: '', productCertificate: '', productWarranty: '', productImage: 'https://d35w8j22j5uly8.cloudfront.net/document/product/image/JQ4JYRVL_1603116807460_1603116915138.png'

    };
};



const ProductImport = (props) => {

    let [selectedId, setSelectedId] = useState(null);

    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState(null);

    const handleClose = () => {
        setOpen(false);
    };


    const [pageSize, setPageSize] = React.useState(5);

    let [description, setDescription] = useState({ value: '', formattedValue: null });

    const [rows, setRows] = React.useState(() => [
        // {
        //     id: 1,
        //     category: '',
        //     product: '',
        //     productCategorieId: 12,
        //     user: {
        //         username: "Harry Potter",
        //         avatar: "https://assets.materialup.com/uploads/bebad102-7f40-4941-99cd-54366113003e/avatar-08.png"
        //     },
        //     image: {
        //         avatar: "https://assets.materialup.com/uploads/bebad102-7f40-4941-99cd-54366113003e/avatar-08.png"
        //     },
        // }
    ]);

    const deleteUser = (id) => {

        setTimeout(() => {
            setRows((prevRows) => prevRows.filter((row) => row.id !== id));
        });
    }

    const duplicateUser = (id) => {

        // (id) => () => {
        let len=rows.length;
        setRows((prevRows) => {
            const rowToDuplicate = prevRows.find((row) => row.id === id);
            return [...prevRows, { ...rowToDuplicate, id: len + 1 }];
        });

    }

    const handleAddRow = () => {
        setRows((prevRows) => [...prevRows, createRandomRow()]);
    };

    const [selectionModel, setSelectionModel] = React.useState(() =>
        rows.filter((r) => r)
    );

    const handleRemoveRow = () => {

        let remainingRows = rows.filter(r => !selectionModel.includes(r.id));

        setRows(remainingRows);
    }


    const handleCommit = (e) => {
        const array = rows.map(r => {
            if (r.id == e.id) {
                if (e.field == 'category') {
                    let categoryObj = arr.find(item => item.name === e.value);

                    r = { ...r, [e.field]: e.value, productCategorieId: categoryObj['productCategorieId'] }
                }
                return { ...r, [e.field]: e.value }
            }
            else {
                return { ...r }
            }
        });
        setRows(array);

    }

    const handleSave = (field, value, format) => {

        const array = rows.map(r => {
            if (r.id === selectedId && field === 'Product Description') {

                return { ...r, detailDesc: value, detailDesc1: format }
            }
            else if (r.id === selectedId && field === 'Product Short Description') {
                return { ...r, shortDesc: value, shortDesc1: format }
            }
            else if (r.id === selectedId && field === 'Product Tweetpitch') {
                return { ...r, tweetPitch: value, tweetPitch1: format }
            }
            else {
                return { ...r }
            }
        });
        setRows(array);
        setOpen(false);
        console.log("save:", array);
    }

    const handleEdit = (e) => {


        setSelectedId(e.id);

        const array = rows.filter(r => r.id === e.id);

        if (e.field === 'detailDesc') {
            if (array[0].detailDesc)
                description.value = array[0].detailDesc1;
            else
                description.value = '';
            setOpen(true);
            setTitle('Product Description');
        }
        else if (e.field === 'shortDesc') {
            if (array[0].shortDesc)
                description.value = array[0].shortDesc1;
            else
                description.value = '';
            setOpen(true);
            setTitle('Product Short Description');
        }
        else if (e.field === 'tweetPitch') {
            if (array[0].tweetPitch)
                description.value = array[0].tweetPitch1;
            else
                description.value = '';
            setOpen(true);
            setTitle('Product Tweetpitch');
        }

    }

    const columns = React.useMemo(
        () => [
            {
                field: 'product', headerName: 'Product Name', width: 250, headerAlign: 'center', editable: true
            },

            {
                field: 'category',
                headerName: 'Product Category',
                type: 'singleSelect',headerAlign: 'center',
                valueOptions: ['mobile phones and smartphones', 'notebooks'],
                width: 250,
                editable: true,
                sortable: false
            },

            {
                field: "productImage",
                headerName: " Default Image",
                width: 250,
                editable: true,headerAlign: 'center',
                sortable: false,
                renderCell: (params) => {

                    return (
                        <>
                            <Avatar src={params.value} />
                        </>
                    );
                }
            },

            {
                field: 'brand',
                headerName: 'Product Brand',
                type: 'singleSelect',
                valueOptions: ['AIWA', 'CELLY'],
                width: 250,headerAlign: 'center',
                editable: true,
                sortable: false
            },

            {
                field: 'detailDesc', headerName: 'Product Description', width: 250, editable: true, autoFocus: false,headerAlign: 'center', sortable: false
            },

            {
                field: 'shortDesc', headerName: 'Short Description', width: 250, editable: true, headerAlign: 'center',sortable: false
            },

            {
                field: 'tweetPitch', headerName: 'Tweetpitch', width: 250, editable: true,headerAlign: 'center', sortable: false
            },

            {
                field: 'businessType',
                headerName: 'B2B or B2C',
                type: 'singleSelect',headerAlign: 'center',
                valueOptions: ['B2B', 'B2C', 'Both'],
                width: 250,
                editable: true,
                sortable: false
            },
            {
                field: 'hasVariant',
                headerName: 'hasVariant',
                type: 'boolean',headerAlign: 'center',
                width: 150,
                editable: true,
                sortable: false
            },
            {
                field: 'variant',
                headerName: 'Variant Details',
                width: 250,headerAlign: 'center',
                editable: true,
                sortable: false
            },
            {
                field: 'currency',
                headerName: 'Currency',
                type: 'singleSelect',headerAlign: 'center',
                valueOptions: ['Dollar', 'Euro'],
                width: 250,
                editable: true,
                sortable: false
            },
            {
                field: 'retailPrice',
                headerName: 'Retail Price',
                type: 'number',headerAlign: 'center',
                width: 250,
                editable: true,
                sortable: false
            },
            // {
            //     field: 'sampleCount',
            //     headerName: 'isUnlimitSample',
            //     type: 'boolean',
            //     width: 150,
            //     editable: true,
            // },
            // {
            //     field: 'sampleOrder',
            //     headerName: 'sampleOrder',
            //     type: 'number',
            //     width: 250,
            //     editable: true,
            // },
            {
                field: 'minOrderQuantityUnit',
                headerName: 'minOrderQuantityUnit',
                type: 'singleSelect',headerAlign: 'center',
                valueOptions: ['Pcs', 'Box'],
                width: 250,
                editable: true,
                sortable: false
            },
            {
                field: 'isAvailableImmediate',
                headerName: 'isAvailableImmediate',
                type: 'singleSelect',headerAlign: 'center',
                valueOptions: ['Immediate', 'Available From'],
                width: 250,
                editable: true,
                sortable: false
            },
            {
                field: 'availableFrom',
                headerName: 'availableFrom',
                type: 'date',headerAlign: 'center',
                width: 250,
                editable: true,
                sortable: false
            },
            {
                field: 'availabilityComment',
                headerName: 'availabilityComment',headerAlign: 'center',
                width: 250,
                editable: true,
                sortable: false
            },
            {
                field: 'productCertificate',
                headerName: 'productCertificate',headerAlign: 'center',
                width: 250,
                editable: true,
                sortable: false
            },
            {
                field: 'productWarranty',
                headerName: 'productWarranty',
                width: 250,headerAlign: 'center',
                editable: true,
            },
            {
                field: 'delete',
                headerName: 'Actions',
                type: 'actions',headerAlign: 'center',
                width: 200,

                renderCell: (params) => {

                    return (
                        <>
                            <Button onClick={() => deleteUser(params.id)}><span className="btn-inner--icon"><i className="fas fa-trash-alt"></i></span></Button>
                            <Button onClick={() => duplicateUser(params.id)}><span className="btn-inner--icon"><i class="fas fa-copy"></i></span></Button>

                        </>
                    );
                }

            },
            // {
            //     field: 'duplicate',
            //     headerName: 'Duplicate',
            //     type: 'actions',
            //     width: 80,

            //     renderCell: (params) => {

            //         return (
            //             <>
            //                 <Button onClick={() => duplicateUser(params.id)}><span className="btn-inner--icon"><i class="fas fa-copy"></i></span></Button>
            //             </>
            //         );
            //     }

            // },

        ]
    );

    const handleDetailDesc = (value) => {

        setDescription({ ...description, value: value, formattedValue: value.replace(/<[^>]+>/g, '') });

    }



    return (
        <div>

            {open &&
                <div>
                    <Modal className="modal-dialog-centered" isOpen={open}>
                        <div className="modal-header">
                            <strong>{title}</strong>
                            <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={handleClose}>
                                <span aria-hidden={true}>×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <ReactQuill
                                theme="snow"
                                autoFocus
                                placeholder="Tell us about your company description and vision -max 2000 characters *"
                                onChange={handleDetailDesc}
                                value={description.value}
                                // ref={register}
                                id="detailDesc"
                                name="detailDesc"
                                modules={{
                                    toolbar: [
                                        ["bold", "italic", "underline"],
                                        [
                                            {
                                                list: "ordered"
                                            },
                                            {
                                                list: "bullet"
                                            }
                                        ]
                                    ]
                                }}
                            />

                        </div>
                        <div className="modal-footer">
                            <Button color="primary" type="button" onClick={() => handleSave(title, description.formattedValue, description.value)}>Ok</Button>
                            <Button color="secondary" data-dismiss="modal" type="button" onClick={handleClose}>Close</Button>
                        </div>
                    </Modal>
                </div>}

            <Row style={{ height: 700, width: '100%' }}>
                <Col>

                    <CardHeader className="p-3">
                        <h2 className="text-center m-0 text-uppercase">Product Information</h2>
                    </CardHeader>
                    <DataGrid editMode="row"
                        getRowHeight={() => 'auto'}
                        //components={{ Toolbar: GridToolbar }}
                        checkboxSelection
                        selectionModel={selectionModel}
                        onSelectionModelChange={setSelectionModel}
                        disableSelectionOnClick

                        // onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        // rowsPerPageOptions={[5, 10, 20]}
                        rows={rows}
                        columns={columns}
                        onCellEditCommit={handleCommit}
                        onCellEditStart={handleEdit}
                        //pagination={false}
                        pageSize={pageSize}
                    />


                </Col>
            </Row>

            <div style={{marginBottom:"4rem",marginTop:"7rem",textAlign:"center",widows:"100%"}}>
            <Button onClick={() => handleAddRow()}>Add</Button>

            </div>




            {/* <p>{JSON.stringify(selectionModel)}</p> */}


        </div>
    );
}

export default ProductImport;
