import React from 'react';
import axios from 'axios';
import { Col, Container, Row, Card,CardBody,Button } from "reactstrap";
export const Wishlist = () => {

       return (
          <>

        <div className='container'>
           <Card>
              <CardBody>
                 <div className="wishheader text-center">
                    <h1>My Wishlist</h1>
                 </div>
              </CardBody>
           </Card>

           <Card>
               <CardBody>
                     <Row>
                        <Col className="col-lg-4 col-md-4 text-center col-12 linewish">
                           <Row>
                           {/* <Col className="col-lg-2"></Col> */}
                           <Col className="col-lg-7 col-md-7 text-center col-12">
                              <h4>Product Name</h4>
                           </Col>
                           <Col className="col-lg-5 col-md-5"></Col>
                           </Row>
                           <Row>
                              <Col className="col-lg-3 col-md-3 pad-less text-center">
                                 <div className="img-wish">
                                    <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  className="img-fluid" src={require("../../../../assets/img/medialibrary/bootstrap.jpg")}/>
                                 </div>
                              </Col>
                              <Col className="col-lg-9 col-md-9 text-left pad-wish">
                                 <h4>Levista</h4>
                              </Col>
                           </Row>
                        </Col>
                        <Col className="col-lg-2 col-md-2 text-center col-12 linewish">
                           <h4>Unit Price</h4>
                           <h4 className="mt4">$6000/$2000</h4>
                           </Col>
                        <Col className="col-lg-2 col-md-2 text-center col-12 linewish">
                           <h4>Stock Status</h4>
                           <h4 className="mt4">In stock</h4>
                       </Col>
                        <Col className="col-lg-3 col-md-3 text-center col-12 linewish">
                           <div className="mt-4 res-wish">
                           <span>test content</span>
                              <button className="btn btn-primary "> Add to Wishlist</button>
                           </div>
                        </Col>
                        <Col className="col-lg-1 col-md-1 pad-wish1 text-center">
                        <i class="far fa-trash-alt"></i>
                        </Col>
                     </Row>
               </CardBody>
           </Card>

           <Card>
               <CardBody>
                     <Row>
                        <Col className="col-lg-4 col-md-4 text-center col-12 linewish">
                           <Row>
                           {/* <Col className="col-lg-2"></Col> */}
                           <Col className="col-lg-7 col-md-7 text-center col-12">
                              <h4>Product Name</h4>
                           </Col>
                           <Col className="col-lg-5 col-md-5"></Col>
                           </Row>
                           <Row>
                              <Col className="col-lg-3 col-md-3 pad-less text-center">
                                 <div className="img-wish">
                                    <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  className="img-fluid" src={require("../../../../assets/img/medialibrary/bootstrap.jpg")}/>
                                 </div>
                              </Col>
                              <Col className="col-lg-9 col-md-9 text-left pad-wish">
                                 <h4>Levista</h4>
                              </Col>
                           </Row>
                        </Col>
                        <Col className="col-lg-2 col-md-2 text-center col-12 linewish">
                           <h4>Unit Price</h4>
                           <h4 className="mt4">$6000/$2000</h4>
                           </Col>
                        <Col className="col-lg-2 col-md-2 text-center col-12 linewish">
                           <h4>Stock Status</h4>
                           <h4 className="mt4">In stock</h4>
                       </Col>
                        <Col className="col-lg-3 col-md-3 text-center col-12 linewish">
                           <div className="mt-4 res-wish">
                           <span>test content</span>
                              <button className="btn btn-primary "> Add to Wishlist</button>
                           </div>
                        </Col>
                        <Col className="col-lg-1 col-md-1 pad-wish1 text-center">
                        <i class="far fa-trash-alt"></i>
                        </Col>
                     </Row>
               </CardBody>
           </Card>


           <Card>
               <CardBody>
                     <Row>
                        <Col className="col-lg-4 col-md-4 text-center col-12 linewish">
                           <Row>
                           {/* <Col className="col-lg-2"></Col> */}
                           <Col className="col-lg-7 col-md-7 text-center col-12">
                              <h4>Product Name</h4>
                           </Col>
                           <Col className="col-lg-5 col-md-5"></Col>
                           </Row>
                           <Row>
                              <Col className="col-lg-3 col-md-3 pad-less text-center">
                                 <div className="img-wish">
                                    <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  className="img-fluid" src={require("../../../../assets/img/medialibrary/bootstrap.jpg")}/>
                                 </div>
                              </Col>
                              <Col className="col-lg-9 col-md-9 text-left pad-wish">
                                 <h4>Levista</h4>
                              </Col>
                           </Row>
                        </Col>
                        <Col className="col-lg-2 col-md-2 text-center col-12 linewish">
                           <h4>Unit Price</h4>
                           <h4 className="mt4">$6000/$2000</h4>
                           </Col>
                        <Col className="col-lg-2 col-md-2 text-center col-12 linewish">
                           <h4>Stock Status</h4>
                           <h4 className="mt4">In stock</h4>
                       </Col>
                        <Col className="col-lg-3 col-md-3 text-center col-12 linewish">
                           <div className="mt-4 res-wish">
                           <span>test content</span>
                              <button className="btn btn-primary "> Add to Wishlist</button>
                           </div>
                        </Col>
                        <Col className="col-lg-1 col-md-1 pad-wish1 text-center">
                        <i class="far fa-trash-alt"></i>
                        </Col>
                     </Row>
               </CardBody>
           </Card>


           <Card>
               <CardBody>
                     <Row>
                        <Col className="col-lg-4 col-md-4 text-center col-12 linewish">
                           <Row>
                           {/* <Col className="col-lg-2"></Col> */}
                           <Col className="col-lg-7 col-md-7 text-center col-12">
                              <h4>Product Name</h4>
                           </Col>
                           <Col className="col-lg-5 col-md-5"></Col>
                           </Row>
                           <Row>
                              <Col className="col-lg-3 col-md-3 pad-less text-center">
                                 <div className="img-wish">
                                    <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  className="img-fluid" src={require("../../../../assets/img/medialibrary/bootstrap.jpg")}/>
                                 </div>
                              </Col>
                              <Col className="col-lg-9 col-md-9 text-left pad-wish">
                                 <h4>Levista</h4>
                              </Col>
                           </Row>
                        </Col>
                        <Col className="col-lg-2 col-md-2 text-center col-12 linewish">
                           <h4>Unit Price</h4>
                           <h4 className="mt4">$6000/$2000</h4>
                           </Col>
                        <Col className="col-lg-2 col-md-2 text-center col-12 linewish">
                           <h4>Stock Status</h4>
                           <h4 className="mt4">In stock</h4>
                       </Col>
                        <Col className="col-lg-3 col-md-3 text-center col-12 linewish">
                           <div className="mt-4 res-wish">
                           <span>test content</span>
                              <button className="btn btn-primary">Add to Wishlist</button>
                           </div>
                        </Col>
                      
                        <Col className="col-lg-1 col-md-1 pad-wish1 text-center">
                        <i class="far fa-trash-alt"></i>
                        </Col>
                     </Row>
               </CardBody>
           </Card>
          
         </div>
          </>
       );

}

export default Wishlist;