import React, { useEffect, useRef, useState } from "react";
import {
  GridRowModes,
  GridActionsCellItem,
  DataGrid,
  GridToolbar,
  GridColumnMenu,
  GridValueGetterFullParams,
  GridValueGetterParams,
  GridCellModes,
  gridClasses,
  useGridApiContext
} from "@mui/x-data-grid";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import CancelIcon from '@mui/icons-material/Cancel';
// import { DataGridPro } from "@mui/x-data-grid-pro";
import { commonService } from "_services/common.service";
import { FixedSizeGrid } from "react-window";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { ToastsContainer, ToastsStore } from "react-toasts";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import WarningIcon from '@mui/icons-material/Warning';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Spinner } from "views/Hoc/Spinner";
import { getFreeze } from "./util-functions";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import clsx from 'clsx';
import { history } from '../../../_helpers';
import UserTour from "components/User-Tour/Usertour";
import { Tourstep } from "components/User-Tour/Tourstep";
import { Tourstep2 } from "components/User-Tour/Tourstep2";
import { Tourstep3 } from "components/User-Tour/Tourstep3";
import { Link } from "react-router-dom";
import { userConstants } from "_constants";
import PlanFeatureDialog from "views/Hoc/Dialog/PlanFeatureDialog";



const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  // height:600,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  borderRadius: "5px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

function randomInteger() {
  var retval = Math.floor(Math.random() * 100000);
  console.log("randomInteger generated " + retval);
  return retval;
}

const visibilityOptions = ["Distributor", "Retailer","Reseller"];

const StyledBox = styled(Box)(({ theme }) => ({
 
  '& .Mui-error': {
    backgroundColor: '#e9e9e9 !important',
    color: 'grey',
    height:'100%',
    pointerEvents:'none'
  },

  '& .Mui-error input': {
    backgroundColor: '#e9e9e9 !important',
    color: 'white',
    height:'100%',
   
  }
}));

const typeOfBusiness = [
  {
    value: "1",
    label: "B2C",
  },
  {
    value: "2",
    label: "B2B",
  },
  {
    value: "3",
    label: "Both",
  }
];

function StartProdImport(props) {
  const [rows, setRows] = useState([]);
  const [isRowLoading,setIsRowLoading]=useState(true)
  const [rowModesModel, setRowModesModel] = useState({});
  const [cellModesModel,setCellModesModel] = useState({})
  const [isNotValid,setIsNotValid]=useState(false)
  const [disabledVarDetailsIds,setDisabledVarDetailsIds]=useState({})
  const [disabledSampleOrderIds,setDisabledSampleOrderIds]=useState({})
  const [disabledAvailabilityIds,setDisabledAvailabilityIds]=useState({})

  const [freezingOptions, setFreezingOptions] = useState({
    [`.MuiDataGrid-row--editing > div`]:{
      borderRight:"1px solid rgba(224, 224, 224, 1)"
    },
    ['.MuiDataGrid-cell--editing fieldset']:{
      borderWidth:0
    }
  });
  const [validations,setValidations] = useState([])

  const [allProducts, setAllProducts] = useState([]);
  const [openErrorModal, setOpenErrorModal] = useState(false);

  const [openDeleteModal,setOpenDeleteModal] = useState(false);
  const [toDeleteId,setToDeleteId] =useState("");
  const [isLoading,setIsLoading] = useState(false);
  const [isVariantDetails,setIsVariantDetails] = useState(false);
  const [isUnlimitSample,setIsUnlimitSample] = useState(false);
  const [isAvailableImmediate,setIsAvailableImmediate] = useState(false)
  const [validationsFor,setValidationsFor] = useState("");
  const [uploadedImages,setUploadedImages] = useState([]);
  const [showUnFreeze,setShowUnFreeze] = useState(false)
  const [visibilityVals,setVisibilityVals] = useState([])
  const [relevantRetailers,setRelaventRetailers] = useState([])
  const [relevantRetailersOptions,setRelaventRetailersOptions] = useState([])
  const [relevantResellers,setRelaventResellers] = useState([])
  const [relevantResellersOptions,setRelaventResellersOptions] = useState([])
  const [freezeColIndex,setFreezeColIndex] = useState(-1);
  const [fieldValidations,setFieldValidations] = useState({});
  const [imagesFolder,setImagesFolder] = useState([]);
  const [businessTypeOptions,setBusinessTypeOptions] = useState([]);
  const [initialBusinessType,setInitialBusinessType] = useState("");
  const [initialProductCategory,setInitialProductCategory]=useState("");
  const [initialProductBrand,setInitialProductBrand]=useState("");

  const [isRetailProfiles,setIsRetailProfiles] = useState(true);
  const [isResellProfiles,setIsResellProfiles] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [popup, setPopup] = useState(false);
  const [itemIndex, setItemIndex] = useState(0);
  const [key,setKey]=useState(0);
  const [rowPopup,setRowPopup]=useState(false);
  const [rowItemIndex,setRowItemIndex]=useState(0);
  const [disableRowPopup,setDisableRowPopup]=useState(false)
  const [planUpgrade, setplanUpgrade] = useState(false);
  const [upgradeType, setUpgradeType] = useState("productAdd");
  const [productLimit, setProductLimit] = useState(true);
  let [tabCount, setTabCount] = useState({ active: 0, inActive: 0, approval: 0,partActive:0 });

 const tourData = [
   {
     title: "Product name*",
     description: "Should be more than 3 characters long",
     target: "productName",
   },
   {
     title: "Business Type*",
     description:
       "Required field & Please indicate if this product is for B2C or B2B end customer",
     target: "businessType",
   },
   {
     title: "Product Short Description*",
     description:
       "What is the usage of this product? (max 140 characters)",
     target: "productShortDec",
   },
   {
     title: "Tweet Pitch*",
     description:
       "What is the unique selling proposition of this product (Min 5 - Max 280 characters)",
     target: "tweetPitch",
   },
   {
     title: "Product Description",
     description:
       "Full product description (Max 2 000 characters)",
     target: "productDesc",
   },

   {
     title: "Product Category*",
     description: "Use the dropdown to select the product category. If needed you can add more product categories under your company profile",
     target: "productCategory",
   },
   {
     title: "Product Brand*",
     description: "Use the dropdown to select the brand. If needed you can add more brands under your company profile",
     target: "productBrand",
   },
   {
     title: "Business Currency*",
     description: "Select the currency from the dropdown list",
     target: "currency",
   },
   {
     title: "Retailer Price*",
     description: "Suggested Retail price",
     target: "retailerPrice",
   },
   {
     title: "Min Order Quantity*",
     description: "Add the Minimum Order Quantity (MOQ) for this product; If no minimum, put 0",
     target: "minOrderQuantity",
   },
   {
     title: "Min Order Qty Unit*",
     description: "What is the unit used for your MOQ?",
     target: "minOrderQuantityUnit",
   },
   {
     title: "Product Visibility*",
     description: "Use the dropdown to select which buyer profile can see this product. You can update your target channel profile under your company profile",
     target: "productVisibility",
   },
   {
     title: "Product Images",
     description: "Add a least 1 product image. If you leave this field empty you can add images later under edit mode.",
     target: "images",
   },
 ];
  const [targetLoc,setTargetLoc] = useState({
    targetZoneOption: [],
    targetZone: [],
    zone: [],
    targetRegionOption: [],
    targetRegion: [],
    region: [],
    targetCountryOption: [],
    targetCountry: [],
    country: []
  });

  const [dropdowns, setDropdowns] = useState({
    brand: [],
    currency: [],
    prodCategory: [],
  });
  const [numberOfFreeze, setNumberOfFreeze] = useState({
    rows: "",
    cols: "",
  });

  var userData = JSON.parse(localStorage.getItem('CHuser'));

  const getTargetLocations =async ()=>{
    const res = await commonService.getServices('getChannelLocation/' + userData.channelId);
    let target=[]
    
    if (res) {
      const locationCollection = res.data;
      if (locationCollection.currentLocation && locationCollection.currentLocation.TARGET.length > 0) {
        target = locationCollection.currentLocation.TARGET;
      } else if (locationCollection.oldLocation) {
        target = locationCollection.oldLocation.TARGET;
      }
      target.forEach(zoneItem => {
        targetLoc.targetZoneOption.push({
          'id': zoneItem.zoneId, 'name': zoneItem.zone
        });
        zoneItem.region.forEach(regionItem => {
          targetLoc.targetRegionOption.push({
            'parentId': zoneItem.zoneId, 'id': regionItem.regionId, 'name': regionItem.region
          });
          regionItem.country.forEach(countryItem => {
            targetLoc.targetCountryOption.push({
              'parentId': regionItem.regionId, 'id': countryItem.countryId,
              'name': countryItem.country,
              'regioncountryjctid': countryItem.RegionCountryJCT.regionCountryJCTId
            });
          });
        });
      });

      targetLoc.zone = targetLoc.targetZoneOption;
      targetLoc.region = targetLoc.targetRegionOption;
      targetLoc.country = targetLoc.targetCountryOption;
      targetLoc.targetZone =targetLoc.targetZoneOption.map((zone)=>zone.name)
      targetLoc.targetRegion=targetLoc.targetRegionOption.map((reg)=>reg.name)
      targetLoc.targetCountry=targetLoc.targetCountryOption.map((con)=>con.name)


      setTargetLoc({...targetLoc})

    }
  }

  const getRetailerReseller = async ()=>{
    const res = await commonService.getServices('channel-infos');

    let retailers = [];
    let resellers =[];
    if (res) {
      retailers =  res.data.RETAILER;
      resellers = res.data.RESELLER;
     
    }
    // console.log('distributer',res.data)
   
    setRelaventRetailersOptions((retailers||[])?.map(it=>it.name))
    setRelaventResellersOptions((resellers||[])?.map(it=>it.name))
    
    setRelaventRetailers(retailers)
    setRelaventResellers(resellers)
  }
let localAddProd;
  const getDropdowns = async () => {

    getRetailerReseller();
    getTargetLocations();
    try {
      let { data } = await commonService
        .getServices("productImport/get-start-up-data")
        .catch((err) => console.log(err));
      let newCurr = [];

      let op = data?.masters?.currency.forEach((element) => {
        let curr = {
          value: element.currency,
          label: element.currency,
        };
        newCurr.push(curr);
      });

      let newBrand = [];

      let opp = data?.masters?.master?.brand.forEach((it) => {
        let brand = {
          // value:it.id,
          value: it.brandName,
          label: it.brandName,
        };
        newBrand.push(brand);
      });

      let newCat = [];

      let oppo = data?.masters?.master?.productCategory.forEach((it) => {
        let prodCat = {
          // value:it.productCategorieId,
          value: it.productCategorie,
          label: it.productCategorie,
        };
        newCat.push(prodCat);
      });

      localAddProd = JSON.parse(localStorage.getItem("startAddProd"));
      let localUploadImage = JSON.parse(localStorage.getItem("uploadedImages"));
      let toShow = JSON.parse(localStorage.getItem("toShow"));

      if (localAddProd) {
        setRows(localAddProd);
        setImagesFolder(localUploadImage);
        setUploadedImages(toShow);
        setIsRowLoading(false);
      }

      setDropdowns((prev) => ({
        ...prev,
        brand: newBrand,
        currency: newCurr,
        prodCategory: newCat,
      }));
      setInitialProductCategory(newCat[0]?.label);
      setInitialProductBrand(newBrand[0]?.label);

      if (rows.length === 0 && !localAddProd) {
        const key = randomInteger();
        setKey(key);
        setRows((oldRows) => [
          { myUniqueKey: key, productName: "", isNew: true, copiedFrom: key },
          ...oldRows,
        ]);

        setRowModesModel((oldModel) => {
          return {
            ...oldModel,
            [key]: { mode: GridRowModes.Edit, fieldToFocus: "productName" },
          };
        });
      }
    } catch (e) {
       ToastsStore.error('something went wrong')
    }
  };
  useEffect(() => {
    setTimeout(() => {
      if (!JSON.parse(localStorage.getItem("startAddProd"))) {setShowModal(true);setRowPopup(false)};
    }, 3000);
  }, []);

  const getBusinessTypeDrops = ()=>{
    let bType=userData.businessType
    // let bType=2;
    let options=[]
    let initialSelected="";
    if (bType===1 || bType===2){
      options.push(typeOfBusiness[bType-1].label)
      initialSelected=typeOfBusiness[bType-1].label
    }

    if (bType===3){
      options=typeOfBusiness.map((it)=>it.label)
      initialSelected=typeOfBusiness[bType-1].label
    }

    setInitialBusinessType(initialSelected)
    setBusinessTypeOptions(options)
  }

  useEffect(() => {
    getDropdowns();
    getBusinessTypeDrops();
    //getProductLimit();
  }, []);

  const showIndividualError = (row) => {
    processRowUpdate(row)
  };

  const showAllErrors = ()=>{
    setOpenErrorModal(true);
  }

  console.log("colRows",rows,rowModesModel)

  const copyRow = (params)=>{
    const key = randomInteger();
    let copiedRow;
    for(let i=0;i<rows.length;i++){
      if (rows[i].myUniqueKey===params.row.myUniqueKey){
        copiedRow=rows[i]
      }
    }
    let newRow=JSON.parse(JSON.stringify(copiedRow))
    newRow.copiedFrom=newRow.copiedFrom
    newRow.myUniqueKey=key
    newRow.isNew=true
    let res=rows.filter(row=>row.copiedFrom===newRow.copiedFrom)
    let newName = newRow.productName + " (2) ";
    let isExist =rows.find(row=>row.productName===newName)
    if(isExist) {
      newName = newRow.productName + " (3) ";
    }
    isExist =rows.find(row=>row.productName===newName)
    if(isExist) {
      newName = newRow.productName + " (4) ";
    }
    isExist =rows.find(row=>row.productName===newName)
    if(isExist) {
      newName = newRow.productName + " (5) ";
    }
    newRow.productName = newName;

    let allRows=[...rows,newRow]
    setRows(allRows);

    localStorage.setItem("startAddProd", JSON.stringify(allRows));
    setRowModesModel((oldModel) => {
      return {
        ...oldModel,
        [key]: { mode: GridRowModes.View,fieldToFocus:"productName"},
      }});
  }

  function CustomEditComponent(props) {
   
    const { id, value = [], field } = props;
    const apiRef = useGridApiContext();
    
    const handleChange = (event) => {
      const eventValue = event.target.value; // The new value entered by the user
      console.log({ eventValue });
      const newValue =
        typeof eventValue === "string" ? value.split(",") : eventValue;
      apiRef.current.setEditCellValue({
        id,
        field,
        value: newValue.filter((x) => x !== "")
      });
    };

    console.log("disableRowPopup", disableRowPopup);
    let firstrow;
       if(rows[0].myUniqueKey===key){
         for (let i = 0; i < tourData.length; i++) {
           firstrow = document.querySelector(`[data-colindex="${i}"]`);
           firstrow.addEventListener("mouseover", () => {
             setRowItemIndex(i);
             setRowPopup(true)
             setPopup(false)
             setShowModal(false)
           });
          //  firstrow.addEventListener("click", (event) => {
          //   if (!firstrow) setRowPopup(false);
          //  });
           console.log("rowPopup", popup);
         }
       }
    
    setIsRowLoading(false);
  
    return (
      <Select
        labelId="demo-multiple-name-label"
        id="demo-multiple-name"
        multiple
        value={value}
        onChange={handleChange}
        sx={{ width: "100%" }}
      >
        {props.options.map((option) => (
          <MenuItem key={option} value={option}>
          <Checkbox checked={value.indexOf(option) > -1} />
            {option}
          </MenuItem>
        ))}
      </Select>
    );
  }
  

  const CustomDiscountEditCell = (params) => <CustomEditComponent {...params} options={visibilityOptions} />;

  const CustomRetailEditCell = (params) =><CustomEditComponent {...params} options={relevantRetailersOptions}/>;

  const CustomResellerEditCell = (params) =><CustomEditComponent {...params} options={relevantResellersOptions}/>;

  const CustomZoneEditCell = (params) =><CustomEditComponent {...params} options={targetLoc.targetZone}/>;
  const CustomRegionEditCell = (params) =><CustomEditComponent {...params} options={targetLoc.targetRegion}/>;
  const CustomCountryEditCell = (params) =><CustomEditComponent {...params} options={targetLoc.targetCountry}/>;

  function CustomFilterInputSingleSelect(props) {
    const { item, applyValue, type, apiRef, focusElementRef, ...others } = props;
  
    return (
      <TextField
        id={`contains-input-${item.id}`}
        value={item.value}
        onChange={(event) => applyValue({ ...item, value: event.target.value })}
        type={type || "text"}
        variant="standard"
        InputLabelProps={{
          shrink: true
        }}
        inputRef={focusElementRef}
        select
        SelectProps={{
          native: true
        }}
      >
        {["", ...visibilityOptions].map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </TextField>
    );
  }
  
  const deleteImage = (rowId,index)=>{
   
    let newUrls;
    uploadedImages.forEach((item)=>{
      if (rowId===item.rowId){
        newUrls=[...item.urls]
      }
    })

    let imgFolCpy=JSON.parse(JSON.stringify(imagesFolder))

    let count=0
    let newFolCpy=[]
    imgFolCpy.forEach((item)=>{
      if (item.rowId===rowId){
        count+=1
      }
      if (count-1 !==index){
        newFolCpy.push(item)
      }
    })

    setImagesFolder(newFolCpy)

    newUrls=newUrls.filter((item,i)=>i!=index)

    let newUploads=uploadedImages.map((it)=>{
      let newIt={...it}
      if (it.rowId===rowId){
        newIt.urls=newUrls
      }
      return newIt
    })

    setUploadedImages(newUploads)
    localStorage.setItem("uploadedImages", JSON.stringify(imagesFolder));
    localStorage.setItem("toShow", JSON.stringify(newUploads));
  }

  const columns = [
   
    {
      field: "productName",
      headerName: "Product Name*",
      width: 170,
      editable: true,
      cellClassName: (params) => {
        if (
          !fieldValidations[params.row.myUniqueKey] ||
          !fieldValidations[params.row.myUniqueKey].productName
        ) {
          return "";
        }

        return clsx("super-app", {
          fieldError: true,
        });
      },
    },

    {
      field: "businessType",
      headerName: "Business Type*",
      width: 200,
      editable: true,
      type: "singleSelect",
      valueOptions: businessTypeOptions,

      cellClassName: (params) => {
        if (
          !fieldValidations[params.row.myUniqueKey] ||
          !fieldValidations[params.row.myUniqueKey].businessType
        ) {
          return "";
        }

        return clsx("super-app", {
          fieldError: true,
        });
      },

      // valueParser:(value,params)=>{
      //   console.log("vlllp",params,params.value,initialBusinessType,value)

      //   if (!value){

      //     if (initialBusinessType==="B2B"){

      //       setIsRetailProfiles(false)
      //       setIsResellProfiles(true)
      //     }else if(initialBusinessType==="B2C"){

      //       setIsRetailProfiles(true)
      //       setIsResellProfiles(false)
      //     }else{
      //       setIsRetailProfiles(true)
      //       setIsResellProfiles(true)
      //     }
      //     return initialBusinessType
      //   }

      //   else{
      //     debugger
      //     if (value==="B2B"){
      //       setIsRetailProfiles(false)
      //       setIsResellProfiles(true)
      //     }else if(value==="B2C"){
      //       setIsRetailProfiles(true)
      //       setIsResellProfiles(false)
      //     }else{
      //       setIsRetailProfiles(true)
      //       setIsResellProfiles(true)
      //     }

      //     return value
      //   }

      // },

      // valueGetter: (params) => {
      //   console.log("vlllg", params, params.value, initialBusinessType);

      //   if (!params.value) {
      //     if (initialBusinessType === "B2B") {
      //       setIsRetailProfiles(false);
      //       setIsResellProfiles(true);
      //     } else if (initialBusinessType === "B2C") {
      //       setIsRetailProfiles(true);
      //       setIsResellProfiles(false);
      //     } else {
      //       setIsRetailProfiles(true);
      //       setIsResellProfiles(true);
      //     }

          // setInitialValFilled((prev)=>({...prev,[params.row.myUniqueKey]:true}))
          // setInitId(params.row.myUniqueKey)
      //     return initialBusinessType;
      //   } else {
      //     if (params.value === "B2B") {
      //       setIsRetailProfiles(false);
      //       setIsResellProfiles(true);
      //     } else if (params.value === "B2C") {
      //       setIsRetailProfiles(true);
      //       setIsResellProfiles(false);
      //     } else {
      //       setIsRetailProfiles(true);
      //       setIsResellProfiles(true);
      //     }

      //     return params.value;
      //   }
      // },
    },
    {
      field: "productShortDec",
      headerName: "Product Short Description*",
      width: 240,
      editable: true,
      cellClassName: (params) => {
        if (
          !fieldValidations[params.row.myUniqueKey] ||
          !fieldValidations[params.row.myUniqueKey].productShortDec
        ) {
          return "";
        }

        return clsx("super-app", {
          fieldError: true,
        });
      },
    },
    {
      field: "tweetPitch",
      headerName: "Tweet Pitch*",
      width: 170,
      editable: true,
      cellClassName: (params) => {
        if (
          !fieldValidations[params.row.myUniqueKey] ||
          !fieldValidations[params.row.myUniqueKey].tweetPitch
        ) {
          return "";
        }

        return clsx("super-app", {
          fieldError: true,
        });
      },
    },

    {
      field: "productDesc",
      headerName: "Product Description",
      width: 200,
      editable: true,
    },
    {
      field: "productCategory",
      headerName: "Product Category*",
      width: 200,
      editable: true,
      type: "singleSelect",
      valueOptions: dropdowns?.prodCategory,
      cellClassName: (params) => {
        if (
          !fieldValidations[params.row.myUniqueKey] ||
          !fieldValidations[params.row.myUniqueKey].productCategory
        ) {
          return "";
        }

        return clsx("super-app", {
          fieldError: true,
        });
      },

      valueGetter: (params) => {
        console.log("pppp", params.value, initialProductCategory);
        if (!params.value) {
          return initialProductCategory;
        } else {
          return params.value;
        }
      },
    },
    {
      field: "productBrand",
      headerName: "Product Brand*",
      width: 200,
      editable: true,
      type: "singleSelect",
      valueOptions: dropdowns?.brand,
      cellClassName: (params) => {
        if (
          !fieldValidations[params.row.myUniqueKey] ||
          !fieldValidations[params.row.myUniqueKey].productBrand
        ) {
          return "";
        }

        return clsx("super-app", {
          fieldError: true,
        });
      },
      valueGetter: (params) => {
        console.log("tttt", params.value, initialProductBrand);
        if (!params.value) {
          return initialProductBrand;
        } else {
          return params.value;
        }
      },
    },

    // {
    //   field: "IsVariant",
    //   headerName: "Is Variant",
    //   width: 170,
    //   editable: true,
    //   type: "singleSelect",
    //   valueOptions: ["yes", "no"],
    //   cellClassName: (params) => {
    //     if (!fieldValidations[params.row.myUniqueKey]||!fieldValidations[params.row.myUniqueKey].IsVariant){
    //      return ''
    //     }

    //    return clsx('super-app', {
    //        fieldError:true
    //      });
    //    },

    //   valueParser: (value, params) => {
    //     if (value=="no"){
    //       setCellModesModel((oldModal)=>{
    //         return {
    //           ...oldModal,
    //           [params.row.myUniqueKey]:{variantDetails:{ mode: GridCellModes.View, ignoreModifications: true  }}
    //         }
    //       })

    //       setDisabledVarDetailsIds(prev=>({...prev,[params.row.myUniqueKey]:true}))

    //       setIsVariantDetails(false)
    //     }else{
    //       setCellModesModel((oldModal)=>{
    //         return {
    //           ...oldModal,
    //           [params.row.myUniqueKey]:{variantDetails:{ mode: GridCellModes.Edit }}
    //         }
    //       })
    //       setDisabledVarDetailsIds(prev=>({...prev,[params.row.myUniqueKey]:false}))

    //       setIsVariantDetails(true)
    //     }
    //     return value
    //   },

    // },
    // {
    //   field: "variantDetails",
    //   headerName: "Varient Details",
    //   width: 160,
    //   editable:isVariantDetails,

    //   cellClassName: (params) => {
    //    if (!disabledVarDetailsIds[params.row.myUniqueKey]){
    //     return ''
    //    }

    //   return clsx('super-app', {
    //       disabled:true
    //     });
    //   },
    // },
    {
      field: "currency",
      headerName: "Business Currency*",
      width: 200,
      editable: true,
      type: "singleSelect",
      valueOptions: dropdowns?.currency,
      cellClassName: (params) => {
        if (
          !fieldValidations[params.row.myUniqueKey] ||
          !fieldValidations[params.row.myUniqueKey].currency
        ) {
          return "";
        }

        return clsx("super-app", {
          fieldError: true,
        });
      },
    },
    {
      field: "retailerPrice",
      headerName: "Retailer Price*",
      width: 200,
      editable: true,
      type: "number",
      headerAlign: "left",
      align: "left",
      cellClassName: (params) => {
        if (
          !fieldValidations[params.row.myUniqueKey] ||
          !fieldValidations[params.row.myUniqueKey].retailerPrice
        ) {
          return "";
        }

        return clsx("super-app", {
          fieldError: true,
        });
      },
    },
    // {
    //   field: "isUnlimitSample",
    //   headerName: "Is Unlimit Sample",
    //   width: 240,
    //   editable: true,
    //   type: "singleSelect",
    //   valueOptions: ["yes", "no"],
    // cellClassName: (params) => {
    //   if (!fieldValidations[params.row.myUniqueKey]|| !fieldValidations[params.row.myUniqueKey].isUnlimitSample){
    //    return ''
    //   }

    //  return clsx('super-app', {
    //      fieldError:true
    //    });
    //  },
    // valueParser: (value, params) => {
    //   if (value=="yes"){
    //     setCellModesModel((oldModal)=>{
    //       return {
    //         ...oldModal,
    //         [params.row.myUniqueKey]:{sampleOrder:{ mode: GridCellModes.View, ignoreModifications: true  }}
    //       }
    //     })
    //     setDisabledSampleOrderIds(prev=>({...prev,[params.row.myUniqueKey]:true}))
    //     setIsUnlimitSample(false)
    //   }else{
    //     setCellModesModel((oldModal)=>{
    //       return {
    //         ...oldModal,
    //         [params.row.myUniqueKey]:{sampleOrder:{ mode: GridCellModes.Edit }}
    //       }
    //     })
    //     setDisabledSampleOrderIds(prev=>({...prev,[params.row.myUniqueKey]:false}))
    //     setIsUnlimitSample(true)
    //   }
    //   return value
    // },
    // },
    // {
    //   field: "sampleOrder",
    //   headerName: "Sample Order",
    //   width: 200,
    //   editable: true,

    //   cellClassName: (params) => {
    //     if (!disabledSampleOrderIds[params.row.myUniqueKey]) {
    //       return "";
    //     }

    //     return clsx("super-app", {
    //       disabled: true,
    //     });
    //   },
    // },
    {
      field: "minOrderQuantity",
      headerName: "Min Order*",
      width: 170,
      editable: true,
      type: "number",
      headerAlign: "left",
      align: "left",
      cellClassName: (params) => {
        if (
          !fieldValidations[params.row.myUniqueKey] ||
          !fieldValidations[params.row.myUniqueKey].minOrderQuantity
        ) {
          return "";
        }

        return clsx("super-app", {
          fieldError: true,
        });
      },
    },
    {
      field: "minOrderQuantityUnit",
      headerName: "Min Order Qty Unit*",
      width: 240,
      editable: true,
      type: "singleSelect",
      valueOptions: ["Pcs", "Box"],
      cellClassName: (params) => {
        if (
          !fieldValidations[params.row.myUniqueKey] ||
          !fieldValidations[params.row.myUniqueKey].minOrderQuantityUnit
        ) {
          return "";
        }

        return clsx("super-app", {
          fieldError: true,
        });
      },
    },
    // {
    //   field: "isAvailableImmediate",
    //   headerName: "Is Available Immediate",
    //   width: 240,
    //   editable: true,
    //   type: "singleSelect",
    //   valueOptions: ["Yes", "No"],
    //   cellClassName: (params) => {
    //     if (!fieldValidations[params.row.myUniqueKey]|| !fieldValidations[params.row.myUniqueKey].isAvailableImmediate){
    //      return ''
    //     }

    //    return clsx('super-app', {
    //        fieldError:true
    //      });
    //    },
    // valueParser: (value, params) => {
    //   if (value==="Immediate"){
    //     setCellModesModel((oldModal)=>{
    //       return {
    //         ...oldModal,
    //         [params.row.myUniqueKey]:{availableFrom:{ mode: GridCellModes.View, ignoreModifications: true  },availabilityComment:{ mode: GridCellModes.View, ignoreModifications: true  }}
    //       }
    //     })

    //     setDisabledAvailabilityIds(prev=>({...prev,[params.row.myUniqueKey]:true}))
    //     setIsAvailableImmediate(false)
    //   }
    //   else{
    //     setCellModesModel((oldModal)=>{
    //       return {
    //         ...oldModal,
    //         [params.row.myUniqueKey]:{availableFrom:{ mode: GridCellModes.Edit },availabilityComment:{ mode: GridCellModes.Edit }}
    //       }
    //     })
    //     setIsAvailableImmediate(true)
    //     setDisabledAvailabilityIds(prev=>({...prev,[params.row.myUniqueKey]:false}))
    //   }
    //   return value
    // },
    // },
    // {
    //   field: "availableFrom",
    //   headerName: "Available From",
    //   width: 200,
    //   editable:isAvailableImmediate,
    //   valueGetter: (params) => {
    //     if (params.value){
    //       return new Date(params.value);
    //     }else{
    //       return new Date();
    //     }

    //   },
    //   type: "date",
    // },
    // {
    // field: "availabilityComment",
    // headerName: "Availability Comment",
    // width: 240,
    // editable: true,

    // cellClassName: (params) => {
    //   if (!disabledAvailabilityIds[params.row.myUniqueKey]) {
    //     return "";
    //   }

    //   return clsx("super-app", {
    //     disabled: true,
    //   });
    // },
    // },
    {
      field: "productVisibility",
      headerName: "Product Visibility*",
      width: 200,
      editable: true,
      type: "singleSelect",
      valueOptions: visibilityOptions,
      cellClassName: (params) => {
        if (
          !fieldValidations[params.row.myUniqueKey] ||
          !fieldValidations[params.row.myUniqueKey].productVisibility
        ) {
          return "";
        }

        return clsx("super-app", {
          fieldError: true,
        });
      },
      valueFormatter: ({ value }) => (value ? value.join("/") : ""),
      renderEditCell: CustomDiscountEditCell,
      filterOperators: [
        {
          value: "contains",
          getApplyFilterFn: (filterItem) => {
            if (filterItem.value == null || filterItem.value === "") {
              return null;
            }
            return ({ value }) => {
              // if one of the cell values corresponds to the filter item
              return value.some((cellValue) => cellValue === filterItem.value);
            };
          },
          InputComponent: CustomFilterInputSingleSelect,
        },
      ],
    },

    // {
    //   field: "retailProfiles",
    //   headerName: "Retail Profiles",
    //   width: 200,
    //   editable: isRetailProfiles,
    //   type: "singleSelect",
    //   valueFormatter: ({ value }) => (value ? value.join("/") : ""),
    //   renderEditCell: isRetailProfiles
    //     ? CustomRetailEditCell
    //     : (params) => <div></div>,
    //   valueOptions: relevantRetailersOptions,
    // },
    // {
    //   field: "resellerProfile",
    //   headerName: "Reseller Profiles",
    //   width: 200,
    //   editable: isResellProfiles,
    //   renderEditCell: isResellProfiles
    //     ? CustomResellerEditCell
    //     : (params) => <div></div>,
    //   valueOptions: relevantResellersOptions,
    //   type: "singleSelect",
    // },
    // {
    //   field: "targetZone",
    //   headerName: "Target Zone",
    //   width: 200,
    //   editable: true,
    //   type: "singleSelect",
    //   valueGetter: ({ value, colDef }) => {
    //     if (!value) {
    //       return targetLoc.targetZone;
    //     } else {
    //       return value;
    //     }
    //   },
    //   // valueOptions:targetLoc.targetZone,
    //   // renderCell:(params)=>targetLoc.targetZone,
    //   renderEditCell: CustomZoneEditCell,
    //   cellClassName: (params) => {
    //     if (!fieldValidations[params.row.myUniqueKey]|| !fieldValidations[params.row.myUniqueKey].targetZone){
    //      return ''
    //     }

    //    return clsx('super-app', {
    //        fieldError:true
    //      });
    //    },
    // },

    // {
    //   field: "targetRegion",
    //   headerName: "Target Region",
    //   width: 250,
    //   editable: true,
    //   type: "singleSelect",
    //   valueOptions: targetLoc.targetRegion,
    //   valueGetter: ({ value, colDef }) => {
    //     if (!value) {
    //       return targetLoc.targetRegion;
    //     } else {
    //       return value;
    //     }
    //   },
    //   renderEditCell: CustomRegionEditCell,
    //   cellClassName: (params) => {
    //     if (!fieldValidations[params.row.myUniqueKey]|| !fieldValidations[params.row.myUniqueKey].targetRegion){
    //      return ''
    //     }

    //    return clsx('super-app', {
    //        fieldError:true
    //      });
    //    },
    // },
    // {
    //   field: "targetCountry",
    //   headerName: "Target Country",
    //   width: 200,
    //   editable: true,
    //   type: "singleSelect",
    //   valueOptions: targetLoc.targetCountry,
    //   // valueGetter: ({ value, colDef }) => {
    //   //   if (!value) {
    //   //     return targetLoc.targetCountry;
    //   //   }
    //   // },
    //   renderEditCell: CustomCountryEditCell,
    //   cellClassName: (params) => {
    //     if (!fieldValidations[params.row.myUniqueKey]|| !fieldValidations[params.row.myUniqueKey].targetCountry){
    //      return ''
    //     }

    //    return clsx('super-app', {
    //        fieldError:true
    //      });
    //    },
    // },
    // {
    //   field: "productCertificate",
    //   headerName: "Product Certificate",
    //   width: 200,
    //   editable: true,
    // },
    // {
    //   field: "productWarranty",
    //   headerName: "Product Warranty",
    //   width: 200,
    //   editable: true,
    // },
    {
      field: "images",
      headerName: "Product Images",
      minWidth: 330,
      renderCell: (params) => {
        let toShow = [];

        if (uploadedImages.length > 0) {
          uploadedImages.forEach((item) => {
            if (item.rowId === params.row.myUniqueKey) {
              toShow = item.urls;
            }
          });
        }

        return (
          <div>
            <div
              style={{
                display: "flex",
                overflow: "auto",
                alignItems: "center",
              }}
            >
              <label
                onChange={(e) => {
                  handleImageUpload(e, params);
                }}
                htmlFor={params.row.myUniqueKey}
              >
                <input
                  type="file"
                  id={params.row.myUniqueKey}
                  accept=".jpg,.jpeg,.png"
                  multiple
                  hidden
                />
                <Button
                  variant="contained"
                  id="img-upload-btn"
                  sx={{
                    background: "#2c9db7",
                    margin: 2,
                    pointerEvents: "none",
                    borderRadius: "50%",
                    height: "30px",
                    minWidth: "unset",
                    width: "30px",
                  }}
                >
                  <AddIcon />
                </Button>
              </label>

              {toShow.length > 0 &&
                toShow.map((it, i) => (
                  <div
                    style={{
                      width: "50px",
                      height: "50px",
                      paddingRight: "10px",
                      position: "relative",
                    }}
                  >
                    <CancelIcon
                      onClick={() => deleteImage(params.row.myUniqueKey, i)}
                      style={{
                        position: "absolute",
                        top: "0",
                        right: "10px",
                        fontSize: "10px",
                        color: "red",
                        cursor: "pointer",
                      }}
                    />
                    <img style={{ height: "100%", width: "100%" }} src={it} />
                  </div>
                ))}
            </div>
          </div>
        );
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 150,
      pinned: "left",
      cellClassName: "actions",
      getActions: (params) => {
        const isInEditMode =
          rowModesModel[params.id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<CheckCircleIcon className="text-success" />}
              label="Save"
              onClick={handleSaveClick(params.id)}
            />,
            <GridActionsCellItem
              icon={<CloseIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(params.id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(params.id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(params.id)}
            color="inherit"
          />,
          params.row.isValid ? (
            <></>
          ) : (
            <GridActionsCellItem
              icon={
                <WarningIcon
                  style={{ color: params.row.isValid ? "" : "red" }}
                />
              }
              label="Errors"
              onClick={() => showIndividualError(params.row)}
              color="inherit"
              title="Errors"
            />
          ),
          <GridActionsCellItem
            icon={<ContentCopyIcon />}
            label="Errors"
            onClick={() => copyRow(params)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  const uploadToAws = async (data,file_name,temp,rowId)=>{
        let random = Math.random();
        let fileName = random + '.png';

        const formData = new FormData();
        let file = dataURLtoFile(data, fileName);
        formData.append('file',file);
        let aws_folder = 'productImages';

        const resImagePath = await commonService.fileUploadService(formData, '/mediaupload/upload/' + aws_folder).catch(err => {
          commonService.postService({ err: err.response.data?.message, reqUrl: window.location.pathname, service: '/mediaupload/upload/' }, '/error')
        });

        if (resImagePath) {
          return { image: resImagePath.data.url, documentUrl: process.env.REACT_APP_CDN_URL_TEMP + resImagePath.data.url, filename: file_name};
          
        }else{
          return false
        }
  }

  console.log("kkasl",imagesFolder,rows)

  const handleImageUpload =async (e,params)=>{
    let urls = []
    for (let i=0;i<e.target.files.length;i++){
      urls.push(URL.createObjectURL(e.target.files[i]))
        

      let file = e.target.files[i];
      const maxSizeInBytes = 1 * 1024 * 1024;
      if (file && file.size > maxSizeInBytes) {
       
        ToastsStore.error(
          "The image size exceeds the allowed limit of 1 Mb"
        );
        return;
      }
      let file_name=e.target.files[i].name;
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend =async () => {
         let res =await uploadToAws(reader.result, file_name,params.row.myUniqueKey)
      
         if (res){
          res.rowId=params.row.myUniqueKey
          setImagesFolder((prev)=>[...prev,res])

        }
        
      };
      
        
    }
    

    let isNewRow=true
    uploadedImages.forEach(it=>{
      
      if (it.rowId===params.row.myUniqueKey){
        isNewRow=false
      }
      
    })
    let images;
    if (!isNewRow){
      images=uploadedImages.map(it=>{
        let newIt={...it}
        if (newIt.rowId===params.row.myUniqueKey){
          newIt.urls=[...newIt.urls,...urls]
          newIt.files=[...newIt.files,...e.target.files]
        }
  
        return newIt
      })

      

    }else{
      images=[...uploadedImages,{rowId:params.row.myUniqueKey,urls,files:e.target.files}]

      
    }
    setUploadedImages(images)
    
  }
  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setDisableRowPopup(true)
    setRowPopup(false)
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    
   
  };

  console.log("klsaks",imagesFolder)
  const deleteRow = ()=>{
    let newRows = rows.filter((row) => row.myUniqueKey !== toDeleteId);
    localStorage.setItem("startAddProd", JSON.stringify(newRows));
    setRows(newRows);
    setOpenDeleteModal(false);
    if (newRows.length!==rows.length){
      return ToastsStore.success("Product is deleted successfully.!")
    }
  }

  const handleDeleteClick = (id) => () => {

    setOpenDeleteModal(true);
    setToDeleteId(id);
    
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.myUniqueKey === id);
    if (editedRow && editedRow.isNew) {
      setRows(rows.filter((row) => row.myUniqueKey !== id));
    }
  };


  const handleCellMode = (row)=>{
    setCellModesModel((oldModal)=>{
      return {
        ...oldModal,
        [row.myUniqueKey]:{availableFrom:{ mode: GridCellModes.View, ignoreModifications: true  }
        ,availabilityComment:{ mode: GridCellModes.View, ignoreModifications: true  }
        ,sampleOrder:{ mode: GridCellModes.View, ignoreModifications: true  }
        ,variantDetails:{ mode: GridCellModes.View, ignoreModifications: true  }}
      }
    })
  
    setIsVariantDetails(false)
   
    setIsAvailableImmediate(false)
   
    setIsUnlimitSample(false)
  }
 

  const processRowUpdate = (newRow) => {



    const fieldValidations = [
      {
        colName: 'Product Name',
        field: "productName",
        message: "Required field and it should be more than 3 characters long",
        validator: (newRow) => { return  newRow.productName.length<3 }
      },
      {
        colName: 'Product Category',
        field: "productCategory",
        message: "Required field and choose category from dropdown",
        validator: (newRow) => { return !newRow.productCategory }
      },
      {
        colName: 'Product Brand',
        field: "productBrand",
        message: "Required field and choose brand from dropdown",
        validator: (newRow) => { return !newRow.productBrand }
      },
      {
        colName: 'Product Short Desc.',
        field: "productShortDec",
        message: "Required field and must have min 3 and max 140 characters",
        validator: (newRow) => { return newRow.productShortDec ? !(newRow.productShortDec.length>3  && newRow.productShortDec.length<140):true }
      },
      {
        colName: 'Tweet Pitch',
        field: "tweetPitch",
        message: "Required field and must have min 5 and max 280 characters",
        validator: (newRow) => { return !newRow.tweetPitch || newRow.tweetPitch.length <5 || newRow.tweetPitch.length>280 }
      },
      {
        colName: 'Business Type',
        field: "businessType",
        message: "Required field and choose from dropdown",
        validator: (newRow) => { return !newRow.businessType }
      },
      // {
      //   colName: 'Is Variant',
      //   field: "IsVariant",
      //   message: "Required field and does product exist is different colors,sizes.",
      //   validator: (newRow) => { return !newRow.IsVariant }
      // },
      // {
      //   colName: 'Variant Details',
      //   field: "variantDetails",
      //   message: 'If is variant is "Yes" then it is required',
      //   validator: (newRow) => { return newRow.IsVariant?newRow.IsVariant=='yes' && !newRow.variantDetails:false }
      // },
      {
        colName: 'Currency',
        field: "currency",
        message: "Required field and choose from dropdown",
        validator: (newRow) => { return !newRow.currency }
      }
      ,
      {
        colName: 'Retailer Price',
        field: "retailerPrice",
        message: "Required field and cannot be less than 0",
        validator: (newRow) => { return !newRow.retailerPrice || +newRow.retailerPrice<0}
      }
      // ,
      // {
      //   colName: 'Is Unlimit Sample',
      //   field: "isUnlimitSample",
      //   message: "Required field and choose from dropdown ",
      //   validator: (newRow) => { return !newRow.isUnlimitSample}
      // }
      // ,{
      //   colName:"Sample Order",
      //   field: "sampleOrder",
      //   message: 'If Is Unlimit Sample is "No" then it is required',
      //   validator:(newRow)=>{return newRow.isUnlimitSample?newRow.isUnlimitSample=='no' && !newRow.sampleOrder:false}
      // }
      ,
      {
        colName: 'Min Order Quantity',
        field: "minOrderQuantity",
        message: "Required field and cannot be less than 0",
        validator: (newRow) => { return !newRow.minOrderQuantity || +newRow.minOrderQuantity<0}
      }
      ,
      {
        colName: 'Min Order Quantity unit',
        field: "minOrderQuantityUnit",
        message: "Required field",
        validator: (newRow) => { return !newRow.minOrderQuantityUnit}
      }
    //  ,
    //   {
    //     colName: 'Is Available Immediate',
    //     field: "isAvailableImmediate",
    //     message: "Required field",
    //     validator:(newRow)=>{return !newRow.isAvailableImmediate }
    //   }
      // ,
      // {
      //   colName: 'Available From',
      //   field: "availableFrom",
      //   message: 'If is available immediate is "No" then it is required',
      //   validator:(newRow)=>{return  newRow.isAvailableImmediate? newRow.isAvailableImmediate && newRow.isAvailableImmediate.toLowerCase().trim() == 'available from' && !newRow.availableFrom :false}
      // }
      ,{
        colName: "Product Visibility",
        field: "productVisibility",
        message: 'Required field',
        validator:(newRow)=>{return !newRow.productVisibility}
      }
      // ,{
      //   colName: "Target Zone",
      //   field: "targetZone",
      //   message: 'Required field',
      //   validator:(newRow)=>{return !newRow.targetZone}
      // }
      // ,{
      //   colName: "Target Region",
      //   field: "targetRegion",
      //   message: 'Required field',
      //   validator:(newRow)=>{return !newRow.targetRegion}
      // }
      // ,{
      //   colName: "Target Country",
      //   field: "targetCountry",
      //   message: 'Required field',
      //   validator:(newRow)=>{return !newRow.targetCountry}
      // }
    ]
   
    let errors = [];
    let fieldVals={}
    for(let n=0 ; n< fieldValidations.length; n++){
      let fieldNotValid = fieldValidations[n].validator(newRow);
      if(fieldNotValid) {
        errors.push(fieldValidations[n]);
        fieldVals[fieldValidations[n].field]=true
      }
     
    }
       
    let isValid = errors.length > 0?false:true;

    setValidations(errors)

    if (!isValid){
      showAllErrors()
      setValidationsFor(newRow.productName?newRow.productName:"")
      setRowModesModel((oldModel) => {
          return {
           ...oldModel,
           [newRow.myUniqueKey]: { mode: GridRowModes.Edit, fieldToFocus: errors[0].field },
         }});
    
    }

    newRow.isValid=isValid

    const updatedRow = { ...newRow, isNew: false };

    let newRows = rows.map((row) =>row.myUniqueKey === newRow.myUniqueKey ? updatedRow : row);
    
    setRows(newRows);

    
    localStorage.setItem("startAddProd", JSON.stringify(newRows));
    setFieldValidations((prev)=>({...prev,[newRow.myUniqueKey]:fieldVals}))
    localStorage.setItem("uploadedImages", JSON.stringify(imagesFolder));
    localStorage.setItem("toShow", JSON.stringify(uploadedImages));
    if (!isValid){
      return
    }

    handleCellMode(updatedRow);

    return updatedRow;
  };

 

  

  function EditToolbar(props) {
    const { setRows, setRowModesModel } = props;

    const handleClick = () => {
      const key = randomInteger();
      setRows((oldRows) => [
        { myUniqueKey: key, productName: "", isNew: true,copiedFrom:key },
        ...oldRows,
      ]);

      setRowModesModel((oldModel) => {
    
     return {
        ...oldModel,
        [key]: { mode: GridRowModes.Edit, fieldToFocus: "productName" },
      }});

     
    };

    return (
      <>
        <Button color="primary"  startIcon={<AddIcon />} onClick={handleClick}>
          Add Record
        </Button>
      </>
    );
  }



  function CustomUserItem(props) {
    const { myCustomHandler, myCustomValue, colHeaderName } = props;

    return (
      <MenuItem onClick={myCustomHandler}>
        <ListItemIcon>
          <SettingsApplicationsIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>{myCustomValue}</ListItemText>
      </MenuItem>
    );
  }

  function CustomUserItemUnfreeze(props) {
    const { myCustomHandler, myCustomValue, colHeaderName } = props;

    return (
      <MenuItem onClick={myCustomHandler}>
        <ListItemIcon>
          <SettingsApplicationsIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>{myCustomValue}</ListItemText>
      </MenuItem>
    );
  }

  const freezeUpto = (colHeaderName,unfreeze=false) => {
    let newFree = getFreeze(colHeaderName,columns,gridClasses, unfreeze)
    let colToFreeze=-1

    if (!unfreeze){
      columns.forEach((col, i) => {
        if (col.headerName === colHeaderName) {
          colToFreeze = i;
        }
      });
           
      setFreezeColIndex(colToFreeze)
      
    }else{
      setFreezeColIndex(colToFreeze)
    }
    

    setFreezingOptions((prev)=>({...prev,...newFree}));
    if (!unfreeze){ 
      setShowUnFreeze(true)

    }else{
      setShowUnFreeze(false)

    }
    

  };

  console.log("freezeColIndex",freezeColIndex)

 

  function CustomColumnMenu(props) {
    let gg ={
      // Add new item
      columnMenuUserItem: CustomUserItem,
      columnMenuUserItemUnfreeze:CustomUserItemUnfreeze
  
    }
    if(!showUnFreeze) gg ={
      // Add new item
      columnMenuUserItem: CustomUserItem,
  
    }

    return (
      <GridColumnMenu
        {...props}
        slots={gg}
        slotProps={{
          columnMenuUserItem: {
            // set `displayOrder` for new item
            displayOrder: 10,
            colHeaderName: props.colDef.headerName,
            // pass additional props
            myCustomValue: "Freeze Columns Upto",
            myCustomHandler: () => freezeUpto(props.colDef.headerName),
          },

          columnMenuUserItemUnfreeze:{
            displayOrder: 10,
            myCustomValue: "Unfreeze Columns",
            myCustomHandler: () => freezeUpto(props.colDef.headerName,true),

          }
        }}
      />
    );
  }
    const getProductLimit = async () => {
      let featureList = userData.featureList;
      console.log("my feature lists  ======== ");
      
      if (featureList && featureList[userConstants.NO_OF_PRODUCT]) {
        setProductLimit(featureList[userConstants.NO_OF_PRODUCT]);
      }
      
    };

  const handleSubmitProds = async () => {
    let areRowsNotValid = rows.some((ele) => !ele.isValid);

    if (areRowsNotValid) {
      return ToastsStore.error("Please save each row to submit!");
    }

    setIsLoading(true);
       let postData = {
         filter: {
           position: null,
           brand: null,
           family: null,
           country: null,
           location: null,
           profile: null,
           category: null,
           partActive:null,
           isPending:null,
         },
         isApproved: null,
         isactive: "1",
         limit: 10,
         offset: 0,
         order: "asc",
         search: null,
       };
      let res = await commonService.postService(postData, 'product/my-products').catch(err => setIsLoading(false));
          tabCount.active = res.data.tabCount.active;
          setTabCount(tabCount);
        let productCount = parseInt(tabCount.active);
        // if (productLimit > productCount || productLimit === "Unlimited") {
       
        // } else {
        //   setplanUpgrade(true);
        //   return
        // }
      
    
    

    let prodRows=JSON.parse(JSON.stringify(rows))

    let imgFol = [...imagesFolder];


    for (let i=0;i<prodRows.length;i++){
      if (prodRows[i].productVisibility.length>0){
        prodRows[i].productVisibility=prodRows[i].productVisibility.join('|')
      }

      let imgsForCurrRow=[]
      for (let j=0;j<imgFol.length;j++){
        if (prodRows[i].myUniqueKey===imgFol[j].rowId){
          if (imgsForCurrRow.length===0){
            imgFol[j].isdefault=1
          }else{
            imgFol[j].isdefault=0
          }
          imgsForCurrRow.push(imgFol[j])
        }
      }
      prodRows[i].productImages={
        productimage:imgsForCurrRow
      }
      prodRows[i].isUnlimitSample='no'
      prodRows[i].IsVariant='no'
      prodRows[i].isAvailableImmediate='Immediate'
      prodRows[i].retailProfiles=relevantRetailersOptions;
      prodRows[i].resellerProfile=relevantResellersOptions;
      prodRows[i].targetZone = targetLoc.targetZone;
      prodRows[i].targetRegion = targetLoc.targetRegion;
      prodRows[i].targetCountry = targetLoc.targetCountry;

    }

    let bodyData = { result: prodRows, fileName: "", imageFolder: null };

    let {data}= await commonService
      .postService(bodyData, "productImport/productBulkUpload")
      .catch((err) =>{ console.log(err)
        setIsLoading(false)
        return ToastsStore.error("There is an error in uploading products!")
      });

      if(data.totalCount == undefined){
        console.log(data)
        let error = "Product Name "
        const duplicateProducts = [];
        setIsLoading(false) 
          for (let i = 0; i < data.duplicates.length; i++) {
              const str = data.duplicates[i];
              const productName = str.replace("Product Name already exist in ChannelHub", "").trim();
              duplicateProducts.push(productName);  
              error = "Product Name (" + duplicateProducts.join(",") +") already exist in ChannelHub"; 
            }
        return ToastsStore.error(error)
      }

      setIsLoading(false)
      if (data && data.uploadedCount){
        history.push(`/admin/startupprodimport/startupprodimportsuccess`,{data:prodRows});
        localStorage.removeItem("startAddProd");
        localStorage.removeItem("toShow");
        localStorage.removeItem("uploadedImages");
        return ToastsStore.success("All products are uploaded!")
      }


  };

const closeplanUpgrade = () => {
  setplanUpgrade(false);
};

  let virScroll;

  const freezeHeader = (xTransform)=>{
    let colHeaders=document.querySelectorAll('.MuiDataGrid-columnHeader')
    let xValue=Math.abs(xTransform);


    if (freezeColIndex>-1){
      
      for (let i=0;i<=freezeColIndex;i++){
        colHeaders[i].style.transform=`translate3d(${xValue}px,0,0)`
        colHeaders[i].style['z-index'] = 100;
        colHeaders[i].style.background = 'white';
      }
    }
    else{
      for (let i=0;i<colHeaders.length;i++){
        colHeaders[i].style.transform=``
        colHeaders[i].style['z-index'] = 10;
        colHeaders[i].style.background = 'none';
      }
    }
  }

  const [scrol,setScrol]=useState(0)


   
  const getTransformVals = ()=>{
    setRowPopup(false);
    let tableHeading=document.querySelector('div[role="rowgroup"]');
    console.log("trransformss",tableHeading.style.transform)
    let splited=tableHeading.style.transform.split('translate3d(')[1]
    console.log('kkkkk',splited.split('px')[0])
    freezeHeader(splited.split('px')[0]);
  }

  if (!virScroll){
    virScroll=document.querySelector('.MuiDataGrid-virtualScroller');
    console.log("lllll",virScroll)
    if (virScroll){
      virScroll.addEventListener('scroll',getTransformVals)
    }
  }

  const openModal = () => {
    setShowModal(!showModal);
    setPopup(false);
    setRowPopup(false);
  };
  function closeModal() {
    setShowModal(false);
  }

  
  const closePopup=()=>{
    setPopup(false)
  }
  const closeRowPopup=()=>{setRowPopup(false)}


  let nameHeaders;

  if (!nameHeaders) {
    nameHeaders = document.querySelectorAll(".MuiDataGrid-columnHeader");
    console.log("nameHEaders", nameHeaders);
    let colHeadersCont = document.querySelector(".MuiDataGrid-columnHeaders");
    if (nameHeaders.length > 0) {
      for (let i = 0; i < nameHeaders.length; i++) {
        let query="div[data-colindex="
        query+=`"${i}"]`

        console.log(query)
        let cells = document.querySelectorAll(query);
    
         nameHeaders[i].addEventListener("mouseenter", () => {
           if (i >= 0 && i<tourData.length) {
             setItemIndex(i);
             setPopup(true);
             setRowPopup(false)
             setShowModal(false)
           }
         });
         nameHeaders[i].addEventListener("mouseout", () => {
           setPopup(false);
         });
      
         
        nameHeaders[i].addEventListener("mousemove", (e) => {
          console.log(e.buttons);

          console.log("cellHEaders", cells,query);
          nameHeaders[i].style.cursor="col-resize";
          let buttonPressed=e.buttons ?true:false
          if (buttonPressed) {
            let desiredX = nameHeaders[i].getBoundingClientRect().left;
            let desiredY = nameHeaders[i].offsetTop;

            // colHeadersCont.style.minHeight =
            // Math.abs(e.pageY - colHeadersCont.getBoundingClientRect().top) +
            // 15 +
            // "px";

            // colHeadersCont.style.maxHeight =
            // Math.abs(e.pageY - colHeadersCont.getBoundingClientRect().top) +
            // 15 +
            // "px";


            // colHeadersCont.style.position = "relative";
            // nameHeaders[i].style.minWidth = Math.abs(e.pageX - desiredX) + 15+"px";
            // nameHeaders[i].style.maxWidth = Math.abs(e.pageX - desiredX)+ 15 + "px";
            // nameHeaders[i].style.width = Math.abs(e.pageX - desiredX)+ 15 + "px";

            // for (let j = 0; j < cells.length; j++) {
            //   cells[j].style.minWidth = Math.abs(e.pageX - desiredX) + 15+ "px";
            //   cells[j].style.maxWidth = Math.abs(e.pageX - desiredX)+ 15 + "px";
            //   cells[j].style.width = Math.abs(e.pageX - desiredX)+ 15 + "px";
            //   // cells[j].style.maxHeight = Math.abs(e.pageY - desiredY)-180 + "px";
            //   // cells[j].style.minHeight = Math.abs(e.pageY - desiredY)-180+ "px";
            // }

          
        
            console.log("dragg",nameHeaders[i].style.width);
            if ( nameHeaders[i].style.width.split('px')[0]>140 
            && nameHeaders[i].style.width.split('px')[0]<500 
            ){
                nameHeaders[i].style.minWidth = Math.abs(e.pageX - desiredX)+ 15 + "px";
                nameHeaders[i].style.maxWidth = Math.abs(e.pageX - desiredX)+ 15 + "px";
                nameHeaders[i].style.width = Math.abs(e.pageX - desiredX) +15+ "px";

                for (let j = 0; j < cells.length; j++) {
                  cells[j].style.minWidth = Math.abs(e.pageX - desiredX)+ 15 + "px";
                  cells[j].style.maxWidth = Math.abs(e.pageX - desiredX) + 15+ "px";
                  cells[j].style.width = Math.abs(e.pageX - desiredX) + 15+ "px";
                  // cells[j].style.maxHeight = Math.abs(e.pageY - desiredY) + "px";
                  // cells[j].style.minHeight = Math.abs(e.pageY - desiredY) + "px";
                }
            }

          }

          if(nameHeaders[i].style.width.split('px')[0]<=200){
            nameHeaders[i].style.minWidth = columns[i].width + "px";
            nameHeaders[i].style.maxWidth = columns[i].width + "px";
            nameHeaders[i].style.width = columns[i].width + "px";

            for (let j = 0; j < cells.length; j++) {
              cells[j].style.minWidth = columns[i].width + "px";
              cells[j].style.maxWidth = columns[i].width + "px";
              cells[j].style.width = columns[i].width + "px";
              // cells[j].style.maxHeight = Math.abs(e.pageY - desiredY) + "px";
              // cells[j].style.minHeight = Math.abs(e.pageY - desiredY) + "px";
            }

                buttonPressed=false


            }
            else if(nameHeaders[i].style.width.split('px')[0]>=500){
              nameHeaders[i].style.minWidth = 499 + "px";
              nameHeaders[i].style.maxWidth = 499+ "px";
              nameHeaders[i].style.width = 499 + "px";

              for (let j = 0; j < cells.length; j++) {
                cells[j].style.minWidth =499 + "px";
                cells[j].style.maxWidth = 499 + "px";
                cells[j].style.width = 499 + "px";
                // cells[j].style.maxHeight = Math.abs(e.pageY - desiredY) + "px";
                // cells[j].style.minHeight = Math.abs(e.pageY - desiredY) + "px";
              }

              buttonPressed=false
            }
        
          
       
        });
      }
    }
  }

  return (
    <>
      {isLoading && <Spinner />}
      {popup && (
        <Tourstep
          closeModal={closePopup}
          tourData={tourData}
          itemIndex={itemIndex}
        />
      )}
      {rowPopup && !disableRowPopup && (
        <Tourstep3
          tourData={tourData}
          itemIndex={rowItemIndex}
          closeModal={closeRowPopup}
        />
      )}

      <div className="container-fluid">
        <div className="d-flex justify-content-end align-items-center">
          <Button
            variant="contained"
            sx={{
              height: "35px",
              background: "#2c9db7",
              marginTop: 2,
              marginRight: 2,
            }}
            onClick={openModal}
          >
            Product Tour
          </Button>
          {showModal && (
            <Tourstep2
              closeModal={closeModal}
              tourData={tourData}
              scrol={scrol}
            />
          )}
          <Button
            variant="contained"
            sx={{ height: "35px", background: "#2c9db7", marginTop: 2 }}
            onClick={handleSubmitProds}
          >
            Submit
          </Button>
        </div>
         {planUpgrade && <PlanFeatureDialog closeUserView={closeplanUpgrade} UpgradeType={upgradeType} />}

        <StyledBox
          sx={{
            height: 600,
            width: "100%",
            marginTop: 2,
            "& .super-app.disabled": {
              backgroundColor: "#e9e9e9 !important",
            },
            "& .super-app.fieldError": {
              border: "1px solid red",
              borderRadius: "6px",
            },
          }}
        >
          <DataGrid
            experimentalFeatures={{ newEditingApi: true }}
            rows={rows}
            columns={columns}
            editMode="row"
            getRowId={(row) => row.myUniqueKey}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
              pinnedColumns: { left: ["actions"] },
            }}
            pageSizeOptions={[10]}
            cellModesModel={cellModesModel}
            disableRowSelectionOnClick
            rowModesModel={rowModesModel}
            onProcessRowUpdateError={(error) =>
              console.log("processError", error)
            }
            onRowEditStart={handleRowEditStart}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            slots={{ toolbar: EditToolbar, columnMenu: CustomColumnMenu }}
            slotProps={{ toolbar: { setRows, setRowModesModel }, }}
            sx={freezingOptions}
            columnBuffer={columns.length}
            loading={isRowLoading}
          />
        </StyledBox>

        <Modal
          open={openErrorModal}
          onClose={() => setOpenErrorModal(false)}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <Box sx={{ ...style }}>
            <div className="d-flex justify-content-between">
              <h2 id="child-modal-title">
                Field Validations for {validationsFor}
              </h2>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => setOpenErrorModal(false)}
              >
                <CloseIcon />
              </div>
            </div>

            <table style={{ fontSize: "13px" }}>
              {validations.length > 0 &&
                validations.map((val) => (
                  <tr>
                    <td className="px-2">{val.colName}</td>
                    <td>{val.message}</td>
                  </tr>
                ))}
            </table>
          </Box>
        </Modal>

        <Modal
          open={openDeleteModal}
          onClose={() => setOpenDeleteModal(false)}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <Box sx={{ ...style }}>
            <div className="d-flex justify-content-end">
              <div
                style={{ cursor: "pointer" }}
                onClick={() => setOpenDeleteModal(false)}
              >
                <CloseIcon />
              </div>
            </div>
            <div>
              <p>Are you sure you want to delete this product?</p>
            </div>
            <div className="d-flex justify-content-end">
              <Button
                variant="contained"
                sx={{ height: "35px", background: "#2c9db7", margin: 2 }}
                onClick={() => setOpenDeleteModal(false)}
              >
                cancel
              </Button>
              <Button
                variant="contained"
                color="error"
                sx={{ height: "35px", margin: 2 }}
                onClick={() => deleteRow()}
              >
                Delete
              </Button>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
}

export default StartProdImport;
