import * as yup from 'yup';

var currentYear= new Date().getFullYear(); 


const GeneralFormSchema = yup.object().shape({
  // companyLogo: yup.string().required('Please Select Profile Image'),
  // empid: yup.string().required('Please Enter Employee Id'),
  // hscontact: yup.string().required('Please Enter HS Contact Id'),
  firstName: yup.string().required('Please Enter First Name').min(2, 'First Name Must Have Min 2 Characters').max(30, 'First Name Must Have Max 30 Characters'),
  lastName: yup.string().required('Please Enter Last Name').max(30, 'Last Name Must Have Max 30 Characters'),
  mobile: yup.string().required('Please Enter Mobile No').min(4, 'Mobile No Must Have Min 4 Numbers').
  matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{1,4}?$/,
  'Please Enter Valid Mobile Number').max(20, 'Mobile No Must Have Max 20 Numbers'),
  phone: yup.lazy(value => !value ? yup.string() : yup.string().min(4, 'Mobile No Must Have Min 4 Numbers').
  matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{1,4}?$/,
  'Please Enter Valid Phone Number').max(20, 'Phone No Must Have Max 20 Numbers')),
  email: yup.string().required('Please Enter Email Address').email('Please Enter a valid Email Address'),  
  country: yup.string().required('Please Select Your country'),
  jobposition: yup.string().required('Please Enter Job Position').min(2, 'Job Position Must Have Min 2 Characters'),
  reportingTo: yup.string(),
  TwitterURL: yup.lazy(value => !value ? yup.string() : yup.string()
  .matches(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/, 'Please Enter Valid Url.')),
  LinkedinURL: yup.lazy(value => !value ? yup.string() : yup.string()
  .matches(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/, 'Please Enter Valid Url.')),
});





const TradeInformationSchema = yup.object().shape({
   // intProductCat: yup.string().when("typeId", {is: (typeId) => (typeId==4||typeId==3),then: yup.string().required('Please Select Interested Product Family')}),
    productCat:yup.string().required('Please Select a Product Family'),
});

const TradeLocationSchema = yup.object().shape({

  target:yup.string().when("typeId",{is:  (typeId) => (typeId==2),then:yup.string().required('Please Choose Target Locations')}),
  selling:yup.string().when("typeId",{is: (typeId) => (typeId==4 || typeId==3 || typeId==5),then:yup.string().required('Please Choose Selling Locations')}),
  
});


const AddprofileSchema = yup.object().shape({
  
  selling:yup.string().when("typeId",{is: (typeId) => (typeId==4 || typeId==3),then:yup.string().required('Please Choose Selling Locations')}),
  target:yup.string().when("typeId",{is: (typeId) => (typeId==2 || typeId==3),then:yup.string().required('Please Choose Target Locations')}),
 

  productCat:yup.string().required('Please Select a Product'),
  
    CommunicationPreference1: yup.string().required('Please Select Communication Preference 1'),
    CommunicationPreference2: yup.string().required('Please Select Communication Preference 2'),
    companyLogo: yup.string().required('Please Select Profile Image.'),
});


export { GeneralFormSchema,  TradeInformationSchema, TradeLocationSchema,AddprofileSchema };