import React , { useEffect } from 'react';
import  { useState} from "react";
import { useForm} from "react-hook-form";
import {Button, Modal,Col,Row} from "reactstrap";
import { FormTextArea } from "_components/FormElements/FormInput";
import {FormAutocomplete} from "_components/FormElements/FormInput";
import { commonService } from '_services/common.service';
import {ToastsContainer, ToastsStore} from 'react-toasts';
import { PostYourBuyingNeed } from './../../pages/profile/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
export  const PostbuyingneedPopup=(props)=> {
  const [state, setState] = useState({ categoriesOption:[], categories:[] })
  let [ note, setNote ] = useState(null);
  let [ loading, setLoading ] = useState(true);
  const {open,PostClose}=props;
  var userData = JSON.parse(localStorage.getItem('CHuser'));
  const { register, handleSubmit, control, errors } = useForm({      
    mode:'onBlur',  
    resolver: yupResolver(PostYourBuyingNeed),
  });
  useEffect(() => {
    setCatOptions();
  }, []);
  // const Postclose=()=>{
  //   PostClose();
  // }
  console.log('errors',errors);
  const setCatOptions= async()=>{
    // let data=await commonService.getServices('/channelAdmin/buyerNeed');
    // console.log('channelAdmin', data);
    const res = await commonService.getServices('channel-infos');
    if(res){
      let data = res.data;
      let cat =[];
      data.productCategory.map(x=>{
        cat.push({name:x.productCategorie, id:x.productCategorieId });
      });
      state.categoriesOption=cat;
      setState(state);
      console.log('state',state);
    }
    setLoading(false);
  }
const handleChange= e =>{
  console.log(e.target.value)
  note = e.target.value;
  setNote(note);
  console.log('note', note);
}

const handleChangeSelect = (input,value) => {
    console.log('input',input,'value',value);
    state[input]=value;
    setState({...state });
  }
  const save = async(e) => {
    let ids = state.categories.map(x=> {return x.id})
    console.log('ids', ids);
    let data ={
      catid: ids,
      channelid: userData.channelId,
      contactid: userData.contactId,
      data: note
    }
    const res = await commonService.postService(data, 'channel/buyerNeed');
    if(res){
      ToastsStore.success('Post submitted successfully');
    }else{
      ToastsStore.warning('Something went wrong!...');
    }
    PostClose();
  }
  const handleClose= (e) =>{
    e.preventDefault();
    PostClose();
  }
  return (
    <div>
      <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore}/>
      <Modal className="modal-dialog-centered" isOpen={open}
      size='lg'>
    {!loading && <div className="modal-body pad-less">
        <Row>
          <Col lg="6" className="pad-less">
            <div className="img-pbn">
             <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  className="img-pbn" src={require("assets/img/wiriting.jpg")}/>
            </div>
          </Col>
          <Col lg="6" className="need">
            <div className="text-center">
            <h1 className="text-center">Post Your Buying Need</h1>
            </div>
            <div>
            <div className="form-group">        

            <FormAutocomplete
              id="Categories"
              name="Categories"
              label="Categories"
              options={state?.categoriesOption}
              placeholder="Select Categories"
              multiple
              disableCloseOnSelect
              limitTags={1}
              control={control}                         
              input='categories'
              value={ state?.categories }
              handleChangeSelect={handleChangeSelect}
              popupIcon=''
              disablePortal={true}
              size='small'
              ListboxProps={{ style: { maxHeight: '8rem' } }}
              errMessage={'Please Select a Product Category'}
              error={errors.Categories }
              />
            </div>
            </div>
            <div>
            <form>
                <FormTextArea
                  className="buytext"
                  name="note"
                  id="note" 
                  rows="5"
                  type="textarea"
                  label=""
                  placeholder="Add a personal note (max 200 characters)"
                  register={register}
                  value={note}
                  onChange={handleChange}
              />
              </form> 
            </div>
            <div className=" text-center mt-4">
                <Button color="secondary" data-dismiss="modal" type="button" onClick={e=>handleClose(e)} >Cancel</Button>
                <Button className='btn btn-primary' onClick={handleSubmit(save)} data-dismiss="modal" type="button" >Confirm</Button>
            </div> 
          </Col>
        </Row>
    </div>}
        </Modal>
    </div>
  );
}

const POstoptions = [
  {
    id: "1",
    name: "Yes",
  },
  {
    id: "0",
    name: "No",
  },
 
];
export default  PostbuyingneedPopup;