
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Container } from '@material-ui/core';
import Navbarg from "components/Navbars3/GuestNavbar";
import Footer from './Footer';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardImg,
    CardImgOverlay,
    CardTitle,
    CardText,
    ListGroupItem,
    ListGroup,
    Row,
    Col
  } from "reactstrap";
const useStyles = makeStyles((theme) => ({
root: {
width: '100%',
},
heading: {
fontSize: theme.typography.pxToRem(15),
flexBasis: '33.33%',
flexShrink: 0,
},
secondaryHeading: {
fontSize: theme.typography.pxToRem(15),
color: theme.palette.text.secondary,
},
}));

export default function ControlledAccordions(props) {
const classes = useStyles();
const [expanded, setExpanded] = React.useState(false);

let path=props.location.pathname;

const handleChange = (panel) => (event, isExpanded) => {
setExpanded(isExpanded ? panel : false);
};

return (
<>
<Navbarg path={path}/>   
<Container>
 
<div className={classes.root}>
 <Card className="p-2 mt-7">
     <CardBody>
         <div className="text-center">
         <h1>FAQ</h1>
         </div>
     </CardBody>
     </Card>   
<Accordion className="Faq" expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
<AccordionSummary
expandIcon={<ExpandMoreIcon />}
aria-controls="panel1bh-content"
id="panel1bh-header"
>
<Typography className={classes.heading}>What is ChannelHub?</Typography>
</AccordionSummary>
<AccordionDetails>
<Typography>
ChannelHub is an international platform that brings a new dimension to Consumer tech channel. It instantly connects all actors in the distribution ecosystem: vendors, distributors/importers, retailers and independent business developers to accelerate the time-to-market new products.
</Typography>
</AccordionDetails>
</Accordion>
<Accordion className="Faq" expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
<AccordionSummary
expandIcon={<ExpandMoreIcon />}
aria-controls="panel2bh-content"
id="panel2bh-header"
>
<Typography className={classes.heading}>Do I need a credit card to register?</Typography>
</AccordionSummary>
<AccordionDetails>
<Typography>
No, credit card is only required when you book a premium paid package. No credit card is required at the application time.
</Typography>
</AccordionDetails>
</Accordion>
<Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
<AccordionSummary
expandIcon={<ExpandMoreIcon />}
aria-controls="panel3bh-content"
id="panel3bh-header"
>
<Typography className={classes.heading}>What is the geographic target of ChannelHub and what is the population?
</Typography>
</AccordionSummary>
<AccordionDetails>
<Typography>
ChannelHub targets mainly distributors and retailers from EMEA region (Europe, Middle East&Africa). Later we will open APAC and AMERICAS regions. On the vendors side, we deal worldwide.
</Typography>
</AccordionDetails>
</Accordion>
<Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
<AccordionSummary
expandIcon={<ExpandMoreIcon />}
aria-controls="panel4bh-content"
id="panel4bh-header"
>
<Typography className={classes.heading}>Can I sell or buy products on ChannelHub?</Typography>
</AccordionSummary>
<AccordionDetails>
<Typography>
No, ChannelHub is not a marketplace and it’s not a trading platform either. We don’t sell products. Our main objective is to create easier and faster connections between buyers and vendors in order to squeeze the time-to-market. The only transaction allowed on the platform is related to sample ordering.
</Typography>
</AccordionDetails>
</Accordion>
<Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
<AccordionSummary
expandIcon={<ExpandMoreIcon />}
aria-controls="panel1bh-content"
id="panel1bh-header"
>
<Typography className={classes.heading}>Do I need to invest a lot of time on ChannelHub?</Typography>
</AccordionSummary>
<AccordionDetails>
<Typography>
No it’s the contrary, ChannelHub helps you save time and money by bringing in front of you qualified buyers (if you ar ea vendor) or selected products (if you are a buyer) and these contacts are just a click away; Our platform is based on a matching algorithm that will pre-select information based on your profile.
</Typography>
</AccordionDetails>
</Accordion>
<Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
<AccordionSummary
expandIcon={<ExpandMoreIcon />}
aria-controls="panel1bh-content"
id="panel1bh-header"
>
<Typography className={classes.heading}>How ChannelHub differentiates itself from LinkedIn and other social networking platform?</Typography>
</AccordionSummary>
<AccordionDetails>
<Typography>
‘- Focusing only on consumer tech market and only on B2B

– Private platform, each application is submitted to approval

– Offering full product showcase and sample ordering features

– Matching algorihtm to connect you with the right people

– Human touch: providing personalised service to each and every member
</Typography>
</AccordionDetails>
</Accordion>
<Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
<AccordionSummary
expandIcon={<ExpandMoreIcon />}
aria-controls="panel1bh-content"
id="panel1bh-header"
>
<Typography className={classes.heading}>Is ChannelHub suitable for startups?</Typography>
</AccordionSummary>
<AccordionDetails>
<Typography>
Yes it is. As a matter of fact we do ask all buyers to indicate if they are interested to see startup products and if yes we ask them what maturity level they would accept. So ChannelHub is an amazing oppurtunity to present your product to professional buyers from all different markets. This will allow you to collect invaluable feedback from them in order to adjust your final product in terms of features, pricing, packaging etc..
</Typography>
</AccordionDetails>
</Accordion>
<Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
<AccordionSummary
expandIcon={<ExpandMoreIcon />}
aria-controls="panel1bh-content"
id="panel1bh-header"
>
<Typography className={classes.heading}>What are the product categories covered by ChannelHub?</Typography>
</AccordionSummary>
<AccordionDetails>
<Typography>
We cover all major consumer tech categories: from IT to Smart tech, including Gaming, Mobility etc… For full details of categories covered by CHannelHub please check: here
</Typography>
</AccordionDetails>
</Accordion>
<Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
<AccordionSummary
expandIcon={<ExpandMoreIcon />}
aria-controls="panel1bh-content"
id="panel1bh-header"
>
<Typography className={classes.heading}>What do you mean by qualified vendor?</Typography>
</AccordionSummary>
<AccordionDetails>
<Typography>
ChannelHub is a private platform. Every application received is verified on the basis that you are a real brand (not OEM/ODM) and also to make sure that your products match the buyer’s demand

Once we receive the application and it’s approved, our team will prepare your profile and when it’s ready you’ll receive your login details to the platform. However keep in mind that your account will not go live untill you have added at least 1 product.
</Typography>
</AccordionDetails>
</Accordion>
<Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
<AccordionSummary
expandIcon={<ExpandMoreIcon />}
aria-controls="panel1bh-content"
id="panel1bh-header"
>
<Typography className={classes.heading}>What do you mean by qualified distributor?</Typography>
</AccordionSummary>
<AccordionDetails>
<Typography>
ChannelHub is a private platform. Every application received is verified on the basis that you are really involved in distribution business (stocking products). We don’t accept Traders and Brokers.

Once we receive the application and it’s approved, our team will prepare your profile and when it’s ready you’ll receive your login details to the platform.
</Typography>
</AccordionDetails>
</Accordion>
<Accordion expanded={expanded === 'panel11'} onChange={handleChange('panel11')}>
<AccordionSummary
expandIcon={<ExpandMoreIcon />}
aria-controls="panel1bh-content"
id="panel1bh-header"
>
<Typography className={classes.heading}>What do you mean by qualified retailer?</Typography>
</AccordionSummary>
<AccordionDetails>
<Typography>
ChannelHub is a private platform. Every application received is verified on the basis that you are either a retailer with physical stores, or an online ecommerce site or an online marketplace

Once we receive the application and it’s approved, our team will prepare your profile and when it’s ready you’ll receive your login details to the platform.
</Typography>
</AccordionDetails>
</Accordion>
<Accordion expanded={expanded === 'panel12'} onChange={handleChange('panel12')}>
<AccordionSummary
expandIcon={<ExpandMoreIcon />}
aria-controls="panel1bh-content"
id="panel1bh-header"
>
<Typography className={classes.heading}>Is ChannelHub free subscription?</Typography>
</AccordionSummary>
<AccordionDetails>
<Typography>
Our business model is based on subscription plans and not commission on transactions. For vendors, we have a freemium model with free plan and premium paid plans. For Retailers, it’s free for up to 5 users per company For Distributors it’s free for up to 2 users per company. We also have a premium paid option for distributors who wish to use ChannelHub to connect and grow their sales with retailers
</Typography>
</AccordionDetails>
</Accordion>
<Accordion expanded={expanded === 'panel13'} onChange={handleChange('panel13')}>
<AccordionSummary
expandIcon={<ExpandMoreIcon />}
aria-controls="panel1bh-content"
id="panel1bh-header"
>
<Typography className={classes.heading}>Do you share my email address?</Typography>
</AccordionSummary>
<AccordionDetails>
<Typography>
No we don’t share your email address to any other party. Only your confirmed connections on ChannelHub will have access to your email and can send you messages.
</Typography>
</AccordionDetails>
</Accordion>
<Accordion expanded={expanded === 'panel14'} onChange={handleChange('panel14')}>
<AccordionSummary
expandIcon={<ExpandMoreIcon />}
aria-controls="panel1bh-content"
id="panel1bh-header"
>
<Typography className={classes.heading}>I'm a distributor and I also have my own brand, how does it work for me?</Typography>
</AccordionSummary>
<AccordionDetails>
<Typography>
In that case you need to apply for 2 different profiles. Distributors can publish the list of brands they are distributing but not the products of their brands.
</Typography>
</AccordionDetails>
</Accordion>
<Accordion expanded={expanded === 'panel14'} onChange={handleChange('panel14')}>
<AccordionSummary
expandIcon={<ExpandMoreIcon />}
aria-controls="panel1bh-content"
id="panel1bh-header"
>
<Typography className={classes.heading}>I'm a buyer and I don't want to be visible</Typography>
</AccordionSummary>
<AccordionDetails>
<Typography>
You can select the option to put yourself as confidential in your settings. However your general company will remain visible. So it means that you can see all matching profile and products but the vendors won’t see you.
</Typography>
</AccordionDetails>
</Accordion>
<Accordion className='mb-4' expanded={expanded === 'panel14'} onChange={handleChange('panel14')}>
<AccordionSummary
expandIcon={<ExpandMoreIcon />}
aria-controls="panel1bh-content"
id="panel1bh-header"
>
<Typography className={classes.heading}>I'm a business developer how does it work for me</Typography>
</AccordionSummary>
<AccordionDetails>
<Typography>
Once your application is approved your profile will be posted on our site. ChannelHub will promote your profile and will manually connect you with vendors looking to hire Independant Business Developers. ChannelHub will charge 5% commission on your monthly invoice to vendors and will also charge 5% to the vendor for a maximum of 2 years.
</Typography>
</AccordionDetails>
</Accordion>
</div>

</Container>
<Footer/>
</>
);
}