import React,{useState, useEffect} from "react";
import { useForm } from "react-hook-form";
import { Container, Row, Col,Button } from "reactstrap";
import { FormInput, FormTextArea } from "_components/FormElements/FormInput";
import {requiredSchema} from "../validation";
import { yupResolver } from '@hookform/resolvers/yup';
import { commonService } from '_services/common.service';
import {Spinner} from 'views/Hoc/Spinner';
import {ToastsContainer, ToastsStore} from 'react-toasts';
import axios from "axios";
import contactus from 'assets/img/guestpage/about.jpg'

const GuestReview = (props) => {
  
  let [state, setState]=useState({email:null, note:''});
  let [loading, setLoading]  = useState(false);
  const { register, handleSubmit, control, errors, watch,formState } = useForm({      
    mode:'onBlur',
    resolver: yupResolver(requiredSchema),
  });
  console.log('errors', errors);
  const watchAll = watch();
  const handleChange = input => e => {
      setState({...state, [input] : e.target.value});
      console.log("add",e.target.value, e.target);  
    }
  const save= async()=>{
    setLoading(true);
    let data={
      email:state.email,
      note:state.note,
      type:props.type,
    }
    if(props.type===2)
    data['productSKU']=props.productSku
    else
    data['channelId']=props.channelId

    let res = await commonService.postService(data, 'guest/guestUserRequirement').catch(err =>console.log('err', err));
    if(res){
      let email_data = {
        email: state.email,
        productId: props.productId
      };

      axios.post('mailerservice/Guest_Product_Enquiry',email_data);

      ToastsStore.success("Successfully!...");
      // state.email='';
      // state.note='';
      // setState({...state});
    }else{
      ToastsStore.error("Something went wrong!...");
    }

    setLoading(false);
  }
    return (
        <div className="main-content">
           <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore}/>


            {loading?<Spinner/>:<div className='bg-white pt-4 pb-4 plrl mt-4'>
            <div className='vendor'>
             <Container fluid>
             <div class="text-left" style={{marginLeft:"3rem"}}><h1>Contact the Brand</h1></div>
                <div className="cypgri p-3">
                    <Row>
                    <Col lg='6'>
                   
                        <div className="videotag">
                        <img  src={contactus} style={{opacity:"0.8",objectFit:"cover",borderRadius:"0.8rem"}} width="100%" height="100%" loading="lazy" />
                        {/* <div className="playbtn3">
                         <h1>Get directly in touch with the brand</h1>   
                        <div className="chck">
                        <FormCheckBox
                        id="Terms-conditions"
                        type="checkbox"
                        label={" I would like to receive the catalog"}
                         label1={{textAlign:"justify"}}
                        
                        />
                        </div>

                        <div className="chck">
                        <FormCheckBox
                        id="Terms-conditions"
                        type="checkbox"
                        label={" I would like to be contacted"}
                         label1={{textAlign:"justify"}}
                        
                        /></div>

                        <div className="chck">
                        <FormCheckBox
                        id="Terms-conditions"
                        type="checkbox"
                        label={" I would like to be sent a rate /quotation"}
                         label1={{textAlign:"justify"}}
                        
                        /></div>

                       <div className="chck">
                        <FormCheckBox
                        id="Terms-conditions"
                        type="checkbox"
                        label={" I would like to place an order with A.D CRÉATION - ANNE DE LA FORGE"}
                         label1={{textAlign:"justify"}}
                        
                        /></div>
                        </div> */}
                         {/* <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  src={require('assets/img/guestpage/playbtn.png')} alt="Video play button" width="94" height="94" loading="lazy"/> */}
                        </div>
                        </Col>
                        <Col lg='6'>
                        <h2 className="content-block__heading blue-text word-reveal">
                        To optimize your exchanges, please specify your need (quantities needed, country and city of delivery etc..)</h2>
                            
                        <div className="form-group">
                          <FormInput
                              id="email" 
                              type="text" 
                              label="Your email" 
                              name="email" 
                              control={control}
                              register={register}
                              error={errors.email}
                              defaultValue={state.email}
                              value={watchAll.email}
                              onChange={handleChange('email')}
                          />
                        </div>
                        {!errors.note || (state.note.length > 5 && state.note.length < 180) ? '':<span style={{color: '#f44336', float: 'right', fontSize:'0.75rem'}}>
                          {state.note.length <= 1? 'can\'t be empty.' : state.note.length <= 5 ? 'must have min 5 characters' : state.note.length > 280 ? 'must have max 280 characters' : ''}</span>
                        }
                            <div className="form-group">
                            <FormTextArea
                            className="buytext45 w-100"
                            name="note"
                            id="note" 
                            rows="3"
                            type="textarea"
                            label=" "
                            placeholder="Your message"
                            // handleChange={handleChangeQuill} 
                            value={state.note}
                            onChange={handleChange('note')}
                             />
                           </div>
                           <div className="text-end" style={{textAlign:"end",marginTop:"2.5rem"}}>
                             <Button color="primary" type="button" onClick={handleSubmit(save)} >Continue</Button>
                               </div>
                        </Col>
                    </Row>
                </div>
              </Container>
            </div>
            </div>}

           
        </div>
    );
};

export default GuestReview;
