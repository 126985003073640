import React, { useState, useEffect } from "react";
import { Card,CardBody,Container,Col,Row,Button } from "reactstrap";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { history } from '../../../../_helpers';
import { subscriptionAction } from '_actions/subscription.action';
import { connect, useSelector } from 'react-redux';
import { commonService } from '_services/common.service';
import {ToastsContainer, ToastsStore} from 'react-toasts';
import { Spinner } from "views/Hoc/Spinner";
import SimpleHeader from "components/Headers/SimpleHeader";
import axios from 'axios';
const Subscription = (props) => {
    const redux_data = useSelector(state => state.subscription);
    const [value, setValue] = useState('Wire Transfer');
    const [helperText, setHelperText] = useState('By default, Auto-Renewal is set to \'On\', for Card option.    You can change to \'Off\' from your Setting page, to avoid auto-renewal.');
    let [planDetails, setPlanDetails] = useState(null);
    let [coupan_amt, setCoupan_Amt] = useState(null);
    let [coupan_dis, setCoupan_Dis] = useState(null);
    let [coupan_code, setCoupan_code] = useState(null);
    let [amountpayable, setAmountpayable] = useState(null);
    let [coupan_error, setCoupan_error] = useState(null);
    let [isLoading, setLoading] = useState(true);
    let [wiretransfer, setWiretransfer] = useState(true);
    
    let [paymentAmt, setPaymentAmt] = useState({planAmt:0, amountpayable:0, plan:0, period:null , tax:0, coupon_discount:0, periodId:null, planId:null });
    let userData = JSON.parse(localStorage.getItem('CHuser'));
    useEffect(() => {
      const subId = redux_data.select_plan;
      if(!subId){
        history.push('/admin/Subscription');
        return;
      }
        getSubscriptionPrice();
        }, [])

        const getSubscriptionPrice=async()=>{
            const subId = redux_data.select_plan;
            console.log(subId);
            setLoading(true);
            let res = await commonService.postService({  subscriptionId:parseInt(subId.subscriptionId),
              paymentMethodId:1,
              transactionId:null
           }, 'channel/subscription/subscribe_amt_evaluvate').catch(err =>console.log('err',  ToastsStore.error(err.response.data.message)));

           if(res) {
             planDetails= res.data;
             setPlanDetails(planDetails);
             setWiretransfer(false)
             console.log('plandet', planDetails);
             paymentAmt.planAmt = ((planDetails.original_amount / 100).toFixed(2)).replace(".", ",");
             paymentAmt.amountpayable = ((planDetails.amount / 100).toFixed(2)).replace(".", ",");
             paymentAmt.plan = planDetails.plan;
             paymentAmt.tax = (planDetails.taxAmount / 100).toFixed(2);
             paymentAmt.period = planDetails.period;
             paymentAmt.periodId = parseInt(planDetails.periodId);
             paymentAmt.planId = parseInt(planDetails.planId);
             paymentAmt.coupon_discount= ((planDetails.coupon_discount / 100).toFixed(2)).replace(".", ",");;
             setPaymentAmt({ ...paymentAmt });
             props.subscriptionRedux(paymentAmt.amountpayable,'PAYMENT_AMT');
             setAmountpayable(paymentAmt.amountpayable);
             if(parseInt(paymentAmt.planId)===1){
              setValue(null);
             }
           }  
            setLoading(false);
       }

    const handleRadioChange = (event) => {
        event.preventDefault();
        props.subscriptionRedux(paymentAmt.amountpayable,'PAYMENT_AMT');
        setValue(event.target.value);
        if (event.target.value === 'Card') {
          setHelperText('By default, Auto-Renewal is set to \'On\', for Card option.    You can change to \'Off\' from your Setting page, to avoid auto-renewal.');
        } else if (event.target.value === 'PayPal') {
          setHelperText('You have paypal account.');
        } else {
          setHelperText('Wire Transfer details will be sent to you via email, once you click \'Subscribe\'.');
        }
      };
      const handleSave = () => {
        if(paymentAmt?.amountpayable===0 || parseInt(paymentAmt.planId)===1){
          // history.push('/admin/PaymentSuccess');
          subscription(1);
      }else{
        if(value=== 'Wire Transfer'){
          subscription(2);
          }else if(value=== 'PayPal'){
            history.push('/admin/Paypal');
          }else if(value=== 'Alipay'){
            history.push('/admin/alipaypayment');
          }else if(value=== 'Card'){
            history.push('/admin/stripepayment');
          }else{
          history.push('/admin/PaymentSuccess');
          }
      }
    }
    const subscription =async (plan) => {
     // setLoading(true);
      console.log("date inredux",redux_data);
      const subId = redux_data.select_plan;     
      let res = await commonService.postService({
        subscriptionId:parseInt(subId.subscriptionId),
        paymentMethodId:plan,
        transactionId:null,
        couponCode:redux_data.couponCode||null
     }, 'channel/subscription/subscribe').catch(err =>console.log('err',  ToastsStore.error(err.response.data.message)));
     if(res){
      // userData.subscriptionId=subId.subscriptionId;
      // localStorage.setItem('CHuser', JSON.stringify(userData));
      let userData = JSON.parse(localStorage.getItem('CHuser'));
      userData.paymentMethodId = plan;
      if(plan != 2){
         
          userData.planId = res.data.planId;
          userData.subscriptionId = +res.data.channelSubscription.subscriptionId;
          userData.featureList = res.data.channelSubscription.keyFeature;
          userData.planExpireAt = res.data.channelSubscription.endAt;
          userData.planName = redux_data.select_plan.title +' - '+ redux_data.select_plan.month;
          
            
          await commonService.getServices('algorithm/channel').catch(err =>console.log(err.response.data.message,  ToastsStore.error('unable to create suggestion')));
      }
      localStorage.setItem('CHuser', JSON.stringify(userData));
       setLoading(false);
       history.push('/admin/PaymentSuccess');
     }
     
    }
    const validateCoupan=async()=>{
      setLoading(true);
      setCoupan_error(null);
      const subId = redux_data.select_plan;  
      props.subscriptionRedux(coupan_code,'COUPON_CODE');
      let coupanData = await commonService.postService({
        finalAmount:paymentAmt.amountpayable,
        couponCode:coupan_code,
        subscriptionId:parseInt(subId.subscriptionId),
        subscriptionPlanId:paymentAmt.planId,
        subscriptionPeriodId:paymentAmt.periodId
     }, 'channel/subscription/coupan_validate').catch(err =>console.log('err',  setCoupan_error(err.response.data.message)));
     setLoading(false);
         if(coupanData){
          console.log('coupanData',coupanData);
         paymentAmt.amountpayable = parseFloat(coupanData.data.finalAmt.payableAmt).toFixed(2);
         setPaymentAmt({ ...paymentAmt });
         props.subscriptionRedux(paymentAmt.amountpayable,'PAYMENT_AMT');
         setCoupan_Dis(coupanData.data.finalAmt.couponOfferValue);
         setCoupan_Amt(parseFloat(coupanData.data.finalAmt.couponDiscount).toFixed(2));
         }
    }
    const resetCoupon=()=>{
       paymentAmt.amountpayable = amountpayable;
       setPaymentAmt({ ...paymentAmt });
       props.subscriptionRedux(paymentAmt.amountpayable,'PAYMENT_AMT');
       setCoupan_Dis(null);
       setCoupan_Amt(null);
    }
return (
<div className="main-content" id="PaymentConfirm">
<SimpleHeader location={props.match}/>
<ToastsContainer position="top_right" autoClose={5000} store={ToastsStore}/>
{isLoading&&<Spinner/>}
        <Container className="mt-4 col-lg-11 col-md-11 col-sm-12" fluid>
<Row>
    <Col lg="7">

      <Row>
        <Col lg="12"> <Card>
            <CardBody>
            <h1 className="ml-3 mt-3">Payment Method</h1><hr className="m-0 ml-3 paycarhr" style={{    width: "14%"}}/>
            <p className="p-3 mb-0">
                    {helperText}
                    </p>
            </CardBody>
        </Card></Col>
      </Row>
      <Row>
    <Col lg="12">
    <Card>
            <CardBody>
            <RadioGroup onChange={handleRadioChange} value={value}  row aria-label="position" name="position">
            <div className="rowBox w-100">
          
          <FormControlLabel value="Card" disabled={parseInt(paymentAmt.planId)===1} control={<Radio color="primary" />} label="Card" />
        <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  alt="...." className="float-right" src={require("assets/img/common/card.png")} />
          </div>
          {/* {value== 'Card' && 
            <CardBody className=" mt-1 pt-0">
            <Row className="justify-content-center pb-2">
        
        <Col lg="12" >
        <CardBody>
          <div >
            <Form className="form-primary" role="form">
            <Row>
                      <Col lg="12">
               <FormGroup className="mb-4">
                
                      
                        <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="outlined-adornment-amount">Email</InputLabel>
            <OutlinedInput
                id="outlined-adornment-amount"
                placeholder="Email"
                value={userData.email}
                disabled
                startAdornment={<InputAdornment position="start">
                    <i class="fas fa-envelope"></i>
                </InputAdornment>}
                labelWidth={60}
            />
            </FormControl>
              </FormGroup>
              </Col>
                  </Row>
           <Row>
               <Col>
        <StripePayment/>
        </Col>
           </Row>
            </Form>
          </div>
        </CardBody>
        </Col>
    </Row>
            </CardBody>} */}

           <div className="rowBox w-100">
          
           <FormControlLabel disabled={parseInt(paymentAmt.planId)===1} value="Wire Transfer" control={<Radio color="primary" />} label="Wire Transfer" />
         <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  className="float-right" alt="..." src={require("assets/img/common/wire.png")} />
           </div>

           <div className="rowBox w-100">
          
           <FormControlLabel disabled={parseInt(paymentAmt.planId)===1} value="PayPal" control={<Radio color="primary" />} label="PayPal" />
        <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  className="float-right" alt="..." src={require("assets/img/common/paypal.png")} />
          </div>
          <div className="rowBox w-100">
          
          <FormControlLabel disabled={parseInt(paymentAmt.planId)===1} value="Alipay" control={<Radio color="primary" />} label="Alipay" />
        <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  className="float-right" alt="..." src={require("assets/img/common/alipay.png")} />
          </div>

           </RadioGroup>
            </CardBody>
            </Card>
    </Col>
    </Row>

       
    </Col>
    <Col lg="5" className="pt-3" style={{backgroundColor:"#d9d9d9"}}>
    <Card>
            <CardBody>
              <Row>
              <Col lg="6">
            <h2 className="mt-1 mb-3 d-flex ">Order Summary</h2></Col>
              <Col lg="6">
            <h4 className=" mt-1 mb-3 d-flex justify-content-end" onClick={()=>history.push('/admin/Subscription')}>
            
            <a className="changeplan">Change Plan</a>
            </h4></Col></Row>         
      

<Row>
  <Col lg="8">
  <h4 className="mt-3 mb-3" >Subscription Plan</h4>
  </Col>
  <Col lg="4" className="d-flex justify-content-start">
  <h4 className="text-center mt-3 mr-4 mb-3 amt">{paymentAmt?.plan} / {paymentAmt.period}</h4>
  </Col>
  <Col lg="8">
  <h4 className="mt-3 mb-3">Subscription Plan Cost</h4>
  </Col>
  <Col lg="4" className="d-flex justify-content-start">
  <h4 className="text-center mt-3 mr-5 mb-3 amt">{paymentAmt?.planAmt} €	</h4>
  </Col>
  <hr className="m-0 ml-3 ordersumsplit" style={{    width: "100%"}}/>
  <Col lg="8">
  <h4 className="mt-3 mb-3">SUB TOTAL</h4>
  </Col>
  <Col lg="4" className="d-flex justify-content-start">
  <h4 className="text-center mt-3 mr-5 mb-3 amt">{paymentAmt?.planAmt} €</h4>
  </Col>
  {coupan_amt && <><Col lg="8">
  <h4 className="mt-3 mb-3">Discount <span className="couponvalid-top">(Code Applied : {coupan_code})</span></h4>
  </Col>
  <Col lg="4" className="d-flex justify-content-start">
  <h4 className="text-center mt-3 mr-5 mb-3 amt">{coupan_amt} €</h4>
  </Col></>}
  {planDetails?.taxAmount>0 && <><Col lg="8">
  <h4 className="mt-3 mb-3">Vat 0%</h4>
  </Col>
  <Col lg="4" className="d-flex justify-content-start">
  <h4 className="text-center mt-3 mr-5 mb-3 amt">{paymentAmt?.tax} €</h4>
  </Col></>}
  <hr className="m-0 ml-3 ordersumsplit" style={{    width: "100%"}}/>
  <Col lg="8">
  <h3 className="mt-3 mb-3">Total</h3>
  </Col>
  <Col lg="4" className="d-flex justify-content-start">
  <h3 className="text-center mt-3 mr-5 mb-3 amt">{paymentAmt?.amountpayable} €</h3>
  </Col>

 </Row>
</CardBody>
</Card>

        
        <Card>
          <CardBody>
          <Row>
          <Col lg="12">
  <h3 className="mt-1 mb-2" >Promo Code</h3>
  </Col>
  <Col lg="12">
  <div class="input-group mb-2">
  <input onChange={e => setCoupan_code(e.target.value)} disabled={parseInt(paymentAmt.planId)===1} readOnly={coupan_dis} type="text" class="form-control" placeholder="Apply Promo Code" aria-label="Apply-prmotional-Code" aria-describedby="button-addon2"/>
  <div class="input-group-append">
    {coupan_dis?
    <button class="btn btn-outline-primary" type="button" id="button-addon2" onClick={resetCoupon}>Remove</button>:
    <button class="btn btn-outline-primary" type="button" id="button-addon2" disabled={parseInt(paymentAmt.planId)===1} onClick={validateCoupan}>Apply</button>}
  </div>
</div>

{/* for valid coupon */}

{coupan_dis && <h5 className="couponvalid"> 
  Promotional code applied! Avail {coupan_dis} € off on your subscription plan cost
</h5>}
{coupan_error && <h5 style={{"color":"red"}}> 
Coupon not valid
</h5>}
  </Col>
  </Row>
  </CardBody>
        
        </Card>

        <Card>
          <CardBody>
          <Row>
  <Col lg="6">
  <h3 className="mt-1 mb-2" >AMOUNT PAYABLE</h3>
  <h3 style={{fontSize:'24px'}} className="mt-3 mb-3 amt">€ {paymentAmt?.amountpayable}</h3>
  </Col>
  <Col className="col-lg-6 d-flex align-items-center d-flex justify-content-end">
    {wiretransfer == true?'':
  <Button onClick={handleSave} className="mt-1 mb-2 btn-apply" variant="outlined" size="m"  color="primary">
            Pay Now
         </Button> }
  </Col>
  </Row>
  </CardBody>
        
        </Card>
    </Col>
   
</Row>


              </Container>
      </div>
    
   
  );
};



// export default Subscription;


function mapState(state) {
    console.log('state', state);
    const { general_data } = state.subscription;
    return { general_data };
}

const actionCreators = {
    subscriptionRedux: subscriptionAction.subscriptionRedux
};

export default connect(mapState,actionCreators)(Subscription); //AddgeneralForm;