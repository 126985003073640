import React, {useState,} from 'react';
import {Row,Col,Button, Modal} from "reactstrap";
import { history } from '../../../_helpers';
import { userService } from '../../../_services';
import { LocalStorageService } from '_services';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import {loginschema} from '../../pages/LoginPage/validation'; 
import {FormInput} from "_components/FormElements/FormInput";
export  const GuestViewContact=(props)=> {
 const {open, handleClose } = props;
 const [apptoken, setApptoken] = useState("");
 const [invalidAuth, setInvalidAuth] = useState(false);
 // const { loggingIn } = props;      
  
  const { register, handleSubmit, control, errors } = useForm({
//    mode:'onBlur',
    resolver: yupResolver(loginschema)
  });
  const onSubmit = async(data) => {    
     if (data) {
        //  let res = await props.login(data.email,data.password,apptoken);
        signIn(data.email,data.password,apptoken);
        //  console.log('resp', res);
       //console.log("data:",data);
     }
  }
  const signIn = async(username, password, apptoken)=> {

    const localStorageService = LocalStorageService.getService();
      userService.login(username, password, apptoken)
            .then(
                user => {
                    console.log('user', user);
                    let data=user?.data?.user;
                    localStorageService.setToken(user.data.token);
                    if(user.data.user.signUpStatusId === "14")
                        history.push('/Pending');
                    
                    if(parseInt(data.channelTypeId)!==2 && (localStorage.getItem('guest-product-fav') || localStorage.getItem('guest-channel-fav'))){
                      if(localStorage.getItem('guest-product-fav')){
                      let id=localStorage.getItem('guest-product-fav');
                      localStorage.removeItem('guest-product-fav');
                      history.push('/admin/channel/productDetail/'+ id);
                      }else if(localStorage.getItem('guest-channel-fav')){
                        let id=localStorage.getItem('guest-channel-fav');
                        localStorage.removeItem('guest-channel-fav');
                        history.push('/admin/channel/channelDetail/'+ id);
                        }
                    }else{
                    if(user.data.user.signUpStatusId === "15" && user.data.user.lastLogin == null){
                        history.push('/Verification');
                    }
                    if(user.data.user.signUpStatusId === "15" && user.data.user.lastLogin != null){
                        history.push('/admin/');
                    }
                  }
                        return true;
                },
                error => {
                  setInvalidAuth(true);
                    return false;
                }
            );
    // };
}
 return (
    <div>
      <Modal className="modal-dialog-centered modal-xl" isOpen={open}>
          <div className="modal-header title1">
           <div  className=""> 
           <strong>Access your account</strong></div>  
            <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={ handleClose}>
              <span aria-hidden={true}>×</span>
            </button>
          </div>
         
          <div className="modal-body" id="login_content">
            <Row>
            {/* <form onSubmit={handleSubmit(onSubmit)}> */}
                <Col lg="6" id="left_content">
                <span className="subtitle">Do you already have an account?</span>
                <p className="description">If you have an existing account, Enter your credentials below.</p>
                 
            <FormInput 
              id="email"
              type="email"
              name="email"
              placeholder="Email Id"      
              register={register}
              error={errors.email}
              defaultValue=''
            />
            <div style={{ marginTop:'1rem' }} >
            <FormInput 
              id="password"
              type="password"
              name="password"
              placeholder="Password"
              register={register}
              error={errors.password}
              />
            </div>
            {invalidAuth && <p style={{color:"red",textAlign:"center", fontSize: '.9rem',fontWeight: 600, marginTop:'-1rem'}}>!Please check your email and password</p>}
           <div className="modal-footer">
          <Button color="primary" type="button" onClick={handleSubmit(onSubmit)} >Login</Button>
          </div>
                </Col>
                {/* </form> */}
               {props?.type==='vendor'?
                <Col lg="6" md="12" sm="12">



<ul class="content-block__list list-group">
  <li class="content-block__list-item list-group-item1">
<h3 className="contactTitle1 text-left mb-2 mt-3  dark-text">Create your account and get the following benefits: 
</h3></li>


 
      <li class="content-block__list-item list-group-item1">
      <h4 class="content-block__subheading dark-text"><i class="fas fa-angle-double-right mr-2"></i>Your profile visible without any time limit
   </h4>
    </li>
    
    
      <li class="content-block__list-item list-group-item1">
      <h4 class="content-block__subheading dark-text"><i class="fas fa-angle-double-right mr-2"></i>Your product catalogue visible without any time limit
       
      </h4>
    </li>

    <li class="content-block__list-item list-group-item1">
      <h4 class="content-block__subheading dark-text"><i class="fas fa-angle-double-right mr-2"></i>Access to hundred of qualified buyers from around the World
       
      </h4>
    </li>

    <li class="content-block__list-item list-group-item1">
      <h4 class="content-block__subheading dark-text"><i class="fas fa-angle-double-right mr-2"></i>Get qualified leads
       
      </h4>
    </li>

    <li class="content-block__list-item list-group-item1">
      <h4 class="content-block__subheading dark-text"><i class="fas fa-angle-double-right mr-2"></i> Integrated connection with our events. Never lose any contact details or company profile of people you met at our events.
       
      </h4>
    </li>

  </ul>
  <div className="modal-footer">
          <Button color="primary" type="button" onClick={()=>history.push('/signup')} >Create My Account</Button>
          </div>
</Col> :
  <Col lg="6" id="right_content">
  <span className="subtitle" >Join ChannelHub!</span>
  <p className="description">If you are new to ChannelHub, apply in 2 minutes and explore a brand new way to discover new products and vendors from around the World.</p>
  <div style={{padding:".5rem"}}>
  <i class="fa fa-envelope" style={{fontSize:"25px",color:"#2c9db7"}}></i>
  <span style={{fontSize:"20px",color:"",marginLeft:"1rem"}}>Get in touch with hundreds for qualified suppliers
  </span>
  </div>

  <div style={{padding:".5rem"}}>
  <i class="fa fa-search-plus" style={{fontSize:"25px",color:"#2c9db7"}}></i>
  <span style={{fontSize:"20px",color:"",marginLeft:"1rem"}}>Discover all the specs of their product
  </span>
  </div>

  <div style={{padding:".5rem"}}>
  <i class="fas fa-heart" style={{fontSize:"25px",color:"#2c9db7"}}></i>
  <span style={{fontSize:"20px",color:"",marginLeft:"1rem"}}>Order samples in just a few click
  </span>
  </div>
  <div className="modal-footer">
<Button color="primary" type="button" onClick={()=>history.push('/signup')} >Create My Account</Button>
</div>
  </Col> 
}

 
            </Row>
          </div>

        </Modal>
    </div>
  );
}

const vendorBenefits = [
  " Your profile visible without any time limit",
  " Your product catalogue visible without any time limit",
  " Access to hundred of qualified buyers from around the World",
  " Get qualified leads",
  " "
]
export default  GuestViewContact;