import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useForm } from "react-hook-form";
import { commonService } from '_services/common.service';
import { ListGroupItem, ListGroup } from 'reactstrap';
import { FormAutocomplete } from "_components/FormElements/FormInput";

const Publicchannelfilter = (props) => {
  const { type, eventId, handleChangeSelect, filterValueState } = props;
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));
  const { control } = useForm({ mode: 'onBlur' });
  const style = { height: '18rem' };

  const [filterOptionState, setFilterOption] = useState({ Category_Option: [], Company_Origin_Option: [], channelTypeOption: [] });
  const classes = useStyles();

  useEffect(() => {
    getFilterData();

  }, []);

  const getFilterData = async () => {
    await commonService.getServices('publicChannel/FilterData/' + type + '/' + eventId).then(res => {
      filterOptionState.channelTypeOption = res.data.channelType;
      filterOptionState.Company_Origin_Option = res.data.country;
      filterOptionState.Category_Option = res.data.product;
      setFilterOption({ ...filterOptionState });
    })
  }
  return (
    <div className='fav-ser1 p-2'>
      <div className={classes.root}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Type</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ListGroup>
                <ListGroupItem>
                  <div className=" text-center mt-2">
                    <div className="form-group">

                      <FormAutocomplete
                        id="channel_type"
                        name="channel_type"
                        label=""
                        options={filterOptionState.channelTypeOption}
                        placeholder="Type Channel Type"
                        // multiple  
                        disableCloseOnSelect
                        limitTags={1}
                        control={control}
                        open={true}
                        input='channel_type'
                        getOptionSelected={(options, value) => options.name === value.name}
                        value={filterValueState.typeId}
                        handleChangeSelect={handleChangeSelect}
                        popupIcon=''
                        disablePortal={true}
                        ListboxProps={{ style: { maxHeight: '16rem' } }}
                        size='small'
                      />

                    </div>
                  </div>
                </ListGroupItem>
              </ListGroup>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography >Country</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ListGroup>
                <ListGroupItem>
                  <div className=" text-center mt-2">
                    <div className="form-group">
                      <FormAutocomplete
                        id="Company_Origin"
                        name="Company_Origin"
                        label=""
                        options={filterOptionState.Company_Origin_Option}
                        placeholder="Type Company Origin"
                        multiple
                        disableCloseOnSelect
                        limitTags={1}
                        control={control}
                        input='Company_Origin'
                        getOptionSelected={(options, value) => options.name === value.name}
                        value={filterValueState.Company_Origin}
                        handleChangeSelect={handleChangeSelect}
                        open={true}
                        popupIcon=''
                        disablePortal={true}
                        size='small'
                        ListboxProps={{ style: { maxHeight: '16rem' } }}
                        style={{ height: '3rem' }}

                      />
                    </div>
                  </div>
                </ListGroupItem>
              </ListGroup>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography >Product Category</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ListGroup>
                <ListGroupItem >
                  <div className=" text-center mt-2">
                    <div className="form-group">
                      <FormAutocomplete
                        id="categories"
                        name="categories"
                        label=""
                        options={filterOptionState.Category_Option}
                        getOptionSelected={(options, value) => options.name === value.name}
                        placeholder="Type Categories"
                        multiple
                        limitTags={1}
                        control={control}
                        input='categories'
                        value={filterValueState.categories}
                        open={true}
                        popupIcon=''
                        disablePortal={true}
                        size='small'
                        ListboxProps={{ style: { maxHeight: '16rem' } }}
                        handleChangeSelect={handleChangeSelect}
                      />
                    </div>
                  </div>
                </ListGroupItem>
              </ListGroup>
            </Typography>
          </AccordionDetails>
        </Accordion>


      </div>
    </div>
  );
};

export default Publicchannelfilter;