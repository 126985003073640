import React, { useEffect, useMemo, useState } from "react";
import AwardVideoItem from "./AwardVideoItem";

export default function BestAwardsVideos() {
    // Default Value
    const Awards = [
        { companyname: "Mymanu", productname: "Clik S", embedId: "T7H2ADz8_X8", category: "Audio & Entertainment", awardlogo: "/images/audio_winner.png" },
        { companyname: "AWOL", productname: "Vanish TV", embedId: "FSKZz1z0X0E", category: "Audio & Entertainment", awardlogo: "/images/audio_honoree.png" },

        { companyname: "Kandao", productname: "QooCam EGO", embedId: "erWzFqXthSM", category: "Audio & Entertainment", awardlogo: "/images/audio_top3.png" },
        { companyname: "GAC Technology", productname: "HP CC180", embedId: "qNkbpFOsmrY", category: "Audio & Entertainment", awardlogo: " " },
        { companyname: "Artlii", productname: "Rubicon", embedId: "3miinf4fiic", category: "Audio & Entertainment", awardlogo: " " },
        { companyname: "Trevi", productname: "XF 3400 PRO", embedId: "Zbmq0GPG9qI", category: "Audio & Entertainment", awardlogo: " " },
        { companyname: "OneOdio", productname: "OpenRock Pro", embedId: "nJb0U_c0ajg", category: "Audio & Entertainment", awardlogo: " " },
        { companyname: "Arozzi", productname: "Arena Gaming desk", embedId: "ZjQ4vaIdoPs", category: "Gaming & Computing", awardlogo: "/images/gaming_winner.png " },
        { companyname: "Mionix", productname: "NAOS PRO", embedId: "MBtTU3INGds", category: "Gaming & Computing", awardlogo: "/images/gaming_honoree.png " },
        { companyname: "Bleu Jour", productname: "KUBB", embedId: "zTq7tTqHrxE", category: "Gaming & Computing", awardlogo: "/images/gaming_top3.png " },
        { companyname: "Celly", productname: "GRANDPRIX SPARCO", embedId: "kM73cn2BxLc", category: "Gaming & Computing", awardlogo: " " },
        { companyname: "Impakt", productname: "Thor 303 TKL", embedId: "w-8QqHOFVdc", category: "Gaming & Computing", awardlogo: " " },
        { companyname: "Inforlandia", productname: "Cuco", embedId: "vRA_NWpoRGI", category: "Gaming & Computing", awardlogo: " " },
        { companyname: "Choetech", productname: "Lightweight Solar Panel", embedId: "_bN5GEF4niU", category: "Green Tech", awardlogo: "/images/greentech_winner.png" },

        { companyname: "Bluetti", productname: "AC500 Solar Generator", embedId: "F-H2DCBcpd8", category: "Green Tech", awardlogo: " /images/greentech_honoree.png" },
        { companyname: "Sandberg", productname: "Survivor Powerbank 8in1 96000", embedId: "Sge1K7R6MuM", category: "Green Tech", awardlogo: "/images/greentech_top3.png" },
        { companyname: "VMAX", productname: "VX2 PRO", embedId: "57RIJmuFJ64", category: "Green Tech", awardlogo: " " },
        { companyname: "Dabbsson", productname: "DBS2300", embedId: "jTopwDGZpS4", category: "Green Tech", awardlogo: " " },
        { companyname: "Hexagro", productname: "Poty Vertical Garden", embedId: "l_YVFzSQi4w", category: "Green Tech", awardlogo: " " },
        { companyname: "Skateflash", productname: "US Scooter", embedId: "YPgv_OLqx-8", category: "Green Tech", awardlogo: " " },
        { companyname: "Riley", productname: "Riley Scooter RS 3", embedId: "GmgWcT5m8JI", category: "Green Tech", awardlogo: " " },
        { companyname: "Mobile Pixels", productname: "Geminos - Stacked Screens", embedId: "0NY_lysIfPc", category: "IT Peripherals & Accessories", awardlogo: "/images/accessories_winner.png" },

        { companyname: "Swiftpoint", productname: "ProPoint", embedId: "j4yrlMmQH7Q", category: "IT Peripherals & Accessories", awardlogo: "/images/accessories_honoree.png" },
        { companyname: "IRIS", productname: "IRIScan Desk 6 Business", embedId: "vW5YZddy-og", category: "IT Peripherals & Accessories", awardlogo: "/images/accessories_top3.png" },
        { companyname: "Dicota", productname: "Webcam PRO Face Recognition D31892", embedId: "6zHDVmzAKto", category: "IT Peripherals & Accessories", awardlogo: " " },
        { companyname: "Verbatim", productname: "PORTABLE TOUCHSCREEN MONITOR 15.6", embedId: "ZaxEFAKUH9M", category: "IT Peripherals & Accessories", awardlogo: " " },
        { companyname: "Port Europe", productname: "FUZION", embedId: "JS9m7bqecug", category: "IT Peripherals & Accessories", awardlogo: " " },
        { companyname: "CZUR", productname: "StarryHub", embedId: "zBe9q3Ov2q4", category: "IT Peripherals & Accessories", awardlogo: " " },
        { companyname: "VuPoint", productname: "Toucan Connect Video Conference System 360", embedId: "VRZ3e44jpQw", category: "IT Peripherals & Accessories", awardlogo: " " },
        { companyname: "Vitesy", productname: "SHELFY", embedId: "g4DSYUQU6cU", category: "Smart Home & Living", awardlogo: " /images/homeliving_winner.png" },

        { companyname: "Aiper", productname: "Seagull Pro", embedId: "gp_kQ4nn4jU", category: "Smart Home & Living", awardlogo: "/images/homeliving_honoree.png" },
        { companyname: "Tellur", productname: "Tellur Smart WiFi Pet Feeder", embedId: "deDAa4mMtsw", category: "Smart Home & Living", awardlogo: " /images/homeliving_top3.png" },
        { companyname: "Carbon8", productname: "Carbon8 - One Touch Sparking Water Maker and Dispenser", embedId: "55Cpx8BSSHE", category: "Smart Home & Living", awardlogo: " " },
        { companyname: "Hombli", productname: "Hombli Smart Robot Vacuum", embedId: "pBm-UG1awk4", category: "Smart Home & Living", awardlogo: " " },
        { companyname: "Lumi", productname: "Aqara Presence Sensor FP2", embedId: "WK--4RuA9KM", category: "Smart Home & Living", awardlogo: " " },
        { companyname: "Renpho", productname: "EYERIS 1", embedId: "XRbCwdJrDIM", category: "Smart Home & Living", awardlogo: " " },
        { companyname: "Bimar", productname: "AP30 KiT", embedId: "wl_JxdLs0Mc", category: "Smart Home & Living", awardlogo: " " },
        { companyname: "Vantop", productname: "Epichef AF600 Air Fryer", embedId: "-8AH2jbYJgk", category: "Smart Home & Living", awardlogo: " " },
        { companyname: "Moft", productname: "Snap Float Folio", embedId: "uQcSQH0jtNE", category: "Mobile Products", awardlogo: " /images/mobile_winner.png" },

        { companyname: "Onyx", productname: "BOOX ePaper Tablet/eReader", embedId: "RLMjaMGwzTg", category: "Mobile Products", awardlogo: "/images/mobile_honoree.png " },
        { companyname: "Vonmahlen", productname: "allroundo Power", embedId: "TAQBkrvUjMo", category: "Mobile Products", awardlogo: "/images/mobile_top3.png" },
        { companyname: "Pocketbook", productname: "Viva", embedId: "oFEb3MRUGZo", category: "Mobile Products", awardlogo: " " },
        { companyname: "Xtrememac", productname: "X-MAG PRO", embedId: "H-80_8YxKlQ", category: "Mobile Products", awardlogo: " " },
        { companyname: "BIOND", productname: "Card Tracker", embedId: "ax52JQtdGs0", category: "Mobile Products", awardlogo: " " },

    ]
    const [catList, setCatList] = useState([]);

    const [selectedCategory, setSelectedCategory] = useState();

    // Add default value on page load
    useEffect(() => {
        setCatList(Awards);
    }, []);

    // Function to get filtered list
    function getFilteredList() {
        // Avoid filter when selectedCategory is null
        if (!selectedCategory) {
            return catList;
        }
        return catList.filter((item) => item.category === selectedCategory);
    }

    // Avoid duplicate function calls with useMemo
    var filteredList = useMemo(getFilteredList, [selectedCategory, catList]);

    function handleCategoryChange(event) {
        setSelectedCategory(event.target.value);
    }

    return (
        <div className="awards-wrapper">
            <div className="filter-container">
                <span><b>Filter by Category:</b></span>
                <select
                    name="category-list"
                    id="category-list"
                    defaultValue=""
                    onChange={handleCategoryChange}
                >
                    <option value="">All</option>
                    <option value="Audio & Entertainment" >Audio & Entertainment</option>
                    <option value="Gaming & Computing">Gaming & Computing</option>
                    <option value="Green Tech" selectedCategory>Green Tech</option>
                    <option value="IT Peripherals & Accessories">IT Peripherals & Accessories</option>
                    <option value="Smart Home & Living">Smart Home & Living</option>
                    <option value="Mobile Products">Mobile Products</option>
                </select>
                <div className="cat-list">
                    <div className="row">

                        {filteredList.map((element, index) => (
                            <AwardVideoItem {...element} key={index} />

                        ))}
                    </div>

                </div>
            </div>
        </div>
    );
}