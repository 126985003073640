import React from "react";
import Initialprofile from "./Initialprofile";
import UserProfile from "./UserProfile";
import AddFirstProduct from "./AddFirstProduct";
import ExportMentor from "./ExportMentor";
import SimpleHeader from "components/Headers/SimpleHeader";
import {Card,CardBody} from "reactstrap";
import Subscription from "./Subscription";
import { history } from '../../../../_helpers';
import {useBeforeFirstRender} from '_hooks/index'

import ProfileSettings from "views/pages/profile/Settings/SettingsWrapper"
import RetailReseller from 'views/pages/companyProfiles/Retail-Reseller';
import CompanyProfileDist from "views/pages/profile/Settings/CompanyProfileDist";
import IncompleteProfileDist from "views/pages/profile/Settings/IncompleteProfileDist";
import IncompleteRetailReseller from "views/pages/companyProfiles/IncompleteRetailer-Reseller"
import  Userprofileform  from 'views/pages/profile/Settings/Userprofileform'
import Addprodtab from 'views/pages/product/Addprodtab';
// import ManageEventUsers from "views/pages/Events/ManageEventUsers";
import EventsNew from "views/pages/Events/EventNew";
// import EventsPayment from "views/pages/Events/EventPaymentConfirm";

const ProfileWrapper = props => { 
  
  let index = (props.location.index ) ? props.location.index.value : 0;  //&& props.location.index.isCompleted==false

  let userData = JSON.parse(localStorage.getItem('CHuser'));

  console.log('index', props)

  const switchPage = () => {
    
    switch(index){
      case 1:        
        let initial;
        if(userData.channelTypeId == '2')
          initial = <ProfileSettings ratingId={index}/>
        else if(userData.channelTypeId == '3')
          initial = userData?.profile_completion_count>=4?<CompanyProfileDist ratingId={index}/>:<IncompleteProfileDist ratingId={index}/>
        else if(userData.channelTypeId == '4' || userData.channelTypeId == '5')
          initial = userData?.profile_completion_count>=4?<RetailReseller ratingId={index}/>:<IncompleteRetailReseller ratingId={index}/>
        return  initial;//<Initialprofile ratingId={index} />;
      case 2:
        // return <UserProfile ratingId={index} />;
        return <Userprofileform ratingId={index}/>
      case 3:
        // return <AddFirstProduct ratingId={index} />;
        return <Addprodtab ratingId={index}/>
        case 4:
          return <ExportMentor ratingId={index} />;
      case 5:
        return <Subscription ratingId={index} />;
      case 6:
        return <EventsNew ratingId={index} />;
        // case 6:
        // return <EventsPayment ratingId={index} />;
      default :
      console.log("ratingId:",index);
        return history.push('/admin/dashboard');
    }
  }

  
  return (
    <>
    {/* <SimpleHeader name="Company Details" parentName="Dashboard" /> */}
    {/* <SimpleHeader name="UserProfile" parentName="Dashboard" /> */}
    {/* <UserProfile/> */}
    {switchPage()}

  
   
  
      </>        
  );
}

export default ProfileWrapper;