import React ,{useState,useEffect} from "react";
import { FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  ListGroupItem,Collapse,NavbarToggler,DropdownItem,DropdownMenu,NavbarBrand, UncontrolledDropdown,DropdownToggle, Card, CardBody,Row, Col,Button,CardTitle,CardText,CardImg ,ButtonGroup } from "reactstrap";
import { Link } from "react-router-dom";
import GuestHotTopics from "./GuestHotTopics";
import GuestCategorys from "./GuestCategorys";
import GuestNavbar from "./GuestNavbar/GuestNavbar";
import GuestFooter from "../GuestPage/GuestNavbar/GuestFooter";
import Slider from 'react-slick';
import GuesProducts from "./GuesProducts";
import GuestContact from "./GuestContact";
import Guestslider from "./GuestNew/Guestslider";
import GuestsliderProductOnly from "./GuestNew/GuestsliderProductOnly";
import GuesProductsforCategory from "./GuesProductsforCategory";
import ProductFilter from "./ProductFilter";
// import {useInfiniteScroll} from '_hooks';
import { commonService } from '_services/common.service';
import Footer from "./GuestNavbar/Footer";
export const GuestCategoryListing = props => {  
  const settings ={
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 3,
    arrows: true,
};

console.log('props', props);
const title = props.location.state?.value?.name ? props.location.state?.value?.name : 'Products';
const id = props.location.state?.value?.id ? [props.location.state?.value?.id] : [];
const pathValue = [props.location.state?.value];


const [state, setstate] = useState(0);
const [collapsed, setCollapsed] = useState(true);

let bType = props.location.state?.type ? [{name: props.location.state?.type=='1' ? 'B2C' : 'B2B', id: props.location.state?.type}] : null;

const [filterValueState, setFilterValue] = useState({parent: 'family',location:[], brand:[], categories:[], family: pathValue, hotValue: [], orderby: 'isFeatured', businessType: bType});
let [compData, setCompData] = useState([]);
const [loading, setLoading] = useState(false);

// const fetchMoreItems = async() => {
  
//   await getproductlist();
//   setIsFetching(false);

// }

// const [isFetching, setIsFetching] = useInfiniteScroll(fetchMoreItems);

let [page, setPage] = useState(0);
let [rowsPerPage, setRowsPerPage] = useState(12);
let [total, setTotal] = useState(0);
const [sortValue, setSortValue] = useState('Featured');
const [sort, setSort] = useState('desc');

let viewId =  props.location.state ? props.location.state.viewId : 1;
const type = props.location.state?.type ? props.location.state?.type : 3;
// let title = viewId == '1' ? 'Buyers' : viewId == '2' ? 'Vendor' : viewId == '3' ? 'Distributor' : viewId == '4' ? 'Retailer' : 'Buyers';

useEffect(() => {
  compData=[];
  setCompData([]);
  // setIsFetching(false);
  setLoading(true);
  getproductlist();
}, [filterValueState]);



const getproductlist = async() => {
  // setLoading(true);
  let postData={
    filter:getFilterData(),
    order:sort?sort:'asc',
    // search:search?search.name:null,
    offset:compData.length, //page * rowsPerPage,
    // // offset:1,
    limit:rowsPerPage,
    orderby:filterValueState.orderby?filterValueState.orderby:'product',
  }

  let res = await commonService.postService(postData, 'guest/my-products/').catch(err => setLoading(false) );
  if(res){
    console.log('aggre', res.data);
    setCompData(prevState => ([...prevState, ...res.data.result]));
    // setCompData(res.data.result);
    setTotal(res.data.total);
    setLoading(false);
  }

}

const getFilterData=()=>{
  let filter ={
    brandId:null,
    // countryId:null,
    productCategoryId:null,
    family: null,
    hot_topic: null,
    businessType: null,
  };
  // if(filterValueState.location && filterValueState.location.length>0){
  //   filter.countryId=filterValueState.location.map(x=> parseInt(x.id));
  // }
  if(filterValueState.family && filterValueState.family.length>0){
    filter.family=filterValueState.family.map(x=> parseInt(x.id));
  }
  if(filterValueState.brand && filterValueState.brand.length>0){
    filter.brandId=filterValueState.brand.map(x=> parseInt(x.id));
  }
  if(filterValueState.categories && filterValueState.categories.length>0){
    filter.productCategoryId=filterValueState.categories.map(x=> parseInt(x.id));
  }
  if(filterValueState.hotValue && filterValueState.hotValue.length>0){
    filter.hot_topic=filterValueState.hotValue.map(x=>parseInt(x.id));
  }
  if (filterValueState.businessType && filterValueState.businessType.length > 0) {
    filter.businessType = filterValueState.businessType.map(x => parseInt(x.id));
    // if (filter.businessType.length == 2)
    filter.businessType.push(3);
  }
  console.log('filteredstate', filterValueState);
  return filter;
}



const handleChangePage = (event, newPage) => {
  page=newPage;
  setPage(page);
  getproductlist();
};

const handleChangeRowsPerPage = (event) => {
  rowsPerPage = parseInt(event.target.value, 10);
  setRowsPerPage(rowsPerPage);
  getproductlist();
};

const handleChangeSelect = (input, value)=>{
  console.log('input, value', input, value);
  
  if(input==='location'){
    filterValueState.location= value;
  }
  if(input=='family'){
    filterValueState.family = value;
    if (filterValueState.categories?.length == 0 && filterValueState.brand?.length == 0)
        filterValueState.parent = 'family';
  }
  if(input==='brand'){
    filterValueState.brand= value;
    if (filterValueState.categories?.length == 0 && filterValueState.family?.length == 0)
        filterValueState.parent = 'brand';
  }
  if(input==='categories'){
    filterValueState.categories= value;
    if (filterValueState.brand?.length == 0 && filterValueState.family?.length == 0)
        filterValueState.parent = 'categories';
  }
  if(input=='hot_topics'){
    filterValueState.hotValue = value
  }
  if (input === 'businessType') {
    filterValueState.businessType = value;
  }
    
  if(input=='brandName'){
    filterValueState.orderby = value;
    setSortValue('Brand')
    setSort('asc');
  }
  
      
  if(input=='product1'){
    filterValueState.orderby = value;
    setSortValue('Name (A - Z)');
  }
  if(input=='product2'){
    filterValueState.orderby = value;
    setSortValue('Name (Z - A)');
  }
    
  if(input=='featured'){
    filterValueState.orderby = 'isFeatured';
    setSortValue('Featured');
    setSort('desc');
  }
  if(input=='country')
  {
    filterValueState.orderby = value;
    setSortValue('country');
    setSort('asc');
  }
  if(input=='order'){
    if(sort!=value)
      setSort(value);
  }
      
  setFilterValue({ ...filterValueState })
  console.log('filterValueState', filterValueState);
}

const resetFilterValue =()=>{
  filterValueState.location=[];
  filterValueState.brand=[];
  filterValueState.categories=[];
  filterValueState.businessType = [];
  setFilterValue({ ...filterValueState });
}

const [isFeatured, setIsFeatured] = useState(true);

const handleIsFeatured = (data) => {

  let hasData = data.map(x => {
    if(x.value)
      return true;
  })
  if(data.length && hasData){
    setIsFeatured(true);
  }
  else
    setIsFeatured(false);
}

const loadProducts = async() => {
  setLoading(true);
  await getproductlist();
}

  return (
<>
<div className="main-content">
<GuestNavbar/>
<Guestslider title={title} familyId={id} type={type} value={pathValue}/>
{/* <Card className="mb-0">
  <CardBody className="divid">
  <div className="GuestDivider"></div>
  </CardBody>
</Card> */}
{isFeatured && <Card className="mb-0">
  <CardBody>
    <Row>
      <Col lg="3" className="d-flex align-items-left justify-content-center flex-column">
      <h1 className="ProductTitle mr-3">Featured Picks for you in {title}</h1>
    
<Link >
        See all
      </Link>
      </Col>
      <Col lg="9">
        <GuestsliderProductOnly title={title} familyId={id} businessType={type}  handleIsFeatured={handleIsFeatured}/>
      </Col>
    </Row>
  </CardBody>
</Card>}
{/* <Card className="mb-0">
  <CardBody className="divid">
  <div className="GuestDivider"></div>
  </CardBody>
</Card> */}
<Row>
  {/* <Col className="leftFilterBg pt-4" lg="2">
  <ProductFilter handleChangeSelect={handleChangeSelect} filterValueState={filterValueState} resetFilterValue={resetFilterValue} expand={id ? 'family' : ''} fromCat={1}/>

  </Col> */}
  <Col lg="12" className="ml--3">
  <GuesProductsforCategory title="Products" sort={true} compData={compData} loadProducts={loadProducts} loading={loading} total={total} 
                           handleChangeSelect={handleChangeSelect} sortValue={sortValue}
  />

  </Col> 
</Row>
</div>
  <GuestContact bg="bgwhite"/>
<GuestFooter/>
</>
  );
}
export default GuestCategoryListing;