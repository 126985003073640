import React, { useEffect, useMemo, useState } from "react";

// import defaultSports from "./AwardsData";
//Filter list by category in React JS
const BestAwardVideos_EMEA24 = () => {
    return (
        <div className='picvideo-wrap'>
            <div className='row'>
                <div style={{margin:"20px"}}>
                    <div className='menapic-wrap'>
                        <div className='menapic-img'>
                            <img src={"/images/bestpresentation.png"} />
                        </div>
                        <span style={{ paddingLeft: "15px", fontSize: "18px", color: "#333" }}>EMEA24-Best Vendor Final votes </span><br />
                        <a className='btn btn-primary' style={{ marginTop: "20px", marginLeft: "20px" }} download="EMEA24-Best Product Final votes pdf" href={"/pdf/EMEA24-Best Vendor Final votes.pdf"} id="downloadClick" title='EMEA24-Best Vendor Final votes pdf' > Download  PDF</a>

                    </div>
                </div>
                <div style={{margin: "20px 0"}}>
                    <div className='menapic-wrap'>
                        <div className='menapic-img'>
                            <img src={"/images/bestproduct.png"} />
                        </div>
                        <span style={{ paddingLeft: "15px", fontSize: "18px", color: "#333" }}>EMEA24-Best Product Final votes</span><br />
                        <a className='btn btn-primary' style={{ marginTop: "20px", marginLeft: "20px" }} download="EMEA24-Best Product Final votes pdf" href={"/pdf/EMEA24-Best Product Final votes.pdf"} id="downloadClick" title='EMEA24-Best Product Final votes pdf' > Download  PDF</a>

                    </div>
                </div>

            </div>
        </div>
    )
}
export default BestAwardVideos_EMEA24