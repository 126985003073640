import React, { useState, useEffect } from 'react';

import { history } from "_helpers";
import axios from 'axios';
import { commonService } from '_services/common.service';

const EventLoginRedirect = (props) => {


    const userData = JSON.parse(localStorage.getItem('CHuser'));

    //console.log("meets:", props)

    useEffect(() => {
        getRedirectDetails();
    }, [])

    const getRedirectDetails = async () => {

        await axios.get('/channelAdmin/getEventRedirect/' + props.match['params'].id+'/'+props.match['params'].channel).then(async res => {

            let redirectPage;
            console.log("eventredire:",res.data)

            let packOffers = []; let vatPercentage; let vatAmount; let offerAmount; let vatNumber;let isVatCompany;

            if (res.data[0].package?.price > 0 && res.data[0].paymentstatus != 1){
                let offers = res.data[0].package?.offerIds != ''?res.data[0].package?.offerIds.split(','):[];
                await commonService.postService({ eventId:res.data[0].eventId,offers: offers,channelTypeId:res.data[0].channelTypeId,regType:userData.eventRegisterAs==1?'Seller':'Buyer', channelId: res.data[0].channelId, contactId: res.data[0].registeredBy }, '/channelAdmin/packageOffers').then(res1 => {
                  console.log("res1:",res1.data);               
                  packOffers = res1.data.offers;
                  vatPercentage = res1.data.hasVat;
                  isVatCompany=res.data.isVatCompany;
                  vatNumber = res1.data.vatNumber;
                  offerAmount = res1.data.offers.filter(x => x.offerType == 1).length ? res1.data.offers.filter(x => x.offerType == 1).reduce((a, curr) => parseInt(a) + parseInt(curr.couponOfferValue), 0) : 0;
                })
              }

            
          

            if (!userData) {
                if (res.data[0].paymentstatus == '1' && res.data[0].approvalStatus == 1) {
                    redirectPage = 'events/eventManagement/'+props.match['params'].id;
                    localStorage.setItem('eventRedirect', JSON.stringify({redirectPage: redirectPage, firstLogin: '1', eventId: res.data[0].eventId }));
                } else if ((res.data[0].paymentstatus == '0' || res.data[0].paymentstatus == null) && res.data[0].approvalStatus == 1) {
                    const paymentDetails = {
                        actualAmount: res.data[0].package.price,
                        package: [{ pacakgeId: res.data[0].packageId, price: res.data[0].package.price, packageName: res.data[0].package.packageName}],
                        // channelId: userData.channelId,
                        // contactId: userData.contactId,
                        finalAmount: res.data[0].package.price-offerAmount,
                        eventId: res.data[0].eventId,
                        packageId: res.data[0].packageId,
                        isPrimary: true,

                        offerAmount: offerAmount,
                        offers: packOffers,
                        vatPercentage: vatPercentage,
                        isVatCompany:isVatCompany,
                        vatNumber: vatPercentage ? vatNumber : '',
                      }
                    redirectPage = 'Subscription/EventPayment';
                    localStorage.setItem('eventRedirect', JSON.stringify({paymentDetails:paymentDetails,redirectPage: redirectPage, firstLogin: '1', eventId: res.data[0].eventId }));
                }
                
                history.push('/Login');
            }
            else {           
                
                if (res.data[0].paymentstatus == '1' && res.data[0].approvalStatus == 1) {
                    redirectPage='admin/events/eventManagement/'+props.match['params'].id;
                    localStorage.setItem('eventRedirect', JSON.stringify({ redirectPage: redirectPage, firstLogin: '0', eventId: res.data[0].eventId }));
                    history.push(redirectPage);
                } else if ((res.data[0].paymentstatus == '0' || res.data[0].paymentstatus == null) && res.data[0].approvalStatus == 1) {
                    redirectPage='admin/Subscription/EventPayment';
                    const paymentDetails = {
                        actualAmount: res.data[0].package.price,
                        package: [{ pacakgeId: res.data[0].packageId, price: res.data[0].package.price, packageName: res.data[0].package.packageName}],
                        // channelId: userData.channelId,
                        // contactId: userData.contactId,
                        finalAmount: res.data[0].package.price-offerAmount,
                        eventId: res.data[0].eventId,
                        packageId: res.data[0].packageId,
                        isPrimary: true,

                        offerAmount: offerAmount,
                        offers: packOffers,
                        vatPercentage: vatPercentage,
                        vatNumber: vatPercentage ? vatNumber : '',
                      }
                    localStorage.setItem('eventRedirect', JSON.stringify({paymentDetails:paymentDetails, redirectPage: redirectPage, firstLogin: '0', eventId: res.data[0].eventId }));
                    history.push(redirectPage);
                }
                
            }
        })
    }

    return (
        <>
        </>
    )


}

export default EventLoginRedirect;

