import React, { useEffect, useMemo, useState } from "react";
import YoutubeEmbed from './YoutubeEmbed';
import AwardS3VideoItem from "./AwardS3VideoItem";

const PicturesVideos = ({ eventId }) => {

   // if(eventId == 39) {
      // Default Value
        const videos = [
            { companyname: "WOBIE GMBH", productname: "WOBIE", embedId: "Event/EMEA25/ENTERTAINMENT/GOVEE.mp4", category: "Entertainment", awardlogo: "/images/audio_winner.png" },
            { companyname: "WOBIE GMBH", productname: "WOBIE", embedId: "Event/EMEA25/ENTERTAINMENT/MYFIRST.mp4", category: "Entertainment", awardlogo: "/images/audio_winner.png" },
            { companyname: "WOBIE GMBH", productname: "WOBIE", embedId: "Event/EMEA25/ENTERTAINMENT/ONEODIO.mp4", category: "Entertainment", awardlogo: "/images/audio_winner.png" },
            { companyname: "WOBIE GMBH", productname: "WOBIE", embedId: "Event/EMEA25/ENTERTAINMENT/POWERLOCUS.mp4", category: "Entertainment", awardlogo: "/images/audio_winner.png" },
            { companyname: "WOBIE GMBH", productname: "WOBIE", embedId: "Event/EMEA25/ENTERTAINMENT/WOBIE.mp4", category: "Entertainment", awardlogo: "/images/audio_winner.png" },
            { companyname: "WOBIE GMBH", productname: "WOBIE", embedId: "Event/EMEA25/ENTERTAINMENT/YABER.mp4", category: "Entertainment", awardlogo: "/images/audio_winner.png" },
           
            
            { companyname: "AGI TECHNOLOGY", productname: "AGI TECHNOLOGY", embedId: "Event/EMEA25/IT_GAMING/1AGI+TECHNOLOGY.mp4", category: "IT GAMING", awardlogo: "/images/gaming_winner.png " },
            { companyname: "MIONIX", productname: "MIONIX", embedId: "Event/EMEA25/IT_GAMING/2MIONIX.mp4", category: "IT GAMING", awardlogo: "/images/gaming_winner.png " },
            { companyname: "PW3R", productname: "PW3R", embedId: "Event/EMEA25/IT_GAMING/3PW3R.mp4", category: "IT GAMING", awardlogo: "/images/gaming_winner.png " },
            { companyname: "VENTION", productname: "VENTION", embedId: "Event/EMEA25/IT_GAMING/4VENTION.mp4", category: "IT GAMING", awardlogo: "/images/gaming_winner.png " },
            { companyname: "VERBATIM", productname: "VERBATIM", embedId: "Event/EMEA25/IT_GAMING/5VERBATIM.mp4", category: "IT GAMING", awardlogo: "/images/gaming_winner.png " },
            { companyname: "WENGER", productname: "WENGER", embedId: "Event/EMEA25/IT_GAMING/6WENGER.mp4", category: "IT GAMING", awardlogo: "/images/gaming_winner.png " },


            { companyname: "3mk", productname: "3mk", embedId: "Event/EMEA25/MOBILE_PRODUCTS_ACCESSORIES/1-3mk.mp4", category: "MOBILE_PRODUCTS_ACCESSORIES", awardlogo: "/images/gaming_winner.png " },
            { companyname: "AUKEY", productname: "AUKEY", embedId: "Event/EMEA25/MOBILE_PRODUCTS_ACCESSORIES/2-AUKEY.mp4", category: "MOBILE_PRODUCTS_ACCESSORIES", awardlogo: "/images/gaming_winner.png " },
            { companyname: "HIFUTURE", productname: "HIFUTURE", embedId: "Event/EMEA25/MOBILE_PRODUCTS_ACCESSORIES/3-HIFUTURE.mp4", category: "MOBILE_PRODUCTS_ACCESSORIES", awardlogo: "/images/gaming_winner.png " },
            { companyname: "MYSCREEN", productname: "MYSCREEN", embedId: "Event/EMEA25/MOBILE_PRODUCTS_ACCESSORIES/4-MYSCREEN.mp4", category: "MOBILE_PRODUCTS_ACCESSORIES", awardlogo: "/images/gaming_winner.png " },

            { companyname: "ANSMANN", productname: "ANSMANN", embedId: "Event/EMEA25/POWER_SOLUTION/1-ANSMANN.mp4", category: "POWER_SOLUTION", awardlogo: " " },
            { companyname: "FIXED", productname: "FIXED", embedId: "Event/EMEA25/POWER_SOLUTION/3-FIXED.mp4", category: "POWER_SOLUTION", awardlogo: " " },
            { companyname: "GENDOME", productname: "GENDOME", embedId: "Event/EMEA25/POWER_SOLUTION/4-GENDOME.mp4", category: "POWER_SOLUTION", awardlogo: " " },
            { companyname: "ENERGIZER", productname: "ENERGIZER", embedId: "Event/EMEA25/POWER_SOLUTION/2-ENERGIZER.mp4", category: "POWER_SOLUTION", awardlogo: " " },
            { companyname: "VEGER POWER", productname: "VEGER POWER", embedId: "Event/EMEA25/POWER_SOLUTION/6-VEGER_POWER.mp4", category: "POWER_SOLUTION", awardlogo: " " },
            { companyname: "UNIQ", productname: "UNIQ", embedId: "Event/EMEA25/POWER_SOLUTION/5-UNIQ.mp4", category: "POWER_SOLUTION", awardlogo: " " },


            { companyname: "ELARI iQIBLA", productname: "ELARI  iQIBLA", embedId: "Event/EMEA25/LIFE_TYLE/1-ANSMANN.mp4", category: "LIFE_TYLE", awardlogo: " " },
            { companyname: "IBLE", productname: "IBLE", embedId: "Event/EMEA25/LIFE_TYLE/3IBLE.mp4", category: "LIFE_TYLE", awardlogo: " " },
            { companyname: "SJCAM", productname: "SJCAM", embedId: "Event/EMEA25/LIFE_TYLE/6SJCAM.mp4", category: "LIFE_TYLE", awardlogo: " " },
            { companyname: "FRAMEO", productname: "FRAMEO", embedId: "Event/EMEA25/LIFE_TYLE/2FRAMEO.mp4", category: "LIFE_TYLE", awardlogo: " " },
            { companyname: "JISULIFE", productname: "JISULIFE", embedId: "Event/EMEA25/LIFE_TYLE/4JISULIFE.mp4", category: "LIFE_TYLE", awardlogo: " " },
            { companyname: "POCKETBOOK", productname: "POCKETBOOK", embedId: "Event/EMEA25/LIFE_TYLE/5POCKETBOOK.mp4", category: "LIFE_TYLE", awardlogo: " " },


            { companyname: "SWANN", productname: "SWANN", embedId: "Event/EMEA25/SMART_LIVING/6SWANN.mp4", category: "SMART_LIVING", awardlogo: " " },
            { companyname: "BIRDFY", productname: "BIRDFY", embedId: "Event/EMEA25/SMART_LIVING/4BIRDFY.mp4", category: "SMART_LIVING", awardlogo: " " },
            { companyname: "AIRDOG", productname: "AIRDOG", embedId: "Event/EMEA25/SMART_LIVING/1AIRDOG.mp4", category: "SMART_LIVING", awardlogo: " " },
            { companyname: "TORNADO", productname: "TORNADO", embedId: "Event/EMEA25/SMART_LIVING/7TORNADO.mp4", category: "SMART_LIVING", awardlogo: " " },
            { companyname: "MEROSS", productname: "MEROSS", embedId: "Event/EMEA25/SMART_LIVING/5MEROSS.mp4", category: "SMART_LIVING", awardlogo: " " },
            { companyname: "AOSU", productname: "3AOSU", embedId: "Event/EMEA25/SMART_LIVING/3AOSU.mp4", category: "SMART_LIVING", awardlogo: " " },
            { companyname: "AIRSEEKERS", productname: "AIRSEEKERS", embedId: "Event/EMEA25/SMART_LIVING/2AIRSEEKERS.mp4", category: "SMART_LIVING", awardlogo: " " },
            
        ]
        const [catList, setCatList] = useState([]);
    
        const [selectedCategory, setSelectedCategory] = useState();
    
        // Add default value on page load
        useEffect(() => {
            setCatList(videos);
        }, []);
    
        // Function to get filtered list
        function getFilteredList() {
            // Avoid filter when selectedCategory is null
            if (!selectedCategory) {
                return catList;
            }
            return catList.filter((item) => item.category === selectedCategory);
        }
    
        // Avoid duplicate function calls with useMemo
        var filteredList = useMemo(getFilteredList, [selectedCategory, catList]);
    
        function handleCategoryChange(event) {
            setSelectedCategory(event.target.value);
        }
   // }
    return (
        <div>
         {(eventId == 39)?   
           <> 
           <div className='picvideo-wrap'>
           <h2>  Pictures</h2>
            <div className='row'>
            
                <div className='col-sm-6'>
               
                    <div className='pic-wrap'>
                        
                   
                        <div className='pic-img' style={{marginTop:'20px'}}>
                        <img  alt="..." src={process.env.REACT_APP_CDN_URL+'Event/EMEA25/attending_event.png'} />
                        </div>
                      

                    </div>
                </div>
                <div className='col-sm-6'>               
                    <div className='pic-wrap'>
                        {/* <h4>Video</h4>
                        <YoutubeEmbed  embedId="TbEFm5MikAI"  />  */}
                         <p  style={{paddingTop:'100px'}}> To get your personal photo gallery, register on this link: <a href="https://channelhub.premagic.com/channel-summit-emea-2025-v3ca/register/" target='_blank '>https://channelhub.premagic.com/channel-summit-emea-2025-v3ca/register/</a></p>
                         <p>  To get all the event pictures click here:<a href=" https://channelhub.premagic.com/share/Channel-Summit-EMEA-2025/#/" target='_blank '> https://channelhub.premagic.com/share/Channel-Summit-EMEA-2025/#/</a></p>
                    </div>
                </div>       

            </div>
            <hr></hr>
            <h2>  Video</h2>
             <div className="awards-wrapper">
             
                  <div className="filter-container">
                    <span><b>Filter by Category:</b></span>
                      <select
                        name="category-list"
                        id="category-list"
                        defaultValue=""
                        onChange={handleCategoryChange}
                      >
                        <option value="">All</option>
                        {/* <option value="Audio" >Audio</option>
                        <option value="Electronics and IoT">Electronics and IoT</option> */}
                        <option value="Entertainment" selectedCategory>Entertainment</option>
                        <option value="IT GAMING">IT GAMING</option>
                        <option value="MOBILE_PRODUCTS_ACCESSORIES">MOBILE PRODUCTS ACCESSORIES</option>
                        <option value="POWER_SOLUTION">POWER SOLUTION</option>
                        <option value="LIFE_TYLE">LIFE TYLE</option>
                        <option value="SMART_LIVING">SMART LIVING</option>
                      </select>
                  </div>
                  <div className="cat-list">
                  <div className="row">
            
                    {filteredList.map((element, index) => (
                      <AwardS3VideoItem {...element} key={index} />
            
                    ))}
                      </div>
            
                  </div>
             </div></div></>
        :
        <>
       
        <div className='picvideo-wrap'>
            <div className='row'>
                <div className='col-sm-6'>
                    <div className='pic-wrap'>
                        <h4>Pictures</h4>
                    <a href='https://www.flickr.com/photos/67672940@N05/albums/with/72177720308306710' target='_blank'>
                        <div className='pic-img' style={{marginTop:'20px'}}>
                            <img src={"/images/pictureback.jpg"} />
                        </div>
                       </a>

                    </div>
                </div>
                <div className='col-sm-6'>
                    <div className='pic-wrap'>
                        <h4>Video</h4>
                        <YoutubeEmbed  embedId="TbEFm5MikAI"  />
                    </div>
                </div>
            </div>
        </div>
        
        </>}
        </div>



    )
}
export default PicturesVideos;