import React ,{useEffect} from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {
Button, Modal,
} from "reactstrap";
import { ProfileUploader } from '../ProfileUpload';
import { commonService } from '_services/common.service';
import {Spinner} from 'views/Hoc/Spinner';

export const AddBrand=(props)=> {
const { opneadd, closeModalPopup, brandName } = props
const [open, setOpen] = React.useState(false);
const theme = useTheme();
const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
useEffect(() => {
setOpen(true);
}, [])
const handleClickOpen = () => {
setOpen(true);
};

const handleClose = () => {
setOpen(false);
};

const [image, setImage] = React.useState('');
const [imageSrc, setImageSrc] = React.useState('');
const [brand, setBrand] = React.useState(brandName);
const [loading, setLoading] = React.useState(false);
console.log('brandaa', brand);

const selectImg = async(data)=>{
    if(data){
      setImage('');
      //converting base64 string to file format.
      var random = Math.random(); 
      var fileName =random +'.png';
      var file = dataURLtoFile(data, fileName);    
      const formData = new FormData();
      formData.append('file', file);
      setLoading(true);
      try{
      const resImagePath = await commonService.fileUploadService(formData, '/mediaupload/upload/'+props.aws_folderName);
      console.log('resImagePath', resImagePath);
      if(resImagePath){
        setImageSrc(resImagePath.data.url);
      }
      }catch(e){
        setError("PNG Image Formats Only Accepted");
      }
       setLoading(false); 
    }
    
  }

  function dataURLtoFile(dataurl, filename) {
 
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
        
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    
    return new File([u8arr], filename, {type:mime});
}

const handleChange = event => {
    const data = event.target.files[0];
    const maxSizeInBytes = 1 * 1024 * 1024;
    if (data && data.size > maxSizeInBytes) {
      let err =
        "The image size exceeds the allowed limit of 1 Mb.";
      setError(err);
      return;
    }
    console.log('files', data)
      setError('');
      if(data && data['type'].split('/')[0] === 'image'){
        console.log('datat', data);
        let file = data;
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          console.log('file', file)
          console.log('reader.result' ,reader.result)
          setImage(
            reader.result
          );
          console.log('image', image)
          //setOpen(false);          
        };
        
      }
      else{
        setError('Jpg | Jpeg | PNG Image Formats Only Accepted');
      }
    
  };

  const handleCropClose = () => {
    setImage('');
  }

  const  [error, setError] = React.useState('');

  const addBrandData = async() => {
    if(brand && imageSrc){
        console.log('brand', brand);
        const isAvailable = await commonService.postService({
          brandName: brand,
        }, 'setting/channelBrandCheck');
        if(isAvailable.data){
          let random = '10' + Math.floor(Math.random() * 100000);
          props.handleChangeSelect('brands', {name:brand, id: +random, doc_id:random,  documentpath:imageSrc, isNew: '1'});
          closeModalPopup();
        }else
        {
          setError('BrandName already taken.')
        }
    }else{
        let err = '';
        err = !brand ? 'Please Enter Brand Name' : '';
        err = !imageSrc ? err ? 'Please Enter Brand Name and Select Brand Image' :  'Please Choose an image' : err;
        setError(err);
        console.log('error', error);
    }
  }

  const handleBrand = e => {
    setBrand(e.target.value);
  }

return (
<div>
  
  <Modal
className="modal-dialog-centered"
isOpen={opneadd} >
<div className="modal-header">
<h3 className="text-center">Add New Brand</h3>
<button
aria-label="Close"
className="close"
data-dismiss="modal"
type="button"
onClick={closeModalPopup}
>
<span aria-hidden={true}>×</span>
</button>
</div>
<div className="modal-body">
<div className="row">
{loading ? <div style={{ float: 'center' }}><Spinner /></div> : <>
<div className="col-6 d-flex align-items-center">
<TextField id="outlined-basic" label="Brand Name" variant="outlined" onChange={handleBrand} value={brand === true ? '' : brand}/>
</div>
<div className="col-6">
<ProfileUploader img={imageSrc ? process.env.REACT_APP_CDN_URL_TEMP+imageSrc : 'http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'}
handleChange={handleChange} selectImg={selectImg} image={image} handleCropClose={handleCropClose}
/> 
</div></>
}
</div>
<span style={{color: '#f44336', fontSize:'0.75rem'}}>{error}</span>
</div>

<div className="modal-footer">
<Button
color="secondary"
data-dismiss="modal"
type="button"
onClick={closeModalPopup}
>
Close
</Button>
<Button
className='btn btn-primary'
data-dismiss="modal"
type="button"
onClick={addBrandData}
>
Add
</Button>
</div>
</Modal>
</div>
);
}
export default AddBrand;