export const employees = [{
  text : 'Demo call with Farouk Hemraj',
  id: 1,
  color: '#56ca85',
  avatar: 'https://app.hubspot.com/hubsettings/v1/avatar/hash/d523b9d2540aa43c884bd1afe59a3311/144',
  age: 27,
  discipline: 'CEO, ChannelHub'
}];

export const data = [{
  text: 'Helen',
  employeeID: 2,
  startDate: new Date('2021-07-27T16:30:00.000Z'),
  endDate: new Date('2021-07-27T18:30:00.000Z')
}, {
  text: 'Helen',
  employeeID: 2,
  startDate: new Date('2021-08-05T16:30:00.000Z'),
  endDate: new Date('2021-08-06T18:30:00.000Z')
}, {
  text: 'Alex',
  employeeID: 1,
  startDate: new Date('2021-07-28T16:30:00.000Z'),
  endDate: new Date('2021-07-28T18:30:00.000Z')
}, {
  text: 'Alex',
  employeeID: 1,
  startDate: new Date('2021-08-06T19:00:00.000Z'),
  endDate: new Date('2021-08-06T20:00:00.000Z')
}, {
  text: 'Alex',
  employeeID: 2,
  startDate: new Date('2021-08-11T16:30:00.000Z'),
  endDate: new Date('2021-08-11T18:30:00.000Z')
}, {
  text: 'Stan',
  employeeID: 1,
  startDate: new Date('2021-08-02T16:30:00.000Z'),
  endDate: new Date('2021-08-02T18:30:00.000Z')
}, {
  text: 'Stan',
  employeeID: 1,
  startDate: new Date('2021-08-23T16:30:00.000Z'),
  endDate: new Date('2021-08-23T18:30:00.000Z')
}, {
  text: 'Stan',
  employeeID: 1,
  startDate: new Date('2021-08-25T16:30:00.000Z'),
  endDate: new Date('2021-08-25T18:30:00.000Z')
},
{
  text: 'Rachel',
  employeeID: 2,
  startDate: new Date('2021-07-30T16:30:00.000Z'),
  endDate: new Date('2021-07-30T18:30:00.000Z')
}, {
  text: 'Rachel',
  employeeID: 2,
  startDate: new Date('2021-08-02T16:30:00.000Z'),
  endDate: new Date('2021-08-02T18:30:00.000Z')
}, {
  text: 'Rachel',
  employeeID: 1,
  startDate: new Date('2021-08-16T16:30:00.000Z'),
  endDate: new Date('2021-08-16T18:30:00.000Z')
}, {
  text: 'Kelly',
  employeeID: 2,
  startDate: new Date('2021-08-10T16:30:00.000Z'),
  endDate: new Date('2021-08-10T18:30:00.000Z')
}, {
  text: 'Kelly',
  employeeID: 2,
  startDate: new Date('2021-08-24T16:30:00.000Z'),
  endDate: new Date('2021-08-24T18:30:00.000Z')
}];
