import React, {useEffect, useState} from 'react';
import { Col,Row,Container,Card,CardImg,CardHeader,Badge,CardText } from "reactstrap";
import CardBody from 'reactstrap/lib/CardBody';
const Guestchannels = () => {
    return (
        <div>
            {/* <Card className="mb-0 Topborder">
                <CardBody>
            <div className='countshow'>
                <Container>
                <Row>
                    <Col lg='4' className='text-center'>
                        <div>
                            <h1>10000 +</h1>
                            <p>
                            Retail buyers discovering new products
                            </p>
                        </div>
                    </Col>
                    <Col lg='4' className='text-center bter'>
                    <div>
                        <h1>10000 +</h1>
                        <p>
                            Retail buyers discovering new products
                        </p>
                    </div>
                    </Col>
                    <Col lg='4' className='text-center'>
                    <div>
                        <h1>10000 +</h1>
                        <p>
                            Retail buyers discovering new products
                        </p>
                    </div>
                    </Col>
                </Row>
                </Container>
            </div></CardBody>
            </Card>
             <div className='vendor'>
             <Container fluid>
                <div className="cypgri p-5">
                    <Row>
                        <Col lg='6'>
                        <h1 className='pt-5'>Create your profile</h1>
                        <div className='cyptxtc'>
                            <p>Add your brands, product categories you are offering, target retail channels, key retailers you are currently serving.</p>
                            <p>Define your geographical scope: countries where you wish to expand and countries where you are currently active.</p>
                        </div>
                        </Col>
                        <Col lg='6'>
                            <div className='cypimgc'>
                            <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  src={require('assets/img/guestpage/vendors-profile-001.png')}/>
                            </div>
                        </Col>
                    </Row>
                </div>
                    </Container>
            </div>

            <div className='vendor mt-4'>
             <Container fluid>
                <div className="cypgri p-5">
                    <Row>
                        <Col lg='6'>
                            <div className='cypimgc'>
                            <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  src={require('assets/img/guestpage/vendors-profile-001.png')}/>
                            </div>
                        </Col>
                        <Col lg='6'>
                        <h1 className='pt-5'>Create your profile</h1>
                        <div className='cyptxtc'>
                            <h3>Testmonial</h3>
                            <p>Add your brands, product categories you are offering, target retail channels, key retailers you are currently serving.</p>
                            <h3>Testmonial</h3>
                            <p>Define your geographical scope: countries where you wish to expand and countries where you are currently active.</p>
                        </div>
                        </Col>
                    </Row>
                </div>
                    </Container>
            </div> */}
<Card className="m-0" style={{backgroundColor:"#f7f7f7"}}>
    <CardBody>
    <CardHeader style={{borderBottom:"none", backgroundColor:"#f7f7f7"}}>
    <h1 className='text-uppercase text-center' style={{backgroundColor:"#f7f7f7"}}>How It Works</h1>
  </CardHeader>
            <div className='discover mt-4'>
                <hr className='custom'/>
                <div className='p-5'>
                <Container fluid>
                <Row>
                    <Col lg='4' className='text-center'>
                        <div>
                            <div className='textcen mb-4'>
                            <div className='outer-circle rounded-circle'><h1><i class="fas fa-briefcase"></i></h1></div>
                            </div>
                            <h2>Create an account</h2>
                            <p>
                            your full company profile will be visible by all buyers even for free accounts
                            </p>
                        </div>
                    </Col>
                    <Col lg='4' className='text-center'>
                    <div>
                    <div className='textcen mb-4'>
                            <div className='outer-circle rounded-circle'><h1><i class="fas fa-bell"></i></h1></div>
                    </div>
                    <h2>Get alerts</h2>
                        <p>
                        everytime a buyer tags your company you'll be informed
                        </p>
                    </div>
                    </Col>
                    <Col lg='4' className='text-center'>
                    <div>
                    <div className='textcen mb-4'>
                            <div className='outer-circle rounded-circle'><h1><i class="fas fa-hourglass-start"></i></h1></div>
                    </div>
                    <h2>Get leads and connection requests</h2>
                        <p>
                        starts discussing with qualified buyers who will contact you through the platform and close deals!
                        </p>
                    </div>
                    </Col>
                </Row>
                </Container>
            </div>
            </div>
            </CardBody>
</Card>
        </div>
    );
};

export default Guestchannels;