import React, { useState, useEffect, useRef } from 'react';
import { Card,CardBody,CardHeader, Container,FormGroup ,Input,InputGroupAddon,InputGroupText,InputGroup,Col,Row } from "reactstrap";
import {QuillEditor,FormInput,FormAutocomplete,SingleAutocompleteGrouping,FormTextArea,FormAutocompleteGrouping,AddFieldWithPopUpAutocomplete} from "_components/FormElements/FormInput";
import { useForm} from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import ImageLibrary from 'views/Hoc/Dialog/ImageLibrary';
import {InputLabel,Select,TextField,FormControl,InputAdornment} from '@material-ui/core';
import MediaLibrary from "views/Hoc/Dialog/MediaLibrary";
import VideoPreview from 'views/Hoc/Dialog/VideoPreview';
import { commonService } from '_services/common.service';
import { Spinner } from 'views/Hoc/Spinner';
import {ToastsContainer, ToastsStore} from 'react-toasts';
import { companyProfileBasiEditSchema } from '../profile/validation';
const Channelprofedit = (props) => {
  const { changedData, closeDrawer, linkedIn }=props;
  var userData = JSON.parse(localStorage.getItem('CHuser'));
  const [loading, setLoading] = useState(true);
    const { register, handleSubmit, control, errors, watch } = useForm({
        mode:'onBlur',
       resolver: yupResolver(companyProfileBasiEditSchema),
      });
      const [state, setState] = useState({
        companyLogo : null,
        tweetPitch : '',
        companyDesc : '',
        introVideo : null,
        companyName: '',
        legalAddress: '',
        country: '',
        countryList: [],
        zipCode: '',
        city: '',
        phoneNumber1: '',
        phoneNumber2: '',
        mobile: '',
        email: '',
        websiteUrl: '',
        linkedIn: '',
        insta: '',
        facebook: '',
        twitter: '',
        tradeUrls: '',
        regAddressId: '',
        billAddressId: '',
        b2bList: [{id: Math.random(), value: ''}],
        deleted_B2B: [],
        social_linkedin_id: null,
        social_instagram_id: null,
        social_twitter_id: null,
        social_facebook_id: null,
        

        linkedIn_pre: 'https://www.linkedin.com/company/',
        insta_pre: 'https://www.instagram.com/',
        facebook_pre: 'https://www.facebook.com/',
        twitter_pre: 'https://twitter.com/', 
      })
      
      const myRef = useRef();

      const watchAll = watch(['companyName', 'websiteUrl', 'city', 'insta', 'linkedIn', 'facebook', 'twitter', 'zipCode','phoneNumber1','phoneNumber2','mobile','companyemail','tradeUrls','no_of_skus','storeCount']);//'tradeUrls',
      useEffect(() => {
        if(props.visible){
          getCountry();
          getCompanyDetails();
        }
    },[props.visible]);
    const getCountry=async()=>{
      await commonService.getServices('/common/getSignup/'+userData.channelTypeId).then(res =>{
        state.countryList=res.data.country;
        setState({...state});
      }).catch(err => {
        commonService.postService({err:JSON.stringify(err),reqUrl:window.location.pathname,service:'/common/getSignup/'},'/error')
      });
    }

    const getCompanyDetails=async ()=>{
      setLoading(true);
        await commonService.getServices('/setting/company').then(res =>{
        state.companyLogo=res.data.channel.channelDetail?.channelLogo?.documentPath;
        state.companyName=res.data.channel.companyName;        
        state.documentId=res.data.channel.channelDetail?.channelLogo?res.data.channel.channelDetail?.channelLogo?.documentId:null;
        state.introVideo=res.data.channel.channelDetail.welcomevideo?res.data.channel.channelDetail.welcomevideo?.documentId:null;
        if(res.data.channel.channelDetail.welcomevideo)
          setInitialVideo(process.env.REACT_APP_CDN_URL + res.data.channel.channelDetail.welcomevideo.documentUrl);
        state.legalAddress=res.data.channel.regAddress.address;
        state.regAddressId=res.data.channel.regAddressId;
        state.billAddressId=res.data.channel.billAddressId;     
        state.zipCode=res.data.channel.regAddress.postalCode;
        state.city=res.data.channel.regAddress.city;
        state.phoneNumber1=res.data.channel.channelDetail.phone1 ? res.data.channel.channelDetail.phone1 : ''; //.substring(res.data.channel.channelDetail.phone1.indexOf('-')+1,res.data.channel.channelDetail.phone1.length);
        state.phoneNumber2=res.data.channel.channelDetail.phone2 ? res.data.channel.channelDetail.phone2 : '';
        state.mobile=res.data.channel.channelDetail.mobileNo;  
        if(res.data.channel.channelDetail.webSiteUrl)
          state.websiteUrl=res.data.channel.channelDetail.webSiteUrl.substring(0, 2)=='//'?res.data.channel.channelDetail.webSiteUrl.substring(2,res.data.channel.channelDetail.webSiteUrl.length):res.data.channel.channelDetail.webSiteUrl;
        state.email=res.data.channel.companyMail;
        if(res.data.channel?.channelSocial?.length){
          res.data.channel.channelSocial.map(x => {
            if(x.socialSite.socialSiteId == 1)
              {
                state.social_linkedin_id=x.channelSocialLinkId;
                state.linkedIn=x.channelSocialLink?.split(/[?#]/)[0].replace(/\/$/, "").split('/').splice(-1, 1)[0];
              }
            if(x.socialSite.socialSiteId == 3)
            {
              state.social_instagram_id=x.channelSocialLinkId;
              state.insta=x.channelSocialLink?.split(/[?#]/)[0].replace(/\/$/, "").split('/').splice(-1, 1)[0];
            }
            if(x.socialSite.socialSiteId == 4)
            {
              state.social_twitter_id=x.channelSocialLinkId;
              state.twitter=x.channelSocialLink?.split(/[?#]/)[0].replace(/\/$/, "").split('/').splice(-1, 1)[0];
            }
            if(x.socialSite.socialSiteId == 5)
            {
              state.social_facebook_id=x.channelSocialLinkId;
              state.facebook=x.channelSocialLink?.split(/[?#]/)[0].replace(/\/$/, "").split('/').splice(-1, 1)[0];
            }
              
            if(x.socialSite.socialSiteId == 2)
              state.tradeUrls=x.channelSocialLink
          })
        }

        if(res.data.channel?.channelB2B?.length){
          state.b2bList = (res.data.channel.channelB2B.map(x => 
            ({channel_B2B_id: x.channelB2BId, value: x.channelB2B})
          ))
        }
        
        state.tweetPitch=res.data.channel.channelDetail.detailDesc? res.data.channel.channelDetail.detailDesc.replace(/<[^>]+>/g, '') : '';
        state.companyDesc = res.data.channel.channelDetail.companyDesc ? res.data.channel.channelDetail.companyDesc.replace(/<[^>]+>/g, '') : '';
        state.country = {name: res.data.channel.country, id: res.data.channel.countryId};
        setState({...state});
        setLoading(false);

        if(linkedIn && myRef){
          myRef.current.scrollIntoView({ behavior: 'smooth' });
        }

      }).catch(err => {
        commonService.postService({err:JSON.stringify(err),reqUrl:window.location.pathname,service:'/setting/company'},'/error')        
        setLoading(false)
      });
    }

      const [initialVideo, setInitialVideo] = useState(''); 
      const [open, setOpen] = useState(false);

      const getCompanyLogo = (resImagePath) => {  
        setState({...state, companyLogo: resImagePath.document.documentPath, documentId: resImagePath.documentId});
      };
      const handleChangeSelect =async (input,value) => {
        state[input]=value;
        setState({...state});
      }
      const handleChange = input => e => {
        if(input == 'tradeUrls'){
          let b2b = [...state.b2bList];
          console.log('b2b', b2b, e.target.value, e.target);
          b2b[e.target.id].value = e.target.value;
          setState({...state, b2bList: b2b})
        }
        else if(input == 'tweetPitch'){
          setState({...state, [input] : e.target.value.slice(0, 280)});  
        }
        else if(input == "linkedIn" || input == "twitter" || input == "facebook" || input == "insta"){
          setState({...state, [input]: e.target.value.slice(state[input+'_pre'].length, )});
          errors[input] = false;
        }
        else{
          setState({...state, [input] : e.target.value});
        }
        console.log("add",e.target.value, e.target);  
          
      }
      const addNewB2B = () => {
        setState({...state,  b2bList:[...state.b2bList, {id: Math.random(), value: ''}]});
      }
      const delB2B = (id) => {
        console.log('id', id);
        let del = state.deleted_B2B;
        state.b2bList.map((x,i) => {
          if(id == i && x.channel_B2B_id)
            del.push(x.channel_B2B_id);
        })
        let temp = state.b2bList.filter((r,i) => i !== id);
        setState({...state, b2bList: temp, deleted_B2B: del});
      }
      const introVideoId = async(data)=>{
        console.log('introVideoId',data.documentId);
        setInitialVideo('');
        state.introVideo= data.documentId;
        setState({...state});
        setLoading(true)
        let res = await commonService.postService({id:data.documentId}, '/setting/updateIntroVideo')
          .catch(err=> {
            ToastsStore.error('Something Went Wrong!');
            commonService.postService({err:JSON.stringify(err),reqUrl:window.location.pathname,service:'/setting/updateIntroVideo'},'/error')
          });
          if(res){
            ToastsStore.success('Intro video Saved Successfully!');
          }
          setLoading(false);
      }

      console.log('state',state);

      const save = async() => {
        try{
        setLoading(true);
        console.log('watchAll',watchAll);
        console.log('state',state);
      let data = {
            company_logo_url: state.documentId,
            company_name: watchAll.companyName,
            company_about : state.tweetPitch,
            company_full : state.companyDesc,
            company_introvideo : state.introVideo,
            is_same_address: 0,
            reg_address_id: +state.regAddressId,
            reg_country_id: +state.country.id,
            reg_address: state.legalAddress,
            reg_postal_code: watchAll.zipCode,
            reg_city: watchAll.city,
            company_phone1: watchAll.phoneNumber1+'',
            // company_phone2: watchAll.phoneNumber2+'',
            company_mobile_no: watchAll.mobile,
            company_email: watchAll.companyemail,
            company_website_url: watchAll.websiteUrl,
            social_linkedin_url: state.linkedIn,
            social_instagram_url: state.insta,
            social_linkedin_id: state.social_linkedin_id,
            social_googleplus_id: null,
            social_instagram_id: state.social_instagram_id,
            contact_linkedin_id: null,
            social_twitter_url: state.twitter,
            social_twitter_id: state.social_twitter_id,
            social_facebook_url: state.facebook,
            social_facebook_id: state.social_facebook_id,
            deleted_B2B:state.deleted_B2B.length?state.deleted_B2B : [null]
          }
          let b2b=[];
          if(state.b2bList.length){
            b2b = [].concat(state.b2bList.map(x => {
                if(x.channel_B2B_id && x.value)
                  return {channelB2B: x.value, channel_B2B_id: x.channel_B2B_id}
                else if(x.channel_B2B_id && !x.value){
                  state.deleted_B2B.push(x.channel_B2B_id);
                  return null;
                }else if(x.value && !x.channel_B2B_id){
                  return {channelB2B: x.value}
                }
            }));
            b2b = b2b.filter((el)=> el!=null);
            data = Object.assign(data, {deleted_B2B: state.deleted_B2B.length ? [... new Set(state.deleted_B2B)] : [null]});
            if(b2b.length)
            data = Object.assign(data, {B2B_url: b2b});
          }
          console.log('data',data);
          let res = await commonService.postService(data, '/setting/editBasicCompany')
          .catch(err=> {
            ToastsStore.error('Something Went Wrong!');
            commonService.postService({err:JSON.stringify(err),reqUrl:window.location.pathname,service:'/setting/updateCompany'},'/error')
          });
          if(res){
            changedData(Object.assign(data,{
              isoCode:state.country.countryCode,
              country:state.country.name,
              company_logo:state.companyLogo? process.env.REACT_APP_CDN_URL +state.companyLogo:null,
              introvideo:initialVideo}),1);
            closeDrawer();
          }
          setLoading(false)
        }
        catch(err){        
          console.log('err',err);
          ToastsStore.error('Something Went Wrong!');
          setLoading(false);    
          commonService.postService({err:JSON.stringify(err),reqUrl:window.location.pathname,service:'updateCompany'},'/error');       
        }
      }
    return (
      <>
      <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore}/>
      {loading ? <Spinner /> :<div>
         <form noValidate onSubmit={handleSubmit(save)}>
            <Card>
                 
          <CardBody >      
<div className="row"> 
    <div className="col-sm-12">
    <div className="row">
    <div className="col-sm-3 pl-0">
    <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="companyLogo" name="companyLogo" ref={register} value={state.companyLogo} /> 
    <h4>Company Logo *{!errors.companyLogo || state.companyLogo ? '' : <span style={{color: '#f44336', fontSize:'0.75rem'}}>   Please Select Company Logo</span>}</h4>
        <div>
       
       <ImageLibrary getDocumentId={getCompanyLogo} aws_folderName='CompanyLogo' id={1} size={1}
               img={state.companyLogo ? process.env.REACT_APP_CDN_URL+state.companyLogo : process.env.REACT_APP_CDN_URL+"document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"}/>
   </div>
</div>
    <div className="col-sm-6 mt-6">
        <span>Accepts Jpeg / Jpg / Png Only</span>
        <br></br>
        <span>(Max size allowed 200 Kb)</span>
        </div>
         </div>         
    </div>   
</div>
<div className="row">
  <div className="col-lg-12 mt-2">
  {/* <h4 >Company Legal Name *</h4> */}
  <FormInput autoFocus className="comp-name mt-2" id="companyName" name="companyName" label="Company Legal Name *" type="text" variant="outlined" value={state.companyName} defaultValue={state.companyName} error={errors.companyName}
  register={register}
  // value={watchAll.companyName}

  />
  </div>
</div>

<div className="row">
  <div className="col-lg-6 mt-3">
  <FormInput className="comp-name mt-2 addrs" id="outlined-search" label="Registered/Legal Address *" type="text" variant="outlined" value={state.legalAddress}
   onChange={handleChange('legalAddress')}
   multiline
  />
  
  </div>

  <div className="col-lg-6 mt-4 ">
  <div className="row">
  <FormControl variant="outlined" style={{width:"100%"}}>
  <FormAutocomplete
                        id="country"
                        name="country"
                        label="Country *"
                        options={state.countryList}
                        placeholder="Select Country"
                        // multiple
                        disableCloseOnSelect
                        blurOnSelect
                        limitTags={1}
                        control={control}                         
                        input='country'
                        value={ state.country }
                        handleChangeSelect={handleChangeSelect}
                        errMessage={'Please Select Country'}
                        error={errors.country }
                        defaultValue={state.country}
                        />
        </FormControl>
  </div>
  <div className="row">
  <FormInput className="comp-name mt-4" id="outlined-search" name="zipCode" placeholder='' label="Zip/Pincode *" type="text" variant="outlined" 
  value={watchAll.zipCode} 
  defaultValue={state.zipCode}
  //onChange={handleChange('zipCode')}  
  register={register}
  error={errors.zipCode}
  />
  </div>
  <div className="row">
  <FormInput className="comp-name mt-4" id="outlined-search1" name="city" label="City *" type="text" variant="outlined" value={watchAll.city}
  defaultValue={state.city}
  //onChange={handleChange('city')}  
  error={errors.city}
  register={register}
  />
  </div>
  </div>
</div>

<div className="row"> 
                <div className="col-sm-12 mt-4 ">
                <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="tweetPitch" name="tweetPitch" ref={register} value={state.tweetPitch} /> 
                {!errors.tweetPitch || (state.tweetPitch.length > 5 && state.tweetPitch.length < 280) ? <h3 style={{fontSize:'1rem'}}>Your Company - Tweet Pitch *
                {state.tweetPitch?.length>0 && <span style={{color:state.tweetPitch?.length>280?"red":"#9ea2a5"}}> remaining characters  {280-state.tweetPitch.length}</span> }
                </h3> :
             <h4>Your Company - Tweet Pitch * <span style={{color: '#f44336', float: 'right', fontSize:'0.75rem'}}>{
                  state.tweetPitch.length <= 1? 'Tweet Pitch can\'t be empty.' : state.tweetPitch.length <= 5 ? 'TweetPitch must have min 5 characters' : state.tweetPitch.length > 280 ? 'TweetPitch must have max 280 characters' : ''
                  }</span></h4> 
                }
          <div className="form-group">
          <FormTextArea
                  className="buytext2 w-100 h-auto"
                  name="note"
                  id="note" 
                  rows="3"
                  type="textarea"
                  label=" "
                  placeholder="Tell us about your company background and vision -max 280 characters *"
                  // handleChange={handleChangeQuill1} 
                  value={state.tweetPitch}
                  onChange={handleChange('tweetPitch')}
                  // register={register}
                //   value={note}
                //   onChange={handleChange}
              />
         
        </div>
        </div>
</div>

<div className="col-sm-12 mt-4 ">
                    <input
                      type="text"
                      style={{
                        position: "absolute",
                        left: "-10000px",
                        top: "auto",
                        width: "1px",
                        height: "1px",
                        overflow: "hidden",
                      }}
                      id="companyDesc"
                      name="companyDesc"
                      ref={register}
                      value={state.companyDesc}
                    />
                    {!errors?.companyDesc ||
                    (state.companyDesc?.length >= 8 &&
                      state.companyDesc?.length <= 2000) ? (
                      <h3 style={{ fontSize: "1rem" }}>
                        Your company’s full presentation (2000 characters) *
                        {state.companyDesc?.length>0 && <span style={{color:state.companyDesc?.length>2000?"red":"#9ea2a5"}}> remaining characters  {2000-state.companyDesc.length}</span> }
                      </h3>
                    ) : (
                      <h4>
                        Company’s full presentation{" "} *
                        <span
                          style={{
                            color: "#f44336",
                            float: "right",
                            fontSize: "0.75rem",
                          }}
                        >
                            {(!state.companyDesc||state.companyDesc==='')
                            ? "Company Description can't be empty."
                            : state.companyDesc?.length <= 5
                            ? "Company Description must have min 5 characters"
                            : state.companyDesc?.length > 280
                            ? "Company Description must have max 2000 characters"
                            : ""}
                        </span>
                      </h4>
                    )}
                    <div className="form-group mb-2">
                      <FormTextArea
                        className="buytext2 w-100"
                        placeholder="Tell us about your company description and vision -max 2000 characters *"
                        onChange={handleChange("companyDesc")}
                        value={state.companyDesc}
                      />
                      
                    </div>
                    {/* {2000 - state.companyDesc.length} remaining */}
                  </div>

<Row>
      <Col lg="12">
      {/* To scroll when editing linkedin url by clicking icon in channelDetail */}
      <div style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} ref={myRef} />


      <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="linkedIn" ref={register} name="linkedIn" value={state.linkedIn} defaultValue={state.linkedIn} control={control} />
      <a style={{float:'right', marginTop: '1rem'}} href={state.linkedIn_pre+state.linkedIn} target="_blank">View Link</a>
      <FormInput className="comp-name mt-4" id="outlined-search" name="linkedIn1" label="Linkedin Handle" type="text" variant="outlined" value={state.linkedIn_pre+state.linkedIn}
      onChange={handleChange('linkedIn')}
      // register={register}
      // error={!(/^(?=[a-zA-Z0-9._-]{0,100}$)(?!.*[_.-]{2})[^_.-].*[^_.-]$/.test(state.linkedIn)) && state.linkedIn ? {message:'Please Enter Valid LinkedIn Handle', required: true} : errors.linkedIn}
      error={errors.linkedIn}
      defaultValue={state.linkedIn_pre+state.linkedIn}
      autoFocus={linkedIn}
      />
      </Col>
    </Row>

    <Row>
      <Col lg="12">
      <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="twitter" ref={register} name="twitter" value={state.twitter} defaultValue={state.twitter} control={control} />
      <a style={{float:'right', marginTop: '1rem'}} href={state.twitter_pre+state.twitter} target="_blank">View Link</a>
      <FormInput className="comp-name mt-4" id="outlined-search" name="twitter1" label="Twitter Handle" type="text" variant="outlined" value={state.twitter_pre+state.twitter}
      onChange={handleChange('twitter')}
      // register={register}
      // error={!(/^(?=[a-zA-Z0-9._-]{0,15}$)(?!.*[_.-]{2})[^_.-].*[^_.-]$/.test(state.twitter)) && state.twitter ? {message:'Please Enter Valid Twitter Handle', required: true} : errors.twitter}
      error={errors.twitter}
      defaultValue={state.twitter}
      />
      </Col>
    </Row>

    <Row>
      <Col lg="12">
      <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="insta" ref={register} name="insta" value={state.insta} defaultValue={state.insta} control={control} />
      <a style={{float:'right', marginTop: '1rem'}} href={state.insta_pre+state.insta} target="_blank">View Link</a>
      <FormInput className="comp-name mt-4" id="outlined-search" name="insta1" label="Instagram Handle" type="text" variant="outlined" value={state.insta_pre+state.insta}
      onChange={handleChange('insta')}
      // register={register}
      // error={!(/^(?=[a-zA-Z0-9._-]{0,30}$)(?!.*[_.-]{2})[^_.-].*[^_.-]$/.test(state.insta)) && state.insta ? {message:'Please Enter Valid Instagram Handle', required: true} : errors.insta}
      error={errors.insta}
      defaultValue={state.insta}
      />
      </Col>
    </Row>

    <Row>
      <Col lg="12">
      <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="facebook" ref={register} name="facebook" value={state.facebook} defaultValue={state.facebook} control={control} />
      <a style={{float:'right', marginTop: '1rem'}} href={state.facebook_pre+state.facebook} target="_blank">View Link</a>
      <FormInput className="comp-name mt-4" id="outlined-search" name="facebook1" label="Facebook Handle" type="text" variant="outlined" value={state.facebook_pre+state.facebook}
      onChange={handleChange('facebook')}
      // register={register}
      // error={!(/^(?=[a-zA-Z0-9._-]{0,50}$)(?!.*[_.-]{2})[^_.-].*[^_.-]$/.test(state.facebook)) && state.facebook ? {message:'Please Enter Valid Facebook Handle', required: true} : errors.facebook}
      error={errors.facebook}
      defaultValue={state.facebook}
      />
      </Col>
    </Row>

    {state.b2bList && state.b2bList.map((x,i) => <Row>
      <Col lg="11">
      <FormInput className="comp-name mt-4" id={i} name={`tradeUrls[${i}].value`} label="Other B2B or Trade Portals Website URLs" type="text" variant="outlined" 
      value={watchAll[`tradeUrls[${i}].value`]}
      onChange={handleChange('tradeUrls')}
      register={register}
      error={errors.tradeUrls ? errors.tradeUrls[i] ? errors.tradeUrls[i].value : '' : ''}
      defaultValue={x.value}
      />
      </Col>
      {i == 0 ? <Col lg="1">
        <div className="faplus">
      <i  class="fa fa-plus-circle" onClick={addNewB2B}></i>
      </div>
      </Col> : 
      <Col lg="1">
        <div className="faminus">
        <i class="fa fa-minus-circle " onClick={() => delB2B(i)}></i>
      </div>
      </Col>}
    </Row>)}
    <Row>
    <Col lg='12'>
    <div className="form-group mt-4">
          <form action="/file-upload" className="dropzone">
  <div className="fallback">    
  <div style={{flex: 1, display:'flex'}}><MediaLibrary text="Upload Intro Video" introVideoId={introVideoId} id='1' size={2}/>
  {initialVideo && <span style={{textDecoration: 'underline' ,color:'orange', display:'flex', alignItems:'center', marginLeft: '2rem',cursor:"pointer"}} onClick={()=>setOpen(true)}>Preview Video</span>}</div>
  <VideoPreview videoModalClose={()=>setOpen(false)} isOpen={open} url={initialVideo}/>
  </div>
</form>
        </div>
        </Col>
        </Row>
</CardBody>
</Card>
<Card>
  <CardHeader>
  <h3 className="text-center">Communication</h3>
  <p className="text-center">Enter your general communication information: switchboard number, web site, general email</p>
  </CardHeader>
  <CardBody>
    <Row>
    <Col lg="6" className="code">
    <div className="form-group">            

{/* <label className="form-control-label" htmlFor="">
<input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="phoneNumber1" ref={register} name="phoneNumber1" />
</label> */}
<FormInput className="comp-name1" id="phoneNumber1" name="phoneNumber1"  label="PhoneNumber1 *" type="text" variant="outlined" value={watchAll.phoneNumber1}
    onChange={handleChange('phoneNumber1')}
    defaultValue={state.phoneNumber1}    
    error={errors.phoneNumber1}
    register={register}    
     />
{/* <InputGroup  className="reg reg_12">
  <InputGroupAddon addonType="prepend">
    <InputGroupText></InputGroupText>
  </InputGroupAddon>
  
           </InputGroup> */}
           </div> 
    
    </Col>
    <Col lg="6"  className="code">
    <div className="form-group">            
    
    {/* <FormInput className="comp-name1" id="phoneNumber2" name="phoneNumber2"  label="PhoneNumber2" type="text" variant="outlined" value={watchAll.phoneNumber2}
    onChange={handleChange('phoneNumber2')}
    defaultValue={state.phoneNumber2}    
    error={errors.phoneNumber2}
    register={register}    
     /> */}

     {/* <InputGroup  className="reg reg_12">
  <InputGroupAddon addonType="prepend">
    <InputGroupText></InputGroupText>
  </InputGroupAddon>
  
           </InputGroup> */}
           </div> 
    
    </Col>
    </Row>
  
    <Row>
    <Col lg="6"  className="code">
    <div className="form-group">            

    <FormInput className="comp-name1" id="mobile" name="mobile"  label="mobile" type="text" variant="outlined" value={watchAll.mobile}
    onChange={handleChange('mobile')}
    defaultValue={state.mobile}    
    error={errors.mobile}
    register={register}    
     />

{/* <InputGroup  className="reg reg_12">
  <InputGroupAddon addonType="prepend">
    <InputGroupText></InputGroupText>
  </InputGroupAddon> */}
  
           {/* </InputGroup> */}
           </div> 
    
    </Col>
    <Col lg="6">
    {/* <FormInput className="comp-name mt-3" id="outlined-search" label="EmailID" type="text" variant="outlined" /> */}
    <div className="form-group">
        <FormInput
            id="companyemail" 
            type="email" 
            label="Enter your Email" 
            name="companyemail" 
            register={register}
            error={errors.companyemail}
            value={watchAll.companyemail}
            defaultValue={state.email}
            onChange={handleChange('email')}
            //error={errors.companyemail ? errors.companyemail : checkMailId ? {message:"Email Id already taken", required: true} : ''}
            
            //defaultValue={email}
         />
        </div>
    </Col>
    </Row>
    <Row>
      <Col lg="12">
      <FormInput className="comp-name mt-2" id="outlined-search" name="websiteUrl" label="Website URL *" type="text" variant="outlined" value={watchAll.websiteUrl}
      defaultValue={state.websiteUrl}
      register={register}
      error={errors.websiteUrl}
      />
      </Col>
    </Row>
  </CardBody>
</Card>
<div className="text-center mt-4">
            <button type="submit" className="btn btn-primary" >Save</button>
          </div>
</form>
        </div>
      }
      </>
    );
};

export default Channelprofedit;