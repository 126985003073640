import React from 'react';
import axios from 'axios';
import {
  Button, Modal,
} from "reactstrap";
export  const ProfileZoom=(props)=> {
	const { url, imgModalClose, isOpen } = props;
  return (
    <div className=''>
      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open responsive dialog
      </Button> */}
      <Modal
          className="modal-dialog-centered companypic-zoom"
          isOpen={isOpen}
         
        >
          <div className="modal-header">
           
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={ imgModalClose}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body  text-center imgprev">
          
           <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  src={url}/>
          </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={imgModalClose}
            >
              Close
            </Button>
           
          </div>
        </Modal>
    </div>
  );
}
export default  ProfileZoom;