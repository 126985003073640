import React  from "react";
import { Card } from "reactstrap";
import YoutubeEmbed from "./YoutubeEmbed";
import PropTypes from "prop-types";


// React Component to display individual item
function AwardS3VideoItem({ companyname, productname, embedId, awardlogo ,category,videoId}) {
  const checkplayer= async (id, src) => {
   //document.getElementById('EventVideo_'+id).muted = true;
  //  let player = videojs('EventVideo_'+id);

  //  // Setting the volume of video player
  //  player.volume(0);
  }
  return(
    
  
    <div className="col-lg-4">
  <Card className="item-container">
   
    <div className="row">
      {/* <div className="col-lg-4" style={{padding:"0"}}>
      <span className="item-label" style={{display:"inline-block", marginBottom:"20px"}}>Product Name:</span>
      </div>
      <div className="col-lg-5" style={{padding:"0"}}>
      <p>{productname}</p>
      </div> */}
      {(awardlogo == null) ?
      <><div title={companyname} style={{ textOverflow:"ellipsis", overflow:"hidden", width:"350px", whiteSpace:"nowrap"}}>
      <strong>Company Name:</strong> {companyname}
      </div>

      <div title={productname} style={{ textOverflow:"ellipsis", overflow:"hidden", width:"350px", whiteSpace:"nowrap"}}>
      <strong>Product Name:</strong> {productname}
      </div></>
       :
       <>
      <div title={companyname} style={{ textOverflow:"ellipsis", overflow:"hidden", width:"310px", whiteSpace:"nowrap"}}>
      <strong>Company Name:</strong> {companyname}
     
      </div>

      <div title={productname} style={{ textOverflow:"ellipsis", overflow:"hidden", width:"310px", whiteSpace:"nowrap"}}>
      <strong>Product Name:</strong> {productname}
      </div> 
      </> }
    </div>
    <div className="award-logo">
      <img  src={awardlogo} />
    </div>
    <div>
    <div className="video-responsive" style={{padding:'20px'}}>
     {/* <iframe
      width="700"
      height="400"
     src={process.env.REACT_APP_CDN_URL+embedId}
      frameBorder="0"
      allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded URL"
    />  */}

<video id={'EventVideo_'+videoId} controls src={process.env.REACT_APP_CDN_URL+embedId}  style={{ width: '100%', height: '100%', borderRadius: '20px' }}  autoPlay={false} muted={false} onClick={checkplayer(videoId, process.env.REACT_APP_CDN_URL+embedId)}></video>
  </div>
    </div>
  </Card>
  </div>
)};
// YoutubeEmbed.propTypes = {
//   embedId: PropTypes.string.isRequired
// };

export default AwardS3VideoItem;