import React from 'react';
import { Card, CardBody, Container, Row, Col } from "reactstrap";
const Title = (props) => {
  console.log('desc', props.desc);
  return (
    <div>
      <div className="main-content">
        <Container className="mt-4 col-12" fluid>
          <Row>
            <Col className="card-wrapper" lg="12" md="12" sm="12" >
              <Card className="tit-card">
                <CardBody style={{ padding: '1rem' }} >
                  <Row>
                    <Col className="col-lg-3 col-md-4 col-12 icon-tag d-flex align-items-center text-uppercase suggestTitle"><h1> {props.title}</h1></Col>
                    <Col className="col-lg-9 pg-title ">
                    {props.desc.map((x, index) => (
                    <React.Fragment key={index}>
                            {typeof x === 'string' ? (
                            <span dangerouslySetInnerHTML={{ __html: x }} />
                                ) : (
                                  <span>{x}</span>
                                    )}
                     <br />
                    </React.Fragment>
            ))}</Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

    </div>
  );
};

export default Title;