import React, {useState, useEffect} from 'react';
import {Modal, Button} from 'reactstrap';
import { userActions } from '_actions/user.actions';

function IdleMonitor()
{
  //Modal
  const [idleModal, setIdleModal] = useState(false);

  let idleTimeout = 1000 * 60 * 30;  //30 minutes idle time
  let idleLogout = 1000 * 60 * 60; //1hr logout timer
  let idleEvent; 
  let idleLogoutEvent;
  let timer;

  const [idleLogoutTimer, setIdleLogoutTimer] = useState(60 * 60); //timer for logout will be shown on last 1hrs 

  /**
   * Add any other events listeners here
   */
  const events = [
    'mousemove',
    'click',
    'keypress'
  ];


  /**
   * @method sessionTimeout
   * This function is called with each event listener to set a timeout or clear a timeout.
   */
  const sessionTimeout = () => 
  {  
    if(!!idleEvent) clearTimeout(idleEvent);
    if(!!idleLogoutEvent) clearTimeout(idleLogoutEvent);

    idleEvent = setTimeout(() => {
        setIdleModal(true);
        timer = setInterval(() => setIdleLogoutTimer(prev => prev-1), 1000);
    }, idleTimeout); //show session warning modal.
    idleLogoutEvent = setTimeout(() => logOut(), idleLogout); //Call logged out on session expire.

  };

  /**
   * @method extendSession
   * This function will extend current user session.
   */
  const extendSession = () => 
  {
    console.log('user wants to stay logged in');
    sessionTimeout();
    clearInterval(timer);
    setIdleLogoutTimer(60 * 60);
    setIdleModal(false);
  }


  /**
   * @method logOut
   * This function will destroy current user session.
   */
  const logOut = () => 
  {
    console.log('logging out');
    setIdleModal(false);
    userActions.logout();
  }

  useEffect(() => 
  {
    for (let e in events) 
    {
      window.addEventListener(events[e], sessionTimeout);
    }

    return () => 
    {
      for(let e in events)
      {
        window.removeEventListener(events[e], sessionTimeout);
      }
    }
  },[]);

  //time conversion
  function secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d  % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return hDisplay + mDisplay + sDisplay; 
  }


    return (
            <div>
            <Modal className="modal-dialog-centered" isOpen={idleModal}>
                <div className="modal-header">
                <strong>Session Expire Warning</strong>
                <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => setIdleModal(false)}>
                    <span aria-hidden={true}>×</span>
                </button>
                </div>
                <div className="modal-body">
                <p>your session will expire in {secondsToHms(idleLogoutTimer)}.</p>
                <p>Do you want to extend the session?</p>
                </div>
                <div className="modal-footer">
                <Button color="primary" type="button" onClick={() => logOut()}>Logout</Button>
                <Button color="secondary" data-dismiss="modal" type="button" onClick={()=> extendSession()}>Extend Session</Button>
                </div>
            </Modal>
            </div>
    )

  }

export default IdleMonitor;