import React, { useState, useEffect } from "react";
// import {UncontrolledTooltip} from "reactstrap";
import { MousePopover } from 'views/Hoc/popover/MousePopover'
export const ListItem = (props) => {

    // console.log("elements:",props);
//    const [all, setState] = useState(false);

    let remainder = [];

    const _renderElements = (elements, index, limit) => { 

        const _elementsToRender = [];
        let _remain = [];
        const _elements = elements.slice(0,limit); // clone array     
  
        _remain = elements.slice(limit,elements.length);
          
        _elements.forEach(el => {
            _elementsToRender.push(el.item_name);
        });

        _remain.forEach(el => {
            remainder.push(el.item_name);
        });
        
        return (
            <span>
                
            {/* {remainder.length > 0 && (        
             <UncontrolledTooltip content='Delete video' target={index} placement='bottom'>
             {remainder.join()}
           </UncontrolledTooltip>      
            )} */}

              {_elementsToRender.join(', ')}{remainder.length > 0 && <MousePopover text={ '+'+remainder.length + ' more'} overData={remainder.join(', ')}/>}
            </span>
        );
     
}  

    return (
        <span>{_renderElements(props.elements, props.index, props.limit)}</span>
    );

}