export const getFreeze = (colHeaderName, columns,gridClasses, unfreeze) => {
    let forLeft = 0;
    let colToFreeze = 0;
    let newFree = {};


    columns.forEach((col, i) => {
      if (col.headerName === colHeaderName) {
        colToFreeze = i + 1;
      }
    });

    if (!unfreeze){
      for (let i = 1; i <= colToFreeze; i++) {
        
        newFree[`& .MuiDataGrid-cell:nth-child(${i})`] = {
          position: "sticky",
          top: "0",
          left: forLeft,
          background: "white",
          // paddingLeft: '1.5rem',
          zIndex: 999,
        };
  
        forLeft += columns[i - 1].width;
      }

    }else{

      for (let i = 1; i <= columns.length; i++) {
        newFree[`& .MuiDataGrid-cell:nth-child(${i})`] = {
          position: "relative",
          top: "0",
          left: "0",
         
          // paddingLeft: '1.5rem',
          zIndex: 9,
        };
  
        
      }
    }

    return newFree;
}




