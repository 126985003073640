import React, { useState, useEffect } from 'react';
import {Card,CardBody,Row,Col,Container} from "reactstrap";
import { commonService } from '_services/common.service';
import {ToastsContainer, ToastsStore} from 'react-toasts';
import axios from 'axios';
import { Spinner } from "views/Hoc/Spinner";
import { ImportProductDialog } from "views/Hoc/Dialog/ImportProduct";
import "react-step-progress-bar/styles.css";
import { ProgressBar } from "react-step-progress-bar";


var FileSaver = require('file-saver');
const Import = () => {

    let [xlmfileName, setXLMfileName] = useState(null);
    let [xlsxfileName, setXLSXfileName] = useState(null);
    let [downloadProcesTime, setDownloadProcesTime] = useState(1);
    let [downloadingText, setDownloadingText] = useState(null);
    let [sampleDownloadXLMLink, setSampleDownloadXLMLink] = useState(null);
    let [sampleDownloadXLSLink, setSampleDownloadXLSLink] = useState(null);
    let [isLoading, setLoading] = useState(false);
    const [OpenProductImport, setOpenProductImport] = useState(false)  
    useEffect(() => {
        document.getElementById('downloadmDIv').style.display = 'none';
        document.getElementById('downloadsxDIv').style.display = 'none';
        document.getElementById('downloadNotify').style.display = 'block';
        getSampleLink();
    }, [])

    const getSampleLink = async() => {
        //setLoading(true);
        setDownloadingText('downloading sample sheets....')
        setTimeout(function () {
            setDownloadProcesTime(10);
          }, 200);
          setTimeout(function () {
            setDownloadProcesTime(50);
          }, 350);

        let result = await commonService.getServices('productImport/productImportSample/macro')
        .catch(err =>console.log(err.result.data.message,  ToastsStore.error('unable to create sample download file')), document.getElementById('downloadmDIv').style.display = 'none');
        if (result) {  //setLoading(false);
            let xlmfile = result.data.sampleFileName;
            setXLMfileName(xlmfile);
            let sampleDownloadXLMLink = '/sampleDwn/' + xlmfile;
            setSampleDownloadXLMLink(sampleDownloadXLMLink);
            setDownloadProcesTime(100);
            document.getElementById('downloadmDIv').style.display = 'inline-flex';
            setDownloadingText('downloading completed.')
            setTimeout(function () {
                document.getElementById('downloadNotify').style.display = 'none';
              }, 350);
        }

        let result1 = await commonService.getServices('productImport/productImportSample/excel')
        .catch(err =>console.log(err.result.data.message,  ToastsStore.error('unable to create sample download file')), document.getElementById('downloadsxDIv').style.display = 'none');
        if (result1) {  //setLoading(false);
            let xlsxfile = result1.data.sampleFileName;
            setXLSXfileName(xlsxfile);
            let sampleDownloadXLSLink = '/sampleDwn/' + xlsxfile;
            setSampleDownloadXLSLink(sampleDownloadXLSLink);
            setDownloadProcesTime(100);
            document.getElementById('downloadsxDIv').style.display = 'inline-flex';
            setDownloadingText('downloading completed.')
            setTimeout(function () {
                document.getElementById('downloadNotify').style.display = 'none';
              }, 350);
        }

    }

    const downloadSampleLink = async(e) => {
        
        if (e == 'xlsm' && xlmfileName) {
            setLoading(false);
            axios.get('productImport'+sampleDownloadXLMLink, { responseType: 'arraybuffer'})
                .then((response) => {
                let  fileName = xlmfileName;
                
                var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    FileSaver.saveAs(blob, fileName);
                    setLoading(false);
                });
        } else if (e == 'xlsx' && xlsxfileName) {
            setLoading(false);
            axios.get('productImport'+sampleDownloadXLSLink, { responseType: 'arraybuffer'})
                .then((response) => {
                let  fileName = xlsxfileName;
                
                var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    FileSaver.saveAs(blob, fileName);
                    setLoading(false);
                });
        
        } else {
            console.log('No sample file name, re-trying.');
            getSampleLink();
        }
    
      }

      const setDefaultProductImportDialog =  input => async e => {        
        
      }

      const openProductImportDialog=(e)=>{
        e.preventDefault();
        setOpenProductImport(true);
      }
      const closeImprtProductDialog=()=>{ 
        setOpenProductImport(false);
      }

    return (
        <div className="main-content">
            
             {isLoading&&<Spinner/>}
            <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore}/>
            
            <div className="top-import">
                    <div className="clip-div"></div>
                <Row>
                    <Col lg="3"></Col>
                    <Col lg="6">
                        <h1 className="text-center">Import Products</h1>
                        <h4 className="text-center">Import your products in bulk, Add your preferred product details with your family, categories,
locations and other parameters.</h4>
                    </Col>
                    <Col lg="3"></Col>
                </Row>
            </div>
        <Container className="imp-card">
            <Row className='d-flex justify-content-center'>
            {/* <Col lg="4">
            <Card className="card-lift--hover border-0 imprt">
                <CardBody className="py-5 card-body">
                    <div class="icon icon-shape bg-gradient-info text-white rounded-circle mb-4">
                        <i class="far fa-file-video"></i>
                        </div>
                        <h4 class="h3 text-info text-uppercase">Watch Video To Import Products</h4>
                        <p class="description mt-3">Get into video walk through to ease your preparation of product sheet with various product parameters. It takes few minutes to finish your bulk upload.</p>
                        <div className="text-center">
                            <a href="#"><span class="badge badge-info badge-pill">View</span></a>
                        </div>
                </CardBody >
            </Card>
            </Col> */}

            <Col lg="4">
            <Card className="card-lift--hover shadow border-0 imprt">
                <CardBody className="py-4 card-body">
                    <div class="icon icon-shape bg-gradient-info text-white rounded-circle mb-4">
                        <i class="fas fa-download"></i>
                        </div>
                        <h4 class="h3 text-info text-uppercase">Download Sample Spreadsheet</h4>
                        <p class="description mt-3">Check our Sample Product Import sheet and images, learn how to update product details and other properties. Use this sample spread sheet for your original product data.</p>
                        <div id="downloadNotify" className="text-center mt2-5" >
                        <ProgressBar
        percent={downloadProcesTime}
        filledBackground="linear-gradient(to right, #fefb72, #f0bb31)"
      />
      <h6  style={{ textAlign:"center"}}>{downloadingText}</h6>
      </div>
                    <div style={{ textAlign:"center"}}>
                        <div className="text-center"  id="downloadmDIv">
                            <a href="#" id="xlsm" onClick={() => downloadSampleLink('xlsm')} ><span class="badge badge-info badge-pill">.xlsm Sheet</span></a>
                        </div>
                        &nbsp;
                        <div className="text-center" id="downloadsxDIv">
                            <a href="#" id="xlsx" onClick={() => downloadSampleLink('xlsx')} ><span class="badge badge-info badge-pill">.xlsx Sheet</span></a>
                        </div>
                    </div>    
                </CardBody >
            </Card>
            </Col>

            <Col lg="4">
            <Card className="card-lift--hover shadow border-0 imprt">
                <CardBody className="py-4 card-body">
                    <div class="icon icon-shape bg-gradient-info text-white rounded-circle mb-4">
                        <i class="fas fa-cloud-upload-alt"></i>
                        </div>
                        <h4 class="h3 text-info text-uppercase">Start Your Import  </h4>
                        <p class="description mt-3"> Upload your product file in Xls format.On completion of import,Products will be in inactive status. After importing, edit the product from the product screens and add images to make them active.</p>
                        <div className="text-center">
                                {/* <Col lg="3"> */}
                                <a className="mt-5" href="#" onClick={e=>openProductImportDialog(e)} style={{color:"#30a2e4"}}>
                                <span class="badge badge-info badge-pill">Start Your Upload</span>
                                </a>
                                <ImportProductDialog closeImprtProductDialog={closeImprtProductDialog} Open={OpenProductImport} setDefaultProductImportDialog={setDefaultProductImportDialog}/>
                            {/* </Col> */}

                        </div>
                </CardBody >
            </Card>
            </Col>
            </Row>
            </Container>
        </div>
    );
};

export default Import;