import React,{useState,useEffect} from "react";

import { Card, CardBody,Row, Col,Badge } from "reactstrap";
import { FormAutocomplete } from "_components/FormElements/FormInput";
import { ListGroupItem, ListGroup } from 'reactstrap';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useForm} from "react-hook-form";
import ProductFilter from "./ProductFilter";
import GuestNavbar from "components/Navbars3/GuestNavbar";
import Title from "views/Hoc/Dialog/Title";
import { commonService } from '_services/common.service';
import {Spinner, ModalSpinner} from 'views/Hoc/Spinner';
import TablePagination from '@material-ui/core/TablePagination';
import {useInfiniteScroll} from '_hooks';
import axios from 'axios';
export const ProductList = props => {  
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));
  const { control } = useForm({ mode:'onBlur' });
  const classes = useStyles();
  const unqual={height: '450px'};

  const [filterValueState, setFilterValue] = useState({location:[], brand:[], categories:[]});
  const [compData, setCompData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchMoreItems = async() => {
    
    await getproductlist();
    setIsFetching(false);
  
  }

  const [isFetching, setIsFetching] = useInfiniteScroll(fetchMoreItems);

  let [page, setPage] = useState(0);
  let [rowsPerPage, setRowsPerPage] = useState(12);
  let [total, setTotal] = useState(0);

  let viewId =  props.location.state ? props.location.state.viewId : 1;

  let title = viewId == '1' ? 'Buyers' : viewId == '2' ? 'Vendor' : viewId == '3' ? 'Distributor' : viewId == '4' ? 'Retailer' : 'Buyers';
  
  useEffect(() => {
    setCompData([]);
    setIsFetching(false);
    setLoading(true);
    getproductlist();
  }, [filterValueState]);

  

  const getproductlist = async() => {
    // setLoading(true);
    let postData={
      filter:getFilterData(),
      // order:sort?sort:'asc',
      // search:search?search.name:null,
      offset:compData.length, //page * rowsPerPage,
      // // offset:1,
      limit:rowsPerPage,
    }

    let res = await commonService.postService(postData, 'guest/my-products/').catch(err => setLoading(false) );
    if(res){
      console.log('aggre', res.data);
      setCompData(prevState => ([...prevState, ...res.data.result]));
      // setCompData(res.data.result);
      setTotal(res.data.total);
      setLoading(false);
    }

  }

  const getFilterData=()=>{
    let filter ={
      brandId:null,
      // countryId:null,
      productCategoryId:null,
      family: null,
      hot_topic: null
    };
    // if(filterValueState.location && filterValueState.location.length>0){
    //   filter.countryId=filterValueState.location.map(x=> parseInt(x.id));
    // }
    if(filterValueState.family && filterValueState.family.length>0){
      filter.family=filterValueState.family.map(x=> parseInt(x.id));
    }
    if(filterValueState.brand && filterValueState.brand.length>0){
      filter.brandId=filterValueState.brand.map(x=> parseInt(x.id));
    }
    if(filterValueState.categories && filterValueState.categories.length>0){
      filter.productCategoryId=filterValueState.categories.map(x=> parseInt(x.id));
    }
    if(filterValueState.hotValue && filterValueState.hotValue.length>0){
      filter.hot_topic=filterValueState.hotValue.map(x=>parseInt(x.id));
    }
    console.log('filteredstate', filterValueState);
    return filter;
  }


  const handleChangePage = (event, newPage) => {
    page=newPage;
    setPage(page);
    getproductlist();
  };

  const handleChangeRowsPerPage = (event) => {
    rowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(rowsPerPage);
    getproductlist();
  };

  const handleChangeSelect = (input, value)=>{
    console.log('input, value', input, value);
    
    if(input==='location'){
      filterValueState.location= value;
    }
    if(input=='family'){
      filterValueState.family = value;
      filterValueState.categories = [];
      filterValueState.brand = [];
    }
    if(input==='brand'){
      filterValueState.brand= value;
    }
    if(input==='categories'){
      filterValueState.categories= value;
      filterValueState.brand = [];
    }
    setFilterValue({ ...filterValueState })
    console.log('filterValueState', filterValueState);
  }

  const resetFilterValue =()=>{
    filterValueState.location=[];
    filterValueState.brand=[];
    filterValueState.categories=[];

    setFilterValue({ ...filterValueState });
  }


  return (<>
    <GuestNavbar/>
    <div className="main-content">
      
   
      <Title title="Products"desc={['desc']} />
              <Card className="mt-4">
              <CardBody>
              <h4 className="text-muted mb-0">FILTERS</h4>
                <Row className="pt-2">
                  {/* <Col id="leftFilter" lg="3" className="p-0 ml--4">
               <ProductFilter handleChangeSelect={handleChangeSelect} filterValueState={filterValueState} resetFilterValue={resetFilterValue} />
                  </Col>  */}
    
                  <Col style={{ backgroundColor: "#f8f9fe" }} lg="12">
                  <div id="product">
                  <div className="mainContainer">
    

  {
  loading ? <Spinner /> : compData.map((x,i) => <div className="column mt-4"><div className='cards' style={(i%2==0) ? unqual : {}} key={x.product}>
    <div className='set-image'>
      <div className='brnd-log'>
      <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  src={x.brandImg ? process.env.REACT_APP_CDN_URL+x.brandImg : process.env.REACT_APP_CDN_URL+'document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'} />
      </div>
      <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  src={x.documentUrl ? x.documentUrl.includes('http') ? x.documentUrl : process.env.REACT_APP_CDN_URL+x.documentUrl : process.env.REACT_APP_CDN_URL + 'document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'} />
    </div>
    <div className='set-headline'>
    <h3>
      {x.product}
      </h3>
      <Row >
         <Col lg="6" className=" pl-0">
        <h6 className="text-dark" style={{fontSize:"1rem"}}>{x.businesscurrencyid == '1' ? '€' : '$' } {x.retailerprice} </h6>
        </Col> 
        <Col lg="6" className="pl-0">
        <Badge className= "badge-1">{x.brandName} </Badge>
        </Col>
        </Row>
    </div>
    </div>
    </div>
  )}
  {/* <div className='cards'>
    <div className='set-image'> 
      <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  src="https://thegadgetflow.com/wp-content/uploads/2021/03/Insta360-GO-2-miniature-action-camera-01-768x432.jpg"/>
    </div>
    <div className='set-headline'>
    <h3>
      Insta360 GO 2 Miniature Action Camera
      </h3>
    </div>
    
  </div>
  <div className='cards' style={unqual}>
    <div className='set-image'> 
      <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  src="https://thegadgetflow.com/wp-content/uploads/2021/03/Fitbit-Ace-3-kids-activity-tracker-01-768x432.jpeg"/>
    </div>
    <div className='set-headline'>
    <h3>
      Insta360 GO 2 Miniature Action Camera
      </h3>
    </div>
    
  </div>
  <div className='cards'>
    <div className='set-image'> 
      <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  src="https://thegadgetflow.com/wp-content/uploads/2021/03/S-O-E-Roadster-Historical-Watch-01-768x432.jpg"/>
    </div>
    <div className='set-headline'>
    <h3>
      Insta360 GO 2 Miniature Action Camera
      </h3>
    </div>
    
  </div>
</div>
<div className="column mt-4">
  <div className='cards'>
    <div className='set-image'> 
      <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  src="https://thegadgetflow.com/wp-content/uploads/2021/03/Eve-Weather-climate-monitoring-device-01-768x432.jpg"/>
    </div>
    <div className='set-headline'>
    <h3>
      Insta360 GO 2 Miniature Action Camera
      </h3>
    </div>
    
  </div>
  <div className='cards'>
    <div className='set-image'> 
      <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  src="https://thegadgetflow.com/wp-content/uploads/2021/03/Insta360-GO-2-miniature-action-camera-01-768x432.jpg"/>
    </div>
    <div className='set-headline'>
    <h3>
      Insta360 GO 2 Miniature Action Camera
      </h3>
    </div>
    
  </div>
  <div className='cards'>
    <div className='set-image'> 
      <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  src="https://thegadgetflow.com/wp-content/uploads/2021/03/Insta360-GO-2-miniature-action-camera-01-768x432.jpg"/>
    </div>
    <div className='set-headline'>
      <h3>
      Insta360 GO 2 Miniature Action Camera
      </h3>
    </div>
    
  </div>
  <div className='cards'>
    <div className='set-image'> 
      <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  src="https://thegadgetflow.com/wp-content/uploads/2021/03/Insta360-GO-2-miniature-action-camera-01-768x432.jpg"/>
    </div>
    <div className='set-headline'>
      <h3>
      Insta360 GO 2 Miniature Action Camera
      </h3>
    </div>
    
  </div>
</div>
<div className="column mt-4">
  <div className='cards' style={unqual}>
    <div className='set-image'> 
      <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  src="https://thegadgetflow.com/wp-content/uploads/2021/03/Insta360-GO-2-miniature-action-camera-01-768x432.jpg"/>
    </div>
    <div className='set-headline'>
      <h3>
      Insta360 GO 2 Miniature Action Camera
      </h3>
    </div>
    
  </div>
  <div className='cards'>
    <div className='set-image'> 
      <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  src="https://thegadgetflow.com/wp-content/uploads/2021/03/Insta360-GO-2-miniature-action-camera-01-768x432.jpg"/>
    </div>
    <div className='set-headline'>
      <h3>
      Insta360 GO 2 Miniature Action Camera
      </h3>
    </div>
    
  </div>
  <div className='cards' style={unqual}>
    <div className='set-image'> 
      <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  src="https://thegadgetflow.com/wp-content/uploads/2021/03/Insta360-GO-2-miniature-action-camera-01-768x432.jpg"/>
    </div>
    <div className='set-headline'>
      <h3>
      Insta360 GO 2 Miniature Action Camera
      </h3>
    </div>
    
  </div>
  <div className='cards'>
    <div className='set-image'> 
      <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  src="https://thegadgetflow.com/wp-content/uploads/2021/03/Insta360-GO-2-miniature-action-camera-01-768x432.jpg"/>
    </div>
    <div className='set-headline'>
      <h3>
      Insta360 GO 2 Miniature Action Camera
      </h3>
    </div>
    
  </div>
</div><div className="column mt-4">
  <div className='cards'>
    <div className='set-image'> 
      <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  src="https://thegadgetflow.com/wp-content/uploads/2021/03/Insta360-GO-2-miniature-action-camera-01-768x432.jpg"/>
    </div>
    <div className='set-headline'>
      <h3>
      Insta360 GO 2 Miniature Action Camera
      </h3>
    </div>
    
  </div>
  <div className='cards'>
    <div className='set-image'> 
      <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  src="https://thegadgetflow.com/wp-content/uploads/2021/03/Insta360-GO-2-miniature-action-camera-01-768x432.jpg"/>
    </div>
    <div className='set-headline'>
      <h3>
      Insta360 GO 2 Miniature Action Camera
      </h3>
    </div>
    
  </div>
  <div className='cards'>
    <div className='set-image'> 
      <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  src="https://thegadgetflow.com/wp-content/uploads/2021/03/Insta360-GO-2-miniature-action-camera-01-768x432.jpg"/>
    </div>
    <div className='set-headline'>
      <h3>
      Insta360 GO 2 Miniature Action Camera
      </h3>
    </div>
    
  </div>
  <div className='cards'>
    <div className='set-image'> 
      <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  src="https://thegadgetflow.com/wp-content/uploads/2021/03/Insta360-GO-2-miniature-action-camera-01-768x432.jpg"/>
    </div>
    <div className='set-headline'>
      <h3>
      Insta360 GO 2 Miniature Action Camera
      </h3>
    </div>
    
  </div> */}
{/* </div> */}

</div>      </div>
{total > compData.length ? (isFetching && <ModalSpinner />) : <>Nothing to Show...</>}
{/* {total>10 &&
                  <TablePagination
                        component="div"
                        count={total}
                        page={page}
                        onChangePage={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        rowsPerPageOptions={[10, 20, 30, 40]}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                      />
                      } */}
                  </Col>
                </Row>
              </CardBody>
            </Card>
            </div>
              </>
  );
}

const AnnuvalTurnOver = [
  { id: '<1M€', name: '<1M€' },
  { id: '1-10M€', name: '1-10M€' },
  { id: '10-100M€', name: '10-100M€' },
  { id: '>100M€', name: '>100M€' },
]


export default ProductList;