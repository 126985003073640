import React ,{useEffect,useState} from 'react';
import { Modal,Card,CardBody,CardHeader,Form, Container,Col,Row,Table,Button } from "reactstrap";
import axios from 'axios';
import { commonService } from '_services/common.service';
import { Spinner} from 'views/Hoc/Spinner';
import { ListItem } from "_components/Listing/ListItem";
export  const EventMeetingView=(props)=> {
  const { url, videoModalClose, isOpen } = props;
  const [open, Setopen] = useState(true);
  const [loading, setLoading] = useState(false);
  let [state, setState] = useState(null);
  let [locations, setLocations] = useState([]);
  let [productCat, setProductCat] = useState([]);
  let [intproductCat, setIntProductCat] = useState([]);
  const  meetingdata  = props.userData.meetings;
  const  channelTypeId  = props.userData.typeId;
 
 // console.log('meetingdata', meetingdata);
  useEffect(() => {
    getContactData();
  }, []);
  const getContactData= async()=>{
   // setLoading(true);
    
  }
const closeOpen=(e)=>{
  e.preventDefault();
  props.closeEventMeetingView();
  Setopen(false);
}
  return (
    <div >
       
      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open responsive dialog
      </Button> */}
      <Modal
          className="modal-dialog-centered"
          isOpen={open}
         size="sm"
         style={{maxWidth:'420px'}}
        >
           <div className="" id="profileview">
           <div class='ribbon-wrapper-5'>
               
                    </div>
                    </div>
          <div className="modal-header pb-0">
          
          
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
           onClick={closeOpen}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body  d-flex justify-content-center pt-0 pb-0">
          {loading? <Spinner />:
          <Card>
                
                {meetingdata.map(x => (
                  <CardBody>
                  <div>
                  <img alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                      src={x.documentPath!=null ? process.env.REACT_APP_CDN_URL + x.documentPath : process.env.REACT_APP_CDN_URL + 'document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'}
                      style={{ width: "140px" }}
                      />
                      <div className="pt-4 text-center">
                      <h5 className="h3 title">
                      <span className="d-block mb-1">{x.name}</span>
                      </h5>
                      
                        {  (channelTypeId == 2)?
                        <span> 
                        <i id="ratedvalue" class={`fa ${x.vendorRating>0 ? "fa-star starrated" : "fa-star ra"}`} ></i>
                        <i id="ratedvalue" class={`fa ${x.vendorRating>1 ? "fa-star starrated" : "fa-star ra"}`} ></i>
                        <i id="ratedvalue" class={`fa ${x.vendorRating>2 ? "fa-star starrated" : "fa-star ra"}`} ></i>
                        <i id="ratedvalue" class={`fa ${x.vendorRating>3 ? "fa-star starrated" : "fa-star ra"}`} ></i>
                        </span>
                        :
                        <span> 
                        <i id="ratedvalue" class={`fa ${x.rating>0 ? "fa-star starrated" : "fa-star ra"}`} ></i>
                        <i id="ratedvalue" class={`fa ${x.rating>1 ? "fa-star starrated" : "fa-star ra"}`} ></i>
                        <i id="ratedvalue" class={`fa ${x.rating>2 ? "fa-star starrated" : "fa-star ra"}`} ></i>
                        <i id="ratedvalue" class={`fa ${x.rating>3 ? "fa-star starrated" : "fa-star ra"}`} ></i>
                        </span>
                      }
                  
                     </div></div>
                     </CardBody>
                ))}
                
                
              </Card>
          }
          </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={closeOpen}
            >
              Close
            </Button>
           
          </div>
        </Modal>
    </div>
  );
}
export default  EventMeetingView;