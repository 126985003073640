import React, {useState, useEffect} from "react";
import { NavItem,Card,  Nav, Container, Row, Col,CardBody ,Input} from "reactstrap";
import {FormInput} from "_components/FormElements/FormInput";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { useForm} from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { guestLoginSchema } from '../../pages/SignupPage/Validation';
import { history } from '_helpers/history';
import axios from "axios";

export const GuestContact = props => {  
  //const {bg}=this.props;
  const [email, setEmail] = useState('');
  
  const { register, handleSubmit, errors, watch } = useForm({
    mode:'onBlur',
    resolver: yupResolver(guestLoginSchema),
  });

  const watchEmail = watch('email');
  const [checkMailId, setCheckMail] = useState(0);

  useEffect(()=>{
    setCheckMail(0);
  }, [watchEmail])

  const checkMail = () => {
    const apiUrl = 'account/checkmail/'+watchEmail;
      console.log('apiUrl',apiUrl);
      axios.get(apiUrl).then(res => {
        console.log('res', res.data)
        if(res.data.isavialble == true){
          history.push({pathname:'/signup', state:{email: email}});
        }
        else{
          console.log('error already taken mail')
          setCheckMail(1)
        }
      })
  }

  const login=()=>{
    history.push({pathname: '/login', state: {email:email}});
  }

  return (
<>
<Card className={'Contact mb-0 mt-0 ' + props.bg } style={{backgroundColor:"f7f7f7"}}>
  <CardBody className="cardPad" style={{backgroundColor:"#f7f7f7"}}>
<Row>
  <Col lg="6" md="12" sm="12">

{/* <p className="text-left mt-4">
- Get suggestion from our matching algorithm
</p>
<p className="text-left mt-4">
- access full product details and vendors profile
</p>
<p className="text-left mt-4">
- Order samples in just a few clicks
</p>
<p className="text-left mt-4">
- Exclusive invitation to our events
</p> */}

<ul class="content-block__list list-group">
  <li class="content-block__list-item list-group-item1">
<h2 className="contactTitle1 text-left mb-2 mt-3  dark-text">Create your account and get the following benefits: 
</h2></li>


  { props?.type==='vendor'?
    vendorBenefits.map(x=>(
      <li class="content-block__list-item list-group-item1">
      <h4 class="content-block__subheading dark-text"><i class="fas fa-angle-double-right mr-2"></i>
     {x} </h4>
    </li>
    )):
    buyerBenefits.map(x=>(
      <li class="content-block__list-item list-group-item1">
      <h4 class="content-block__subheading dark-text"><i class="fas fa-angle-double-right mr-2"></i>
        {x}
      </h4>
    </li>
    ))
  
  }

      </ul>



  </Col>
<Col  lg="6" md="12" sm="12" className="borderfull">
  <form>
    <div className="conttop">
<h3 className="mt-2">Don't have a ChannelHub account?
</h3>
<FormInput 
name="email" 
placeholder="Enter your e-mail here" 
type="email" 
onChange={e => setEmail(e.target.value)}
register={register}
value={watchEmail}
error={errors.email ? errors.email : checkMailId ? {message:"Email Id already taken", required: true} : ''}
defaultValue={email}
 />
<div className="mt-4">
{/* <Link to="/Login" className="nav-item nav-link Btnborder">Sign In</Link> */}
<div className="text-center">
<button className="btn btn-primary btn-md ml-2 fet " onClick={handleSubmit(checkMail)}> Create an Account</button>  
</div>
<h3 className="text-center text-muted mt-2">or</h3>
<div className="text-center">
<button className="btn btn-md ml-2 fet Btnborder" onClick={login}> Sign In</button>  
</div>
</div>
    </div>
  
</form>
  </Col>
</Row>
</CardBody>
</Card>
</>
  );
}
const buyerBenefits = [
  " Contact the right interlocutor in charge of your region",
  " Get suggestion from our matching algorithm",
  " Access full product details and vendors profile",
  " Order samples in just a few clicks",
  " Exclusive invitation to our events"
  ]
const vendorBenefits = [
    " Your profile visible without any time limit",
    " Your product catalogue visible without any time limit",
    " Access to hundred of qualified buyers from around the World",
    " Get qualified leads",
    " Integrated connection with our events. Never lose any contact details or company profile of people you met at our events."
]
export default GuestContact;