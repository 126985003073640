import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import { Button, Modal } from "reactstrap";
import { Radio } from '@material-ui/core';
import MultiUpload from '../MultiUpload';
import { commonService } from '_services/common.service';
import { Spinner } from 'views/Hoc/Spinner';
import ModalImageCropper from '../ModalImageCropper';
import { UncontrolledTooltip } from "reactstrap";
import ImagePreview from 'views/Hoc/Dialog/ImagePreview';
import axios from 'axios';
import { useEffect } from 'react';
export const ImageLibrary = (props) => {
  const { getDocumentId, img, aws_folderName, id } = props;
  let [loading, setLoading] = useState(false);
  let [selectData, setSelectData] = useState(null);
  let [selectIndex, setSelectIndex] = useState(null);
  let [list, setList] = useState([]);
  const [open, setOpen] = useState(false);
  let [isImg, setImgOpen] = useState(false);
  let [imgUrl, setImgUrl] = useState(null);
  const [activeTab, setActiveTab] = useState('1');


  useEffect(() => {
    if (id == 3) {
      getCompanyMediaLibrary('/setting/getCompanyMediaLibrary/');
      setOpen(true);
    }
  }, [])

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  }

  const handleClickOpen = () => {
    if (id === 1) {
      getCompanyMediaLibrary('/setting/getCompanyMediaLibrary/');
    }
    else if (id === 2) {
      getCompanyMediaLibrary('/setting/getCompanyMediaLibrary/');
    }
    setOpen(true);
  };

  const handleClose = () => {
    setActiveTab('1');
    setOpen(false);
  };
  const handleUpload = () => {
    setOpen(false);
    getDocumentId(selectData);
  }
  const GreenCheckbox = withStyles({
    root: {
      color: green[400],
      '&$checked': {
        color: green[600],
      },
    },
    checked: {},
  })((props) => <Radio color="default" {...props} />);

  //localStorage
  let userData = JSON.parse(localStorage.getItem('CHuser'));

  const getCompanyMediaLibrary = async (url) => {
    loading = true;
    setLoading(loading);
    const MediaLibrary = await commonService.getServices(url + userData?.channelId);
    console.log("MediaLibrary", MediaLibrary.data);
    list = MediaLibrary.data;
    setList(list);
    loading = false;
    setLoading(loading);
    handleChange(null, null);
  }

  const handleChange = (index, data) => {
    selectIndex = index;
    setSelectIndex(selectIndex);
    selectData = data;
    setSelectData(selectData);
  };

  const [image, setImage] = useState(null);

  const handleOnDrop = async (data) => {
    if (data.length > 0) {
      console.log('datat', data);
      let file = data[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        console.log('file', file)
        console.log('reader.result', reader.result)
        setImage(
          reader.result
        );
        console.log('image', image)
        //setOpen(false);          
      };

    }
  }

  function dataURLtoFile(dataurl, filename) {

    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  const handleCropClose = () => {
    setImage('');
  }

  const selectImg = async (data) => {
    if (data.length > 0) {
      loading = true;

      setLoading(loading);
      setImage('');
      //converting base64 string to file format.
      var random = Math.random();
      var fileName = random + '.png';
      var file = dataURLtoFile(data, fileName);
      const formData = new FormData();
      formData.append('file', file);

      const resImagePath = await commonService.fileUploadService(formData, '/mediaupload/upload/' + aws_folderName);
      if (resImagePath) {
        const uploadMediaLibrary = await commonService.postService({
          Key: resImagePath.data.url,
          user: {
            channelId: userData?.channelId
          }
        }, '/setting/createCompanyLogo');
        list.unshift(uploadMediaLibrary.data);
        setList(list);
        handleChange(0, list[0]);
        toggle('2');
        loading = false;
        setLoading(loading);
      } else {
        loading = false;
        setLoading(loading);
      }

    }
    // setOpen(false);
  }
  const deleteLogo = async (data) => {
    loading = true;
    setLoading(loading);
    const uploadMediaLibrary = await commonService.postService({
      channelDocumentId: data.channelDocumentId,
      user: {
        channelId: userData?.channelId,
        contactId: userData?.contactId
      }
    }, '/channelAdmin/deleteCompanyLogo');
    getCompanyMediaLibrary('/setting/getCompanyMediaLibrary/');
  }
  const imageModalClose = () => {
    isImg = false;
    setImgOpen(isImg);
  };
  const previewImg = (data) => {
    imgUrl = data.document.documentUrl;
    setImgUrl(imgUrl);
    isImg = true;
    setImgOpen(isImg);
  }
  return (
    <div>
      <div onClick={handleClickOpen} className="brandCard">
        <div className="imgContiner">
          <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", props.userLogo ? "http://d2maj8ifjo1lti.cloudfront.net/document/default_image/contact.png" : "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} className="Brandimg imgCont" src={img} alt="img" />
        </div>
      </div>
      <Modal scrollable={true}
        className="modal-dialog-centered"
        isOpen={open} size="lg">
        <div className="modal-header">
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '1' })}
                onClick={() => { toggle('1'); }}
              >
                Upload File
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '2' })}
                onClick={() => { toggle('2'); }}
              >
                Media library
              </NavLink>
            </NavItem>

          </Nav>

          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={handleClose}
          >
            <span aria-hidden={true}>×</span>
          </button>

        </div>
        <div className="modal-body p-0">

          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              {loading ? <Spinner /> : <Row>
                <Col sm="12">
                  <MultiUpload acceptedFiles={['image/jpeg', 'image/png', 'image/gif']} handleChange={handleOnDrop} size={props.size} />

                </Col>
              </Row>}
            </TabPane>
            <TabPane tabId="2">
              {list.length <= 0 && <div className="no-records">
                {/* <span>No Records</span> */}
                <div className="no-img text-center">
                  <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} className="img-fluid" alt="ch-logo" src={require("../../../assets/img/medialibrary/no-data.png")} /></div>
              </div>}
              {loading ? <Spinner /> : <>
                <div className="container">
                  <div className="dropdown-divider m-0"></div>
                </div>
                <Row>
                  {list.map((x, i) => {
                    return (
                      <Col sm="2" lg="2" className="mr-3">
                        <div className="brandCard" onClick={() => handleChange(i, x)}>
                          <GreenCheckbox style={{ width: '50px', position: 'absolute', top: '-12px', right: '-15px' }} checked={selectIndex === i} name="checked" />
                          <div className="imgContiner">

                            <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} className="lib-img" type="img/img" src={process.env.REACT_APP_CDN_URL+x.document.documentPath} />
                          </div>
                          <div className="row medd-view">
                            <div className="col-6 text-right">
                              <div id='eye' className="editeye text-center" onClick={() => previewImg(x)}>
                                <i class="fa fa-eye"></i>
                              </div>
                              <UncontrolledTooltip content='preview video' target='eye' placement='bottom'>
                                preview
                              </UncontrolledTooltip></div>
                            <div className="col-6 text-left">
                              <div id='delete' className="deleteicon text-center" onClick={() => deleteLogo(x)}>
                                <i class="fas fa-trash-alt"></i>
                              </div>
                              <UncontrolledTooltip content='Delete video' target='delete' placement='bottom'>
                                delete
                              </UncontrolledTooltip></div>
                          </div>
                        </div>
                      </Col>
                    )
                  })}
                </Row></>}
            </TabPane>
          </TabContent>

        </div>
        <div className="modal-footer">
          {activeTab === '2' && <>
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={handleClose}
            >
              Close
            </Button>
            <Button color="primary" type="button" onClick={() => handleUpload(selectData)} disabled={selectIndex === null}>
              Upload
            </Button></>}
        </div>
        <ImagePreview imgModalClose={imageModalClose} isOpen={isImg} url={imgUrl} />
      </Modal>
      {image ? <ModalImageCropper isOpen={true} src={image} handleChange={selectImg} handleCropClose={handleCropClose} /> : <></>}
    </div>

  );
}
export default ImageLibrary;
