import React from "react"
import ReactCountryFlag from "react-country-flag"
 
const CountryFlag= (props)=> {
    return (
        <div>
            <ReactCountryFlag
                countryCode={props.code}
                svg
              className="countryFlag"
                title={props.code}
            />
        </div>
    )
}
 
export default CountryFlag