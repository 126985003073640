import { dashboardConstants } from '../_constants';

// let user = JSON.parse(localStorage.getItem('user'));
// const initialState = user ? { loggedIn: true, user } : {};

export function dashboard(state = {}, action) {
  switch (action.type) {
    case dashboardConstants.COUNT_REQUEST:
      return {
        loading: true        
      };
    case dashboardConstants.COUNT_SUCCESS:
    {
      //console.log("cdash:",action);
      const dasboard_count = action.dasboard_count;      
      return {     
          ...state,           
          dasboard_count:dasboard_count
      };
    }
    case dashboardConstants.COUNT_FAILURE:
      return {};
   
    default:
      return state
  }
}