import React, { useEffect, useState } from "react";
import { Card, Row, Col, CardBody, Container } from "reactstrap";
import ScrollAnimation from 'react-animate-on-scroll';
import { Link } from "react-router-dom";
import axios from "axios";
import VisibilitySensor from "react-visibility-sensor";

export const GuestVideo = props => {
  const { title, sort, btype } = props;

  let [compData, setCompData] = useState([]);
  const [slide, setSlide] = useState(0);
  const [play, setPlay] = useState(false);
  const{homePage} = props;
  const [mute, setMute] = useState(true);

  const unmute = () => {
    console.log('muted', mute)
    setMute(prev => !prev);
  }

  useEffect(() => {
    setSlide(0);
    compData = [];
    setCompData(compData)
    //getFeaturedVendors();
  }, [btype]);

  const getFeaturedVendors = async () => {

    let data = {
      limit: 8,
      offset: compData.length,
      btype: btype
    }

    await axios.post('guest/featuredVendors/', { data }).then(res => {

      setCompData(prevState => ([...prevState, ...res.data.result]));

    })
  }

  const prevSlide = () => {
    if (slide != 0)
      setSlide(prev => prev - 1);

    setPlay(false);
  }

  const nextSlide = () => {

    if (slide < compData.length - 1)
      setSlide(prev => prev + 1);

    if (slide == compData.length - 2)
      //getFeaturedVendors();

    setPlay(false);
  }

  const channelDetailView = (id) => {
    const win = window.open('channelDetailView/' + id);
    // history.push('productDetailView/'+x.productSKU)
    win.focus();
  }



  const playVideo = () => {
    var myVideo = document.getElementById('myVideo');

    if (myVideo) {
      if (play)
        myVideo.play();
      else
        myVideo.pause();
    }

  }

  useEffect(() => {
    playVideo();
  }, [play])


  return (
    <Container fluid className="mb-3 bgnone" style={{display:homePage === true ? "none" : "block"}}>
      {!!compData.length > 0 &&
        <Card className="VideoSection mb-0">
          <CardBody className="p-0">


            <VisibilitySensor onChange={(isVisible) => {
              setPlay(isVisible);
            }}>
              <>

                <div className="p-3 mt-1 ml-3">
                  <h1 className="ProductTitle d-inline-block mr-3">Featured Vendors</h1>
                </div>
                <div className='left-arrow' onClick={prevSlide}>
                  <i class="fas fa-angle-left"></i>
                </div>
                <div className='right-arrow' onClick={nextSlide}>
                  <i class="fas fa-angle-right"></i>
                </div>


                <div className="playhead">
                  <h1 className="mt-3 titleVid pointer"
                    onClick={() => channelDetailView(compData[slide].channelId)}
                  >{compData[slide].companyName}</h1>
                  <h3 className="mt-4 h3class">{compData[slide].detailDesc?.length > 200 ? compData[slide].detailDesc.replace(/<[^>]+>/g, '').slice(0, 200) + '...' : compData[slide].detailDesc.replace(/<[^>]+>/g, '')}
                  </h3> </div>


                <div className="VideoSec">
                  <video id="myVideo" type="video/mp4" src={compData[slide].documentUrl ? process.env.REACT_APP_CDN_URL + compData[slide].documentUrl : "https://file-examples-com.github.io/uploads/2017/04/file_example_MP4_480_1_5MG.mp4"} autoPlay={play} muted={mute} onClick={() => setPlay(prev => !prev)} />
                  {!play && <div className="playbtn">
                    <img style={{ zIndex: 100 }} onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} src={require('assets/img/guestpage/playbtn.png')} alt="Video play button" width="94" height="94" loading="lazy" onClick={() => { setPlay(true); }} />
                  </div>}

                  <button id="unmuteButton" onClick={unmute}><i class={mute ? 'fas fa-volume-mute mte' : 'fas fa-volume-up unmte'}></i></button>
                </div></>
            </VisibilitySensor>

            {/* {"https://file-examples-com.github.io/uploads/2017/04/file_example_MP4_480_1_5MG.mp4"} */}

          </CardBody>
          <div className="text-center">
            <Link to="/channel">
              <button className="btn btn-primary btn-md ml-2 driggle"> More Vendors</button>  </Link>
          </div>
        </Card>
      }
      {/* <Card className="HotTopic mb-0">
<div className="p-3 mt-3 ml-3">
  <h1 className="ProductTitle d-inline-block mr-3">Hot Topics</h1>
  </div>
<Row>
  <Col lg="6" md="12" sm="12">
  <div className="leftImg">
    <div  className="HotBrand">
    <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  src="https://images.unsplash.com/photo-1523275335684-37898b6baf30?ixid=MXwxMjA3fDB8MHxzZWFyY2h8M3x8cHJvZHVjdHxlbnwwfHwwfA%3D%3D&ixlib=rb-1.2.1&w=1000&q=80"/></div>
    <h2 className="HotTopic-text">
    Sustainable products are those products that provide environmental.
    </h2>
  </div>
  </Col>
  <Col lg="6" md="12" sm="12">
  <div className="rightTopImg">
  <div  className="HotBrand">
    <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  src="https://images.unsplash.com/photo-1523275335684-37898b6baf30?ixid=MXwxMjA3fDB8MHxzZWFyY2h8M3x8cHJvZHVjdHxlbnwwfHwwfA%3D%3D&ixlib=rb-1.2.1&w=1000&q=80"/></div>
    <h2 className="HotTopic-text">
    Sustainable products.
    </h2>
  </div>
  <div className="rightBottomImg">
  <div  className="HotBrand ">
    <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  src="https://images.unsplash.com/photo-1523275335684-37898b6baf30?ixid=MXwxMjA3fDB8MHxzZWFyY2h8M3x8cHJvZHVjdHxlbnwwfHwwfA%3D%3D&ixlib=rb-1.2.1&w=1000&q=80"/></div>
    <h2 className="HotTopic-text">
    Sustainable products are
    </h2>
  </div>
  </Col>
</Row>
</Card> */}
    </Container>
  );
}
export default GuestVideo;