import React from "react";
import { Col, Container, Row, Card,CardBody,Button,UncontrolledTooltip } from "reactstrap";
import logo from 'assets/img/brand/ch-logo.svg'
import { AskmeetingPopup } from "./AskmeetingPopup";
import axios from 'axios';

const Events = () => {
  return (
      <>
      <section className="eventtop">
      <Card className=" col-8 mt-12 container-fluid numcard">
          <CardBody >
          <div className="head-logo">
            <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  src={logo}/>
            </div>
           
        

          <div className="text-center"><h3>Retail Connect will happen right here from your ChannelHub account. All your one-to-one meetings,</h3></div>
          <div className="text-center"><h3>Pitch-our-Product session, GfK keynote and workshops will be available from a single sign-in.</h3></div>
          <div className="text-center"><h3 >The button below will bring you to the event management page</h3></div>
          
          <div id='btttn' className="text-center mt-3">    
              
               <Button
              color="warning"
              
            //   data-dismiss="modal"
              type="button"
            //   onClick={handleClose}
            size="lg"
            >
             Event module will be opened on Feb 15th.
            </Button>
            <UncontrolledTooltip target='btttn' placement='bottom'>
           Opening on 15th February
            </UncontrolledTooltip></div>
    
            </CardBody >
            </Card>

            <Card className=" col-8 mt-4 container-fluid numcard">
          <CardBody >
           <div className="evndt">
          <strong><h1 className='text-center'>EVENT DATE</h1></strong>
          <div className="evnnum">
              <h1 className='text-center'>2- 4 March 2021</h1>
              </div>
              </div>
          </CardBody >
            </Card>
            </section> 
           
            
    </>
  );
}

export default Events;