import React ,{useEffect, useState} from 'react';
import { Button, Modal } from "reactstrap";
import { Card, CardBody, ListGroupItem, ListGroup, Row, Col } from "reactstrap";
import axios from 'axios';
import { ModalSpinner} from 'views/Hoc/Spinner';
import {ToastsContainer, ToastsStore} from 'react-toasts';
import { LinkedinIcon, TwitterIcon, WhatsappIcon } from "react-share";
import { LinkedinShareButton, TwitterShareButton, WhatsappShareButton  } from "react-share";

export  const SocialShareDialog=(props)=> {

  console.log('in socual share');
  console.log(props);
  const { socialShareModalClose, isOpen, data } = props;
  const linkedInShare = (data.SKU)?'https://channelhub.net?productDetailView=' + data.SKU:'https://channelhub.net';
  
 
  useEffect(() => {
  //  setLoading(false);
   
  }, []);


  return (
    <div className="p-3">
   
     <span className="socialbox"> Share this product with your Colleague</span>
         <div className="borderdivid w-100 mt-1"></div>
         <p className="mb-0 mt-2">  {data.title}</p>
           
      
            <Card className="w-100 mb-0">
            <CardBody>
             
              
              <Row>
                  <Col lg="12" align="center">
                  <LinkedinShareButton className="mr-2" title={data.title} url={linkedInShare}>
                  <LinkedinIcon className="mr-2" size={32} round={true} />
                  </LinkedinShareButton>
                  <span>&nbsp;</span>
                  <TwitterShareButton className="mr-3" title={data.title} url={data.url}>
                      <TwitterIcon size={32} round />
                  </TwitterShareButton> 
                  <span>&nbsp;</span> 
                  <WhatsappShareButton title={data.title} url={linkedInShare}>
                      <WhatsappIcon size={32} round />
                  </WhatsappShareButton> 
                  </Col>
              </Row> 
            </CardBody>
            </Card>
        </div>
    
        
       
   
  );
}
export  default SocialShareDialog;