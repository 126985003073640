import React, { useState, useEffect, useRef } from "react";
import { ListItem } from "_components/Listing/ListItem";
import { withStyles } from "@material-ui/core/styles";
import {
  Badge, DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown, UncontrolledTooltip
} from "reactstrap";
import InputBase from "@material-ui/core/InputBase";
import { Progress } from "reactstrap";
import { Card, CardBody, Row, Col } from "reactstrap";
import TablePagination from '@material-ui/core/TablePagination';
import { TablePaginationActions } from 'views/Hoc/pagination/PaginationActions';
import { commonService } from '_services/common.service';
import { Spinner } from "views/Hoc/Spinner";
import CountryFlag from "views/Hoc/countryFlag/countryFlag";
import Favourities from 'views/Hoc/Dialog/Favourities'
import { UpgradeModal } from "views/Hoc/Dialog/GlobalDialog";
import { Favorite } from "@material-ui/icons";
import { history } from '../../../_helpers/history';
import { channelAction } from '_actions/channel.action';
import { connect, useSelector } from 'react-redux';
import { ContactListing } from "views/Hoc/contact-list/contactList";
import NotificationAlert from "react-notification-alert";
import axios from 'axios';
const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    width: "100%",
    padding: "5px 26px 5px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

export const OldChannel = (props) => {
  const { filterValueState, activeTab, search, channelFlag, toBreadCrumb } = props;
  let [page, setPage] = useState(0);
  let [filterFlag, setFilterFlag] = useState(0);
  let [rowsPerPage, setRowsPerPage] = useState(10);
  let [total, setTotal] = useState(0);
  let [isLoading, setLoading] = useState(true);
  let [applyBlur, setApplyBlur] = useState(false);
  let user = JSON.parse(localStorage.getItem('CHuser'));
  let [data, setData] = useState([]);
  let [isUpgradePopup, setUpgradePopup] = useState(false);
  let [modalPopValue, setModalPopValue] = useState({ cid: null, JctId: null });
  let [sort, setSort] = useState('asc');
  let [outboundCount, setOutboundCount] = useState(0);
  const notificationAlert = useRef(null);
  let planId = user?.planId;
  let fromIndex = 0;
  const redux_data = useSelector(state => state.channelListing);
  useEffect(() => {

    if (redux_data.sorting) {
      if (sort !== redux_data.sorting) {
        // setSort(redux_data.sorting);
        sort = redux_data.sorting;
        setSort(sort);
        getCount();
        callChannelList();

      }
    }
  }, [redux_data.sorting]);
  useEffect(() => {
    if (activeTab)
      callChannelList();
  }, [filterValueState, search]);

  console.log("raj:", activeTab);

  const callChannelList = () => {
    page = 0;
    setPage(page);
    getChannelList();
  }
  const getChannelList = async () => {
    applyBlur = user.planId === '1' && (activeTab === '9' || activeTab === '10') && (user.channelTypeId === '2');// || user.channelTypeId === '3');
    setApplyBlur(applyBlur);
    //console.log('search.name:',search,search.name);
    filterFlag = 0;
    setFilterFlag(filterFlag);
    let postData = {
      filter: getFilterData(),
      order: sort ? sort : 'asc',
      search: search ? search.name : null,
      offset: page * rowsPerPage,
      // offset:1,
      limit: rowsPerPage,
      user: {
        contactId: user?.contactId,
        channelId: user?.channelId,
        channelTypeId: user?.channelTypeId,
        userId: user?.userId
      }
    }


    setLoading(channelFlag);
    let res = [];
   
      res = await commonService.postService(postData, 'channel/getNonMember/' + activeTab + '/' + planId).catch(err => setLoading(false));
    
    
    if (res) {

      data = res.data.results;
      setData(data);
      total = res.data.total;
      setTotal(total);
      props.channelRedux(res.data.master, 'CHANNELS');
      if (redux_data?.tab_count)
        setReduxCount();
      setLoading(false);
    } else {
      data = [];
      setData(data);
      total = 0;
      setTotal(total);
      setLoading(false);
    }
  }
  const setReduxCount = () => {
    let count = redux_data?.tab_count;
    console.log('activeTab', activeTab);
    console.log('total', total);
    switch (parseInt(activeTab)) {
      case 1:
        // if(count?.confirm_count)
        count.confirm_count = total;
        break;
      case 2:
        // if(count?.outbound_count)
        count.outbound_count = total;
        break;
      case 3:
        // if(count?.inbound_count)
        count.inbound_count = total;
        break;
      case 4:
        // if(count?.request_count)
        count.request_count = total;
        break;
      case 5:
        // if(count?.savelater_count)
        count.savelater_count = total;
        break;
      case 8:
        // if(count?.suggestion_vendor_count || count?.suggestion_vendor_count===0)
        count.suggestion_vendor_count = total;
        break;
      case 9:
        // if(count?.suggestion_dist_count)
        count.suggestion_dist_count = total;
        break;
      case 10:
        // if(count?.suggestion_retail_count)
        count.suggestion_retail_count = total;
        break;
      case 15:
        count.nonmember_count = total;

      default:
        break;
    }
    props.channelRedux(count, 'TABCOUNT');
  }
  const getFilterData = () => {
    let filter = {
      specialityId: null,
      brandId: null,
      countryId: null,
      productId: null,
      intProductId: null,
      locationId: null,
      retailerprofileId: null,
      resellerprofileId: null,
      productCategoryId: null,
      turnOver: null,
      connectionStatusId: null,
      typeId: null,
      storeCount: null,
    };

    if (filterValueState.Speciality && filterValueState.Speciality.length > 0) {
      filter.specialityId = filterValueState.Speciality.map(x => parseInt(x.id));
      filterFlag = 1;
      setFilterFlag(filterFlag);
    }
    if (filterValueState.Brand && filterValueState.Brand.length > 0) {
      filter.brandId = filterValueState.Brand.map(x => parseInt(x.id));
      filterFlag = 1;
      setFilterFlag(filterFlag);
    }

    if (filterValueState.Current_Product && filterValueState.Current_Product.length > 0) {
      filter.productId = filterValueState.Current_Product.map(x => parseInt(x.childid));
      filterFlag = 1;
      setFilterFlag(filterFlag);
    }

    if (filterValueState.Interested_Product && filterValueState.Interested_Product.length > 0) {
      filter.intProductId = filterValueState.Interested_Product.map(x => parseInt(x.childid));
      filterFlag = 1;
      setFilterFlag(filterFlag);
    }

    if (filterValueState.Company_Origin && filterValueState.Company_Origin.length > 0) {
      filter.countryId = filterValueState.Company_Origin.map(x => parseInt(x.id));
      filterFlag = 1;
      setFilterFlag(filterFlag);
    }
    if (filterValueState.Target_Location && filterValueState.Target_Location.length > 0) {
      filter.locationId = filterValueState.Target_Location.map(x => parseInt(x.id));
      filterFlag = 1;
      setFilterFlag(filterFlag);
    }
    if (filterValueState.Store_Count) {
      filter.storeCount = filterValueState.Store_Count.id;
      filterFlag = 1;
      setFilterFlag(filterFlag);
    }
    if (filterValueState.Retailer_Profile && filterValueState.Retailer_Profile.length > 0) {
      filter.retailerprofileId = filterValueState.Retailer_Profile.map(x => parseInt(x.id));
      filterFlag = 1;
      setFilterFlag(filterFlag);
    }
    if (filterValueState.Reseller_Profile && filterValueState.Reseller_Profile.length > 0) {
      filter.resellerprofileId = filterValueState.Reseller_Profile.map(x => parseInt(x.id));
      filterFlag = 1;
      setFilterFlag(filterFlag);
    }
    if (filterValueState.productCategory && filterValueState.productCategory.length > 0) {
      filter.productCategoryId = filterValueState.productCategory.map(x => parseInt(x.id));
      filterFlag = 1;
      setFilterFlag(filterFlag);
    }
    if (filterValueState.Annual_Turnover) {
      filter.turnOver = filterValueState.Annual_Turnover.id;
      filterFlag = 1;
      setFilterFlag(filterFlag);
    }
    if (filterValueState.connectionStatusId) {
      filter.connectionStatusId = filterValueState.connectionStatusId.id;
      filterFlag = 1;
      setFilterFlag(filterFlag);
    }
    if (filterValueState.typeId) {
      filter.typeId = filterValueState.typeId.id;
      filterFlag = 1;
      setFilterFlag(filterFlag);
    }
    return filter;

  }
  const handleChangePage = (event, newPage) => {
    page = newPage;
    setPage(page);
    getChannelList();
  };

  const handleChangeRowsPerPage = (event) => {
    rowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(rowsPerPage);
    callChannelList();
  };
  const openUpgradePopup = (event) => {
    modalPopValue.cid = event.target.id;
    modalPopValue.JctId = event.target.parentNode.id;
    setModalPopValue({ ...modalPopValue });
    setUpgradePopup(true);
  }
  const closeUpgradePopup = () => {
    setUpgradePopup(false);
  }
  const notify = (type, message) => {
    let options = {
      place: "tr",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {/* {" "}
                {type} */}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      // icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  const getCount = async () => {
    let res = await commonService.getServices('/channel/getTapCount/' + user.contactId);
    // let counts = {
    //     confirm_count: res.data?.counts?.confirm_count,
    //     outbound_count: res.data?.counts.outbound_count,
    //     //dismiss_count: totalTabCount.dismiss_count
    // }
    setOutboundCount(res.data?.counts.dismiss_count);
  }
  let icnt = 0;
  return (

    <div id="iner" className="main-content mt-3">
      <NotificationAlert ref={notificationAlert} />
      <UpgradeModal open={isUpgradePopup} value={modalPopValue} handleSave={closeUpgradePopup} handleClose={closeUpgradePopup} />
      {(data.length <= 0 && !isLoading) && <div className="no-records ">
        {/* <span>No Records</span> */}
        <div className="no-img text-center">
          <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} className="img-fluid" alt="ch-logo" src={require("../../../assets/img/medialibrary/no-data.png")} /></div>

        {(activeTab == '8' || activeTab == '9' || activeTab == '10' || activeTab == '14') &&
          'Unfortunately, based on your product categories and target countries there is no matching profile at the moment. '
          + 'You can use our advanced search features to find other results based on your own filters or modify your target countries in your Personal Profile.'
          + 'note: Target countries are user-based and not company-based.'}
      </div>
      }
      {isLoading ? <Spinner /> : <>
        {data.length > 0 && data.map((item, index) => {
          let logo = "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg";
          //let isConfidential = (((parseInt(data?.isconfidential) !== null && parseInt(data?.isconfidential) === 0) || (applyBlur && parseInt(data?.isconfidential) !==1) || (parseInt(data?.isconfidential) === null && data?.status === 'NonSuggestion' && user.planId==='1'))  && user.channelTypeId !== '4'&& parseInt(data?.channeltypeid) !==2)


          //  let isConfidential = ((item.isconfidential === 1 && item.isconfidential !== null && item.isconfidential !==0 ) || (item.status === 'NonSuggestion' && user.planId==='1') && user.channelTypeId !== '4'&& parseInt(data?.channeltypeid) !==2);

          // let isConfidential = ((item.isconfidential === 1 && item.isconfidential !== null && item.isconfidential !==0 ) || (item.status === 'NonSuggestion' && user.planId==='1') && user.channelTypeId !== '4'&& parseInt(data?.channeltypeid) !==2);


          //let isConfidential = (((item.isconfidential === 1 ) || (item.status === 'NonSuggestion' && user.planId==='1') && user.channelTypeId !== '4'&& parseInt(data?.channeltypeid) !==2))?false:true;

          let isConfidential;
          if (filterFlag == 1 || page != 0)
            isConfidential = ((user.planId == 1 && user.channelTypeId == 2 && item.isconfidential == 1)) ? false : true;
          else
            isConfidential = ((user.planId == 1 && user.channelTypeId == 2 && item.isconfidential == 1) && (icnt > 0 && outboundCount == 0)) ? false : true;


          icnt++;
          // if(activeTab == 2){
          //   isConfidential = true;
          // }

          return (
            <Row className="justify-content-center">
              <Col className="card-wrapper ct-example" lg="12" md="12" sm="12" >
                <Card>
                  {/* <CardHeader>
                  <h3 className="mb-0">Styles</h3>
                </CardHeader> */}
                  <CardBody>
                    <Row className="align-items-center">

                      <Col className="col-lg-2 col-md-2 col-sm-12 mobi-res text-center">
                        <a
                          className={"avatar avatar-xl mb-2 details" + (isConfidential == false ? 'isConfidential' : '')}
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }}
                            alt="..."
                            src={isConfidential == false ? logo : item.logo ? process.env.REACT_APP_CDN_URL + item.logo : logo}
                          />
                        </a>
                        {(planId==6||activeTab != '15') && <div className="pointer mt-3" id={item.channel_jct_id} onClick={isConfidential == false ? openUpgradePopup :  () => { window.open('/admin/nonmemberDetail/' + item.cid,'_blank') }}>
                          <Badge id={item.cid} style={{ backgroundColor: "#2c9db7", color: "white", fontSize: "0.8000rem", textTransform: "capitalize", width: "100%" }}>
                            +details
                          </Badge>
                        </div>}
                        {item.websiteurl && <div className="text-center mt-3 pointer">
                          {!isConfidential?
                          <a onClick={openUpgradePopup}><i className="fas fa-globe-asia mr-1"></i>Website
                          </a>:<a 
                          onClick={(e) => {
                            e.preventDefault();
                            let url = item.websiteurl;
                            if (!url.match(/^https?:\/\//i)) {
                              if (!url.match(/^http?:\/\//i))
                                url = '//' + url;
                            }
                            window.open(url, '_blank')
                          }}
                          
                          >
                          <i className="fas fa-globe-asia mr-1"></i>Website
                        </a>}
                          
                        </div>}
                      </Col>
                      <div className="col-lg-9 col-md-9 col-sm-12 mobi-res3">
                        <div className="row">
                          <div className="col-lg-3 col-md-2 col-sm-12 mobi-res1  res-padr1">
                            <Badge
                              style={{
                                backgroundColor: "#FF6347",
                                color: "white",
                                fontSize: "0.9000rem",
                              }}
                            >
                              {item.channeltype}
                            </Badge>
                          </div>
                          {/* flag */}
                          <div className="col-auto col mobi-res2">

                            {/* <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  alt="..." src={item.contacts.map((x) => { if(x.isDefaultContact=="1")return x.documentPath })?"http://d2maj8ifjo1lti.cloudfront.net/"+item.contacts.map((x) => { if(x.isDefaultContact=="1")return x.documentPath }):"http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"}/> */}
                            <CountryFlag code={item.isoCode} />

                          </div>

                          <div style={{ paddingLeft: "0px" }} className={"col-lg-3 col-md-3 col-sm-12 mobi-res pad-01 " + (isConfidential == false ? 'isConfidential' : '')}>
                            <h3 className="mb-0">{isConfidential == false ? "XXXXXXXXXXXX" : item.country}</h3>
                          </div>
                          <div className="col-lg-5 col-md-5 col-sm-12 pr-0 mobi-res4">
                            {/* <Select
                              labelId="demo-customized-select-label"
                              id="demo-customized-select"
                              value={10}
                              style={{ width: "100%" }}

                              input={<BootstrapInput />}
                            >
                              {item.contacts?.map((con, i) => (
                                <MenuItem value={10}>
                                  <a className={"avatar avatar-xs rounded-circle " + (isConfidential == false ? 'isConfidential' : '')}>
                                    <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}} 
                                      alt="..."
                                      src={con.documentPath ? "http://d2maj8ifjo1lti.cloudfront.net/" + con.documentPath : "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"}
                                    />
                                  </a>
                                  <span
                                    style={{
                                      position: "absolute",
                                      marginLeft: "10px",
                                    }} className={"userelip " + (isConfidential == false ? 'isConfidential' : '')}
                                  >
                                    {isConfidential == false ? "XXXXXXXXXXXX" : con.firstName + " " + con.lastName + "-" + con.jobTitle}

                                  </span>
                                </MenuItem>
                              ))}
                            </Select> */}


                            {!!item.contacts?.length && <UncontrolledDropdown className="dropdownwidthfirst">
                              <DropdownToggle caret color="default" className="btnfirst">
                                <a className={"avatar avatar-xs rounded-circle " + (isConfidential == false ? 'isConfidential' : '')}>
                                  <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/default_image/contact.png"); }} style={{ width: '24px', height: '24px', objectFit: 'cover' }} alt="..." src={item.contacts[0]?.documentPath ? "http://d2maj8ifjo1lti.cloudfront.net/" + item.contacts[0]?.documentPath : "http://d2maj8ifjo1lti.cloudfront.net/document/default_image/contact.png"} />
                                </a>
                                <span className={"dropdownfirst " + (isConfidential == false ? 'isConfidential' : '')}>
                                  {isConfidential == false ? "XXXXXXXXXXXX" : item.contacts[0]?.firstName + " " + item.contacts[0]?.lastName + "-" + item.contacts[0]?.jobTitle}

                                </span>
                              </DropdownToggle>

                              <DropdownMenu className="dropdownwidthseco">

                                {item.contacts?.map((con, i) => (
                                  <>
                                    <li>
                                      <DropdownItem id={"id-" + i} onClick={e => e.preventDefault()}>
                                        <a className={"avatar avatar-xs rounded-circle " + (isConfidential == false ? 'isConfidential' : '')}>
                                          <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/default_image/contact.png"); }} style={{ width: '24px', height: '24px', objectFit: 'cover' }}
                                            alt="..."
                                            src={con.documentPath ? "http://d2maj8ifjo1lti.cloudfront.net/" + con.documentPath : "http://d2maj8ifjo1lti.cloudfront.net/document/default_image/contact.png"}
                                          /></a>
                                        <span
                                          style={{
                                            position: "absolute",
                                            marginLeft: "10px",
                                          }} className={"userelip " + (isConfidential == false ? 'isConfidential' : '')}
                                        >
                                          {isConfidential == false ? "XXXXXXXXXXXX" : con.firstName + " " + con.lastName + "-" + con.jobTitle}

                                        </span>
                                      </DropdownItem>
                                      <UncontrolledTooltip target={"id-" + i} placement='bottom'>
                                        {isConfidential == false ? "XXXXXXXXXXXX" : con.firstName + " " + con.lastName + "-" + con.jobTitle}
                                      </UncontrolledTooltip>
                                    </li>
                                  </>
                                ))}

                              </DropdownMenu>
                            </UncontrolledDropdown>
                            }                          </div>


                        </div>

                        {/* First Row End */}
                        <Row>
                          <Col className="col-lg-auto col-md-auto col-sm-12 mobi-res2">
                            <h3 className={"mb-0 text-uppercase " + (isConfidential == false ? 'isConfidential' : '')}>
                              <a
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                {isConfidential == false ? "XXXXXXXXXXXX" : item.companyname}
                              </a>
                            </h3>
                          </Col>
                        </Row>
                        {/* Seoncd Row End */}
                        <Row>
                          <Col className="col-lg-7 col-md-7 col-sm-12">
                            <h4>
                              Specialty in/Categories: &nbsp;
                              <p style={{ display: "inline" }}>
                                {item.company_cat && <ListItem elements={item.company_cat} index={'cat' + index} limit={4} />}
                              </p>
                            </h4>
                            {activeTab !== '10' && <h4>
                              Channel Customer Profiles: &nbsp;
                              <p style={{ display: "inline" }}>
                                {item.retail_customer_profile && <ListItem elements={item.retail_customer_profile} index={'pro' + index} limit={4} />}
                              </p>
                            </h4>}
                            {activeTab === '10' && <h4>
                              Interest In Start-Up : &nbsp;
                              <p style={{ display: "inline" }}>
                                {item.looking_startups && <ListItem elements={item.looking_startups} index={'interest' + index} limit={4} />}
                              </p>
                            </h4>}
                          </Col>
                          {item.matchingscore &&
                            <Col className="col-lg-5 col-md-5 col-sm-12 pl-0 pr-0 mobi-res4">
                              <Progress style={{ height: "2rem" }} value={item.matchingscore}>
                                <h4 style={{ color: "white" }} className="mb-0">
                                  Matching Score: {item.matchingscore + '%'}
                                </h4>
                              </Progress>
                            </Col>
                          }
                        </Row>
                        {/* Seoncd Row End */}
                        <Row>
                          <Col className="col-auto">

                            <a
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              <h3 className="mb-0 text-uppercase"> Tweet Pitch</h3>
                            </a>

                            <p>
                              {item.detaildesc?.replace(/<[^>]+>/g, '')}
                            </p>
                          </Col>
                        </Row>

                        {/* 
                     <h4 className="mb-0">
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          John Snow
                        </a>
                      </h4> <h4 className="mb-0">
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          John Snow
                        </a>
                      </h4>
                      <p className="text-sm text-muted mb-0">
                        Working remoteley
                      </p>
                      <span className="text-success mr-1">●</span>
                      <small>Active</small>*/}
                      </div>

                      <Col className="col-lg-1 col-md-1 col-sm-12 mobi-res5">
                        {/* <i class="far fa-heart heart-o"  aria-hidden="true"></i> */}
                        {/* <a href="#" onClick={() => openFavourite(!Favorite)} ><i class="fas fa-heart heart"  aria-hidden="true"></i></a>
                      { Favourite && <Favourities/>} */}
                        {(isConfidential == false || activeTab == '15') ? '' : <Favourities channelTypeId={item.channeltypeid} channelId={item.cid} isFavorite={item.favorite} />}
                        {/* <UncontrolledTooltip placement='bottom'>
                        Add Favourite
                      </UncontrolledTooltip> */}
                        {activeTab != '15' && <div id={'view' + item.channelId} className="pointer" onClick={isConfidential == false ? openUpgradePopup : () => { activeTab > 6 ? history.push('/admin/' + toBreadCrumb + '/channelDetail/' + item.cid) : history.push('/admin/mynetwork/channelDetail/' + item.cid) }}>
                          <i
                            style={{ fontSize: "2em", cursor: "pointer" }}
                            className="far fa-eye float-right"
                          ></i>
                          <UncontrolledTooltip target={'view' + item.channelId} placement='bottom'>
                            View Channel
                          </UncontrolledTooltip>
                        </div>}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )

        })}
        {total > 10 &&
          <TablePagination
            component="div"
            count={total}
            page={page}
            onChangePage={handleChangePage}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[10, 20, 30, 40]}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        }
      </>}
    </div>

  );
};
function mapState(state) {
  const general_data = state.channelListing;
  return general_data;
}

const actionCreators = {
  channelRedux: channelAction.channelRedux
};

export default connect(mapState, actionCreators)(OldChannel);