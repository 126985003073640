
import React, { useState, useEffect } from "react";
import { Container, Row, Col, CardBody, Card, Button } from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader"
import Title from "views/Hoc/Dialog/Title";
import { commonService } from '_services/common.service';
import Moment from 'moment';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { Spinner } from 'views/Hoc/Spinner';
var _ = require('lodash');
const Events = (props) => {
  const [loading, setLoading] = useState(false);
  let [isBoFo, setIsBoFo] = useState(false);
  let [profileData, setProfileData] = useState(null);
  let [list, setList] = useState([]);
  let [listData, setListData] = useState([]);
  let [token, settoken] = useState(null);
  useEffect(() => {
    console.log('window.location.hostname', window);
    console.log('window.location.hostname', window.location.hostname);
    isBoFo = window.location.hostname === process.env.REACT_APP_BOFO;
    setIsBoFo(isBoFo);
    eventAuth();
  }, []);
  const eventAuth = () => {
    setLoading(true);
    eventRegister();
    if (!localStorage.getItem('eventcatalyst_token')) {
      let req = {
        url: process.env.REACT_APP_EVENTCATALYST_API + 'oauth/token'
      }
      commonService.postService(req, 'account/eventcatalystToken').then(res => {
        if (res.data?.error) {
          setLoading(false);
          ToastsStore.warning('Something went wrong');
        } else {
          localStorage.setItem('eventcatalyst_token', JSON.stringify(res.data));
          getEventList();
        }
      }).catch(err => {
        setLoading(false);
        console.log('err', err)
      })
    } else {
      getEventList();
    }
  }
  const getEventList = () => {
    const url = 'getEventRegister';
    let data = {
      token: JSON.parse(localStorage.getItem('eventcatalyst_token')),
      url: process.env.REACT_APP_EVENTCATALYST_API + 'api/registration/' + userData.contactId
    }
    commonService.postService(data, 'account/getEventRegister').then(res => {
      setLoading(false);
      list = listData = _.orderBy(res.data.registrations, ['date_start'], ['desc']);
      setListData(listData);
      setList(list);
      console.log('list', list)
      // resultCount= list?list.length:0;
    }, err => {
      setLoading(false);
      console.log('err', err)
    });
  }
  var userData = JSON.parse(localStorage.getItem('CHuser'));
  const eventRegister = () => {
    return new Promise((resolve) => {
      commonService.getServices('channel/eventRegister/' + userData.contactId).then(async res => {
        profileData = res.data;
        setProfileData(profileData);
        console.log('res.data', res.data);
        let tokenData = await commonService.getServices('account/getPublicToken/' + userData.channelId);
        if (tokenData) {
          console.log('tokenData', tokenData);
          token = tokenData.data.token;
          settoken(token);
          resolve(true);
        } else
          resolve(false);
      }, err => {
        resolve(false);
        console.log('err', err);
      });
    })
  }
  const getManage = async (event) => {
    setLoading(true);
    await eventRegister();
    let dec = profileData.length > 0 ? profileData[0].detailDesc : null
    if ((dec === null) || (dec === '') || !dec) {
      dec = null;
    } else {
      dec = dec.toString();
      dec = dec.replace(/(<([^>]+)>)/ig, '').trim();
    }
    let data = {
      participantId: parseInt(userData.contactId),
      eventId: event.eventId,
      companyId: parseInt(userData.channelId),
      existingCategory: profileData.length > 0 ? profileData[0].ExistingCategory ? profileData[0].ExistingCategory.replace(/,/g, "|") : null : null,
      interestedCategory: profileData.length > 0 ? profileData[0].IntrestedCategory ? profileData[0].IntrestedCategory.replace(/,/g, "|") : null : null,
      profileType: profileData.length > 0 ? profileData[0].ProfileType : null,
      retailCustomerProfile: profileData.length > 0 ? profileData[0].Retail_Customer_Profile ? profileData[0].Retail_Customer_Profile.replace(/,/g, "|") : null : null,
      detailDesc: dec,
      password: profileData.length > 0 ? profileData[0].password : null
    }
    // let body = {
    //   token: JSON.parse(localStorage.getItem('eventcatalyst_token')),
    //   url: process.env.REACT_APP_EVENTCATALYST_API + 'api/manage',
    //   body: data
    // }
    // commonService.postService(body, 'account/postEventRegister').then(res => {
    //   
    //   window.open(res.data.pageUrl, '_blank');
    // });    
    let eventName;
    if (event.eventId == '80858442-323e-499e-89cd-536a45c03aaf')
      eventName = 'ces-breakfast'
    else if (event.eventId == '122d6cc2-b33b-47f6-808a-fce568ad5d94')
      eventName = 'channel-summit22'
    let type = userData.channelTypeId == 2 ? 'buyers' : 'vendors';
    setLoading(false);
    const win = window.open(process.env.REACT_APP_LOCALSITE + eventName + '/' + type, '_blank');
    win.focus();
  }
  const registration = async (event) => {
    // contactInfo = JSON.parse(localStorage.getItem(AppLocalStorageKeys.CONTACT_INFO));
    // let logoUrl= localStorage.getItem('fo-company_logo');
    // if(token == '' || !token){
    //   let checkTokenExists = await getAuthToken(channelId);
    //   if(!checkTokenExists){
    //     ToastsStore.warning('Something went wrong'); 
    //     return 
    //   }
    // }
    if (!isBoFo) {
      // ToastsStore.warning('Something went wrong');
      if (parseInt(userData.channelTypeId) === 2)
        window.open("https://www.retailconnect1to1.com/product-page/pod", "_blank");
      else
        window.open("https://www.channel-summit.com/ch-delegate-application", '_blank');
      return
    }
    setLoading(true);
    await eventRegister();
    let dec = profileData.length > 0 ? profileData[0].detailDesc : null
    console.log('dec', dec);
    if ((dec === null) || (dec === '')) {
      dec = null;
    } else {
      dec = dec.toString();
      dec = dec.replace(/(<([^>]+)>)/ig, '').trim();
    }

    let data = {
      "participantId": parseInt(userData.contactId),
      "firstName": profileData[0].firstName,
      "lastName": profileData[0].lastName,
      "email": profileData[0].email,
      "companyName": profileData[0].companyName,
      "companyId": parseInt(userData.channelId),
      "eventId": event.eventId,
      "status": "Confirmed",
      "type": userData.channelTypeId === '2' ? "Vendor" : "Buyer",
      "typeId": parseInt(userData.channelTypeId),
      "timezone": "",
      "matchingScore": [],
      "country": profileData[0].isoCode,
      "profileUrl": process.env.REACT_APP_LOCALSITE + 'channelView/' + userData.channelId + '?token=' + token,
      "photoUrl": profileData[0].profileimage,
      "mobile": profileData[0].mobileNo,
      "jobTitle": profileData[0].jobTitle,
      "category": userData.channelTypeId === '2' ? 'Vendor' : userData.channelTypeId === '3' ? 'Distributor' : userData.channelTypeId === '4' ? 'Retailer' : 'Reseller',
      "logoUrl": profileData[0].companylogo,
      "existingCategory": profileData.length > 0 ? profileData[0].ExistingCategory ? profileData[0].ExistingCategory.replace(/,/g, "|") : null : null,
      "interestedCategory": profileData.length > 0 ? profileData[0].IntrestedCategory ? profileData[0].IntrestedCategory.replace(/,/g, "|") : null : null,
      "profileType": profileData.length > 0 ? profileData[0].ProfileType : null,
      "retailCustomerProfile": profileData.length > 0 ? profileData[0].Retail_Customer_Profile ? profileData[0].Retail_Customer_Profile.replace(/,/g, "|").replace(/,/g, "|") : null : null,
      "detailDesc": dec,
      "password": profileData.length > 0 ? profileData[0].password : null
    }
    // // Status: Invited, Shortlist, Confirmed, Rejected, Cancelled, WaitingList, Declined
    let body = {
      token: JSON.parse(localStorage.getItem('eventcatalyst_token')),
      url: process.env.REACT_APP_EVENTCATALYST_API + 'api/registration',
      body: data
    }
    commonService.postService(body, 'account/postEventRegister').then(res => {
      setLoading(false);
      setEventContact(data)
      getEventList();
      window.open(res.data.pageUrl, '_blank');
    });
  }
  const setEventContact = (data) => {
    //update contact table isEvent
    commonService.getServices('channel/setEventContact/' + userData.contactId).then(res => {
    }, err => {
      console.log('err', err)
    });
    //add into event participant table
    commonService.postService(data, 'channel/addEventParticipants').then(res => {
    }, err => {
      console.log('err', err)
    });
  }
  return (
    <>
      <SimpleHeader location={props.match} />
      <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore} />
      <div id='Events' className="main-content">
        <Title title="Events" desc={["List of events and tradeshows happening in channelhub"]} />
        <Container fluid className="mt-4">
          {/* <Nav tabs className="list-tab">
    <NavItem className="list-main">
        <NavLink className= "active">
          <h4>EVENTS</h4>
          <Row className="text-uppercase">
            <Col onClick={()=>setTab(1)}   xs="6" className="icon-tag2 mt-2 sub-a">
              <a style={{cursor:'pointer'}}>
                <h3 className="mb-0">COMPANIES</h3>
                <p class="count m-0">(12)</p>
              </a>
            </Col> 
          <Col  onClick={()=>setTab(2)}  xs="6" className="mt-2 sub-a">
              <a style={{cursor:'pointer'}} >
                <h3 className="mb-0">PRODUCTS</h3>
                <p class="count m-0">(18)</p>
              </a>
            </Col>
          </Row>
        </NavLink>
      </NavItem>
    
    </Nav> */}
          {loading ? <Spinner /> :
            <Card>
              <CardBody>
                {list.length > 0 &&
                  list.map(item => (
                    <Row className="pt-4">
                      <Col lg="12" className="event-card pl-3 pt-2 pr-3 pb-2 d-flex justify-content-center">
                        <Row>
                          <Col lg="1" md="1" sm="12">
                            <div className="d-inline mr-4">
                              <a className="event-logo avatar avatar-xl mb-2 p-2" href="#pablo" onClick={(e) => e.preventDefault()}>
                                <img alt="..." src={item.logo ? item?.logo : "https://static.wixstatic.com/media/76c928_c8c2edb7ca4347cfb5f6a94cc986bdda~mv2.png/v1/fill/w_188,h_118,al_c,q_85,usm_0.33_1.00_0.00/RETAIL_CONNECT_ONE_TO_ONE_EMEA.webp"} />
                              </a>
                            </div>
                          </Col>
                          <Col className="ml-2" lg="7" md="5" sm="12" align-self-center>
                            <div className="d-inline mr-4">
                              <div className="event-title mb-2">{item.event_name}</div>
                              <div>{item.description.replace(/<[^>]+>/g, '')}</div>
                            </div>
                          </Col>
                          <Col className="ml-2" lg="2" md="5" sm="12" align-self-center>
                            <div className="d-inline mr-4">
                              <div className="event-venue mb-2">Date and Venue</div>
                              <div className="event-venue-detail"><i class="fa fa-calendar mr-2" aria-hidden="true"></i>{Moment(item.date_start).format('D MMMM y')} - {Moment(item.date_end).format('D MMMM y')}</div>
                              <div className="event-venue-detail"><i class="fa fa-map-marker mr-2" aria-hidden="true"></i>{item.location}</div>
                            </div>
                          </Col>
                          <Col lg="1" md="5" sm="12" align-self-center>
                            <div className="d-inline">
                              {(Moment(item.date_start).format('yyyy') >= 2022 && Moment(item.date_start).format('M') >= 1) ? <>
                                {!item.statusDate && item.statusDate === '' && <Button size="md" type="button" onClick={() => registration(item)}>Register</Button>}
                                {item.statusDate && item.statusDate !== '' && <Button size="md" type="button" onClick={() => getManage(item)}>Manage</Button>}
                              </> :
                                <Button size="md" type="button">Completed</Button>}
                              {/* {Moment(item.date_start).format('yyyy')!=2021 && <Button size="md" type="button">Completed</Button>} */}
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  ))
                }
              </CardBody>
            </Card>
          }
        </Container>
      </div>
    </>
  );
}


export default Events;