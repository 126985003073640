import { userConstants } from '../_constants';
import { userService,LocalStorageService } from '../_services';
import { dashboardConstants } from '../_constants';
import { dashboardService } from '../_services';
import { alertActions } from './';
import { history } from '../_helpers';


export const userActions = {
    login,
    logout,
    
//    register,
  //  getAll,
    //delete: _delete
};


function login(username, password, apptoken) {

    const localStorageService = LocalStorageService.getService();

    return dispatch => {
        dispatch(request({ username }));

      userService.login(username, password, apptoken)
            .then(
                user => { 
                    dispatch(success(user));

                        dashboardService.dashboard_count()
                              .then(                                   
                                     dasboard_count => dispatch(count(dasboard_count)),                          
                              );
                    
                    console.log('user', user);
                    
                    localStorageService.setToken(user.data.token);
                    if(user.data.user.signUpStatusId === "14")
                        history.push('/Pending');
                    if(user.data.user.signUpStatusId === "15" && user.data.user.lastLogin == null){
                        history.push('/Verification');
                    }
                    if(user.data.user.signUpStatusId === "15" && user.data.user.lastLogin != null){
                        history.push('/admin/');
                    }
                        
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function count(dasboard_count) { return { type: dashboardConstants.COUNT_SUCCESS, dasboard_count } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

 

function logout() {
    userService.logout();
    return { type: userConstants.LOGOUT };
}

