import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Moment from 'moment';
import {
  Button, Modal,Row,Col,Card
} from "reactstrap";
import { LaptopWindows } from '@material-ui/icons';
const Awardlist=(props)=> {
  
  const {open, handleClose, value } = props;
  //console.log(props)
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
 

  return (
    <div>
      <Modal className="modal-dialog-centered" isOpen={open}>
          <div className="modal-header">
           <strong>Awards</strong>
            <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={handleClose}>
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body awd-bdy pt-0">
            <Card>

         
                  <Row style={{border: "1px solid #d0d0d0"}}>
  <Col className="col-4">
  
         
                    <div className="brandCard">
                        <div  className="imgContiner">
                        <img style={{width:"100px",height:"100px"}} className="Brandimg"  src="http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/1615441186085_1615441186207_1615441186686.png"/>
                     </div>
                    </div>
                    
                  
              
  </Col>
  <Col className="col-4">
  
         
  <div className="brandCard">
      <div  className="imgContiner">
      <img className="Brandimg"  src="http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/1615441186085_1615441186207_1615441186686.png"/>
   </div>
  </div>
  


</Col>
                  </Row>
            
            </Card>
          </div>
          <div className="modal-footer">
          {/* <Button color="primary" type="button" onClick={()=>handleSave(value)}>Ok</Button> */}
          <Button color="secondary" data-dismiss="modal" type="button" onClick={handleClose}>Close</Button>
          </div>
        </Modal>
    </div>
  );
}
export default Awardlist;