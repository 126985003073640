import React, { useState, useEffect } from "react";
import { Container } from "reactstrap";
import Title from "views/Hoc/Dialog/Title";
import ProfileListingTabs from "./ProfileListingTabs";
import SimpleHeader from "components/Headers/SimpleHeader";
import ProfileViewWrapper from "../dashboards/ProfileViewWrapper";

const ProfileViewListing = (props) => {
  console.log('props', props);
  let [listing, setListing] = useState(props.location.pathname);
  let [activeTab, setActiveTab] = useState(null);
  let [val, setSelection] = useState(1);
  let [desc, setDesc] = useState([]);
  let [searchState, setSearch] = useState(null);
  let userStatus = JSON.parse(localStorage.getItem('CHuser'));
  let [title, setTitle] = useState();

  let viewId = props.location.state?.viewId ? props.location.state.viewId : '1';

  let profileViews = props.location.state?.profileViews ? props.location.state.profileViews : '0';

  let unknownProfileViews = props.location.state?.unknownProfileViews ? props.location.state.unknownProfileViews : '0';

  //   useEffect(() => { 
  //     listing=props.location.pathname;  
  //     setListing(listing);
  //     console.log('listing', listing);
  //     if(listing === '/admin/search/11')
  //     {
  //       desc=["List of your various search"];
  //       activeTab='11';
  //       title="Search";
  //       console.log('listing', activeTab);
  //     }  
  //     else if(listing === '/admin/search/12')
  //     {
  //       desc=["List of your various search"];
  //       activeTab='12';
  //       title="Search";
  //       console.log('listing', activeTab);
  //     }  
  //     else if(listing === '/admin/search/13')
  //     {

  //       desc=["List of your various search"];
  //       activeTab='13';
  //       title="Search";
  //       console.log('listing', activeTab);
  //     }

  //     setActiveTab(activeTab);
  //     setDesc(desc);
  //     setTitle(title);



  // },[props.location.pathname] )

  useEffect(() => {

    if (viewId == '1') {
      desc = ["Below are users who have viewed your account recently. You can check their company profile and connect with them to start the discussion"]
      title = "Profile View";
    } else if (viewId == '2') {
      desc = ["Product View Description"]
      title = "Product View";
    } else if (viewId == '3') {
      desc = ["Below are users who have put your company in their favourites. You can check their company profile and connect with them to start the discussion"]
      title = "Follower";
    }
    setDesc(desc);
    setTitle(title);
  }, [])


  const handleChangeSearch = (input, value) => {
    searchState = value;
    setSearch(searchState);
  }

  return (
    <>
      <SimpleHeader location={props.match} />
      <div id="listing" className="main-content profileviewlistingwrapper">

        <Title title={title} desc={desc} />

        <Container className="mt-4 col-12 list-tab" fluid>
          <ProfileListingTabs activeTab={activeTab} userStatus={userStatus} listing={listing} handleChangeSearch={handleChangeSearch} viewId={viewId} profileViews={profileViews} unknownProfileViews={unknownProfileViews} />
          <ProfileViewWrapper search={searchState} viewId={viewId} />
        </Container>
      </div>
    </>
  );
};
export default ProfileViewListing; 
