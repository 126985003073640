import React,{useState, useEffect, useRef} from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Container,Card,CardBody, Row,Col,NavItem,NavLink,Nav,TabContent,TabPane} from "reactstrap";
import classnames from "classnames";
import CompanyProfile from './CompanyProfile';
import TradeInformation from './TradeInformation'
import Settings from './Settings'
import Tradelocation from './Tradelocation'
import Usermanage from './Usermanage'
import SimpleHeader from "components/Headers/SimpleHeader"
import CompanyProfileVendor from "./CompanyProfileVendor";
import IncompleteProfileVendor from "./IncompleteProfileVendor"
import YourChannel from "./YourChannel";
import {history} from "_helpers/history";

const SettingsWrapper = (props) => { 
  
  let userData = JSON.parse(localStorage.getItem('CHuser'));
  let typeId = userData?.channelTypeId;

  let myRef = useRef(null);

  if(typeId!='2'){
    if(typeId=='3')
      history.push('/admin/companyprofiledist');
    if(typeId=='4')
      history.push('/admin/companyprofileretres');
  }

  useEffect(() => {
    if(props.location?.state?.id){
      setState({circledNavPills: props.location.state?.id})
    }

  }, []);

  const settings ={
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 3,
    arrows: true,
    responsive:[
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      }
    ]        
};
const [state, setState] = useState({
  circledNavPills: 1
});

const changeNav = (id) => {
  setState({circledNavPills: +id});
  myRef.current.scrollIntoView();
}

const [FinalDataForInitial, setFinalDataForInitial] = useState({});

const transferFormData = (data) => {
  setFinalDataForInitial(data);
}
  
  return (
    <div>
        <>
        {!props.ratingId && <SimpleHeader Ispublic={true} location={props.match}/>}
          <Row>
            <Col lg="12" className="pt-4 settings">
              
            <Slider {...settings} className="prof-slide">
          <div ref={myRef}>
          <Nav className="nav-pills-circle jc-01" id="tabs_2" pills role="tablist">
          <NavItem>
            <NavLink to="/Profile"
              aria-selected={state.circledNavPills === 1}
              className={classnames("rounded-circle", {
                active: state.circledNavPills === 1
              })}
               onClick={e => setState({circledNavPills: 1})}
              
              role="tab"
            >  <div className="" >
              <span className="nav-link-icon d-block">
              <i class="ni ni-building tat"/>
              <h5 style={{marginTop:'1rem',width:"121px", marginLeft:'-2rem',}}>Company Profile</h5>
              </span>
              </div>        
            </NavLink>
          </NavItem>
          </Nav>
          </div>
          
         
          <div>
          {typeId == 2 && <Nav className="nav-pills-circle jc-01" id="tabs_2" pills role="tablist">
          <NavItem>
            <NavLink
              aria-selected={state.circledNavPills === 3}
              className={classnames("rounded-circle", {
                active: state.circledNavPills === 3
              })}
              onClick={e => props.ratingId ? '' : setState({circledNavPills: 3})}
              
              role="tab"
            ><div >
              <span className="nav-link-icon d-block">
              <i class="ni ni-single-copy-04 tat"/>
              <h5 style={{marginTop:'1rem',width:"121px", marginLeft:'-2rem',}}>My Channel</h5>
              </span>
              </div>
             
            </NavLink>
          </NavItem>
          </Nav>}
          </div>
          {/*<div>
          <Nav className="nav-pills-circle jc-01" id="tabs_2" pills role="tablist">
          <NavItem>
            <NavLink
              aria-selected={state.circledNavPills === 4}
              className={classnames("rounded-circle", {
                active: state.circledNavPills === 4
              })}
              onClick={e => setState({circledNavPills: 4})}
             
              role="tab"
            >
              <span className="nav-link-icon d-block">
              <i class="ni ni-square-pin tat"/>
              <h5 style={{marginTop:'1rem',width:"121px", marginLeft:'-2rem',}}>Trade Location</h5>
              </span>
        
            </NavLink>
          </NavItem>
          </Nav>
          </div>
          <div>
          <Nav className="nav-pills-circle jc-01" id="tabs_2" pills role="tablist">
          <NavItem>
            <NavLink
              aria-selected={state.circledNavPills === 5}
              className={classnames("rounded-circle", {
                active: state.circledNavPills === 5
              })}
              onClick={e => setState({circledNavPills: 5})}
              
              role="tab"
            >
              <span className="nav-link-icon d-block">
              <i class="fa fa-users tat" aria-hidden="true"/>
              <h5 style={{marginTop:'1rem',width:"121px", marginLeft:'-2rem',}}>User Management</h5>
              </span>
        
            </NavLink>
          </NavItem>
          </Nav>
          </div>
          <div>
          <Nav className="nav-pills-circle jc-01" id="tabs_2" pills role="tablist">
          <NavItem>
            <NavLink
              aria-selected={state.circledNavPills === 6}
              className={classnames("rounded-circle", {
                active: state.circledNavPills === 6
              })}
              onClick={e => setState({circledNavPills: 6})}
             
              role="tab"
            >
              <span className="nav-link-icon d-block">
              <i class="fa fa-cog tat"/>
              <h5 style={{marginTop:'1rem'}}>Settings</h5>
              </span>
            
            </NavLink>
          </NavItem> 
          </Nav>
          </div> */}
        </Slider>
            </Col>
          </Row>
        </>
        <Card className="shadow">
          <CardBody style={{backgroundColor: "#f8f9fe",paddingTop:"0px"}}>
            <TabContent activeTab={"tabs" + state.circledNavPills}>
            {typeId==2 && <TabPane tabId="tabs1">
                
              {userData?.profile_completion_count>=4?<CompanyProfileVendor ratingId={props.ratingId} setFinalData={setFinalDataForInitial} changeNav={changeNav}/>:<IncompleteProfileVendor ratingId={props.ratingId} setFinalData={setFinalDataForInitial} changeNav={changeNav}/> }
                
              </TabPane>
            }
              {/* <TabPane tabId="tabs2">
              <MyProfile/>
              </TabPane> */}
              {typeId==2  && <TabPane tabId="tabs3">
              {/* <TradeInformation/> */}
              <YourChannel ratingId={props.ratingId} finalData={FinalDataForInitial} changeNav={changeNav}/>
              </TabPane>}
              {/* <TabPane tabId="tabs4">
              <Tradelocation/>
              </TabPane>
              <TabPane tabId="tabs5">
              <Usermanage/>
              </TabPane>
              <TabPane tabId="tabs6">
              <Settings dashboard={props.location.state?.dashboard ? props.location.state?.dashboard : null} />
              </TabPane> */}
            </TabContent>
          </CardBody>
        </Card>
        </div>
  );
}

export default SettingsWrapper;