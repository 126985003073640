
export function adduser(state = {}, action) {

  switch (action.type) {
    case 'GENERAL_DATA':  
    {
      const general_data = action.data;      
      return {  
          ...state,        
          general_data:general_data
      };
    }
    case 'TRADE_INFO':
        {
            const trade_info = action.data;      
            return {              
                ...state,
                trade_info:trade_info
            };
          }
    case 'TRADE_LOC':
        {
            const trade_loc = action.data;      
            return {                
                ...state,
                trade_loc:trade_loc
            };
          }

    case 'PREFERENCE':
        {
            const preference = action.data;      
            return {     
                ...state,           
                preference:preference
            };
        }

    case 'CONTACT':
        {
            const preference = action.data;      
            return {     
                ...state,           
                contactId:preference
            };
        }
      
    default:
      return state
  }
}