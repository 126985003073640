 
import React, { useState, useEffect } from 'react';
import Slider from "react-slick"; 
 

import {
  Col,
} from "reactstrap";
var _ = require('lodash');
 
const HomePageSlider = ({ channels, sliderTitle=null, buyerPage}) => {
  let count = 0;
  const [slide, setSlide] = useState(0);
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: current => setSlide(current)
  };

  return (
    <div className="ProductContainer" style={{ backgroundColor:buyerPage === true ? "#fff" : sliderTitle==="Latest Vendors"? "#f7f7f7" : "none", padding:"1px" }} id='dashslide'>
      <Slider {...settings}>
        {channels.map(row => (
          <div className="d-flex justify-content-left publicproduct">
            {row.map(x => (
              <Col xl='3' lg='4' md='6' sm='12' xs='12' >
                <div id="dictnSKU00011801566" className="ListContainer" style={{ borderRadius: "10px", margin:"0",paddingTop:'3rem', backgroundColor: buyerPage===true  ? "#f7f7f7":sliderTitle==="Latest Buyers"? "#f7f7f7" : "none" }}>
                  <a href="" onClick={e => { 
                     e.preventDefault();  
                      let win = window.open(`/channelDetailView/${x.channelId}`);
                      win.focus();
                   }}>
                    <div className='list-image'>
                      <img
                        alt="..."
                        className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                        src={x.logo ? process.env.REACT_APP_CDN_URL + x.logo : "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"}
                      />  </div>
                    <h3 style={{paddingTop:"2rem"}}>{x.companyName}</h3>
                    <p style={{paddingBottom:"1rem"}}>{x.country}</p>
                  </a>
                </div>
              </Col>
            ))}
          </div>
        ))}
      </Slider>
    </div>
  );
}; 
export default HomePageSlider;